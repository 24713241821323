import { useEffect, useRef, useCallback } from 'react'
import debounce from 'lodash.debounce'

export default (callback = () => {}, deps = [], ratio = 0.8) => {
  const componentRef = useRef(null)
  const handleScroll = useCallback(
    debounce(() => {
      if (!componentRef.current) return
      const scrollRatio =
        (componentRef.current.scrollTop + componentRef.current.clientHeight) /
        componentRef.current.scrollHeight

      if (scrollRatio >= ratio) {
        callback()
      }
    }, 450),
    [componentRef.current, ...deps]
  )

  useEffect(() => {
    document.removeEventListener('scroll', handleScroll, true)
    document.addEventListener('scroll', handleScroll, true)
    return () => {
      document.removeEventListener('scroll', handleScroll, true)
    }
  }, [...deps])

  return componentRef
}
