import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Dialog from '@material-ui/core/Dialog'
import { BidButton, CancelButton } from 'src/components/atoms/Buttons'

import { GavelIcon } from 'src/features/stocks/components/atoms'
import OfferInfoBox from 'src/components/OfferInfoBox'
import {
  BOOLEAN,
  DATE_LONG_FORMAT_WITH_WEEKDAY,
  MIN_BID
} from 'src/ducks/consts'
import moment from 'moment'
import {
  DateTitle,
  Description,
  GreenGavelIcon,
  Title
} from 'src/components/atoms/AuctionPrice'
import Alert from 'src/components/Alert'
import styled from 'styled-components'
import XIcon from 'src/assets/icons/close-x.svg'
import { breakpoint } from 'src/theme/grid'
import Stepper from 'src/components/atoms/Stepper'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { path, subtract, add } from 'ramda'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'

export default ({ onClick, offer, onOfferNumberClick, open, setOpen }) => {
  const intl = useIntl()
  const [price, setPrice] = useState(0)
  const [accepted, setAccepted] = useState(false)
  const minBid = MIN_BID[offer.currency]

  const isAuctionDirectionAscending =
    offer.offerType === STOCKS_TYPES.FREE_CARRIERS ||
    offer.offerType === STOCKS_TYPES.POST_IMPORT

  const offerPrice = path(['price', 'float'], offer)

  const initPrice = isAuctionDirectionAscending
    ? add(offerPrice, minBid)
    : subtract(offerPrice, minBid)

  const suggestedBid = initPrice >= minBid ? initPrice : minBid

  useEffect(() => {
    setPrice(suggestedBid)
  }, [offer.price.float])

  const isStartPriceHidden =
    offer.reservationsCount === 0 &&
    offer.price.float === 0 &&
    !offer.isPriceVisible

  const onClose = () => {
    setOpen(false)
    setPrice(suggestedBid)
    setAccepted(false)
  }

  const isValid =
    accepted &&
    price >= minBid &&
    (isStartPriceHidden || isAuctionDirectionAscending
      ? price >= suggestedBid
      : price <= suggestedBid)

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CloseIcon onClick={onClose} />
          <DialogHeader>
            <FormattedMessage id='makeABid.title' />
          </DialogHeader>
          <OfferInfoBox
            offer={offer}
            onOfferNumberClick={onOfferNumberClick}
            offerorCompany={offer.issuer.company.name}
          />
          <PriceContainer>
            <Column>
              <Title>
                <FormattedMessage id='offerDetails.currentPrice' />
              </Title>
              {!isStartPriceHidden && (
                <Price>
                  <>
                    {offer.price.float} <span>{offer.price.currency}</span>
                  </>
                </Price>
              )}
              {isStartPriceHidden && (
                <Price>
                  <>
                    <FormattedMessage id='offerDetails.hidden' />{' '}
                    <span>
                      <FormattedMessage id='offerDetails.makeFirstBid' />
                    </span>
                  </>
                </Price>
              )}
              <DateTitle>
                {moment(offer.auctionDeadline).fromNow(true)}
                <FormattedMessage id='offerDetails.toEnd' />
              </DateTitle>
              <Description>
                (
                {moment(offer.auctionDeadline).format(
                  DATE_LONG_FORMAT_WITH_WEEKDAY
                )}
                )
              </Description>
            </Column>
            <IconColumn>
              <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
            </IconColumn>
            <Column>
              <StepperText>
                {intl.formatMessage({
                  id: 'makeABid.yourOffer'
                })}
              </StepperText>
              <StepperRow>
                <Stepper
                  step={minBid}
                  minValue={
                    isStartPriceHidden || isAuctionDirectionAscending
                      ? suggestedBid
                      : minBid
                  }
                  maxValue={
                    isStartPriceHidden || isAuctionDirectionAscending
                      ? Number.MAX_SAFE_INTEGER
                      : suggestedBid
                  }
                  value={price}
                  onChange={setPrice}
                  withInput={isStartPriceHidden}
                />
                <Currency>{offer.currency}</Currency>
                <GreenGavelIcon />
              </StepperRow>
            </Column>
          </PriceContainer>
          {!isStartPriceHidden && (
            <Info
              type='info'
              message={
                <FormattedMessage
                  id={
                    isAuctionDirectionAscending
                      ? 'makeABid.infoAsc'
                      : 'makeABid.infoDsc'
                  }
                  values={{
                    minBid: <b>&nbsp;{minBid}&nbsp;</b>,
                    currency: <b>{offer.currency}&nbsp;</b>
                  }}
                />
              }
            />
          )}
          <DialogSubtitle>
            <FormattedMessage id='makeABid.auctionConditions' />
          </DialogSubtitle>
          <Conditions>
            <ListItem>
              <FormattedMessage id='makeABid.condition1' />
            </ListItem>
            <ListItem>
              {isAuctionDirectionAscending ? (
                <FormattedMessage id='makeABid.condition3' />
              ) : (
                <FormattedMessage id='makeABid.condition2' />
              )}
            </ListItem>
          </Conditions>
          {offer.notes && (
            <AuctionConditions>
              <AuctionsConditionsTitle>
                <FormattedMessage id='makeABid.offerOwnerConditions' />
              </AuctionsConditionsTitle>
              {offer.notes}
            </AuctionConditions>
          )}
          <AcceptContainer>
            <Checkbox
              name='conditionsAccepted'
              value={accepted}
              onChange={() => setAccepted(!accepted)}
              option={{ value: BOOLEAN.YES }}
            />
            <FormattedMessage id='makeABid.acceptAuctionConditions' />
          </AcceptContainer>
          <ActionBar>
            <CancelButton
              active
              onClick={() => {
                setOpen(false)
              }}
            >
              {intl.formatMessage({ id: 'common.cancel' })}
            </CancelButton>
            <BidButton
              active={isValid}
              onClick={() => {
                onClose()
                onClick(price)
              }}
            >
              <FormattedMessage id='offerDetails.makeABid' />
              <span>
                <GavelIcon />
              </span>
            </BidButton>
          </ActionBar>
        </DialogContent>
      </Dialog>
    </>
  )
}

const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    width: 600px;
    padding: 1.6rem 2.2rem;
  `}
`

const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.78;
  margin: 0.8rem 0 0.875rem 0;
  width: 100%;
  display: inline-block;
`

const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
`

const Currency = styled(DialogText)`
  width: auto;
`

const StepperText = styled(DialogText)`
  width: auto;
  margin-right: 1.2rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  span {
    opacity: 0.6;
  }
`

const AuctionConditions = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
  background-color: ${({ theme }) => theme.colors.athensGrayLight};
  border-radius: 4px;
  padding: 0.5rem;
`

const DialogSubtitle = styled.h4`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`

const PriceContainer = styled.div`
  margin: 0.875rem 0 0.5rem 0;
  display: flex;
  flex-direction: column;
  ${breakpoint.m`
    flex-direction: row;
  `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  ${breakpoint.m`
      margin-top: 0;
      margin-bottom: 0;
  `}
`

const Price = styled.div`
  font-size: 1.125rem;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  text-transform: uppercase;
  & > span {
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: none;
  }
`

const IconColumn = styled(Column)`
  width: auto;
  margin: 0 20px;
  flex: auto;
  justify-content: center;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  display: none;
  ${breakpoint.m`
    display: flex;
  `}
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  padding-bottom: 0.8rem;
  && > button {
    flex: none;
    margin-right: 0;
  }
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    padding-bottom: 0;
  `}
`

const StepperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > svg {
    display: none;
  }
  & > p {
    margin-left: 0.5rem;
  }
  ${breakpoint.m`
    justify-content: space-between;
    & > p {
    margin-left: 0;
    }
    & > svg {
    display: inline;
    }
  `}
`

const Info = styled(Alert)`
  font-size: 0.75rem;
  padding: 5px 24px 5px 0;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3)};
  margin-bottom: 0.75rem;
  & b {
    font-weight: 600;
  }
`

const Conditions = styled.ul`
  padding-left: 0.625rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
`

const ListItem = styled.li`
  font-size: 0.875rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  &:before {
    content: '\\2022';
    color: ${({ theme }) => theme.colors.apple.hex()};
    font-weight: 800;
    display: inline-block;
    width: 1rem;
  }
`

const AuctionsConditionsTitle = styled.p`
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.5).hex()};
  font-weight: 600;
  text-transform: uppercase;
`

const AcceptContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
  line-height: 2;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  margin-top: 0.5rem;
  ${Checkbox} {
    width: auto;
    & > span {
      width: 18px;
      height: 18px;
      margin: 0;
      &:before {
        border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
        width: 14px;
        height: 14px;
      }
    }
  }
`
