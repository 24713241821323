import React, { useContext } from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import styled from 'styled-components'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { MENU_ITEMS } from 'src/features/account/duck/consts'
import routes from 'src/utils/routes'
import CustomIcon from 'src/components/CustomIcon'
import { Avatar } from 'src/components/atoms/Avatar'
import {
  AvatarContainer,
  Key,
  User,
  UserContainer,
  UserProperties,
  Value
} from './atoms'
import { Name, Role } from './typography'
import UploadAvatar from './UploadAvatar'

const UserInfo = ({ user }) => {
  const permissions = useContext(PermissionsContext)
  return (
    <>
      <AvatarContainer>
        <Avatar big url={user.avatar && user.avatar.url} />
        <UploadAvatar />
      </AvatarContainer>
      <UserContainer>
        <User>
          <Name>{`${user.firstName} ${user.lastName}`}</Name>
          <Role>
            <FormattedMessage id={`common.${user.role}`} />
            {hasPermissionTo(PERMISSIONS.COMPANY_MANAGE_USERS, permissions) && (
              <Link
                to={`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.EDIT_USER}&id=${user.id}`}
              >
                <EditIcon name='edit' size={24} />
              </Link>
            )}
          </Role>
        </User>
        <UserProperties>
          <Key>
            <FormattedMessage id='common.login' />
          </Key>
          <Value>{user.username}</Value>
          <Key>
            <FormattedMessage id='common.email' />
          </Key>
          <Value>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </Value>
        </UserProperties>
      </UserContainer>
    </>
  )
}
export default UserInfo

const EditIcon = styled(CustomIcon)`
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  margin-left: 0.5rem;
  ${breakpoint.m`
          float: right;
          margin-left: 0;
  `}
`
