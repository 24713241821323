import styled from 'styled-components'
import NoNotificationsGraphic from 'src/assets/no-notifications.svg'
import { breakpoint } from 'src/theme/grid'
import MUISettings from '@material-ui/icons/SettingsOutlined'
import MUIClose from '@material-ui/icons/Close'

export const Title = styled.span`
  display: flex;
  width: 100%;
  font-weight: 800;
  font-size: 0.95rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  align-items: center;
  padding: 0.6rem 0.8rem;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  margin-bottom: 0.4rem;
  ${breakpoint.m`
    font-size: 1.1rem;
  `}
`

export const NoNotificationsComponent = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  background-image: url(${NoNotificationsGraphic});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 2.2rem 0;
  margin-top: 6.2rem;
`

export const NoNotificationsTitle = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 1.05rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black.hex()};
  line-height: 2;
  margin-bottom: 0.4rem;
`

export const NoNotificationsDescription = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  box-sizing: border-box;
  padding: 0 1.2rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  line-height: 1.71;
`

export const CogIcon = styled(MUISettings)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1.28rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 16px;
  right: 64px;
  cursor: pointer;
  ${breakpoint.s`
    right: 16px;
  `}
`

export const CloseIcon = styled(MUIClose)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1.28rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  ${breakpoint.s`
    display: none !important;
  `}
`

export const MarkAsRead = styled.span`
  display: inline-block;
  width: 100%;
  text-align: right;
  font-size: 0.78rem;
  line-height: 1.42;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  box-sizing: border-box;
  padding: 0 1rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
