import React from 'react'
import styled from 'styled-components'

const OfferDetails = ({ className, offer }) => (
  <div className={className}>
    <Text>
      {offer.from ? `${offer.from},` : ''}
      {offer.fromDate ? <span>{`${offer.fromDate}, `}</span> : ''}
      {offer.to ? `${offer.to}, ` : ''}{' '}
      {offer.toDate ? <span>{`${offer.toDate}, `}</span> : ''}
      {offer.startDate ? `${offer.startDate} - ` : ''}
      {offer.endDate ? `${offer.endDate}, ` : ''}
      {offer.container ? `${offer.container}, ` : ''}
      {offer.shipowner ? `${offer.shipowner}, ` : ''}
      {offer.handlerCompany ? `${offer.handlerCompany}, ` : ''}
      {offer.handlerName ? `${offer.handlerName}, ` : ''}
      {offer.driver ? `${offer.driver}, ` : ''}
      {offer.driverPhone ? `${offer.driverPhone}` : ''}
    </Text>
  </div>
)

export default styled(OfferDetails)`
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 20px;
  line-height: 1;
`

const Text = styled.span`
  display: inline;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  span {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.6).hex()};
  }
`
