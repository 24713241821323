import React from 'react'
import styled from 'styled-components'
import { Page } from 'src/components/Wrappers'
import { PlaceholderTitle } from 'src/components/Typography'

const DebtCollection = ({ className }) => (
  <Page className={className}>
    <PlaceholderTitle>Windykacja</PlaceholderTitle>
  </Page>
)

export default styled(DebtCollection)``
