import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import MUIDropdown from '@material-ui/icons/KeyboardArrowDown'
import { breakpoint } from 'src/theme/grid'
import icons from './Icons'

const Section = ({
  className,
  label,
  children,
  initialOpened = false,
  icon = 'offer'
}) => {
  const [opened, setOpened] = useState(initialOpened)
  return (
    <div className={className}>
      <Header onClick={() => setOpened(!opened)}>
        <TypeIcon active={opened}>{icons[icon]}</TypeIcon>
        <span>{label}</span>
        <DropdownIcon />
      </Header>
      {opened && <Body>{children}</Body>}
    </div>
  )
}

export default styled(Section)`
  display: inline-block;
  position: relative;
  width: 100%;
  &:after {
    content: '';
    width: calc(100% - 40px);
    height: 2px;
    background-color: ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  min-height: 80px;
  cursor: pointer;
  position: relative;
  & > span {
    font-size: 1.2rem;
    display: inline-block;
    color: ${({ theme }) => theme.colors.black.hex()};
    margin-left: 0.8rem;
    font-weight: 600;
    font-size: 1rem;
    ${breakpoint.m`
      font-size: 1.2rem;
    `}
  }
`

const Body = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  min-height: 100px;
  flex-direction: column;
  padding: 0 1.6rem 1.6rem 1.6rem;
  position: relative;
  ${breakpoint.m`
    padding: 0 3.2rem 2.8rem 3.2rem;
    padding-top: 45px;
  `}
`

const TypeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 14px 0 18px;
  background-color: ${({ theme }) => theme.colors.geyser.alpha(0.5).hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
      box-shadow: 0 0 10px 0 rgba(36, 45, 63, 0.25);
      color: white;
    `};
`

const DropdownIcon = styled(MUIDropdown)`
  display: inline-block;
  color: inherit;
  font-size: 1.2rem !important;
  position: relative;
  z-index: 20;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`
