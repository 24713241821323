import React, { useState } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { isNil } from 'ramda'
import styled, { css } from 'styled-components'
import { mapNotificationCodeToIcon } from 'src/features/notifications/duck/consts'
import useClickOutside from 'src/hooks/useClickOutside'
import EllipsisTooltip from 'src/components/EllipsisTooltip'

const Notification = ({ className, notification }) => {
  const [expand, setExpand] = useState(false)
  const componentRef = useClickOutside(() => setExpand(false))
  const text = notification.body.substring(0, 100)
  const hiddenText = notification.body.substring(100)
  const isMore = hiddenText.length > 0

  return (
    <div
      ref={componentRef}
      className={className}
      onMouseEnter={() => setExpand(true)}
      onMouseLeave={() => setExpand(false)}
    >
      <TypeIcon type={notification.type}>
        {mapNotificationCodeToIcon(notification.code)}
      </TypeIcon>
      <Details>
        <Subject>
          <EllipsisTooltip>{notification.subject}</EllipsisTooltip>
        </Subject>
        {!isNil(notification.body) && (
          <Row>
            <TextBody>
              {text}
              {!expand && isMore && '...'}
              {expand && isMore && hiddenText}
            </TextBody>
          </Row>
        )}
        <More show={isMore}>
          <span onClick={() => setExpand(!expand)}>
            {!expand && <FormattedMessage id='common.more' />}
            {expand && <FormattedMessage id='common.less' />}
          </span>
        </More>
      </Details>
    </div>
  )
}
export default styled(Notification)`
  display: flex;
  min-height: 124px;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  margin: 0.75rem 0.5rem;
  overflow: hidden;
`

const Details = styled.div`
  overflow: hidden;
`

const Row = styled.div``

const TextBody = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const TypeIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 0.75rem;
  background-color: ${({ theme, type }) =>
    type !== 'info'
      ? theme.colors.fadedRed.alpha(0.2).hex()
      : theme.colors.easternBlue.alpha(0.2).hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const Subject = styled.div`
  font-weight: 600;
  font-size: 0.88rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-right: 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.3rem;
`

const More = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-weight: 400;
  text-align: right;
  visibility: hidden;
  margin-top: 0.5rem;
  & > span {
    cursor: pointer;
  }
  ${({ show }) =>
    show &&
    css`
      visibility: visible;
    `}
`
