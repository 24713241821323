import React from 'react'
import styled, { css } from 'styled-components'
import MUIArrowRight from '@material-ui/icons/ArrowRightAlt'
import MUIDone from '@material-ui/icons/Done'
import MUICopy from '@material-ui/icons/FileCopyOutlined'
import MUIDelete from '@material-ui/icons/DeleteOutline'
import { breakpoint } from 'src/theme/grid'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import gavelBackgroundUrl from 'src/assets/gavel-background.svg'
import CheckboxInput from 'src/components/atoms/CheckboxInput'
import { Title } from 'src/features/stocks/components/typography'
import { Link } from 'gatsby-plugin-intl'
import AttachFileUrl from 'src/assets/icons/attach-file.svg'
import Alert from 'src/components/Alert'

export const PlaceholderRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem 0.6rem;
  box-sizing: border-box;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
`

export const Name = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

export const Value = styled(Name)`
  font-weight: 400;
`

export const PlaceholderTable = styled.div`
  display: flex;
  width: 100%;
  max-width: 450px;
  flex-direction: column;
  margin: 1.2rem 0;
  ${PlaceholderRow}:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.mercury.hex()};
  }
`

export const SectionBox = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(50, 54, 58, 0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
  box-sizing: border-box;
  padding: 1.8rem 1.2rem;
`

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 0.8rem 0;
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
`

export const FormSection = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  position: relative;
  ${breakpoint.m`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  `}
`

export const FormInnerWrapper = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 3.2rem;
  ${breakpoint.m`
    overflow-y: auto;
    height: 100%;
  `}
  background-color: #fff;
`

export const Padding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.4rem;
  width: 100%;
  box-sizing: border-box;
`

export const DraftsSection = styled.div`
  display: flex;
  height: 100%;
  width: 400px;
  border-left: 1px solid ${({ theme }) => theme.colors.iron.hex()};
  background-color: white;
  overflow-y: auto;
  box-sizing: border-box;
`

export const FormSectionSpacer = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black.hex()};
  line-height: 1.7;
  padding-bottom: 0.3rem;
  margin: 1.6rem 0 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
`

export const FormSubsectionSpacer = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  text-transform: uppercase;
  line-height: 1.7;
  margin: 1rem 0;
`

export const FormActionBar = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1rem 1.2rem;
  font-size: 0.875rem;
  width: 100%;
  height: 80px;
  box-shadow: 0 -2px 30px 0 rgba(36, 45, 63, 0.15);
  background-color: white;
  z-index: 20;
  ${breakpoint.m`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 1.2rem;
  `}
`

export const InputsRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const AuctionInputsRowWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  background: none;
  ${breakpoint.l`
      background: url(${gavelBackgroundUrl}) no-repeat 90% 50%;
  `}
`

export const InputWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 0 0.8rem 0 0;
  max-width: 626px;
`

export const PlaceInputWrapper = styled(InputWrapper)`
  width: 100%;
  ${breakpoint.m`
    width: 256px;
    margin-right: 3.6rem;
  `}
`

export const DateInputWrapper = styled(InputWrapper)`
  width: calc(55% - 0.8rem);
  ${breakpoint.m`
    width: 180px;
  `}
`

export const TimeInfoWrapper = styled(InputWrapper)`
  display: flex;
  justify-content: center;
  ${breakpoint.l`
    justify-content: flex-end;
    padding-right: 65px;
    margin-top: -5px;
  `}
`

export const TimeInputWrapper = styled(InputWrapper)`
  width: calc(45% - 0.8rem);
  ${breakpoint.m`
    width: 120px;
  `}
`

export const AuctionTimeInputWrapper = styled(TimeInputWrapper)`
  ${breakpoint.m`
    margin-right: 9.6rem;
  `}
`

export const WarningWrapper = styled(InputWrapper)`
  margin-bottom: 1rem;
`

export const AuctionNotesWrapper = styled(InputWrapper)`
  margin-top: 1rem;
`

export const DictionaryInputWrapper = styled(InputWrapper)`
  width: 100%;
  ${breakpoint.m`
    width: 220px;
  `}
`

export const WeightInputWrapper = styled(InputWrapper)`
  width: 100%;
  ${breakpoint.m`
    width: 160px;
  `}
`

export const PriceInputWrapper = styled(InputWrapper)`
  width: 100%;
  ${breakpoint.m`
    width: 160px;
    margin-right: 3.6rem;
  `}
`

export const CargoTypeInputWrapper = styled(InputWrapper)`
  width: 100%;
  ${breakpoint.m`
    width: 220px;
  `}
`

export const CheckboxInputWrapper = styled(InputWrapper)`
  width: 100%;
  ${breakpoint.m`
    width: 200px;
  `}
`

export const TemplateNameInputWrapper = styled(InputWrapper)`
  width: 100%;
  ${breakpoint.m`
    width: 300px;
  `}
`

const TemplateName = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-bottom: 0.3rem;
`

const PlaceName = styled.span`
  font-weight: 600;
  width: auto;
  display: inline-block;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95px;
  ${breakpoint.xxs`
    max-width: 120px;
  `}
`

const TemplateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.1rem;
  position: relative;
`

const ArrowRight = styled(MUIArrowRight)`
  font-size: 1.3rem !important;
  color: ${({ theme }) => theme.colors.apple.hex()};
  margin: 0 0.6rem;
`

const TemplateDetail = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.75rem;
  padding-right: 0.6rem;
  margin-right: 0.5rem;
  position: relative;
  &:after {
    content: '';
    width: 1px;
    height: 14px;
    background-color: ${({ theme }) => theme.colors.ghost.hex()};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
`

const CopyIcon = styled(MUICopy)`
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 6px;
  transition: color 0.3s;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
`

const DoneIcon = styled(MUIDone)`
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 24px;
  transition: color 0.3s;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  ${breakpoint.m`
    top: 4px;
    right: 30px;
  `}
`

const DeleteIcon = styled(MUIDelete)`
  display: inline-block;
  position: absolute;
  top: 0;
  right: -8px;
  transition: color 0.3s;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  padding: 0.2rem;
  ${breakpoint.m`
    right: 0;
    color: transparent;
    &:hover {
      background-color: ${({ theme }) => theme.colors.ghost.alpha(0.5).hex()};
    }
  `}
`

const TemplateComponent = ({
  className,
  templateName,
  id,
  from,
  to,
  details = [],
  onClick,
  selected,
  template,
  removeTemplate
}) => (
  <div className={className} onClick={onClick}>
    {templateName && <TemplateName>{templateName}</TemplateName>}
    <TemplateRow>
      {selected && <DoneIcon />}
      {template && <DeleteIcon onClick={e => removeTemplate(e, id)} />}
      {from && (
        <PlaceName>
          <EllipsisTooltip>{from}</EllipsisTooltip>
        </PlaceName>
      )}
      {to && (
        <>
          <ArrowRight />
          <PlaceName>
            <EllipsisTooltip>{to}</EllipsisTooltip>
          </PlaceName>
        </>
      )}
    </TemplateRow>
    <TemplateRow>
      {details.map(detail => (
        <TemplateDetail>{detail}</TemplateDetail>
      ))}
    </TemplateRow>
  </div>
)

export const Template = styled(TemplateComponent)`
  display: inline-block;
  width: 100%;
  min-height: 40px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 0.6rem;
  margin-bottom: 0.6rem;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &:hover {
    ${CopyIcon} {
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
    }
    ${DeleteIcon} {
      color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
    }
  }
  ${({ withTitle }) =>
    withTitle &&
    css`
      ${PlaceName} {
        font-weight: 400;
      }
      ${CopyIcon} {
        top: -8px;
      }
    `}
`

export const TemplatesButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.colors.easternBlue.hex()};
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  border-radius: 4px;
  margin-top: 0.8rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  ${breakpoint.m`
    display: none;
  `}
`

export const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`

export const ActionGroupWeb = styled.div`
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  ${breakpoint.s`
    display: flex;
  `}
`

export const ActionGroupMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  ${breakpoint.s`
    display: none;
  `}
`

export const HideOnMobile = styled.div`
  display: none;
  margin-right: 1.2rem;
  ${breakpoint.m`
    display: inline-block;
  `}
`

export const RadioWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  & > div {
    width: auto;
    display: inline-flex;
    padding: 0;
    margin-right: 1rem;
  }
  & > ${CheckboxInput} span {
    margin-right: 0.5rem;
  }
`

export const OfferDetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem 0 0 0;
  ${Title} {
    padding-bottom: 4px;
  }
  > div {
    margin-bottom: 1rem;
  }
  background-color: #fff;
  ${breakpoint.m`
      padding: 1rem 0 80px 0;
   `}
`

export const Heading = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`

export const HeadingContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

export const OfferLink = styled(Link)`
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

export const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px 0 rgba(36, 45, 63, 0.16);
  box-sizing: border-box;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  height: 100%;
  width: 100%;
  ${breakpoint.m`
      width: 400px;
   `}
`

export const BidderTitle = styled.div`
  padding: 1rem 1rem 0;
  background-color: ${({ theme }) => theme.colors.alabaster.hex()};
`

export const AttachFileIconComponent = ({ className }) => (
  <div className={className}>
    <AttachFileUrl />
  </div>
)

export const AttacheFileIcon = styled(AttachFileIconComponent)`
  background-color: ${({ theme }) => theme.colors.apple.alpha(0.2).hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black.alpha(0.8).hex()};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const AlertsRow = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
  ${breakpoint.m`
      flex-direction: row;
  `}
`

export const OrdersLink = styled(Alert)`
  display: flex;
  flex: 1;
  box-shadow: 0 2px 20px 0 rgba(36, 45, 63, 0.1);
  border-radius: 8px;
  cursor: pointer;
`

export const TrackingLink = styled(Alert)`
  display: flex;
  flex: 1;
  box-shadow: 0 2px 20px 0 rgba(36, 45, 63, 0.1);
  border-radius: 8px;
  cursor: pointer;
`
export const MoreReservations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  font-size: 0.75rem;
  padding: 0.5rem 0;
  box-shadow: 0px 6px 10px rgba(36, 45, 63, 0.15);
  margin-bottom: 7px;
  position: relative;
  z-index: 1;
  position: relative;
  svg {
    opacity: 0.7;
  }
`

export const Subtitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-bottom: 0.5rem;
`

const PrivateLabelComponent = ({ className, label = 'P' }) => (
  <span className={className}>{label}</span>
)

export const PrivateLabel = styled(PrivateLabelComponent)`
  background-color: white; // Wybierz kolor tła, który pasuje do Twojego designu
  color: #13a2bf; // Kolor tekstu
  border-radius: 3px; // Zaokrąglenie rogów
  padding: 1px 4px; // Wewnętrzny margines
  margin-right: 1px; // Wewnętrzny margines
  margin-left: 2px; // Zewnętrzny margines
  font-size: 0.8em; // Rozmiar czcionki
  border-width: 1px; // Grubość obramowania
  border-color: #13a2bf;
  border-style: solid;
`
