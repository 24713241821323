import React from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'

import Pagination from 'src/components/Pagination'
import LoadingList from 'src/components/atoms/LoadingList'
import Shade from 'src/components/Shade'

import {
  BottomRow,
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  RefreshIcon,
  Row
} from 'src/features/stocks/components/atoms'

import { isStockLoading } from 'src/utils/stocks'

import {
  ActionGroup,
  HeaderItem,
  Padding
} from 'src/features/myOffers/components/atoms'
import { Icon, SectionBox } from 'src/features/account/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { connectMyContractsOffersFinished } from 'src/features/contracts/duck/connectors'
import {
  CONTRACTS_HEADERS,
  CONTRACTS_HEADERS_MY_OFFERS_FINISHED_WIDTHS,
  CONTRACTS_HEADERS_MY_OFFERS_FINISHED_NAMES
} from 'src/features/contracts/duck/consts'
import { TableHeader } from 'src/features/contracts/components/atoms'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { BOOLEAN } from 'src/ducks/consts'
import OfferRowFinished from 'src/features/contracts/components/OfferRowFinished'

const OffersList = ({
  offers,
  pagination,
  filters,
  setFilter,
  hidePagination = false,
  getFinishedOffers,
  className,
  stock
}) => {
  const headers = CONTRACTS_HEADERS.MY_OFFERS_FINISHED
  const intl = useIntl()

  const toggleFilter = name => {
    setFilter(name, !filters.onlyMe)
  }

  const renderListContents = () => {
    if (isStockLoading(stock, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (offers.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='offers.noOffers' />
        </EmptyOffers>
      )
    }

    if (offers.length > 0) {
      return offers.map(offer => (
        <OfferRowFinished offer={offer} key={offer.id} />
      ))
    }
  }

  return (
    <div className={className}>
      <Padding></Padding>
      <ListTopRow>
        <CounterGroup>
          <Counter>
            <span>{`${pagination.total} `}</span>
            <FormattedMessage
              id='offers.offersFound'
              values={{
                count: pagination.total
              }}
            />
          </Counter>
          <RefreshIcon onClick={getFinishedOffers} />
        </CounterGroup>
        <ActionGroup>
          <Checkbox
            name='onlyMe'
            value={filters.onlyMe}
            onChange={toggleFilter}
            option={{
              text: intl.formatMessage({ id: 'offers.onlyMe' }),
              value: BOOLEAN.YES
            }}
          />
        </ActionGroup>
      </ListTopRow>
      <Container>
        <TableHeader
          widths={CONTRACTS_HEADERS_MY_OFFERS_FINISHED_WIDTHS}
          columnNames={CONTRACTS_HEADERS_MY_OFFERS_FINISHED_NAMES}
        >
          {headers &&
            headers.map((header, index) => (
              <HeaderItem sort={false} key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage
                    id={`stocksHeaders.${header.name}`}
                    defaultMessage=' '
                  />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </HeaderItem>
            ))}
        </TableHeader>
        {renderListContents()}
      </Container>
      {offers.length > 0 && !hidePagination && (
        <BottomRow>
          <Pagination
            itemsPerPage={filters.limit}
            currentPage={pagination.currentPage}
            pagesCount={pagination.lastPage}
            setPage={page => setFilter('page', page)}
            setItemsPerPage={limit => {
              setFilter('limit', limit)
              setFilter('page', 1)
            }}
          />
        </BottomRow>
      )}
    </div>
  )
}

const connectedComponent = connectMyContractsOffersFinished(OffersList)
export default styled(connectedComponent)`
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
export const ListTopRow = styled(Row)`
  justify-content: space-between;
  padding: 0 1.2rem;
  ${Counter} {
    margin-right: 0.75rem;
  }
  ${breakpoint.m`
     padding: 0;
  `}
`
const CounterGroup = styled.div`
  display: flex;
`
