import React from 'react'
import styled from 'styled-components'

import MUILocalOffer from '@material-ui/icons/LocalOfferOutlined'
import MUIGavel from '@material-ui/icons/Gavel'
import MUIBookmarks from '@material-ui/icons/BookmarksOutlined'
import MUIDescription from '@material-ui/icons/DescriptionOutlined'
import CustomIcon from 'src/components/CustomIcon'

const Offer = styled(MUILocalOffer)`
  display: inline-block;
  color: inherit;
  font-size: 1.2rem !important;
  position: relative;
  z-index: 20;
`

const ReservationIcon = styled(MUIBookmarks)`
  display: inline-block;
  color: inherit;
  font-size: 1.2rem !important;
  position: relative;
  z-index: 20;
`

const AuctionOfferIcon = styled(MUIGavel)`
  display: inline-block;
  color: inherit;
  font-size: 1.2rem !important;
  position: relative;
  z-index: 20;
`

const ContractIcon = styled(MUIDescription)`
  display: inline-block;
  color: inherit;
  font-size: 1.2rem !important;
  position: relative;
  z-index: 20;
`

const TrackingIcon = styled(CustomIcon)`
  display: inline-block;
  color: inherit;
  margin-top: 14px;
  position: relative;
  z-index: 20;
`

const OrderIcon = styled(CustomIcon)`
  display: inline-block;
  color: inherit;
  margin-top: 14px;
  position: relative;
  z-index: 20;
`

const InvoiceIcon = styled(CustomIcon)`
  display: inline-block;
  color: inherit;
  margin-top: 14px;
  position: relative;
  z-index: 20;
`

export default {
  offer: <Offer />,
  auction: <AuctionOfferIcon />,
  reservation: <ReservationIcon />,
  contract: <ContractIcon />,
  'transportation-tracking': <TrackingIcon name='tracking' size={24} />,
  'transportation-order': <OrderIcon name='order' size={24} />,
  invoice: <InvoiceIcon name='invoice' size={24} />
}
