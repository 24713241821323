import { connect } from 'react-redux'
import {
  setExportDrawerRoutine,
  showExportOfferDetailsRoutine
} from 'src/features/stocks/ducks/actions-export'
import {
  setImportDrawerRoutine,
  showImportOfferDetailsRoutine
} from 'src/features/stocks/ducks/actions-import'
import {
  setPostImportDrawerRoutine,
  showPostImportOfferDetailsRoutine
} from 'src/features/stocks/ducks/actions-post-import'
import {
  setFreeCarriersDrawerRoutine,
  showFreeCarrierOfferDetailsRoutine
} from 'src/features/stocks/ducks/actions-free-carriers'
import { selectOfferForDebtCollectionRoutine } from 'src/features/debtCollection/duck/actions'

import { setConversationOpenedRoutine } from 'src/features/messages/duck/actions'
import {
  cancelExportReservationRoutine,
  cancelFreeCarrierReservationRoutine,
  cancelImportReservationRoutine,
  cancelPostImportReservationRoutine,
  getMyExportFinishedReservationsRoutine,
  getMyExportOngoingReservationsRoutine,
  getMyFreeCarrierFinishedReservationsRoutine,
  getMyFreeCarrierOngoingReservationsRoutine,
  getMyImportFinishedReservationsRoutine,
  getMyImportOngoingReservationsRoutine,
  getMyPostImportFinishedReservationsRoutine,
  getMyPostImportOngoingReservationsRoutine,
  setMyExportFinishedReservationsFiltersRoutine,
  setMyExportOngoingReservationsFiltersRoutine,
  setMyFreeCarrierFinishedReservationsFiltersRoutine,
  setMyFreeCarrierOngoingReservationsFiltersRoutine,
  setMyImportFinishedReservationsFiltersRoutine,
  setMyImportOngoingReservationsFiltersRoutine,
  setMyPostImportFinishedReservationsFiltersRoutine,
  setMyPostImportOngoingReservationsFiltersRoutine
} from './actions'
import {
  selectMyExportFinishedReservations,
  selectMyExportOngoingReservations,
  selectMyFreeCarrierFinishedReservations,
  selectMyImportFinishedReservations,
  selectMyImportOngoingReservations,
  selectMyFreeCarrierOngoingReservations,
  selectMyPostImportFinishedReservations,
  selectMyPostImportOngoingReservations,
  selectMyReservationsExportFinishedFilters,
  selectMyReservationsExportFinishedPagination,
  selectMyReservationsExportOngoingFilters,
  selectMyReservationsExportOngoingPagination,
  selectMyReservationsFreeCarrierFinishedFilters,
  selectMyReservationsFreeCarrierFinishedPagination,
  selectMyReservationsFreeCarrierOngoingFilters,
  selectMyReservationsFreeCarrierOngoingPagination,
  selectMyReservationsImportFinishedFilters,
  selectMyReservationsImportFinishedPagination,
  selectMyReservationsImportOngoingFilters,
  selectMyReservationsImportOngoingPagination,
  selectMyReservationsPostImportFinishedFilters,
  selectMyReservationsPostImportFinishedPagination,
  selectMyReservationsPostImportOngoingFilters,
  selectMyReservationsPostImportOngoingPagination,
  selectExportOngoingReservations,
  selectExportFinishedReservations,
  selectFreeCarrierOngoingReservations,
  selectFreeCarrierFinishedReservations,
  selectFreeCarrierFinishedReservationsGeneral,
  selectImportFinishedReservations,
  selectImportReservationsOngoing,
  selectPostImportFinishedReservations,
  selectPostImportOngoingReservations
} from './selectors'

export const connectExportReservations = connect(null, dispatch => ({
  getMyOngoingReservations: () =>
    dispatch(getMyExportOngoingReservationsRoutine()),
  getMyFinishedReservations: () =>
    dispatch(getMyExportFinishedReservationsRoutine())
}))

export const connectExportOngoingReservations = connect(
  state => ({
    myReservations: selectExportOngoingReservations(state),
    reservations: selectMyExportOngoingReservations(state),
    filters: selectMyReservationsExportOngoingFilters(state),
    pagination: selectMyReservationsExportOngoingPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyExportOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyExportFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelExportReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(setMyExportOngoingReservationsFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setExportDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showExportOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectExportFinishedReservations = connect(
  state => ({
    myReservations: selectExportFinishedReservations(state),
    reservations: selectMyExportFinishedReservations(state),
    filters: selectMyReservationsExportFinishedFilters(state),
    pagination: selectMyReservationsExportFinishedPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyExportOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyExportFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelExportReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(setMyExportFinishedReservationsFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setExportDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showExportOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)

export const connectImportReservations = connect(null, dispatch => ({
  getMyOngoingReservations: () =>
    dispatch(getMyImportOngoingReservationsRoutine()),
  getMyFinishedReservations: () =>
    dispatch(getMyImportFinishedReservationsRoutine())
}))

export const connectImportOngoingReservations = connect(
  state => ({
    ongoingReservation: selectImportReservationsOngoing(state),
    reservations: selectMyImportOngoingReservations(state),
    filters: selectMyReservationsImportOngoingFilters(state),
    pagination: selectMyReservationsImportOngoingPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyImportOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyImportFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelImportReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(setMyImportOngoingReservationsFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setImportDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showImportOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectImportFinishedReservations = connect(
  state => ({
    finishedReservation: selectImportFinishedReservations(state),
    reservations: selectMyImportFinishedReservations(state),
    filters: selectMyReservationsImportFinishedFilters(state),
    pagination: selectMyReservationsImportFinishedPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyImportOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyImportFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelImportReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(setMyImportFinishedReservationsFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setImportDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showImportOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)

export const connectPostImportReservations = connect(null, dispatch => ({
  getMyOngoingReservations: () =>
    dispatch(getMyPostImportOngoingReservationsRoutine()),
  getMyFinishedReservations: () =>
    dispatch(getMyPostImportFinishedReservationsRoutine())
}))

export const connectPostImportOngoingReservations = connect(
  state => ({
    ongoingReservations: selectPostImportOngoingReservations(state),
    reservations: selectMyPostImportOngoingReservations(state),
    filters: selectMyReservationsPostImportOngoingFilters(state),
    pagination: selectMyReservationsPostImportOngoingPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyPostImportOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyPostImportFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelPostImportReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(
        setMyPostImportOngoingReservationsFiltersRoutine({ name, value })
      ),
    setDrawerState: drawerState =>
      dispatch(setPostImportDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showPostImportOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectPostImportFinishedReservations = connect(
  state => ({
    finishedReservation: selectPostImportFinishedReservations(state),
    reservations: selectMyPostImportFinishedReservations(state),
    filters: selectMyReservationsPostImportFinishedFilters(state),
    pagination: selectMyReservationsPostImportFinishedPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyPostImportOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyPostImportFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelPostImportReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(
        setMyPostImportFinishedReservationsFiltersRoutine({ name, value })
      ),
    setDrawerState: drawerState =>
      dispatch(setPostImportDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showPostImportOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)

export const connectFreeCarrierReservations = connect(null, dispatch => ({
  getMyOngoingReservations: () =>
    dispatch(getMyFreeCarrierOngoingReservationsRoutine()),
  getMyFinishedReservations: () =>
    dispatch(getMyFreeCarrierFinishedReservationsRoutine())
}))

export const connectFreeCarrierOngoingReservations = connect(
  state => ({
    myReservations: selectFreeCarrierOngoingReservations(state),
    reservations: selectMyFreeCarrierOngoingReservations(state),
    filters: selectMyReservationsFreeCarrierOngoingFilters(state),
    pagination: selectMyReservationsFreeCarrierOngoingPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyFreeCarrierOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyFreeCarrierFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelFreeCarrierReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(
        setMyFreeCarrierOngoingReservationsFiltersRoutine({ name, value })
      ),
    setDrawerState: drawerState =>
      dispatch(setFreeCarriersDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showFreeCarrierOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectFreeCarrierFinishedReservations = connect(
  state => ({
    finishedReservation: selectFreeCarrierFinishedReservationsGeneral(state),
    myReservations: selectFreeCarrierFinishedReservations(state),
    reservations: selectMyFreeCarrierFinishedReservations(state),
    filters: selectMyReservationsFreeCarrierFinishedFilters(state),
    pagination: selectMyReservationsFreeCarrierFinishedPagination(state)
  }),
  dispatch => ({
    getMyOngoingReservations: () =>
      dispatch(getMyFreeCarrierOngoingReservationsRoutine()),
    getMyFinishedReservations: () =>
      dispatch(getMyFreeCarrierFinishedReservationsRoutine()),
    cancelReservation: payload =>
      dispatch(cancelFreeCarrierReservationRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(
        setMyFreeCarrierFinishedReservationsFiltersRoutine({ name, value })
      ),
    setDrawerState: drawerState =>
      dispatch(setFreeCarriersDrawerRoutine({ drawerState })),
    showOffer: id => dispatch(showFreeCarrierOfferDetailsRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)
