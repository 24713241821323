import React from 'react'
import { Ellipsis } from 'src/features/stocks/components/atoms'

import { TableRow } from 'src/features/contracts/components/atoms'
import styled from 'styled-components'
import {
  INVOICE_ITEMS_TABLE_NAMES,
  INVOICE_ITEMS_TABLE_WIDTHS,
  TAX_RATES_OPTIONS
} from 'src/features/invoices/duck/consts'
import {
  getLabelFromSelectOptions,
  removePrefixFromGtuCodeValue
} from 'src/features/invoices/duck/helpers'
import MobileInvoiceItemRowPreview from 'src/features/invoices/components/itemsTable/MobileInvoiceItemRowPreview'
import EllipsisTooltip from 'src/components/EllipsisTooltip'

const currency = /\b(PLN|GBP|EUR)\b/gi
const ItemRow = ({ item, index, className }) => (
  <>
    <InvoiceItemRow
      className={className}
      widths={INVOICE_ITEMS_TABLE_WIDTHS}
      columnNames={INVOICE_ITEMS_TABLE_NAMES}
    >
      <div>
        <Ellipsis>{index + 1}.</Ellipsis>
      </div>
      <div>
        <Cell
          disabled
          name={['items', index, 'description']}
          value={item.description}
        />
      </div>
      <div>
        <Cell name={['items', index, 'pkwiuCode']} value={item.pkwiuCode} />
      </div>
      <div>
        <Cell
          name='gtuCode'
          value={item.gtuCode ? removePrefixFromGtuCodeValue(item.gtuCode) : ''}
        />
      </div>
      <div>
        <Cell min={0} name={['items', index, 'qty']} value={item.qty} />
      </div>
      <div>
        <Cell name={['items', index, 'unit']} value={item.unit} />
      </div>
      <div>
        <PriceInput
          name={['items', index, 'unitPrice', 'float']}
          value={item.unitPrice.formatted.replace(currency, '')}
        />
      </div>
      <div>
        <Cell
          name='taxRate'
          value={getLabelFromSelectOptions(item.taxRate, TAX_RATES_OPTIONS)}
        />
      </div>
    </InvoiceItemRow>
    <MobileInvoiceItemRowPreview
      item={item}
      index={index}
      className={className}
    />
  </>
)
const InvoiceItemRow = styled(TableRow)`
  height: 40px;
  border-radius: 0;
  border: solid 1px ${({ theme }) => theme.colors.ghost.hex()};
  border-bottom: none;
  pointer-events: none;
  &:nth-child(2) {
    border-radius: 2px 2px 0 0;
  }
  &:last-child {
    border-bottom: solid 1px ${({ theme }) => theme.colors.ghost.hex()};
    border-radius: 0 0 2px 2px;
  }
  & > div {
    &:first-child {
      border-left: none;
    }
    padding: 0.5rem;
    height: 100%;
    align-items: center;
    display: flex;
    border-left: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  }
`

export default ItemRow

const Cell = ({ value, className }) => (
  <div className={className} style={{ minWidth: 0, pointerEvents: 'auto' }}>
    <EllipsisTooltip>{value || '-'}</EllipsisTooltip>
  </div>
)

const PriceInput = styled(Cell)`
  text-align: right;
  width: 100%;
`
