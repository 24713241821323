import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import routes from 'src/utils/routes'
import { Row, SectionBox, Column } from 'src/features/account/components/atoms'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import Slider from 'src/components/Slider'
import Notification from './components/NotificationSticky'
import connect from './duck/connect'
import Img from 'gatsby-image'

import GooglePlayBadgeUrl from 'src/assets/images/btn-google-play.png'
import AppStoreBadgeUrl from 'src/assets/images/btn-app-store.png'

import Dialog from '@material-ui/core/Dialog'
import { CancelButton, SaveOfferButton } from 'src/components/atoms/Buttons'
import { mapNotificationCodeToIcon } from 'src/features/notifications/duck/consts'

import { MobileAppStoreBadgeBtn } from 'src/components/static/mobileAppStoreBadgeBtn.jsx'

const Dashboard = ({
  className,
  getStickyNotificationsList,
  exchangeCounters,
  stickyNotificationsList,
  currentUser
}) => {
  const intl = useIntl()
  const [currentDialogIndex, setCurrentDialogIndex] = React.useState(0)

  useEffect(() => {
    getStickyNotificationsList()
  }, [])

  const handleDialogClose = (event, reason) => {
    console.log(event)
    console.log('handleDialogClose', reason)

    reason === undefined
      ? setCurrentDialogIndex(prevIndex => prevIndex + 1)
      : null
  }

  const SliderTitle = (
    <NameWrapper>
      <Title2>
        <FormattedMessage id='dashboard.info' />
      </Title2>
      <Subtitle>
        <FormattedMessage
          values={{ count: stickyNotificationsList.length }}
          id='dashboard.notification'
        />
      </Subtitle>
    </NameWrapper>
  )

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'dashboard.title'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <SliderWrapper>
        <NameWrapper>
          <Title>
            <FormattedMessage
              id='dashboard.hello'
              values={{ name: currentUser.firstName }}
            />
          </Title>
          <Subtitle>{currentUser.username}</Subtitle>
        </NameWrapper>
        <Row />
        <Slider
          title={SliderTitle}
          slides={stickyNotificationsList.map((notification, index) => (
            <Notification
              key={index}
              notification={notification}
              first={index === 0}
              last={index === stickyNotificationsList.length - 1}
            />
          ))}
        />
      </SliderWrapper>
      {/* <SectionBox style={{'margin-bottom': 0}}>
        <Stock>
          {' '}
          <Row>
            <Column
              style={{ 'padding-right': '1.5em', 'text-align': 'center' }}
            >
              <Title>Szanowni Użytkownicy,</Title>
              <br />
              <h4>
                Dziękujemy za współpracę, a także zaufanie, jakim obdarzyliście
                nas w 2021 roku.
                <br />
                <br />
                Niech nowy 2022 rok przyniesie Wam i Waszym Bliskim dużo zdrowia
                i wszelkiej pomyślności w sferze biznesowej jak i osobistej.
              </h4>
              <br />
              <strong>Zespół e-containers</strong>
            </Column>
            <Column style={{ flex: 0.3 }}>
              {' '}
              <BestWishes2022Image src={Best2022Url} />
            </Column>
          </Row>
        </Stock>
      </SectionBox> */}
      <MobileAppSectionBox>
        <Title2>
          <FormattedMessage id={'common.downloadNewMobileApp'} />
        </Title2>
        <MobileStoreWrapper>
          {/* <a href='https://play.google.com/store/apps/details?id=pl.econtainers.exchange'>
            <MobileStoreBadge src={GooglePlayBadgeUrl} />
          </a>
          <a href='https://apps.apple.com/mn/app/e-containers-gie%C5%82da/id1569031248'>
            <MobileStoreBadge src={AppStoreBadgeUrl} />
          </a> */}
          <MobileAppStoreBadgeBtn
            locale={intl.locale}
            app='driver'
            platform='android'
          />
          <MobileAppStoreBadgeBtn
            locale={intl.locale}
            app='driver'
            platform='ios'
          />
        </MobileStoreWrapper>
      </MobileAppSectionBox>
      <SectionBox>
        <Row>
          <Title2>
            <FormattedMessage id='dashboard.stocks' />
          </Title2>
        </Row>
        <StocksContainer>
          <Stock>
            <StockTitle>
              <FormattedMessage id='dashboard.exportTitle' />
              <StockOffersCounterMobile>
                {exchangeCounters.export}
              </StockOffersCounterMobile>
            </StockTitle>
            <StockDescription>
              <DescriptionText>
                <FormattedMessage
                  id='dashboard.exportDescription'
                  values={{ b: chunks => <b>{chunks}</b> }}
                />
              </DescriptionText>
              <StockOffersCounter>{exchangeCounters.export}</StockOffersCounter>
            </StockDescription>
            <StockLinks>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_EXPORT}`}
              >
                <FormattedMessage id='dashboard.stock' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`}
              >
                <FormattedMessage id='dashboard.myOffers' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_RESERVATIONS}`}
              >
                <FormattedMessage id='dashboard.myReservations' />
              </Link>
            </StockLinks>
          </Stock>
          <Stock>
            <StockTitle>
              <FormattedMessage id='dashboard.importTitle' />
              <StockOffersCounterMobile>
                {exchangeCounters.import}
              </StockOffersCounterMobile>
            </StockTitle>
            <StockDescription>
              <DescriptionText>
                <FormattedMessage
                  id='dashboard.importDescription'
                  values={{ b: chunks => <b>{chunks}</b> }}
                />
              </DescriptionText>
              <StockOffersCounter>{exchangeCounters.import}</StockOffersCounter>
            </StockDescription>
            <StockLinks>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_IMPORT}`}
              >
                <FormattedMessage id='dashboard.stock' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`}
              >
                <FormattedMessage id='dashboard.myOffers' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_RESERVATIONS}`}
              >
                <FormattedMessage id='dashboard.myReservations' />
              </Link>
            </StockLinks>
          </Stock>
          <Stock>
            <StockTitle>
              <FormattedMessage id='dashboard.postImportTitle' />
              <StockOffersCounterMobile>
                {exchangeCounters.post_import_containers}
              </StockOffersCounterMobile>
            </StockTitle>
            <StockDescription>
              <DescriptionText>
                <FormattedMessage
                  id='dashboard.postImportDescription'
                  values={{ b: chunks => <b>{chunks}</b> }}
                />
              </DescriptionText>
              <StockOffersCounter>
                {exchangeCounters.post_import_containers}
              </StockOffersCounter>
            </StockDescription>
            <StockLinks>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_POST_IMPORT}`}
              >
                <FormattedMessage id='dashboard.stock' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_POST_IMPORT_OFFERS}`}
              >
                <FormattedMessage id='dashboard.myOffers' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_POST_IMPORT_RESERVATIONS}`}
              >
                <FormattedMessage id='dashboard.myReservations' />
              </Link>
            </StockLinks>
          </Stock>
          <Stock>
            <StockTitle>
              <FormattedMessage id='dashboard.freeCarriersTitle' />
              <StockOffersCounterMobile>
                {exchangeCounters.free_carriers}
              </StockOffersCounterMobile>
            </StockTitle>
            <StockDescription>
              <DescriptionText>
                <FormattedMessage
                  id='dashboard.freeCarriersDescription'
                  values={{ b: chunks => <b>{chunks}</b> }}
                />
              </DescriptionText>
              <StockOffersCounter>
                {exchangeCounters.free_carriers}
              </StockOffersCounter>
            </StockDescription>
            <StockLinks>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_FREE_CARRIERS}`}
              >
                <FormattedMessage id='dashboard.stock' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_FREE_CARRIERS_OFFERS}`}
              >
                <FormattedMessage id='dashboard.myOffers' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_FREE_CARRIERS_RESERVATIONS}`}
              >
                <FormattedMessage id='dashboard.myReservations' />
              </Link>
            </StockLinks>
          </Stock>
          <Stock>
            <StockTitle>
              <FormattedMessage id='dashboard.contractsTitle' />
              <StockOffersCounterMobile>
                {exchangeCounters.contracts}
              </StockOffersCounterMobile>
            </StockTitle>
            <StockDescription>
              <DescriptionText>
                <FormattedMessage
                  id='dashboard.contractsDescription'
                  values={{ b: chunks => <b>{chunks}</b> }}
                />
              </DescriptionText>
              <StockOffersCounter>
                {exchangeCounters.contracts}
              </StockOffersCounter>
            </StockDescription>
            <StockLinks>
              <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS}`}>
                <FormattedMessage id='dashboard.stock' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS_OFFERS}`}
              >
                <FormattedMessage id='dashboard.myOffers' />
              </Link>
              <Dot>&#8729;</Dot>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS_RESERVATIONS}`}
              >
                <FormattedMessage id='dashboard.myReservations' />
              </Link>
            </StockLinks>
          </Stock>
        </StocksContainer>
      </SectionBox>

      {/* {stickyNotificationsList && (
        <>
          {stickyNotificationsList
            .filter(n => n.readAt === null)
            .map((notification, index) => (
              <Dialog
                disableKeyboard
                onClose={handleDialogClose}
                open={index === currentDialogIndex}
              >
                <Container>
                  <DialogTitle>
                    <TypeIcon type={notification.type}>
                      {mapNotificationCodeToIcon(notification.code)}
                    </TypeIcon>
                    <DialogTitleText>{notification.subject}</DialogTitleText>
                  </DialogTitle>
                  <DialogContent></DialogContent>
                  <DialogContent>
                    <DialogSubtitle>Subtitle</DialogSubtitle>
                    {notification.body}
                  </DialogContent>
                  <DialogActions>
                    {notification.cta && (
                      <CTAButton
                        active={true}
                        onClick={() => {
                          window.location.href
                        }}
                      >
                        Pobierz fakturę
                      </CTAButton>
                    )}

                    <SaveOfferButton active={true} onClick={handleDialogClose}>
                      Zamknij
                    </SaveOfferButton>
                  </DialogActions>
                </Container>
              </Dialog>
            ))}
        </>
      )} */}
    </Page>
  )
}

const ConnectedComponent = connect(Dashboard)

export default styled(ConnectedComponent)`
  ${SectionBox} {
    padding: 1rem;
    ${breakpoint.m`
      padding: 2rem;
  `}
  }
`

const SliderWrapper = styled.div`
  width: 100%;
  backdrop-filter: blur(5px);
  background-color: ${({ theme }) => theme.colors.lightGrey.hex()};
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
  padding: 1rem 0;
  margin-bottom: 10px;
  box-sizing: border-box;
  ${Row} {
    align-items: baseline;
  }
  ${breakpoint.m`
    max-height: 310px;
    padding: 2rem 0;
  `}
`

const Title = styled.h3`
  display: inline;
  font-size: 1.125rem;
  line-height: 1.78;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
`

const Title2 = styled.h4`
  display: inline;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
`

export const Subtitle = styled.span`
  margin-left: 0.5rem;
  font-size: 0.75rem;
  line-height: 2.67;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  opacity: 0.7;
`

const StocksContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Stock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 96px;
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  border-bottom: 2px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  padding: 0.75rem 1.5rem;
  &:first-of-type {
    margin-top: 0.75rem;
  }
  ${breakpoint.m`
    position: static;
  `}
`
const StockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 700;
`
const StockDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DescriptionText = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
`

const StockOffersCounter = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey.hex()};
  border-radius: 16px;
  padding: 0rem 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  font-size: 0.75rem;
  line-height: 2;
  font-weight: 700;
  display: none;
  ${breakpoint.m`
    display: block;
    padding: 0.3125rem 1rem;
  `}
`

const StockOffersCounterMobile = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey.hex()};
  border-radius: 16px;
  padding: 0rem 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  font-size: 0.75rem;
  line-height: 2;
  font-weight: 700;
  display: block;
  ${breakpoint.m`
    display: none;
  `}
`

const StockLinks = styled.div`
  margin-top: 0.3rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 0.75rem;
  font-weight: 400;
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const Dot = styled.span`
  margin: 0 1rem;
`

const NameWrapper = styled(Row)`
  box-sizing: border-box;
  padding: 0 1rem;
  ${breakpoint.m`
    padding: 0 2rem;
  `}
`
const MobileStoreWrapper = styled.div`
  display: flex;
  max-height: 60px;
  flex-direction: row;
  align-items: center;
  padding-top: 0.95rem;
  // ${breakpoint.sm`
  //   flex-direction: row;
  // `}
`

const MobileStoreBadge = styled.img`
  display: flex;
  // height: 42px;
  width: 60px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  ${breakpoint.sm`
    // margin-left: 0.5rem;
    margin-right: 0.5rem;
    // margin-bottom: 3.0rem;
  `}
`
const MobileAppSectionBox = styled(SectionBox)`
  padding: 0;
  margin: 0;
`

// const BestWishes2022Image = styled.img`
//   display: flex;
//   horizontal-align: center;
//   height: 170px;
//   width: auto;
//   object-fit: cover;
//   ${breakpoint.sm`
//   width: 100%;
//   object-fit: cover;
//   `}
// `

export const Container = styled.div`
  min-width: 400px;
  padding: 1.5rem 1rem 0rem 1rem;
  & > div {
    margin: 0.7rem 0;
  }

  ${breakpoint.m`
        padding: 1.5rem 2rem 0rem 2rem;
    `}
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: start;
`

const DialogTitleText = styled.h3`
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  color: ${({ theme }) => theme.colors.black.hex()};
`
export const DialogContent = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;

  & ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
  & ol {
    padding-left: 2rem;
  }
  & a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    &:hover {
      color: ${({ theme }) => theme.colors.easternBlue.darken().hex()};
    }
  }
`

export const DialogSubtitle = styled.h5`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`

export const TermsPreview = styled(DialogContent)`
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 300px;
  background-color: #f8f8f9;
  border-radius: 4px;
  padding: 0.5rem;
`

const DialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  ${({ withReason }) =>
    withReason &&
    css`
      && {
        margin: 0 0 0.7rem 0;
      }
    `}
`

const TypeIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 0.75rem;
  background-color: ${({ theme, type }) =>
    type !== 'info'
      ? theme.colors.fadedRed.alpha(0.2).hex()
      : theme.colors.easternBlue.alpha(0.2).hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const CTAButton = styled(SaveOfferButton)`
  border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
  color: ${({ theme }) => theme.colors.apple.hex()};
  background-color: ${({ theme }) => theme.colors.white.hex()};
`
