import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { AddOfferButton } from 'src/components/atoms/Buttons'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { Avatar } from 'src/components/atoms/Avatar'
import { DateTime, Location, Row } from 'src/features/stocks/components/atoms'
import { Status } from 'src/features/myOffers/components/StatusIcon'
import { ReservationStatus } from 'src/features/myOffers/components/ReservationStatus'
import GavelUrl from 'src/assets/icons/gavel.svg'
import TrophyIcon from 'src/assets/icons/trophy.svg'
import { Link, useIntl } from 'gatsby-plugin-intl'
import Alert from 'src/components/Alert'
import React from 'react'
import { TrackingIconWithBackground } from 'src/features/stocks/components/OfferDetails/atoms'
import ActionsMenu from 'src/features/stocks/components/ActionsMenu'

export const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
  ${AddOfferButton} {
    margin-left: 1rem;
  }
  ${Checkbox} {
    & span {
      font-size: 0.875rem;
      margin-right: 0.5rem;
    }
  }
`
export const Selected = styled(Checkbox)`
  & span {
    height: auto;
  }
  & span:before {
    width: 14px;
    height: 14px;
    border-width: 1px;
  }
  & span:after {
    width: 10px;
    height: 10px;
  }
  display: none;
  ${breakpoint.m`
     grid-area: select;
     display: flex
  `}
`
export const CreatedAt = styled.div`
  grid-area: date;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  overflow: visible !important;
  ${breakpoint.m`
    justify-content: space-between;
  `}
  & svg {
    display: none;
    ${breakpoint.m`
     display: inline;
     flex-shrink: 0;
  `}
  }
  ${ActionsMenu} {
    ${breakpoint.m`
      display: none;
   `}
    & svg {
      display: flex;
    }
  }
`

export const OfferNumber = styled.div`
  cursor: pointer;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  grid-area: index;
  display: flex;
  align-items: center;
  ${Status}, ${ReservationStatus} {
    display: flex;
    ${breakpoint.m`
     display: none;
  `}
  }
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    text-decoration: none;
  }
`

export const ReservationStatusIcon = styled.div`
  display: none;
  ${breakpoint.m`
     display: block;
  `}
`

export const LocationFrom = styled(Location)`
  grid-area: locationFrom;
`

export const LocationTo = styled(Location)`
  grid-area: locationTo;
`

export const Container = styled.div`
  grid-area: container;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  ${breakpoint.m`
  font-size: inherit;
  `}
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    ${breakpoint.m`
     display: none;
  `}
  }
  > div {
    overflow: hidden;
  }
`

export const Handler = styled.div`
  grid-area: handler;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  ${Avatar} {
    margin-right: 1rem;
    display: none;
    ${breakpoint.m`
     display: inline-block;
  `}
  }
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    ${breakpoint.m`
     display: none;
  `}
  }
`

export const Offeror = styled(Handler)`
  grid-area: offeror;
  align-items: center;
  & > div {
  }
  & > svg {
    color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    opacity: 1;
    margin-left: auto;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    ${breakpoint.m`
     display: inline-block;
     margin-left: 0.5rem;
  `}
  }
`

export const Shipowner = styled.div`
  grid-area: shipowner;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  ${breakpoint.m`
  font-size: inherit;
  `}
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    ${breakpoint.m`
     display: none;
  `}
  }
  > div {
    overflow: hidden;
  }
`

export const Reservations = styled.div`
  display: none;
  cursor: ${({ hasReservations }) => (hasReservations ? 'pointer' : 'normal')};
  font-size: 0.75rem;
  line-height: 2;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;

  & > div {
    display: flex;
    align-items: center;
  }

  ${breakpoint.m`
     background-color: inherit;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     grid-column: auto;
     span {
      display: none;
     }
  `}
`

export const Currency = styled.b`
  font-size: 0.625rem;
  line-height: 2.4;
  color: rgba(51, 51, 51, 0.702);
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 0.1rem;
`

export const ReservationsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  font-size: 0.75rem;
  padding: 7px 0;
  position: relative;
  span {
    margin-right: 0.3rem;
  }
  margin-bottom: 4px;
  box-shadow: 0 6px 10px rgba(36, 45, 63, 0.15);
  ${({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: none;
      margin-bottom: 0;
    `}
  svg {
    color: ${({ theme, isOpen }) =>
      isOpen
        ? theme.colors.apple.hex()
        : theme.colors.ebonyClay.alpha(0.8).hex()};
  }
  ${breakpoint.m`
     display: none;
  `}
`

export const AuctionsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  font-size: 0.75rem;
  padding: 7px 0;
  position: relative;
  font-weight: 600;
  span {
    text-transform: uppercase;
    margin: 0 0.5rem;
    b {
      text-transform: none;
    }
  }

  svg {
    color: ${({ theme, isOpen }) =>
      isOpen
        ? theme.colors.apple.hex()
        : theme.colors.ebonyClay.alpha(0.8).hex()};
  }
  margin-bottom: 4px;
  box-shadow: 0 6px 10px rgba(36, 45, 63, 0.15);
  ${({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: none;
      margin-bottom: 0;
    `}

  ${breakpoint.m`
     display: none;
  `}
`

export const Name = styled.div`
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const OfferorName = styled(Name)``

export const Actions = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  grid-column: 3;
  grid-row: 1/4;
  justify-self: flex-end;
  align-self: center;
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: left 0.2s ease;
  transition-delay: 0.2s;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  && {
    padding: 0;
  }
  & svg {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }
  ${breakpoint.m`
    display: flex;
    transition-delay: 0s;
    grid-column: -2/-1;
    grid-row: 1/1;
    padding: inherit;
    & svg:hover {
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
  `}
`

export const PhoneNumber = styled.a`
  font-size: 0.75rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  text-transform: lowercase;
`

export const Action = styled.div`
  flex: 1;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white.hex()};
  ${breakpoint.m`
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
    background-color: inherit;
   `}
  ${({ loading }) =>
    loading &&
    css`
      ${breakpoint.m`
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.4).hex()};
    pointer-events: none;
   `}
    `}
`

export const PinCode = styled(Action)`
  background-color: ${({ theme }) => theme.colors.bluewood.hex()};
`

export const Copy = styled(Action)`
  background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

export const Delete = styled(Action)`
  background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
`

export const ChangeHandler = styled(Action)`
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};

  svg:first-child {
    display: none;
  }
  ${breakpoint.m`
  svg:first-child {
    display: block;
  }
  svg:last-child {
    display: none;
  }
  `}
`

export const Refresh = styled(Action)`
  background-color: ${({ theme }) => theme.colors.frenchGray.hex()};
`

export const MakePublic = styled(Action)`
  background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

export const TableRow = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  position: relative;
  font-size: 0.875rem;
  margin-top: 4px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container shipowner handler handler';

  ${breakpoint.m`
    overflow: hidden;
    border-bottom: 1px solid ${({ theme }) =>
      theme.colors.ghost.alpha(0.3).hex()};
     margin-top: 0;
     grid-template-rows: none;
     grid-template-columns: minmax(35px, 0.8fr) 2fr 2.3fr 3fr 3fr 2fr 2fr 3fr 2fr;
     grid-template-areas:
    'select index reservations locationFrom locationTo container shipowner handler date'
  `}

  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    ${breakpoint.m`
    align-self: center;
  `}
    &:first-of-type {
      padding-left: 10px;
    }
  }

  &:hover {
    z-index: 1;
    box-shadow: none;
    position: relative;
    & > ${DateTime} {
      display: none;
    }
    ${breakpoint.m`
     grid-template-columns: minmax(35px, 0.8fr) 2fr 2.3fr 3fr 3fr 2fr 2fr 2fr 3fr;
     ${Actions} {
      width: 100%;
      left: 0;
    }
     `}
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGreen.hex()};
      border-left: 4px solid ${({ theme }) => theme.colors.apple.hex()};
      margin-left: -4px;
      position: relative;
      ${Actions} {
        background-color: ${({ theme }) => theme.colors.lightGreen.hex()};
      }
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 1;
      margin-top: 7px !important;
      border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
      border-bottom: none;
      margin-left: -4px;
      position: relative;
      box-shadow: none;
      ${breakpoint.m`
      border-bottom: none;
         box-shadow: 0 2px 20px 0 ${({ theme }) =>
           theme.colors.ebonyClay.alpha(0.1).hex()};
     `}
    `}

  & > ${Status} {
  display: none;
      ${breakpoint.m`
     display: flex;
     `}
  }
`

export const FreeCarriersTableRow = styled(TableRow)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container container handler handler';

  ${breakpoint.m`
     margin-bottom: 0;
     grid-template-rows: none;
     grid-template-columns: 1fr 2fr 2fr 3fr 3fr 2fr 3fr 2fr;
     grid-template-areas:
    'select index reservations locationFrom locationTo container handler date'
  `}
  &:hover {
    ${breakpoint.m`
     grid-template-columns: 1fr 2fr 2fr 3fr 3fr 2fr 2fr 3fr;
     `}
  }
`

export const HeaderItem = styled.div`
  & div {
    display: block;
  }
  &&& {
    ${({ sort }) =>
      !sort &&
      css`
        cursor: default;
        &:hover {
          border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
        }
      `}
  }
`
export const TableHeader = styled(TableRow)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  position: static;
  &:hover {
    position: static;
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
    ${breakpoint.m`
     grid-template-columns: minmax(35px, 0.8fr) 2fr 2.3fr 3fr 3fr 2fr 2fr 3fr 2fr;
    `}
  }
  ${breakpoint.m`
     display: grid;
  `}
  > ${HeaderItem} {
    position: relative;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
    &:hover {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    }
  }
  ${({ sticky, headerWidth }) =>
    sticky &&
    css`
      position: fixed;
      top: 0;
      width: ${headerWidth}px;
    `}
`

export const FreeCarrierTableHeader = styled(TableHeader)`
  && {
    grid-template-columns: 1fr 2fr 2fr 3fr 3fr 2fr 3fr 2fr;
  }
  &:hover {
    ${breakpoint.m`
     grid-template-columns: 1fr 2fr 2fr 3fr 3fr 2fr 3fr 2fr;
    `}
  }
`

export const ReservationDetails = styled(TableRow)`
  margin-top: 0!important;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(7, 1fr);
  grid-template-areas:
    'index company company company company actions actions'
    '. offeror offeror offeror offeror offeror offeror';
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  && {
    box-shadow: 0 6px 10px rgba(36, 45, 63, 0.15);
  }
  z-index: 1;
  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: 7px;
    `}
  & ${Offeror} > ${Avatar} {
    display: inline-block;
  }
  & ${Offeror} > ${PhoneNumber} {
    display: none;
    ${breakpoint.m`
    display: inline;
    `}
  }
  ${breakpoint.m`
    grid-template-columns: min-content 1.5fr 0 0 repeat(2, 1fr) repeat(2, 20%);
    grid-template-areas: 'index company company company offeror offeror offeror actions actions';
    `}
    &:hover {
    ${breakpoint.m`
    grid-template-columns: min-content 1.5fr 0 0 repeat(2, 1fr) repeat(2, 20%);
     `}
  }
  ${({ botttomOfferor }) =>
    botttomOfferor &&
    css`
      ${breakpoint.m`
        &  #offeror-name-row {
          flex: 1;
          }
        & #offeror-contact-row {
          justify-content: flex-end;
        }      
      grid-template-rows: 1fr 1fr;
      grid-template-columns: min-content repeat(6, 1fr);
      grid-template-areas:
    'index company company company actions actions actions'
    '. offeror offeror offeror offeror offeror offeror';
    
    `}
      &:hover {
        ${breakpoint.m`
      grid-template-rows: 1fr 1fr;
      grid-template-columns: min-content repeat(6, 1fr);
      grid-template-areas:
    'index company company company actions actions actions'
    '. offeror offeror offeror offeror offeror offeror';
     `}
      }
    `}
  
`

export const AuctionDetails = styled(TableRow)`
  grid-template-rows: 1fr auto;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'price deadline'
    'offeror offeror';
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  align-items: end;
  && {
    box-shadow: 0 6px 6px rgba(36, 45, 63, 0.15);
  }
  z-index: 1;
  margin-top: 0 !important;
  & ${Offeror} > ${Avatar} {
    display: inline-block;
  }
  & ${Offeror} > ${PhoneNumber} {
    display: none;
    ${breakpoint.m`
    display: inline;
    `}
  }

  & ${DateTime} {
    text-align: left;
  }
  ${breakpoint.m`
  align-items: center;
  margin-top: 0!important;
  grid-template-rows: 1fr;
  grid-template-columns: min-content minmax(200px, min-content) repeat(4, auto);
  grid-template-areas: 'icon price deadline offeror offeror offerorsCounter';
      `}

  &:hover {
    ${breakpoint.m`
  grid-template-columns: min-content minmax(200px, min-content) repeat(4, auto);
     `}
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      margin-bottom: 7px !important;
    `}
`

export const DeleteMultiple = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  width: 100%;
  height: 48px;
  position: absolute;
  top: -52px;
  padding: 1rem 0 1rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 18px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
    position: absolute;
    top: 40px;
  }
  & > span {
    color: ${({ theme }) => theme.colors.white.hex()};
    font-size: 0.875rem;
    & b {
      font-weight: 800;
    }
  }
`
export const Padding = styled.div`
  padding: 0 1.2rem;
`

export const ListTopRow = styled(Row)`
  padding: 0 1.2rem;
  ${breakpoint.m`
     padding: 0;
  `}
`

export const ActionButtons = styled.div`
  grid-area: actions;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
`
export const OfferLink = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
export const Index = styled.div`
  grid-area: index;
`
export const Company = styled(Location)`
  grid-area: company;
  display: flex;
  & svg {
    opacity: 0.7;
    margin-right: 0.5rem;
  }
  & a:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`
export const MoreReservations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  font-size: 0.75rem;
  padding: 0.5rem 0;
  box-shadow: 0px 6px 10px rgba(36, 45, 63, 0.15);
  border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin-left: -4px;
  margin-bottom: 7px;
  position: relative;
  z-index: 1;
  position: relative;
  svg {
    opacity: 0.7;
  }
`

export const AuctionIcon = styled.div`
  grid-area: icon;
  display: none;
  ${breakpoint.m`
  display: flex;
  `}
`

export const AuctionPrice = styled.div`
  grid-area: price;
  min-width: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 1rem;
  &::after {
    position: absolute;
    right: 0;
    content: '';
    border-right: 1px solid ${({ theme }) => theme.colors.iron.hex()};
    display: block;
    width: 1px;
    height: calc(100% - 20px);
  }
  ${breakpoint.m`
  &::after {
    content: none;
  }
  `}
`

export const OfferorsCounter = styled.div`
  grid-area: offerorsCounter;
  display: none;
  align-items: center;
  justify-content: flex-end;
  & > div {
    display: flex;
    align-items: center;
  }
  ${breakpoint.m`
  display: flex;
  `}
`
export const AuctionGavelIcon = styled(GavelUrl)`
  display: inline;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.15;
  width: 40px;
  height: 40px;
`

export const AuctionTrophyIcon = styled(TrophyIcon)`
  display: inline;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.15;
  width: 40px;
  height: 40px;
`
export const AuctionPriceTitle = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  opacity: 0.7;
  text-transform: uppercase;
`
export const AuctionPriceValue = styled.div`
  font-size: 1.125rem;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  text-transform: capitalize;
  & span {
    font-weight: 400;
    font-size: 0.75rem;
    margin-left: 0.2rem;
  }
`

export const SmallGreenGavelIcon = styled(GavelUrl)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.apple.hex()};
`
export const AuctioneersCounter = styled.b`
  font-size: 0.75rem;
  color: rgba(51, 51, 51, 0.6);
  font-weight: 400;
`

const OfferHasTrackingInfoComponent = ({ className }) => {
  const intl = useIntl()
  return (
    <div className={className}>
      <Alert
        vertical
        title={intl.formatMessage({ id: 'myOffers.trackingTurnedOn' })}
        icon={<TrackingIconWithBackground small />}
        type='wildSand'
        message={intl.formatMessage({
          id: 'offerDetails.trackingIsRequired'
        })}
      />
    </div>
  )
}

export const OfferHasTrackingInfo = styled(OfferHasTrackingInfoComponent)`
  border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin-left: -4px;
  background-color: #f8f8f9;
  box-shadow: 0 6px 10px rgba(36, 45, 63, 0.15);
  position: relative;
`
export const CounterGroup = styled.div`
  display: flex;
`
