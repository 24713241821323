import React from 'react'
import styled from 'styled-components'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { breakpoint } from 'src/theme/grid'

const NotificationSetting = ({
  className,
  settings,
  updateNotificationSetting
}) => {
  const handleNotificationSettingChange = key => {
    updateNotificationSetting(settings.update(key, v => !v))
  }

  return (
    <div className={className}>
      <span>{settings.label}</span>
      <CheckboxGroup>
        <Item>
          <CheckboxLabel>WWW</CheckboxLabel>
          <Checkbox
            value={settings.databaseChannel}
            option={{ value: true, text: '' }}
            onChange={() => handleNotificationSettingChange('databaseChannel')}
          />
        </Item>

        <Item>
          <CheckboxLabel>E-mail</CheckboxLabel>
          <Checkbox
            value={settings.mailChannel}
            option={{ value: true, text: '' }}
            onChange={() => handleNotificationSettingChange('mailChannel')}
          />
        </Item>

        <Item>
          <CheckboxLabel>Mobile</CheckboxLabel>
          <Checkbox
            value={settings.mobilePushChannel}
            option={{ value: true, text: '' }}
            onChange={() =>
              handleNotificationSettingChange('mobilePushChannel')
            }
          />
        </Item>
      </CheckboxGroup>
    </div>
  )
}

export default styled(NotificationSetting)`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  min-height: 52px;
  ${breakpoint.m`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
  ${Checkbox} {
    width: auto;
    & > span {
      margin-right: 0;
    }
  }
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 0.4rem;
  margin-top: 0.6rem;
  margin-bottom: 1.2rem;
  ${breakpoint.m`
    width: 180px;
    flex-direction: row;
    margin-top: 0;
  `}
`

const CheckboxLabel = styled.span`
  display: inline-block;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  ${breakpoint.m`
    display: none;
  `}
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${breakpoint.m`
    width: auto;
  `}
`
