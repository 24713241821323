import React, { useState, useContext } from 'react'
import { compose } from 'recompose'

import styled from 'styled-components'
import { head, equals } from 'ramda'
import { connectExchangeCounters } from 'src/ducks/connectors'
import PanelNavigation from 'src/components/PanelNavigation'
import routes from 'src/utils/routes'
import useSwitchActiveTab from 'src/hooks/useSwitchActiveTab'
import { HeaderContext } from 'src/components/header/HeaderLoggedIn'

import StockCargoExport from 'src/features/stocks/StockCargoExport'
import StockCargoImport from 'src/features/stocks/StockCargoImport'
import StockPostImport from 'src/features/stocks/StockPostImport'
import StockVehicles from 'src/features/stocks/StockFreeCarriers'
import OfferDetailsExport from 'src/features/offers/OfferDetailsExport'
import OfferDetailsImport from 'src/features/offers/OfferDetailsImport'
import OfferDetailsPostImport from 'src/features/offers/OfferDetailsPostImport'
import OfferFormImport from 'src/features/offers/OfferFormImport'
import OfferFormExport from 'src/features/offers/OfferFormExport'
import OfferFormFreeCarriers from 'src/features/offers/OfferFormFreeCarriers'
import OfferFormPostImport from 'src/features/offers/OfferFormPostImport'
import OfferFormContract from 'src/features/offers/OfferFormContracts'
import Orders from 'src/features/orders/Orders'
import MyExportOffers from 'src/features/myOffers/MyExportOffers'
import MyImportOffers from 'src/features/myOffers/MyImportOffers'

import Dashboard from 'src/features/dashboard/DashboardContent'
import PrivateRoute from 'src/components/PrivateRoute'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import MyPostImportOffers from 'src/features/myOffers/MyPostImportOffers'
import MyFreeCarrierOffers from 'src/features/myOffers/MyFreeCarrierOffers'
import MyExportReservations from 'src/features/reservations/MyExportReservations'
import MyImportReservations from 'src/features/reservations/MyImportReservations'
import MyPostImportReservations from 'src/features/reservations/MyPostImportReservations'
import MyFreeCarrierReservations from 'src/features/reservations/MyFreeCarrierReservations'
import Companies from 'src/features/companies/CompaniesSearch'
import use100vh from 'src/hooks/use100vh'
import Company from 'src/features/companies/CompanyPublicDetails'
import DebtCollectionForm from 'src/features/debtCollection/DebtCollectionForm'
import OrderTrackingList from 'src/features/tracking/TrackingList'
import TrackingEdit from 'src/features/tracking/TrackingEdit'

import OrderTrackingItem from 'src/features/tracking/TrackingDetails'
import OrderTrackingHistory from 'src/features/tracking/TrackingHistory'
import ContractList from 'src/features/contracts/ContractList'
import MyContractOffers from 'src/features/contracts/MyContractOffers'
import MyContractReservations from 'src/features/contracts/MyContractReservations'
import OfferDetailsFreeCarriers from 'src/features/offers/OfferDetailsFreeCarriers'
import OfferDetailsContract from 'src/features/offers/OfferDetailsContract'
import AddOrderForm from 'src/features/orders/AddOrderForm'
import OrderDetails from 'src/features/orders/OrderDetails'
import InvoicesList from 'src/features/invoices/InvoicesList'
import AddInvoice from 'src/features/invoices/AddInvoice'
import AddNote from 'src/features/invoices/AddNote'
import NoteDetails from 'src/features/invoices/NoteDetails'
import InvoiceDetails from 'src/features/invoices/InvoiceDetails'
import CorrectiveInvoiceDetails from 'src/features/invoices/CorrectiveInvoiceDetails'
import AddCorrectiveInvoice from 'src/features/invoices/AddCorrectiveInvoice'
import { MENU_ITEMS, MENU_LIST } from './duck/consts'
import connect from './duck/connect'

const DashboardPage = ({ className, location, exchangeCounters }) => {
  const ref = use100vh()
  const permissions = useContext(PermissionsContext)
  const [opened, setOpened] = useState(false)
  const [activeTab, setActiveTab] = useSwitchActiveTab(
    head(MENU_LIST).value,
    location.search
  )
  const isPageActive = equals(activeTab)

  return (
    <div className={className} ref={ref}>
      <PanelNavigation
        activeTab={activeTab}
        menu={MENU_LIST}
        setActiveTab={value => setActiveTab(value)}
        url={routes.APP_DASHBOARD}
        opened={opened}
        onClose={() => setOpened(false)}
        {...exchangeCounters.toJS()}
      />
      <HeaderContext.Provider
        value={{ onBurgerAction: () => setOpened(!opened) }}
      >
        <Content>
          {isPageActive(MENU_ITEMS.DASHBOARD) && (
            <PrivateRoute hasAccess component={Dashboard} />
          )}
          {isPageActive(MENU_ITEMS.MY_EXPORT_OFFERS) && (
            <PrivateRoute hasAccess component={MyExportOffers} />
          )}
          {isPageActive(MENU_ITEMS.MY_IMPORT_OFFERS) && (
            <PrivateRoute hasAccess component={MyImportOffers} />
          )}
          {isPageActive(MENU_ITEMS.MY_POST_IMPORT_OFFERS) && (
            <PrivateRoute hasAccess component={MyPostImportOffers} />
          )}
          {isPageActive(MENU_ITEMS.MY_FREE_CARRIERS_OFFERS) && (
            <PrivateRoute hasAccess component={MyFreeCarrierOffers} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_EXPORT) && (
            <PrivateRoute hasAccess component={OfferDetailsExport} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_IMPORT) && (
            <PrivateRoute hasAccess component={OfferDetailsImport} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_POST_IMPORT) && (
            <PrivateRoute hasAccess component={OfferDetailsPostImport} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_FREE_CARRIERS) && (
            <PrivateRoute hasAccess component={OfferDetailsFreeCarriers} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_CONTRACT) && (
            <PrivateRoute hasAccess component={OfferDetailsContract} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_FORM_IMPORT) && (
            <PrivateRoute hasAccess component={OfferFormImport} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_FORM_EXPORT) && (
            <PrivateRoute hasAccess component={OfferFormExport} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_FORM_POST_IMPORT) && (
            <PrivateRoute hasAccess component={OfferFormPostImport} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_FORM_FREE_CARRIERS) && (
            <PrivateRoute hasAccess component={OfferFormFreeCarriers} />
          )}
          {isPageActive(MENU_ITEMS.OFFER_FORM_CONTRACTS) && (
            <PrivateRoute hasAccess component={OfferFormContract} />
          )}
          {isPageActive(MENU_ITEMS.MY_EXPORT_RESERVATIONS) && (
            <PrivateRoute hasAccess component={MyExportReservations} />
          )}
          {isPageActive(MENU_ITEMS.MY_IMPORT_RESERVATIONS) && (
            <PrivateRoute hasAccess component={MyImportReservations} />
          )}
          {isPageActive(MENU_ITEMS.MY_POST_IMPORT_RESERVATIONS) && (
            <PrivateRoute hasAccess component={MyPostImportReservations} />
          )}
          {isPageActive(MENU_ITEMS.MY_FREE_CARRIERS_RESERVATIONS) && (
            <PrivateRoute hasAccess component={MyFreeCarrierReservations} />
          )}
          {isPageActive(MENU_ITEMS.ORDERS) && (
            <PrivateRoute hasAccess component={Orders} />
          )}
          {isPageActive(MENU_ITEMS.ORDER_ADD) && (
            <PrivateRoute hasAccess component={AddOrderForm} />
          )}
          {isPageActive(MENU_ITEMS.ORDER_EDIT) && (
            <PrivateRoute hasAccess component={AddOrderForm} />
          )}
          {isPageActive(MENU_ITEMS.ORDER_DETAILS) && (
            <PrivateRoute hasAccess component={OrderDetails} />
          )}
          {isPageActive(MENU_ITEMS.INVOICES) && (
            <PrivateRoute hasAccess component={InvoicesList} />
          )}
          {isPageActive(MENU_ITEMS.INVOICES_ADD) && (
            <PrivateRoute hasAccess component={AddInvoice} />
          )}
          {isPageActive(MENU_ITEMS.INVOICE_ADD_NOTE) && (
            <PrivateRoute hasAccess component={AddNote} />
          )}
          {isPageActive(MENU_ITEMS.INVOICE_NOTE_DETAILS) && (
            <PrivateRoute hasAccess component={NoteDetails} />
          )}
          {isPageActive(MENU_ITEMS.INVOICE_DETAILS) && (
            <PrivateRoute hasAccess component={InvoiceDetails} />
          )}
          {isPageActive(MENU_ITEMS.CORRECTIVE_INVOICE_DETAILS) && (
            <PrivateRoute hasAccess component={CorrectiveInvoiceDetails} />
          )}
          {isPageActive(MENU_ITEMS.CORRECTIVE_INVOICE_ADD) && (
            <PrivateRoute hasAccess component={AddCorrectiveInvoice} />
          )}
          {isPageActive(MENU_ITEMS.COMPANIES) && (
            <PrivateRoute hasAccess component={Companies} />
          )}
          {isPageActive(MENU_ITEMS.COMPANY) && (
            <PrivateRoute hasAccess component={Company} />
          )}
          {isPageActive(MENU_ITEMS.STOCKS_CARGO_EXPORT) && (
            <PrivateRoute
              hasAccess={hasPermissionTo(
                PERMISSIONS.STOCK_EXPORT_ACCESS,
                permissions
              )}
              component={StockCargoExport}
            />
          )}
          {isPageActive(MENU_ITEMS.STOCKS_CARGO_IMPORT) && (
            <PrivateRoute
              hasAccess={hasPermissionTo(
                PERMISSIONS.STOCK_IMPORT_ACCESS,
                permissions
              )}
              component={StockCargoImport}
            />
          )}
          {isPageActive(MENU_ITEMS.STOCKS_POST_IMPORT) && (
            <PrivateRoute
              hasAccess={hasPermissionTo(
                PERMISSIONS.STOCK_POST_IMPORT_ACCESS,
                permissions
              )}
              component={StockPostImport}
            />
          )}
          {isPageActive(MENU_ITEMS.STOCKS_FREE_CARRIERS) && (
            <PrivateRoute
              hasAccess={hasPermissionTo(
                PERMISSIONS.STOCK_FREE_CARRIERS_ACCESS,
                permissions
              )}
              component={StockVehicles}
            />
          )}
          {isPageActive(MENU_ITEMS.CONTRACTS) && (
            <PrivateRoute
              hasAccess={hasPermissionTo(
                PERMISSIONS.CONTRACTS_ACCESS,
                permissions
              )}
              component={ContractList}
            />
          )}
          {isPageActive(MENU_ITEMS.CONTRACTS_OFFERS) && (
            <PrivateRoute
              hasAccess={hasPermissionTo(
                PERMISSIONS.CONTRACTS_ACCESS,
                permissions
              )}
              component={MyContractOffers}
            />
          )}
          {isPageActive(MENU_ITEMS.CONTRACTS_RESERVATIONS) && (
            <PrivateRoute
              hasAccess={hasPermissionTo(
                PERMISSIONS.CONTRACTS_ACCESS,
                permissions
              )}
              component={MyContractReservations}
            />
          )}
          {isPageActive(MENU_ITEMS.DEBT_COLLECTION) && (
            <PrivateRoute hasAccess component={DebtCollectionForm} />
          )}
          {isPageActive(MENU_ITEMS.TRACKING) && (
            <PrivateRoute hasAccess component={OrderTrackingList} />
          )}
          {isPageActive(MENU_ITEMS.TRACKING_DETAILS) && (
            <PrivateRoute hasAccess component={OrderTrackingItem} />
          )}
          {isPageActive(MENU_ITEMS.TRACKING_HISTORY) && (
            <PrivateRoute hasAccess component={OrderTrackingHistory} />
          )}
          {isPageActive(MENU_ITEMS.TRACKING_EDIT) && (
            <PrivateRoute hasAccess component={TrackingEdit} />
          )}
        </Content>
      </HeaderContext.Provider>
    </div>
  )
}

const ConnectedComponent = compose(
  connect,
  connectExchangeCounters
)(DashboardPage)

export default styled(ConnectedComponent)`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: auto;
`
