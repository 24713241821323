import styled from 'styled-components'
import MUIAddIcon from '@material-ui/icons/Add'
import { Location } from 'src/features/stocks/components/atoms'

export const Description = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  margin-top: 0.1rem;
  span {
    color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  }
  img {
    margin-bottom: -4px;
    margin-left: 1rem;
  }
`
export const Bold = styled.p`
  font-weight: 600;
  font-size: 0.875rem;
`
export const Date = styled(Location)`
  font-weight: 400;
  font-size: 0.875rem;
`
export const PhoneNumbers = styled(Description)`
  margin-top: 12px;
`
export const AdditionalInfo = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
`
export const Title = styled.h5`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  text-transform: uppercase;
  font-weight: 400;
`
export const AddIcon = styled(MUIAddIcon)`
  font-size: 1.2rem;
  margin-left: 0.8rem;
`
