import React from 'react'
import styled from 'styled-components'

const Euro = styled.div`
  color: ${({ theme }) => theme.colors.apple.hex()};
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  line-height: 32px;
  width: 69px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
  white-space: nowrap;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
`

export default ({ euroClass }) => (
  <Euro>{`EURO ${euroClass === 7 ? 'Alt' : euroClass}`}</Euro>
)
