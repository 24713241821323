import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useIntl, Link } from 'gatsby-plugin-intl'
import moment from 'moment'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { compose } from 'recompose'
import { breakpoint } from 'src/theme/grid'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { scrollToRef } from 'src/utils/scroll'
import { Page } from 'src/components/Wrappers'
import BreadCrumbs from 'src/components/BreadCrumbs'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import LocationInput from 'src/components/filters/LocationInput'
import Alert from 'src/components/Alert'
import routes from 'src/utils/routes'
import { connectDictionaries } from 'src/ducks/connectors'
import Checkbox from 'src/components/atoms/CheckboxInput'
import Select from 'src/components/filters/SelectInput'
import TextInput from 'src/components/atoms/TextInput'
import TextInputMasked from 'src/components/atoms/TextInputMasked'
import DatePicker from 'src/components/atoms/DatePicker'
import {
  OFFER_TYPES,
  OFFER_TYPES_OPTIONS,
  OFFER_VISIBILITY_TYPES,
  OFFER_VISIBILITY_TYPES_OPTIONS
} from 'src/features/offers/duck/consts'
import { BOOLEAN, LOCATION_TYPES, API_STATES } from 'src/ducks/consts'
import { EURO_OPTIONS } from 'src/features/stocks/ducks/consts'
import IconWithTooltip from 'src/components/IconWithTooltip'
import CreateOfferMenu from './components/CreateOfferMenu'
import {
  Content,
  FormSection,
  FormInnerWrapper,
  FormSectionSpacer,
  FormActionBar,
  InputsRowWrapper,
  InputWrapper,
  PlaceInputWrapper,
  DateInputWrapper,
  TimeInputWrapper,
  DictionaryInputWrapper,
  CheckboxInputWrapper,
  Padding,
  TemplateNameInputWrapper,
  TemplatesButton,
  ActionGroup,
  HideOnMobile,
  RadioWrapper,
  WarningWrapper,
  AuctionTimeInputWrapper,
  PriceInputWrapper,
  AuctionNotesWrapper
} from './components/atoms'
import CheckboxSection from './components/CheckboxSection'
import Drafts from './components/Drafts'
import { FormNavigationHeader } from './components/typography'
import connect from './duck/connectors'
import {
  validateOfferFreeCarriers,
  validateOfferFreeCarriersSchema
} from './duck/schema'
import NewForeignLocationsAddedAlert from 'src/features/offers/components/NewForeignLocationsAddedAlert'
import PermissionsContext, {
  PERMISSIONS,
  hasPermissionTo
} from 'src/utils/permissions'
import PrivateOfferCreateOptions from 'src/features/offers/components/PrivateOfferCreateOptions'
import { EXCHANGE_API_URL_PREFIXES } from 'src/ducks/consts'

const OfferFormFreeCarriers = ({
  className,
  offerFormFreeCarriers,
  setOfferFormFreeCarriersValue,
  clearFreeCarriersOfferForm,
  fetchLocations,
  availabilityDictionary,
  destinationDictionary,
  dictionaries,
  getFreeCarriersMyCompanyLastOffers,
  getFreeCarriersOfferTemplates,
  lastOffersFreeCarriers,
  offerTemplatesFreeCarriers,
  fillFormUsingTemplateFreeCarriers,
  removeTemplateFreeCarriers,

  createFreeCarriersOffer,
  createBulkFreeCarriersOffers,
  createAndDuplicateFreeCarriersOffer,
  createAndStartNewFreeCarriersOffer
}) => {
  const intl = useIntl()
  const templateRef = useRef(null)
  const formContentRef = useRef(null)

  const permissions = useContext(PermissionsContext)
  const [visibilityType, setVisibilityType] = useState(
    OFFER_VISIBILITY_TYPES.PUBLIC
  )

  const [goPublicAtDateTime, setGoPublicAtDateTime] = useState(null)

  const [templatesOpened, setTemplatesOpened] = useState(false)
  const valid = useValidateSchema(
    offerFormFreeCarriers.toJS(),
    validateOfferFreeCarriersSchema
  )

  const handleGoPublicAtChange = value => {
    setGoPublicAtDateTime(value)
    setOfferFormFreeCarriersValue('goPublicAt', value)
  }

  useEffect(() => {
    getFreeCarriersMyCompanyLastOffers()
    getFreeCarriersOfferTemplates()
    return clearFreeCarriersOfferForm
  }, [])

  const isAuction = offerFormFreeCarriers.type === OFFER_TYPES.AUCTION
  const handleVisibilityType = (name, value) => {
    setVisibilityType(value)
    // setOfferFormFreeCarrierValue(name, value)
  }

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <FormNavigationHeader>
              {intl.formatMessage({ id: 'forms.freeCarriersFormTitle' })}
            </FormNavigationHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Content>
        <FormSection>
          <FormInnerWrapper ref={formContentRef}>
            <Padding>
              <BreadCrumbs
                links={[
                  {
                    name: intl.formatMessage({ id: 'forms.dashboard' }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                  },
                  {
                    name: intl.formatMessage({
                      id: 'forms.exchangeFreeCarriersList'
                    }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_FREE_CARRIERS}`
                  },
                  {
                    name: intl.formatMessage({ id: 'forms.addOffer' }),
                    url: null
                  }
                ]}
              />
              <TemplatesButton onClick={() => setTemplatesOpened(true)}>
                {intl.formatMessage({ id: 'forms.copyOrUseTemplate' })}
              </TemplatesButton>
              <NewForeignLocationsAddedAlert />
              <FormSectionSpacer>
                <RadioWrapper>
                  <div>{intl.formatMessage({ id: 'forms.offerType' })}</div>
                  {OFFER_TYPES_OPTIONS.map(option => ({
                    ...option,
                    text: intl.formatMessage({ id: option.text })
                  })).map(opt => (
                    <Checkbox
                      key={opt.value}
                      name='type'
                      value={offerFormFreeCarriers.type}
                      onChange={setOfferFormFreeCarriersValue}
                      type='radio'
                      option={opt}
                    />
                  ))}
                </RadioWrapper>
              </FormSectionSpacer>
              {isAuction && (
                <>
                  <WarningWrapper>
                    <Alert
                      message={intl.formatMessage({
                        id: 'forms.auctionWarning'
                      })}
                      type='info'
                    />
                  </WarningWrapper>
                  <InputsRowWrapper>
                    <DateInputWrapper>
                      <DatePicker
                        label={intl.formatMessage({
                          id: 'forms.auctionEndDate'
                        })}
                        disablePast
                        required
                        name='auctionDeadlineDate'
                        placeholder='dd.mm.rrrr'
                        value={offerFormFreeCarriers.auctionDeadlineDate}
                        onChange={setOfferFormFreeCarriersValue}
                        maxDate={moment().add(6, 'days')}
                      />
                    </DateInputWrapper>
                    <AuctionTimeInputWrapper>
                      <TextInputMasked
                        mask='99:99'
                        label={intl.formatMessage({
                          id: 'forms.auctionEndTime'
                        })}
                        required
                        id='auctionDeadlineTime'
                        name='auctionDeadlineTime'
                        placeholder='gg:mm'
                        value={offerFormFreeCarriers.auctionDeadlineTime}
                        onChange={setOfferFormFreeCarriersValue}
                        validate={validateOfferFreeCarriers}
                        type='tel'
                        calendarTime
                      />
                    </AuctionTimeInputWrapper>
                    <PriceInputWrapper>
                      <TextInput
                        label={intl.formatMessage({
                          id: 'forms.auctionBasePrice'
                        })}
                        id='price'
                        name='price'
                        required
                        placeholder=''
                        value={offerFormFreeCarriers.price}
                        onChange={setOfferFormFreeCarriersValue}
                        validate={validateOfferFreeCarriers}
                        type='currency'
                        onCurrencyChange={setOfferFormFreeCarriersValue}
                        currencyValue={offerFormFreeCarriers.currency}
                      />
                    </PriceInputWrapper>
                    <Checkbox
                      name='isPriceVisible'
                      value={offerFormFreeCarriers.isPriceVisible}
                      option={{
                        value: BOOLEAN.YES,
                        text: intl.formatMessage({
                          id: 'forms.isPriceVisible'
                        })
                      }}
                      onChange={name => {
                        setOfferFormFreeCarriersValue(
                          name,
                          !offerFormFreeCarriers.isPriceVisible
                        )
                      }}
                    />
                    <AuctionNotesWrapper>
                      <TextInput
                        label={intl.formatMessage({
                          id: 'forms.auctionNotes'
                        })}
                        id='notes'
                        name='notes'
                        multiline
                        rows={3}
                        placeholder={intl.formatMessage({
                          id: 'forms.auctionNotesPlaceholder'
                        })}
                        value={offerFormFreeCarriers.notes}
                        onChange={setOfferFormFreeCarriersValue}
                        validate={validateOfferFreeCarriers}
                      />
                    </AuctionNotesWrapper>
                  </InputsRowWrapper>
                </>
              )}
              {hasPermissionTo(
                PERMISSIONS.STOCK_PRIVATE_ADD_OFFER,
                permissions
              ) && (
                <FormSectionSpacer>
                  <RadioWrapper>
                    <div>
                      {intl.formatMessage({ id: 'offersFilters.visibility' })}
                    </div>
                    {OFFER_VISIBILITY_TYPES_OPTIONS.map(option => ({
                      ...option,
                      text: intl.formatMessage({ id: option.text })
                    })).map(opt => (
                      <Checkbox
                        key={opt.value}
                        name='visibility'
                        value={offerFormFreeCarriers.visibility}
                        onChange={setOfferFormFreeCarriersValue}
                        type='radio'
                        option={opt}
                      />
                    ))}
                  </RadioWrapper>
                </FormSectionSpacer>
              )}
              {/* {JSON.stringify(offerFormFreeCarrier)} */}
              {offerFormFreeCarriers.visibility ==
                OFFER_VISIBILITY_TYPES.PRIVATE && (
                <PrivateOfferCreateOptions
                  exchangeType={EXCHANGE_API_URL_PREFIXES.FREE_CARRIERS}
                  onGoPublicDateTimeChange={setOfferFormFreeCarriersValue}
                />
              )}

              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.tracking' })}
                <IconWithTooltip
                  openTime={3000}
                  title={intl.formatMessage({
                    id: 'forms.trackingHelperCarrier'
                  })}
                />
              </FormSectionSpacer>
              <Checkbox
                name='doesAllowTracking'
                value={offerFormFreeCarriers.doesAllowTracking}
                option={{
                  value: BOOLEAN.YES,
                  text: intl.formatMessage({
                    id: 'forms.doesRequireTrackingCarrier'
                  })
                }}
                onChange={name => {
                  setOfferFormFreeCarriersValue(
                    name,
                    !offerFormFreeCarriers.doesAllowTracking
                  )
                }}
              />
              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.availabilitySectionName' })}
              </FormSectionSpacer>
              <InputsRowWrapper>
                <PlaceInputWrapper>
                  <LocationInput
                    required
                    id='availabilityLocationId'
                    label={intl.formatMessage({
                      id: 'forms.availabilityFreeCarriersPlaceInputLabel'
                    })}
                    locationName='availabilityLocationId'
                    locationValue={offerFormFreeCarriers.availabilityLocationId}
                    locationPlaceholder={intl.formatMessage({
                      id: 'forms.availabilityFreeCarriersPlaceInputLabel'
                    })}
                    onChange={(name, value) =>
                      setOfferFormFreeCarriersValue(name, value)
                    }
                    placeholder={intl.formatMessage({
                      id: 'forms.locationPlaceholder'
                    })}
                    locationTypes={LOCATION_TYPES.TERMINAL}
                    dictionary={availabilityDictionary}
                    fetchLocations={fetchLocations}
                    searchHintText={intl.formatMessage({
                      id: 'offersFilters.searchHintText'
                    })}
                  />
                </PlaceInputWrapper>
                <DateInputWrapper>
                  <DatePicker
                    label={intl.formatMessage({
                      id: 'forms.availabilityDateInputLabel'
                    })}
                    disablePast
                    required
                    name='availabilityDate'
                    placeholder='dd.mm.rrrr'
                    value={offerFormFreeCarriers.availabilityDate}
                    onChange={setOfferFormFreeCarriersValue}
                  />
                </DateInputWrapper>
                <TimeInputWrapper>
                  <TextInputMasked
                    mask='99:99'
                    label={intl.formatMessage({
                      id: 'forms.availabilityTimeInputLabel'
                    })}
                    id='availabilityTime'
                    name='availabilityTime'
                    placeholder='gg:mm'
                    value={offerFormFreeCarriers.availabilityTime}
                    onChange={setOfferFormFreeCarriersValue}
                    validate={validateOfferFreeCarriers}
                    type='tel'
                    calendarTime
                  />
                </TimeInputWrapper>
              </InputsRowWrapper>
              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.destinationSectionName' })}
              </FormSectionSpacer>
              <InputsRowWrapper>
                <PlaceInputWrapper>
                  <LocationInput
                    id='destinationLocationId'
                    label={intl.formatMessage({
                      id: 'forms.destinationFreeCarriersPlaceInputLabel'
                    })}
                    locationName='destinationLocationId'
                    locationValue={offerFormFreeCarriers.destinationLocationId}
                    locationPlaceholder={intl.formatMessage({
                      id: 'forms.destinationFreeCarriersPlaceInputLabel'
                    })}
                    onChange={(name, value) =>
                      setOfferFormFreeCarriersValue(name, value)
                    }
                    placeholder={intl.formatMessage({
                      id: 'forms.locationPlaceholder'
                    })}
                    locationTypes={LOCATION_TYPES.CITY}
                    dictionary={destinationDictionary}
                    fetchLocations={fetchLocations}
                    searchHintText={intl.formatMessage({
                      id: 'offersFilters.searchHintText'
                    })}
                  />
                </PlaceInputWrapper>
              </InputsRowWrapper>
              <FormSectionSpacer>
                {intl.formatMessage({
                  id: 'forms.freeCarriersContainerSectionName'
                })}
              </FormSectionSpacer>
              <InputsRowWrapper>
                <DictionaryInputWrapper>
                  <Select
                    required
                    id='containerSemiTrailerId'
                    name='containerSemiTrailerId'
                    label={intl.formatMessage({
                      id: 'forms.containerSemiTrailer'
                    })}
                    value={offerFormFreeCarriers.containerSemiTrailerId}
                    options={dictionaries.containerSemiTrailers}
                    onChange={setOfferFormFreeCarriersValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                  />
                </DictionaryInputWrapper>
                <CheckboxInputWrapper>
                  <Checkbox
                    name='isSemiTrailerWithGenset'
                    value={offerFormFreeCarriers.isSemiTrailerWithGenset}
                    option={{
                      value: BOOLEAN.YES,
                      text: intl.formatMessage({
                        id: 'forms.freeCarriersGenset'
                      })
                    }}
                    onChange={name =>
                      setOfferFormFreeCarriersValue(
                        name,
                        !offerFormFreeCarriers.isSemiTrailerWithGenset
                      )
                    }
                  />
                </CheckboxInputWrapper>
              </InputsRowWrapper>
              <InputsRowWrapper>
                <DictionaryInputWrapper>
                  <Select
                    id='adrClassId'
                    name='adrClassId'
                    label={intl.formatMessage({ id: 'forms.adrClass' })}
                    value={offerFormFreeCarriers.adrClassId}
                    options={dictionaries.adrClasses}
                    onChange={setOfferFormFreeCarriersValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                    width='380px'
                  />
                </DictionaryInputWrapper>
                <DictionaryInputWrapper>
                  <Select
                    id='europeanEmissionStandard'
                    name='europeanEmissionStandard'
                    label={intl.formatMessage({
                      id: 'forms.europeanEmissionStandard'
                    })}
                    value={offerFormFreeCarriers.europeanEmissionStandard}
                    options={EURO_OPTIONS.map(option => ({
                      ...option,
                      label: intl.formatMessage({ id: option.label })
                    }))}
                    onChange={setOfferFormFreeCarriersValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                    width='230px'
                  />
                </DictionaryInputWrapper>
              </InputsRowWrapper>
              {!isAuction && (
                <>
                  <FormSectionSpacer>
                    {intl.formatMessage({ id: 'forms.additionalInformation' })}
                  </FormSectionSpacer>
                  <InputsRowWrapper>
                    <InputWrapper width={10}>
                      <TextInput
                        label={intl.formatMessage({
                          id: 'forms.additionalInformation'
                        })}
                        id='notes'
                        name='notes'
                        multiline
                        rows={3}
                        placeholder={intl.formatMessage({
                          id: 'forms.additionalInformationPlaceholder'
                        })}
                        value={offerFormFreeCarriers.notes}
                        onChange={setOfferFormFreeCarriersValue}
                        validate={validateOfferFreeCarriers}
                      />
                    </InputWrapper>
                  </InputsRowWrapper>
                  <FormSectionSpacer />
                </>
              )}
            </Padding>
            <CheckboxSectionStyled
              ref={templateRef}
              opened={offerFormFreeCarriers.templateNameOpened}
              setOpened={opened =>
                setOfferFormFreeCarriersValue('templateNameOpened', opened)
              }
              label={intl.formatMessage({
                id: 'forms.templateName'
              })}
              handleClose={() =>
                setOfferFormFreeCarriersValue('templateName', null)
              }
            >
              <TemplateNameInputWrapper>
                <TextInput
                  required
                  label={intl.formatMessage({
                    id: 'forms.templateNameLabel'
                  })}
                  id='templateName'
                  name='templateName'
                  placeholder={intl.formatMessage({
                    id: 'forms.templateNamePlaceholder'
                  })}
                  value={offerFormFreeCarriers.templateName}
                  onChange={setOfferFormFreeCarriersValue}
                  validate={validateOfferFreeCarriers}
                />
              </TemplateNameInputWrapper>
            </CheckboxSectionStyled>
          </FormInnerWrapper>
          <FormActionBar>
            <Link
              to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_FREE_CARRIERS}`}
            >
              {intl.formatMessage({ id: 'forms.cancel' })}
            </Link>
            <ActionGroup>
              <HideOnMobile>
                <Checkbox
                  name='templateNameOpened'
                  value={offerFormFreeCarriers.templateNameOpened}
                  option={{
                    value: BOOLEAN.YES,
                    text: intl.formatMessage({
                      id: 'forms.templateName'
                    })
                  }}
                  onChange={name => {
                    setOfferFormFreeCarriersValue(
                      name,
                      !offerFormFreeCarriers.templateNameOpened
                    )
                    scrollToRef(templateRef, formContentRef)
                  }}
                />
              </HideOnMobile>
              <CreateOfferMenu
                isLoading={
                  offerFormFreeCarriers.state === API_STATES.IN_PROGRESS
                }
                active={
                  valid &&
                  offerFormFreeCarriers.state !== API_STATES.IN_PROGRESS
                }
                createOffer={createFreeCarriersOffer}
                createBulkOffers={createBulkFreeCarriersOffers}
                createAndDuplicateOffer={createAndDuplicateFreeCarriersOffer}
                createAndStartNewOffer={createAndStartNewFreeCarriersOffer}
              >
                {intl.formatMessage({ id: 'forms.addOffer' })}
              </CreateOfferMenu>
            </ActionGroup>
          </FormActionBar>
        </FormSection>
        <Drafts
          fillFormUsingTemplate={fillFormUsingTemplateFreeCarriers}
          opened={templatesOpened}
          onClose={() => setTemplatesOpened(false)}
          lastOffers={lastOffersFreeCarriers}
          templates={offerTemplatesFreeCarriers}
          removeTemplate={removeTemplateFreeCarriers}
        />
      </Content>
    </Page>
  )
}

const ConnectedComponent = compose(
  connect,
  connectDictionaries
)(OfferFormFreeCarriers)

const CheckboxSectionStyled = styled(CheckboxSection)`
  ${breakpoint.m`
    margin-bottom: 330px;
  `}
`

export default styled(ConnectedComponent)``
