import React from 'react'
import { compose } from 'recompose'
import { useIntl } from 'gatsby-plugin-intl'
import PlaceAndDatesRangeBox from 'src/components/filters/PlaceAndDatesRangeBox'
import PlaceBox from 'src/components/filters/PlaceBox'
import Select from 'src/components/filters/SelectBox'
import SelectRemote from 'src/components/filters/SelectRemoteBox'
import {
  SearchFilter,
  SelectWrapper
} from 'src/components/filters/components/atoms'
import { connectDictionaries } from 'src/ducks/connectors'
import { connectFreeCarriers } from 'src/features/stocks/ducks/connectors'
import {
  EURO_OPTIONS,
  GENSET_OPTIONS,
  REFERENCE_NUMBER_PREFIX,
  RESERVATION_STATUS_OPTIONS,
  VISIBILITY_OPTIONS
} from 'src/features/stocks/ducks/consts'
import { LOCATION_TYPES } from 'src/ducks/consts'
import FiltersRow from './FiltersRow'
import PermissionsContext, {
  PERMISSIONS,
  hasPermissionTo
} from 'src/utils/permissions'

const FreeCarriersFilters = ({
  setFilter,
  setFilters,
  filters,
  dictionaries,
  availabilityDictionary,
  destinationDictionary,
  fetchLocations,
  companiesDictionary,
  fetchCompanies,
  activeFiltersNumber,
  clearFilters,
  createFilterTab,
  removeFilterTab,
  tabs,
  tabsData,
  activeTab,
  setActiveTab
}) => {
  const intl = useIntl()
  const permissions = React.useContext(PermissionsContext)

  return (
    <FiltersRow
      activeFiltersNumber={activeFiltersNumber}
      clearFilters={clearFilters}
      createFilterTab={createFilterTab}
      removeFilterTab={removeFilterTab}
      tabs={tabs}
      tabsData={tabsData}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      advancedFilters={
        <>
          <SelectWrapper>
            <Select
              removeSelectedOptions
              removeSearchInput
              id='filters-free-carriers-genset'
              name='genset'
              label={intl.formatMessage({ id: 'offersFilters.genset' })}
              value={filters.genset}
              options={GENSET_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
              type='radio'
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              height='440px'
              width='380px'
              id='filters-export-adr-classes'
              name='adrClass'
              label={intl.formatMessage({ id: 'offersFilters.adrClasses' })}
              value={filters.adrClass}
              options={dictionaries.adrClasses}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              removeSelectedOptions
              height='300px'
              width='230px'
              id='filters-export-euro'
              name='europeanEmissionStandard'
              label={intl.formatMessage({
                id: 'offersFilters.europeanEmissionStandard'
              })}
              value={filters.europeanEmissionStandard}
              options={EURO_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              removeSelectedOptions
              removeSearchInput
              height='250px'
              id='filters-export-status'
              name='status'
              label={intl.formatMessage({ id: 'offersFilters.status' })}
              value={filters.status}
              options={RESERVATION_STATUS_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
              type='radio'
            />
          </SelectWrapper>
          {hasPermissionTo(
            PERMISSIONS.STOCK_PRIVATE_OFFERS_VIEW,
            permissions
          ) && (
            <SelectWrapper>
              <Select
                removeSearchInput
                removeSelectedOptions
                height='250px'
                id='filters-free-carriers-visibility'
                name='visibility'
                label={intl.formatMessage({ id: 'offersFilters.visibility' })}
                value={filters.visibility}
                options={VISIBILITY_OPTIONS.map(option => ({
                  ...option,
                  label: intl.formatMessage({ id: option.label })
                }))}
                onChange={setFilter}
                placeholder={intl.formatMessage({
                  id: 'offersFilters.searchPlaceholder'
                })}
                type='radio'
              />
            </SelectWrapper>
          )}
        </>
      }
    >
      <SelectWrapper>
        <SearchFilter
          name='referenceNumber'
          id='input-search-free-carriers'
          initValue={REFERENCE_NUMBER_PREFIX.FREE_CARRIERS}
          filterValue={filters.referenceNumber}
          activeTab={activeTab}
          active={!!filters.referenceNumber}
          setFilter={setFilter}
          placeholder={intl.formatMessage({ id: 'common.offerNumber' })}
        />
      </SelectWrapper>
      <PlaceAndDatesRangeBox
        id='filters-free-carriers-availability'
        label={intl.formatMessage({ id: 'offersFilters.availability' })}
        onChange={setFilter}
        onChangeBulk={setFilters}
        locationName='availabilityLocation'
        locationValue={filters.availabilityLocation}
        dateFromName='availabilityDateFrom'
        dateFromValue={filters.availabilityDateFrom}
        dateFromLabel={intl.formatMessage({
          id: 'offersFilters.availabilityFrom'
        })}
        dateToName='availabilityDateTo'
        dateToValue={filters.availabilityDateTo}
        dateToLabel={intl.formatMessage({
          id: 'offersFilters.availabilityTo'
        })}
        locationTabName={intl.formatMessage({
          id: 'offersFilters.terminal'
        })}
        datesTabName={intl.formatMessage({
          id: 'offersFilters.date'
        })}
        placeholder={intl.formatMessage({
          id: 'offersFilters.locationPlaceholderTerminal'
        })}
        locationTypes={LOCATION_TYPES.TERMINAL}
        dictionary={availabilityDictionary}
        fetchLocations={fetchLocations}
        searchHintText={intl.formatMessage({
          id: 'offersFilters.searchHintText'
        })}
      />
      <PlaceBox
        id='filters-free-carriers-destination'
        label={intl.formatMessage({ id: 'offersFilters.destination' })}
        onChange={setFilter}
        locationName='destinationLocation'
        locationValue={filters.destinationLocation}
        placeholder={intl.formatMessage({
          id: 'offersFilters.locationPlaceholderPlace'
        })}
        locationTypes={LOCATION_TYPES.CITY}
        dictionary={destinationDictionary}
        fetchLocations={fetchLocations}
        searchHintText={intl.formatMessage({
          id: 'offersFilters.searchHintText'
        })}
      />
      <Select
        height='380px'
        id='filters-free-carriers-containerSemiTrailer'
        name='containerSemiTrailer'
        label={intl.formatMessage({ id: 'offersFilters.containerSemiTrailer' })}
        value={filters.containerSemiTrailer}
        options={dictionaries.containerSemiTrailers}
        onChange={setFilter}
        placeholder={intl.formatMessage({
          id: 'offersFilters.searchPlaceholder'
        })}
      />
      <SelectRemote
        id='filters-free-carriers-company'
        name='company'
        label={intl.formatMessage({ id: 'offersFilters.company' })}
        value={filters.company}
        options={[]}
        onChange={setFilter}
        placeholder={intl.formatMessage({
          id: 'offersFilters.searchPlaceholder'
        })}
        searchHintText={intl.formatMessage({
          id: 'offersFilters.searchHintText'
        })}
        dictionary={companiesDictionary}
        fetchCompanies={fetchCompanies}
      />
    </FiltersRow>
  )
}

export default compose(
  connectDictionaries,
  connectFreeCarriers
)(FreeCarriersFilters)
