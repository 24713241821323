import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useIntl, navigate } from 'gatsby-plugin-intl'
import useClickOutside from 'src/hooks/useClickOutside'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import routes from 'src/utils/routes'
import { breakpoint } from 'src/theme/grid'
import { MENU_ITEMS } from 'src/features/account/duck/consts'

import Loader from 'src/components/LinearLoader'

import {
  Title,
  NoNotificationsComponent,
  NoNotificationsTitle,
  NoNotificationsDescription,
  CogIcon,
  CloseIcon,
  MarkAsRead
} from './components/atoms'
import Notification from './components/Notification'
import { connectNotifications } from './duck/connectors'

const NotificationsList = ({
  className,
  notificationsListOpened,
  setNotificationsListOpened,
  notifications = [],
  getNotificationsList,
  markAllNotificationsAsSeen,
  markAllNotificationsAsRead,
  clearNotifications,
  markNotificationAsRead,
  isListLoading
}) => {
  const intl = useIntl()
  const componentRef = useClickOutside(() => setNotificationsListOpened(false))
  const scrollRef = useInfiniteScroll(() => {
    getNotificationsList(true) //flag true = append elements to the end of the list
  })

  useEffect(() => {
    if (notificationsListOpened) {
      getNotificationsList()
      markAllNotificationsAsSeen()
    }

    return clearNotifications
  }, [notificationsListOpened])

  return (
    <div className={className} ref={componentRef}>
      <CogIcon
        onClick={() => {
          navigate(`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.SETTINGS}`)
          setNotificationsListOpened(false)
        }}
      />
      <CloseIcon onClick={() => setNotificationsListOpened(false)} />
      <LoaderStyled loading={isListLoading} />
      <Title>{intl.formatMessage({ id: 'notifications.listTitle' })}</Title>

      {notifications.length === 0 && (
        <Padding>
          <NoNotificationsComponent />
          <NoNotificationsTitle>
            {intl.formatMessage({ id: 'notifications.noNotificationsTitle' })}
          </NoNotificationsTitle>
          <NoNotificationsDescription>
            {intl.formatMessage({
              id: 'notifications.noNotificationsDescription'
            })}
          </NoNotificationsDescription>
        </Padding>
      )}
      {notifications.length > 0 && (
        <>
          <MarkAsRead onClick={markAllNotificationsAsRead}>
            {intl.formatMessage({
              id: 'notifications.markAllNotificationsAsRead'
            })}
          </MarkAsRead>
          <NotificationsContainer ref={scrollRef}>
            {notifications.map((notification, key) => (
              <Notification
                key={key}
                notification={notification}
                marked={notification.marked}
                type={notification.type}
                onClick={() => markNotificationAsRead(notification.id)}
              />
            ))}
          </NotificationsContainer>
        </>
      )}
    </div>
  )
}

const ConnectedComponent = connectNotifications(NotificationsList)
export default styled(ConnectedComponent)`
  display: flex;
  flex-direction: column;
  position: fixed;
  box-sizing: border-box;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  width: 100%;
  height: 100%;
  ${breakpoint.s`
    height: calc(100vh - 60px);
    width: 400px;
  `}
`

const Padding = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.2rem;
`

const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-height: calc(100% - 80px);
  overflow-y: auto;
`
const LoaderStyled = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
`
