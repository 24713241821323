import React, { useState, useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { isNil, head, take } from 'ramda'
import { isFalsy, isEmptyString, isEmptyArray } from 'ramda-adjunct'
import { useIntl } from 'gatsby-plugin-intl'
import debounce from 'lodash.debounce'
import { breakpoint } from 'src/theme/grid'
import LocalStorageService from 'src/services/LocalStorageService'
import { createLastUsedLocalStorageKey } from 'src/utils/helpers'
import useClickOutside from 'src/hooks/useClickOutside'
import RequestNewLocationDialog from 'src/components/filters/RequestNewLocationDialog'
import { Button } from '../atoms/Buttons'
import {
  SelectInput,
  FilterDropdown,
  SearchInput,
  SearchInputWrapper,
  SelectOptionsWrapper,
  SelectRow,
  OptionsSectionTitle
} from './components/atoms'

const PlaceBox = ({
  className,
  id,
  label,
  locationName,
  locationValue,
  placeholder,
  onChange,
  locationTypes,
  dictionary,
  fetchLocations,
  required,
  locationPlaceholder,
  helperText,
  pristine,
  validate,
  searchHintText
}) => {
  const intl = useIntl()
  const [filterOpened, setFilterOpened] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [lastUsedOptions, setLastUsedOptions] = useState([])
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })
  const toggleDropdown = () => setFilterOpened(!filterOpened)
  const componentRef = useClickOutside(() => setFilterOpened(false))
  const emptyText = isEmptyString(searchText) || searchText.length < 2
  const isMultiple = () => Array.isArray(locationValue)
  const lastUsedLocalStorageKey = createLastUsedLocalStorageKey(
    dictionary.name,
    locationTypes
  )

  const debouncedSearch = useCallback(
    debounce(
      text =>
        fetchLocations({
          filter: {
            type: locationTypes,
            name: text
          },
          dictionaryName: dictionary.name,
          limit: dictionary.limit,
          page: dictionary.page
        }),
      200
    ),
    []
  )

  useEffect(() => {
    //search from 2 characters and for empty string to get full list after clear
    if (emptyText) return
    debouncedSearch(searchText)
  }, [searchText])

  useEffect(() => {
    const savedOptions = LocalStorageService.get(lastUsedLocalStorageKey)
    setLastUsedOptions(isNil(savedOptions) ? [] : JSON.parse(savedOptions))
  }, [])

  const saveLastUsedElement = option => {
    //only last 5 options is shown
    const optionsToSave = [option.toJS()].concat(take(4)(lastUsedOptions))
    LocalStorageService.set(
      lastUsedLocalStorageKey,
      JSON.stringify(optionsToSave)
    )
    setLastUsedOptions(optionsToSave)
  }

  //append options when location changes
  useEffect(() => {
    if (isFalsy(locationValue)) return

    fetchLocations({
      filter: {
        id: isMultiple() ? locationValue : [locationValue]
      },
      dictionaryName: dictionary.name,
      limit: dictionary.limit,
      page: dictionary.page,
      append: true
    })
  }, [locationValue])

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(locationName, locationValue, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [locationValue, inputPristine, pristine])

  const handleClose = () => {
    _setPristine(false)
    setFilterOpened(false)
  }

  const isLocationSelected = locationId =>
    isMultiple()
      ? locationValue.filter(obj => obj.id === locationId).length > 0
      : !isNil(locationValue) && locationValue === locationId

  const generateFilterName = () => {
    const selected = isMultiple() ? head(locationValue) : locationValue
    if (isFalsy(selected)) {
      return locationPlaceholder
    }

    const selectedOption = head(
      dictionary.values.filter(opt => opt.value === selected)
    )
    return !isFalsy(selectedOption) ? selectedOption.label : locationPlaceholder
  }

  const handleLocationChange = (optionValue, isThisValueSelected) => {
    const singleValue = () => (isThisValueSelected ? null : optionValue)
    const multipleValue = () =>
      isThisValueSelected
        ? locationValue.filter(obj => obj !== optionValue)
        : [...locationValue, optionValue]
    onChange(locationName, isMultiple() ? multipleValue() : singleValue())
    !isThisValueSelected && handleClose()
  }

  const handleClear = () => {
    onChange(locationName, isMultiple() ? [] : null)
    setSearchText('')
  }

  return (
    <div className={className} ref={componentRef}>
      <Label required={required}>{label}</Label>
      <SelectInput
        onClick={toggleDropdown}
        opened={filterOpened}
        hasValue={
          isMultiple() ? locationValue.length > 0 : !isNil(locationValue)
        }
        id={`filter-${id}`}
        selectedNumber={isMultiple() ? locationValue.length : null}
        onClear={handleClear}
      >
        {generateFilterName()}
      </SelectInput>
      {filterOpened && (
        <FilterDropdownStyled
          filterName={label}
          onClose={() => handleClose()}
          onClear={handleClear}
          clearButtonText={intl.formatMessage({ id: 'offersFilters.clear' })}
          confirmButtonText={intl.formatMessage({
            id: 'offersFilters.confirm'
          })}
        >
          <SearchInputWrapper>
            <SearchInput
              name={locationName}
              id={`input-${id}`}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder={placeholder}
            />
          </SearchInputWrapper>
          <SelectOptionsWrapperStyled>
            {emptyText && isEmptyArray(lastUsedOptions) && (
              <SearchHint>{searchHintText}</SearchHint>
            )}
            {emptyText && !isEmptyArray(lastUsedOptions) && (
              <>
                <OptionsSectionTitle>
                  {intl.formatMessage({ id: 'offersFilters.lastOptions' })}
                </OptionsSectionTitle>
                {lastUsedOptions.map((option, key) => {
                  const isSelected = isLocationSelected(option.value)
                  return (
                    <SelectRow
                      key={key}
                      selected={isSelected}
                      name={option.label}
                      onClick={() => {
                        handleLocationChange(option.value, isSelected)
                      }}
                    />
                  )
                })}
              </>
            )}
            {!emptyText &&
              dictionary.values.map(option => {
                const isSelected = isLocationSelected(option.value)
                return (
                  <SelectRow
                    selected={isSelected}
                    name={option.label}
                    onClick={() => {
                      handleLocationChange(option.value, isSelected)
                      !isSelected && saveLastUsedElement(option)
                    }}
                  />
                )
              })}
            {/* {!emptyText && isEmptyArray(dictionary.values) && (
                <>
                <SearchHint>Nie znaleziono lokalizacji. Zgłoś dodanie jej teraz.</SearchHint>
                <Button style={{textAlign: "center", margin: "auto"}} onClick={() => {alert('kliket')}}>Dodaj {searchText}</Button>
                <RequestNewLocationDialog open={false} />
               
                </>
              )} */}
          </SelectOptionsWrapperStyled>
        </FilterDropdownStyled>
      )}
      <HelperText valid={valid}>{valid ? helperText : error}</HelperText>
    </div>
  )
}

PlaceBox.defaultProps = {
  value: '',
  pristine: true,
  validate: () => true
}

export default styled(PlaceBox)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      div {
        opacity: 0.7;
      }
    `}
  ${FilterDropdown} {
    ${breakpoint.m`
      width: 320px;
      height: 400px;
    `}
  }
`
const SelectOptionsWrapperStyled = styled(SelectOptionsWrapper)`
  height: calc(100% - 40px);
`

const Label = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`

const FilterDropdownStyled = styled(FilterDropdown)`
  ${breakpoint.m`
    top: 70px;
  `}
`

const HelperText = styled.span`
  display: inline-block;
  width: 100%;
  margin: 8px 6px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1em;
  color: ${({ theme, valid }) =>
    valid ? theme.colors.ghost.hex() : theme.colors.error.hex()};
`

const SearchHint = styled.span`
  display: flex;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;
`
