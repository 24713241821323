import React from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { TitleContainer } from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'
import { breakpoint } from 'src/theme/grid'

export const Summary = ({ tax, subtotal, total, title }) => {
  const intl = useIntl()

  return (
    <SummaryBox>
      <TitleContainer>
        <Title>{title || <FormattedMessage id='invoices.summary' />}</Title>
      </TitleContainer>
      <SummaryRow
        title={intl.formatMessage({
          id: 'invoices.sumNetto'
        })}
        amount={subtotal.formatted}
      />
      <SummaryRow
        title={intl.formatMessage({
          id: 'invoices.tax'
        })}
        amount={tax.formatted}
      />
      <SummaryRow
        title={intl.formatMessage({
          id: 'invoices.sumBrutto'
        })}
        amount={total.formatted}
        total
      />
    </SummaryBox>
  )
}

const SummaryBox = styled.ul`
  background-color: #fff;
  border-radius: 2px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  flex: 0.5;
  margin-top: 1rem;
  ${breakpoint.m`
    margin-top: 0;
    padding: 1.5rem;
  `}
`
const SummaryTitle = styled.div`
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-size: 0.875rem;
`
const SummaryAmount = styled.div`
  ${({ total }) =>
    total &&
    css`
      font-weight: bold;
      font-size: 1.125rem;
    `}
`
const SummaryRowComponent = ({ title = '', amount = '', className, total }) => (
  <li className={className}>
    <SummaryTitle>{title}</SummaryTitle>
    <SummaryAmount total={total}>{amount}</SummaryAmount>
  </li>
)
const SummaryRow = styled(SummaryRowComponent)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 4px;
`
