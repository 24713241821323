import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isNil } from 'ramda'
import GoogleMaps from 'src/features/tracking/components/TrackingMap'
import TrackIconUrl from 'src/assets/icons/track.svg'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import withGoogleMapsHelpers from 'src/hocs/withGoogleMapsHelpers'
import Alert from 'src/components/Alert'
import { Description, Title } from 'src/features/stocks/components/typography'
import IconWithTooltip from 'src/components/IconWithTooltip'
import routes from 'src/utils/routes'
import CustomIcon from 'src/components/CustomIcon'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'

const TrackingCurrentInfoBoxWithMap = ({
  className,
  markers,
  status,
  delay,
  workTime,
  offerType,
  trackingId,
  originLocationLat,
  originLocationLong,
  destinationLocationLat,
  destinationLocationLong,
  getDistance,
  hideWorkTime
}) => {
  const [distance, setDistance] = useState(null)
  const [duration, setDuration] = useState(null)

  useEffect(() => {
    const arePointsDefined =
      originLocationLat &&
      originLocationLong &&
      destinationLocationLat &&
      destinationLocationLong
    if (arePointsDefined) {
      getDistance({
        origin: {
          lat: parseFloat(originLocationLat),
          lng: parseFloat(originLocationLong)
        },
        destination: {
          lat: parseFloat(destinationLocationLat),
          lng: parseFloat(destinationLocationLong)
        }
      }).then(result => {
        setDistance(result.distance)
        setDuration(result.duration)
      })
    }
  }, [
    originLocationLat,
    originLocationLong,
    destinationLocationLat,
    destinationLocationLong
  ])
  return (
    <div className={className}>
      <StatusContainer>
        <Alert
          icon={<TrackIcon />}
          type='white'
          title={<FormattedMessage id={`tracking.${status}`} />}
          message={<FormattedMessage id={`tracking.${offerType}`} />}
        />
        <MoreLink
          to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_HISTORY}&id=${trackingId}`}
        >
          <FormattedMessage id='common.more' />
        </MoreLink>
      </StatusContainer>
      <Separator />
      <Row>
        {!hideWorkTime && (
          <div>
            <Title>
              <FormattedMessage id='tracking.workTime' />
            </Title>
            <Description>
              {workTime || <FormattedMessage id='common.missing' />}
            </Description>
          </div>
        )}
        <div>
          <Title>
            <FormattedMessage id='tracking.delay' />
          </Title>
          <Delay>
            <CustomIcon name='wait' size={24} />{' '}
            {delay || <FormattedMessage id='common.missing' />}
          </Delay>
        </div>
      </Row>
      <GoogleMaps
        markers={markers}
        originLocationLat={originLocationLat}
        originLocationLong={originLocationLong}
        destinationLocationLat={destinationLocationLat}
        destinationLocationLong={destinationLocationLong}
      />
      <Row>
        <div>
          <Title>
            <FormattedMessage id='tracking.distance' />
          </Title>
          <Description>
            {!isNil(distance) ? (
              distance.text
            ) : (
              <FormattedMessage id='common.missing' />
            )}
          </Description>
        </div>
        <div>
          <Title>
            <FormattedMessage id='tracking.travelTime' />
          </Title>
          <Description>
            {!isNil(duration) ? (
              duration.text
            ) : (
              <FormattedMessage id='common.missing' />
            )}
          </Description>
        </div>
        <IconWithTooltip
          openTime={3000}
          title={<FormattedMessage id='tracking.noteAboutEstimatedRoute' />}
        />
      </Row>
    </div>
  )
}

const ConnectedComponent = compose(withGoogleMapsHelpers)(
  TrackingCurrentInfoBoxWithMap
)

export default styled(ConnectedComponent)`
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(36, 45, 63, 0.16);
  background-color: ${({ theme }) => theme.colors.white.hex()};
  padding: 1rem;
`

const TrackIconComponent = ({ className }) => (
  <div className={className}>
    <TrackIconUrl />
  </div>
)

const TrackIcon = styled(TrackIconComponent)`
  background-color: ${({ theme }) => theme.colors.california.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white.hex()};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const MoreLink = styled(Link)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  text-transform: uppercase;
`
const Row = styled.div`
  display: flex;
  margin: 1rem 0;
  &:last-child {
    margin-bottom: 0;
  }
  & > div {
    flex: 1;
  }
`

const Separator = styled.hr`
  opacity: 0.3;
  border-top: solid 1px ${({ theme }) => theme.colors.geyser.hex()};
`

const Delay = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
  font-size: 0.9rem;
  font-weight: 600;
`
