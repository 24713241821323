import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import { all, props, remove } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { HeaderItem, Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroup,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection
} from 'src/features/offers/components/atoms'
import { breakpoint, mediaQuery } from 'src/theme/grid'
import Drawer from 'src/features/stocks/components/Drawer'
import { connectDictionaries } from 'src/ducks/connectors'
import {
  BigInputContainer,
  CreateOfferButton,
  Heading,
  InputRow
} from 'src/features/orders/components/atoms'
import SettingsIcon from 'src/assets/icons/settings.svg'
import { connectAddInvoice } from 'src/features/invoices/duck/connectors'
import Checkbox from 'src/components/atoms/CheckboxInput'
import TextInput from 'src/components/atoms/TextInput'
import { API_STATES, BOOLEAN } from 'src/ducks/consts'
import { Icon, TitleContainer } from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import ItemRow from 'src/features/invoices/components/itemsTable/InvoiceItemRow'
import {
  AddRecipientForm,
  InvoiceItem
} from 'src/features/invoices/duck/records'
import { Summary } from 'src/features/invoices/components/SummaryBox'
import { ItemsTable } from 'src/features/invoices/components/itemsTable/ItemsTable'
import { InvoiceSettingsDialog } from 'src/features/invoices/components/InvoiceSettingsDialog'
import { PaymentsBox } from 'src/features/invoices/components/PaymentsBox'
import { AddRecipientDialog } from 'src/features/invoices/components/AddRecipientDialog'
import debounce from 'lodash.debounce'
import useValidateSchema from 'src/hooks/useValidateSchema'
import {
  validateAddInvoice,
  validateAddInvoiceRecalculateSchema,
  validateAddInvoiceRegenerateNumberSchema,
  validateAddInvoiceSchema
} from 'src/features/invoices/duck/schema'
import { AddInvoiceButtonComponent } from 'src/features/invoices/components/AddInvoiceItemButton'
import VisibilityIcon from 'src/assets/icons/visibility.svg'
import { SectionTopWeb } from 'src/features/invoices/components/TopSection/SectionTopWeb'
import { SectionTopMobile } from 'src/features/invoices/components/TopSection/SectionTopMobile'
import { useMedia } from 'react-use'
import { RecipientDetails } from 'src/features/invoices/components/TopSection/RecipientDetails'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import {
  AdditionalInfo,
  Center,
  CenteredTitle,
  HeaderName,
  IssuerContainer,
  OrderLink,
  PreviewButton,
  Settings,
  StyledCheckbox,
  SummaryContainer
} from 'src/features/invoices/styles'

const AddInvoice = ({
  className,
  settings,
  saveMyCompanyInvoiceSettings,
  showMyCompanyInvoiceSettings,
  invoicesIssuedLength,
  setInvoiceSettingsValue,
  prepareInvoice,
  invoice,
  setInvoiceEditFormValue,
  createInvoice,
  recalculateInvoice,
  regenerateInvoiceNumber,
  setInvoiceMultipleFormValues,
  clearRecipient,
  previewInvoice,
  clearAddInvoiceForm
}) => {
  const [invoiceSettingsModalOpen, setInvoiceSettingsModalOpen] = useState(
    false
  )
  const [addRecipientModalOpen, setAddRecipientModalOpen] = useState(false)

  const valid = useValidateSchema(invoice.toJS(), validateAddInvoiceSchema)
  const validForRecalculate = useValidateSchema(
    invoice.toJS(),
    validateAddInvoiceRecalculateSchema
  )
  const validForRegenerateNumber = useValidateSchema(
    invoice.toJS(),
    validateAddInvoiceRegenerateNumberSchema
  )

  useEffect(() => {
    !invoice.transportationOrderId && prepareInvoice()
    return clearAddInvoiceForm
  }, [])

  useEffect(() => {
    const allFieldsEmpty = all(isFalsy)(
      props(['issuePlace', 'paymentBankAccount', 'paymentBankName'], settings)
    )
    const isFirstInvoice = invoicesIssuedLength === 0
    const settingsLoaded = settings.state === API_STATES.DONE

    if (
      isFirstInvoice &&
      allFieldsEmpty &&
      settingsLoaded &&
      !invoiceSettingsModalOpen
    ) {
      setInvoiceSettingsModalOpen(true)
    }
  }, [invoicesIssuedLength, settings])

  const debouncedRecalculate = debounce(() => {
    validForRecalculate && recalculateInvoice()
  }, 1000)

  const debouncedRegenerateInvoiceNumber = useCallback(
    debounce(regenerateInvoiceNumber, 1000),
    []
  )

  const intl = useIntl()

  const isLoading = invoice.state === API_STATES.IN_PROGRESS

  const removeItem = index => {
    debouncedRecalculate()
    setInvoiceEditFormValue('items', remove(index, 1, invoice.items))
  }
  const addItem = () => {
    debouncedRecalculate()
    setInvoiceEditFormValue('items', [...invoice.items, new InvoiceItem()])
  }

  const onPostfixChange = (name, value) => {
    if (value[0] === '/') {
      setInvoiceEditFormValue(name, value)
    } else {
      setInvoiceEditFormValue(name, `/${value}`)
    }
  }

  useEffect(() => {
    invoice.numberingScheme &&
      validForRegenerateNumber &&
      debouncedRegenerateInvoiceNumber()
  }, [invoice.numberPostfix, invoice.numberingScheme, validForRegenerateNumber])

  const recipientAddedManually = !invoice.recipientCompanyId

  const handleAddRecipient = () => {
    setInvoiceMultipleFormValues({
      recipientCompanyAddress: invoice.addRecipientForm.recipientCompanyAddress,
      recipientCompanyAddressLine2:
        invoice.addRecipientForm.recipientCompanyAddressLine2,
      recipientCompanyCity: invoice.addRecipientForm.recipientCompanyCity,
      recipientCompanyCountryCode:
        invoice.addRecipientForm.recipientCompanyCountryCode,
      recipientCompanyEmail: invoice.addRecipientForm.recipientCompanyEmail,
      recipientCompanyId: null,
      recipientCompanyName: invoice.addRecipientForm.recipientCompanyName,
      recipientCompanyPostalCode:
        invoice.addRecipientForm.recipientCompanyPostalCode,
      recipientCompanyTaxId: invoice.addRecipientForm.recipientCompanyTaxId
    })
    setAddRecipientModalOpen(false)
    setTimeout(() => {
      setInvoiceMultipleFormValues({
        addRecipientForm: new AddRecipientForm()
      })
    }, 500)
  }

  const isWeb = useMedia(mediaQuery('m'))

  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                <FormattedMessage id='invoices.addInvoice' />
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          <FormSection>
            <FormInnerWrapper>
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'invoices.invoices' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`
                    },
                    {
                      name: intl.formatMessage({ id: 'invoices.addInvoice' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES_ADD}`
                    }
                  ]}
                />
                <TitleContainer>
                  <Heading>
                    <FormattedMessage id='invoices.vatInvoice' />{' '}
                    {invoice.number}
                    {invoice.transportationOrderId && (
                      <OrderLink>
                        {' '}
                        <FormattedMessage id='common.to' />{' '}
                        <Link
                          to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${invoice.transportationOrder.referenceNumber}`}
                        >
                          {invoice.transportationOrder.referenceNumber}
                        </Link>
                      </OrderLink>
                    )}
                  </Heading>
                  <Settings
                    onClick={() => {
                      setInvoiceSettingsModalOpen(true)
                    }}
                  >
                    <SettingsIcon />
                  </Settings>
                </TitleContainer>
                {isWeb && (
                  <SectionTopWeb
                    invoice={invoice}
                    setInvoiceEditFormValue={setInvoiceEditFormValue}
                    onPostfixChange={onPostfixChange}
                    recipientAddedManually={recipientAddedManually}
                    onClick={() => setAddRecipientModalOpen(true)}
                    recalculateInvoice={recalculateInvoice}
                    clearRecipient={clearRecipient}
                    setInvoiceMultipleFormValues={setInvoiceMultipleFormValues}
                  />
                )}
                {!isWeb && (
                  <SectionTopMobile
                    invoice={invoice}
                    setInvoiceEditFormValue={setInvoiceEditFormValue}
                    onPostfixChange={onPostfixChange}
                    recipientAddedManually={recipientAddedManually}
                    onClick={() => setAddRecipientModalOpen(true)}
                    recalculateInvoice={recalculateInvoice}
                    clearRecipient={clearRecipient}
                    setInvoiceMultipleFormValues={setInvoiceMultipleFormValues}
                  />
                )}
                {isWeb && <RecipientDetails invoice={invoice} />}
                <CenteredTitle>
                  <FormattedMessage id='invoices.invoiceItems' />
                </CenteredTitle>
                <ItemsTable
                  renderHeader={(header, index) => (
                    <HeaderItem sort={false} key={`${header.name} ${index}`}>
                      <EllipsisTooltip>
                        <HeaderName required={header.required}>
                          <FormattedMessage
                            id={`invoices.${header.name}`}
                            defaultMessage=' '
                          />
                        </HeaderName>
                        {header.sort && (
                          <Icon>
                            <ArrowIcon />
                          </Icon>
                        )}
                      </EllipsisTooltip>
                    </HeaderItem>
                  )}
                  items={invoice.items}
                  renderItem={(item, index) => (
                    <ItemRow
                      key={`${item.id}-${index}`}
                      item={item}
                      index={index}
                      invoicesLength={invoice.items.length}
                      setInvoiceEditFormValue={setInvoiceEditFormValue}
                      removeItem={removeItem}
                      recalculateInvoice={debouncedRecalculate}
                      invoice={invoice}
                    />
                  )}
                />
                <SummaryContainer>
                  <AddInvoiceButtonComponent onClick={addItem} />
                  <Summary
                    subtotal={invoice.subtotal}
                    tax={invoice.tax}
                    total={invoice.total}
                  />
                </SummaryContainer>
                <PaymentsBox
                  invoice={invoice}
                  validate={validateAddInvoice}
                  amountPaid={invoice.amountPaid}
                  onChange={setInvoiceEditFormValue}
                  paymentMethod={invoice.paymentMethod}
                  paymentBankAccount={invoice.paymentBankAccount}
                  paymentBankName={invoice.paymentBankName}
                  isSplitPayment={invoice.isSplitPayment}
                  setSplitPayment={name =>
                    setInvoiceEditFormValue(name, !invoice.isSplitPayment)
                  }
                  renderSaftOption={name => (
                    <Checkbox
                      key={name}
                      name={name}
                      value={invoice.saft[name]}
                      option={{
                        value: BOOLEAN.YES,
                        text: intl.formatMessage({
                          id: `invoices.saft.${name}`
                        })
                      }}
                      onChange={fieldName =>
                        setInvoiceEditFormValue(
                          ['saft', fieldName],
                          !invoice.saft[fieldName]
                        )
                      }
                    />
                  )}
                />
                <AdditionalInfo>
                  <TitleContainer>
                    <Title>
                      <FormattedMessage id='orders.additionalInfo' />
                    </Title>
                  </TitleContainer>
                  <InputRow>
                    <BigInputContainer>
                      <TextInput
                        type='text'
                        label={intl.formatMessage({
                          id: 'invoices.invoiceNotes'
                        })}
                        id='additionalInfo'
                        name='additionalInfo'
                        placeholder=''
                        value={invoice.additionalInfo}
                        onChange={setInvoiceEditFormValue}
                        multiline
                        validate={validateAddInvoice}
                      />
                    </BigInputContainer>
                    <IssuerContainer>
                      <TextInput
                        disabled
                        label={intl.formatMessage({
                          id: 'invoices.issuer'
                        })}
                        id='issuer'
                        name='issuer'
                        placeholder=''
                        value={`${invoice.issuer.firstName} ${invoice.issuer.lastName}`}
                        validate={validateAddInvoice}
                      />
                    </IssuerContainer>
                  </InputRow>
                </AdditionalInfo>
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Center>
                <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`}>
                  {intl.formatMessage({ id: 'forms.cancel' })}
                </Link>
                <ActionGroup>
                  <PreviewButton
                    active={valid && !isLoading}
                    onClick={previewInvoice}
                  >
                    <FormattedMessage id='invoices.preview' />
                    <VisibilityIcon />
                  </PreviewButton>
                  <CreateOfferButton
                    active={valid && !isLoading}
                    onClick={createInvoice}
                  >
                    <FormattedMessage id='invoices.addInvoice' />
                  </CreateOfferButton>
                </ActionGroup>
              </Center>
            </FormActionBar>
          </FormSection>
        </Content>
      </Page>
      <AddRecipientDialog
        open={addRecipientModalOpen}
        onClose={() => setAddRecipientModalOpen(false)}
        onClick={handleAddRecipient}
        onChange={setInvoiceEditFormValue}
        invoice={invoice}
      />
      <InvoiceSettingsDialog
        showMyCompanyInvoiceSettings={showMyCompanyInvoiceSettings}
        open={invoiceSettingsModalOpen}
        onClose={() => setInvoiceSettingsModalOpen(false)}
        renderNumberingSchemeOption={opt => (
          <StyledCheckbox
            key={opt.value}
            name='numberingScheme'
            value={settings.numberingScheme}
            selected={opt.value === settings.numberingScheme}
            onChange={setInvoiceSettingsValue}
            type='radio'
            option={{ ...opt, text: intl.formatMessage({ id: opt.text }) }}
          />
        )}
        numberingScheme={settings.numberingScheme}
        paymentBankAccount={settings.paymentBankAccount}
        onChange={setInvoiceSettingsValue}
        paymentBankName={settings.paymentBankName}
        issuePlace={settings.issuePlace}
        onClick={() =>
          saveMyCompanyInvoiceSettings({
            meta: { onSuccess: () => setInvoiceSettingsModalOpen(false) }
          })
        }
      />
    </>
  )
}

const ConnectedComponent = compose(
  connectAddInvoice,
  connectDictionaries
)(AddInvoice)

export default styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
    background-color: #f8f8f9;
  }
  ${Padding} {
    max-width: calc(887px + 2.8rem);
    margin: 0 auto;
  }
  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
`
