import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'

export const CreateOfferButton = styled.button`
  font-size: 1rem;
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  overflow: visible;
  padding: 0 1.2rem;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.mercury.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  pointer-events: none;
  position: relative;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      cursor: pointer;
      color: white;
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      box-shadow: 0 2px 10px 0 rgba(71, 170, 66, 0.25);
    `}
`
export const OfferTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  & svg {
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  }
`
export const OfferType = styled.p`
  font-size: 0.875rem;
  margin-left: 0.5rem;
`
export const Heading = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`
export const HeadingContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
`
export const OfferLink = styled(Link)`
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
export const InputRow = styled.div`
  display: flex;
  & > div:not(:last-child) {
    ${breakpoint.m`
     margin-right: 0.5rem;
  `}
  }
  flex-wrap: wrap;
  flex-direction: column;
  ${breakpoint.m`
    flex-direction: row;
  `}
  ${({ optionalField }) =>
    optionalField &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.alpha(0.05).hex()};
      padding: 1rem 1rem 0.5rem;
    `}
`
export const BigInputContainer = styled.div`
  margin-bottom: 1rem;
  flex: 1 0 50%;
  min-width: 100px;
  ${breakpoint.m`
  max-width: 50%;
  `}
`
export const SmallInputContainer = styled.div`
  flex: 1 0 21%;
  min-width: 100px;

  ${breakpoint.m`
   max-width: 25%;
  `}
`

export const VerySmallInputContainer = styled.div`
  flex: 1 0 10%;
  min-width: 100px;

  ${breakpoint.m`
   max-width: 10%;
  `}
`

export const Subtitle = styled.h5`
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 0.75rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
`
export const OrderSubtitle = styled.h5`
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 0.75rem;
  font-weight: normal;
  margin-bottom: 0.75rem;
`
const PropertyComponent = ({ className, title = '', children }) => (
  <div className={className}>
    <PropertyTitle>{title}</PropertyTitle>
    <PropertyValue>{children}</PropertyValue>
  </div>
)
const PropertyTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
  &::first-letter {
    text-transform: uppercase;
  }
`
const PropertyValue = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  font-size: 0.875rem;
  line-height: 1.71;
`
export const Property = styled(PropertyComponent)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
`
export const AlertContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`
export const GeneratePdf = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  cursor: pointer;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    transition: color 0.3s;
  }
`
