import React from 'react'
import { navigate } from 'gatsby-plugin-intl'
import moment from 'moment'
import {
  DateTime,
  Ellipsis,
  Location
} from 'src/features/stocks/components/atoms'

import { TableRow } from 'src/features/contracts/components/atoms'
import styled, { css } from 'styled-components'
import { Action, Actions } from 'src/features/myOffers/components/atoms'
import PdfIcon from 'src/assets/icons/pdf.svg'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import {
  INVOICES_RECEIVED_TABLE_NAMES,
  INVOICES_RECEIVED_TABLE_WIDTHS,
  INVOICES_STATES
} from 'src/features/invoices/duck/consts'
import Wait from 'src/assets/icons/wait.svg'
import MobileInvoiceRow from 'src/features/invoices/components/MobileInvoiceRow'
import InvoiceStatus from 'src/features/invoices/components/InvoiceStatus'
import { getInvoiceStatus } from 'src/features/invoices/duck/helpers'

const InvoiceRow = ({
  invoice,
  downloadInvoicePdf,
  downloadCorrectiveInvoicePdf
}) => {
  const status = getInvoiceStatus(invoice)

  const isOverdue =
    status !== INVOICES_STATES.FULLY_PAID &&
    moment(invoice.dueDate).isBefore(moment(), 'day')

  const invoiceDetailsLink = invoice.isCorrectiveInvoice
    ? `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CORRECTIVE_INVOICE_DETAILS}&id=${invoice.id}`
    : `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.id}`

  const downloadPdf = invoice.isCorrectiveInvoice
    ? downloadCorrectiveInvoicePdf
    : downloadInvoicePdf

  const hasCorrectiveInvoice = !!invoice.lastCorrectiveInvoice.id

  return (
    <>
      <TableRow
        widths={INVOICES_RECEIVED_TABLE_WIDTHS}
        columnNames={INVOICES_RECEIVED_TABLE_NAMES}
      >
        <div>
          <OfferLink onClick={() => navigate(invoiceDetailsLink)}>
            {invoice.number}
          </OfferLink>
          {invoice.isCorrectiveInvoice && (
            <Small>
              do{' '}
              <OfferLink
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.invoice.id}`
                  )
                }
              >
                {invoice.invoice.number}
              </OfferLink>
            </Small>
          )}
          {hasCorrectiveInvoice && (
            <Small>
              kor.{' '}
              <OfferLink
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CORRECTIVE_INVOICE_DETAILS}&id=${invoice.lastCorrectiveInvoice.id}`
                  )
                }
              >
                {invoice.lastCorrectiveInvoice.number}
              </OfferLink>
            </Small>
          )}
        </div>
        <div>
          <Ellipsis>{invoice.issuerCompanyName}</Ellipsis>
        </div>
        <div>
          <Ellipsis>
            {invoice.transportationOrder.referenceNumber ? (
              <OfferLink
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${invoice.transportationOrder.referenceNumber}`
                  )
                }
              >
                {invoice.transportationOrder.referenceNumber}
              </OfferLink>
            ) : (
              '-'
            )}
          </Ellipsis>
        </div>
        <Location>
          <Ellipsis>{invoice.total.formatted}</Ellipsis>
          <DateTime>
            <InvoiceStatus status={status} />
          </DateTime>
        </Location>
        <Date>{invoice.issueDate} </Date>
        <Date overdue={isOverdue}>{invoice.dueDate}</Date>
        <Actions>
          <Action
            onClick={() => {
              downloadPdf(invoice.id)
            }}
          >
            <PdfIcon />
          </Action>
        </Actions>
      </TableRow>
      <MobileInvoiceRow
        downloadInvoicePdf={downloadPdf}
        invoice={invoice}
        overdue={isOverdue}
      />
    </>
  )
}

export default InvoiceRow

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const Small = styled.div`
  font-size: 0.75rem;
  & > a {
    font-size: 0.75rem;
  }
`

const WaitIcon = styled(Wait)`
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
  margin: 0 0.25rem;
  height: 12px;
  width: 12px;
`

const DateComponent = ({ overdue, className, children }) => (
  <div className={className}>
    {children}
    {overdue && <WaitIcon />}
  </div>
)

const Date = styled(DateComponent)`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  ${({ overdue }) =>
    overdue &&
    css`
      color: ${({ theme }) => theme.colors.fadedRed.hex()};
    `}
`
