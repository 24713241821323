import React from 'react'
import {
  ActionButtons,
  AuctionPriceValue,
  Company,
  Index,
  ReservationDetails as StyledReservationDetails
} from 'src/features/myOffers/components/atoms'
import { DateTime, Ellipsis } from 'src/features/stocks/components/atoms'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { AcceptButton } from 'src/components/atoms/Buttons'
import { API_STATES } from 'src/ducks/consts'
import Offeror from 'src/features/myOffers/components/Offeror'
import { ReservationStatus } from 'src/features/myOffers/components/ReservationStatus'

const ReservationDetailsOffers = ({
  offer,
  reservation,
  index,
  isOpen,
  chatAvailable,
  buttonsState,
  acceptReservation,
  finished,
  setConversationOpened = () => {},
  acceptReservationSuccess = () => {},
  botttomOfferor
}) => (
  <StyledReservationDetails
    key={reservation.id}
    isOpen={isOpen}
    isLast={index + 1 === offer.reservations.length}
    botttomOfferor={botttomOfferor}
  >
    <Index>{index + 1}.</Index>
    <Company>
      <div>
        <Ellipsis>
          <AuctionPriceValue>
            {reservation.price.float}
            <span>{reservation.price.currency}</span>
          </AuctionPriceValue>
        </Ellipsis>
        <DateTime>
          <FormattedMessage
            id='myOffers.offeredAt'
            values={{ date: reservation.createdAt }}
          />
        </DateTime>
      </div>
    </Company>
    <Offeror
      chatAvailable={chatAvailable}
      offeror={reservation.offeror}
      setConversationOpened={setConversationOpened}
    />
    <ActionButtons>
      {finished && reservation.acceptedAt && (
        <ReservationStatus status='accepted' />
      )}
      {reservation.isAcceptable && !finished && (
        <AcceptButton
          active={buttonsState !== API_STATES.IN_PROGRESS}
          onClick={() =>
            acceptReservation({
              offerId: offer.id,
              reservationId: reservation.id,
              onSuccess: acceptReservationSuccess
            })
          }
        />
      )}
      {}
    </ActionButtons>
  </StyledReservationDetails>
)

export default ReservationDetailsOffers
