import React, { useEffect, useRef, useState } from 'react'
import Carousel from 'react-multi-carousel'
import MobileDetect from 'mobile-detect'
import styled from 'styled-components'
import 'react-multi-carousel/lib/styles.css'
import Chevron from 'src/assets/icons/down.svg'
import { breakpoint } from 'src/theme/grid'

const Slider = ({
  slides,
  className,
  deviceType,
  title,
  responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1160 },
      items: 3,
      slidesToSlide: 1,
      partialVisibilityGutter: 40
    },
    desktop: {
      breakpoint: { max: 1160, min: 1025 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1025, min: 667 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 667, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0
    }
  },
  renderDotsOutside = true,
  infinite = false
}) => {
  const carouselRef = useRef(null)
  const [currentSlideCounter, setCurrentSlide] = useState(1)
  const totalSlides = slides.length
  const nextSlide = () => {
    carouselRef.current.next()
  }
  const prevSlide = () => {
    carouselRef.current.previous()
  }

  useEffect(() => {
    if (carouselRef.current) {
      setCurrentSlide(carouselRef.current.state.slidesToShow)
    }
  }, [carouselRef.current])

  return (
    <div className={className}>
      <TitleRow>
        {title}
        {slides.length > 0 && (
          <ArrowsContainer>
            <Arrow onClick={prevSlide}>
              <Chevron
                width={32}
                height={32}
                style={{ transform: 'rotate(90deg)' }}
              />
            </Arrow>
            <Arrow onClick={nextSlide}>
              <Chevron
                width={32}
                height={32}
                style={{ transform: 'rotate(270deg)' }}
              />
            </Arrow>
          </ArrowsContainer>
        )}
      </TitleRow>
      <Carousel
        swipeable
        draggable
        partialVisible
        responsive={responsive}
        renderDotsOutside={renderDotsOutside}
        renderButtonGroupOutside
        ssr
        showDots={false}
        arrows={false}
        infinite={infinite}
        containerClass='container-with-dots'
        itemClass='slider-item'
        deviceType={deviceType}
        ref={carouselRef}
        afterChange={(previousSlide, { currentSlide, slidesToShow }) => {
          setCurrentSlide(currentSlide + slidesToShow)
        }}
      >
        {slides}
      </Carousel>
      {totalSlides > 0 && (
        <SlidesCounter>
          {currentSlideCounter}/{totalSlides}
        </SlidesCounter>
      )}
    </div>
  )
}

Slider.getInitialProps = async ({ req }) => {
  let userAgent
  let deviceType
  if (req) {
    userAgent = req.headers['user-agent']
  } else {
    userAgent = navigator.userAgent
  }
  const md = new MobileDetect(userAgent)
  if (md.tablet()) {
    deviceType = 'tablet'
  } else if (md.mobile()) {
    deviceType = 'mobile'
  } else {
    deviceType = 'desktop'
  }
  return { deviceType }
}

const StyledSlider = styled(Slider)`
  position: relative;
  width: 100%;
  .react-multi-carousel-dot button {
    background: ${({ theme }) => theme.colors.transparent.hex()};
    border: 2px solid ${({ theme }) => theme.colors.ghost.hex()};
  }
  .react-multi-carousel-dot--active button {
    background: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const Arrow = styled.div`
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

const ArrowsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 0 1rem;
  ${breakpoint.m`
    padding: 0 2rem;
  `}
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -5px;
`

const SlidesCounter = styled.div`
  font-size: 0.75rem;
  line-height: 1.67;
  text-align: center;
  letter-spacing: 0.33px;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  opacity: 0.5;
  ${breakpoint.m`
    display: none;
  `}
`

export default StyledSlider
