import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, navigate, useIntl, Link } from 'gatsby-plugin-intl'
import { head, isEmpty, pathOr } from 'ramda'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import { isNotOlderThanMinutes } from 'src/utils/helpers'
import {
  Container,
  LocationFrom,
  LocationTo,
  MoreReservations,
  OfferLink,
  OfferNumber,
  ReservationsMobile,
  Shipowner,
  TableRow
} from 'src/features/myOffers/components/atoms'

import { Arrow, DateTime, Ellipsis } from 'src/features/stocks/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import ContainerIcon from 'src/assets/icons/container.svg'
import ShipIcon from 'src/assets/icons/ship.svg'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import { Status } from 'src/features/myOffers/components/StatusIcon'

import { connectExportFinished } from 'src/features/myOffers/duck/connectors'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { FINISHED_OFFER_STATES } from 'src/features/myOffers/duck/consts'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import AuctionDetailsOffers from 'src/features/myOffers/components/AuctionDetailsOffers'
import ReservationDetailsOffers from 'src/features/myOffers/components/ReservationDetailsOffers'
import { ReservationsField } from 'src/features/myOffers/components/ReservationsField'
import { ActionsFieldFinished } from 'src/features/myOffers/components/ActionsFieldFinished'
import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import { HandlerField } from 'src/features/myOffers/components/HandlerField'
import { AuctionsMobileField } from 'src/features/myOffers/components/AuctionsMobileField'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import PersonIcon from 'src/assets/icons/person.svg'
import CopyIcon from 'src/assets/icons/copy.svg'
import DeleteIcon from 'src/assets/icons/delete.svg'
import RestoreIcon from 'src/assets/icons/restore.svg'
import MoneyIcon from 'src/assets/icons/money.svg'

const OfferRowFinished = ({
  offer,
  isSelected,
  showOffersReservations,
  headers,
  setFilter,
  restoreOffer,
  deleteOffer,
  hasPermissionToDeleteOwnOffer,
  copyMyOfferToForm,
  currentUserId,
  acceptReservation,
  rejectReservation,
  buttonsState,
  setConversationOpened,
  selectOfferForDebtCollection
}) => {
  const intl = useIntl()
  const [isOpen, setOpen] = useState(false)
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const isNew = isNotOlderThanMinutes(offer.addedToListingAt, 1)
  const isSold = offer.outcome === FINISHED_OFFER_STATES.SOLD

  const permissions = useContext(PermissionsContext)
  const hasPermissionToSeeCompanyConversations = hasPermissionTo(
    PERMISSIONS.CHAT_COMPANY_CONVERSATIONS_SEE,
    permissions
  )
  const belongsToCurrentUser = offer.handler.id === currentUserId
  const chatAvailable =
    belongsToCurrentUser || hasPermissionToSeeCompanyConversations
  const hasReservations = offer.reservationsCount > 0
  const hiddenReservationsCount =
    offer.reservations.length > 0 &&
    offer.reservationsCount - offer.reservations.length
  const isAuction = offer.type === OFFER_TYPES.AUCTION

  const handleClick = () => {
    if (isAuction || hasReservations) setOpen(!isOpen)
  }

  useEffect(() => {
    hasReservations && isOpen && showOffersReservations(offer.id)
  }, [hasReservations, isOpen])

  useEffect(() => {
    isEmpty(offer.reservations) && setOpen(false)
  }, [offer.reservations])

  return (
    <>
      <TableRow
        key={offer.id}
        numberOfHeaders={headers.length}
        isSelected={isSelected}
        isOpen={isOpen}
      >
        <Status status={offer.outcome} />
        <OfferNumber>
          <Status status={offer.outcome} />
          <Link to={createOfferDetailsLink(offer.referenceNumber)}>
            {offer.referenceNumber}
          </Link>
        </OfferNumber>
        <ReservationsField
          hasReservations={hasReservations}
          onClick={handleClick}
          auction={isAuction}
          reservationsCount={offer.reservationsCount}
          price={offer.price}
          open={isOpen}
          finishedOffer={true}
        />
        <LocationFrom>
          <Arrow>
            <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
          </Arrow>
          {/* COUNTRY_CODES <Ellipsis>{`${offer.loadingLocation.countryCode}, ${offer.loadingLocation.name}`}</Ellipsis> */}
          <Ellipsis>{offer.loadingLocation.name}</Ellipsis>
          <DateTime>
            {offer.loadingDate}
            {offer.loadingTime && `, ${offer.loadingTime}`}
          </DateTime>
        </LocationFrom>
        <LocationTo>
          {/* COUNTRY_CODES <Ellipsis>{`${offer.returnLocation.countryCode}, ${offer.returnLocation.name}`}</Ellipsis> */}
          <Ellipsis>{offer.returnLocation.name}</Ellipsis>
          <DateTime>
            {offer.returnDate}
            {offer.returnTime && `, ${offer.returnTime}`}
          </DateTime>
        </LocationTo>
        <Container>
          <ContainerIcon />
          <div>
            <Ellipsis>{offer.container.name}</Ellipsis>
          </div>
        </Container>
        <Shipowner>
          <ShipIcon />
          <div>
            <Ellipsis>{offer.shipowner.name}</Ellipsis>
          </div>
        </Shipowner>
        <HandlerField handler={offer.handler} />
        <CreatedAtField
          isNew={isNew}
          addedAt={offer.finishedAt}
          auction={isAuction}
        >
          <ActionsMenu onClick={() => {}}>
            <DropdownItem
              onClick={e => {
                e.stopPropagation()
                navigate(createOfferDetailsLink(offer.referenceNumber))
              }}
            >
              <PersonIcon />
              <FormattedMessage id='offerDetails.changeHandler' />
            </DropdownItem>
            <DropdownItem
              onClick={e => {
                e.stopPropagation()
                copyMyOfferToForm(offer.raw)
              }}
            >
              <CopyIcon />
              <FormattedMessage id='offerDetails.copyOffer' />
            </DropdownItem>
            {offer.isDeletable && hasPermissionToDeleteOwnOffer && (
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  setDeleteDialogOpen(true)
                }}
              >
                <DeleteIcon />
                <FormattedMessage id='offerDetails.deleteOffer' />
              </DropdownItem>
            )}
            {offer.isRestorable && (
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  setFilter('page', 1)
                  restoreOffer(offer.id)
                }}
              >
                <RestoreIcon />
                <FormattedMessage id='offerDetails.restoreOffer' />
              </DropdownItem>
            )}
            {offer.isDebtCollectable && (
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  selectOfferForDebtCollection(offer)
                }}
              >
                <MoneyIcon />
                <FormattedMessage id='offerDetails.debtCollection' />
              </DropdownItem>
            )}
          </ActionsMenu>
        </CreatedAtField>
        <ActionsFieldFinished
          onClickOfferNumber={() =>
            navigate(createOfferDetailsLink(offer.referenceNumber))
          }
          onClickCopy={() => {
            copyMyOfferToForm(offer.raw)
          }}
          deletable={offer.isDeletable}
          hasPermissionToDeleteOwnOffer={hasPermissionToDeleteOwnOffer}
          onClickDelete={() => setDeleteDialogOpen(true)}
          restorable={offer.isRestorable}
          onClickRestore={() => {
            setFilter('page', 1)
            restoreOffer(offer.id)
          }}
          onClickDebtCollection={() => selectOfferForDebtCollection(offer)}
          isDebtCollectable={offer.isDebtCollectable}
          isUpdatable={offer.isUpdatable}
        />
      </TableRow>
      {hasReservations && !isAuction && (
        <ReservationsMobile onClick={handleClick} isOpen={isOpen}>
          <span>
            <FormattedMessage id='myOffers.reservations' />
          </span>
          {offer.reservationsCount}
          {hasReservations && (
            <MUIDropdownIcon
              width={24}
              height={24}
              style={{ transform: `rotate(${isOpen ? '180deg' : '0'})` }}
            />
          )}
        </ReservationsMobile>
      )}
      {isAuction && (
        <AuctionsMobileField
          onClick={handleClick}
          open={isOpen}
          hasReservations={hasReservations}
          price={offer.price}
          reservationsCount={offer.reservationsCount}
        />
      )}
      {isOpen &&
        !isAuction &&
        offer.reservations.map((reservation, index) => (
          <ReservationDetailsOffers
            key={reservation.id}
            offer={offer}
            reservation={reservation}
            index={index}
            isOpen={isOpen}
            chatAvailable={chatAvailable}
            buttonsState={buttonsState}
            acceptReservation={acceptReservation}
            hiddenReservationsCount={hiddenReservationsCount}
            rejectReservation={rejectReservation}
            handleOfferNumberClick={() =>
              navigate(createOfferDetailsLink(offer.referenceNumber))
            }
            setConversationOpened={() => {
              setConversationOpened({
                receiverId: reservation.offeror.id,
                contextId: offer.id,
                contextType: offer.offerContextType,
                conversationOpened: true
              })
            }}
          />
        ))}
      {isOpen && isAuction && (
        <AuctionDetailsOffers
          reservation={head(offer.reservations)}
          isOpen={isOpen}
          offer={offer}
          chatAvailable={chatAvailable}
          setConversationOpened={() => {
            setConversationOpened({
              receiverId: pathOr(
                null,
                ['offeror', 'id'],
                head(offer.reservations)
              ),
              contextId: offer.id,
              contextType: offer.offerContextType,
              conversationOpened: true
            })
          }}
        />
      )}
      {isOpen && hiddenReservationsCount > 0 && !isAuction && (
        <MoreReservations>
          <FormattedMessage
            id='myOffers.moreReservations'
            values={{
              hiddenReservationsCount
            }}
          />
        </MoreReservations>
      )}
      <ConfirmDialog
        withReason={isSold}
        open={isDeleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        offer={offer}
        onConfirm={reason => deleteOffer(offer.id, reason)}
        onOfferNumberClick={() =>
          navigate(createOfferDetailsLink(offer.referenceNumber))
        }
        message={
          <FormattedMessage
            id='myOffers.deleteSoldOfferWarning'
            values={{
              name: offer.referenceNumber,
              a: (...chunks) => (
                <OfferLink to={createOfferDetailsLink(offer.referenceNumber)}>
                  {chunks}
                </OfferLink>
              )
            }}
          />
        }
        title={
          <FormattedMessage
            id='myOffers.deleteOneOfferTitle'
            values={{
              name: offer.referenceNumber
            }}
          />
        }
        inputTitle={intl.formatMessage({
          id: 'myOffers.deleteSoldInputTitle'
        })}
        inputPlaceholder={intl.formatMessage({
          id: 'myOffers.deleteSoldInputPlaceholder'
        })}
      />
    </>
  )
}

export default connectExportFinished(OfferRowFinished)
