import React from 'react'
import styled from 'styled-components'
import { TRACKING_STATES } from 'src/features/tracking/duck/consts'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Alert from 'src/components/Alert'
import { breakpoint } from 'src/theme/grid'

const icons = {
  [TRACKING_STATES.INCOMPLETE_DATA]: {
    type: 'info'
  },
  [TRACKING_STATES.WAITING_FOR_DRIVER]: {
    type: 'info'
  },
  [TRACKING_STATES.IN_TRANSIT]: {
    type: 'warning'
  },
  [TRACKING_STATES.ACTIVE]: {
    type: 'success'
  },
  [TRACKING_STATES.COMPLETED_ON_SCHEDULE]: {
    type: 'success'
  },
  [TRACKING_STATES.COMPLETED_BEHIND_SCHEDULE]: {
    type: 'warning'
  },
  [TRACKING_STATES.COMPLETED_UNKNOWN_SCHEDULE]: {
    type: 'success'
  }
}

const StatusComponent = ({ status, className }) => (
  <div className={className}>
    {status && (
      <Alert
        type={icons[status].type}
        message={<FormattedMessage id={`tracking.${status}`} />}
      />
    )}
  </div>
)
export default styled(StatusComponent)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex: 1;
  ${Alert} {
    height: 100%;
    width: 100%;
  }
  margin-bottom: 0.5rem;
  ${breakpoint.m`
    margin-bottom:0;
    margin-right: 12px;
  `}
`
