import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import HeaderLoggedIn from 'src/components/header/HeaderLoggedIn'
import CookiesBar from 'src/components/CookiesInformation'

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${({ theme }) => theme.dimensions.topBarHeight});
  overflow-y: auto;
  padding: ${({ contentPadding }) => contentPadding};
  box-sizing: border-box;
  width: 100vw;
  ${breakpoint.m`
    width: 100%;
  `}
`

export const PageComponent = ({
  className,
  children,
  topBar = <HeaderLoggedIn />,
  contentPadding = '2.2rem',
  wrapperRef
}) => (
  <PageWrapper className={className}>
    {topBar}
    <ContentWrapper contentPadding={contentPadding} ref={wrapperRef}>
      {children}
    </ContentWrapper>
    <CookiesBar />
  </PageWrapper>
)

export const Page = styled(PageComponent)``
