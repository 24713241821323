import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useIntl } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'

import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import Tabs from 'src/components/Tabs'
import { Page } from 'src/components/Wrappers'
import Alert from 'src/components/Alert'
import { Column, Row, SectionBox } from 'src/features/account/components/atoms'
import { MY_OFFERS_TABS_ARRAY } from 'src/features/myOffers/duck/consts'
import routes from 'src/utils/routes'
import BreadCrumbs from 'src/components/BreadCrumbs'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import MyOffersOngoing from 'src/features/myOffers/components/export/OffersListOngoing'
import MyOffersFinished from 'src/features/myOffers/components/export/OffersListFinished'
import { connectExportOngoing } from 'src/features/myOffers/duck/connectors'
import { Padding } from 'src/features/myOffers/components/atoms'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'

const MyExportOffers = ({ className, getOngoingOffers, getFinishedOffers }) => {
  const intl = useIntl()
  const initialActiveTab = 0

  useEffect(() => {
    getOngoingOffers()
    getFinishedOffers()
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader withDropdown>
              {intl.formatMessage({
                id: 'myOffers.titleExport'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Padding>
        <BreadCrumbs
          links={[
            {
              name: intl.formatMessage({ id: 'forms.dashboard' }),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
            },
            {
              name: intl.formatMessage({
                id: 'offers.freightExchangeExportTitle'
              }),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_EXPORT}`
            },
            {
              name: intl.formatMessage({
                id: 'myOffers.title'
              }),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`
            }
          ]}
        />
      </Padding>
      <Tabs list={MY_OFFERS_TABS_ARRAY} initialActiveTab={initialActiveTab}>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <MyOffersOngoing />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <MyOffersFinished />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = connectExportOngoing(MyExportOffers)
export default styled(ConnectedComponent)`
  ${Tabs} {
    display: block;
    margin-bottom: 0;
    & > div {
      padding: 0.75rem 1.2rem 0 1.2rem;
    }
    & span {
      font-weight: 400;
    }
  }

  ${Alert} {
    width: auto;
    margin: 1rem 0 0 0;
    ${breakpoint.m`
    margin: initial;
    width: inherit;
  `}
  }
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
