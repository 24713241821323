import { object, string, ref, number } from 'yup'
import {
  validate,
  validateDependantFields,
  validateSchema
} from 'src/ducks/schema'
import { MAX_IMAGE_SIZE_BYTES } from 'src/ducks/consts'

const userSchema = object().shape({
  phoneNumber: string()
    .matches(/^$|^\+\d{9,20}$/, 'validation.phoneNumber')
    .nullable(),
  contactInfo: string().nullable(),
  firstName: string().nullable(),
  lastName: string().nullable()
})

export const validateUser = validate(userSchema)
export const validateUserSchema = validateSchema(userSchema)

const passwordSchema = object().shape({
  currentPassword: string().required(),
  newPassword: string()
    .min(4, 'validation.passwordToShort')
    .required(),
  newPasswordConfirmation: string()
    .min(4, 'validation.passwordToShort')
    .required()
    .oneOf([ref('newPassword')], 'validation.same')
})

export const validatePasswordConfirmation = validateDependantFields(
  passwordSchema
)
export const validatePassword = validate(passwordSchema)
export const validatePasswordSchema = validateSchema(passwordSchema)

const myCompany = object().shape({
  phoneNumber: string()
    .matches(/^$|^\+\d{9,20}$/, 'validation.phoneNumber')
    .nullable()
    .required(),
  email: string()
    .email()
    .nullable()
    .required(),
  homepageUrl: string().nullable()
})

export const validateMyCompany = validate(myCompany)
export const validateMyCompanySchema = validateSchema(myCompany)

const addUserSchema = object().shape({
  username: string().required(),
  email: string()
    .email()
    .required(),
  firstName: string().required(),
  lastName: string().required(),
  password: string()
    .min(4, 'validation.passwordToShort')
    .required(),
  phoneNumber: string()
    .matches(/^$|^\+\d{9,20}$/, 'validation.phoneNumber')
    .nullable(),
  contactInfo: string().nullable(),
  avatarSize: number().max(MAX_IMAGE_SIZE_BYTES)
})

export const validateAddUser = validate(addUserSchema)
export const validateAddUserSchema = validateSchema(addUserSchema)

const editUserSchema = object().shape({
  username: string().required(),
  email: string()
    .email()
    .required(),
  firstName: string().required(),
  lastName: string().required(),
  password: string().matches(/^(?:.{4,}|)$/, 'validation.passwordToShort'),
  phoneNumber: string()
    .matches(/^$|^\+\d{9,20}$/, 'validation.phoneNumber')
    .nullable(),
  contactInfo: string().nullable(),
  avatarSize: number().max(MAX_IMAGE_SIZE_BYTES)
})

export const validateEditUser = validate(editUserSchema)
export const validateEditUserSchema = validateSchema(editUserSchema)

const contactFormSchema = object().shape({
  category: string()
    .required()
    .nullable(),
  message: string()
    .required()
    .nullable(),
  subject: string()
    .required()
    .nullable()
})

export const validateContactForm = validate(contactFormSchema)
export const validateContactFormSchema = validateSchema(contactFormSchema)
