import styled from 'styled-components'
import { Link } from 'gatsby-plugin-intl'

export const PlaceholderTitle = styled.h1`
  font-size: 3.2rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
`

export const LinkPlaceholder = styled(Link)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 1.2rem;
  background-color: ${({ theme }) => theme.colors.mercury.hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1rem;
  border-radius: 6px;
`

export const PageTitle = styled.h1`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin-bottom: 1.2rem;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 2.2rem;
`

export const PageSubtitle = styled.h3`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin-bottom: 1.2rem;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.8rem;
  opacity: 0.8;
`

export const Chip = styled.span`
  display: inline-block;
  padding: 0.2rem 0.6rem;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 800;
  border-radius: 20px;
  min-width: 40px;
  text-align: center;
`
