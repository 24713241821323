import React, { useState } from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import {
  CONTRACTS_HEADERS_STOCKS_NAMES,
  CONTRACTS_HEADERS_STOCKS_WIDTHS
} from 'src/features/contracts/duck/consts'
import {
  DateTime,
  Ellipsis,
  Location
} from 'src/features/stocks/components/atoms'
import moment from 'moment'
import { DATE_FORMAT } from 'src/ducks/consts'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import Chevron from 'src/assets/icons/down.svg'
import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import {
  ContractLocations,
  EllipsisContainer,
  TableRow
} from 'src/features/contracts/components/atoms'
import styled from 'styled-components'
import { isNotOlderThanMinutes } from 'src/utils/helpers'
import MobileTableRow from 'src/features/contracts/components/MobileTableRow'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'

const OfferRow = ({ offer, handleClick, isSelected }) => {
  const isNew = isNotOlderThanMinutes(offer.addedToListingAt, 1)
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow
        widths={CONTRACTS_HEADERS_STOCKS_WIDTHS}
        columnNames={CONTRACTS_HEADERS_STOCKS_NAMES}
        open={open}
        onClick={() => handleClick(offer.id)}
        isSelected={isSelected}
        clickable
      >
        <div>
          <OfferLink
            onClick={e => e.stopPropagation()}
            to={createOfferDetailsLink(offer.referenceNumber)}
          >
            {offer.referenceNumber}
          </OfferLink>
        </div>
        <div>
          <Ellipsis>
            <FormattedMessage id={`common.${offer.type}`} defaultMessage='-' />
          </Ellipsis>
        </div>
        <div>
          <Ellipsis>{offer.issuer.company.name}</Ellipsis>
        </div>
        <DateTime>
          <Ellipsis>{moment(offer.startDate).format(DATE_FORMAT)}</Ellipsis>
        </DateTime>
        <DateTime>
          <Ellipsis>{moment(offer.endDate).format(DATE_FORMAT)}</Ellipsis>
        </DateTime>
        <ContractLocations>
          {offer.transportations.map((line, index) => (
            <React.Fragment key={`${line.offerId}-${index}`}>
              {(index === 0 || open) && (
                <Location>
                  <LocationRow>
                    <EllipsisContainer>
                      <Ellipsis>{`${line.originLocation.countryCode}, ${line.originLocation.name}`}</Ellipsis>
                    </EllipsisContainer>
                    <Arrow>
                      <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
                    </Arrow>
                    <EllipsisContainer>
                      <Ellipsis>{`${line.destinationLocation.countryCode}, ${line.destinationLocation.name}`}</Ellipsis>
                    </EllipsisContainer>
                    {offer.transportations.length > 1 && index === 0 && (
                      <MoreContractsCounter
                        onClick={e => {
                          e.stopPropagation()
                          setOpen(!open)
                        }}
                      >
                        {open ? (
                          <FormattedMessage id='common.less' />
                        ) : (
                          `+${offer.transportations.length - 1}`
                        )}
                        <Chevron
                          style={{
                            transform: `rotate(${open ? '180deg' : '0'})`
                          }}
                        />
                      </MoreContractsCounter>
                    )}
                  </LocationRow>
                  <DateTime>
                    {line.container.name}
                    {', '}
                    {line.shipowner.name}
                    {', '}
                    {'x'}
                    {line.numberOfContainers}
                  </DateTime>
                </Location>
              )}
            </React.Fragment>
          ))}
        </ContractLocations>
        <CreatedAtField isNew={isNew} addedAt={offer.addedToListingAt} />
      </TableRow>
      <MobileTableRow
        offer={offer}
        open={open}
        setOpen={setOpen}
        isNew={isNew}
        onClick={() => handleClick(offer.id)}
      />
    </>
  )
}

export default OfferRow

const Arrow = styled.span`
  color: ${({ theme }) => theme.colors.apple.hex()};
`
const LocationRow = styled.div`
  display: flex;
  align-items: center;
`
const MoreContractsCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  cursor: pointer;
  font-size: 0.75rem;
`

const OfferLink = styled(Link)`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
