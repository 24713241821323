import { addMethod, object, string, number } from 'yup'
import { validate, validateSchema } from 'src/ducks/schema'
import moment from 'moment'
import { isNil } from 'ramda'
import { isEmptyString } from 'ramda-adjunct'

addMethod(string, 'time', function() {
  return this.test('time', 'validation.timeOrEmpty', value =>
    moment(value, 'HH:mm', true).isValid()
  )
})

addMethod(string, 'timeOrEmpty', function() {
  return this.test('timeOrEmpty', 'validation.timeOrEmpty', value => {
    //'__:__' default value for masked input
    const isEmpty = isNil(value) || isEmptyString(value) || value === '__:__'
    return isEmpty || moment(value, 'HH:mm', true).isValid()
  })
})

addMethod(string, 'positiveIntegerOrEmpty', function() {
  return this.test('positiveIntegerOrEmpty', 'validation.wrongValue', value => {
    const isEmpty = isNil(value) || isEmptyString(value)
    return isEmpty || /^[1-9][0-9]*$/.test(value)
  })
})

const addOrderSchema = object().shape({
  offerType: string()
    .nullable()
    .required(),
  offerId: string()
    .nullable()
    .required(),
  transportationType: string().nullable(),
  containerId: string()
    .nullable()
    .required(),
  shipownerId: string()
    .nullable()
    .required(),
  containerNumber: string()
    .nullable()
    .required(),
  weight: string()
    .positiveIntegerOrEmpty()
    .nullable(),
  sealNumber: string().nullable(),
  pickUpAddress: string()
    .nullable()
    .required(),
  pin: string().nullable(),
  pickUpRef: string().nullable(),
  returnAddress: string()
    .nullable()
    .required(),
  booking: string().nullable(),
  returnRef: string().nullable(),
  loadingUnloadingAddress: string()
    .nullable()
    .required(),
  loadingUnloadingDate: string()
    .nullable()
    .required(),
  loadingUnloadingTime: string()
    .timeOrEmpty()
    .nullable()
    .required(),
  loadingUnloadingCompanyName: string().nullable(),
  customsClearanceType: string().nullable(),
  customsClearanceAddress: string().when('customsClearanceType', {
    is: v => v === 'inCustomsTransit' || v === 'address',
    then: string()
      .nullable()
      .required(),
    otherwise: string().nullable()
  }),
  driverName: string()
    .nullable()
    .required(),
  driverIdentityCardNumber: string()
    .nullable()
    .required(),
  vehicleRegistrationPlate: string()
    .nullable()
    .required(),
  containerSemiTrailerRegistrationPlate: string().nullable(),
  price: number()
    .typeError('validation.number')
    .positive()
    .nullable()
    .required(),
  currency: string().nullable(),
  cargoName: string()
    .nullable()
    .required(),
  adrClassId: string().nullable(),
  adrClassUn: string().nullable(),
  issuePlace: string()
    .nullable()
    .required(),
  additionalInfo: string().nullable(),
  conditions: string().nullable()
})

export const validateAddOrder = validate(addOrderSchema)
export const validateAddOrderSchema = validateSchema(addOrderSchema)
