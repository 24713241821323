import {
  CargoTypeInputWrapper,
  FormSectionSpacer,
  FormSubsectionSpacer,
  Padding,
  PlaceInputWrapper,
  WeightInputWrapper
} from 'src/features/offers/components/atoms'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import RemoveIconUrl from 'src/assets/icons/delete.svg'
import TextInput from 'src/components/atoms/TextInput'
import LocationInput from 'src/components/filters/LocationInput'
import { LOCATION_TYPES } from 'src/ducks/consts'
import Select from 'src/components/filters/SelectInput'
import { EURO_OPTIONS } from 'src/features/stocks/ducks/consts'
import CheckboxSection from 'src/features/offers/components/CheckboxSection'
import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import connect from 'src/features/offers/duck/connectors'
import { connectDictionaries } from 'src/ducks/connectors'
import { remove } from 'ramda'
import { validateOfferContract } from 'src/features/offers/duck/schema'

const ExportLine = props => {
  const {
    destinationDictionary,
    returnDictionary,
    index,
    setOfferFormContractValue,
    loadingDictionary,
    dictionaries,
    fetchLocations,
    offerFormContracts: { transportations }
  } = props

  const intl = useIntl()

  const removeLine = () =>
    setOfferFormContractValue(
      'transportations',
      remove(index, 1, transportations)
    )

  return (
    <Padding>
      <LineHeader>
        <span>
          {index + 1}. <FormattedMessage id='forms.line' />
        </span>
        {index > 0 && (
          <RemoveIcon onClick={removeLine}>
            <RemoveIconUrl />
          </RemoveIcon>
        )}
      </LineHeader>
      <Line>
        <FormSectionSpacer>
          {intl.formatMessage({ id: 'forms.transports' })}
        </FormSectionSpacer>
        <PlaceInputWrapper>
          <TextInput
            type='number'
            label={intl.formatMessage({
              id: 'forms.transportsCount'
            })}
            id='transportsCount'
            name={['transportations', index, 'numberOfContainers']}
            placeholder={intl.formatMessage({
              id: 'forms.transportsCountPlaceholder'
            })}
            value={transportations[index].numberOfContainers}
            onChange={setOfferFormContractValue}
            width='380px'
            required
            validate={validateOfferContract}
          />
        </PlaceInputWrapper>
        <FormSectionSpacer>
          {intl.formatMessage({ id: 'forms.loadingSectionName' })}
        </FormSectionSpacer>
        <PlaceInputWrapper>
          <LocationInput
            required
            id='loadingLocationId'
            label={intl.formatMessage({
              id: 'forms.loadingPlaceInputLabel'
            })}
            locationName={['transportations', index, 'originLocationId']}
            locationValue={transportations[index].originLocationId}
            locationPlaceholder={intl.formatMessage({
              id: 'forms.loadingPlaceInputLabel'
            })}
            onChange={setOfferFormContractValue}
            placeholder={intl.formatMessage({
              id: 'forms.locationPlaceholder'
            })}
            locationTypes={LOCATION_TYPES.CITY}
            dictionary={loadingDictionary}
            fetchLocations={fetchLocations}
            searchHintText={intl.formatMessage({
              id: 'offersFilters.searchHintText'
            })}
            validate={validateOfferContract}
          />
        </PlaceInputWrapper>
        <FormSectionSpacer>
          {intl.formatMessage({ id: 'forms.returnSectionName' })}
        </FormSectionSpacer>
        <PlaceInputWrapper>
          <LocationInput
            required
            id='returnLocationId'
            label={intl.formatMessage({
              id: 'forms.returnPlaceInputLabel'
            })}
            locationName={['transportations', index, 'destinationLocationId']}
            locationValue={transportations[index].destinationLocationId}
            locationPlaceholder={intl.formatMessage({
              id: 'forms.returnPlaceInputLabel'
            })}
            onChange={setOfferFormContractValue}
            placeholder={intl.formatMessage({
              id: 'forms.locationPlaceholder'
            })}
            locationTypes={LOCATION_TYPES.TERMINAL}
            dictionary={returnDictionary}
            fetchLocations={fetchLocations}
            searchHintText={intl.formatMessage({
              id: 'offersFilters.searchHintText'
            })}
            validate={validateOfferContract}
          />
        </PlaceInputWrapper>
        <FormSectionSpacer>
          {intl.formatMessage({ id: 'forms.cargoSectionName' })}
        </FormSectionSpacer>
        <WeightInputWrapper>
          <Select
            required
            id='containerId'
            name={['transportations', index, 'containerId']}
            label={intl.formatMessage({ id: 'forms.container' })}
            value={transportations[index].containerId}
            options={dictionaries.containers}
            onChange={setOfferFormContractValue}
            placeholder={intl.formatMessage({
              id: 'forms.searchPlaceholder'
            })}
            validate={validateOfferContract}
          />
        </WeightInputWrapper>
        <CargoTypeInputWrapper>
          <Select
            required
            id='shipownerId'
            name={['transportations', index, 'shipownerId']}
            label={intl.formatMessage({ id: 'forms.shipowner' })}
            value={transportations[index].shipownerId}
            options={dictionaries.shipowners}
            onChange={setOfferFormContractValue}
            placeholder={intl.formatMessage({
              id: 'forms.searchPlaceholder'
            })}
            validate={validateOfferContract}
          />
        </CargoTypeInputWrapper>
        <WeightInputWrapper>
          <Select
            id='adrClassId'
            name={['transportations', index, 'adrClassId']}
            label={intl.formatMessage({ id: 'forms.adrClass' })}
            value={transportations[index].adrClassId}
            options={dictionaries.adrClasses}
            onChange={setOfferFormContractValue}
            placeholder={intl.formatMessage({
              id: 'forms.searchPlaceholder'
            })}
            width='380px'
            validate={validateOfferContract}
          />
        </WeightInputWrapper>
        <FormSubsectionSpacer>
          {intl.formatMessage({
            id: 'forms.additionalCargoInformation'
          })}
        </FormSubsectionSpacer>
        <WeightInputWrapper>
          <TextInput
            label={intl.formatMessage({ id: 'forms.weight' })}
            id='weight'
            name={['transportations', index, 'weight']}
            placeholder={intl.formatMessage({ id: 'forms.weight' })}
            value={transportations[index].weight}
            onChange={setOfferFormContractValue}
            type='unit'
            unit='kg'
            validate={validateOfferContract}
          />
        </WeightInputWrapper>
        <CargoTypeInputWrapper>
          <Select
            id='cargoTypeId'
            name={['transportations', index, 'cargoTypeId']}
            label={intl.formatMessage({ id: 'forms.cargoType' })}
            value={transportations[index].cargoTypeId}
            options={dictionaries.cargoTypes}
            onChange={setOfferFormContractValue}
            placeholder={intl.formatMessage({
              id: 'forms.searchPlaceholder'
            })}
            validate={validateOfferContract}
          />
        </CargoTypeInputWrapper>
        <FormSubsectionSpacer>
          {intl.formatMessage({
            id: 'forms.additionalCarrierInformation'
          })}
        </FormSubsectionSpacer>
        <CargoTypeInputWrapper>
          <Select
            id='europeanEmissionStandard'
            name={['transportations', index, 'europeanEmissionStandard']}
            label={intl.formatMessage({
              id: 'forms.europeanEmissionStandard'
            })}
            value={transportations[index].europeanEmissionStandard}
            options={EURO_OPTIONS}
            onChange={setOfferFormContractValue}
            placeholder={intl.formatMessage({
              id: 'forms.searchPlaceholder'
            })}
            width='230px'
            validate={validateOfferContract}
          />
        </CargoTypeInputWrapper>
        <CheckboxSection
          opened={transportations[index].customsClearanceLocationIdOpened}
          setOpened={opened =>
            setOfferFormContractValue(
              ['transportations', index, 'customsClearanceLocationIdOpened'],
              opened
            )
          }
          label={intl.formatMessage({
            id: 'forms.customsClearance'
          })}
          handleClose={() =>
            setOfferFormContractValue(
              ['transportations', index, 'customsClearanceLocationIdOpened'],
              null
            )
          }
        >
          <PlaceInputWrapper>
            <LocationInput
              required
              id='customsClearanceLocationId'
              label={intl.formatMessage({
                id: 'forms.customsClearanceLocationLabel'
              })}
              locationName={[
                'transportations',
                index,
                'customsClearanceLocationId'
              ]}
              locationValue={transportations[index].customsClearanceLocationId}
              locationPlaceholder={intl.formatMessage({
                id: 'forms.customsClearanceLocationLabel'
              })}
              onChange={setOfferFormContractValue}
              placeholder={intl.formatMessage({
                id: 'forms.locationPlaceholder'
              })}
              locationTypes={LOCATION_TYPES.CITY}
              dictionary={destinationDictionary}
              fetchLocations={fetchLocations}
              searchHintText={intl.formatMessage({
                id: 'offersFilters.searchHintText'
              })}
            />
          </PlaceInputWrapper>
        </CheckboxSection>
      </Line>
    </Padding>
  )
}

export default compose(connect, connectDictionaries)(ExportLine)

const Line = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
  border-radius: 4px;
  padding: 1rem;
  max-width: 626px;
  box-sizing: border-box;
  ${FormSectionSpacer} {
    margin-top: 0.5rem;
    &:first-of-type {
      margin-top: 0rem;
    }
  }
`
const LineHeader = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`
const RemoveIcon = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`
