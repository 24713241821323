import React from 'react'
import styled from 'styled-components'

const Genset = styled.div`
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  line-height: 32px;
  width: 69px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.easternBlue.hex()};
`

export default () => <Genset>GENSET</Genset>
