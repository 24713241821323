import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { isNil } from 'ramda'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/styles'
import theme from 'src/theme/theme'

const EllipsisTooltip = ({ children, trigger }) => {
  const [open, setOpen] = useState(false)
  const [isOverflowing, setOverflowing] = useState(false)
  const textElementRef = useRef(null)

  const compareSize = () => {
    if (isNil(textElementRef) || isNil(textElementRef.current)) {
      return
    }

    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    setOverflowing(compare)
  }

  useEffect(() => {
    setTimeout(() => compareSize(), 1000)
  }, [trigger])

  const handleTouchStart = () => {
    setOpen(isOverflowing)
    setTimeout(() => {
      setOpen(false)
    }, 1000)
  }

  return (
    <>
      {open && (
        <StyledTooltip title={children} arrow placement='top' open={open}>
          <ContentPlaceholder />
        </StyledTooltip>
      )}
      <TooltipContent
        onTouchStart={handleTouchStart}
        onMouseOver={() => setOpen(isOverflowing)}
        onFocus={() => setOpen(isOverflowing)}
        onMouseOut={() => setOpen(false)}
        onBlur={() => setOpen(false)}
        ref={textElementRef}
      >
        {children}
      </TooltipContent>
    </>
  )
}

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: theme.colors.codGray.hex(),
    color: theme.colors.white.hex(),
    fontSize: '0.8rem',
    borderRadius: '4px',
    position: 'relative',
    '&>i': {
      display: 'none'
    }
  }
}))(Tooltip)

const ContentPlaceholder = styled.span`
  display: block;
  width: 100%;
`

const TooltipContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default EllipsisTooltip
