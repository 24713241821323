import React from 'react'
import styled from 'styled-components'
import {
  BigInputContainer,
  InputRow
} from 'src/features/orders/components/atoms'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { Recipient } from 'src/features/invoices/components/TopSection/styles'
import { TextTitle } from 'src/features/invoices/InvoiceDetails'

export const CauseOfInvoiceCorrection = ({ invoice }) => {
  const intl = useIntl()
  return (
    <InputRow>
      <InputContainer>
        <TextTitle>
          <FormattedMessage id='invoices.causeOfCorrection' />
        </TextTitle>
        <Recipient>
          {intl.formatMessage({
            id: `invoices.correctionReasons.${invoice.correctionReason}`
          })}
        </Recipient>
      </InputContainer>
    </InputRow>
  )
}

const InputContainer = styled(BigInputContainer)`
  ${Recipient} {
    margin-top: 4px;
  }
`
