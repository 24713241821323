import React, { useState } from 'react'
import { navigate, FormattedMessage } from 'gatsby-plugin-intl'
import moment from 'moment'
import {
  DateTime,
  Ellipsis,
  Location
} from 'src/features/stocks/components/atoms'

import { TableRow } from 'src/features/contracts/components/atoms'
import styled, { css } from 'styled-components'
import { Action, Actions } from 'src/features/myOffers/components/atoms'
import PdfIcon from 'src/assets/icons/pdf.svg'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import {
  INVOICES_ISSUED_TABLE_NAMES,
  INVOICES_ISSUED_TABLE_WIDTHS,
  INVOICES_STATES
} from 'src/features/invoices/duck/consts'
import Wait from 'src/assets/icons/wait.svg'
import MobileInvoiceRow from 'src/features/invoices/components/MobileInvoiceRow'
import InvoiceStatus from 'src/features/invoices/components/InvoiceStatus'
import { getInvoiceStatus } from 'src/features/invoices/duck/helpers'
import MarkAsPaid from 'src/features/invoices/components/MarkAsPaid'
import NoteIconUrl from 'src/assets/icons/note.svg'
import IconWithTooltip from 'src/components/IconWithTooltip'

const InvoiceRow = ({
  invoice,
  downloadInvoicePdf,
  markAsPaid: markInvoiceAsPaid,
  markCorrectiveAsPaid,
  downloadCorrectiveInvoicePdf
}) => {
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false)
  const status = getInvoiceStatus(invoice)
  const isOverdue =
    status !== INVOICES_STATES.FULLY_PAID &&
    moment(invoice.dueDate).isBefore(moment(), 'day')
  const isInFuture = moment(invoice.issueDate).isAfter(moment(), 'day')

  const invoiceDetailsLink = invoice.isCorrectiveInvoice
    ? `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CORRECTIVE_INVOICE_DETAILS}&id=${invoice.id}`
    : `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.id}`

  const downloadPdf = invoice.isCorrectiveInvoice
    ? downloadCorrectiveInvoicePdf
    : downloadInvoicePdf

  const hasCorrectiveInvoice = !!invoice.lastCorrectiveInvoice.id

  const markAsPaid = invoice.isCorrectiveInvoice
    ? markCorrectiveAsPaid
    : markInvoiceAsPaid

  return (
    <>
      <TableRow
        widths={INVOICES_ISSUED_TABLE_WIDTHS}
        columnNames={INVOICES_ISSUED_TABLE_NAMES}
      >
        <div>
          <OffeLinkContainer>
            {invoice.correctiveNotesCount > 0 && (
              <IconWithTooltip
                icon={<NoteIcon />}
                title={<FormattedMessage id='invoices.correctionNote' />}
              />
            )}
            <OfferLink onClick={() => navigate(invoiceDetailsLink)}>
              {invoice.number}
            </OfferLink>
          </OffeLinkContainer>
          {invoice.isCorrectiveInvoice && (
            <Small>
              do{' '}
              <OfferLink
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.invoice.id}`
                  )
                }
              >
                {invoice.invoice.number}
              </OfferLink>
            </Small>
          )}
          {hasCorrectiveInvoice && (
            <Small>
              kor.{' '}
              <OfferLink
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CORRECTIVE_INVOICE_DETAILS}&id=${invoice.lastCorrectiveInvoice.id}`
                  )
                }
              >
                {invoice.lastCorrectiveInvoice.number}
              </OfferLink>
            </Small>
          )}
        </div>
        <div>
          <Ellipsis>{invoice.recipientCompanyName}</Ellipsis>
        </div>
        <div>
          <Ellipsis>
            {invoice.transportationOrder.referenceNumber ? (
              <OfferLink
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${invoice.transportationOrder.referenceNumber}`
                  )
                }
              >
                {invoice.transportationOrder.referenceNumber}
              </OfferLink>
            ) : (
              '-'
            )}
          </Ellipsis>
        </div>
        <Location>
          <Ellipsis>{invoice.total.formatted}</Ellipsis>
          <DateTime>
            {status !== INVOICES_STATES.FULLY_PAID && !isInFuture && (
              <MarkAsPaidButton
                status={status}
                onClick={() => setPaymentDialogOpen(!paymentDialogOpen)}
              >
                {status === INVOICES_STATES.PARTIALLY_PAID ? (
                  <FormattedMessage id='invoices.partially' />
                ) : (
                  <FormattedMessage id='invoices.payment' />
                )}
              </MarkAsPaidButton>
            )}
            {status === INVOICES_STATES.FULLY_PAID && (
              <InvoiceStatus status={status} />
            )}
          </DateTime>
        </Location>
        <Date>{invoice.issueDate} </Date>
        <Date overdue={isOverdue}>{invoice.dueDate}</Date>
        <Actions>
          <Action
            onClick={() => {
              downloadPdf(invoice.id)
            }}
          >
            <IconWithTooltip
              icon={<PdfIcon />}
              title={<FormattedMessage id='orders.downloadPdf' />}
            />
          </Action>
        </Actions>
      </TableRow>
      <MobileInvoiceRow
        downloadInvoicePdf={downloadPdf}
        markAsPaid={setPaymentDialogOpen}
        invoice={invoice}
      />
      <MarkAsPaid
        onConfirm={markAsPaid}
        invoice={invoice}
        open={paymentDialogOpen}
        setOpen={setPaymentDialogOpen}
      />
    </>
  )
}

export default InvoiceRow

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const WaitIcon = styled(Wait)`
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
  margin: 0 0.25rem;
  height: 12px;
  width: 12px;
`

const DateComponent = ({ overdue, className, children }) => (
  <div className={className}>
    {children}
    {overdue && <WaitIcon />}
  </div>
)

export const Date = styled(DateComponent)`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  ${({ overdue }) =>
    overdue &&
    css`
      color: ${({ theme }) => theme.colors.fadedRed.hex()};
    `}
`

const MarkAsPaidButton = styled.button`
  width: 65px;
  padding: 1px;
  margin-top: 2px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white.hex()};
  font-weight: 600;
  appearance: none;
  border: none;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0
    ${({ status, theme }) =>
      status === INVOICES_STATES.PARTIALLY_PAID
        ? theme.colors.california.alpha(0.24).hex()
        : theme.colors.ebonyClay.alpha(0.24).hex()};
  background-color: ${({ status, theme }) =>
    status === INVOICES_STATES.PARTIALLY_PAID
      ? theme.colors.california.hex()
      : theme.colors.ebonyClay.hex()};
`

const NoteIcon = styled(NoteIconUrl)`
  opacity: 0.8;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  flex-shrink: 0;
`

const OffeLinkContainer = styled.div`
  display: flex;
  align-items: center;
`

const Small = styled.div`
  font-size: 0.75rem;
  & > a {
    font-size: 0.75rem;
  }
`
