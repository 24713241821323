import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { useIntl } from 'gatsby-plugin-intl'

import { API_STATES } from 'src/ducks/consts'
import Alert from 'src/components/Alert'
import TextInput from 'src/components/atoms/TextInput'
import {
  InputsRowWrapper,
  InputWrapper
} from 'src/components/atoms/FormHelpers'
import {
  CancelButton,
  SaveButtonWithLoader as SaveButton
} from 'src/components/atoms/Buttons'
import { ButtonsRow } from 'src/features/account/components/atoms'
import { connectCompany } from 'src/features/account/duck/connectors'
import {
  validateMyCompanySchema,
  validateMyCompany
} from 'src/features/account/duck/schema'

const CompanyContactDataForm = ({
  className,
  onCancel,
  updateMyCompany,
  company,
  updateMyCompanyStatus: { changedSuccessfully, errorMessage, state }
}) => {
  const intl = useIntl()
  const [form, setForm] = useState({
    phoneNumber: company.phoneNumber,
    email: company.email,
    homepageUrl: company.homepageUrl
  })

  useEffect(() => {
    changedSuccessfully && onCancel()
  }, [changedSuccessfully])

  const valid = useValidateSchema(form, validateMyCompanySchema)

  const isLoading = state === API_STATES.IN_PROGRESS

  const handleSave = () => {
    updateMyCompany(form)
  }

  const handleChange = (name, value) => setForm({ ...form, [name]: value })

  return (
    <div className={className}>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            type='tel'
            label={intl.formatMessage({ id: 'myCompany.phoneNumberLabel' })}
            id='phoneNumber'
            name='phoneNumber'
            placeholder={intl.formatMessage({
              id: 'myCompany.phoneNumberPlaceholder'
            })}
            value={form.phoneNumber}
            onChange={handleChange}
            validate={validateMyCompany}
          />
        </InputWrapper>
      </InputsRowWrapper>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            label={intl.formatMessage({ id: 'myCompany.email' })}
            id='email'
            name='email'
            placeholder={intl.formatMessage({
              id: 'myCompany.emailPlaceholder'
            })}
            value={form.email}
            onChange={handleChange}
            validate={validateMyCompany}
          />
        </InputWrapper>
      </InputsRowWrapper>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            label={intl.formatMessage({ id: 'myCompany.www' })}
            id='homepageUrl'
            name='homepageUrl'
            placeholder={intl.formatMessage({
              id: 'myCompany.wwwPlaceholder'
            })}
            value={form.homepageUrl}
            onChange={handleChange}
            validate={validateMyCompany}
          />
        </InputWrapper>
      </InputsRowWrapper>
      {errorMessage && <Alert type='alert' message={errorMessage} />}
      <ButtonsRow>
        <SaveButton
          active={valid && !isLoading}
          loading={isLoading}
          onClick={handleSave}
        >
          {intl.formatMessage({ id: 'common.save' })}
        </SaveButton>
        <CancelButton active onClick={onCancel}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </CancelButton>
      </ButtonsRow>
    </div>
  )
}
const StyledUserContactForm = styled(CompanyContactDataForm)`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 400px;
  ${InputsRowWrapper} {
    margin-bottom: 0.5rem;
  }
`

export default connectCompany(StyledUserContactForm)
