import React, { useState, useEffect } from 'react'
import { isNil } from 'ramda'
import { navigate } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { Title } from 'src/features/stocks/components/typography'
import Timeline from 'src/features/stocks/components/OfferDetails/Timeline'
import Shade from 'src/components/Shade'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import BidOffer from 'src/features/stocks/components/BidOffer'
import Contact from 'src/features/stocks/components/OfferDetails/Contact'
import {
  AuctionPriceOfferDetails,
  ButtonsForCustomer,
  ButtonsForOwner,
  ContainerInfo,
  ContainerSemiTrailerInfo,
  CreatedDateOfferDetails,
  CustomsClearance,
  DrawerTitle,
  MiddleContainer,
  Notes,
  OfferBelongsToCurrentUserInfo,
  OfferHasTrackingInfo,
  Price,
  OfferRemovedInfo
} from 'src/features/stocks/components/OfferDetails/atoms'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import CustomIcon from 'src/components/CustomIcon'
import { DATE_LONG_FORMAT } from 'src/ducks/consts'
import moment from 'moment'
import IconWithTooltip from 'src/components/IconWithTooltip'
import { PrivateOfferCountdown } from 'src/features/stocks/components/OfferDetails/atoms'

const OfferDetails = ({
  className,
  setDrawerState,
  offerDetails,
  timelineData,
  checkIfTheOfferBelongsToTheCurrentCompany,
  checkIfTheOfferBelongsToTheCurrentUser,
  updateOffer,
  reserveOffer,
  stock,
  refreshOffer,
  setConversationOpened,
  bidOffer
}) => {
  const offerRemoved = !isNil(offerDetails.deletedAt)
  const isAuction = offerDetails.type === OFFER_TYPES.AUCTION
  const hasTracking =
    offerDetails.doesRequireTracking || offerDetails.doesAllowTracking
  const hasCustomsClearance = offerDetails.customsClearanceLocation.id
  const hasContainer = offerDetails.container && offerDetails.container.name
  const hasContainerSemiTrailer =
    offerDetails.containerSemiTrailer && offerDetails.containerSemiTrailer.name
  const hasNotes = offerDetails.notes
  const hasPrice =
    offerDetails.price && offerDetails.price.float > 0 && !isAuction
  const offerBelongsToCurrentUserCompany = checkIfTheOfferBelongsToTheCurrentCompany(
    offerDetails.issuer.companyId
  )
  const offerBelongsToCurrentUser = checkIfTheOfferBelongsToTheCurrentUser(
    offerDetails.handler.id
  )
  const [bidDialog, setBidDialog] = useState(false)
  const hidePrice =
    !offerBelongsToCurrentUserCompany &&
    !offerDetails.isPriceVisible &&
    offerDetails.reservationsCount === 0

  const changeHandler = newHandlerId => {
    updateOffer(offerDetails.id, { handler_id: newHandlerId })
  }

  const navigateToMyOffers = () => {
    const link = {
      [STOCKS_TYPES.POST_IMPORT]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_POST_IMPORT_OFFERS}`,
      [STOCKS_TYPES.CARGO_EXPORT]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`,
      [STOCKS_TYPES.CARGO_IMPORT]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`,
      [STOCKS_TYPES.FREE_CARRIERS]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_FREE_CARRIERS_OFFERS}`
    }[offerDetails.offerType]
    const options = { state: { openOfferId: offerDetails.id } }
    navigate(link, options)
  }
  if (offerRemoved) {
    return (
      <div className={className}>
        <DrawerTitle setDrawerState={setDrawerState} />
        <OfferRemovedInfo />
      </div>
    )
  }

  return (
    <div className={className}>
      {/* {JSON.stringify(offerDetails)} */}
      {stock?.isLoading('details') && <Shade />}
      <DrawerTitle
        offerDetails={offerDetails}
        setDrawerState={setDrawerState}
      />
      {offerDetails.id && (
        <MiddleContainer>
          {offerBelongsToCurrentUserCompany && (
            <OfferBelongsToCurrentUserInfo />
          )}
          {hasTracking && <OfferHasTrackingInfo />}
          {isAuction && (
            <AuctionPriceOfferDetails
              offerDetails={offerDetails}
              hidePrice={hidePrice}
            />
          )}
          {timelineData && <Timeline data={timelineData} />}
          {hasCustomsClearance && (
            <CustomsClearance offerDetails={offerDetails} />
          )}
          {hasContainer && <ContainerInfo offerDetails={offerDetails} />}
          {hasContainerSemiTrailer && (
            <ContainerSemiTrailerInfo offerDetails={offerDetails} />
          )}
          {hasNotes && <Notes offerDetails={offerDetails} />}
          {hasPrice && <Price offerDetails={offerDetails} />}
          <Contact
            issuer={offerDetails.issuer}
            stockType={stock.offerDetails.offerType}
            handler={offerDetails.handler}
            changeHandler={changeHandler}
            offerBelongsToCurrentUserCompany={offerBelongsToCurrentUserCompany}
            offerBelongsToCurrentUser={offerBelongsToCurrentUser}
            isUpdatable={offerDetails.isUpdatable}
            handleConversationStart={() => {
              setConversationOpened({
                receiverId: offerDetails.handlerId,
                contextId: offerDetails.id,
                contextType: offerDetails.offerContextType,
                conversationOpened: true
              })
            }}
          />
          <CreatedDateOfferDetails offerDetails={offerDetails} />
          {/* {offerDetails.isPrivate && (
            <PrivateOfferCountdown offerDetails={offerDetails} />
          )} */}
        </MiddleContainer>
      )}
      {!offerBelongsToCurrentUserCompany && !offerRemoved && (
        <ButtonsForCustomer
          offerDetails={offerDetails}
          setBidDialog={setBidDialog}
          stock={stock}
          reserveOffer={reserveOffer}
          setConversationOpened={setConversationOpened}
        />
      )}
      {offerBelongsToCurrentUserCompany && !offerRemoved && (
        <ButtonsForOwner
          offerDetails={offerDetails}
          stock={stock}
          refreshOffer={refreshOffer}
          navigateToMyOffers={navigateToMyOffers}
        />
      )}
      <BidOffer
        open={bidDialog}
        setOpen={setBidDialog}
        offer={offerDetails}
        onClick={price => bidOffer({ id: offerDetails.id, price })}
      />
    </div>
  )
}

export default styled(OfferDetails)`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 1.6rem 0 80px 0;
  ${Title} {
    padding-bottom: 4px;
  }
`
