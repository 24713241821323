import { DATE_SHORT_FORMAT } from 'src/ducks/consts'

export const pl = {
  relativeTime: {
    future: 'za %s',
    past: '%s temu',
    s: 'kilka sekund',
    ss: '%d sekund',
    m: 'minutę',
    mm: '%d minut',
    h: 'godzinę',
    hh: '%d godzin',
    d: 'dzień',
    dd: '%d dni',
    M: 'miesiąc',
    MM: '%d miesięcy',
    y: 'rok',
    yy: '%d lat'
  },
  months: 'styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień'.split(
    '_'
  ),
  monthsShort: 'sty_lut_mar_kwi_maj_cze_lip_sie_wrz_paź_lis_gru'.split('_'),
  weekdays: 'niedziela_poniedziałek_wtorek_środa_czwartek_piątek_sobota'.split(
    '_'
  ),
  weekdaysShort: 'ndz_pon_wt_śr_czw_pt_sob'.split('_'),
  weekdaysMin: 'Nd_Pn_Wt_Śr_Cz_Pt_So'.split('_'),
  calendar: {
    lastDay: DATE_SHORT_FORMAT,
    sameDay: '[dziś]',
    nextDay: DATE_SHORT_FORMAT,
    lastWeek: DATE_SHORT_FORMAT,
    nextWeek: DATE_SHORT_FORMAT,
    sameElse: DATE_SHORT_FORMAT
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
}

export const ru = {
  relativeTime: {
    future: 'в %s',
    past: '%s назад',
    s: 'несколько секунд',
    ss: '%d секунд',
    m: 'минута',
    mm: '%d минут',
    h: 'час',
    hh: '%d часов',
    d: 'день',
    dd: '%d дней',
    M: 'месяц',
    MM: '%d месяцев',
    y: 'год',
    yy: '%d лет'
  },
  months: {
    format: 'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split(
      '_'
    ),
    standalone: 'январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь'.split(
      '_'
    )
  },
  monthsShort: {
    // по CLDR именно "июл." и "июн.", но какой смысл менять букву на точку ?
    format: 'янв._февр._мар._апр._мая_июня_июля_авг._сент._окт._нояб._дек.'.split(
      '_'
    ),
    standalone: 'янв._февр._март_апр._май_июнь_июль_авг._сент._окт._нояб._дек.'.split(
      '_'
    )
  },
  weekdays: {
    standalone: 'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split(
      '_'
    ),
    format: 'воскресенье_понедельник_вторник_среду_четверг_пятницу_субботу'.split(
      '_'
    ),
    isFormat: /\[ ?[Вв] ?(?:прошлую|следующую|эту)? ?\] ?dddd/
  },
  weekdaysShort: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
  weekdaysMin: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
  calendar: {
    lastDay: DATE_SHORT_FORMAT,
    sameDay: '[сегодня]',
    nextDay: DATE_SHORT_FORMAT,
    lastWeek: DATE_SHORT_FORMAT,
    nextWeek: DATE_SHORT_FORMAT,
    sameElse: DATE_SHORT_FORMAT
  },
  dayOfMonthOrdinalParse: /\d{1,2}-(й|го|я)/,
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
}

export const de = {
  relativeTime: {
    future: 'für %s',
    past: 'vor %s',
    s: 'Sekunde',
    ss: '%d Sekunden',
    m: 'Minute',
    mm: '%d Minuten',
    h: 'Stunde',
    hh: '%d Stunden',
    d: 'Tag',
    dd: '%d Tagen',
    M: 'Monat',
    MM: '%d Monaten',
    y: 'Jahr',
    yy: '%d Jahren'
  },
  months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split(
    '_'
  ),
  monthsShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split(
    '_'
  ),
  weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split(
    '_'
  ),
  weekdaysShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
  weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
  calendar: {
    lastDay: DATE_SHORT_FORMAT,
    sameDay: '[heute]',
    nextDay: DATE_SHORT_FORMAT,
    lastWeek: DATE_SHORT_FORMAT,
    nextWeek: DATE_SHORT_FORMAT,
    sameElse: DATE_SHORT_FORMAT
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
}

export const en = {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  },
  months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
    '_'
  ),
  monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
    '_'
  ),
  weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
  weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
  calendar: {
    lastDay: DATE_SHORT_FORMAT,
    sameDay: '[today]',
    nextDay: DATE_SHORT_FORMAT,
    lastWeek: DATE_SHORT_FORMAT,
    nextWeek: DATE_SHORT_FORMAT,
    sameElse: DATE_SHORT_FORMAT
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
}
