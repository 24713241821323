import React from 'react'
import { navigate, FormattedMessage } from 'gatsby-plugin-intl'
import styled, { css } from 'styled-components'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import { breakpoint } from 'src/theme/grid'
import routes from 'src/utils/routes'

const TitleDropdown = ({ className, children, setOpen, open }) => (
  <div className={className}>
    <IconButtonStyled>
      <MUIDropdownIcon
        fontSize='small'
        style={{ transform: `rotate(${open ? '180deg' : '0'})` }}
      />
    </IconButtonStyled>
    <ActionMenu active={open}>
      {children.map(item => (
        <MenuItem
          key={item.value}
          onClick={() => {
            setOpen(false)
            navigate(`${routes.APP_DASHBOARD}?tab=${item.value}`)
          }}
        >
          <FormattedMessage id={item.name} />
        </MenuItem>
      ))}
    </ActionMenu>
  </div>
)

export default styled(TitleDropdown)`
  display: inline;
  margin-left: 0.5rem !important;
  ${breakpoint.m`
    z-index: 1;
  `}
`

export const ActionMenu = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  background-color: white;
  display: none;
  flex-direction: column;
  overflow: hidden;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 9999;
  min-width: 200px;
  max-width: 300px;
  ${({ active }) =>
    active &&
    `
      display: flex;
  `}
`

export const MenuItem = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0.9rem 1rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 0.3s, background-color 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.05).hex()};
  }
  > span {
    margin-left: 0.8rem;
  }
  ${({ active }) =>
    active &&
    `
      font-weight: 800;
  `}
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.darkSkyBlue.hex()};
    `}
`

export const IconButtonStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
`
