import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'

const Drawer = ({ className, children }) => (
  <div className={className}>
    <Content>{children}</Content>
  </div>
)

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
`

export default styled(Drawer)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${({ theme }) => `calc(100vh - ${theme.dimensions.topBarHeight})`};
  width: 0;
  position: relative;
  right: 0;
  top: 0;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(36, 45, 63, 0.16);
  box-sizing: border-box;
  z-index: 99;
  ${({ open }) =>
    open &&
    css`
      position: absolute;
      top: ${({ theme }) => theme.dimensions.topBarHeight};
      width: 100%;
      ${breakpoint.m`
    width: 400px;
    position: relative;
    top: 0;
   `}
    `}
`
