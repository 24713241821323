import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import MUIContext from '@material-ui/icons/MoreHoriz'
import useClickOutside from 'src/hooks/useClickOutside'

const ActionsMenu = ({ className, children, onClick = () => null }) => {
  const [open, setOpen] = useState(false)
  const componentRef = useClickOutside(() => setOpen(false))
  const onOpen = e => {
    e.stopPropagation()
    onClick()
    setOpen(!open)
  }

  return (
    <div className={className} ref={componentRef}>
      <IconButtonStyled onClick={onOpen}>
        <ContextMenuIcon />
      </IconButtonStyled>
      <Dropdown open={open}>
        {React.Children.toArray(children).map(child =>
          React.cloneElement(child, {
            ...child?.props,
            onClick: event => {
              setOpen(false)
              child?.props.onClick && child?.props.onClick(event)
            }
          })
        )}
      </Dropdown>
    </div>
  )
}

export default styled(ActionsMenu)`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: visible;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

export const ContextMenuIcon = styled(MUIContext)`
  display: flex;
`

export const IconButtonStyled = styled.span`
  display: flex;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    color: white;
  }
  && svg {
    margin-left: 0;
  }
`
export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  padding: 0.5rem 2.375rem 0.5rem 1rem;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ghost.alpha(0.2).hex()};
  }
  & svg {
    flex-shrink: 0;
    opacity: 0.7;
    margin-right: 1rem;
  }
  & a {
    color: inherit;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

const Dropdown = styled.div`
  display: none;
  right: 0;
  padding: 0.5rem 0;
  position: absolute;
  top: calc(100% + 4px);
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid rgb(198, 203, 212);
  box-shadow: 0 12px 32px -8px rgba(36, 45, 63, 0.24);
  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
`
