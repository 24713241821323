import React from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'
import { Link } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import { Avatar } from 'src/components/atoms/Avatar'
import {
  OfferorName,
  PhoneNumber
} from 'src/features/myOffers/components/atoms'
import ChatIcon from 'src/assets/icons/icon_chat.svg'
import PhoneIcon from 'src/assets/icons/phone.svg'
import { formatPhoneNumber } from 'src/utils/helpers'
import * as routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'

const Offeror = ({
  offeror,
  chatAvailable,
  className,
  setConversationOpened = () => {},
  date = '',
  withDate,
  hidePhoneNumber = false
}) => (
  <div className={className}>
    <Row id='offeror-name-row'>
      <Avatar small url={offeror.avatar && offeror.avatar.url} />
      <OfferorNameAndCompanyBox>
        <OfferorName>{`${offeror.firstName} ${offeror.lastName}`}</OfferorName>
        <OfferorCompany>
          {!withDate && (
            <Link
              to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.COMPANY}&companyId=${offeror.company.id}`}
            >
              {`${offeror.company.name}`}
            </Link>
          )}
          {withDate && <span>{date}</span>}
        </OfferorCompany>
      </OfferorNameAndCompanyBox>
    </Row>
    {!hidePhoneNumber && (
      <ContactRow id='offeror-contact-row'>
        {offeror.phoneNumber && !isEmpty(offeror.phoneNumber) && (
          <PhoneNumber href={`tel:${offeror.phoneNumber}`}>
            <span>{formatPhoneNumber(offeror.phoneNumber)}</span>
            <PhoneIcon />
          </PhoneNumber>
        )}
        {chatAvailable && <ChatIconStyled onClick={setConversationOpened} />}
      </ContactRow>
    )}
  </div>
)
const StyledOfferor = styled(Offeror)`
  grid-area: offeror;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoint.m`
     justify-content: flex-start;
  `}

  & ${PhoneNumber} {
    & > span {
      display: none;
      ${breakpoint.m`
     display: inline;
  `}
    }

    & > svg {
      ${breakpoint.m`
     display: none;
  `}
    }
  }

  & svg {
    margin-left: 1rem;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
    cursor: pointer;
    &:hover {
      transition: color 0.3s;
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
    }
    ${breakpoint.m`
     display: inline-block;
     margin-left: 1.5rem;
  `}
  }
`

export default StyledOfferor

export const OfferorWithoutGrid = styled(Offeror)`
  justify-content: space-between;
  align-items: center;
  ${breakpoint.m`
     justify-content: flex-start;
  `}

  & ${PhoneNumber} {
    & > span {
      display: none;
      ${breakpoint.m`
     display: inline;
  `}
    }

    & > svg {
      ${breakpoint.m`
     display: none;
  `}
    }
  }

  & svg {
    margin-left: 1rem;
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    ${breakpoint.m`
     display: inline-block;
     margin-left: 1.5rem;
  `}
  }
`
const OfferorCompany = styled(OfferorName)`
  font-size: 0.75rem;
  opacity: 0.7;
  a:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const Row = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  flex-direction: row;
  & > ${Avatar} {
    margin-right: 0.5rem;
  }
  ${breakpoint.m`
  flex: 0.5;
  `}
`

const ContactRow = styled(Row)`
  flex-shrink: 0;
  ${breakpoint.m`
    flex: 1;
    justify-content: flex-start;
  `}
`

const OfferorNameAndCompanyBox = styled.div`
  min-width: 0;
`

const ChatIconStyled = styled(ChatIcon)`
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`
