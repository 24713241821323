import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'
import { DateTime, Ellipsis } from 'src/features/stocks/components/atoms'
import { breakpoint } from 'src/theme/grid'
import {
  ContractLocations,
  EllipsisContainer
} from 'src/features/contracts/components/atoms'
import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import CompanyIconUrl from 'src/assets/icons/work-case.svg'
import ContainerIconUrl from 'src/assets/icons/container.svg'
import ShipIconUrl from 'src/assets/icons/ship.svg'
import * as routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import PdfIcon from 'src/assets/icons/pdf.svg'
import OrderStatusDot from 'src/features/orders/components/OrderStatusDot'
import { checkOrderStatus } from 'src/utils/helpers'
import ImportIconUrl from 'src/assets/icons/import.svg'
import ExportIconUrl from 'src/assets/icons/export.svg'
import { connectOrderDetails } from 'src/features/orders/duck/connectors'

const MobileTableRow = ({ order, isNew, className, downloadOrderPdf }) => {
  const handleOfferNumberClick = () => {
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${order.id}`
    )
  }

  const status = checkOrderStatus(order)

  return (
    <>
      <div className={className}>
        <TopRow>
          <Status>
            <OrderStatusDot status={status} />
            <EllipsisContainer>
              <Ellipsis>
                <FormattedMessage id={`orders.${status}`} />
              </Ellipsis>
            </EllipsisContainer>
          </Status>
          {order.acceptedAt && (
            <ActionsMenu>
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  downloadOrderPdf(order.id)
                }}
              >
                <PdfIcon />
                <FormattedMessage id='orders.downloadPdf' />
              </DropdownItem>
            </ActionsMenu>
          )}
        </TopRow>
        <TopRow>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <OfferLink onClick={handleOfferNumberClick}>
              {order.referenceNumber}
            </OfferLink>
          </div>
          <DateAndActionsContainer>
            <CreatedAtField isNew={isNew} addedAt={order.createdAt} />
          </DateAndActionsContainer>
        </TopRow>
        <Subtitle>
          <FormattedMessage id='orders.loadingUnloading' />
        </Subtitle>
        <LocationRow>
          <EllipsisContainer>
            <Ellipsis>{order.loadingUnloadingAddress}</Ellipsis>
            <DateTime>{order.loadingUnloadingDateTime}</DateTime>
          </EllipsisContainer>
        </LocationRow>
        <DetailsRow>
          {order.transportationType && (
            <div>
              <EllipsisTooltip>
                <span>
                  {order.transportationType === 'import' ? (
                    <ImportIcon />
                  ) : (
                    <ExportIcon />
                  )}
                  <FormattedMessage
                    id={`common.${order.transportationType}`}
                    defaultMessage='-'
                  />
                </span>
              </EllipsisTooltip>
            </div>
          )}
          {order.container.id && (
            <div>
              <EllipsisTooltip>
                <span>
                  <ContainerIcon />
                  {order.container.name}
                </span>
              </EllipsisTooltip>
            </div>
          )}
          {order.shipowner.id && (
            <div>
              <EllipsisTooltip>
                <span>
                  <ShipIcon />
                  {order.shipowner.name}
                </span>
              </EllipsisTooltip>
            </div>
          )}
        </DetailsRow>
        <Subtitle>
          {order.forwarderCompany.id ? (
            <FormattedMessage id='orders.issuer' />
          ) : (
            <FormattedMessage id='orders.contractor' />
          )}
        </Subtitle>
        <DetailsRow>
          <div>
            <EllipsisTooltip>
              <span>
                <CompanyIcon />
                {order.forwarderCompany.name || order.carrierCompany.name}
              </span>
            </EllipsisTooltip>
          </div>
        </DetailsRow>
      </div>
    </>
  )
}

const LocationRow = styled.div`
  display: flex;
  align-items: center;
  ${DateTime} {
    text-align: left;
  }
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: -0.4rem;
`
const MoreContractsCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  cursor: pointer;
  font-size: 0.75rem;
`

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const TopRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.75rem;
  font-weight: normal;
  & > div {
    overflow: hidden;
    padding: 0 0.5rem;
    border-right: 1px solid
      ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border: none;
    }
  }
`

const DateAndActionsContainer = styled.div`
  display: flex;
`
const CompanyIcon = styled(CompanyIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
`

const ImportIcon = styled(ImportIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const ExportIcon = styled(ExportIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const ContainerIcon = styled(ContainerIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -5px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const ShipIcon = styled(ShipIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const Status = styled.div`
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`

const StyledComponent = styled(MobileTableRow)`
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(36, 45, 63, 0.15);
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  & > div {
    margin-top: 0.4rem;
    &:first-child {
      margin-top: 0;
    }
  }
  ${ContractLocations} {
    font-size: 0.875rem;
    padding-top: 0;
    ${MoreContractsCounter} {
      margin-left: auto;
    }
  }
  ${breakpoint.m`
      display: none;
    `}
`

export default connectOrderDetails(StyledComponent)
