import React, { useEffect } from 'react'
import { flatten } from 'ramda'
import styled, { css } from 'styled-components'
import { isFalsy } from 'ramda-adjunct'
import Linkify from 'react-linkify'
import MUIDoneIcon from '@material-ui/icons/Done'
import ExampleUserAvatarUrl from 'src/assets/avatar-placeholder.svg'
import { formatMessagesGroupTimestamp } from 'src/utils/helpers'
import debounce from 'lodash.debounce'

const MessagesList = ({
  className,
  lastRequest,
  messages,
  markConversationAsSeen,
  markMessageAsSeen,
  conversationOpened,
  getMessages,
  conversationId,
  messagesRef
}) => {
  useEffect(() => {
    if (flatten(messages).length > 0 && conversationOpened) {
      markConversationAsSeen()
    }
  }, [flatten(messages).length])

  useEffect(() => {
    if (flatten(messages).length === 10 && conversationOpened) {
      messagesRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  }, [flatten(messages).length])

  const debouncedGetMessages = debounce(element => {
    if (lastRequest?.[conversationId]?.length > 0) {
      getMessages({
        conversationId,
        values: { limit: 5, maxSentDate: messages[0][0].createdAt }
      })
      element.scrollBy(0, 290)
    }
  }, 200)

  const handleScroll = event => {
    const element = event.target
    if (element.scrollTop === 0) {
      debouncedGetMessages(element)
    }
  }

  return (
    <div className={className} onScroll={handleScroll}>
      <InnerWrapper ref={messagesRef}>
        {messages.map(group =>
          group.map((message, index) => {
            if (message.isSystemMessage) {
              return (
                <>
                  {index === 0 && (
                    <Timestamp id={`message-timestamp-system-${message.id}`}>
                      {formatMessagesGroupTimestamp(message.createdAt)}
                    </Timestamp>
                  )}
                  <SystemMessageStyled message={message.text} />
                </>
              )
            }

            return (
              <>
                {index === 0 && (
                  <Timestamp id={`message-timestamp-default-${message.id}`}>
                    {formatMessagesGroupTimestamp(message.createdAt)}
                  </Timestamp>
                )}
                <MessageStyled
                  onSeen={() =>
                    markMessageAsSeen({ conversationId, messageId: message.id })
                  }
                  message={message}
                  isInterlocutorMessage={message.isInterlocutorMessage}
                />
              </>
            )
          })
        )}
      </InnerWrapper>
    </div>
  )
}

export default styled(MessagesList)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: inline-block;
`

const InnerWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0.8rem 1.2rem;
  overflow-x: hidden;
`

const Avatar = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-image: url(${({ url }) => url || ExampleUserAvatarUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 14px 0 10px;
`

const CheckIcon = styled(MUIDoneIcon)`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  position: absolute;
  right: -16px;
  bottom: 2px;
`

const MessageComponent = ({
  className,
  message,
  isInterlocutorMessage,
  onSeen
}) => {
  const messageSeen = !isFalsy(message.seenAt)

  useEffect(() => {
    !messageSeen && message.id && isInterlocutorMessage && onSeen()
  }, [])
  return (
    <div className={className}>
      <Avatar url={message.avatar} />
      <TextMessage
        isInterlocutorMessage={isInterlocutorMessage}
        messageSeen={messageSeen}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target='_blank' href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {message.text}
        </Linkify>
        {messageSeen && <CheckIcon />}
      </TextMessage>
    </div>
  )
}

const MessageStyled = styled(MessageComponent)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
  align-items: flex-end;
  ${({ isInterlocutorMessage }) =>
    !isInterlocutorMessage &&
    css`
      justify-content: flex-end;
      ${Avatar} {
        display: none;
      }
    `}
`

const TextMessage = styled.div`
  display: inline-block;
  min-height: 35px;
  border-radius: 19px;
  padding: 8px 16px;
  max-width: 85%;
  color: white;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.bluewood.hex()};
  font-size: 14px;
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;
  a {
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
  ${({ isInterlocutorMessage }) =>
    isInterlocutorMessage &&
    css`
      color: ${({ theme }) => theme.colors.ebonyClay.hex()};
      background-color: ${({ theme }) => theme.colors.athensGray.hex()};
      a {
        color: ${({ theme }) => theme.colors.ebonyClay.hex()};
      }
    `}
  ${CheckIcon} {
    display: none;
  }
  ${({ isInterlocutorMessage, messageSeen }) =>
    !isInterlocutorMessage &&
    messageSeen &&
    css`
      ${CheckIcon} {
        display: flex;
      }
    `}
`

const SystemMessage = ({ className, message }) => (
  <div className={className}>
    <span>{message}</span>
  </div>
)

const SystemMessageStyled = styled(SystemMessage)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
  &:before {
    content: '';
    width: calc(100% + 2.4rem);
    height: 1px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.iceberg.hex()};
    position: absolute;
    top: 50%;
    left: -1.2rem;
    z-index: 1;
  }
  span {
    display: flex;
    position: relative;
    width: auto;
    max-width: calc(100% - 2.4rem);
    border-radius: 19px;
    padding: 7px 16px;
    font-size: 12px;
    background-color: ${({ theme }) => theme.colors.iceberg.hex()};
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.bluewood.hex()};
    border: solid 1px rgba(19, 162, 191, 0.15);
    text-align: center;
    z-index: 10;
  }
`

const Timestamp = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  margin: 0.4rem 0;
  font-weight: 600;
`
