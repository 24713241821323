import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import { path, add } from 'ramda'
import { BidButton, CancelButton } from 'src/components/atoms/Buttons'
import ContractIcon from 'src/assets/icons/description.svg'
import { DATE_LONG_FORMAT_WITH_WEEKDAY, MIN_BID } from 'src/ducks/consts'
import {
  DateTitle,
  Description,
  Title
} from 'src/components/atoms/AuctionPrice'
import XIcon from 'src/assets/icons/close-x.svg'
import { breakpoint } from 'src/theme/grid'
import Stepper from 'src/components/atoms/Stepper'
import OfferInfoBoxContract from 'src/components/OfferInfoBoxContract'
import {
  ContractDates,
  Type
} from 'src/features/stocks/components/OfferDetails/atoms'
import { Description as Conditions } from 'src/features/stocks/components/typography'

export default ({ onClick, offer, open, setOpen }) => {
  const intl = useIntl()
  const [price, setPrice] = useState(0)
  const minBid = MIN_BID[offer.currency]

  const offerPrice = path(['price', 'float'], offer)

  const initPrice = add(offerPrice, minBid)

  useEffect(() => {
    setPrice(initPrice)
  }, [offer.price.float])

  const onClose = () => {
    setOpen(false)
    setPrice(initPrice)
  }

  const isValid = price >= minBid && price >= initPrice

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CloseIcon onClick={onClose} />
          <DialogHeader>
            <FormattedMessage id='makeABid.offerContractRate' />
          </DialogHeader>
          <PriceContainer>
            <Column>
              <StepperText>
                {intl.formatMessage({
                  id: 'makeABid.yourOffer'
                })}
              </StepperText>
              <StepperRow>
                <Stepper
                  step={minBid}
                  minValue={initPrice}
                  maxValue={Number.MAX_SAFE_INTEGER}
                  value={price}
                  onChange={setPrice}
                  withInput
                />
                <Currency>{offer.currency}</Currency>
              </StepperRow>
            </Column>
            <Column>
              <Title>
                <FormattedMessage id='makeABid.validOffer' />
              </Title>
              <DateTitle>
                {moment(offer.validUntilDate).fromNow(true)}
                <FormattedMessage id='offerDetails.toEnd' />
              </DateTitle>
              <Description>
                (
                {moment(offer.validUntilDate).format(
                  DATE_LONG_FORMAT_WITH_WEEKDAY
                )}
                )
              </Description>
            </Column>
          </PriceContainer>
          <DetailsContainer>
            <TypeColumn>
              <Type offerDetails={offer} />
            </TypeColumn>
            <Column>
              <ContractDates offerDetails={offer} withIcon />
            </Column>
          </DetailsContainer>
          {offer.conditions && (
            <>
              <DialogSubtitle>
                <FormattedMessage id='forms.contractTerms' />
              </DialogSubtitle>
              <StyledConditions>{offer.conditions}</StyledConditions>
            </>
          )}
          <DialogSubtitle>
            <FormattedMessage
              id='makeABid.contractLines'
              values={{ count: offer.transportations.length }}
            />
          </DialogSubtitle>
          <OfferInfoBoxContract
            offer={offer}
            offerorCompany={offer.issuer.company.name}
          />
          <ActionBar>
            <CancelButton
              active
              onClick={() => {
                setOpen(false)
              }}
            >
              {intl.formatMessage({ id: 'common.cancel' })}
            </CancelButton>
            <BidButton
              active={isValid}
              onClick={() => {
                onClose()
                onClick(price)
              }}
            >
              <FormattedMessage id='offerDetails.makeABid' />
              <span>
                <ContractIcon />
              </span>
            </BidButton>
          </ActionBar>
        </DialogContent>
      </Dialog>
    </>
  )
}

const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    width: 600px;
    overflow-x: hidden;
    padding: 1.6rem 2.2rem;
  `}
`

const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.78;
  margin: 0.8rem 0 0.875rem 0;
  width: 100%;
  display: inline-block;
`

const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
`

const Currency = styled(DialogText)`
  width: auto;
`

const StepperText = styled(DialogText)`
  width: auto;
  margin-right: 1.2rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  span {
    opacity: 0.6;
  }
`

const DialogSubtitle = styled.h4`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  ${breakpoint.m`
    flex-direction: row;
    margin: 0.875rem 0 0.5rem 0;
  `}
`

const DetailsContainer = styled.div`
  margin: 0.875rem 0 0.5rem 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem;
  ${breakpoint.m`
    flex-direction: row;
  `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  ${breakpoint.m`
      margin-top: 0;
      margin-bottom: 0;
      &:nth-of-type(odd) {
        padding-right: 1rem;
      }
      &:nth-of-type(even) {
        padding-left: 1rem;
      }
  `}
`

const TypeColumn = styled(Column)`
  ${breakpoint.m`
  border-right: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  `}
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  padding-bottom: 0.8rem;
  && > button {
    flex: none;
    margin-right: 0;
  }
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    padding-bottom: 0.8rem;
  `}
`

const StepperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > svg {
    display: none;
  }
  & > p {
    margin-left: 0.5rem;
  }
  ${breakpoint.m`
    & > svg {
    display: inline;
    }
  `}
`

const StyledConditions = styled(Conditions)`
  margin-bottom: 0.5rem;
`
