import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { forEachObjIndexed, isNil } from 'ramda'
import { isEmptyString } from 'ramda-adjunct'
import { useIntl } from 'gatsby-plugin-intl'
import debounce from 'lodash.debounce'

import { breakpoint } from 'src/theme/grid'

import useClickOutside from 'src/hooks/useClickOutside'
import withGoogleMapsHelpers from 'src/hocs/withGoogleMapsHelpers'
import { AddressData } from 'src/features/tracking/duck/records'
import { normalizeAddressData } from 'src/features/tracking/duck/normalizers'
import {
  SelectInput,
  FilterDropdown,
  SearchInput,
  SearchInputWrapper,
  SelectOptionsWrapper,
  SelectRow
} from './components/atoms'

const PlaceBox = ({
  className,
  id,
  label,
  locationName,
  locationValue,
  placeholder,
  onChange,
  required,
  helperText,
  pristine,
  validate,
  searchHintText,
  fetchPredictionsByText,
  predictions,
  getAddressByPlaceId,
  locationType
}) => {
  const intl = useIntl()
  const [searchText, setSearchText] = useState('')
  const [filterOpened, setFilterOpened] = useState(false)
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })
  const toggleDropdown = () => setFilterOpened(!filterOpened)
  const componentRef = useClickOutside(() => setFilterOpened(false))
  const emptyText = isEmptyString(searchText) || searchText.length < 2

  const debouncedSearch = useCallback(
    debounce(text => fetchPredictionsByText(text), 200),
    []
  )

  useEffect(() => {
    if (!searchText) return
    debouncedSearch(searchText)
  }, [searchText])

  useEffect(() => {
    if (emptyText) return
    debouncedSearch(searchText)
  }, [searchText])

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(locationName, locationValue, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [locationValue, inputPristine, pristine])

  const handleClose = () => {
    _setPristine(false)
    setFilterOpened(false)
  }

  const handleClear = () => {
    const addressData = normalizeAddressData(locationType)(AddressData().toJS())
    forEachObjIndexed((value, key) => onChange(key, value))(addressData)
    setFilterOpened(false)
    setSearchText('')
  }

  const handlePredictionClick = ({ placeId }) => {
    getAddressByPlaceId({ placeId }).then(response => {
      const addressData = normalizeAddressData(locationType)(response.toJS())
      forEachObjIndexed((value, key) => onChange(key, value))(addressData)
      setFilterOpened(false)
    })
  }

  return (
    <div className={className} ref={componentRef}>
      <Label required={required}>{label}</Label>
      <SelectInput
        onClick={toggleDropdown}
        opened={filterOpened}
        hasValue={!isNil(locationValue)}
        id={`filter-${id}`}
        selectedNumber={null}
        onClear={handleClear}
      >
        {locationValue}
      </SelectInput>
      {filterOpened && (
        <FilterDropdownStyled
          filterName={label}
          onClose={() => handleClose()}
          onClear={handleClear}
          clearButtonText={intl.formatMessage({ id: 'offersFilters.clear' })}
          confirmButtonText={intl.formatMessage({
            id: 'offersFilters.confirm'
          })}
        >
          <SearchInputWrapper>
            <SearchInput
              name={locationName}
              id={`input-${id}`}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder={placeholder}
            />
          </SearchInputWrapper>
          <SelectOptionsWrapperStyled>
            {emptyText && <SearchHint>{searchHintText}</SearchHint>}
            {!emptyText &&
              predictions.map(option => (
                <SelectRow
                  key={option.placeId}
                  name={option.name}
                  onClick={() =>
                    option.placeId &&
                    handlePredictionClick({
                      placeId: option.placeId
                    })
                  }
                />
              ))}
          </SelectOptionsWrapperStyled>
        </FilterDropdownStyled>
      )}
      <HelperText valid={valid}>{valid ? helperText : error}</HelperText>
    </div>
  )
}

PlaceBox.defaultProps = {
  value: '',
  pristine: true,
  validate: () => true
}

const StyledPlaceBox = styled(PlaceBox)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${FilterDropdown} {
    ${breakpoint.m`
      width: 320px;
      height: 400px;
    `}
  }
`

export default withGoogleMapsHelpers(StyledPlaceBox)

const SelectOptionsWrapperStyled = styled(SelectOptionsWrapper)`
  height: calc(100% - 40px);
`

const Label = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`

const FilterDropdownStyled = styled(FilterDropdown)`
  ${breakpoint.m`
    top: 70px;
  `}
`

const HelperText = styled.span`
  display: inline-block;
  width: 100%;
  margin: 8px 6px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1em;
  color: ${({ theme, valid }) =>
    valid ? theme.colors.ghost.hex() : theme.colors.error.hex()};
`

const SearchHint = styled.span`
  display: flex;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;
`
