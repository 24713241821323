import { connect } from 'react-redux'
import {
  selectInvoiceAddForm,
  selectInvoiceSettings,
  selectInvoicesIssued,
  selectInvoicesIssuedButtonsState,
  selectInvoicesIssuedFilters,
  selectInvoicesIssuedLength,
  selectInvoicesIssuedPagination,
  selectInvoicesReceived,
  selectInvoicesReceivedButtonsState,
  selectInvoicesReceivedFilters,
  selectInvoicesReceivedPagination,
  selectAllInvoicesIssued,
  selectAllInvoicesReceived,
  selectInvoiceDetails,
  selectNoteDetails,
  selectCorrectiveInvoiceDetails,
  selectCorrectiveInvoiceAddForm
} from 'src/features/invoices/duck/selectors'
import {
  selectCurrentUserId,
  selectIsCurrentCompanyCarrier
} from 'src/features/account/duck/selectors'
import {
  getInvoicesListIssuedRoutine,
  getInvoicesListReceivedRoutine,
  saveMyCompanyInvoiceSettingsRoutine,
  setInvoicesIssuedFiltersRoutine,
  setInvoicesReceivedFiltersRoutine,
  showMyCompanyInvoiceSettingsRoutine,
  setInvoiceSettingsValueRoutine,
  prepareInvoiceRoutine,
  setInvoiceEditFormValueRoutine,
  createInvoiceRoutine,
  recalculateInvoiceRoutine,
  regenerateInvoiceNumberRoutine,
  searchForInvoiceRecipientRoutine,
  clearRecipientRoutine,
  setInvoiceMultipleFormValuesRoutine,
  previewInvoiceRoutine,
  downloadInvoicePdfRoutine,
  markAsPaidRoutine,
  showInvoiceDetailsRoutine,
  clearInvoiceDetailsRoutine,
  deletePaymentRoutine,
  clearRecipientSearchRoutine,
  clearAddInvoiceFormRoutine,
  createCorrectionNoteRoutine,
  showCorrectionNoteRoutine,
  downloadNotePdfRoutine,
  markCorrectiveAsPaidRoutine,
  deletePaymentFromCorrectiveInvoiceRoutine,
  downloadCorrectiveInvoiceRoutine,
  showCorrectiveInvoiceDetailsRoutine,
  clearCorrectiveInvoiceDetailsRoutine,
  recalculateCorrectiveInvoiceRoutine,
  prepareCorrectiveInvoiceRoutine,
  createCorrectiveInvoiceRoutine,
  previewCorrectiveInvoiceRoutine,
  setCorrectiveInvoiceEditFormValueRoutine,
  clearAddCorrectiveInvoiceFormRoutine
} from 'src/features/invoices/duck/actions'
import { setConversationOpenedRoutine } from 'src/features/messages/duck/actions'
import { API_STATES } from 'src/ducks/consts'

export const connectInvoicesIssued = connect(
  state => ({
    invoicesIssued: selectAllInvoicesIssued(state),
    invoices: selectInvoicesIssued(state),
    filters: selectInvoicesIssuedFilters(state),
    pagination: selectInvoicesIssuedPagination(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectInvoicesIssuedButtonsState(state)
  }),
  dispatch => ({
    downloadInvoicePdf: payload => dispatch(downloadInvoicePdfRoutine(payload)),
    downloadCorrectiveInvoicePdf: payload =>
      dispatch(downloadCorrectiveInvoiceRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(setInvoicesIssuedFiltersRoutine({ name, value })),
    getInvoicesListIssued: () => dispatch(getInvoicesListIssuedRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    markAsPaid: payload => dispatch(markAsPaidRoutine(payload)),
    markCorrectiveAsPaid: payload =>
      dispatch(markCorrectiveAsPaidRoutine(payload))
  })
)

export const connectInvoicesReceived = connect(
  state => ({
    receivedInvoices: selectAllInvoicesReceived(state),
    invoices: selectInvoicesReceived(state),
    filters: selectInvoicesReceivedFilters(state),
    pagination: selectInvoicesReceivedPagination(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectInvoicesReceivedButtonsState(state)
  }),
  dispatch => ({
    downloadInvoicePdf: payload => dispatch(downloadInvoicePdfRoutine(payload)),
    downloadCorrectiveInvoicePdf: payload =>
      dispatch(downloadCorrectiveInvoiceRoutine(payload)),
    setFilter: (name, value) =>
      dispatch(setInvoicesReceivedFiltersRoutine({ name, value })),
    getInvoicesListReceived: () => dispatch(getInvoicesListReceivedRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectInvoicesList = connect(
  state => ({
    isCurrentCompanyCarrier: selectIsCurrentCompanyCarrier(state)
  }),
  dispatch => ({
    getInvoicesListReceived: () => dispatch(getInvoicesListReceivedRoutine()),
    getInvoicesListIssued: () => dispatch(getInvoicesListIssuedRoutine())
  })
)

export const connectAddInvoice = connect(
  state => ({
    settings: selectInvoiceSettings(state),
    invoicesIssuedLength: selectInvoicesIssuedLength(state),
    invoice: selectInvoiceAddForm(state)
  }),
  dispatch => ({
    searchForInvoiceRecipient: value =>
      dispatch(searchForInvoiceRecipientRoutine({ value })),
    recalculateInvoice: () => dispatch(recalculateInvoiceRoutine()),
    regenerateInvoiceNumber: () => dispatch(regenerateInvoiceNumberRoutine()),
    showMyCompanyInvoiceSettings: () =>
      dispatch(showMyCompanyInvoiceSettingsRoutine()),
    saveMyCompanyInvoiceSettings: payload =>
      dispatch(saveMyCompanyInvoiceSettingsRoutine(payload)),
    setInvoiceSettingsValue: (name, value) =>
      dispatch(setInvoiceSettingsValueRoutine({ name, value })),
    prepareInvoice: () => dispatch(prepareInvoiceRoutine()),
    createInvoice: () => dispatch(createInvoiceRoutine()),
    previewInvoice: () => dispatch(previewInvoiceRoutine()),
    setInvoiceEditFormValue: (name, value, onSuccess) =>
      dispatch(setInvoiceEditFormValueRoutine({ name, value, onSuccess })),
    setInvoiceMultipleFormValues: payload =>
      dispatch(setInvoiceMultipleFormValuesRoutine(payload)),
    clearRecipient: () => dispatch(clearRecipientRoutine()),
    clearAddInvoiceForm: () => dispatch(clearAddInvoiceFormRoutine())
  })
)

export const connectInvoiceDetails = connect(
  state => ({
    invoice: selectInvoiceDetails(state)
  }),
  dispatch => ({
    markAsPaid: payload => dispatch(markAsPaidRoutine(payload)),
    deletePayment: payload => dispatch(deletePaymentRoutine(payload)),
    downloadInvoicePdf: payload => dispatch(downloadInvoicePdfRoutine(payload)),
    showInvoiceDetails: id => dispatch(showInvoiceDetailsRoutine(id)),
    clearInvoiceDetails: () => dispatch(clearInvoiceDetailsRoutine())
  })
)

export const connectSearchRecipient = connect(
  state => ({
    invoice: selectInvoiceAddForm(state),
    predictions: state.invoices.add.recipientSearchResults,
    isLoading:
      state.invoices.add.recipientSearchState === API_STATES.IN_PROGRESS
  }),
  dispatch => ({
    onSearch: value => dispatch(searchForInvoiceRecipientRoutine({ value })),
    onClear: () => dispatch(clearRecipientRoutine()),
    clearRecipientSearch: () => dispatch(clearRecipientSearchRoutine())
  })
)

export const connectInvoiceNote = connect(
  state => ({
    invoiceDetails: selectInvoiceDetails(state),
    note: selectNoteDetails(state)
  }),
  dispatch => ({
    downloadNotePdf: payload => dispatch(downloadNotePdfRoutine(payload)),
    showCorrectionNote: id => dispatch(showCorrectionNoteRoutine({ id })),
    showInvoiceDetails: id => dispatch(showInvoiceDetailsRoutine(id)),
    clearInvoiceDetails: () => dispatch(clearInvoiceDetailsRoutine()),
    createCorrectionNote: payload =>
      dispatch(createCorrectionNoteRoutine(payload))
  })
)

export const connectCorrectiveInvoiceDetails = connect(
  state => ({
    invoice: selectCorrectiveInvoiceDetails(state)
  }),
  dispatch => ({
    markAsPaid: payload => dispatch(markCorrectiveAsPaidRoutine(payload)),
    deletePayment: payload =>
      dispatch(deletePaymentFromCorrectiveInvoiceRoutine(payload)),
    downloadInvoicePdf: payload =>
      dispatch(downloadCorrectiveInvoiceRoutine(payload)),
    showInvoiceDetails: id => dispatch(showCorrectiveInvoiceDetailsRoutine(id)),
    clearInvoiceDetails: () => dispatch(clearCorrectiveInvoiceDetailsRoutine())
  })
)

export const connectAddCorrectiveInvoice = connect(
  state => ({
    invoice: selectCorrectiveInvoiceAddForm(state)
  }),
  dispatch => ({
    recalculateInvoice: () => dispatch(recalculateCorrectiveInvoiceRoutine()),
    prepareInvoice: id => dispatch(prepareCorrectiveInvoiceRoutine({ id })),
    createInvoice: () => dispatch(createCorrectiveInvoiceRoutine()),
    previewInvoice: () => dispatch(previewCorrectiveInvoiceRoutine()),
    setInvoiceEditFormValue: (name, value, onSuccess) =>
      dispatch(
        setCorrectiveInvoiceEditFormValueRoutine({
          name,
          value,
          onSuccess
        })
      ),
    clearAddInvoiceForm: () => dispatch(clearAddCorrectiveInvoiceFormRoutine())
  })
)
