import React, { useEffect, useState } from 'react'
import { FormattedMessage, FormattedPlural, useIntl } from 'gatsby-plugin-intl'
import { any, filter, includes, toLower } from 'ramda'

import {
  Column,
  DocumentsHeaderItem,
  DocumentsTableRow,
  Icon,
  UsersHeaderItem
} from 'src/features/account/components/atoms'

import {
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  Row
} from 'src/features/stocks/components/atoms'

import { IconChat, IconEdit } from 'src/features/account/components/rows'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import TextInput from 'src/components/atoms/TextInput'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { USERS_HEADERS_PUBLIC } from 'src/features/companies/duck/consts'
import { UsersRowPublic } from 'src/features/companies/components/UsersRowPublic'

const CompanyUsersPublic = ({ users }) => {
  const intl = useIntl()
  const [search, setSearch] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(users)

  useEffect(() => {
    if (search.length > 0) {
      setFilteredUsers(
        filter(
          user =>
            any(includes(toLower(search)), [
              toLower(user.username),
              toLower(user.firstName),
              toLower(user.lastName)
            ]),
          users
        )
      )
    } else {
      setFilteredUsers(users)
    }
  }, [search, users])

  return (
    <Column>
      <Row>
        <Counter>
          <span>{filteredUsers.length} </span>
          <FormattedPlural
            value={filteredUsers.length}
            other={intl.formatMessage({ id: 'myCompany.users.users' })}
            one={intl.formatMessage({ id: 'myCompany.users.user' })}
          />
        </Counter>
        <SearchInputContainer>
          <TextInput
            type='search'
            name='searchUser'
            placeholder={intl.formatMessage({
              id: 'myCompany.users.searchUser'
            })}
            value={search}
            onChange={(name, value) => setSearch(value)}
            validate={() => true}
          />
        </SearchInputContainer>
      </Row>
      <StyledContainer>
        <UsersTableHeader numberOfHeaders={USERS_HEADERS_PUBLIC.length}>
          {USERS_HEADERS_PUBLIC &&
            USERS_HEADERS_PUBLIC.map((header, index) => (
              <UsersHeaderItem key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage id={header.name} defaultMessage=' ' />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </UsersHeaderItem>
            ))}
        </UsersTableHeader>
        {filteredUsers.length > 0 &&
          filteredUsers.map((user, index) => (
            <UsersTableRow
              key={index}
              numberOfHeaders={USERS_HEADERS_PUBLIC.length}
            >
              <UsersRowPublic user={user} onChat={() => null} />
            </UsersTableRow>
          ))}
        {users.length === 0 && (
          <EmptyOffers>
            <FormattedMessage id='myCompany.documents.noUsers' />
          </EmptyOffers>
        )}
      </StyledContainer>
    </Column>
  )
}
export default CompanyUsersPublic

const StyledContainer = styled(Container)`
  margin: 0 -0.8rem;
  width: auto;
  ${breakpoint.m`
      margin: initial;
      width: 100%;
  `}
`

const SearchInputContainer = styled.div`
  flex: 1;
  margin: 0 0.5rem -1rem;
  transition: all 0.3s;
  & input {
    padding-left: 0;
  }

  ${breakpoint.s`
    flex: 0.4;
  `}
`
const UsersTableRow = styled(DocumentsTableRow)`
  ${IconEdit}, ${IconChat} {
    display: none;
  }

  > div:first-of-type {
    padding-left: 10px;
    ${breakpoint.m`
      padding-left: 25px;
      `}
  }
  border-radius: 4px;
  margin-bottom: 4px;
  overflow: hidden;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'name name name status status'
    'username username email email email';

  &:hover {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    transform: translate(-16%, 0px);
    transition: transform 0.3s;
    width: 120%;
    grid-template-areas:
      'name name name status status icon2 icon1'
      'username username email email email icon2 icon1';

    ${IconEdit}, ${IconChat} {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
      i {
        color: white;
      }
    }

    ${IconEdit} {
      background-color: ${({ theme }) => theme.colors.bluewood.hex()};
    }
  }

  ${breakpoint.m`
    border-radius: 0;
    margin-bottom: 0;
    grid-template-rows: none;
    grid-template-columns: 2fr 1fr 2fr repeat(2, 1fr) 0.5fr;
     grid-template-areas:
    'name username email status role icon2';

    &:hover {
      border-radius: 0;
      margin-bottom: 0;
      grid-template-rows: none;
      grid-template-columns: 2fr 1fr 2fr repeat(2, 1fr) 0.5fr;
      grid-template-areas:
        'name username email status role icon2';
       transform: none;
      width: inherit;
     ${IconEdit}, ${IconChat} {
      display: inherit;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: inherit;
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
      i {
        color: inherit;
      }
    }
     ${IconEdit} {
      justify-self: flex-start;
      background-color: inherit;
      color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
    }
    }

  `}
`

const UsersTableHeader = styled(UsersTableRow)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  &:hover {
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
  }
  ${breakpoint.m`
     display: grid;
  `}
  > ${DocumentsHeaderItem} {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
  }
`
