import React from 'react'
import styled from 'styled-components'
import { take, isNil } from 'ramda'
import { connectFreeCarriers } from 'src/features/stocks/ducks/connectors'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import Pagination from 'src/components/Pagination'
import LoadingList from 'src/components/atoms/LoadingList'
import Shade from 'src/components/Shade'
import {
  REFERENCE_NUMBER_PREFIX,
  STOCKS_HEADERS
} from 'src/features/stocks/ducks/consts'
import {
  DateTimeWithReservation,
  GavelIcon,
  LocationFrom,
  LocationTo,
  Company,
  ContainerSemiTrailer
} from 'src/features/stocks/components/atoms'
import { isStockLoading } from 'src/utils/stocks'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import { SearchFilterMobile } from 'src/components/filters/components/atoms'
import { OfferNumber } from 'src/features/myOffers/components/atoms'
import ContainerIcon from 'src/assets/icons/container.svg'
import CompanyIcon from 'src/assets/icons/work-case.svg'
import { MAX_RESERVATIONS_COUNT_FOR_QUEUE } from 'src/ducks/consts'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import PhoneIcon from 'src/assets/icons/phone.svg'
import ChatIcon from 'src/assets/icons/icon_chat.svg'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import {
  BottomRow,
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  HeaderItem,
  TableHeader,
  FreeCarriersTableRow as TableRow,
  DateTime,
  Dot,
  Reserved,
  Arrow,
  InfoBox,
  RefreshIcon,
  ExchangeTopRow,
  ClearAllFilters
} from '../atoms'
import { PrivateLabel } from 'src/features/offers/components/atoms'

const FreeCarriersOffersList = ({
  offers,
  selectedOfferId,
  isDrawerOpen,
  setDrawerState,
  showOffer,
  filters,
  pagination,
  setFilter,
  hidePagination = false,
  stock,
  trigger,
  infoBoxOffersNumber,
  clearFilters,
  getOffers,
  activeTab,
  setConversationOpened,
  checkIfTheOfferBelongsToTheCurrentUser
}) => {
  const intl = useIntl()
  const handleOfferClick = id => {
    setDrawerState(true)
    showOffer(id, { origin: 'drawer' })
  }

  const sortDescOrder = filters.sort[0] === '-'
  const currentSort = filters.sort.replace('-', '')

  const handleHeaderClick = chosenFilter => {
    const shouldReverseOrder = !sortDescOrder && chosenFilter === currentSort
    setFilter('sort', shouldReverseOrder ? `-${chosenFilter}` : chosenFilter)
  }
  const takeMaxOffersElements = take(parseInt(filters.limit, 10) * 2)

  const renderListContents = () => {
    if (isStockLoading(stock, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (offers.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='offers.noOffers' />
        </EmptyOffers>
      )
    }

    if (offers.length > 0) {
      return takeMaxOffersElements(offers).map(offer => (
        <TableRow
          onClick={e => {
            e.nativeEvent.stopImmediatePropagation()
            handleOfferClick(offer.id)
          }}
          key={offer.id}
          numberOfHeaders={STOCKS_HEADERS.FREE_CARRIERS.length}
          isSelected={selectedOfferId === offer.id && isDrawerOpen}
          isNewLive={offer.isOfferLiveNew}
          isRemoved={!isNil(offer.liveOfferRemovedAt)}
        >
          <OfferNumber>
            <Link
              onClick={e => e.stopPropagation()}
              to={createOfferDetailsLink(offer.referenceNumber)}
            >
              {offer.referenceNumber}
              {offer.isPrivate && <PrivateLabel />}
            </Link>
          </OfferNumber>
          <LocationFrom>
            <Arrow>
              <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
            </Arrow>
            {/* COUNTRY_CODES <Ellipsis trigger={trigger}>
              {`${offer.availabilityLocation.countryCode}, ${offer.availabilityLocation.name}`}
            </Ellipsis> */}
            <Ellipsis trigger={trigger}>
              {offer.availabilityLocation.name}
            </Ellipsis>
            <DateTime>
              {offer.availabilityDate}
              {offer.availabilityTime && `, ${offer.availabilityTime}`}
            </DateTime>
          </LocationFrom>
          <LocationTo>
            {/* COUNTRY_CODES <Ellipsis trigger={trigger}>
              {offer.destinationLocation.name
                ? `${offer.destinationLocation.countryCode}, ${offer.destinationLocation.name}`
                : ''}
            </Ellipsis> */}
            <Ellipsis trigger={trigger}>
              {offer.destinationLocation.name}
            </Ellipsis>
          </LocationTo>
          <ContainerSemiTrailer>
            <ContainerIcon />
            <div>
              <Ellipsis trigger={trigger}>
                {offer.containerSemiTrailer.name}
              </Ellipsis>
            </div>
          </ContainerSemiTrailer>
          <Company>
            <CompanyIcon />
            <div>
              <Ellipsis trigger={trigger}>{offer.issuer.company.name}</Ellipsis>
            </div>
          </Company>
          <DateTimeWithReservation>
            <Ellipsis trigger={trigger}>
              {offer.isOfferNew && <Dot />}
              {offer.timeFromAdded}
            </Ellipsis>
            {offer.type === OFFER_TYPES.QUEUE &&
              offer.reservationsCount > 0 && (
                <Reserved>{`${offer.reservationsCount}/${MAX_RESERVATIONS_COUNT_FOR_QUEUE}`}</Reserved>
              )}
            {offer.type === OFFER_TYPES.AUCTION && <GavelIcon />}
            <ActionsMenu
              onClick={() => {
                showOffer(offer.id)
              }}
            >
              <DropdownItem
                onClick={e => e.stopPropagation()}
                disabled={!offer.handler.phoneNumber}
              >
                <PhoneIcon />
                <a href={`tel:${offer.handler.phoneNumber}`}>
                  <FormattedMessage id='common.call' />
                </a>
              </DropdownItem>
              <DropdownItem
                disabled={checkIfTheOfferBelongsToTheCurrentUser(
                  offer.handlerId
                )}
                onClick={e => {
                  e.stopPropagation()
                  setConversationOpened({
                    receiverId: offer.handlerId,
                    contextId: offer.id,
                    contextType: offer.offerContextType,
                    conversationOpened: true
                  })
                }}
              >
                <ChatIcon />
                <FormattedMessage id='common.openChat' />
              </DropdownItem>
            </ActionsMenu>
          </DateTimeWithReservation>
          <div>
            {offer.type === OFFER_TYPES.QUEUE &&
              offer.reservationsCount > 0 && (
                <Reserved>{`${offer.reservationsCount}/${MAX_RESERVATIONS_COUNT_FOR_QUEUE}`}</Reserved>
              )}
            {offer.type === OFFER_TYPES.AUCTION && <GavelIcon />}
          </div>
        </TableRow>
      ))
    }
  }
  return (
    <div>
      {infoBoxOffersNumber > 0 && (
        <InfoBox number={infoBoxOffersNumber}>
          {intl.formatMessage({ id: 'offers.xNewOffers' })}
        </InfoBox>
      )}
      <ExchangeTopRow>
        <Counter>
          <span>{`${pagination.total} `}</span>
          <FormattedMessage
            id='offers.offersFound'
            values={{
              count: pagination.total
            }}
          />
        </Counter>
        <RefreshIcon onClick={getOffers} />
        <ClearAllFilters onClick={clearFilters}>
          {intl.formatMessage({ id: 'offersFilters.clearAllFilters' })}
        </ClearAllFilters>
        <SearchFilterMobile
          name='referenceNumber'
          id='input-search-free-carriers'
          initValue={REFERENCE_NUMBER_PREFIX.FREE_CARRIERS}
          filterValue={filters.referenceNumber}
          activeTab={activeTab}
          active={!!filters.referenceNumber}
          setFilter={setFilter}
          placeholder={intl.formatMessage({ id: 'common.offerNumber' })}
        />
      </ExchangeTopRow>
      <Container>
        <TableHeader numberOfHeaders={STOCKS_HEADERS.FREE_CARRIERS.length}>
          {STOCKS_HEADERS.FREE_CARRIERS.map((header, index) => (
            <HeaderItem
              sort={header.sort}
              onClick={() => header.sort && handleHeaderClick(header.sort)}
              key={`${header.name} ${index}`}
            >
              <Ellipsis trigger={trigger}>
                <FormattedMessage id={header.name} defaultMessage=' ' />
                {currentSort === header.sort && (
                  <Icon>
                    <ArrowIcon
                      style={{
                        transform: `rotate(${sortDescOrder ? '0' : '180deg'})`
                      }}
                    />
                  </Icon>
                )}
              </Ellipsis>
            </HeaderItem>
          ))}
        </TableHeader>
        {renderListContents()}
      </Container>
      {offers.length > 0 && !hidePagination && (
        <BottomRow>
          <Pagination
            itemsPerPage={filters.limit}
            currentPage={pagination.currentPage}
            pagesCount={pagination.lastPage}
            setPage={page => setFilter('page', page)}
            setItemsPerPage={limit => {
              setFilter('limit', limit)
              setFilter('page', 1)
            }}
          />
        </BottomRow>
      )}
    </div>
  )
}

const Icon = styled.i`
  position: absolute;
  top: 3px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  width: 24px;
  svg {
    transition: transform 0.3s;
  }
`

export default connectFreeCarriers(FreeCarriersOffersList)
