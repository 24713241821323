import React, { useCallback, useEffect, useState } from 'react'
import { isNotEmpty } from 'ramda-adjunct'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { propOr, flip, gt, any, map } from 'ramda'
import CustomIcon from 'src/components/CustomIcon'
import styled, { css } from 'styled-components'
import { MAX_FILE_SIZE_BYTES_FOR_TRACKING } from 'src/ducks/consts'
import { ErrorMessage, FileInput } from 'src/features/account/components/atoms'
import { useDropzone } from 'react-dropzone'

const AddFiles = ({ onChange, className, children }) => {
  const [inputFiles, setInputFiles] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    const moreThanSize = flip(gt)(MAX_FILE_SIZE_BYTES_FOR_TRACKING)
    const filesArrayNotEmpty = isNotEmpty(inputFiles)
    const size = propOr(0, 'size')
    const hasWrongSize = any(moreThanSize, map(size, inputFiles))

    if (!hasWrongSize && filesArrayNotEmpty) {
      onChange(inputFiles)
    }

    setError(hasWrongSize)
  }, [inputFiles])

  const onDrop = useCallback(acceptedFiles => {
    setInputFiles(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div className={className} {...getRootProps()}>
        <FileInput
          {...getInputProps()}
          accept='image/jpg, image/png, image/jpeg, image/gif, application/pdf, .doc, .docx, application/msword, .txt'
        />
        <FileDropZone htmlFor='file'>
          {children}
          {isDragActive && (
            <div>
              <FormattedMessage id='tracking.dropHere' />
            </div>
          )}
        </FileDropZone>
      </div>
      {error && (
        <ErrorMessage
          type='alert'
          message={<FormattedMessage id='tracking.toLargeFileErrorMessage' />}
        />
      )}
    </>
  )
}

const styledAddFiles = styled(AddFiles)`
  position: relative;
  height: fit-content;
`

export default styledAddFiles

export const FileDropZone = styled.label`
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px;
  border: 1px dashed rgb(198, 203, 212);
  background-color: ${({ theme }) => theme.colors.white.hex()};
  padding: 1.4375rem;
  margin: 4px 0;
  font-size: 0.875rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  & a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    font-weight: 600;
  }
  ${({ url }) =>
    url &&
    css`
      background-image: url(${url});
    `}

  ${CustomIcon} {
    height: 24px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
