import React, { useEffect } from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import {
  Column,
  CreatedAtUser,
  Row,
  SectionBox
} from 'src/features/account/components/atoms'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import * as routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { Padding } from 'src/features/myOffers/components/atoms'
import BreadCrumbs from 'src/components/BreadCrumbs'
import { withLocation } from 'src/hocs/withLocation'
import qs from 'qs'
import { MY_COMPANY_TABS_ARRAY } from 'src/features/account/duck/consts'
import Alert from 'src/components/Alert'
import moment from 'moment'
import { API_STATES, DATE_FORMAT } from 'src/ducks/consts'
import CompanyInfo from 'src/features/account/components/CompanyInfo'
import CompanyContact from 'src/features/account/components/CompanyContact'
import CompanyDocuments from 'src/features/account/components/CompanyDocuments'
import Tabs from 'src/components/Tabs'
import MarkCompany from 'src/features/companies/components/MarkCompany'
import CompanyUsersPublic from 'src/features/companies/components/CompanyUsersPublic'
import Shade from 'src/components/Shade'
import connect from './duck/connectors'

const CompanyPublicDetails = ({
  className,
  companyDetails,
  companiesState,
  showCompanyPublicDetails,
  clearCompanyPublicDetails,
  location,
  currentCompanyId
}) => {
  const intl = useIntl()

  useEffect(() => clearCompanyPublicDetails, [])

  useEffect(() => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    if (params.companyId) showCompanyPublicDetails(params.companyId)
  }, [location.search])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>{companyDetails.name}</StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      {companiesState === API_STATES.IN_PROGRESS && <Shade />}
      <Padding>
        <BreadCrumbs
          links={[
            {
              name: intl.formatMessage({ id: 'forms.dashboard' }),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
            },
            {
              name: intl.formatMessage({
                id: 'companies.companiesDatabase'
              }),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.COMPANIES}`
            },
            {
              name: companyDetails.name,
              url: `${routes.APP_DASHBOARD}`
            }
          ]}
        />
      </Padding>
      <Tabs list={MY_COMPANY_TABS_ARRAY}>
        <SectionBox>
          <Row>
            <Column left>
              <Row>
                <CompanyInfo company={companyDetails} />
              </Row>
            </Column>
            <Column right>
              {companyDetails.createdAt && (
                <Row>
                  <CreatedAtUser>
                    <FormattedMessage
                      id='myCompany.createdAt'
                      values={{
                        date: moment(companyDetails.createdAt).format(
                          DATE_FORMAT
                        )
                      }}
                    />
                  </CreatedAtUser>
                </Row>
              )}
              {companyDetails.id !== currentCompanyId && (
                <MarkCompanyRow>
                  <MarkCompany
                    isWhitelisted={companyDetails.isWhitelisted}
                    isBlacklisted={companyDetails.isBlacklisted}
                    companyId={companyDetails.id}
                  />
                </MarkCompanyRow>
              )}
              <CompanyContactRow>
                <Column>
                  <CompanyContact
                    company={companyDetails}
                    editAllowed={false}
                  />
                </Column>
              </CompanyContactRow>
            </Column>
          </Row>
          <Row>
            <CompanyDocuments documents={companyDetails.documents} />
          </Row>
        </SectionBox>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <CompanyUsersPublic
                    users={companyDetails.users}
                    editAllowed={false}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = compose(connect, withLocation)(CompanyPublicDetails)
export default styled(ConnectedComponent)`
  ${SectionBox} {
    padding: 0.8rem;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
  ${CreatedAtUser} {
    display: none;
    ${breakpoint.m`
      display: inline;
  `}
  }
  ${Tabs} {
    display: block;
    & > div {
      padding: 0.8rem 1.2rem 0 1.2rem;
    }
    & span {
      font-weight: 400;
    }
  }

  ${Alert} {
    width: auto;
    margin: 1rem 0 0 0;
    ${breakpoint.m`
    margin: initial;
    width: inherit;
  `}
  }
`

const CompanyContactRow = styled(Row)`
  height: 100%;
`

const MarkCompanyRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
`
