import React, { useState } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import CustomIcon from 'src/components/CustomIcon'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import { Key, TitleContainer, UserProperties, Value } from './atoms'
import { Title } from './typography'
import UserContactDataForm from '../myProfile/forms/UserContactData'

const UserContact = ({ user, changedSuccessfully }) => {
  const [edit, setEdit] = useState(false)

  return (
    <>
      <TitleContainer>
        <Title>
          <FormattedMessage id='account.contactData' />
        </Title>
        {!edit && !changedSuccessfully && (
          <EditIconContainer onClick={() => setEdit(!edit)}>
            <CustomIcon name='edit' size={24} />
          </EditIconContainer>
        )}
      </TitleContainer>
      {!edit && (
        <UserProperties>
          <Key>
            <EllipsisTooltip>
              <FormattedMessage id='common.name' />
            </EllipsisTooltip>
          </Key>
          <Value>{user.firstName}</Value>
          <Key>
            <EllipsisTooltip>
              <FormattedMessage id='common.surname' />
            </EllipsisTooltip>
          </Key>
          <Value>{user.lastName}</Value>
          <Key>
            <EllipsisTooltip>
              <FormattedMessage id='account.tel' />
            </EllipsisTooltip>
          </Key>
          <Value>{user.phoneNumber}</Value>
          <Key>
            <EllipsisTooltip>
              <FormattedMessage id='account.additionalInfo' />
            </EllipsisTooltip>
          </Key>
          <Value>{user.contactInfo}</Value>
        </UserProperties>
      )}
      {edit && <UserContactDataForm onCancel={() => setEdit(false)} />}
    </>
  )
}

export default UserContact

const EditIconContainer = styled.div`
  cursor: pointer;
`
