import React, { useState } from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/styles'
import theme from 'src/theme/theme'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIconUrl from 'src/assets/icons/info.svg'

const IconWithTooltip = ({ title, openTime = 1000, icon }) => {
  const [open, setOpen] = useState(false)

  const handleTouchStart = () => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, openTime)
  }

  return (
    <StyledTooltip title={title} arrow placement='top' open={open}>
      <span
        onTouchStart={handleTouchStart}
        onMouseOver={() => setOpen(true)}
        onFocus={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
        onBlur={() => setOpen(false)}
      >
        {icon || <InfoIcon />}
      </span>
    </StyledTooltip>
  )
}
const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: theme.colors.codGray.hex(),
    color: theme.colors.white.hex(),
    fontSize: '0.8rem',
    borderRadius: '4px',
    position: 'relative',
    '&>i': {
      display: 'none'
    }
  }
}))(Tooltip)

const InfoIcon = styled(InfoIconUrl)`
  display: inline;
  margin-bottom: -4px;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  color: ${theme.colors.easternBlue.hex()};
`

export default IconWithTooltip
