import React, { useEffect, useState } from 'react'
import {
  FormattedMessage,
  FormattedPlural,
  Link,
  navigate,
  useIntl
} from 'gatsby-plugin-intl'
import { any, filter, includes, toLower } from 'ramda'

import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'

import {
  Column,
  Icon,
  UsersHeaderItem,
  UsersTableHeader,
  UsersTableRow
} from 'src/features/account/components/atoms'

import {
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  Row
} from 'src/features/stocks/components/atoms'

import { MENU_ITEMS, USERS_HEADERS } from 'src/features/account/duck/consts'

import { UsersRow } from 'src/features/account/components/rows'
import routes from 'src/utils/routes'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { AddUserButton } from 'src/components/atoms/Buttons'
import TextInput from 'src/components/atoms/TextInput'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'

const CompanyUsers = ({ users, editAllowed, setConversationOpened }) => {
  const intl = useIntl()
  const [search, setSearch] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(users)

  useEffect(() => {
    if (search.length > 0) {
      setFilteredUsers(
        filter(
          user =>
            any(includes(toLower(search)), [
              toLower(user.username),
              toLower(user.firstName),
              toLower(user.lastName)
            ]),
          users
        )
      )
    } else {
      setFilteredUsers(users)
    }
  }, [search, users])

  return (
    <Column>
      <Row>
        <Counter>
          <span>{filteredUsers.length} </span>
          <FormattedPlural
            value={filteredUsers.length}
            other={intl.formatMessage({ id: 'myCompany.users.users' })}
            one={intl.formatMessage({ id: 'myCompany.users.user' })}
          />
        </Counter>
        <SearchInputContainer>
          <TextInput
            type='search'
            name='searchUser'
            placeholder={intl.formatMessage({
              id: 'myCompany.users.searchUser'
            })}
            value={search}
            onChange={(name, value) => setSearch(value)}
            validate={() => true}
          />
        </SearchInputContainer>
        {editAllowed && (
          <Link to={`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.ADD_USER}`}>
            <AddUserButton />
          </Link>
        )}
      </Row>
      <StyledContainer>
        <UsersTableHeader numberOfHeaders={USERS_HEADERS.length}>
          {USERS_HEADERS &&
            USERS_HEADERS.map((header, index) => (
              <UsersHeaderItem key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage id={header.name} defaultMessage=' ' />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </UsersHeaderItem>
            ))}
        </UsersTableHeader>
        {filteredUsers.length > 0 &&
          filteredUsers.map((user, index) => (
            <UsersTableRow key={index} numberOfHeaders={USERS_HEADERS.length}>
              <UsersRow
                user={user}
                onEdit={() =>
                  navigate(
                    `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.EDIT_USER}&id=${user.id}`
                  )
                }
                handleConversationStart={() => {
                  setConversationOpened({
                    receiverId: user.id,
                    conversationOpened: true
                  })
                }}
                editAllowed={editAllowed}
              />
            </UsersTableRow>
          ))}
        {users.length === 0 && (
          <EmptyOffers>
            <FormattedMessage id='myCompany.documents.noUsers' />
          </EmptyOffers>
        )}
      </StyledContainer>
    </Column>
  )
}
export default connectMessagesBasicData(CompanyUsers)

const StyledContainer = styled(Container)`
  margin: 0 -0.8rem;
  width: auto;
  ${breakpoint.m`
      margin: initial;
      width: 100%;
  `}
`

const SearchInputContainer = styled.div`
  flex: 1;
  margin: 0 0.5rem -1rem;
  transition: all 0.3s;
  & input {
    padding-left: 0;
  }

  ${breakpoint.s`
    flex: 0.4;
  `}
`
