import React, { useState, useEffect } from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import styled from 'styled-components'
import Chevron from 'src/assets/down.svg'

const SelectInput = ({
  options,
  pristine = true,
  name,
  value,
  id,
  onChange,
  validate = () => true,
  helperText,
  placeholder,
  label,
  required,
  className
}) => {
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })

  const handleChange = e => onChange(name, e.target.value)

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(name, value, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [value, inputPristine])

  return (
    <div className={className}>
      <Label required={required}>{label}</Label>
      <select
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={() => _setPristine(false)}
        placeholder={placeholder}
      >
        {placeholder && (
          <option key='default' value='' selected disabled>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {(helperText || error) && (
        <FormHelperText error={!valid}>
          {valid ? helperText : error}
        </FormHelperText>
      )}
    </div>
  )
}

export default styled(SelectInput)`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem;

  select {
    appearance: none;
    outline: none;
    height: 40px;
    border-radius: 2px;
    border: 1px solid rgb(198, 203, 212);
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.black.hex()};
    font-weight: 400;
    line-height: 1.71;
    padding-right: 24px;
    padding-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${({ theme }) => theme.colors.white.hex()} url(${Chevron})
      no-repeat right 5px top 50%;
    background-color: ${({ theme }) => theme.colors.white.hex()};
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.black.hex()};
    }
    &:focus {
      border: 1px solid rgba(19, 162, 191, 1);
      box-shadow: 0 2px 10px 0 rgba(19, 162, 191, 0.25);
    }
  }
`

const Label = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`
