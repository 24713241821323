import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Tabs, { TabHeader } from 'src/components/Tabs'
import {
  ADD_INVOICE_PAYMENT_TABS_ARRAY,
  PAYMENTS_OPTIONS,
  SAFT_NAMES_ARRAY
} from 'src/features/invoices/duck/consts'
import { Column, Row } from 'src/features/account/components/atoms'
import { InputRow } from 'src/features/orders/components/atoms'
import TextInput from 'src/components/atoms/TextInput'
import Select from 'src/features/invoices/components/Select'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { BOOLEAN } from 'src/ducks/consts'
import { validateAddInvoice } from 'src/features/invoices/duck/schema'
import { breakpoint } from 'src/theme/grid'
import { InputTitle } from 'src/features/invoices/components/InvoiceSettingsDialog'
import { formatPrice } from 'src/features/invoices/duck/helpers'

export const PaymentsBox = ({
  amountPaid,
  renderSaftOption,
  onChange,
  setSplitPayment,
  paymentMethod,
  paymentBankAccount,
  paymentBankName,
  isSplitPayment,
  invoice,
  correctiveInvoice
}) => {
  const intl = useIntl()

  const onPriceBlur = price => {
    const priceFormatted = formatPrice(price)
    if (priceFormatted !== price) {
      onChange(['amountPaid', 'float'], priceFormatted)
    }
  }

  return (
    <PaymentsContainer>
      <Tabs list={ADD_INVOICE_PAYMENT_TABS_ARRAY}>
        <Box>
          <Row>
            <Column>
              <Row>
                <Column>
                  {!correctiveInvoice && (
                    <InputRow>
                      <SmallInputContainer>
                        <TextInput
                          required
                          onBlur={() => {
                            onPriceBlur(amountPaid.float)
                          }}
                          type='number'
                          label={intl.formatMessage({
                            id: 'invoices.paid'
                          })}
                          id='paid'
                          name={['amountPaid', 'float']}
                          placeholder=''
                          value={amountPaid.float}
                          onChange={onChange}
                          validate={validateAddInvoice}
                        />
                      </SmallInputContainer>
                    </InputRow>
                  )}
                  {correctiveInvoice && (
                    <SmallInputContainer>
                      <AmountsRow>
                        <div>
                          <InputTitle>
                            {invoice.total.float > 0 ? (
                              <FormattedMessage id='invoices.toPay' />
                            ) : (
                              <FormattedMessage id='invoices.toRefund' />
                            )}
                          </InputTitle>
                          <Text>{invoice.total.formatted}</Text>
                        </div>
                        <div>
                          <InputTitle>
                            <FormattedMessage id='invoices.totalAfterCorrection' />
                          </InputTitle>
                          <Text>
                            {invoice.invoiceTotalAfterCorrection.formatted}
                          </Text>
                        </div>
                      </AmountsRow>
                    </SmallInputContainer>
                  )}
                  <InputRow>
                    <SmallInputContainer>
                      <InputTitle>
                        {intl.formatMessage({
                          id: 'invoices.paymentMethod'
                        })}
                      </InputTitle>
                      <PaymentMethodSelect
                        options={PAYMENTS_OPTIONS.map(option => ({
                          ...option,
                          label: intl.formatMessage({ id: option.label })
                        }))}
                        label={intl.formatMessage({
                          id: 'invoices.paymentMethod'
                        })}
                        name='paymentMethod'
                        validate={validateAddInvoice}
                        value={paymentMethod}
                        onChange={e =>
                          onChange('paymentMethod', e.target.value)
                        }
                        id='paymentMethod'
                      />
                    </SmallInputContainer>
                  </InputRow>
                  {invoice.paymentMethod === 'transfer' && (
                    <InputRow>
                      <SmallInputContainer>
                        <TextInput
                          required={invoice.paymentMethod === 'transfer'}
                          label={intl.formatMessage({
                            id: 'invoices.bankAccount'
                          })}
                          id='paymentBankAccount'
                          name='paymentBankAccount'
                          placeholder=''
                          value={paymentBankAccount}
                          onChange={onChange}
                          validate={validateAddInvoice}
                        />
                      </SmallInputContainer>
                      <SmallInputContainer>
                        <TextInput
                          required={invoice.paymentMethod === 'transfer'}
                          label={intl.formatMessage({
                            id: 'invoices.bankName'
                          })}
                          id='paymentBankName'
                          name='paymentBankName'
                          placeholder=''
                          value={paymentBankName}
                          onChange={onChange}
                          validate={validateAddInvoice}
                        />
                      </SmallInputContainer>
                    </InputRow>
                  )}
                  <InputRow>
                    <Checkbox
                      name='isSplitPayment'
                      value={isSplitPayment}
                      validate={validateAddInvoice}
                      option={{
                        value: BOOLEAN.YES,
                        text: intl.formatMessage({
                          id: 'invoices.isSplitPayment'
                        })
                      }}
                      onChange={setSplitPayment}
                    />
                  </InputRow>
                </Column>
              </Row>
            </Column>
          </Row>
        </Box>
        <Box>
          <Row>
            <Column>
              <Row>
                <Column>{SAFT_NAMES_ARRAY.map(renderSaftOption)}</Column>
              </Row>
            </Column>
          </Row>
        </Box>
      </Tabs>
    </PaymentsContainer>
  )
}
const PaymentsContainer = styled.div`
  border: solid 1px rgba(36, 45, 63, 0.15);
  background-color: #f5f5f6;

  ${TabHeader} {
    padding: 1rem 1.5rem;
  }
`
const Box = styled.div`
  display: block;
  margin-bottom: 1.2rem;
  box-sizing: border-box;
  padding: 0 1.5rem;
  ${breakpoint.m`
    padding: 0 40px;
  `}
`
const SmallInputContainer = styled.div`
  ${breakpoint.m`
    width: 270px;
  `}
`

const PaymentMethodSelect = styled(Select)`
  margin-bottom: 1rem;
`
const Text = styled.p`
  font-size: 0.875rem;
  padding-left: 4px;
  margin-bottom: 10px;
`
const AmountsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
