import React from 'react'
import styled from 'styled-components'

const PaymentsList = ({ renderItem, payments, className }) => (
  <ul className={className}>{payments.map(renderItem)}</ul>
)

export default styled(PaymentsList)`
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  li {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    &:before {
      content: '•';
      padding-right: 8px;
      color: ${({ theme }) => theme.colors.apple.hex()};
    }
  }
`
