import React from 'react'
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl'
import { isNil } from 'ramda'
import styled from 'styled-components'
import { Title } from 'src/features/stocks/components/typography'
import Timeline from 'src/features/stocks/components/OfferDetails/Timeline'
import Contact from 'src/features/stocks/components/OfferDetails/Contact'
import {
  ContainerInfo,
  ContainerSemiTrailerInfo,
  CreatedDateOfferDetails,
  CustomsClearance,
  DrawerTitle,
  MiddleContainer,
  Notes,
  OfferRemovedInfo,
  Price,
  TrackingIconWithBackground
} from 'src/features/stocks/components/OfferDetails/atoms'
import { compose } from 'recompose'
import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'
import { connectOrderDetails } from 'src/features/orders/duck/connectors'
import { TrackingLink } from 'src/features/offers/components/atoms'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { getIssuerAndContractorFromOrderDetails } from 'src/features/orders/duck/helpers'

const OfferDetails = ({
  className,
  setDrawerState,
  orderDetails,
  offer,
  timelineData,
  setConversationOpened,
  checkIfTheOfferBelongsToTheCurrentUser
}) => {
  const intl = useIntl()
  const offerRemoved = !isNil(offer.deletedAt)
  const trackingReferenceNumber =
    offer.transportationTrackingId &&
    offer.transportationTracking?.referenceNumber
  const hasCustomsClearance = offer.customsClearanceLocation?.id
  const hasContainer = offer.container && offer.container.name
  const hasContainerSemiTrailer =
    offer.containerSemiTrailer && offer.containerSemiTrailer.name
  const hasNotes = offer.notes
  const hasPrice = offer.price && offer.price.float > 0

  const { issuer, contractor } = getIssuerAndContractorFromOrderDetails(
    orderDetails
  )

  const isCurrentUserIssuer = checkIfTheOfferBelongsToTheCurrentUser(issuer.id)
  const isCurrentUserContractor = checkIfTheOfferBelongsToTheCurrentUser(
    contractor.id
  )

  const navigateToTrackingDetails = () => {
    trackingReferenceNumber &&
      navigate(
        `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${trackingReferenceNumber}`
      )
  }

  return (
    <div className={className}>
      <DrawerTitle offerDetails={offer} setDrawerState={setDrawerState} />
      {offerRemoved && <OfferRemovedInfo />}
      {!offerRemoved && offer.id && (
        <MiddleContainer>
          {trackingReferenceNumber && (
            <div>
              <TrackingLink
                onClick={navigateToTrackingDetails}
                title={intl.formatMessage({
                  id: 'offerDetails.tracking'
                })}
                icon={<TrackingIconWithBackground />}
                type='white'
                message={intl.formatMessage({
                  id: 'offerDetails.goToTracking'
                })}
              />
            </div>
          )}
          {timelineData && <Timeline data={timelineData} />}
          {hasCustomsClearance && <CustomsClearance offerDetails={offer} />}
          {hasContainer && <ContainerInfo offerDetails={offer} />}
          {hasContainerSemiTrailer && (
            <ContainerSemiTrailerInfo offerDetails={offer} />
          )}
          {hasNotes && <Notes offerDetails={offer} />}
          {hasPrice && <Price offerDetails={offer} />}
          <UserTitle>
            <FormattedMessage id='orders.issuer' />
          </UserTitle>
          <Contact
            offerBelongsToCurrentUser={isCurrentUserIssuer}
            issuer={issuer}
            handler={issuer}
            handleConversationStart={() => {
              setConversationOpened({
                receiverId: issuer.id,
                contextId: offer.id,
                conversationOpened: true,
                contextType: orderDetails.offerType
              })
            }}
          />
          <UserTitle>
            <FormattedMessage id='orders.contractor' />
          </UserTitle>
          <Contact
            offerBelongsToCurrentUser={isCurrentUserContractor}
            issuer={contractor}
            handler={contractor}
            handleConversationStart={() => {
              setConversationOpened({
                receiverId: contractor.id,
                contextId: offer.id,
                conversationOpened: true,
                contextType: orderDetails.offerType
              })
            }}
          />
          <CreatedDateOfferDetails offerDetails={offer} />
        </MiddleContainer>
      )}
    </div>
  )
}

const StyledOfferDetails = styled(OfferDetails)`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 1.6rem 0 0 0;
  ${Title} {
    padding-bottom: 4px;
  }
`
export default compose(
  connectOrderDetails,
  connectMessagesBasicData
)(StyledOfferDetails)

const UserTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.alabaster.hex()};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
`
