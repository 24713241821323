import React from 'react'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'
import {
  DateTime,
  Ellipsis,
  Location
} from 'src/features/stocks/components/atoms'

import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import {
  EllipsisContainer,
  TableRow
} from 'src/features/contracts/components/atoms'
import styled from 'styled-components'
import { checkOrderStatus, isNotOlderThanMinutes } from 'src/utils/helpers'
import { Action, Actions } from 'src/features/myOffers/components/atoms'
import {
  ORDERS_ISSUED_TABLE_NAMES,
  ORDERS_ISSUED_TABLE_WIDTHS
} from 'src/features/orders/duck/consts'
import PdfIcon from 'src/assets/icons/pdf.svg'
import OrderStatusDot from 'src/features/orders/components/OrderStatusDot'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import MobileOrderRow from 'src/features/orders/components/MobileOrderRow'
import { connectOrderDetails } from 'src/features/orders/duck/connectors'

const OfferRow = ({ order, downloadOrderPdf }) => {
  const isNew = isNotOlderThanMinutes(order.createdAt, 1)

  const status = checkOrderStatus(order)

  return (
    <>
      <TableRow
        widths={ORDERS_ISSUED_TABLE_WIDTHS}
        columnNames={ORDERS_ISSUED_TABLE_NAMES}
      >
        <Status>
          <OrderStatusDot status={status} />
          <EllipsisContainer>
            <Ellipsis>
              <FormattedMessage id={`orders.${status}`} />
            </Ellipsis>
          </EllipsisContainer>
        </Status>
        <div>
          <OfferLink
            onClick={() =>
              navigate(
                `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${order.referenceNumber}`
              )
            }
          >
            {order.referenceNumber}
          </OfferLink>
        </div>
        <div>
          <Ellipsis>
            <FormattedMessage
              id={`common.${order.transportationType}`}
              defaultMessage='-'
            />
          </Ellipsis>
        </div>
        <Location>
          <Ellipsis>{order.loadingUnloadingAddress}</Ellipsis>
          <DateTime>{order.loadingUnloadingDateTime}</DateTime>
        </Location>
        <div>
          <Ellipsis>
            {order.container.name}
            {order.shipowner.id && `, ${order.shipowner.name}`}
          </Ellipsis>
        </div>
        <div>
          <Ellipsis>{order.carrierCompany.name}</Ellipsis>
        </div>
        <CreatedAtField isNew={isNew} addedAt={order.createdAt} />
        {order.acceptedAt && (
          <Actions>
            <Action
              onClick={() => {
                downloadOrderPdf(order.id)
              }}
            >
              <PdfIcon />
            </Action>
          </Actions>
        )}
      </TableRow>
      <MobileOrderRow order={order} />
    </>
  )
}

export default connectOrderDetails(OfferRow)

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const Status = styled.div`
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`
