import React, { useEffect } from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Tabs from 'src/components/Tabs'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import Alert from 'src/components/Alert'
import SelectLanguage from 'src/components/SelectLanguage'
import moment from 'moment'
import { API_STATES, DATE_FORMAT } from 'src/ducks/consts'
import connect from 'src/features/account/duck/connectors'
import PasswordChangeForm from 'src/features/account/myProfile/forms/PasswordChange'
import {
  Column,
  Row,
  SectionBox,
  TitleContainer,
  LanguageContainer,
  CreatedAtUser
} from 'src/features/account/components/atoms'
import { Subtitle, Title } from 'src/features/account/components/typography'
import UserInfo from 'src/features/account/components/UserInfo'
import UserContact from 'src/features/account/components/UserContact'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'

const Profile = ({
  className,
  currentUser,
  changePasswordStatus,
  updateCurrentUserStatus: { changedSuccessfully },
  avatarState,
  getCurrentUserDetails
}) => {
  const intl = useIntl()

  useEffect(() => {
    getCurrentUserDetails()
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'account.myProfile'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Tabs
        list={[
          intl.formatMessage({ id: 'account.userData' }),
          intl.formatMessage({ id: 'account.changePassword' })
        ]}
      >
        <SectionBox>
          <Row>
            <Column left>
              {currentUser.createdAt && (
                <CreatedAtUser>
                  <FormattedMessage
                    id='account.createdAt'
                    values={{
                      date: moment(currentUser.createdAt).format(DATE_FORMAT)
                    }}
                  />
                </CreatedAtUser>
              )}
              <Row>
                <UserInfo user={currentUser} />
              </Row>
              {avatarState === API_STATES.ERROR && (
                <Row>
                  <Alert
                    fullWidth
                    type='alert'
                    message={intl.formatMessage({
                      id: 'account.uploadErrorMessage'
                    })}
                  />
                </Row>
              )}
              <Row>
                <Alert
                  fullWidth
                  type='info'
                  message={intl.formatMessage({
                    id: 'account.changeDataAlert'
                  })}
                />
              </Row>
            </Column>
            <Column right>
              <Row>
                <Column>
                  <UserContact
                    user={currentUser}
                    changedSuccessfully={changedSuccessfully}
                  />
                  {changedSuccessfully && (
                    <Alert
                      type='success'
                      message={intl.formatMessage({
                        id: 'account.updateSuccess'
                      })}
                    />
                  )}
                </Column>
              </Row>
              <Row>
                <Column>
                  <TitleContainer>
                    <Title>
                      <FormattedMessage id='account.settings' />
                    </Title>
                  </TitleContainer>
                  <LanguageContainer>
                    <Subtitle>
                      <FormattedMessage id='common.language' />
                    </Subtitle>
                    <SelectLanguage />
                  </LanguageContainer>
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
        <SectionBox>
          <Row>
            <Column left>
              <Row>
                <Alert
                  fullWidth
                  type='info'
                  message={intl.formatMessage({
                    id: 'account.changePasswordAlert'
                  })}
                />
              </Row>
              <Row>
                <Column>
                  <TitleContainer>
                    <Title>
                      <FormattedMessage id='account.changePassword' />
                    </Title>
                  </TitleContainer>
                  <PasswordChangeForm
                    //Change key to render new instance of component (clear state and validation)
                    key={changePasswordStatus.changedSuccessfully}
                  />
                </Column>
              </Row>
            </Column>
            <Column right></Column>
          </Row>
        </SectionBox>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = connect(Profile)
export default styled(ConnectedComponent)`
  ${Tabs} {
    display: block;
    & > div {
      padding: 0.8rem 1.2rem 0 1.2rem;
    }
    & span {
      font-weight: 400;
    }
  }

  ${Alert} {
    width: auto;
    margin: 1rem 0 0 0;
    ${breakpoint.m`
    margin: initial;
    width: inherit;
  `}
  }
`
