import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { propOr } from 'ramda'
import CustomIcon from 'src/components/CustomIcon'
import styled from 'styled-components'
import { MAX_IMAGE_SIZE_BYTES } from 'src/ducks/consts'
import { Image } from 'src/features/account/duck/records'
import {
  AddPhotoCircle,
  ErrorMessage,
  FileInput,
  RemoveIconContainer
} from 'src/features/account/components/atoms'

const AddPhotoByUrl = ({ onChange, className, photo }) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    const size = propOr(0, 'size', file)
    const hasWrongSize = file && size > MAX_IMAGE_SIZE_BYTES
    const fileUrl = file ? URL.createObjectURL(file) : null
    onChange(Image({ url: fileUrl, type: fileUrl ? 'blob' : null }), size)
    setError(hasWrongSize)
  }, [file])

  const isEmpty = !!(!file && !photo.url)

  const onRemove = () => {
    onChange(Image(), 0)
    setFile(null)
  }

  return (
    <>
      <div className={className}>
        <FileInput
          type='file'
          accept='image/jpg, image/png, image/jpeg, image/gif'
          name='file'
          id='file'
          onChange={e => setFile(e.target.files[0])}
        />
        <AddPhotoCircle htmlFor='file' url={photo.url}>
          {isEmpty && <CustomIcon name='addPhoto' size={24} />}
          {isEmpty && <FormattedMessage id='myCompany.users.addPhoto' />}
        </AddPhotoCircle>
        {!isEmpty && (
          <RemoveIconContainer onClick={onRemove}>
            <CustomIcon name='delete' size={24} />
          </RemoveIconContainer>
        )}
      </div>
      {error && (
        <ErrorMessage
          type='alert'
          message={
            <FormattedMessage id='myCompany.users.toLargePhotoErrorMessage' />
          }
        />
      )}
    </>
  )
}

const styledAddPhoto = styled(AddPhotoByUrl)`
  position: relative;
  height: fit-content;
`

export default styledAddPhoto
