import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styled, { css } from 'styled-components'
import { includes, equals, not, toLower, forEachObjIndexed } from 'ramda'
import TextInput from 'src/components/atoms/TextInput'
import useClickOutside from 'src/hooks/useClickOutside'

const DriversSearchInput = ({
  drivers = [],
  onChange,
  value,
  name,
  selected,
  disableSearch,
  ...props
}) => {
  const [filteredDrivers, setFilteredDrivers] = useState([])
  const componentRef = useClickOutside(() => setFilteredDrivers([]))

  useEffect(() => {
    if (typeof value === 'string') {
      setFilteredDrivers(
        drivers.filter(
          driver =>
            includes(toLower(value), toLower(driver.name)) &&
            not(equals(toLower(value), toLower(driver.name)))
        )
      )
    }
  }, [value])

  const handleElementClick = driver => {
    const driverFieldsData = {
      driverId: driver.id,
      driverName: driver.name,
      driverPhoneNumber: driver.phoneNumber,
      driverEmail: driver.email,
      driverIdentityCardNumber: driver.identityCardNumber
    }

    forEachObjIndexed((driverValue, driverName) => {
      onChange(driverName, driverValue)
    }, driverFieldsData)
  }

  const handleInputChange = (inputName, inputValue) => {
    onChange('driverId', null)
    onChange(inputName, inputValue)
  }

  return (
    <div style={{ position: 'relative' }} ref={componentRef}>
      <TextInputContainer selected={selected}>
        <TextInput
          value={value}
          onChange={handleInputChange}
          name={name}
          {...props}
        />
        {!disableSearch && (
          <Clear onClick={() => handleInputChange('driverName', '')}>
            <FormattedMessage id='common.clear' />
          </Clear>
        )}
      </TextInputContainer>
      {!disableSearch && (
        <ActionMenu active={filteredDrivers.length > 0 && value?.length > 0}>
          {filteredDrivers.map(driver => (
            <MenuItem
              key={driver.id}
              onClick={() => handleElementClick(driver)}
            >
              {driver.name}
            </MenuItem>
          ))}
        </ActionMenu>
      )}
    </div>
  )
}

export default DriversSearchInput

const ActionMenu = styled.ul`
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  background-color: white;
  display: none;
  flex-direction: column;
  overflow: hidden;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 9999;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  ${({ active }) =>
    active &&
    `
      display: flex;
  `}
`

const MenuItem = styled.li`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0.9rem 1rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 0.3s, background-color 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.05).hex()};
  }
  > span {
    margin-left: 0.8rem;
  }
  ${({ active }) =>
    active &&
    `
      font-weight: 800;
  `}
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.darkSkyBlue.hex()};
    `}
`

const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ selected }) =>
    selected &&
    css`
      & > div > div {
        border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
      }
    `}
`

const Clear = styled.span`
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-size: 0.875rem;
  margin-left: 0.5rem;
  cursor: pointer;
`
