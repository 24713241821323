import React, { useContext, useEffect, useState } from 'react'
import { isNil } from 'ramda'
import styled from 'styled-components'

import Timeline from 'src/features/stocks/components/OfferDetails/Timeline'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import BidOffer from 'src/features/stocks/components/BidOffer'
import Contact from 'src/features/stocks/components/OfferDetails/Contact'
import { breakpoint } from 'src/theme/grid'

import {
  AuctionPriceOfferDetails,
  BottomButtons,
  ButtonsForCustomer,
  ButtonsForOwner,
  ContainerInfo,
  ContainerSemiTrailerInfo,
  CreatedDateOfferDetails,
  CustomsClearance,
  Notes,
  OfferBelongsToCurrentUserInfo,
  OfferHasTrackingInfo,
  OfferRemovedInfo,
  OfferSoldInfo,
  Price,
  TrackingIconWithBackground
} from 'src/features/stocks/components/OfferDetails/atoms'
import useUrlParams from 'src/hooks/useUrlParams'
import { compose } from 'recompose'
import { connectImport } from 'src/features/stocks/ducks/connectors'
import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import { FormattedMessage, useIntl, navigate } from 'gatsby-plugin-intl'
import {
  AlertsRow,
  AttacheFileIcon,
  BidderTitle,
  Content,
  Drawer,
  FormActionBar,
  FormInnerWrapper,
  FormSection,
  Heading,
  HeadingContainer,
  MoreReservations,
  OfferDetailsContainer,
  OfferLink,
  OrdersLink,
  Subtitle,
  TrackingLink
} from 'src/features/offers/components/atoms'
import { Padding } from 'src/features/myOffers/components/atoms'
import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { Separator } from 'src/components/filters/components/atoms'
import ReservationDetailsOffers from 'src/features/myOffers/components/ReservationDetailsOffers'
import { PrivateOfferCountdown } from 'src/features/stocks/components/OfferDetails/atoms'
import { PrivateLabel } from 'src/features/offers/components/atoms'

const OfferDetails = ({
  className,
  offerDetails,
  timelineData,
  checkIfTheOfferBelongsToTheCurrentCompany,
  checkIfTheOfferBelongsToTheCurrentUser,
  updateOffer,
  reserveOffer,
  stock,
  refreshOffer,
  setConversationOpened,
  bidOffer,
  showOffer,
  prepareOrder,
  setDrawerState,
  cleanImportOffersDetails,
  showOngoingOffersReservations,
  showFinishedOffersReservations,
  buttonsState,
  acceptReservation,
  rejectReservation
}) => {
  const params = useUrlParams()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToSeeCompanyConversations = hasPermissionTo(
    PERMISSIONS.CHAT_COMPANY_CONVERSATIONS_SEE,
    permissions
  )
  const offerRemoved = !isNil(offerDetails.deletedAt)
  const isAuction = offerDetails.type === OFFER_TYPES.AUCTION
  const hasTracking =
    offerDetails.doesRequireTracking || offerDetails.doesAllowTracking
  const trackingReferenceNumber =
    offerDetails.transportationTrackingId &&
    offerDetails.transportationTracking?.referenceNumber
  const hasCustomsClearance = offerDetails.customsClearanceLocation.id
  const hasContainer = offerDetails.container && offerDetails.container.name
  const hasContainerSemiTrailer =
    offerDetails.containerSemiTrailer && offerDetails.containerSemiTrailer.name
  const hasNotes = offerDetails.notes
  const hasPrice =
    offerDetails.price && offerDetails.price.float > 0 && !isAuction
  const offerBelongsToCurrentUserCompany = checkIfTheOfferBelongsToTheCurrentCompany(
    offerDetails.issuer.companyId
  )
  const offerBelongsToCurrentUser = checkIfTheOfferBelongsToTheCurrentUser(
    offerDetails.handler.id
  )
  const [bidDialog, setBidDialog] = useState(false)
  const hidePrice =
    !offerBelongsToCurrentUserCompany &&
    !offerDetails.isPriceVisible &&
    offerDetails.reservationsCount === 0

  const changeHandler = newHandlerId => {
    updateOffer(offerDetails.id, { handler_id: newHandlerId })
  }

  const isFinished = offerDetails.finishedAt

  const intl = useIntl()

  useEffect(() => {
    params.id && showOffer(params.id, { origin: 'details' })
    return () => {
      setDrawerState(false)
      cleanImportOffersDetails()
    }
  }, [params.id])

  const navigateToMyOffers = () =>
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`, {
      state: { openOfferId: offerDetails.id }
    })

  const navigateToTrackingDetails = () => {
    trackingReferenceNumber &&
      navigate(
        `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${trackingReferenceNumber}`
      )
  }

  const hasTransportationOrder = offerDetails.transportationOrderId
  const onTransportationOrderClick = hasTransportationOrder
    ? () =>
        navigate(
          `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${offerDetails.transportationOrder.referenceNumber}`
        )
    : () =>
        offerDetails.canCreateTransportationOrder &&
        prepareOrder(offerDetails.id, STOCKS_TYPES.CARGO_IMPORT)
  const showOrdersLink =
    hasTransportationOrder || offerDetails.canCreateTransportationOrder

  const chatAvailable =
    offerBelongsToCurrentUser || hasPermissionToSeeCompanyConversations
  const hasReservations = offerDetails.reservationsCount > 0
  const hiddenReservationsCount =
    offerDetails.reservations.length > 0 &&
    offerDetails.reservationsCount - offerDetails.reservations.length

  useEffect(() => {
    !isFinished
      ? hasReservations &&
        offerDetails.reservations.length === 0 &&
        offerBelongsToCurrentUserCompany &&
        showOngoingOffersReservations(offerDetails.id)
      : offerDetails.reservations.length === 0 &&
        offerBelongsToCurrentUserCompany &&
        showFinishedOffersReservations(offerDetails.id)
  }, [hasReservations, offerDetails, offerBelongsToCurrentUserCompany])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              <FormattedMessage id='offers.offerDetailsImportTitle' />
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Content>
        <FormSection>
          <FormInnerWrapper>
            <Padding>
              <BreadCrumbs
                links={[
                  {
                    name: intl.formatMessage({ id: 'forms.dashboard' }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                  },
                  {
                    name: intl.formatMessage({
                      id: 'offers.freightExchangeImportTitle'
                    }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_IMPORT}`
                  },
                  {
                    name: (
                      <FormattedMessage
                        id='offers.offerDetails'
                        values={{ id: offerDetails.referenceNumber }}
                      />
                    ),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`
                  }
                ]}
              />
              <OfferDetailsContainer>
                {offerRemoved && <OfferRemovedInfo />}
                {!offerRemoved && offerDetails.id && (
                  <>
                    <HeadingContainer>
                      <Heading>
                        <FormattedMessage id='offers.offerDetailsTitle' />{' '}
                        <OfferLink
                          to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_IMPORT}&id=${offerDetails.id}`}
                        >
                          {offerDetails.referenceNumber}{' '}
                          {offerDetails.isPrivate && <PrivateLabel />}
                        </OfferLink>
                      </Heading>
                    </HeadingContainer>
                    {isFinished && (
                      <OfferSoldInfo status={offerDetails.outcome} />
                    )}
                    {offerBelongsToCurrentUserCompany && (
                      <OfferBelongsToCurrentUserInfo />
                    )}
                    <AlertsRow>
                      {hasTracking && trackingReferenceNumber && (
                        <TrackingLink
                          onClick={navigateToTrackingDetails}
                          title={intl.formatMessage({
                            id: 'offerDetails.tracking'
                          })}
                          icon={<TrackingIconWithBackground />}
                          type='white'
                          message={intl.formatMessage({
                            id: 'offerDetails.goToTracking'
                          })}
                        />
                      )}
                      {hasTracking && !trackingReferenceNumber && (
                        <OfferHasTrackingInfo />
                      )}
                      {showOrdersLink && (
                        <OrdersLink
                          onClick={onTransportationOrderClick}
                          title={
                            <FormattedMessage id='tracking.transportationOrder' />
                          }
                          message={
                            hasTransportationOrder ? (
                              <FormattedMessage id='tracking.goToTransportationOrder' />
                            ) : (
                              <FormattedMessage id='tracking.createTransportationOrder' />
                            )
                          }
                          type='white'
                          icon={<AttacheFileIcon />}
                        />
                      )}
                    </AlertsRow>
                    {isAuction && (
                      <AuctionPriceOfferDetails
                        offerDetails={offerDetails}
                        hidePrice={hidePrice}
                      />
                    )}
                    {timelineData && <Timeline data={timelineData} />}
                    {hasCustomsClearance && (
                      <CustomsClearance offerDetails={offerDetails} />
                    )}
                    {hasContainer && (
                      <ContainerInfo offerDetails={offerDetails} />
                    )}
                    {hasContainerSemiTrailer && (
                      <ContainerSemiTrailerInfo offerDetails={offerDetails} />
                    )}
                    {hasNotes && <Notes offerDetails={offerDetails} />}
                    {hasPrice && <Price offerDetails={offerDetails} />}
                    {offerBelongsToCurrentUserCompany &&
                      !offerRemoved &&
                      hasReservations &&
                      !isAuction && (
                        <div>
                          <Separator />
                          <Subtitle>
                            <FormattedMessage
                              id='offerDetails.reservations'
                              values={{
                                reservationsCount:
                                  offerDetails.reservationsCount
                              }}
                            />
                          </Subtitle>
                          {offerDetails.reservations.map(
                            (reservation, index) => (
                              <ReservationDetailsOffers
                                botttomOfferor
                                key={reservation.id}
                                offer={offerDetails}
                                reservation={reservation}
                                index={index}
                                isOpen={false}
                                finished={offerDetails.finishedAt}
                                chatAvailable={chatAvailable}
                                buttonsState={buttonsState}
                                rejectReservation={rejectReservation}
                                acceptReservation={acceptReservation}
                                rejectReservationSuccess={() =>
                                  showOffer(params.id, { origin: 'details' })
                                }
                                acceptReservationSuccess={() =>
                                  showOffer(params.id, { origin: 'details' })
                                }
                                setConversationOpened={() => {
                                  setConversationOpened({
                                    receiverId: reservation.offeror.id,
                                    conversationOpened: true
                                  })
                                }}
                              />
                            )
                          )}
                          {hiddenReservationsCount > 0 && !isAuction && (
                            <MoreReservations>
                              <FormattedMessage
                                id='myOffers.moreReservations'
                                values={{
                                  hiddenReservationsCount
                                }}
                              />
                            </MoreReservations>
                          )}
                        </div>
                      )}
                  </>
                )}
              </OfferDetailsContainer>
            </Padding>
          </FormInnerWrapper>
          <FormActionBar>
            {!offerBelongsToCurrentUserCompany && !offerRemoved && (
              <ButtonsForCustomer
                offerDetails={offerDetails}
                setBidDialog={setBidDialog}
                stock={stock}
                reserveOffer={reserveOffer}
                setConversationOpened={setConversationOpened}
                showPrivateOfferCountdown={false}
              />
            )}
            {offerBelongsToCurrentUserCompany && !offerRemoved && (
              <ButtonsForOwner
                offerDetails={offerDetails}
                stock={stock}
                refreshOffer={refreshOffer}
                navigateToMyOffers={navigateToMyOffers}
                showPrivateOfferCountdown={false}
              />
            )}
            <BidOffer
              open={bidDialog}
              setOpen={setBidDialog}
              offer={offerDetails}
              onClick={price => bidOffer({ id: offerDetails.id, price })}
            />
          </FormActionBar>
        </FormSection>
        <Drawer>
          {!offerRemoved && (
            <>
              <BidderTitle>
                <Heading>
                  <FormattedMessage id='offers.bidder' />
                </Heading>
              </BidderTitle>
              <Contact
                issuer={offerDetails.issuer}
                handler={offerDetails.handler}
                changeHandler={changeHandler}
                offerBelongsToCurrentUserCompany={
                  offerBelongsToCurrentUserCompany
                }
                offerBelongsToCurrentUser={offerBelongsToCurrentUser}
                isUpdatable={offerDetails.isUpdatable}
                handleConversationStart={() => {
                  setConversationOpened({
                    receiverId: offerDetails.handlerId,
                    contextId: offerDetails.id,
                    contextType: offerDetails.offerContextType,
                    conversationOpened: true
                  })
                }}
              />
              {offerDetails.isPrivate && (
                <PrivateOfferCountdown offerDetails={offerDetails} />
              )}
              <CreatedDateOfferDetails offerDetails={offerDetails} />
            </>
          )}
        </Drawer>
      </Content>
    </Page>
  )
}

const StyledOfferDetails = styled(OfferDetails)`
  background-color: ${({ theme }) => theme.colors.white.hex()};
  ${FormActionBar} {
    position: fixed;
    bottom: 0;
    padding: 0;
    ${breakpoint.m`
    position: static;

   `}
  }
  ${BottomButtons} {
    justify-content: flex-end;
    button {
      max-width: 200px;
    }
  }
  ${Padding} {
    max-width: calc(626px + 2.8rem);
    margin: 0 auto;
  }

  ${Content} {
    flex-direction: column;
    ${breakpoint.m`
    flex-direction: row;
   `}
  }

  ${breakpoint.m`
    ${Content} {
      overflow-y: hidden;
    ${Drawer} {
      overflow-y: auto;
    }
  }
   `}
`

export default compose(
  connectImport,
  connectMessagesBasicData
)(StyledOfferDetails)
