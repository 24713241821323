import { FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'
import { TextTitle } from 'src/features/invoices/InvoiceDetails'
import styled from 'styled-components'

export const SaftCodes = ({ saftCodes }) => (
  <>
    <TextTitle>
      <FormattedMessage id='invoices.taxProcedures' />
    </TextTitle>
    <PillsRow>
      {saftCodes.map(name => (
        <Pill>{name}</Pill>
      ))}
    </PillsRow>
    <Separator />
  </>
)

const Row = styled.div`
  display: flex;
  flex-direction: row;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
  flex-wrap: wrap;
`

const Separator = styled.hr`
  display: block;
  margin: 0.875rem 0;
  border: none;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
`

const Pill = styled.div`
  padding: 2px 8px;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  background-color: ${({ theme }) => theme.colors.easternBlue.alpha(0.1).hex()};
  border: 1px solid ${({ theme }) => theme.colors.easternBlue.hex()};
  border-radius: 4px;
  font-size: 12px;
`
const PillsRow = styled(Row)`
  margin: 0 0 1rem 0;
`
