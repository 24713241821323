import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { CreateOfferButton } from 'src/features/orders/components/atoms'
import Checkbox from 'src/components/atoms/CheckboxInput'

export const Center = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(887px + 2.8rem);
  margin: 0 auto;
`
export const StyledCheckbox = styled(Checkbox)`
  flex: 0.25;
  margin-right: 0.5rem;
  padding: 0 0.5rem 0.5rem 0;
  & > span:before {
    border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
    width: 14px;
    height: 14px;
  }
  & > span::after {
    width: 8px;
    height: 8px;
  }
  & span {
    font-size: 0.875rem;
  }
`
export const Settings = styled.div`
  cursor: pointer;
  &:hover {
    transition: color 0.3s;
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`
export const CenteredTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`
export const AdditionalInfo = styled.div`
  margin-top: 2rem;
`
export const SummaryContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${breakpoint.m`
    flex-direction: row;
  `}
`
export const PreviewButton = styled(CreateOfferButton)`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.easternBlue.hex()};
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  background-color: ${({ theme }) => theme.colors.white.hex()};
  margin-right: 0.75rem;
  box-shadow: none;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  svg {
    margin-left: 0.5rem;
  }
`
export const IssuerContainer = styled.div`
  margin-left: auto;
  width: 100%;
  ${breakpoint.m`
    width: 40%;
  `}
`
export const HeaderName = styled.span`
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`
export const OrderLink = styled.span`
  font-size: 0.875rem;
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`
