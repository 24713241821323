import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import {
  Column,
  CreatedAtUser,
  Dot,
  LanguageContainer,
  NarrowColumn,
  Row,
  TitleContainer,
  User
} from 'src/features/account/components/atoms'
import {
  Name,
  Subtitle,
  Title
} from 'src/features/account/components/typography'
import { Language } from 'src/components/SelectLanguage'
import {
  InputsRowWrapper,
  InputWrapper
} from 'src/components/atoms/FormHelpers'
import TextInput from 'src/components/atoms/TextInput'
import { validateEditUser } from 'src/features/account/duck/schema'
import { ROLE_OPTIONS } from 'src/features/account/duck/consts'
import Checkbox from 'src/components/atoms/CheckboxInput'
import Alert from 'src/components/Alert'
import moment from 'moment'
import { DATE_FORMAT } from 'src/ducks/consts'
import { breakpoint } from 'src/theme/grid'
import PowerIcon from 'src/assets/icons/power.svg'
import DeleteIcon from 'src/assets/icons/delete.svg'
import { connectEditCompanyUser } from 'src/features/account/duck/connectors'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { CancelButton, SaveOfferButton } from 'src/components/atoms/Buttons'
import AddPhotoDirectly from 'src/features/account/components/AddPhotoDirectly'

const EditUserForm = ({
  className,
  setEditUserFormValue: setChange,
  editUserForm: data,
  deactivateUser,
  deleteUser,
  currentUser,
  uploadEmployeeAvatar,
  user
}) => {
  const intl = useIntl()
  const [dialogs, setDialogs] = useState({ deactivate: false, delete: false })
  const isCurrentUser = currentUser.id === data.id

  return (
    <>
      <Row className={className}>
        <Column left>
          <TitleContainer>
            <Title>
              <FormattedMessage id='myCompany.users.role' />
            </Title>
          </TitleContainer>
          <RadioWrapper>
            {ROLE_OPTIONS.map(opt => (
              <Checkbox
                key={opt.value}
                name='role'
                value={data.role}
                onChange={setChange}
                type='radio'
                option={{ ...opt, text: intl.formatMessage({ id: opt.text }) }}
              />
            ))}
          </RadioWrapper>
          <TitleContainer>
            <Title>
              <FormattedMessage id='myCompany.users.basicInfo' />
            </Title>
          </TitleContainer>
          <BasicInfoContainer>
            {data.createdAt && (
              <CreatedAtUser>
                <FormattedMessage
                  id='account.createdAt'
                  values={{
                    date: moment(data.createdAt).format(DATE_FORMAT)
                  }}
                />
              </CreatedAtUser>
            )}
            <NarrowColumn>
              <AddPhotoDirectly
                onChange={file =>
                  uploadEmployeeAvatar({
                    id: data.id,
                    file
                  })
                }
                photo={data.avatar}
              />
            </NarrowColumn>
            <Column right>
              <UserContainer>
                <User>
                  <Name>{`${user.firstName} ${user.lastName}`}</Name>
                </User>
                <Alert
                  type='info'
                  message={intl.formatMessage({
                    id: 'account.changeDataAlert'
                  })}
                />
              </UserContainer>
              <InputsRowWrapper>
                <InputWrapper width={12}>
                  <TextInput
                    label={intl.formatMessage({ id: 'common.userName' })}
                    id='userName'
                    name='username'
                    placeholder={intl.formatMessage({
                      id: 'myCompany.users.usernamePlaceholder'
                    })}
                    value={data.username}
                    onChange={setChange}
                    validate={validateEditUser}
                  />
                </InputWrapper>
              </InputsRowWrapper>
              <InputsRowWrapper>
                <InputWrapper width={12}>
                  <TextInput
                    label={intl.formatMessage({ id: 'common.email' })}
                    id='email'
                    name='email'
                    placeholder={intl.formatMessage({
                      id: 'myCompany.users.emailPlaceholder'
                    })}
                    value={data.email}
                    onChange={setChange}
                    validate={validateEditUser}
                  />
                </InputWrapper>
              </InputsRowWrapper>
            </Column>
          </BasicInfoContainer>
          {!isCurrentUser && (
            <>
              <TitleContainer>
                <Title>
                  <FormattedMessage id='account.changePassword' />
                </Title>
              </TitleContainer>
              <InputsRowWrapper>
                <InputWrapper width={12}>
                  <TextInput
                    label={intl.formatMessage({
                      id: 'account.newPasswordLabel'
                    })}
                    id='password'
                    name='password'
                    type='password'
                    placeholder={intl.formatMessage({
                      id: 'account.newPasswordPlaceholder'
                    })}
                    value={data.password}
                    onChange={setChange}
                    validate={validateEditUser}
                  />
                </InputWrapper>
              </InputsRowWrapper>
            </>
          )}
        </Column>
        <Column right>
          <Row>
            <Column>
              <TitleContainer>
                <Title>
                  <FormattedMessage id='myCompany.contactData' />
                </Title>
              </TitleContainer>
              <InputsRowWrapper>
                <InputWrapper width={12}>
                  <TextInput
                    label={intl.formatMessage({ id: 'common.name' })}
                    id='name'
                    name='firstName'
                    placeholder={intl.formatMessage({
                      id: 'myCompany.users.namePlaceholder'
                    })}
                    value={data.firstName}
                    onChange={setChange}
                    validate={validateEditUser}
                  />
                </InputWrapper>
              </InputsRowWrapper>
              <InputsRowWrapper>
                <InputWrapper width={12}>
                  <TextInput
                    label={intl.formatMessage({ id: 'common.surname' })}
                    id='lastName'
                    name='lastName'
                    placeholder={intl.formatMessage({
                      id: 'myCompany.users.lastNamePlaceholder'
                    })}
                    value={data.lastName}
                    onChange={setChange}
                    validate={validateEditUser}
                  />
                </InputWrapper>
              </InputsRowWrapper>
              <InputsRowWrapper>
                <InputWrapper width={12}>
                  <TextInput
                    type='tel'
                    label={intl.formatMessage({
                      id: 'account.phoneNumberLabel'
                    })}
                    id='phoneNumber'
                    name='phoneNumber'
                    placeholder={intl.formatMessage({
                      id: 'common.phoneNumber'
                    })}
                    value={data.phoneNumber}
                    onChange={setChange}
                    validate={validateEditUser}
                  />
                </InputWrapper>
              </InputsRowWrapper>
              <InputsRowWrapper>
                <InputWrapper width={12}>
                  <TextInput
                    label={intl.formatMessage({
                      id: 'account.additionalInfoEdit'
                    })}
                    id='contactInfo'
                    name='contactInfo'
                    placeholder={intl.formatMessage({
                      id: 'account.additionalInfoPlaceholder'
                    })}
                    value={data.contactInfo}
                    onChange={setChange}
                    multiline
                    validate={validateEditUser}
                  />
                </InputWrapper>
              </InputsRowWrapper>
              <TitleContainer>
                <Title>
                  <FormattedMessage id='account.settings' />
                </Title>
              </TitleContainer>
              <LanguageContainer>
                <Subtitle>
                  <FormattedMessage id='common.language' />
                </Subtitle>
                <Language
                  updateCurrentUserLanguage={({ lang }) =>
                    setChange('lang', lang)
                  }
                  value={data.lang}
                />
              </LanguageContainer>
              {data.errorMessage && (
                <ErrorMessage type='alert' message={data.errorMessage} />
              )}
              <TitleContainer>
                <Title>
                  <FormattedMessage id='myCompany.users.status' />
                </Title>
              </TitleContainer>
              <StatusContainer>
                <div>
                  <Dot color={data.isActive ? 'apple' : 'fadedRed'} />
                  <FormattedMessage
                    id={
                      data.isActive
                        ? 'myCompany.users.isActive'
                        : 'myCompany.users.isNotActive'
                    }
                  />
                </div>
                {data.isActive && !isCurrentUser && (
                  <Deactivate
                    onClick={() => setDialogs({ ...dialogs, deactivate: true })}
                  >
                    <PowerIcon />
                    <FormattedMessage id='myCompany.users.deactivate' />
                  </Deactivate>
                )}
              </StatusContainer>
              {!data.isActive && (
                <DeleteAccount>
                  <Deactivate
                    onClick={() => setDialogs({ ...dialogs, delete: true })}
                  >
                    <DeleteIcon />
                    <FormattedMessage id='myCompany.users.deleteUser' />
                  </Deactivate>
                </DeleteAccount>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
      <Dialog
        open={dialogs.deactivate}
        onClose={() => setDialogs({ ...dialogs, deactivate: false })}
      >
        <DialogTitle>
          <FormattedMessage id='offerDetails.changeHandlerWarningTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id='myCompany.users.deactivateUserWarning'
              values={{
                username: data && `${data.firstName} ${data.lastName}`
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton
            active
            onClick={() => {
              setDialogs({ ...dialogs, deactivate: false })
            }}
          >
            {intl.formatMessage({ id: 'common.no' })}
          </CancelButton>
          <SaveOfferButton
            active
            onClick={() => {
              deactivateUser()
              setDialogs({ ...dialogs, deactivate: false })
            }}
          >
            {intl.formatMessage({ id: 'common.yes' })}
          </SaveOfferButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogs.delete}
        onClose={() => setDialogs({ ...dialogs, delete: false })}
      >
        <DialogTitle>
          <FormattedMessage id='offerDetails.changeHandlerWarningTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id='myCompany.users.deleteUserWarning'
              values={{
                username: data && `${data.firstName} ${data.lastName}`
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton
            active
            onClick={() => {
              setDialogs({ ...dialogs, delete: false })
            }}
          >
            {intl.formatMessage({ id: 'common.no' })}
          </CancelButton>
          <SaveOfferButton
            active
            onClick={() => {
              deleteUser()
              setDialogs({ ...dialogs, delete: false })
            }}
          >
            {intl.formatMessage({ id: 'common.yes' })}
          </SaveOfferButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
const StyledEditUserForm = styled(EditUserForm)`
  ${InputWrapper} {
    max-width: 400px;
  }

  ${InputsRowWrapper} {
    margin-bottom: 0.5rem;
  }
`

export default connectEditCompanyUser(StyledEditUserForm)

const Deactivate = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  svg {
    margin-right: 0.5rem;
  }
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
`

const DeleteAccount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.875rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.fadedRed};
`

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 1rem;
  flex-wrap: wrap;
  ${Checkbox} {
    width: auto;
    flex: 1;
  }
`

const BasicInfoContainer = styled(Row)`
  & {
    padding-top: 0;
  }
`

const ErrorMessage = styled(Alert)`
  max-width: 400px;
  box-sizing: border-box;
  margin: 1rem 0;
`

export const UserContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 0 0;
  ${breakpoint.m`
        padding: 1.5rem 0;
  `}
`
