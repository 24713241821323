import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import debounce from 'lodash.debounce'
import useClickOutside from 'src/hooks/useClickOutside'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import { breakpoint } from 'src/theme/grid'
import Loader from 'src/components/LinearLoader'
import CustomIcon from 'src/components/CustomIcon'

import {
  Title,
  NoMessagesComponent,
  NoMessagesTitle,
  NoMessagesDescription,
  CloseIcon
} from './components/atoms'
import Tabs, { TabContent } from './components/Tabs'
import ListSearchInput from './components/ListSearchInput'
import { connectMessages } from './duck/connectors'
import LastMessage from './components/LastMessage'
import FoundUser from './components/FoundUser'

const MessagesList = ({
  className,
  listOpened,
  setMessagesListOpened,
  getLastMessagesList,
  personalMessages,
  contextualMessages,
  setConversationOpened,
  messagesCounters,
  searchForUserConversations,
  personalSearchResults,
  contextualSearchResults,
  clearLastMessagesLists,

  contextualSearchResultsAllFetched,
  personalSearchResultsAllFetched,

  getLastContextualMessagesList,
  getLastPersonalMessagesList,
  isMessagesListLoading,
  isChatMuted,
  muteChat,
  unmuteChat,
  online
}) => {
  const intl = useIntl()
  const [personalSearchText, setPersonalSearchText] = useState('')
  const [contextualSearchText, setContextualSearchText] = useState('')
  const [activeTab, setActiveTab] = useState('contextual')
  const componentRef = useClickOutside(() => setMessagesListOpened(false))

  const scrollContextualRef = useInfiniteScroll(() => {
    if (!contextualSearchText) {
      return getLastContextualMessagesList(true)
    }

    if (contextualSearchText && !contextualSearchResultsAllFetched) {
      return searchForUserConversations({
        filter: {
          type: 'contextual',
          name: contextualSearchText
        },
        append: true
      })
    }
  }, [contextualSearchText, contextualSearchResultsAllFetched])

  const scrollPersonalRef = useInfiniteScroll(() => {
    if (!personalSearchText) {
      return getLastPersonalMessagesList(true)
    }
    if (personalSearchText && !personalSearchResultsAllFetched) {
      return searchForUserConversations({
        filter: {
          type: 'personal',
          name: personalSearchText
        },
        append: true
      })
    }
  }, [personalSearchText, personalSearchResultsAllFetched])

  useEffect(() => {
    if (listOpened) {
      getLastMessagesList()
    }

    return clearLastMessagesLists
  }, [listOpened])

  const debouncedSearch = useCallback(
    debounce(
      (text, type) =>
        searchForUserConversations({
          page: 1,
          filter: {
            type,
            name: text
          }
        }),
      500
    ),
    []
  )

  useEffect(() => {
    const activeSearchText =
      activeTab === 'contextual' ? contextualSearchText : personalSearchText

    if (!activeSearchText) return

    debouncedSearch(activeSearchText, activeTab)
  }, [contextualSearchText, personalSearchText])

  return (
    listOpened && (
      <div className={className} ref={componentRef}>
        <LoaderStyled loading={isMessagesListLoading} />
        <Title>{intl.formatMessage({ id: 'messages.listTitle' })}</Title>
        <CloseIcon onClick={() => setMessagesListOpened(false)} />
        <NotificationIcon
          name={isChatMuted ? 'notificationOff' : 'notificationActive'}
          isMuted={isChatMuted}
          size={24}
          onClick={() => (isChatMuted ? unmuteChat() : muteChat())}
        />

        <Tabs
          list={[
            {
              name: intl.formatMessage({ id: 'messages.contextTitle' }),
              counter: messagesCounters.contextualMessagesCount,
              tab: 'contextual'
            },
            {
              name: intl.formatMessage({ id: 'messages.noContextTitle' }),
              counter: messagesCounters.personalMessagesCount,
              tab: 'personal'
            }
          ]}
          onChange={tab => setActiveTab(tab)}
        >
          <TabContent>
            <Padding>
              <ListSearchInput
                name='name'
                id='id'
                value={contextualSearchText}
                onChange={e => setContextualSearchText(e.target.value)}
                placeholder={intl.formatMessage({ id: 'messages.searchText' })}
                onClear={() => setContextualSearchText('')}
              />
              {!contextualSearchText && contextualMessages.length === 0 && (
                <>
                  <NoMessagesComponent />
                  <NoMessagesTitle>
                    {intl.formatMessage({ id: 'messages.noMessagesTitle' })}
                  </NoMessagesTitle>
                  <NoMessagesDescription>
                    {intl.formatMessage({
                      id: 'messages.noMessagesDescriptionOffers'
                    })}
                  </NoMessagesDescription>
                </>
              )}
            </Padding>
            <MessagesContainer ref={scrollContextualRef}>
              {!contextualSearchText &&
                contextualMessages.map((message, index) => (
                  <LastMessage
                    key={index}
                    message={message}
                    messageSeen={message.messageSeen}
                    interlocutorOnline={online[message.interlocutorId]}
                    onClick={() => {
                      setConversationOpened({
                        receiverId: message.interlocutorId,
                        contextId: message.contextId,
                        contextType: message.contextType,
                        conversationOpened: true
                      })
                      setMessagesListOpened(false)
                    }}
                  />
                ))}
              {contextualSearchText.length > 0 &&
                contextualSearchResults.map((message, index) => (
                  <LastMessage
                    key={index}
                    message={message}
                    messageSeen={message.messageSeen}
                    interlocutorOnline={online[message.interlocutorId]}
                    onClick={() => {
                      setConversationOpened({
                        receiverId: message.interlocutorId,
                        contextId: message.contextId,
                        contextType: message.contextType,
                        conversationOpened: true
                      })
                      setMessagesListOpened(false)
                    }}
                  />
                ))}
            </MessagesContainer>
          </TabContent>
          <TabContent>
            <Padding>
              <ListSearchInput
                name='name'
                id='id'
                value={personalSearchText}
                onChange={e => setPersonalSearchText(e.target.value)}
                placeholder={intl.formatMessage({ id: 'messages.searchText' })}
                onClear={() => setPersonalSearchText('')}
              />
              {!personalSearchText && personalMessages.length === 0 && (
                <>
                  <NoMessagesComponent />
                  <NoMessagesTitle>
                    {intl.formatMessage({ id: 'messages.noMessagesTitle' })}
                  </NoMessagesTitle>
                  <NoMessagesDescription>
                    {intl.formatMessage({
                      id: 'messages.noMessagesDescription'
                    })}
                  </NoMessagesDescription>
                </>
              )}
            </Padding>
            <MessagesContainer ref={scrollPersonalRef}>
              {!personalSearchText &&
                personalMessages.map((message, index) => (
                  <LastMessage
                    key={index}
                    message={message}
                    messageSeen={message.messageSeen}
                    interlocutorOnline={online[message.interlocutorId]}
                    onClick={() => {
                      setConversationOpened({
                        receiverId: message.interlocutorId,
                        conversationOpened: true
                      })
                      setMessagesListOpened(false)
                    }}
                  />
                ))}

              {personalSearchText.length > 0 &&
                personalSearchResults.map((user, index) => (
                  <FoundUser
                    key={index}
                    user={user}
                    interlocutorOnline={online[user.id]}
                    onClick={() => {
                      setConversationOpened({
                        receiverId: user.id,
                        conversationOpened: true
                      })
                      setMessagesListOpened(false)
                    }}
                  />
                ))}
            </MessagesContainer>
          </TabContent>
        </Tabs>
      </div>
    )
  )
}

const ConnectedComponent = connectMessages(MessagesList)
export default styled(ConnectedComponent)`
  display: flex;
  flex-direction: column;
  position: fixed;
  box-sizing: border-box;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1001;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};

  height: 100%;
  width: 100%;
  ${breakpoint.s`
    height: calc(100vh - 60px);
    width: 400px;
  `}
`

const Padding = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.2rem;
`

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-height: calc(100% - 58px);
  overflow-y: auto;
`

const LoaderStyled = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
`

const NotificationIcon = styled(CustomIcon)`
  position: absolute;
  right: 64px;
  top: 13px;
  cursor: pointer;
  height: unset;
  ${breakpoint.s`
    right: 16px;
    top: 16px;
  `}

  ${({ isMuted }) =>
    isMuted &&
    css`
      color: ${({ theme }) => theme.colors.fadedRed.hex()};
    `}
`
