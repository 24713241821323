import { compose } from 'recompose'
import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'
import { connectContracts } from 'src/features/contracts/duck/connectors'
import {
  connectExport,
  connectFreeCarriers,
  connectImport,
  connectPostImport
} from '../../ducks/connectors'
import OfferDetails from './OfferDetails'
import OfferDetailsContract from './OfferDetailsContract'

export const ExportOfferDetails = compose(
  connectExport,
  connectMessagesBasicData
)(OfferDetails)

export const ImportOfferDetails = compose(
  connectImport,
  connectMessagesBasicData
)(OfferDetails)

export const PostImportOfferDetails = compose(
  connectPostImport,
  connectMessagesBasicData
)(OfferDetails)

export const FreeCarriersOfferDetails = compose(
  connectFreeCarriers,
  connectMessagesBasicData
)(OfferDetails)

export const ContractsOfferDetails = compose(
  connectContracts,
  connectMessagesBasicData
)(OfferDetailsContract)
