import React, { memo, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage, Link, navigate, useIntl } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import qs from 'qs'
import Dialog from '@material-ui/core/Dialog'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { OfferLink, Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroup,
  Content,
  DateInputWrapper,
  FormActionBar,
  FormInnerWrapper,
  FormSection,
  FormSectionSpacer,
  FormSubsectionSpacer,
  InputsRowWrapper,
  PlaceInputWrapper,
  TimeInputWrapper,
  WarningWrapper,
  TimeInfoWrapper
} from 'src/features/offers/components/atoms'
import Alert, { TimeAlert } from 'src/components/Alert'
import { Row, TitleContainer } from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'
import TextInput from 'src/components/atoms/TextInput'
import { breakpoint } from 'src/theme/grid'
import { connectTrackingEdit } from 'src/features/tracking/duck/connectors'
import { InputTitle } from 'src/features/debtCollection/DebtCollectionForm'
import CustomIcon from 'src/components/CustomIcon'
import LocationInput from 'src/components/filters/LocationInput'
import { API_STATES, LOCATION_TYPES } from 'src/ducks/consts'
import DatePicker from 'src/components/atoms/DatePicker'
import TextInputMasked from 'src/components/atoms/TextInputMasked'
import { connectDictionaries } from 'src/ducks/connectors'
import LocationInputWithGoogleMaps from 'src/components/filters/LocationInputWithGoogleMaps'
import XIcon from 'src/assets/icons/close-x.svg'
import {
  TRACKING_LOCATION_TYPES,
  FREE_CARRIER_TRANSPORTATION_TYPES_OPTIONS,
  TRACKING_OFFER_TYPES,
  ORIGIN_LOCATION_NAMES,
  DESTINATION_LOCATION_NAMES
} from 'src/features/tracking/duck/consts'
import Drawer from 'src/features/stocks/components/Drawer'
import TrackingOfferDetailsDrawer from 'src/features/tracking/TrackingOfferDetailsDrawer'
import AddFilesDropzone from 'src/features/tracking/components/AddFilesDropzone'
import AttachFileUrl from 'src/assets/icons/attach-file.svg'
import { ellipsisInTheMiddle } from 'src/utils/helpers'
import RemoveIconUrl from 'src/assets/icons/delete.svg'
import { SaveOfferButton } from 'src/components/atoms/Buttons'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { anyPass, propEq } from 'ramda'
import DriversSearchInput from 'src/features/tracking/components/DriversSearchInput'
import {
  validateTrackingEdit,
  validateTrackingEditSchema
} from 'src/features/tracking/duck/schema'
import useValidateSchema from 'src/hooks/useValidateSchema'

const TrackingEdit = ({
  className,
  getTrackingItem,
  trackingEditFrom,
  trackingItem,
  setTrackingEditFormValue,
  updateTracking,
  fetchLocations,
  loadingDictionary,
  returnDictionary,
  uploadTrackingFiles,
  deleteTrackingFile,
  setTrackingTransportationType,
  currentUserId,
  drivers,
  getDrivers,
  clearEditTrackingForm
}) => {
  const [
    showDetailedAddressFormOrigin,
    setShowDetailedAddressFormOrigin
  ] = useState(false)
  const [
    showDetailedAddressFormDestination,
    setShowDetailedAddressFormDestination
  ] = useState(false)

  useEffect(
    () => () => {
      clearEditTrackingForm()
    },
    []
  )

  useEffect(() => {
    if (
      trackingEditFrom.originLocationAddress ||
      trackingEditFrom.originLocationCompanyName
    ) {
      setShowDetailedAddressFormOrigin(true)
    }
  }, [
    trackingEditFrom.originLocationAddress,
    trackingEditFrom.originLocationCompanyName
  ])

  useEffect(() => {
    if (
      trackingEditFrom.destinationLocationAddress ||
      trackingEditFrom.destinationLocationCompanyName
    ) {
      setShowDetailedAddressFormDestination(true)
    }
  }, [
    trackingEditFrom.destinationLocationAddress,
    trackingEditFrom.destinationLocationCompanyName
  ])

  const [freeCarriersModalOpen, setFreeCarriersModalOpen] = useState(false)
  const [
    freeCarrierTransportationType,
    setFreeCarrierTransportationType
  ] = useState(null)

  const isExportOffer = propEq('offerType', TRACKING_OFFER_TYPES.EXPORT)
  const isImportOffer = propEq('offerType', TRACKING_OFFER_TYPES.IMPORT)
  const isPostImportOffer = propEq(
    'offerType',
    TRACKING_OFFER_TYPES.POST_IMPORT
  )
  const isFreeCarrierOffer = propEq(
    'offerType',
    TRACKING_OFFER_TYPES.FREE_CARRIER
  )

  const isCurrentUserForwarder = propEq(
    'forwarderUserId',
    currentUserId
  )(trackingItem)

  const disableDriverFields =
    trackingItem.driverId && currentUserId !== trackingItem.carrierUserId

  const showOriginLocationDetails = anyPass([isExportOffer, isPostImportOffer])(
    trackingItem
  )
  const showDestinationLocationDetails = anyPass([
    isImportOffer,
    isFreeCarrierOffer
  ])(trackingItem)

  // const originLocationNames =
  //   ORIGIN_LOCATION_NAMES[trackingItem.offerType || TRACKING_OFFER_TYPES.EXPORT]

  let originLocationNames

  if (!trackingItem.offerType) {
    console.log('trackingItem.offerType is null')
    originLocationNames = Object.assign(
      {},
      ORIGIN_LOCATION_NAMES[TRACKING_OFFER_TYPES.EXPORT]
    )
  } else if (trackingItem.offerType === 'free_carrier_offer') {
    console.log('trackingItem.offerType is free_carrier_offer')
    if (trackingItem.transportationType === 'export') {
      console.log('trackingItem.transportationType is export')
      originLocationNames = Object.assign(
        {},
        DESTINATION_LOCATION_NAMES[trackingItem.offerType]
      )
      originLocationNames['sectionName'] =
        ORIGIN_LOCATION_NAMES[trackingItem.offerType]['sectionName']
    } else if (trackingItem.transportationType === 'import') {
      console.log('trackingItem.transportationType is import')
      originLocationNames = Object.assign(
        {},
        ORIGIN_LOCATION_NAMES[trackingItem.offerType]
      )
    } else {
      console.log('trackingItem.transportationType is not export or import')
      originLocationNames = Object.assign(
        {},
        ORIGIN_LOCATION_NAMES[TRACKING_OFFER_TYPES.EXPORT]
      )
    }
  } else {
    console.log('trackingItem.offerType is not free_carrier_offer')
    originLocationNames = Object.assign(
      {},
      ORIGIN_LOCATION_NAMES[trackingItem.offerType]
    )
  }

  if (!originLocationNames) {
    originLocationNames = Object.assign(
      {},
      ORIGIN_LOCATION_NAMES[TRACKING_OFFER_TYPES.EXPORT]
    )
    console.log('originLocationNames is null')
  }

  // REACTJS IS A SHIT
  let destinationLocationNames

  if (trackingItem.offerType === 'free_carrier_offer') {
    if (trackingItem.transportationType === 'export') {
      destinationLocationNames = Object.assign(
        {},
        ORIGIN_LOCATION_NAMES[trackingItem.offerType]
      )
      destinationLocationNames['sectionName'] =
        DESTINATION_LOCATION_NAMES[trackingItem.offerType]['sectionName']
    } else if (trackingItem.transportationType === 'import') {
      destinationLocationNames = Object.assign(
        {},
        DESTINATION_LOCATION_NAMES[trackingItem.offerType]
      )
    }
  } else {
    destinationLocationNames = Object.assign(
      {},
      DESTINATION_LOCATION_NAMES[
        trackingItem.offerType || TRACKING_OFFER_TYPES.EXPORT
      ]
    )
  }

  if (!destinationLocationNames) {
    destinationLocationNames = Object.assign(
      {},
      DESTINATION_LOCATION_NAMES[TRACKING_OFFER_TYPES.EXPORT]
    )
  }

  const handleDialogClose = () => {
    setFreeCarriersModalOpen(false)
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING}`)
  }

  const handleChooseType = type => {
    setTrackingTransportationType({
      id: trackingItem.id,
      values: { transportationType: type }
    })
  }

  useEffect(() => {
    getDrivers()
  }, [])

  useEffect(() => {
    setFreeCarriersModalOpen(
      !trackingItem.transportationType && trackingItem.id
    )
  }, [trackingItem.transportationType, trackingItem.id])

  useEffect(() => {
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    getTrackingItem(params.id)
  }, [])

  const intl = useIntl()

  const valid = useValidateSchema(
    trackingEditFrom.toJS(),
    validateTrackingEditSchema
  )

  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                <FormattedMessage
                  id='tracking.editTrackingTitle'
                  values={{ id: trackingItem.referenceNumber }}
                />
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          <div>{/* {JSON.stringify(trackingItem)} */}</div>
          <FormSection>
            <FormInnerWrapper>
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'tracking.tracking' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING}`
                    },
                    {
                      name: (
                        <FormattedMessage
                          id='tracking.trackingPreview'
                          values={{ id: trackingItem.referenceNumber }}
                        />
                      ),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${trackingItem.id}`
                    },
                    {
                      name: (
                        <FormattedMessage
                          id='tracking.editTracking'
                          values={{ id: trackingItem.referenceNumber }}
                        />
                      ),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_EDIT}&id=${trackingItem.id}`
                    }
                  ]}
                />
                <WarningWrapper>
                  <Alert
                    message={intl.formatMessage({
                      id: 'tracking.editTrackingGeneralInfo'
                    })}
                    type='info'
                  />
                </WarningWrapper>
                {!isCurrentUserForwarder && (
                  <WarningWrapper>
                    <Alert
                      message={intl.formatMessage({
                        id: 'tracking.editAllowedOnlyForCarrier'
                      })}
                      type='warning'
                    />
                  </WarningWrapper>
                )}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='tracking.transportationType' />
                  </Title>
                </TitleContainer>
                {trackingItem.transportationType && (
                  <OfferTypeWrapper>
                    <CustomIcon
                      name={trackingItem.transportationType}
                      size={24}
                    />
                    <OfferType>
                      <FormattedMessage
                        id={`common.${trackingItem.transportationType}`}
                        defaultMessage='-'
                      />
                    </OfferType>
                  </OfferTypeWrapper>
                )}
                <FormSectionSpacer>
                  {intl.formatMessage({
                    id: `forms.${originLocationNames.sectionName}`
                  })}
                </FormSectionSpacer>
                <InputsRowWrapper>
                  {/* <h1>iscurrentuserforwarder ? {isCurrentUserForwarder ? "TAK": "NIE"} - currentUserId: {currentUserId}, trackingItem.forwarderUserId -> {JSON.stringify(trackingItem)}</h1> */}

                  {trackingItem.transportationType === 'import' && (
                    <PlaceInputWrapper>
                      <LocationInput
                        disabled={!isCurrentUserForwarder}
                        id='originLocationId'
                        label={intl.formatMessage({
                          id: `forms.${originLocationNames.placeInputLabel}`
                        })}
                        locationName='originLocationId'
                        locationValue={trackingEditFrom.originLocationId}
                        locationPlaceholder={intl.formatMessage({
                          id: `forms.${originLocationNames.placeInputLabel}`
                        })}
                        onChange={setTrackingEditFormValue}
                        placeholder={intl.formatMessage({
                          id: 'forms.locationPlaceholder'
                        })}
                        locationTypes={LOCATION_TYPES.TERMINAL}
                        dictionary={loadingDictionary}
                        fetchLocations={fetchLocations}
                        searchHintText={intl.formatMessage({
                          id: 'offersFilters.searchHintText'
                        })}
                        validate={validateTrackingEdit}
                      />
                    </PlaceInputWrapper>
                  )}
                  {trackingItem.transportationType === 'export' && (
                    <PlaceInputWrapper>
                      <LocationInput
                        disabled={!isCurrentUserForwarder}
                        id='originLocationId'
                        label={intl.formatMessage({
                          id: `forms.${originLocationNames.placeInputLabel}`
                        })}
                        locationName='originLocationId'
                        locationValue={trackingEditFrom.originLocationId}
                        locationPlaceholder={intl.formatMessage({
                          id: `forms.${originLocationNames.placeInputLabel}`
                        })}
                        onChange={setTrackingEditFormValue}
                        placeholder={intl.formatMessage({
                          id: 'forms.locationPlaceholder'
                        })}
                        locationTypes={LOCATION_TYPES.CITY}
                        dictionary={loadingDictionary}
                        fetchLocations={fetchLocations}
                        searchHintText={intl.formatMessage({
                          id: 'offersFilters.searchHintText'
                        })}
                        validate={validateTrackingEdit}
                      />
                    </PlaceInputWrapper>
                  )}

                  <DateInputWrapper>
                    <DatePicker
                      disabled={!isCurrentUserForwarder}
                      required
                      label={intl.formatMessage({
                        id: `forms.${originLocationNames.dateInputLabel}`
                      })}
                      disablePast
                      name='originLocationOperationDate'
                      placeholder='dd.mm.rrrr'
                      value={trackingEditFrom.originLocationOperationDate}
                      onChange={setTrackingEditFormValue}
                      validate={validateTrackingEdit}
                    />
                  </DateInputWrapper>
                  <TimeInputWrapper>
                    <TextInputMasked
                      disabled={!isCurrentUserForwarder}
                      required
                      mask='99:99'
                      label={intl.formatMessage({
                        id: 'forms.loadingTimeInputLabel'
                      })}
                      id='loadingTime'
                      name='originLocationOperationTime'
                      placeholder='gg:mm'
                      value={trackingEditFrom.originLocationOperationTime}
                      onChange={setTrackingEditFormValue}
                      type='tel'
                      calendarTime
                      validate={validateTrackingEdit}
                    />
                  </TimeInputWrapper>
                  <TimeInfoWrapper>
                    <TimeAlert
                      message={intl.formatMessage({
                        id: 'tracking.editTime'
                      })}
                      type='info'
                    />
                  </TimeInfoWrapper>
                  {showOriginLocationDetails && (
                    <PlaceInputWrapper>
                      {!showDetailedAddressFormOrigin && (
                        <EnterADetailedAddress
                          onClick={() => setShowDetailedAddressFormOrigin(true)}
                        >
                          +{' '}
                          <FormattedMessage id='tracking.enterDetailedAddress' />
                        </EnterADetailedAddress>
                      )}
                      {showDetailedAddressFormOrigin && (
                        <DetailedAddressForm disabled={!isCurrentUserForwarder}>
                          <DetailedAddressTitle>
                            <FormattedMessage id='tracking.enterDetailedAddress' />
                            <CloseIcon
                              onClick={() =>
                                setShowDetailedAddressFormOrigin(false)
                              }
                            />
                          </DetailedAddressTitle>
                          <TextInput
                            type='text'
                            label={intl.formatMessage({
                              id: 'common.companyName'
                            })}
                            id='originLocationCompanyName'
                            name='originLocationCompanyName'
                            placeholder={intl.formatMessage({
                              id: 'common.companyName'
                            })}
                            value={trackingEditFrom.originLocationCompanyName}
                            onChange={setTrackingEditFormValue}
                            validate={validateTrackingEdit}
                          />
                          <LocationInputWithGoogleMaps
                            id='originLocationId'
                            label='Adres'
                            locationName='originLocationAddress'
                            locationValue={
                              trackingEditFrom.originLocationAddress
                            }
                            locationType={TRACKING_LOCATION_TYPES.ORIGIN}
                            locationPlaceholder={intl.formatMessage({
                              id: 'tracking.enterAddressAndChoose'
                            })}
                            onChange={setTrackingEditFormValue}
                            placeholder={intl.formatMessage({
                              id: 'forms.locationPlaceholder'
                            })}
                            searchHintText={intl.formatMessage({
                              id: 'offersFilters.searchHintText'
                            })}
                            validate={validateTrackingEdit}
                          />
                        </DetailedAddressForm>
                      )}
                    </PlaceInputWrapper>
                  )}
                </InputsRowWrapper>
                <FormSectionSpacer>
                  {intl.formatMessage({
                    id: `forms.${destinationLocationNames.sectionName}`
                  })}
                </FormSectionSpacer>
                <InputsRowWrapper>
                  {trackingItem.transportationType === 'import' && (
                    <PlaceInputWrapper>
                      <LocationInput
                        disabled={!isCurrentUserForwarder}
                        id='destinationLocationId'
                        label={intl.formatMessage({
                          id: `forms.${destinationLocationNames.placeInputLabel}`
                        })}
                        locationName='destinationLocationId'
                        locationValue={trackingEditFrom.destinationLocationId}
                        locationPlaceholder={intl.formatMessage({
                          id: `forms.${destinationLocationNames.placeInputLabel}`
                        })}
                        onChange={setTrackingEditFormValue}
                        placeholder={intl.formatMessage({
                          id: 'forms.locationPlaceholder'
                        })}
                        locationTypes={LOCATION_TYPES.CITY}
                        dictionary={returnDictionary}
                        fetchLocations={fetchLocations}
                        searchHintText={intl.formatMessage({
                          id: 'offersFilters.searchHintText'
                        })}
                        validate={validateTrackingEdit}
                      />
                    </PlaceInputWrapper>
                  )}

                  {trackingItem.transportationType === 'export' && (
                    <PlaceInputWrapper>
                      <LocationInput
                        disabled={!isCurrentUserForwarder}
                        id='destinationLocationId'
                        label={intl.formatMessage({
                          id: `forms.${destinationLocationNames.placeInputLabel}`
                        })}
                        locationName='destinationLocationId'
                        locationValue={trackingEditFrom.destinationLocationId}
                        locationPlaceholder={intl.formatMessage({
                          id: `forms.${destinationLocationNames.placeInputLabel}`
                        })}
                        onChange={setTrackingEditFormValue}
                        placeholder={intl.formatMessage({
                          id: 'forms.locationPlaceholder'
                        })}
                        locationTypes={LOCATION_TYPES.TERMINAL}
                        dictionary={returnDictionary}
                        fetchLocations={fetchLocations}
                        searchHintText={intl.formatMessage({
                          id: 'offersFilters.searchHintText'
                        })}
                        validate={validateTrackingEdit}
                      />
                    </PlaceInputWrapper>
                  )}

                  <DateInputWrapper>
                    <DatePicker
                      disabled={!isCurrentUserForwarder}
                      label={intl.formatMessage({
                        id: `forms.${destinationLocationNames.dateInputLabel}`
                      })}
                      disablePast
                      required
                      name='destinationLocationOperationDate'
                      placeholder='dd.mm.rrrr'
                      value={trackingEditFrom.destinationLocationOperationDate}
                      onChange={setTrackingEditFormValue}
                      minDate={trackingEditFrom.originLocationOperationDate}
                      validate={validateTrackingEdit}
                    />
                  </DateInputWrapper>
                  <TimeInputWrapper>
                    <TextInputMasked
                      disabled={!isCurrentUserForwarder}
                      required
                      mask='99:99'
                      label={intl.formatMessage({
                        id: 'forms.returnTimeInputLabel'
                      })}
                      id='destinationLocationOperationTime'
                      name='destinationLocationOperationTime'
                      placeholder='gg:mm'
                      value={trackingEditFrom.destinationLocationOperationTime}
                      onChange={setTrackingEditFormValue}
                      type='tel'
                      calendarTime
                      validate={validateTrackingEdit}
                    />
                  </TimeInputWrapper>
                </InputsRowWrapper>
                {showDestinationLocationDetails && (
                  <LocationDetailsWrapper>
                    {!showDetailedAddressFormDestination && (
                      <EnterADetailedAddress
                        onClick={() =>
                          setShowDetailedAddressFormDestination(true)
                        }
                      >
                        +{' '}
                        <FormattedMessage id='tracking.enterDetailedAddress' />
                      </EnterADetailedAddress>
                    )}
                    {showDetailedAddressFormDestination && (
                      <DetailedAddressForm disabled={!isCurrentUserForwarder}>
                        <DetailedAddressTitle>
                          <FormattedMessage id='tracking.enterDetailedAddress' />
                          <CloseIcon
                            onClick={() =>
                              setShowDetailedAddressFormDestination(false)
                            }
                          />
                        </DetailedAddressTitle>
                        <TextInput
                          type='text'
                          label={intl.formatMessage({
                            id: 'common.companyName'
                          })}
                          id='destinationLocationCompanyName'
                          name='destinationLocationCompanyName'
                          placeholder={intl.formatMessage({
                            id: 'common.companyName'
                          })}
                          value={
                            trackingEditFrom.destinationLocationCompanyName
                          }
                          onChange={setTrackingEditFormValue}
                          validate={validateTrackingEdit}
                        />
                        <LocationInputWithGoogleMaps
                          id='destinationLocationId'
                          label='Adres'
                          locationName='destinationLocationAddress'
                          locationValue={
                            trackingEditFrom.destinationLocationAddress
                          }
                          locationType={TRACKING_LOCATION_TYPES.DESTINATION}
                          locationPlaceholder={intl.formatMessage({
                            id: 'tracking.enterAddressAndChoose'
                          })}
                          onChange={setTrackingEditFormValue}
                          placeholder={intl.formatMessage({
                            id: 'forms.locationPlaceholder'
                          })}
                          searchHintText={intl.formatMessage({
                            id: 'offersFilters.searchHintText'
                          })}
                          validate={validateTrackingEdit}
                        />
                      </DetailedAddressForm>
                    )}
                  </LocationDetailsWrapper>
                )}{' '}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='forms.additionalInformation' />
                  </Title>
                </TitleContainer>
                <TextInput
                  disabled={!isCurrentUserForwarder}
                  label={intl.formatMessage({
                    id: 'forms.additionalInformation'
                  })}
                  id='additionalInformation'
                  name='notes'
                  multiline
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: 'forms.additionalInformation'
                  })}
                  value={trackingEditFrom.notes}
                  onChange={setTrackingEditFormValue}
                  validate={validateTrackingEdit}
                />
                <Row>
                  <DropZoneContainer>
                    <InputTitle>
                      <FormattedMessage id='tracking.files' />
                    </InputTitle>
                    <AddFilesDropzone
                      files={trackingItem.files}
                      onChange={uploadTrackingFiles(trackingItem.id)}
                    >
                      <FormattedMessage
                        id='debtCollection.dropZone'
                        values={{ a: (...chunks) => <a>{chunks}</a> }}
                      />
                    </AddFilesDropzone>
                    <DropZoneInfo>
                      <FormattedMessage id='tracking.dropZoneInfo' />
                    </DropZoneInfo>
                  </DropZoneContainer>
                </Row>
                {trackingItem.files.length > 0 && (
                  <FilesList>
                    {trackingItem.files.map(file => (
                      <File key={file.url}>
                        <AttachIcon>
                          <AttachFileUrl />
                        </AttachIcon>
                        <FileName>
                          <a href={file.url}>
                            {ellipsisInTheMiddle(file.filename, 40)}
                          </a>
                        </FileName>
                        {file.isDeletable && (
                          <RemoveIcon
                            onClick={() =>
                              deleteTrackingFile(trackingItem.id, file.id)
                            }
                          >
                            <RemoveIconUrl />
                          </RemoveIcon>
                        )}
                      </File>
                    ))}
                  </FilesList>
                )}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='tracking.driverAndVehicle' />
                  </Title>
                </TitleContainer>
                {isCurrentUserForwarder && (
                  <WarningWrapper>
                    <Alert
                      message={intl.formatMessage({
                        id: 'tracking.driverAlertInfo'
                      })}
                      type='warning'
                    />
                  </WarningWrapper>
                )}
                <DriverInputsContainer>
                  <FormSubsectionSpacer>
                    {intl.formatMessage({ id: 'tracking.driverData' })}
                  </FormSubsectionSpacer>
                  <InputsRowWrapper>
                    <DriversSearchInput
                      disabled={
                        disableDriverFields || !!trackingEditFrom.driverId
                      }
                      required
                      type='search'
                      label={intl.formatMessage({
                        id: 'common.name'
                      })}
                      id='driverName'
                      name='driverName'
                      placeholder={intl.formatMessage({
                        id: 'tracking.driverName'
                      })}
                      value={trackingEditFrom.driverName}
                      onChange={setTrackingEditFormValue}
                      drivers={drivers}
                      selected={trackingEditFrom.driverId}
                      disableSearch={isCurrentUserForwarder}
                      validate={validateTrackingEdit}
                    />
                  </InputsRowWrapper>
                  <InputsRowWrapper>
                    <TextInput
                      disabled={disableDriverFields}
                      required
                      type='tel'
                      label={intl.formatMessage({
                        id: 'account.phoneNumberLabel'
                      })}
                      id='phoneNumber'
                      name='driverPhoneNumber'
                      placeholder={intl.formatMessage({
                        id: 'common.phoneNumber'
                      })}
                      value={trackingEditFrom.driverPhoneNumber}
                      onChange={setTrackingEditFormValue}
                      validate={validateTrackingEdit}
                    />
                  </InputsRowWrapper>
                  <InputsRowWrapper>
                    <TextInput
                      disabled={disableDriverFields}
                      type='email'
                      label={intl.formatMessage({
                        id: 'common.email'
                      })}
                      id='email'
                      name='driverEmail'
                      placeholder={intl.formatMessage({
                        id: 'common.email'
                      })}
                      value={trackingEditFrom.driverEmail}
                      onChange={setTrackingEditFormValue}
                      validate={validateTrackingEdit}
                    />
                  </InputsRowWrapper>
                  <InputsRowWrapper>
                    <TextInput
                      disabled={disableDriverFields}
                      type='text'
                      label={intl.formatMessage({
                        id: 'tracking.driverIdentityCardNumber'
                      })}
                      id='driverIdentityCardNumber'
                      name='driverIdentityCardNumber'
                      placeholder={intl.formatMessage({
                        id: 'tracking.driverIdentityCardNumber'
                      })}
                      value={trackingEditFrom.driverIdentityCardNumber}
                      onChange={setTrackingEditFormValue}
                      validate={validateTrackingEdit}
                    />
                  </InputsRowWrapper>
                  <FormSubsectionSpacer>
                    {intl.formatMessage({ id: 'tracking.vehicle' })}
                  </FormSubsectionSpacer>
                  <InputsRowWrapper>
                    <TextInput
                      disabled={
                        isCurrentUserForwarder &&
                        trackingItem.vehicleRegistrationPlate
                      }
                      type='text'
                      label={intl.formatMessage({
                        id: 'tracking.vehicleRegistrationPlate'
                      })}
                      id='vehicleRegistrationPlate'
                      name='vehicleRegistrationPlate'
                      placeholder={intl.formatMessage({
                        id: 'tracking.vehicleRegistrationPlate'
                      })}
                      value={trackingEditFrom.vehicleRegistrationPlate}
                      onChange={setTrackingEditFormValue}
                      validate={validateTrackingEdit}
                    />
                  </InputsRowWrapper>
                </DriverInputsContainer>
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING}`}>
                {intl.formatMessage({ id: 'forms.cancel' })}
              </Link>
              <ActionGroup>
                <CreateOfferButton
                  active={
                    valid && trackingEditFrom.state !== API_STATES.IN_PROGRESS
                  }
                  onClick={() =>
                    updateTracking({
                      id: trackingItem.id,
                      values: trackingEditFrom
                    })
                  }
                >
                  {intl.formatMessage({ id: 'common.save' })}
                </CreateOfferButton>
              </ActionGroup>
            </FormActionBar>
          </FormSection>
          <Drawer open={true} onClose={() => {}}>
            <TrackingOfferDetailsDrawer />
          </Drawer>
        </Content>
      </Page>
      <Dialog
        open={freeCarriersModalOpen}
        onClose={handleDialogClose}
        PaperProps={{
          style: { borderRadius: 9 }
        }}
      >
        <DialogContent>
          <ModalCloseIcon onClick={handleDialogClose} />
          <DialogHeader>
            <FormattedMessage
              id='tracking.chooseTransportationTypeTitle'
              values={{ id: trackingItem.referenceNumber }}
            />
          </DialogHeader>
          <DialogText>
            <FormattedMessage
              id='tracking.chooseTransportationTypeDescription'
              values={{
                id: (
                  <OfferLink
                    onClick={() => {
                      navigate(
                        `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${trackingItem.id}`
                      )
                    }}
                  >
                    {trackingItem.referenceNumber}
                  </OfferLink>
                ),
                p: (...chunks) => (
                  <p>
                    <br />
                    {chunks}
                  </p>
                )
              }}
            />
          </DialogText>
          <RadioWrapper>
            {FREE_CARRIER_TRANSPORTATION_TYPES_OPTIONS.map(opt => (
              <StyledCheckbox
                key={opt.value}
                name='type'
                value={freeCarrierTransportationType}
                selected={opt.value === freeCarrierTransportationType}
                onChange={(name, value) =>
                  setFreeCarrierTransportationType(value)
                }
                type='radio'
                option={{
                  ...opt,
                  description: intl.formatMessage({ id: opt.description }),
                  text: intl.formatMessage({ id: opt.text })
                }}
              />
            ))}
          </RadioWrapper>
          <ActionBar>
            <CancelButton onClick={handleDialogClose}>
              {intl.formatMessage({ id: 'myOffers.notNow' })}
            </CancelButton>
            <SaveOfferButton
              active
              onClick={() => handleChooseType(freeCarrierTransportationType)}
            >
              <FormattedMessage id='tracking.chooseType' />
            </SaveOfferButton>
          </ActionBar>
        </DialogContent>
      </Dialog>
    </>
  )
}

const ConnectedComponent = compose(
  connectTrackingEdit,
  connectDictionaries
)(TrackingEdit)

export default memo(styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
  }
  ${Padding} {
    max-width: calc(626px + 2.8rem);
    margin: 0 auto;
  }
  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
`)

const CreateOfferButton = styled.button`
  font-size: 1rem;
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  overflow: visible;
  padding: 0 1.2rem;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.mercury.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  pointer-events: none;
  position: relative;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      cursor: pointer;
      color: white;
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      box-shadow: 0 2px 10px 0 rgba(71, 170, 66, 0.25);
    `}
`

const DriverInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint.m`
  width: 50%;
  `}
`

const DropZoneContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const DropZoneInfo = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  opacity: 0.7;
`

const OfferTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  & > i {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  }
`

const OfferType = styled.p`
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

const EnterADetailedAddress = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  cursor: pointer;
  transition: opacity 0.2s;
`

const DetailedAddressForm = styled.div`
  background-color: ${({ theme }) => theme.colors.apple.alpha(0.05).hex()};
  padding: 0.75rem;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      div {
        opacity: 0.7;
      }
    `}
`

const CloseIcon = styled(XIcon)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background-color 0.3s;
  margin-left: auto;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const DetailedAddressTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  text-transform: uppercase;
  line-height: 1.7;
  display: flex;
  margin-bottom: 0.5rem;
`

const RemoveIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const FileName = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & span {
    font-size: 0.75rem;
    opacity: 0.7;
  }
`
const FilesList = styled.ul`
  width: 100%;
  margin-top: 0.5rem;
  ${breakpoint.m`
    margin-top: 0;
  `}
`

const File = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  width: 100%;
`

const AttachIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.geyser.hex()};
  border-radius: 4px;
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.colors.white.hex()};
  margin-right: 0.5rem;
`

const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    width: 600px;
    padding: 1.6rem 2.2rem;
  `}
`

const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  margin: 0.8rem 0 1.2rem 0;
  width: 100%;
  display: inline-block;
`

const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    margin-bottom: 0;
  `}
`

const CancelButton = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
  margin-right: 0.8rem;
  align-items: center;
  ${breakpoint.m`
    margin-right: 2.2rem;
  `}
`

const ModalCloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const StyledCheckbox = styled(Checkbox)`
  border: 1px solid ${({ theme }) => theme.colors.geyser.hex()};
  border-radius: 4px;
  min-height: 104px;
  flex: 1;
  margin-right: 0.5rem;
  padding: 1rem;
  text-transform: capitalize;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.alpha(0.1).hex()};
      border-color: ${({ theme }) => theme.colors.apple.hex()};
    `}
`

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`
const LocationDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`
