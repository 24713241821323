import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useIntl } from 'gatsby-plugin-intl'

import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import Tabs from 'src/components/Tabs'
import { Column, Row, SectionBox } from 'src/features/account/components/atoms'
import { Page } from 'src/components/Wrappers'
import Alert from 'src/components/Alert'
import { breakpoint } from 'src/theme/grid'
import { ORDERS_TABS_ARRAY } from 'src/features/orders/duck/consts'
import OrdersIssued from 'src/features/orders/components/OrdersIssued'
import OrdersReceived from 'src/features/orders/components/OrdersReceived'
import { connectOrderList } from './duck/connectors'

const Orders = ({
  getOrdersListReceived,
  getOrdersListIssued,
  isCurrentCompanyCarrier,
  className
}) => {
  useEffect(() => {
    getOrdersListIssued()
    getOrdersListReceived()
  }, [])

  const intl = useIntl()

  const hideIssuedOrders = isCurrentCompanyCarrier

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'orders.orders'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Tabs
        list={hideIssuedOrders ? [ORDERS_TABS_ARRAY[1]] : ORDERS_TABS_ARRAY}
      >
        {!hideIssuedOrders && (
          <SectionBox>
            <Row>
              <Column>
                <Row>
                  <Column>
                    <OrdersIssued />
                  </Column>
                </Row>
              </Column>
            </Row>
          </SectionBox>
        )}
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <OrdersReceived />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = connectOrderList(Orders)
export default styled(ConnectedComponent)`
  ${Tabs} {
    display: block;
    margin-bottom: 0;
    & > div {
      padding: 0.75rem 1.2rem 0 1.2rem;
    }
    & span {
      font-weight: 400;
    }
  }

  ${Alert} {
    width: auto;
    margin: 1rem 0 0 0;
    ${breakpoint.m`
    margin: initial;;
    width: inherit;
  `}
  }
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
