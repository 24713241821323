import React, { useContext, useEffect, useState } from 'react'
import { isNil } from 'ramda'
import styled from 'styled-components'

import Timeline from 'src/features/stocks/components/OfferDetails/Timeline'
import Contact from 'src/features/stocks/components/OfferDetails/Contact'
import { breakpoint } from 'src/theme/grid'

import {
  BottomButtons,
  ContainerInfo,
  ContractDates,
  CreatedDateOfferDetails,
  CustomsClearance,
  Notes,
  OfferBelongsToCurrentUserInfo,
  OfferSoldInfo,
  Type
} from 'src/features/stocks/components/OfferDetails/atoms'
import useUrlParams from 'src/hooks/useUrlParams'
import { compose } from 'recompose'
import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import {
  BidderTitle,
  Content,
  Drawer,
  FormActionBar,
  FormInnerWrapper,
  FormSection,
  Heading,
  HeadingContainer,
  OfferDetailsContainer,
  OfferLink,
  Subtitle
} from 'src/features/offers/components/atoms'
import { Padding } from 'src/features/myOffers/components/atoms'
import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import {
  createOfferDetailsLink,
  MENU_ITEMS
} from 'src/features/dashboard/duck/consts'
import { connectContracts } from 'src/features/contracts/duck/connectors'
import ContractPrice from 'src/components/atoms/ContractPrice'
import ContractIcon from 'src/assets/icons/description.svg'
import { TransportationTitle } from 'src/features/stocks/components/OfferDetails/OfferDetailsContract'
import { ButtonsForCustomerContract } from 'src/features/contracts/components/atoms'
import ReserveContract from 'src/features/contracts/components/ReserveContract'
import ReservationDetailsOffers from 'src/features/contracts/components/ReservationDetailsMyOffers'
import { Separator } from 'src/components/filters/components/atoms'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'

const OfferDetails = ({
  className,
  offerDetails,
  timelineData,
  checkIfTheOfferBelongsToTheCurrentCompany,
  checkIfTheOfferBelongsToTheCurrentUser,
  updateOffer,
  reserveOffer,
  stock,
  setConversationOpened,
  showOffer,
  setDrawerState,
  cleanContractDetails,
  buttonsState,
  acceptReservation,
  showFinishedContractReservations,
  showOngoingContractReservations
}) => {
  const params = useUrlParams()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToSeeCompanyConversations = hasPermissionTo(
    PERMISSIONS.CHAT_COMPANY_CONVERSATIONS_SEE,
    permissions
  )
  const offerRemoved = !isNil(offerDetails.deletedAt)
  const hasNotes = offerDetails.notes
  const offerBelongsToCurrentUserCompany = checkIfTheOfferBelongsToTheCurrentCompany(
    offerDetails.issuer.companyId
  )
  const offerBelongsToCurrentUser = checkIfTheOfferBelongsToTheCurrentUser(
    offerDetails.handler.id
  )
  const isFinished = offerDetails.finishedAt
  const hasReservations = offerDetails.reservationsCount > 0
  const chatAvailable =
    offerBelongsToCurrentUser || hasPermissionToSeeCompanyConversations
  const [bidDialog, setBidDialog] = useState(false)

  const changeHandler = newHandlerId => {
    updateOffer(offerDetails.id, { handler_id: newHandlerId })
  }

  const intl = useIntl()

  useEffect(() => {
    params.id && showOffer(params.id, { origin: 'details' })
    return () => {
      setDrawerState(false)
      cleanContractDetails()
    }
  }, [params.id])

  useEffect(() => {
    !isFinished
      ? hasReservations &&
        offerDetails.reservations.length === 0 &&
        offerBelongsToCurrentUserCompany &&
        showOngoingContractReservations(offerDetails.id)
      : hasReservations &&
        offerDetails.reservations.length === 0 &&
        offerBelongsToCurrentUserCompany &&
        showFinishedContractReservations(offerDetails.id)
  }, [
    hasReservations,
    offerDetails,
    offerBelongsToCurrentUserCompany,
    isFinished
  ])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              <FormattedMessage id='dashboard.contract' />
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Content>
        <FormSection>
          <FormInnerWrapper>
            <Padding>
              <BreadCrumbs
                links={[
                  {
                    name: intl.formatMessage({ id: 'forms.dashboard' }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                  },
                  {
                    name: intl.formatMessage({
                      id: 'dashboard.contractsTitle'
                    }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS}`
                  },
                  {
                    name: (
                      <FormattedMessage
                        id='offers.offerDetails'
                        values={{ id: offerDetails.referenceNumber }}
                      />
                    ),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`
                  }
                ]}
              />
              <OfferDetailsContainer>
                <HeadingContainer>
                  <Heading>
                    <FormattedMessage id='offers.offerDetailsTitle' />{' '}
                    <OfferLink
                      to={createOfferDetailsLink(offerDetails.referenceNumber)}
                    >
                      {offerDetails.referenceNumber}
                    </OfferLink>
                  </Heading>
                </HeadingContainer>
                {isFinished && <OfferSoldInfo status={offerDetails.outcome} />}
                {offerBelongsToCurrentUserCompany && (
                  <OfferBelongsToCurrentUserInfo />
                )}
                <div>
                  <ContractPrice
                    deadline={
                      offerDetails.finishedAt || offerDetails.validUntilDate
                    }
                    price={offerDetails.price}
                    belongsToCurrentCompany={offerBelongsToCurrentUserCompany}
                  />
                </div>
                <Type offerDetails={offerDetails} />
                <ContractDates
                  offerDetails={offerDetails}
                  icon={ContractIcon}
                />
                {offerDetails.transportations.map((transportation, index) => (
                  <React.Fragment key={transportation.id}>
                    <TransportationTitle>
                      {`${index + 1}. ${intl.formatMessage({
                        id: 'offerDetails.line'
                      })}`}
                      <span>{`x${transportation.numberOfContainers}`}</span>
                    </TransportationTitle>
                    {timelineData && <Timeline data={timelineData[index]} />}
                    {transportation.customsClearanceLocationId && (
                      <CustomsClearance offerDetails={transportation} />
                    )}
                    {transportation.containerId && (
                      <ContainerInfo offerDetails={transportation} />
                    )}
                  </React.Fragment>
                ))}
                {hasNotes && <Notes offerDetails={offerDetails} />}
                {offerBelongsToCurrentUserCompany &&
                  !offerRemoved &&
                  hasReservations && (
                    <div>
                      <Separator />
                      <Subtitle>
                        <FormattedMessage
                          id='offerDetails.reservations'
                          values={{
                            reservationsCount: offerDetails.reservationsCount
                          }}
                        />
                      </Subtitle>
                      {offerDetails.reservations.map((reservation, index) => (
                        <ReservationDetailsOffers
                          botttomOfferor
                          key={reservation.id}
                          offer={offerDetails}
                          reservation={reservation}
                          index={index}
                          isOpen={false}
                          finished={offerDetails.finishedAt}
                          chatAvailable={chatAvailable}
                          buttonsState={buttonsState}
                          acceptReservation={acceptReservation}
                          acceptReservationSuccess={() =>
                            showOffer(params.id, { origin: 'details' })
                          }
                          setConversationOpened={() => {
                            setConversationOpened({
                              receiverId: reservation.offeror.id,
                              conversationOpened: true
                            })
                          }}
                        />
                      ))}
                    </div>
                  )}
              </OfferDetailsContainer>
            </Padding>
          </FormInnerWrapper>
          {!offerBelongsToCurrentUserCompany && !offerRemoved && (
            <FormActionBar>
              <ButtonsForCustomerContract
                offerDetails={offerDetails}
                setBidDialog={setBidDialog}
                stock={stock}
                setConversationOpened={setConversationOpened}
              />
            </FormActionBar>
          )}
          <ReserveContract
            open={bidDialog}
            setOpen={setBidDialog}
            offer={offerDetails}
            onClick={price => reserveOffer(offerDetails.id, { price })}
          />
        </FormSection>
        <Drawer>
          {!offerRemoved && (
            <>
              <BidderTitle>
                <Heading>
                  <FormattedMessage id='offers.bidder' />
                </Heading>
              </BidderTitle>
              <Contact
                issuer={offerDetails.issuer}
                handler={offerDetails.handler}
                changeHandler={changeHandler}
                offerBelongsToCurrentUserCompany={
                  offerBelongsToCurrentUserCompany
                }
                offerBelongsToCurrentUser={offerBelongsToCurrentUser}
                isUpdatable={offerDetails.isUpdatable}
                handleConversationStart={() => {
                  setConversationOpened({
                    receiverId: offerDetails.handlerId,
                    conversationOpened: true
                  })
                }}
              />
              <CreatedDateOfferDetails offerDetails={offerDetails} />
            </>
          )}
        </Drawer>
      </Content>
    </Page>
  )
}

const StyledOfferDetails = styled(OfferDetails)`
  background-color: ${({ theme }) => theme.colors.white.hex()};
  ${FormActionBar} {
    position: fixed;
    bottom: 0;
    padding: 0;
    ${breakpoint.m`
    position: static;

   `}
  }
  ${BottomButtons} {
    justify-content: flex-end;
    button {
      max-width: 200px;
    }
  }
  ${Padding} {
    max-width: calc(626px + 2.8rem);
    margin: 0 auto;
  }

  ${Content} {
    flex-direction: column;
    ${breakpoint.m`
    flex-direction: row;
   `}
  }

  ${breakpoint.m`
    ${Content} {
      overflow-y: hidden;
    ${Drawer} {
      overflow-y: auto;
    }
  }
   `}
`

export default compose(
  connectContracts,
  connectMessagesBasicData
)(StyledOfferDetails)
