import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import React from 'react'
import MUIAddIcon from '@material-ui/icons/Add'

export const AddRecipientButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 6px;
`
export const AddIcon = styled(MUIAddIcon)`
  font-size: 24px;
`
export const DateInputContainer = styled.div`
  width: 100%;
  ${breakpoint.m`
  width: 160px;
  `}
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`
const SelectRowComponent = ({ name, taxId, onClick, className }) => (
  <div className={className} onClick={onClick}>
    <b>{name}</b>
    <p>NIP: {taxId}</p>
  </div>
)
export const SelectRow = styled(SelectRowComponent)`
  cursor: pointer;
  padding: 0.5rem 0;
  margin: 0 1rem;
  font-size: 0.875rem;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
  &:hover {
    background-color: rgba(36, 45, 63, 0.04);
    padding: 0.5rem 1rem;
    margin: 0;
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: rgba(36, 45, 63, 0.04);
      padding: 0.5rem 1rem;
      margin: 0;
    `}
`
export const Recipient = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  font-size: 0.875rem;
  line-height: 1.71;
  margin-top: 1rem;
`
export const RecipientContainer = styled.div`
  margin-bottom: 1.5rem;
`
export const SearchRecipientContainer = styled(Row)`
  margin-top: 0.5rem;
`
