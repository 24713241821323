import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl, navigate } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import { Row, SectionBox } from 'src/features/account/components/atoms'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import SearchInput from 'src/features/companies/components/SearchInput'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { API_STATES, MIN_TAXID_LENGTH } from 'src/ducks/consts'
import Shade from 'src/components/Shade'
import { SearchButton } from './components/SearchButton'
import { CompanyListItem } from './components/CompanyListItem'
import connect from './duck/connectors'

const CompaniesSearch = ({
  className,
  companiesSearchResult,
  searchCompany,
  clearCompanySearchResult,
  companiesState,
  getAllMarkedCompanies,
  allMarkedCompanies,
  addCompanyToWhitelist,
  companiesErrorMessage,
  currentCompanyId
}) => {
  const intl = useIntl()
  const [taxId, setTaxId] = useState('')

  useEffect(() => {
    getAllMarkedCompanies()
    return clearCompanySearchResult
  }, [])

  const onSearch = () => {
    searchCompany(taxId)
  }

  const handleKeypress = e => {
    if (e.charCode === 13) {
      onSearch()
    }
  }

  const onClear = () => {
    setTaxId('')
    clearCompanySearchResult()
  }

  const showMarkedCompanies =
    allMarkedCompanies.length > 0 &&
    !companiesSearchResult.id &&
    !companiesErrorMessage
  const showSearchResult =
    companiesSearchResult.id && companiesState !== API_STATES.PRISTINE
  const showNotFound = companiesErrorMessage === 'no-content'

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'companies.companiesDatabase'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Row>
        <SearchWrapper>
          <Title>
            <FormattedMessage id='companies.findCompany' />
          </Title>
          <Description>
            <FormattedMessage id='companies.companiesDatabaseDescription' />
          </Description>
          <Row>
            <SearchInput
              name='tax-id'
              placeholder={intl.formatMessage({
                id: 'companies.searchCompanyPlaceholder'
              })}
              value={taxId}
              onChange={setTaxId}
              onClear={onClear}
              onKeyDown={handleKeypress}
              validate={() => true}
            />
            <SearchButton
              onClick={onSearch}
              disabled={taxId.length < MIN_TAXID_LENGTH}
            >
              <FormattedMessage id='companies.search' />
            </SearchButton>
          </Row>
        </SearchWrapper>
      </Row>
      <SectionBox>
        {showSearchResult && (
          <>
            {companiesState === API_STATES.IN_PROGRESS && <Shade />}
            <ListTitle>
              <FormattedMessage id='companies.found' />
            </ListTitle>
            <CompanyListItem
              currentCompanyId={currentCompanyId}
              companyId={companiesSearchResult.id}
              isBlacklisted={companiesSearchResult.isBlacklisted}
              isWhitelisted={companiesSearchResult.isWhitelisted}
              name={companiesSearchResult.name}
              taxId={companiesSearchResult.taxId}
              address={companiesSearchResult.address}
              addToWhitelist={() =>
                addCompanyToWhitelist(companiesSearchResult.id)
              }
              onClick={() =>
                navigate(
                  `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.COMPANY}&companyId=${companiesSearchResult.id}`
                )
              }
            />
          </>
        )}
        {showMarkedCompanies && (
          <>
            {companiesState === API_STATES.IN_PROGRESS && <Shade />}
            <ListTitle>
              <FormattedMessage id='companies.marked' />
            </ListTitle>
            {allMarkedCompanies.map(company => (
              <CompanyListItem
                key={company.id}
                currentCompanyId={currentCompanyId}
                companyId={company.id}
                isBlacklisted={company.isBlacklisted}
                isWhitelisted={company.isWhitelisted}
                name={company.name}
                taxId={company.taxId}
                address={company.address}
                addToWhitelist={() => addCompanyToWhitelist(company.id)}
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.COMPANY}&companyId=${company.id}`
                  )
                }
              />
            ))}
          </>
        )}
        {showNotFound && (
          <NotFound>
            <FormattedMessage id='companies.notFound' />
          </NotFound>
        )}
      </SectionBox>
    </Page>
  )
}

const ConnectedComponent = connect(CompaniesSearch)
export default styled(ConnectedComponent)`
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  backdrop-filter: blur(5px);
  background-color: ${({ theme }) => theme.colors.lightGrey.hex()};
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
  padding: 1rem 10% 3rem;
  box-sizing: border-box;
  ${breakpoint.m`
        padding: 4rem 20% 3rem;
  `}
  & ${SearchInput} {
    margin-top: 0.5rem;
    ${breakpoint.m`
        margin-top: 0;
  `}
  }
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.78;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
`

const Description = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`

const ListTitle = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  margin-bottom: 0.8125rem;
  margin-left: 1.5rem;
  ${breakpoint.m`
          margin-left:0;
  `}
`
export const NotFound = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  opacity: 0.7;
  margin-top: 2rem;
`
