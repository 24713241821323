import React, { useEffect } from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import XIcon from 'src/assets/icons/close-x.svg'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Dialog from '@material-ui/core/Dialog'
import {
  INVOICE_NUMBERING_SCHEME,
  INVOICE_NUMBERING_SCHEME_OPTIONS
} from 'src/features/invoices/duck/consts'
import TextInput from 'src/components/atoms/TextInput'
import { SaveOfferButton } from 'src/components/atoms/Buttons'

export const InvoiceSettingsDialog = ({
  onClose,
  renderNumberingSchemeOption,
  open,
  onClick,
  paymentBankAccount,
  issuePlace,
  paymentBankName,
  numberingScheme,
  onChange,
  showMyCompanyInvoiceSettings
}) => {
  const intl = useIntl()

  useEffect(() => {
    showMyCompanyInvoiceSettings()
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 9 }
      }}
    >
      <DialogContent>
        <ModalCloseIcon onClick={onClose} />
        <DialogHeader>
          <FormattedMessage id='invoices.settingsTitle' />
        </DialogHeader>
        <DialogText>
          <FormattedMessage id='invoices.settingsDescription' />
        </DialogText>
        <InputTitle>
          <FormattedMessage id='invoices.numberingScheme' />
        </InputTitle>
        <RadioWrapper>
          {INVOICE_NUMBERING_SCHEME_OPTIONS.map(renderNumberingSchemeOption)}
        </RadioWrapper>
        <Description>
          {numberingScheme === INVOICE_NUMBERING_SCHEME.MONTHLY && (
            <FormattedMessage id='invoices.monthlyDescription' />
          )}
          {numberingScheme === INVOICE_NUMBERING_SCHEME.YEARLY && (
            <FormattedMessage id='invoices.yearlyDescription' />
          )}
        </Description>
        <InputsRow>
          <BankAccount>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'invoices.bankAccount'
              })}
              id='bankAccount'
              name='paymentBankAccount'
              placeholder=''
              value={paymentBankAccount}
              onChange={onChange}
            />
          </BankAccount>
          <BankName>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'invoices.bankName'
              })}
              id='bankName'
              name='paymentBankName'
              placeholder=''
              value={paymentBankName}
              onChange={onChange}
            />
          </BankName>
        </InputsRow>
        <InputsRow>
          <IssuePlace>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'invoices.issuePlace'
              })}
              id='issuePlace'
              name='issuePlace'
              placeholder=''
              value={issuePlace}
              onChange={onChange}
            />
          </IssuePlace>
          <BankName />
        </InputsRow>
        <ActionBar>
          <CancelButton onClick={onClose}>
            {intl.formatMessage({ id: 'myOffers.notNow' })}
          </CancelButton>
          <SaveOfferButton active onClick={onClick}>
            <FormattedMessage id='invoices.configData' />
          </SaveOfferButton>
        </ActionBar>
      </DialogContent>
    </Dialog>
  )
}
const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    padding: 1.6rem 2.2rem;
  `}
`
const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  margin: 0.8rem 0 1.2rem 0;
  width: 100%;
  display: inline-block;
`
const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
  margin-bottom: 1rem;
`
const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    margin-bottom: 0;
  `}
`
const CancelButton = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
  margin-right: 0.8rem;
  align-items: center;
  ${breakpoint.m`
    margin-right: 2.2rem;
  `}
`
const ModalCloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 24px;
  right: 12px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`
export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const InputsRow = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint.m`
    flex-wrap: wrap;
    flex-direction: row;
      & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
    `}
`
const Description = styled.div`
  opacity: 0.7;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-bottom: 1rem;
`
export const InputTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`
const BankAccount = styled.div`
  flex: 1;
`
const BankName = styled.div`
  flex: 0.6;
`
const IssuePlace = styled.div`
  flex: 1;
`
