import React from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import moment from 'moment'
import { breakpoint } from 'src/theme/grid'
import {
  AuctionPrice,
  AuctionPriceTitle,
  AuctionPriceValue,
  AuctionTrophyIcon
} from 'src/features/myOffers/components/atoms'
import { DateTime } from 'src/features/stocks/components/atoms'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import {
  DATE_LONG_FORMAT_WITH_WEEKDAY,
  DATE_SHORT_FORMAT_WITH_TIME
} from 'src/ducks/consts'
import { DateTitle, Description } from 'src/components/atoms/AuctionPrice'
import Offeror from 'src/features/myOffers/components/Offeror'
import ContractIcon from 'src/assets/icons/description.svg'
import { RejectOfferButton } from 'src/components/atoms/Buttons'

const ReservationDetails = props => {
  const {
    setConversationOpened,
    validUntil,
    offer,
    price,
    isCancellable,
    handleCancelReservation,
    chatAvailable,
    createdAt,
    acceptedAt,
    finishedAt
  } = props

  const endDate = finishedAt || validUntil

  return (
    <>
      <ContractOfferDetails isOpen>
        <ContractDetailsContainer>
          {acceptedAt ? <AuctionTrophyIcon /> : <ContractIconBig />}
          <AuctionPrice>
            <AuctionPriceTitle>
              <EllipsisTooltip>
                <FormattedMessage id='myOffers.yoursBid' />
              </EllipsisTooltip>
            </AuctionPriceTitle>
            <AuctionPriceValue>
              <EllipsisTooltip>
                {price.float}
                <span>{price.currency}</span>
              </EllipsisTooltip>
            </AuctionPriceValue>
            <DateTime>
              <EllipsisTooltip>
                {acceptedAt ? (
                  <FormattedMessage id='reservations.youWon' />
                ) : (
                  <FormattedMessage
                    id='myOffers.offeredAt'
                    values={{
                      date: moment(createdAt).format(
                        DATE_SHORT_FORMAT_WITH_TIME
                      )
                    }}
                  />
                )}
              </EllipsisTooltip>
            </DateTime>
          </AuctionPrice>
        </ContractDetailsContainer>
        <div>
          <DateTitle>
            <EllipsisTooltip>
              {moment(endDate).isAfter(moment()) && (
                <>
                  {moment(endDate).fromNow(true)}
                  <FormattedMessage id='offerDetails.toEnd' />
                </>
              )}
              {moment(endDate).isBefore(moment()) && (
                <FormattedMessage id='offerDetails.finished' />
              )}
            </EllipsisTooltip>
          </DateTitle>
          <Description>
            <EllipsisTooltip>
              ({moment(endDate).format(DATE_LONG_FORMAT_WITH_WEEKDAY)})
            </EllipsisTooltip>
          </Description>
        </div>
        <Offeror
          chatAvailable={chatAvailable}
          offeror={offer.handler}
          setConversationOpened={setConversationOpened}
        />
        {isCancellable && (
          <ContractDetailsContainer>
            <RejectOfferButton active onClick={handleCancelReservation}>
              <FormattedMessage id='common.cancel' />
            </RejectOfferButton>
          </ContractDetailsContainer>
        )}
      </ContractOfferDetails>
      <ContractOfferDetailsMobile isOpen>
        <div>
          <AuctionPriceMobile>
            <AuctionPriceTitle>
              <FormattedMessage id='myOffers.yoursBid' />
            </AuctionPriceTitle>
            <AuctionPriceValue>
              {price.float}
              <span>{price.currency}</span>
            </AuctionPriceValue>
            <Time>{moment(createdAt).format(DATE_SHORT_FORMAT_WITH_TIME)}</Time>
          </AuctionPriceMobile>
        </div>
        <div>
          <DateTitle>
            {moment(endDate).isAfter(moment()) && (
              <>
                {moment(endDate).fromNow(true)}
                <FormattedMessage id='offerDetails.toEnd' />
              </>
            )}
            {moment(endDate).isBefore(moment()) && (
              <FormattedMessage id='offerDetails.finished' />
            )}
          </DateTitle>
          <Description>
            ({moment(endDate).format(DATE_LONG_FORMAT_WITH_WEEKDAY)})
          </Description>
        </div>
        <Offeror
          chatAvailable={chatAvailable}
          offeror={offer.handler}
          setConversationOpened={setConversationOpened}
        />
        {isCancellable && (
          <div>
            <RejectOfferButton active onClick={handleCancelReservation}>
              <FormattedMessage id='common.cancel' />
            </RejectOfferButton>
          </div>
        )}
      </ContractOfferDetailsMobile>
    </>
  )
}

const ContractIconBig = styled(ContractIcon)`
  width: 40px;
  height: 40px;
  opacity: 0.15;
  margin-right: 1rem;
  flex-shrink: 0;
`

const ContractDetailsContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`
const ContractOfferDetails = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  display: none;
  align-items: center;
  grid-template-columns: 1fr 1fr 2fr min-content;
  grid-template-areas: 'price date offeror actions';
  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    &:first-of-type {
      padding-left: 10px;
    }
  }

  ${breakpoint.m`
    display: grid;
  `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 1;
      border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
      border-bottom: none;
      margin-left: -4px;
      position: relative;
      box-shadow: none;
      margin-bottom: 7px;
    `}
`

const ContractOfferDetailsMobile = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 1fr min-content;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    'price date actions'
    'offeror offeror offeror';
  > div {
    padding: 10px;
  }

  ${breakpoint.m`
    display: none;
  `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 1;
      border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
      border-bottom: none;
      margin-left: -4px;
      position: relative;
      box-shadow: none;
      margin-bottom: 7px;
    `}
`

const Time = styled.div`
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-size: 0.75rem;
  font-weight: 400;
`

export const AuctionPriceMobile = styled.div`
  grid-area: price;
  display: flex;
  flex-direction: column;
  position: relative;
  &::after {
    position: absolute;
    right: 0;
    content: '';
    border-right: 1px solid ${({ theme }) => theme.colors.iron.hex()};
    display: block;
    width: 1px;
    height: 80%;
    top: 10%;
  }
  ${breakpoint.m`
  &::after {
    content: none;
  }
  `}
`

export default ReservationDetails
