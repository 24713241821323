import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { compose } from 'recompose'

import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import PrivateRoute from 'src/components/PrivateRoute'
import initializeIntl from 'src/intl/initializeIntlWithMoment'
import routes from 'src/utils/routes'

import Dashboard from 'src/features/dashboard/Dashboard'
import NotificationsList from 'src/features/notifications/NotificationsList'
import { connectNotificationsBasics } from 'src/features/notifications/duck/connectors'
import Account from 'src/features/account/Account'

import MessagesList from 'src/features/messages/MessagesList'
import Conversation from 'src/features/messages/Conversation'
import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'

import { connectUserAccount } from 'src/features/account/duck/connectors'
import { connectBootstrap } from 'src/ducks/connectors'

import PermissionsProvider from 'src/utils/permissions'
import TermsOfServiceDialog from 'src/features/termsOfService/TermsOfServiceDialog'

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
// import { ReactQueryDevtools } from 'react-query-devtools'
export const queryClient = new QueryClient()

const AppPage = ({
  permissions,
  clearUserPermissions,
  clearConversation,
  bootstrapApplication,
  notificationsListOpened,
  listOpened,
  conversationOpened,
  currentUser
}) => {
  const intl = initializeIntl()
  useEffect(() => {
    bootstrapApplication()
    return () => {
      clearUserPermissions()
      clearConversation()
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <PermissionsProvider.Provider value={permissions}>
          <Layout isUserLoggedIn>
            <Router basepath={`${intl.locale}`}>
              <PrivateRoute path={routes.APP_DASHBOARD} component={Dashboard} />
              <PrivateRoute path={routes.APP_ACCOUNT} component={Account} />
            </Router>
            {currentUser.id && <TermsOfServiceDialog />}
            {notificationsListOpened && <NotificationsList />}
            {listOpened && <MessagesList />}
            {conversationOpened && <Conversation />}
          </Layout>
        </PermissionsProvider.Provider>
      </ThemeProvider>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  )
}

export default compose(
  connectUserAccount,
  connectBootstrap,
  connectNotificationsBasics,
  connectMessagesBasicData
)(AppPage)
