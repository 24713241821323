import React, { useRef, useEffect } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline
} from 'react-google-maps'
import { compose, withProps } from 'recompose'
import { isFalsy } from 'ramda-adjunct'
import { last } from 'ramda'

import withGoogleMapsHelpers from 'src/hocs/withGoogleMapsHelpers'
import MarkerCurrentPositionIcon from 'src/assets/marker-current-position.svg'
import MarkerStartPointIcon from 'src/assets/marker-start-point.svg'
import MarkerEndPointIcon from 'src/assets/marker-end-point.svg'

const GoogleMapsComponent = ({
  markers = [],
  originLocationLat,
  originLocationLong,
  destinationLocationLat,
  destinationLocationLong
}) => {
  const point = last(markers)
  const mapRef = useRef(null)

  //set map position and zoom
  useEffect(() => {
    const isMapDefined =
      mapRef && mapRef.current && window.google && window.google.maps
    const points = [
      { lat: originLocationLat, long: originLocationLong },
      { lat: destinationLocationLat, long: destinationLocationLong },
      point,
      ...markers
    ]
    if (isMapDefined) {
      const bounds = new window.google.maps.LatLngBounds()
      points.map(e => {
        if (!isFalsy(e) && e.lat !== 0 && e.long !== 0) {
          bounds.extend(new window.google.maps.LatLng(e.lat, e.long))
        }
      })
      mapRef.current.fitBounds(bounds)
    }
  }, [
    mapRef,
    point,
    originLocationLat,
    originLocationLong,
    destinationLocationLat,
    destinationLocationLong,
    markers
  ])
  return (
    <GoogleMap
      zoom={10}
      ref={mapRef}
      defaultZoom={10}
      defaultCenter={{
        lat: 54.517892,
        lng: 18.541038
      }}
    >
      <Polyline
        path={markers
          .filter(e => e.lat !== 0 && e.long !== 0)
          .map(e => ({ lat: e.lat, lng: e.long }))}
        geodesic={true}
        options={{
          strokeColor: '#ffffff',
          strokeOpacity: 1.0,
          strokeWeight: 20
        }}
      />
      <Polyline
        path={markers
          .filter(e => e.lat !== 0 && e.long !== 0)
          .map(e => ({ lat: e.lat, lng: e.long }))}
        geodesic={true}
        options={{
          strokeColor: '#13a2bf',
          strokeOpacity: 1.0,
          strokeWeight: 9
        }}
      />
      {point && point.lat !== 0 && point.long !== 0 && (
        <Marker
          icon={{ url: MarkerCurrentPositionIcon }}
          position={{
            lat: parseFloat(point.lat),
            lng: parseFloat(point.long)
          }}
        />
      )}{' '}
      {originLocationLat && originLocationLong && (
        <Marker
          icon={{ url: MarkerStartPointIcon }}
          position={{
            lat: parseFloat(originLocationLat),
            lng: parseFloat(originLocationLong)
          }}
        />
      )}
      {destinationLocationLat && destinationLocationLong && (
        <Marker
          icon={{ url: MarkerEndPointIcon }}
          position={{
            lat: parseFloat(destinationLocationLat),
            lng: parseFloat(destinationLocationLong)
          }}
        />
      )}
    </GoogleMap>
  )
}
export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=geometry,places,drawing`,
    loadingElement: <div style={{ height: '400px', width: '100%' }} />,
    containerElement: <div style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '400px' }} />
  }),
  withScriptjs,
  withGoogleMap,
  withGoogleMapsHelpers
)(GoogleMapsComponent)
