export const MY_RESERVATIONS_HEADERS = {
  EXPORT_ONGOING: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.loading', sort: null },
    { name: 'stocksHeaders.return', sort: null },
    { name: 'stocksHeaders.container', sort: null },
    { name: 'stocksHeaders.shipper', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true },
    { name: 'reservations.queue', sort: null }
  ],
  EXPORT_FINISHED: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.loading', sort: null },
    { name: 'stocksHeaders.return', sort: null },
    { name: 'stocksHeaders.container', sort: null },
    { name: 'stocksHeaders.shipper', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true }
  ],
  IMPORT_ONGOING: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.pickup', sort: null },
    { name: 'stocksHeaders.unloading', sort: null },
    { name: 'stocksHeaders.container', sort: null },
    { name: 'stocksHeaders.shipper', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true },
    { name: 'reservations.queue', sort: null }
  ],
  IMPORT_FINISHED: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.pickup', sort: null },
    { name: 'stocksHeaders.unloading', sort: null },
    { name: 'stocksHeaders.container', sort: null },
    { name: 'stocksHeaders.shipper', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true }
  ],
  FREE_CARRIER_ONGOING: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.availability', sort: null },
    { name: 'stocksHeaders.destination', sort: null },
    { name: 'stocksHeaders.trailer', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true },
    { name: 'reservations.queue', sort: null }
  ],
  FREE_CARRIER_FINISHED: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.availability', sort: null },
    { name: 'stocksHeaders.destination', sort: null },
    { name: 'stocksHeaders.trailer', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true }
  ],
  POST_IMPORT_ONGOING: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.availability', sort: null },
    { name: 'stocksHeaders.destination', sort: null },
    { name: 'stocksHeaders.container', sort: null },
    { name: 'stocksHeaders.shipper', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true },
    { name: 'reservations.queue', sort: null }
  ],
  POST_IMPORT_FINISHED: [
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'emptyString',
      sort: null
    },
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    { name: 'stocksHeaders.availability', sort: null },
    { name: 'stocksHeaders.destination', sort: null },
    { name: 'stocksHeaders.container', sort: null },
    { name: 'stocksHeaders.shipper', sort: null },
    { name: 'stocksHeaders.company', sort: null },
    { name: 'reservations.reservation', sort: true }
  ]
}
