import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { IntlContextConsumer, FormattedMessage } from 'gatsby-plugin-intl'
import { Bold, Description } from 'src/features/stocks/components/typography'
import DownIcon from 'src/assets/icons/down.svg'
import Flag from 'src/components/atoms/Flag'
import useClickOutside from 'src/hooks/useClickOutside'
import { connectEditCurrentUserForm } from 'src/features/account/duck/connectors'
import { Avatar } from 'src/components/atoms/Avatar'

const languageName = {
  en: 'English',
  pl: 'Polski',
  de: 'Deutsch'
}

export const Language = ({ className, updateCurrentUserLanguage, value }) => {
  const [isListOpen, setListState] = useState(false)
  const componentRef = useClickOutside(() => setListState(false))

  const handleClick = () => {
    setListState(!isListOpen)
  }

  const handleChoose = lang => {
    updateCurrentUserLanguage({ lang })
    setListState(false)
  }
  return (
    <div className={className} ref={componentRef}>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => {
          const currentValue = value || currentLocale
          return (
            <div>
              <Select
                isListOpen={isListOpen}
                className={className}
                onClick={handleClick}
              >
                <Flag name={currentValue} />
                <Bold>{languageName[currentValue]}</Bold>
                <IconContainer>
                  <DownIcon opacity={0.7} />
                </IconContainer>
              </Select>
              {isListOpen && (
                <List>
                  <ul>
                    {languages.map(lang => (
                      <Item
                        as='li'
                        key={lang}
                        onClick={() => handleChoose(lang)}
                        disabled={lang === currentValue}
                      >
                        <ItemContent>
                          <Flag name={lang} />
                          {languageName[lang]}
                          <span>
                            <FormattedMessage id='offerDetails.choose' />
                          </span>
                        </ItemContent>
                      </Item>
                    ))}
                  </ul>
                </List>
              )}
            </div>
          )
        }}
      </IntlContextConsumer>
    </div>
  )
}

const StyledLanguage = styled(Language)`
  position: relative;
`
export default connectEditCurrentUserForm(StyledLanguage)

const Select = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 28px 0.5rem 24px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.colors.ghost.hex()};
  background-color: ${({ theme }) => theme.colors.white.hex()};
  ${({ isListOpen }) =>
    isListOpen &&
    css`
      box-shadow: 0 0 10px 0
        ${({ theme }) => theme.colors.apple.alpha(0.24).hex()};
      border: solid 1px ${({ theme }) => theme.colors.apple.hex()};
    `}
`

const IconContainer = styled.div`
  margin-left: auto;
  display: block;
`

const List = styled.div`
  position: absolute;
  width: 100%;
  margin-top: 4px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.colors.ghost.alpha(0.6).hex()};
  box-shadow: 0 12px 32px -8px ${({ theme }) => theme.colors.ebonyClay.alpha(0.03).hex()};
  background-color: ${({ theme }) => theme.colors.white.hex()};
  max-height: 200px;
  overflow-y: scroll;
  z-index: 22;
`
const ItemContent = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  padding: 6px 0;
  margin-left: 1.5rem;
  margin-right: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  & span {
    text-transform: uppercase;
    margin-left: auto;
    color: ${({ theme }) => theme.colors.white.hex()};
  }
  ${Avatar} {
    margin-right: 8px;
  }
`

const Item = styled(Description)`
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.03).hex()};
    ${ItemContent} span {
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
    }
  }
  &:last-child ${ItemContent} {
    border: none;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) =>
        theme.colors.ebonyClay.alpha(0.03).hex()};
      ${ItemContent} span {
        display: none;
      }
    `}
`
