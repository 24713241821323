import React from 'react'
import styled, { css } from 'styled-components'
import { isNil } from 'ramda'
import ExampleUserAvatarUrl from 'src/assets/avatar-placeholder.svg'
import MUIDone from '@material-ui/icons/Done'
import { breakpoint } from 'src/theme/grid'
import OfferInfoBox from './OfferInfoBox'

const LastMessage = ({
  className,
  message,
  onClick = () => {},
  interlocutorOnline
}) => {
  const messageUnseen = message.isInterlocutorMessage && !message.messageSeen
  const haveInterlocutorSeenMyMessage =
    !isNil(message.messageSeenAt) && !message.isInterlocutorMessage

  return (
    <div className={className} onClick={onClick}>
      <InformationGroup>
        <Avatar url={message.avatar} online={interlocutorOnline} />
        <Column>
          <Row>
            <PersonName>{message.userName}</PersonName>
            <CompanyName>{message.companyName}</CompanyName>
          </Row>
          <Row>
            <TextMessage>{message.text}</TextMessage>
            <Time>{message.createdAt}</Time>
          </Row>
        </Column>
        <IconWrapper>
          {messageUnseen && <UnseenDot />}
          {haveInterlocutorSeenMyMessage && <DoneIcon />}
        </IconWrapper>
      </InformationGroup>
      {!isNil(message.context) && <OfferInfoBox context={message.context} />}
    </div>
  )
}

export default styled(LastMessage)`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0.6rem 0;
  cursor: pointer;
  &:after {
    content: '';
    width: 85%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ messageSeen }) =>
    !messageSeen &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.easternBlue.alpha(0.05).hex()};
      ${TextMessage} {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.easternBlue.hex()};
      }
    `}
`

const InformationGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
`

const Avatar = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: url(${({ url }) => url || ExampleUserAvatarUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 7px 0 9px;
  ${breakpoint.xxs`
    margin: 0 14px 0 18px;
  `}
  position: relative;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: -2px;
    bottom: 1px;
  }
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme, online }) =>
      online ? theme.colors.apple.hex() : theme.colors.geyser.hex()};
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 3px;
  }
`
const PersonName = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 0.88rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.6rem;
`

const CompanyName = styled.span`
  display: inline-block;
  line-height: 1.42;
  font-size: 0.73rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  max-width: 95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TextMessage = styled.span`
  display: inline-block;
  line-height: 1.42;
  max-width: 230px;
  font-size: 0.72rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 400;
  overflow-wrap: break-word;
  word-wrap: break-word;
`

const Time = styled.span`
  display: inline-block;
  line-height: 1.42;
  font-size: 0.72rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 400;
  vertical-align: middle;
  &:before {
    content: '';
    width: 2px;
    height: 2px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    line-height: 1.42;
    vertical-align: middle;
  }
`

const DoneIcon = styled(MUIDone)`
  font-size: 1.2rem !important;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const IconWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
`

const UnseenDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
  border-radius: 50%;
  position: relative;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) =>
      theme.colors.easternBlue.alpha(0.15).hex()};
    border-radius: 50%;
    position: absolute;
    top: -2px;
    left: -2px;
  }
`
