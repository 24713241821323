import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Tabs = ({ className, list = [], children }) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <>
      <div className={className}>
        <TabsHeaderWrapper>
          {list.map((tab, index) => (
            <TabHeader
              key={index}
              active={index === activeTab}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </TabHeader>
          ))}
        </TabsHeaderWrapper>
      </div>
      {React.Children.toArray(children)[activeTab]}
    </>
  )
}

export default styled(Tabs)`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
`

const TabsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  border-bottom: solid 2px rgba(50, 54, 58, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  white-space: nowrap;
`
const TabHeader = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.45rem 1.6rem;
  padding-top: 0;
  line-height: 1.7;
  flex: 1;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black.alpha(0.6).hex()};
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: calc(100% - 20px);
    height: 4px;
    background-color: transparent;
    transition: background-color 0.3s;
    position: absolute;
    bottom: -2px;
    left: 10px;
  }
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.black.alpha(0.87).hex()};
      &:after {
        background-color: ${({ theme }) => theme.colors.apple.hex()};
      }
    `}
`

export const TabContent = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 1.2rem;
  padding-bottom: 0.8rem;
  z-index: 101;
`
