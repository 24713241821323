import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useIntl, Link, FormattedHTMLMessage } from 'gatsby-plugin-intl'
import moment from 'moment'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { compose } from 'recompose'
import { breakpoint } from 'src/theme/grid'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { scrollToRef } from 'src/utils/scroll'
import { Page } from 'src/components/Wrappers'
import BreadCrumbs from 'src/components/BreadCrumbs'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import LocationInput from 'src/components/filters/LocationInput'
import routes from 'src/utils/routes'
import { connectDictionaries } from 'src/ducks/connectors'
import Checkbox from 'src/components/atoms/CheckboxInput'
import Select from 'src/components/filters/SelectInput'
import Alert from 'src/components/Alert'
import TextInput from 'src/components/atoms/TextInput'
import TextInputMasked from 'src/components/atoms/TextInputMasked'
import DatePicker from 'src/components/atoms/DatePicker'
import { BOOLEAN, LOCATION_TYPES, API_STATES } from 'src/ducks/consts'
import { EURO_OPTIONS } from 'src/features/stocks/ducks/consts'
import {
  OFFER_TYPES,
  OFFER_TYPES_OPTIONS,
  OFFER_VISIBILITY_TYPES,
  OFFER_VISIBILITY_TYPES_OPTIONS
} from 'src/features/offers/duck/consts'

import {
  AuctionNotesWrapper,
  AuctionTimeInputWrapper,
  RadioWrapper,
  WarningWrapper
} from 'src/features/offers/components/atoms'
import IconWithTooltip from 'src/components/IconWithTooltip'
import CreateOfferMenu from './components/CreateOfferMenu'
import {
  Content,
  FormSection,
  FormInnerWrapper,
  FormSectionSpacer,
  FormActionBar,
  InputsRowWrapper,
  InputWrapper,
  FormSubsectionSpacer,
  PlaceInputWrapper,
  DateInputWrapper,
  TimeInputWrapper,
  DictionaryInputWrapper,
  WeightInputWrapper,
  PriceInputWrapper,
  CargoTypeInputWrapper,
  Padding,
  TemplateNameInputWrapper,
  TemplatesButton,
  ActionGroup,
  HideOnMobile
} from './components/atoms'
import CheckboxSection from './components/CheckboxSection'
import Drafts from './components/Drafts'
import { FormNavigationHeader } from './components/typography'
import connect from './duck/connectors'
import { validateOfferExport, validateOfferExportSchema } from './duck/schema'
import NewForeignLocationsAddedAlert from 'src/features/offers/components/NewForeignLocationsAddedAlert'
import PermissionsContext, {
  PERMISSIONS,
  hasPermissionTo
} from 'src/utils/permissions'
import PrivateOfferCreateOptions from 'src/features/offers/components/PrivateOfferCreateOptions'
import { EXCHANGE_API_URL_PREFIXES } from 'src/ducks/consts'

const OfferFormExport = ({
  className,
  offerFormExport,
  setOfferFormExportValue,
  clearExportOfferForm,
  fetchLocations,
  loadingDictionary,
  returnDictionary,
  dictionaries,
  destinationDictionary,
  getExportMyCompanyLastOffers,
  getExportOfferTemplates,
  lastOffersExport,
  offerTemplatesExport,
  fillFormUsingTemplateExport,
  removeTemplateExport,

  createExportOffer,
  createBulkExportOffers,
  createAndDuplicateExportOffer,
  createAndStartNewExportOffer
}) => {
  const intl = useIntl()
  const templateRef = useRef(null)
  const formContentRef = useRef(null)
  const permissions = useContext(PermissionsContext)
  const [visibilityType, setVisibilityType] = useState(
    OFFER_VISIBILITY_TYPES.PUBLIC
  )
  const [goPublicAtDateTime, setGoPublicAtDateTime] = useState(null)

  const [templatesOpened, setTemplatesOpened] = useState(false)
  const valid = useValidateSchema(
    offerFormExport.toJS(),
    validateOfferExportSchema
  )

  const handleGoPublicAtChange = value => {
    setGoPublicAtDateTime(value)
    setOfferFormExportValue('goPublicAt', value)
  }

  useEffect(() => {
    getExportMyCompanyLastOffers()
    getExportOfferTemplates()
    return clearExportOfferForm
  }, [])

  const isAuction = offerFormExport.type === OFFER_TYPES.AUCTION
  const handleVisibilityType = (name, value) => {
    setVisibilityType(value)
    // setOfferFormExportValue(name, value)
  }

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <FormNavigationHeader>
              {intl.formatMessage({ id: 'forms.exportFormTitle' })}
            </FormNavigationHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Content>
        <FormSection>
          <FormInnerWrapper ref={formContentRef}>
            <Padding>
              <BreadCrumbs
                links={[
                  {
                    name: intl.formatMessage({ id: 'forms.dashboard' }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                  },
                  {
                    name: intl.formatMessage({
                      id: 'forms.exchangeExportList'
                    }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_EXPORT}`
                  },
                  {
                    name: intl.formatMessage({ id: 'forms.addOffer' }),
                    url: null
                  }
                ]}
              />
              <TemplatesButton onClick={() => setTemplatesOpened(true)}>
                {intl.formatMessage({ id: 'forms.copyOrUseTemplate' })}
              </TemplatesButton>

              <NewForeignLocationsAddedAlert />
              <FormSectionSpacer>
                <RadioWrapper>
                  <div>{intl.formatMessage({ id: 'forms.offerType' })}</div>
                  {OFFER_TYPES_OPTIONS.map(option => ({
                    ...option,
                    text: intl.formatMessage({ id: option.text })
                  })).map(opt => (
                    <Checkbox
                      key={opt.value}
                      name='type'
                      value={offerFormExport.type}
                      onChange={setOfferFormExportValue}
                      type='radio'
                      option={opt}
                    />
                  ))}
                </RadioWrapper>
              </FormSectionSpacer>
              {isAuction && (
                <>
                  <WarningWrapper>
                    <Alert
                      message={intl.formatMessage({
                        id: 'forms.auctionWarning'
                      })}
                      type='info'
                    />
                  </WarningWrapper>
                  <InputsRowWrapper>
                    <DateInputWrapper>
                      <DatePicker
                        label={intl.formatMessage({
                          id: 'forms.auctionEndDate'
                        })}
                        disablePast
                        required
                        name='auctionDeadlineDate'
                        placeholder='dd.mm.rrrr'
                        value={offerFormExport.auctionDeadlineDate}
                        onChange={setOfferFormExportValue}
                        maxDate={moment().add(6, 'days')}
                      />
                    </DateInputWrapper>
                    <AuctionTimeInputWrapper>
                      <TextInputMasked
                        mask='99:99'
                        label={intl.formatMessage({
                          id: 'forms.auctionEndTime'
                        })}
                        required
                        id='auctionDeadlineTime'
                        name='auctionDeadlineTime'
                        placeholder='gg:mm'
                        value={offerFormExport.auctionDeadlineTime}
                        onChange={setOfferFormExportValue}
                        validate={validateOfferExport}
                        type='tel'
                        calendarTime
                      />
                    </AuctionTimeInputWrapper>
                    <PriceInputWrapper>
                      <TextInput
                        label={intl.formatMessage({
                          id: 'forms.auctionBasePrice'
                        })}
                        id='price'
                        name='price'
                        required
                        placeholder=''
                        value={offerFormExport.price}
                        onChange={setOfferFormExportValue}
                        validate={validateOfferExport}
                        type='currency'
                        onCurrencyChange={setOfferFormExportValue}
                        currencyValue={offerFormExport.currency}
                      />
                    </PriceInputWrapper>
                    <Checkbox
                      name='isPriceVisible'
                      value={offerFormExport.isPriceVisible}
                      option={{
                        value: BOOLEAN.YES,
                        text: intl.formatMessage({
                          id: 'forms.isPriceVisible'
                        })
                      }}
                      onChange={name => {
                        setOfferFormExportValue(
                          name,
                          !offerFormExport.isPriceVisible
                        )
                      }}
                    />
                    <AuctionNotesWrapper>
                      <TextInput
                        label={intl.formatMessage({
                          id: 'forms.auctionNotes'
                        })}
                        id='notes'
                        name='notes'
                        multiline
                        rows={3}
                        placeholder={intl.formatMessage({
                          id: 'forms.auctionNotesPlaceholder'
                        })}
                        value={offerFormExport.notes}
                        onChange={setOfferFormExportValue}
                        validate={validateOfferExport}
                      />
                    </AuctionNotesWrapper>
                  </InputsRowWrapper>
                </>
              )}
              {hasPermissionTo(
                PERMISSIONS.STOCK_PRIVATE_ADD_OFFER,
                permissions
              ) && (
                <FormSectionSpacer>
                  <RadioWrapper>
                    <div>
                      {intl.formatMessage({ id: 'offersFilters.visibility' })}
                    </div>
                    {OFFER_VISIBILITY_TYPES_OPTIONS.map(option => ({
                      ...option,
                      text: intl.formatMessage({ id: option.text })
                    })).map(opt => (
                      <Checkbox
                        key={opt.value}
                        name='visibility'
                        value={offerFormExport.visibility}
                        onChange={setOfferFormExportValue}
                        type='radio'
                        option={opt}
                      />
                    ))}
                  </RadioWrapper>
                </FormSectionSpacer>
              )}
              {/* {JSON.stringify(offerFormExport)} */}
              {offerFormExport.visibility == OFFER_VISIBILITY_TYPES.PRIVATE && (
                <PrivateOfferCreateOptions
                  exchangeType={EXCHANGE_API_URL_PREFIXES.EXPORT}
                  onGoPublicDateTimeChange={setOfferFormExportValue}
                />
              )}

              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.tracking' })}
                <IconWithTooltip
                  openTime={3000}
                  title={intl.formatMessage({
                    id: 'forms.trackingHelperForwarder'
                  })}
                />
              </FormSectionSpacer>
              <Checkbox
                name='doesRequireTracking'
                value={offerFormExport.doesRequireTracking}
                option={{
                  value: BOOLEAN.YES,
                  text: intl.formatMessage({
                    id: 'forms.doesRequireTrackingForwarder'
                  })
                }}
                onChange={name => {
                  setOfferFormExportValue(
                    name,
                    !offerFormExport.doesRequireTracking
                  )
                }}
              />
              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.loadingSectionName' })}
              </FormSectionSpacer>
              <InputsRowWrapper>
                <PlaceInputWrapper>
                  <LocationInput
                    required
                    id='loadingLocationId'
                    label={intl.formatMessage({
                      id: 'forms.loadingPlaceInputLabel'
                    })}
                    locationName='loadingLocationId'
                    locationValue={offerFormExport.loadingLocationId}
                    locationPlaceholder={intl.formatMessage({
                      id: 'forms.loadingPlaceInputLabel'
                    })}
                    onChange={(name, value) =>
                      setOfferFormExportValue(name, value)
                    }
                    placeholder={intl.formatMessage({
                      id: 'forms.locationPlaceholder'
                    })}
                    locationTypes={LOCATION_TYPES.CITY}
                    dictionary={loadingDictionary}
                    fetchLocations={fetchLocations}
                    searchHintText={intl.formatMessage({
                      id: 'offersFilters.searchHintText'
                    })}
                  />
                </PlaceInputWrapper>
                <DateInputWrapper>
                  <DatePicker
                    label={intl.formatMessage({
                      id: 'forms.loadingDateInputLabel'
                    })}
                    disablePast
                    required
                    name='loadingDate'
                    placeholder='dd.mm.rrrr'
                    value={offerFormExport.loadingDate}
                    onChange={setOfferFormExportValue}
                    maxDate={offerFormExport.returnDate}
                    minDate={offerFormExport.auctionDeadlineDate}
                  />
                </DateInputWrapper>
                <TimeInputWrapper>
                  <TextInputMasked
                    mask='99:99'
                    label={intl.formatMessage({
                      id: 'forms.loadingTimeInputLabel'
                    })}
                    id='loadingTime'
                    name='loadingTime'
                    placeholder='gg:mm'
                    value={offerFormExport.loadingTime}
                    onChange={setOfferFormExportValue}
                    validate={validateOfferExport}
                    type='tel'
                    calendarTime
                  />
                </TimeInputWrapper>
              </InputsRowWrapper>
              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.returnSectionName' })}
              </FormSectionSpacer>
              <InputsRowWrapper>
                <PlaceInputWrapper>
                  <LocationInput
                    required
                    id='returnLocationId'
                    label={intl.formatMessage({
                      id: 'forms.returnPlaceInputLabel'
                    })}
                    locationName='returnLocationId'
                    locationValue={offerFormExport.returnLocationId}
                    locationPlaceholder={intl.formatMessage({
                      id: 'forms.returnPlaceInputLabel'
                    })}
                    onChange={(name, value) =>
                      setOfferFormExportValue(name, value)
                    }
                    placeholder={intl.formatMessage({
                      id: 'forms.locationPlaceholder'
                    })}
                    locationTypes={LOCATION_TYPES.TERMINAL}
                    dictionary={returnDictionary}
                    fetchLocations={fetchLocations}
                    searchHintText={intl.formatMessage({
                      id: 'offersFilters.searchHintText'
                    })}
                  />
                </PlaceInputWrapper>
                <DateInputWrapper>
                  <DatePicker
                    label={intl.formatMessage({
                      id: 'forms.returnDateInputLabel'
                    })}
                    disablePast
                    required
                    name='returnDate'
                    placeholder='dd.mm.rrrr'
                    value={offerFormExport.returnDate}
                    onChange={setOfferFormExportValue}
                    minDate={offerFormExport.loadingDate}
                  />
                </DateInputWrapper>
                <TimeInputWrapper>
                  <TextInputMasked
                    mask='99:99'
                    label={intl.formatMessage({
                      id: 'forms.returnTimeInputLabel'
                    })}
                    id='returnTime'
                    name='returnTime'
                    placeholder='gg:mm'
                    value={offerFormExport.returnTime}
                    onChange={setOfferFormExportValue}
                    validate={validateOfferExport}
                    type='tel'
                    calendarTime
                  />
                </TimeInputWrapper>
              </InputsRowWrapper>
              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.cargoSectionName' })}
              </FormSectionSpacer>
              <InputsRowWrapper>
                <DictionaryInputWrapper>
                  <Select
                    required
                    id='containerId'
                    name='containerId'
                    label={intl.formatMessage({ id: 'forms.container' })}
                    value={offerFormExport.containerId}
                    options={dictionaries.containers}
                    onChange={setOfferFormExportValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                  />
                </DictionaryInputWrapper>
                <DictionaryInputWrapper>
                  <Select
                    required
                    id='shipownerId'
                    name='shipownerId'
                    label={intl.formatMessage({ id: 'forms.shipowner' })}
                    value={offerFormExport.shipownerId}
                    options={dictionaries.shipowners}
                    onChange={setOfferFormExportValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                  />
                </DictionaryInputWrapper>
              </InputsRowWrapper>
              <InputsRowWrapper>
                <DictionaryInputWrapper>
                  <Select
                    id='adrClassId'
                    name='adrClassId'
                    label={intl.formatMessage({ id: 'forms.adrClass' })}
                    value={offerFormExport.adrClassId}
                    options={dictionaries.adrClasses}
                    onChange={setOfferFormExportValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                    width='380px'
                  />
                </DictionaryInputWrapper>
                <DictionaryInputWrapper>
                  <Select
                    id='europeanEmissionStandard'
                    name='europeanEmissionStandard'
                    label={intl.formatMessage({
                      id: 'forms.europeanEmissionStandard'
                    })}
                    value={offerFormExport.europeanEmissionStandard}
                    options={EURO_OPTIONS.map(option => ({
                      ...option,
                      label: intl.formatMessage({ id: option.label })
                    }))}
                    onChange={setOfferFormExportValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                    width='230px'
                  />
                </DictionaryInputWrapper>
              </InputsRowWrapper>
              <FormSubsectionSpacer>
                {intl.formatMessage({ id: 'forms.additionalCargoInformation' })}
              </FormSubsectionSpacer>
              <InputsRowWrapper>
                <WeightInputWrapper>
                  <TextInput
                    label={intl.formatMessage({ id: 'forms.weight' })}
                    id='weight'
                    name='weight'
                    placeholder={intl.formatMessage({ id: 'forms.weight' })}
                    value={offerFormExport.weight}
                    onChange={setOfferFormExportValue}
                    validate={validateOfferExport}
                    type='unit'
                    unit='kg'
                  />
                </WeightInputWrapper>
                {!isAuction && (
                  <PriceInputWrapper>
                    <TextInput
                      label={intl.formatMessage({ id: 'forms.price' })}
                      id='price'
                      name='price'
                      placeholder={intl.formatMessage({ id: 'forms.price' })}
                      value={offerFormExport.price}
                      onChange={setOfferFormExportValue}
                      validate={validateOfferExport}
                      type='currency'
                      onCurrencyChange={setOfferFormExportValue}
                      currencyValue={offerFormExport.currency}
                    />
                  </PriceInputWrapper>
                )}
                <CargoTypeInputWrapper>
                  <Select
                    id='cargoTypeId'
                    name='cargoTypeId'
                    label={intl.formatMessage({ id: 'forms.cargoType' })}
                    value={offerFormExport.cargoTypeId}
                    options={dictionaries.cargoTypes}
                    onChange={setOfferFormExportValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                  />
                </CargoTypeInputWrapper>
              </InputsRowWrapper>
            </Padding>
            <CheckboxSection
              opened={offerFormExport.customsClearanceLocationIdOpened}
              setOpened={opened =>
                setOfferFormExportValue(
                  'customsClearanceLocationIdOpened',
                  opened
                )
              }
              label={intl.formatMessage({
                id: 'forms.customsClearance'
              })}
              handleClose={() =>
                setOfferFormExportValue('customsClearanceLocationId', null)
              }
            >
              <PlaceInputWrapper>
                <LocationInput
                  required
                  id='customsClearanceLocationId'
                  label={intl.formatMessage({
                    id: 'forms.customsClearanceLocationLabel'
                  })}
                  locationName='customsClearanceLocationId'
                  locationValue={offerFormExport.customsClearanceLocationId}
                  locationPlaceholder={intl.formatMessage({
                    id: 'forms.customsClearanceLocationLabel'
                  })}
                  onChange={(name, value) =>
                    setOfferFormExportValue(name, value)
                  }
                  placeholder={intl.formatMessage({
                    id: 'forms.locationPlaceholder'
                  })}
                  locationTypes={LOCATION_TYPES.CITY}
                  dictionary={destinationDictionary}
                  fetchLocations={fetchLocations}
                  searchHintText={intl.formatMessage({
                    id: 'offersFilters.searchHintText'
                  })}
                />
              </PlaceInputWrapper>
            </CheckboxSection>
            {!isAuction && (
              <Padding>
                <FormSectionSpacer>
                  {intl.formatMessage({ id: 'forms.additionalInformation' })}
                </FormSectionSpacer>
                <InputsRowWrapper>
                  <InputWrapper>
                    <TextInput
                      label={intl.formatMessage({
                        id: 'forms.additionalInformation'
                      })}
                      id='notes'
                      name='notes'
                      multiline
                      rows={3}
                      placeholder={intl.formatMessage({
                        id: 'forms.additionalInformationPlaceholder'
                      })}
                      value={offerFormExport.notes}
                      onChange={setOfferFormExportValue}
                      validate={validateOfferExport}
                    />
                  </InputWrapper>
                </InputsRowWrapper>
                <FormSectionSpacer />
              </Padding>
            )}
            <CheckboxSectionStyled
              ref={templateRef}
              opened={offerFormExport.templateNameOpened}
              setOpened={opened =>
                setOfferFormExportValue('templateNameOpened', opened)
              }
              label={intl.formatMessage({
                id: 'forms.templateName'
              })}
              handleClose={() => setOfferFormExportValue('templateName', null)}
            >
              <TemplateNameInputWrapper>
                <TextInput
                  required
                  label={intl.formatMessage({
                    id: 'forms.templateNameLabel'
                  })}
                  id='templateName'
                  name='templateName'
                  placeholder={intl.formatMessage({
                    id: 'forms.templateNamePlaceholder'
                  })}
                  value={offerFormExport.templateName}
                  onChange={setOfferFormExportValue}
                  validate={validateOfferExport}
                />
              </TemplateNameInputWrapper>
            </CheckboxSectionStyled>
          </FormInnerWrapper>
          <FormActionBar>
            <Link
              to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_EXPORT}`}
            >
              {intl.formatMessage({ id: 'forms.cancel' })}
            </Link>
            <ActionGroup>
              <HideOnMobile>
                <Checkbox
                  name='templateNameOpened'
                  value={offerFormExport.templateNameOpened}
                  option={{
                    value: BOOLEAN.YES,
                    text: intl.formatMessage({
                      id: 'forms.templateName'
                    })
                  }}
                  onChange={name => {
                    setOfferFormExportValue(
                      name,
                      !offerFormExport.templateNameOpened
                    )
                    scrollToRef(templateRef, formContentRef)
                  }}
                />
              </HideOnMobile>
              <CreateOfferMenu
                isLoading={offerFormExport.state === API_STATES.IN_PROGRESS}
                active={
                  valid && offerFormExport.state !== API_STATES.IN_PROGRESS
                }
                createOffer={createExportOffer}
                createBulkOffers={createBulkExportOffers}
                createAndDuplicateOffer={createAndDuplicateExportOffer}
                createAndStartNewOffer={createAndStartNewExportOffer}
              >
                {intl.formatMessage({ id: 'forms.addOffer' })}
              </CreateOfferMenu>
            </ActionGroup>
          </FormActionBar>
        </FormSection>
        <Drafts
          fillFormUsingTemplate={fillFormUsingTemplateExport}
          opened={templatesOpened}
          onClose={() => setTemplatesOpened(false)}
          lastOffers={lastOffersExport}
          templates={offerTemplatesExport}
          removeTemplate={removeTemplateExport}
        />
      </Content>
    </Page>
  )
}

const ConnectedComponent = compose(
  connect,
  connectDictionaries
)(OfferFormExport)

const CheckboxSectionStyled = styled(CheckboxSection)`
  ${breakpoint.m`
    margin-bottom: 330px;
  `}
`

export default styled(ConnectedComponent)``
