import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import { breakpoint } from 'src/theme/grid'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import BreadCrumbs from 'src/components/BreadCrumbs'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import routes from 'src/utils/routes'
import { connectDictionaries } from 'src/ducks/connectors'
import Checkbox from 'src/components/atoms/CheckboxInput'
import Select from 'src/components/filters/SelectInput'
import Alert from 'src/components/Alert'
import TextInput from 'src/components/atoms/TextInput'
import DatePicker from 'src/components/atoms/DatePicker'
import { VALID_FOR_OPTIONS } from 'src/features/stocks/ducks/consts'
import { Transportation } from 'src/features/contracts/duck/records'

import ExportLine from 'src/features/offers/components/LineExport'
import {
  CONTRACTS_TYPES_OPTIONS,
  CONTRACTS_TYPES
} from 'src/features/contracts/duck/consts'
import {
  AuctionNotesWrapper,
  CheckboxInputWrapper,
  RadioWrapper,
  WarningWrapper,
  WeightInputWrapper
} from 'src/features/offers/components/atoms'
import ImportLine from 'src/features/offers/components/LineImport'
import useValidateSchema from 'src/hooks/useValidateSchema'
import {
  validateOfferContract,
  validateOfferContractSchema
} from 'src/features/offers/duck/schema'
import { API_STATES } from 'src/ducks/consts'
import {
  ActionGroup,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection,
  FormSectionSpacer,
  InputsRowWrapper,
  Padding
} from './components/atoms'
import { FormNavigationHeader } from './components/typography'
import connect from './duck/connectors'

const OfferFormContracts = ({
  className,
  offerFormContracts,
  setOfferFormContractValue,
  clearContractOffer,
  createContractOffer,
  offerFormContracts: { state }
}) => {
  const intl = useIntl()

  const valid = useValidateSchema(
    offerFormContracts.toJS(),
    validateOfferContractSchema
  )

  useEffect(() => clearContractOffer, [])

  const addLine = () =>
    setOfferFormContractValue('transportations', [
      ...offerFormContracts.transportations,
      new Transportation()
    ])

  const clearLines = () =>
    setOfferFormContractValue('transportations', [new Transportation()])

  const isLoading = state === API_STATES.IN_PROGRESS

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <FormNavigationHeader>
              {intl.formatMessage({ id: 'forms.addContract' })}
            </FormNavigationHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Content>
        <FormSection>
          <FormInnerWrapper>
            <Padding>
              <BreadCrumbs
                links={[
                  {
                    name: intl.formatMessage({ id: 'forms.dashboard' }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                  },
                  {
                    name: intl.formatMessage({
                      id: 'dashboard.contracts'
                    }),
                    url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS}`
                  },
                  {
                    name: intl.formatMessage({ id: 'forms.addContract' }),
                    url: null
                  }
                ]}
              />
              <FormSectionSpacer>
                <RadioWrapper>
                  <div>{intl.formatMessage({ id: 'forms.contractType' })}</div>
                  {CONTRACTS_TYPES_OPTIONS.map(opt => (
                    <Checkbox
                      key={opt.value}
                      name='type'
                      value={offerFormContracts.type}
                      onChange={(name, value) => {
                        setOfferFormContractValue(name, value)
                        clearLines()
                      }}
                      type='radio'
                      option={opt}
                    />
                  ))}
                </RadioWrapper>
              </FormSectionSpacer>
              <WarningWrapper>
                <Alert
                  message={intl.formatMessage({
                    id: 'forms.contractWarning'
                  })}
                  type='info'
                />
              </WarningWrapper>
              <InputsRowWrapper>
                <CheckboxInputWrapper>
                  <DatePicker
                    label={intl.formatMessage({
                      id: 'forms.startContract'
                    })}
                    disablePast
                    required
                    name='startDate'
                    placeholder='dd.mm.rrrr'
                    value={offerFormContracts.startDate}
                    onChange={setOfferFormContractValue}
                  />
                </CheckboxInputWrapper>
                <CheckboxInputWrapper>
                  <DatePicker
                    label={intl.formatMessage({
                      id: 'forms.endContract'
                    })}
                    disablePast
                    required
                    name='endDate'
                    placeholder='dd.mm.rrrr'
                    value={offerFormContracts.endDate}
                    onChange={setOfferFormContractValue}
                  />
                </CheckboxInputWrapper>
                <WeightInputWrapper>
                  <Select
                    id='validFor'
                    name='validFor'
                    label={intl.formatMessage({
                      id: 'forms.timeValid'
                    })}
                    value={offerFormContracts.validFor}
                    options={VALID_FOR_OPTIONS}
                    onChange={setOfferFormContractValue}
                    placeholder={intl.formatMessage({
                      id: 'forms.searchPlaceholder'
                    })}
                    required
                    validate={validateOfferContract}
                  />
                </WeightInputWrapper>
              </InputsRowWrapper>
              <AuctionNotesWrapper>
                <TextInput
                  label={intl.formatMessage({
                    id: 'forms.contractTerms'
                  })}
                  id='conditions'
                  name='conditions'
                  multiline
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: 'forms.contractTermsPlaceholder'
                  })}
                  value={offerFormContracts.conditions}
                  onChange={setOfferFormContractValue}
                  // validate={validateOfferExport}
                />
              </AuctionNotesWrapper>
            </Padding>
            {offerFormContracts.type === CONTRACTS_TYPES.EXPORT &&
              offerFormContracts.transportations.map(
                (transportation, index) => <ExportLine index={index} />
              )}
            {offerFormContracts.type === CONTRACTS_TYPES.IMPORT &&
              offerFormContracts.transportations.map(
                (transportation, index) => <ImportLine index={index} />
              )}
            <Padding>
              <AddLine onClick={addLine}>
                <FormattedMessage id='forms.addLine' />
              </AddLine>
              <FormSectionSpacer>
                {intl.formatMessage({ id: 'forms.additionalInformation' })}
              </FormSectionSpacer>
              <AuctionNotesWrapper>
                <TextInput
                  label={intl.formatMessage({
                    id: 'forms.additionalInformation'
                  })}
                  id='notes'
                  name='notes'
                  multiline
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: 'forms.additionalInformationPlaceholder'
                  })}
                  value={offerFormContracts.notes}
                  onChange={setOfferFormContractValue}
                />
              </AuctionNotesWrapper>
            </Padding>
            <MarginBottom />
          </FormInnerWrapper>
          <FormActionBar>
            <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS}`}>
              {intl.formatMessage({ id: 'forms.cancel' })}
            </Link>
            <ActionGroup>
              <CreateOfferButton
                active={valid && !isLoading}
                onClick={createContractOffer}
              >
                {intl.formatMessage({ id: 'forms.addContract' })}
              </CreateOfferButton>
            </ActionGroup>
          </FormActionBar>
        </FormSection>
      </Content>
    </Page>
  )
}

const ConnectedComponent = compose(
  connect,
  connectDictionaries
)(OfferFormContracts)

export default styled(ConnectedComponent)`
  ${Padding} {
    max-width: calc(626px + 2.8rem);
  }
`

const MarginBottom = styled.div`
  ${breakpoint.m`
    margin-bottom: 150px;
  `}
`

const CreateOfferButton = styled.button`
  font-size: 1rem;
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  overflow: visible;
  padding: 0 1.2rem;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.mercury.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  pointer-events: none;
  position: relative;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      cursor: pointer;
      color: white;
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      box-shadow: 0 2px 10px 0 rgba(71, 170, 66, 0.25);
    `}
`

export const AddLine = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-weight: 400;
  text-transform: uppercase;
  text-align: right;
  cursor: pointer;
`
