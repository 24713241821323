import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, navigate, Link } from 'gatsby-plugin-intl'
import { head, pathOr } from 'ramda'
import styled from 'styled-components'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import { isNotOlderThanMinutes } from 'src/utils/helpers'
import {
  Container,
  LocationFrom,
  LocationTo,
  MoreReservations,
  OfferHasTrackingInfo,
  OfferNumber,
  ReservationsMobile,
  Selected,
  Shipowner,
  TableRow
} from 'src/features/myOffers/components/atoms'
import { DATE_SHORT_FORMAT_WITH_TIME } from 'src/ducks/consts'
import { BOOLEAN, MAX_RESERVATIONS_COUNT_FOR_QUEUE } from 'src/ducks/consts'
import { Arrow, DateTime, Ellipsis } from 'src/features/stocks/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import ContainerIcon from 'src/assets/icons/container.svg'
import ShipIcon from 'src/assets/icons/ship.svg'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import { connectImportOngoing } from 'src/features/myOffers/duck/connectors'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import AuctionDetailsOffers from 'src/features/myOffers/components/AuctionDetailsOffers'
import ReservationDetailsOffers from 'src/features/myOffers/components/ReservationDetailsOffers'
import { ReservationsField } from 'src/features/myOffers/components/ReservationsField'
import { ActionsField } from 'src/features/myOffers/components/ActionsFieldOngoing'
import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import { HandlerField } from 'src/features/myOffers/components/HandlerField'
import { AuctionsMobileField } from 'src/features/myOffers/components/AuctionsMobileField'
import { withLocation } from 'src/hocs/withLocation'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import PersonIcon from 'src/assets/icons/person.svg'
import CopyIcon from 'src/assets/icons/copy.svg'
import DeleteIcon from 'src/assets/icons/delete.svg'
import RefreshIcon from 'src/assets/icons/refresh.svg'
import { PrivateLabel } from 'src/features/offers/components/atoms'
import VisibilityIcon from 'src/assets/icons/visibility.svg'
import GoPublicAt from 'src/features/myOffers/components/GoPublicAt'

const OfferRowOngoing = ({
  offer,
  isSelected,
  showOffersReservations,
  headers,
  setSelectedOffer,
  setFilter,
  refreshOffer,
  makeOfferPublic,
  deleteOffer,
  hasPermissionToDeleteOwnOffer,
  copyMyOfferToForm,
  currentUserId,
  acceptReservation,
  rejectReservation,
  buttonsState,
  setConversationOpened,
  getOngoingOffers,
  location
}) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const isNew = isNotOlderThanMinutes(offer.addedToListingAt, 1)
  const handleOfferNumberClick = () => {
    navigate(createOfferDetailsLink(offer.referenceNumber))
  }
  const permissions = useContext(PermissionsContext)
  const hasPermissionToSeeCompanyConversations = hasPermissionTo(
    PERMISSIONS.CHAT_COMPANY_CONVERSATIONS_SEE,
    permissions
  )
  const belongsToCurrentUser = offer.handler.id === currentUserId
  const chatAvailable =
    belongsToCurrentUser || hasPermissionToSeeCompanyConversations
  const hasReservations = offer.reservationsCount > 0
  const hiddenReservationsCount =
    offer.reservations.length > 0 &&
    offer.reservationsCount - offer.reservations.length
  const isAuction = offer.type === OFFER_TYPES.AUCTION
  const hasTracking = offer.doesRequireTracking || offer.doesAllowTracking

  const handleClick = () => {
    if (isAuction || hasReservations) setOpen(!isOpen)
  }

  useEffect(() => {
    hasReservations && isOpen && showOffersReservations(offer.id)
  }, [hasReservations, isOpen])

  useEffect(() => {
    const openOnMount =
      pathOr(false, ['state', 'openOfferId'], location) === offer.id
    if (openOnMount && !isOpen) {
      setTimeout(() => {
        hasReservations && setOpen(true)
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 1000)
    }
  }, [location, offer.id])

  return (
    <>
      <TableRow
        ref={ref}
        key={offer.id}
        numberOfHeaders={headers.length}
        isSelected={isSelected}
        isOpen={isOpen}
      >
        <Selected
          name='selected-offer'
          value={isSelected}
          onChange={() => setSelectedOffer(offer.id)}
          option={{
            text: '',
            value: BOOLEAN.YES
          }}
        />
        <OfferNumber>
          <Link to={createOfferDetailsLink(offer.referenceNumber)}>
            {offer.referenceNumber}
            {offer.isPrivate && <PrivateLabel />}
            {offer.isPrivate && <GoPublicAt offer={offer} />}
          </Link>
        </OfferNumber>
        <ReservationsField
          hasReservations={hasReservations}
          onClick={handleClick}
          auction={isAuction}
          reservationsCount={offer.reservationsCount}
          price={offer.price}
          open={isOpen}
        />
        <LocationFrom>
          <Arrow>
            <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
          </Arrow>
          {/* COUNTRY_CODES <Ellipsis>{`${offer.pickupLocation.countryCode}, ${offer.pickupLocation.name}`}</Ellipsis> */}
          <Ellipsis>{offer.pickupLocation.name}</Ellipsis>
          <DateTime>
            {offer.pickupDate}
            {offer.pickupTime && `, ${offer.pickupTime}`}
          </DateTime>
        </LocationFrom>
        <LocationTo>
          {/* COUNTRY_CODES <Ellipsis>{`${offer.unloadingLocation.countryCode}, ${offer.unloadingLocation.name}`}</Ellipsis> */}
          <Ellipsis>{offer.unloadingLocation.name}</Ellipsis>
          <DateTime>
            {offer.unloadingDate}
            {offer.unloadingTime && `, ${offer.unloadingTime}`}
          </DateTime>
        </LocationTo>
        <Container>
          <ContainerIcon />
          <div>
            <Ellipsis>{offer.container.name}</Ellipsis>
          </div>
        </Container>
        <Shipowner>
          <ShipIcon />
          <div>
            <Ellipsis>{offer.shipowner.name}</Ellipsis>
          </div>
        </Shipowner>
        <HandlerField handler={offer.handler} />
        <CreatedAtField
          isNew={isNew}
          addedAt={offer.addedToListingAt}
          auction={isAuction}
        >
          <ActionsMenu onClick={() => {}}>
            <DropdownItem
              onClick={e => {
                e.stopPropagation()
                navigate(createOfferDetailsLink(offer.referenceNumber))
              }}
            >
              <PersonIcon />
              <FormattedMessage id='offerDetails.changeHandler' />
            </DropdownItem>
            <DropdownItem
              onClick={e => {
                e.stopPropagation()
                copyMyOfferToForm(offer.raw)
              }}
            >
              <CopyIcon />
              <FormattedMessage id='offerDetails.copyOffer' />
            </DropdownItem>
            {offer.isDeletable && hasPermissionToDeleteOwnOffer && (
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  setDeleteDialogOpen(true)
                }}
              >
                <DeleteIcon />
                <FormattedMessage id='offerDetails.deleteOffer' />
              </DropdownItem>
            )}
            {offer.isRefreshable && (
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  setFilter('page', 1)
                  refreshOffer(offer.id)
                }}
              >
                <RefreshIcon />
                <FormattedMessage id='offerDetails.refreshOffer' />
              </DropdownItem>
            )}
            {offer.isPublicable && (
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  setFilter('page', 1)
                  makeOfferPublic(offer.id)
                }}
              >
                <VisibilityIcon />
                <FormattedMessage id='offerDetails.makePublic' />
              </DropdownItem>
            )}
          </ActionsMenu>
        </CreatedAtField>{' '}
        <ActionsField
          onClickOfferNumber={handleOfferNumberClick}
          onClickCopy={() => {
            copyMyOfferToForm(offer.raw)
          }}
          deletable={offer.isDeletable}
          hasPermissionToDeleteOwnOffer={hasPermissionToDeleteOwnOffer}
          onClickDelete={() => setDeleteDialogOpen(true)}
          refreshable={offer.isRefreshable}
          isPublicable={offer.isPublicable}
          onClickMakePublic={() => {
            setFilter('page', 1)
            makeOfferPublic(offer.id)
          }}
          isUpdatable={offer.isUpdatable}
          buttonsState={buttonsState}
          onClickRefresh={() => {
            setFilter('page', 1)
            refreshOffer(offer.id)
          }}
        />
      </TableRow>
      {hasReservations && !isAuction && (
        <ReservationsMobile onClick={handleClick} isOpen={isOpen}>
          <span>
            <FormattedMessage id='myOffers.reservations' />
          </span>
          {`${offer.reservationsCount}/${MAX_RESERVATIONS_COUNT_FOR_QUEUE}`}
          {hasReservations && (
            <MUIDropdownIcon
              width={24}
              height={24}
              style={{ transform: `rotate(${isOpen ? '180deg' : '0'})` }}
            />
          )}
        </ReservationsMobile>
      )}
      {isAuction && (
        <AuctionsMobileField
          onClick={handleClick}
          open={isOpen}
          hasReservations={hasReservations}
          price={offer.price}
          reservationsCount={offer.reservationsCount}
        />
      )}
      {isOpen && hasTracking && <OfferHasTrackingInfo />}
      {isOpen &&
        !isAuction &&
        offer.reservations.map((reservation, index) => (
          <ReservationDetailsOffers
            key={reservation.id}
            offer={offer}
            reservation={reservation}
            index={index}
            isOpen={isOpen}
            chatAvailable={chatAvailable}
            buttonsState={buttonsState}
            acceptReservation={acceptReservation}
            hiddenReservationsCount={hiddenReservationsCount}
            rejectReservation={rejectReservation}
            rejectReservationSuccess={() => setOpen(false)}
            getOngoingOffers={getOngoingOffers}
            handleOfferNumberClick={handleOfferNumberClick}
            setConversationOpened={() => {
              setConversationOpened({
                receiverId: reservation.offeror.id,
                contextId: offer.id,
                contextType: offer.offerContextType,
                conversationOpened: true
              })
            }}
          />
        ))}
      {isOpen && isAuction && (
        <AuctionDetailsOffers
          reservation={head(offer.reservations)}
          isOpen={isOpen}
          offer={offer}
          chatAvailable={chatAvailable}
          setConversationOpened={() => {
            setConversationOpened({
              receiverId: pathOr(
                null,
                ['offeror', 'id'],
                head(offer.reservations)
              ),
              contextId: offer.id,
              contextType: offer.offerContextType,
              conversationOpened: true
            })
          }}
        />
      )}
      {isOpen && hiddenReservationsCount > 0 && !isAuction && (
        <MoreReservations>
          <FormattedMessage
            id='myOffers.moreReservations'
            values={{
              hiddenReservationsCount
            }}
          />
        </MoreReservations>
      )}
      <ConfirmDialog
        open={isDeleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        offer={offer}
        onConfirm={reason => deleteOffer(offer.id, reason)}
        onOfferNumberClick={handleOfferNumberClick}
        message={<FormattedMessage id='myOffers.deleteOneOfferWarning' />}
        title={
          <FormattedMessage
            id='myOffers.deleteOneOfferTitle'
            values={{
              name: offer.referenceNumber
            }}
          />
        }
      />
    </>
  )
}

export default connectImportOngoing(withLocation(OfferRowOngoing))
