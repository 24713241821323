import React from 'react'
import {
  Company,
  ReservationDetails
} from 'src/features/reservations/components/atoms'
import CompanyIcon from 'src/assets/icons/work-case.svg'
import { Ellipsis } from 'src/features/stocks/components/atoms'
import Handler from 'src/features/reservations/components/Handler'
import { Link } from 'gatsby-plugin-intl'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'

export const ReservationDetailsOffer = ({
  offer,
  chatAvailable,
  open,
  setConversationOpened
}) => (
  <ReservationDetails isOpen={open}>
    <Company>
      <CompanyIcon />
      <div>
        <Link
          to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.COMPANY}&companyId=${offer.issuer.company.id}`}
        >
          <Ellipsis>{offer.issuer.company.name}</Ellipsis>
        </Link>
      </div>
    </Company>
    <Handler
      handler={offer.handler}
      chatAvailable={chatAvailable}
      setConversationOpened={setConversationOpened}
    />
  </ReservationDetails>
)
