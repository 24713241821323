import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import Dropdown from 'src/features/stocks/components/TitleDropdown'
import { head, isEmpty } from 'ramda'
import { MENU_LIST } from 'src/features/dashboard/duck/consts'
import useSwitchActiveTab from 'src/hooks/useSwitchActiveTab'
import useClickOutside from 'src/hooks/useClickOutside'
import { getSubmenuItemsForActiveTab } from 'src/features/stocks/ducks/helpers'
import { withLocation } from 'src/hocs/withLocation'

const StockHeader = ({ className, children, withDropdown, location }) => {
  const [open, setOpen] = useState(false)
  const componentRef = useClickOutside(() => setOpen(false))
  const [activeTab] = useSwitchActiveTab(head(MENU_LIST).value, location.search)
  const menuItems = useMemo(() => getSubmenuItemsForActiveTab(activeTab), [
    activeTab
  ])
  const showDropdown = useMemo(() => withDropdown && !isEmpty(menuItems), [
    withDropdown,
    menuItems
  ])

  return (
    <span
      className={className}
      onClick={() => setOpen(!open)}
      ref={componentRef}
    >
      <span>{children}</span>
      {showDropdown && (
        <Dropdown setOpen={setOpen} open={open}>
          {menuItems}
        </Dropdown>
      )}
    </span>
  )
}

const StyledComponent = styled(StockHeader)`
  position: relative;
  display: inline-flex;
  min-width: 0;
  height: 100%;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  box-sizing: border-box;
  margin: 0 0.8rem 0 0.4rem;
  ${({ withDropdown }) =>
    withDropdown &&
    css`
      cursor: pointer;
    `}
  & > span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ${breakpoint.xxs`
    margin: 0 1.2rem;
    max-width: unset;
    font-size: 1.45rem;
  `}
`

export default withLocation(StyledComponent)
