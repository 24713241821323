import React from 'react'
import styled from 'styled-components'
import { OfferLink } from 'src/features/myOffers/components/atoms'
import { DateTime, Location } from 'src/features/stocks/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import ContainerIcon from 'src/assets/icons/container.svg'
import ShipIcon from 'src/assets/icons/ship.svg'
import WorkcaseIcon from 'src/assets/icons/work-case.svg'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'

import {
  normalizeExportOfferTimelineData,
  normalizeFreeCarrierOfferTimelineData,
  normalizeImportOfferTimelineData,
  normalizePostImportOfferTimelineData
} from 'src/features/stocks/ducks/normalizers'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'

const OfferInfoBox = ({ offer, className, offerorCompany }) => {
  const normalizeLocations = {
    [STOCKS_TYPES.CARGO_EXPORT]: normalizeExportOfferTimelineData,
    [STOCKS_TYPES.CARGO_IMPORT]: normalizeImportOfferTimelineData,
    [STOCKS_TYPES.FREE_CARRIERS]: normalizeFreeCarrierOfferTimelineData,
    [STOCKS_TYPES.POST_IMPORT]: normalizePostImportOfferTimelineData
  }[offer.offerType]

  const locations = normalizeLocations(offer)

  return (
    <div className={className}>
      <NumberRow>
        <OfferLink to={createOfferDetailsLink(offer.referenceNumber)}>
          {offer.referenceNumber}
        </OfferLink>
      </NumberRow>
      <LocationRow>
        {locations.map(
          (step, index) =>
            step.location.id && (
              <React.Fragment key={`loc${index}${step.location.id}`}>
                <Location>
                  <EllipsisTooltip>{step.location.name}</EllipsisTooltip>
                </Location>
                <DateTime>
                  {step.date && step.date}
                  {step.time && `, ${step.time}`}
                </DateTime>
                <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
              </React.Fragment>
            )
        )}
      </LocationRow>
      <PropertiesRow>
        <Container>
          <ContainerIcon />
          <div>
            <EllipsisTooltip>
              {offer.container
                ? offer.container.name
                : offer.containerSemiTrailer.name}
            </EllipsisTooltip>
          </div>
        </Container>
        {offer.shipowner.id && (
          <Shipowner>
            <ShipIcon />
            <div>
              <EllipsisTooltip>{offer.shipowner.name}</EllipsisTooltip>
            </div>
          </Shipowner>
        )}
        {offerorCompany && (
          <Shipowner>
            <WorkcaseIcon />
            <div>
              <EllipsisTooltip>{offerorCompany}</EllipsisTooltip>
            </div>
          </Shipowner>
        )}
      </PropertiesRow>
    </div>
  )
}

export default styled(OfferInfoBox)`
  background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  border-radius: 4px;
  padding: 0.3rem 1rem 0.75rem 1rem;
  width: 100%;
`

const LocationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 0.3rem;
  }

  ${Location} {
    font-size: 0.875rem;
  }

  ${DateTime} {
    font-size: 0.75rem;
  }

  & svg {
    color: ${({ theme }) => theme.colors.apple.hex()};
    &:last-child {
      display: none;
    }
  }
`

const PropertiesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  & > * {
    margin-right: 1rem;
    margin-top: 0.3rem;
  }
  font-size: 0.75rem;
`

const NumberRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  & > * {
    margin-right: 1rem;
    margin-top: 0.3rem;
  }
  font-size: 0.75rem;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
  > div {
    overflow: hidden;
  }
`

export const Shipowner = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
  > div {
    overflow: hidden;
  }
`
