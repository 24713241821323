import React, { useState } from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import FiltersMenu from './FiltersMenu'
import Tabs from './Tabs'

const FiltersRow = ({
  className,
  children,
  advancedFilters,
  activeFiltersNumber,
  clearFilters,
  createFilterTab,
  removeFilterTab,
  tabs,
  tabsData,
  activeTab,
  setActiveTab
}) => {
  const [moreFiltersOpened, setOpenMoreFilters] = useState(false)
  const [mobileDrawerOpened, setMobileDrawerOpened] = useState(false)
  const intl = useIntl()
  return (
    <div className={className}>
      <Tabs
        createFilterTab={createFilterTab}
        removeFilterTab={removeFilterTab}
        list={tabs}
        tabsData={tabsData}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <DisplayDesktop>
        {children}
        {moreFiltersOpened && advancedFilters}
        <ShowMoreDesktop
          onClick={() => setOpenMoreFilters(!moreFiltersOpened)}
          opened={moreFiltersOpened}
        >
          <span>
            {moreFiltersOpened
              ? `- ${intl.formatMessage({ id: 'common.less' })}`
              : `+ ${intl.formatMessage({ id: 'common.more' })}`}
          </span>
        </ShowMoreDesktop>
        <FiltersMenu createFilterTab={createFilterTab} />
      </DisplayDesktop>
      <FilterMobileButton onClick={() => setMobileDrawerOpened(true)}>
        <span>{intl.formatMessage({ id: 'common.filter' })}</span>
        <i>{activeFiltersNumber}</i>
      </FilterMobileButton>
      {mobileDrawerOpened && (
        <>
          <MobileDrawer>
            <DrawerContent>
              <DrawerTitle>
                {intl.formatMessage({ id: 'common.filters' })}
                <FiltersMenu
                  createFilterTab={createFilterTab}
                  closeMobileDrawer={() => setMobileDrawerOpened(false)}
                />
              </DrawerTitle>
              <FilterBoxesWrapper>
                {children}
                {advancedFilters}
                <ClearFilters onClick={clearFilters}>
                  {intl.formatMessage({ id: 'offers.clearFilters' })}
                </ClearFilters>
              </FilterBoxesWrapper>
              <ActionRow>
                <span onClick={() => setMobileDrawerOpened(false)}>
                  {intl.formatMessage({ id: 'common.cancel' })}
                </span>
                <AcceptButton onClick={() => setMobileDrawerOpened(false)}>
                  {intl.formatMessage({ id: 'common.filter' })}{' '}
                  <span>{activeFiltersNumber}</span>
                </AcceptButton>
              </ActionRow>
            </DrawerContent>
          </MobileDrawer>
        </>
      )}
    </div>
  )
}

export default styled(FiltersRow)`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0.6rem 0;
`

const ShowMoreDesktop = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  cursor: pointer;
  transition: color 0.3s;
  margin-bottom: 0.6rem;
  &:hover {
    color: ${({ theme }) => theme.colors.easternBlue.darken().hex()};
    & > span {
      background-color: ${({ theme }) => theme.colors.athensGray.hex()};
      border-radius: 4px;
    }
  }
  & > span {
    display: inline-block;
    position: relative;
    height: 36px;
    line-height: 36px;
    vertical-align: middle;
    padding: 0 0.6rem;
  }
`

const DisplayDesktop = styled.section`
  display: none;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-right: 32px;
  ${breakpoint.m`
      display: inline-block;
      width: 100%;
  `}

  & > div {
    display: inline-block;
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;
    vertical-align: middle;
  }
`

const MobileDrawer = styled.section`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 200;
  box-shadow: 0 2px 10px 0
    ${({ theme }) => theme.colors.ebonyClay.alpha(0.15).hex()};
  border-top: 1px solid ${({ theme }) => theme.colors.iron.hex()};
  border-radius: 12px;
  box-sizing: border-box;
  &:before {
    content: '';
    width: 100vh;
    height: 100vw;
    background-color: ${({ theme }) => theme.colors.white.alpha(0.4).hex()};
    position: absolute;
    top: -60px;
    left: 0;
  }
  ${breakpoint.m`
      display: none;
  `}
`

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 200;
`

const DrawerTitle = styled.span`
  display: inline-block;
  font-size: 1.05rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  line-height: 2;
  font-weight: 800;
  margin-bottom: 2.2rem;
  position: relative;
`

const FilterMobileButton = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 64px;
  line-height: 64px;
  border-radius: 32px;
  box-shadow: 0 0 20px 0
    ${({ theme }) => theme.colors.ebonyClay.alpha(0.15).hex()};
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
  position: fixed;
  bottom: 1.6rem;
  left: -40px;
  overflow: hidden;
  z-index: 98;
  & > span {
    display: inline-block;
    margin-left: 50px;
  }
  & > i {
    display: inline-block;
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    font-style: normal;
    color: white;
    background-color: ${({ theme }) => theme.colors.apple.hex()};
    border-radius: 50%;
  }
  ${breakpoint.m`
      display: none !important;
  `}
`

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 0.875rem;
  z-index: 200;
  min-height: 45px;
  margin-bottom: 20px;
  ${breakpoint.m`
    min-height: unset;
    margin-bottom: 0;
  `}
`

const AcceptButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  align-items: center;
  border-radius: 4px;
  height: 39px;
  box-sizing: border-box;
  color: white;
  padding: 0 0.8rem 0 1.4rem;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.apple.hex()};
    margin-left: 0.6rem;
  }
`

const FilterBoxesWrapper = styled.div`
  display: inline-block;
  padding-bottom: 1.2rem;
  min-height: 380px;
`

const ClearFilters = styled.span`
  display: inline-block;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-weight: 400;
  font-size: 0.85rem;
  margin: 1.1rem 0;
  cursor: pointer;
`
