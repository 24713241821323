import React from 'react'
import StyledCustomIcon from 'src/components/CustomIcon'
import Alert from 'src/components/Alert'
import { FormattedHTMLMessage } from 'gatsby-plugin-intl'

const NewForeignLocationsAdded = () => {
  return (
    <Alert
      message={<FormattedHTMLMessage id='forms.newForeignLocationsAdded' />}
      type='info'
      icon={
        <StyledCustomIcon
          style={{ color: 'rgb(19, 162, 191)' }}
          size={22}
          name='announcement'
        />
      }
    />
  )
}

export default NewForeignLocationsAdded
