import React from 'react'
import { FormattedMessage, FormattedPlural, useIntl } from 'gatsby-plugin-intl'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  AuctioneersCounter,
  AuctionsMobile
} from 'src/features/myOffers/components/atoms'
import { GavelIcon } from 'src/features/stocks/components/atoms'

export const AuctionsMobileField = ({
  onClick,
  hasReservations,
  price,
  open,
  reservationsCount
}) => {
  const intl = useIntl()
  return (
    <AuctionsMobile onClick={onClick} isOpen={open}>
      <GavelIcon />
      <span>
        {hasReservations && `${price.float} ${price.currency} `}
        {!hasReservations && <FormattedMessage id='offerDetails.hidden' />}
        <AuctioneersCounter>
          {' ('}
          {reservationsCount}&nbsp;
          <FormattedPlural
            value={reservationsCount}
            other={intl.formatMessage({ id: 'myOffers.auctioneers' })}
            one={intl.formatMessage({ id: 'myOffers.auctioneer' })}
          />
          )
        </AuctioneersCounter>
      </span>
      <MUIDropdownIcon
        width={24}
        height={24}
        fontSize='small'
        style={{ transform: `rotate(${open ? '180deg' : '0'})` }}
      />
    </AuctionsMobile>
  )
}
