import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { Button } from 'src/components/atoms/Buttons'
import { pathOr } from 'ramda'
import styled, { css } from 'styled-components'
import { Bold, Description } from 'src/features/stocks/components/typography'
import DownIcon from 'src/assets/icons/down.svg'
import useClickOutside from 'src/hooks/useClickOutside'
import GgReplacementUrl from 'src/assets/gg.png'
import { Avatar } from 'src/components/atoms/Avatar'

const SelectUser = ({
  handler,
  className,
  getCurrentUserCoworkers,
  coworkers,
  onChange,
  userCantUndoChange
}) => {
  const [isListOpen, setListState] = useState(false)
  const [isDialogOpen, setDialogState] = useState(false)
  const [selectedCoworkerId, setSelectedCoworkerId] = useState(null)
  const componentRef = useClickOutside(() => setListState(false))
  const gg = pathOr(null, ['communicators', 'gg'], handler)

  useEffect(() => {
    getCurrentUserCoworkers()
  }, [])

  const handleClick = () => {
    setListState(!isListOpen)
  }

  const handleSelect = id => {
    setSelectedCoworkerId(id)
    setListState(false)
    userCantUndoChange ? setDialogState(true) : onChange(id)
  }

  return (
    <div className={className} ref={componentRef}>
      <Select
        isListOpen={isListOpen}
        className={className}
        onClick={handleClick}
      >
        <Avatar url={handler.avatar && handler.avatar.url} />
        <Username>
          <Bold>{`${handler.firstName} ${handler.lastName}`}</Bold>
          <Description>
            {handler.phoneNumber}{' '}
            {gg && (
              <a href={`gg:${gg}`}>
                <img
                  alt='gg'
                  src={`http://status.gadu-gadu.pl/users/status.asp?id=${gg}&styl=0`}
                  onError={e => {
                    e.target.onerror = null
                    e.target.src = GgReplacementUrl
                  }}
                />
              </a>
            )}
          </Description>
        </Username>
        <IconContainer>
          <DownIcon
            style={{
              transform: `rotate(${isListOpen ? '180deg' : '0'})`
            }}
          />
        </IconContainer>
      </Select>
      {isListOpen && (
        <List>
          <ul>
            {coworkers.map(emp => (
              <Item
                as='li'
                key={emp.id}
                onClick={() => handleSelect(emp.id)}
                disabled={emp.id === handler.id}
              >
                <ItemContent>
                  <Avatar small url={emp.avatar && emp.avatar.url} />
                  {emp.firstName} {emp.lastName}
                  <span>
                    <FormattedMessage id='offerDetails.choose' />
                  </span>
                </ItemContent>
              </Item>
            ))}
          </ul>
        </List>
      )}
      <Dialog open={isDialogOpen} onClose={() => setDialogState(false)}>
        <DialogTitle>
          <FormattedMessage id='offerDetails.changeHandlerWarningTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='offerDetails.changeHandlerWarningDescription' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            active
            onClick={() => {
              setDialogState(false)
              onChange(selectedCoworkerId)
            }}
            color='primary'
            autoFocus
          >
            <FormattedMessage id='common.yes' />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default styled(SelectUser)`
  position: relative;
`

const Select = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  background-color: ${({ theme }) => theme.colors.white.hex()};
  ${({ isListOpen }) =>
    isListOpen &&
    css`
      box-shadow: 0 0 10px 0
        ${({ theme }) => theme.colors.apple.alpha(0.24).hex()};
      border: solid 1px ${({ theme }) => theme.colors.apple.hex()};
    `}
`

const IconContainer = styled.div`
  margin-left: auto;
  display: block;
`
const Username = styled.div`
  margin-left: 1rem;
`

const List = styled.div`
  position: absolute;
  width: 100%;
  margin-top: 4px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.white.hex()};
  max-height: 200px;
  overflow-y: scroll;
  z-index: 22;
`
const ItemContent = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  padding: 6px 0;
  margin-left: 1.5rem;
  margin-right: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  & span {
    text-transform: uppercase;
    margin-left: auto;
    color: ${({ theme }) => theme.colors.white.hex()};
  }
  ${Avatar} {
    margin-right: 8px;
  }
`

const Item = styled(Description)`
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.03).hex()};
    ${ItemContent} span {
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
    }
  }
  &:last-child ${ItemContent} {
    border: none;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) =>
        theme.colors.ebonyClay.alpha(0.03).hex()};
      ${ItemContent} span {
        display: none;
      }
    `}
`
