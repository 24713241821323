import React from 'react'
import styled from 'styled-components'
import {
  BigInputContainer,
  InputRow
} from 'src/features/orders/components/atoms'
import { navigate, FormattedMessage } from 'gatsby-plugin-intl'
import { Recipient } from 'src/features/invoices/components/TopSection/styles'
import { TextTitle } from 'src/features/invoices/InvoiceDetails'

import { DATE_FORMAT } from 'src/ducks/consts'
import moment from 'moment'

import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'

export const CorrectedInvoiceNumber = ({ invoice }) => (
  <InputRow>
    <InputContainer>
      <TextTitle>
        <FormattedMessage id='invoices.correctedInvoice' />
      </TextTitle>
      <Recipient>
        <p>
          <OfferLink
            onClick={() =>
              navigate(
                `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.id}`
              )
            }
          >
            {invoice.number}{' '}
          </OfferLink>
          <FormattedMessage id='invoices.issuedAt' />{' '}
          {moment(invoice.issueDate).format(DATE_FORMAT)}
        </p>
      </Recipient>
    </InputContainer>
  </InputRow>
)

const InputContainer = styled(BigInputContainer)`
  ${Recipient} {
    margin-top: 4px;
  }
`
const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
