import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import MarkCompany from 'src/features/companies/components/MarkCompany'

export const CompanyListItem = ({
  address,
  name,
  taxId,
  companyId,
  currentCompanyId,
  onClick,
  isWhitelisted,
  isBlacklisted
}) => (
  <ItemRow>
    <CompanyItem onClick={onClick}>
      <CompanyNameContainer>
        <div>
          <CompanyName>{name}</CompanyName>
          <CompanyTaxId>NIP: {taxId}</CompanyTaxId>
        </div>
      </CompanyNameContainer>
      <CompanyAddress>{address}</CompanyAddress>
    </CompanyItem>
    <ButtonContainer>
      {currentCompanyId !== companyId && (
        <MarkCompany
          isWhitelisted={isWhitelisted}
          isBlacklisted={isBlacklisted}
          companyId={companyId}
        />
      )}
    </ButtonContainer>
  </ItemRow>
)

const ItemRow = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  padding: 0.8125rem 1.5rem;
  ${breakpoint.m`
    align-items: center;
  `}
`

export const CompanyItem = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  & > ${MarkCompany} {
    display: none;
  }
  ${breakpoint.m`
     flex-direction: row;
     align-items: center;
    & > ${MarkCompany} {
      align-self: center;
      display: block;
      margin-left: auto;
    }
  `}
`
export const CompanyName = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`
export const CompanyTaxId = styled.div`
  font-size: 0.75rem;
  line-height: 2;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  opacity: 0.7;
`
export const CompanyAddress = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  display: flex;
  align-items: center;
`
const CompanyNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${breakpoint.m`
      width: 30%;
    ${MarkCompany} {
      display: none;
    }
  `}
`

const ButtonContainer = styled.div`
  ${breakpoint.m`
    width: 165px;
    display: flex;
    justify-content: flex-end;
  `}
`
