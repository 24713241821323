import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import XIcon from 'src/assets/icons/close-x.svg'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Dialog from '@material-ui/core/Dialog'
import TextInput from 'src/components/atoms/TextInput'
import { SaveOfferButton } from 'src/components/atoms/Buttons'
import React from 'react'
import Select from 'src/components/atoms/SelectNative'
import { COUNTRIES_OPTIONS } from 'src/ducks/consts'
import useValidateSchema from 'src/hooks/useValidateSchema'
import {
  validateAddRecipient,
  validateAddRecipientFormSchema
} from 'src/features/invoices/duck/schema'

export const AddRecipientDialog = ({
  onClose,
  open,
  onClick,
  onChange,
  invoice
}) => {
  const intl = useIntl()
  const valid = useValidateSchema(
    invoice.addRecipientForm.toJS(),
    validateAddRecipientFormSchema
  )

  const handleInputChange = (name, value) => {
    onChange(['addRecipientForm', name], value)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 9 }
      }}
    >
      <DialogContent>
        <ModalCloseIcon onClick={onClose} />
        <DialogHeader>
          <FormattedMessage id='invoices.addRecipient' />
        </DialogHeader>
        <DialogText>
          <FormattedMessage id='invoices.addRecipientDescription' />
        </DialogText>
        <InputsRow>
          <CompanyName>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'common.companyName'
              })}
              name='recipientCompanyName'
              placeholder={intl.formatMessage({
                id: 'common.companyName'
              })}
              value={invoice.addRecipientForm.recipientCompanyName}
              onChange={handleInputChange}
              validate={validateAddRecipient}
            />
          </CompanyName>
        </InputsRow>
        <InputsRow>
          <Address>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'invoices.addressLine1'
              })}
              name='recipientCompanyAddress'
              placeholder={intl.formatMessage({
                id: 'invoices.addressLine1'
              })}
              value={invoice.addRecipientForm.recipientCompanyAddress}
              onChange={handleInputChange}
              validate={validateAddRecipient}
            />
          </Address>
          <Address>
            <TextInput
              optional
              type='text'
              label={intl.formatMessage({
                id: 'invoices.addressLine2'
              })}
              name='recipientCompanyAddressLine2'
              placeholder={intl.formatMessage({
                id: 'invoices.addressLine2'
              })}
              value={invoice.addRecipientForm.recipientCompanyAddressLine2}
              onChange={handleInputChange}
              validate={validateAddRecipient}
            />
          </Address>
        </InputsRow>
        <InputsRow>
          <Address>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'common.postcode'
              })}
              name='recipientCompanyPostalCode'
              placeholder='00-000'
              value={invoice.addRecipientForm.recipientCompanyPostalCode}
              onChange={handleInputChange}
              validate={validateAddRecipient}
            />
          </Address>
          <CityName>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'offersFilters.place'
              })}
              name='recipientCompanyCity'
              placeholder=''
              value={invoice.addRecipientForm.recipientCompanyCity}
              onChange={handleInputChange}
              validate={validateAddRecipient}
            />
          </CityName>
          <Address>
            <Select
              options={COUNTRIES_OPTIONS}
              label={intl.formatMessage({ id: 'common.country' })}
              name='recipientCompanyCountryCode'
              value={invoice.addRecipientForm.recipientCompanyCountryCode}
              onChange={handleInputChange}
              validate={validateAddRecipient}
            />
          </Address>
        </InputsRow>
        <InputsRow>
          <Address>
            <TextInput
              type='text'
              label={intl.formatMessage({
                id: 'common.nip'
              })}
              name='recipientCompanyTaxId'
              placeholder='0000000000'
              value={invoice.addRecipientForm.recipientCompanyTaxId}
              onChange={handleInputChange}
              validate={validateAddRecipient}
            />
          </Address>
          <CityName>
            <TextInput
              optional
              type='text'
              label={intl.formatMessage({
                id: 'common.email'
              })}
              name='recipientCompanyEmail'
              placeholder=''
              value={invoice.addRecipientForm.recipientCompanyEmail}
              onChange={handleInputChange}
              validate={validateAddRecipient}
              helperText={<FormattedMessage id='invoices.emailHelperText' />}
            />
          </CityName>
        </InputsRow>
        <ActionBar>
          <CancelButton onClick={onClose}>
            {intl.formatMessage({ id: 'myOffers.notNow' })}
          </CancelButton>
          <SaveOfferButton active={valid} onClick={onClick}>
            <FormattedMessage id='invoices.addRecipient' />
          </SaveOfferButton>
        </ActionBar>
      </DialogContent>
    </Dialog>
  )
}
const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    padding: 1.6rem 2.2rem;
  `}
`
const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.black.hex()};
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  margin: 0.8rem 0 1.2rem 0;
  width: 100%;
  display: inline-block;
`
const DialogText = styled.p`
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`
const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    margin-bottom: 0;
  `}
`
const CancelButton = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
  margin-right: 0.8rem;
  align-items: center;
  ${breakpoint.m`
    margin-right: 2.2rem;
  `}
`
const ModalCloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`
export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const InputsRow = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint.m`
    flex-wrap: wrap;
    flex-direction: row;
    `}
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`

const CompanyName = styled.div`
  flex: 1;
`
const CityName = styled.div`
  flex: 1.3;
`
const Address = styled.div`
  flex: 1;
`
