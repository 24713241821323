import { connect } from 'react-redux'

import {
  selectCurrentUserCompanyId,
  selectCurrentUserId
} from 'src/features/account/duck/selectors'

import { setConversationOpenedRoutine } from 'src/features/messages/duck/actions'

import { prepareOrderRoutine } from 'src/features/orders/duck/actions'

import {
  selectExportButtonsState,
  selectFreeCarriersButtonsState,
  selectImportButtonsState,
  selectPostImportButtonsState
} from 'src/features/stocks/ducks/selectors'
import {
  showFinishedExportOfferReservationsRoutine,
  showOngoingExportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-export'
import {
  acceptExportReservationRoutine,
  acceptFreeCarrierReservationRoutine,
  acceptImportReservationRoutine,
  acceptPostImportReservationRoutine,
  rejectExportReservationRoutine,
  rejectFreeCarrierReservationRoutine,
  rejectImportReservationRoutine,
  rejectPostImportReservationRoutine
} from 'src/features/reservations/duck/actions'
import {
  showFinishedImportOfferReservationsRoutine,
  showOngoingImportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-import'
import {
  showFinishedFreeCarrierOfferReservationsRoutine,
  showOngoingFreeCarrierOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-free-carrier'
import {
  showFinishedPostImportOfferReservationsRoutine,
  showOngoingPostImportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-post-import'
import {
  clearExportOffersRoutine,
  getExportOffersRoutine,
  showExportOfferDetailsRoutine,
  setExportDrawerRoutine,
  setExportFiltersRoutine,
  updateExportOfferRoutine,
  reserveExportOfferRoutine,
  refreshExportOfferRoutine,
  makeExportOfferPublicRoutine,
  clearExportFiltersRoutine,
  getExportFilterTabsListRoutine,
  createExportFilterTabRoutine,
  removeExportFilterTabRoutine,
  setExportActiveTabRoutine,
  bidExportOfferRoutine,
  cleanExportOffersDetailsRoutine
} from './actions-export'

import {
  getImportOffersRoutine,
  clearImportOffersRoutine,
  showImportOfferDetailsRoutine,
  setImportDrawerRoutine,
  setImportFiltersRoutine,
  updateImportOfferRoutine,
  reserveImportOfferRoutine,
  refreshImportOfferRoutine,
  clearImportFiltersRoutine,
  createImportFilterTabRoutine,
  removeImportFilterTabRoutine,
  setImportActiveTabRoutine,
  bidImportOfferRoutine,
  cleanImportOffersDetailsRoutine
} from './actions-import'

import {
  getPostImportOffersRoutine,
  clearPostImportOffersRoutine,
  showPostImportOfferDetailsRoutine,
  setPostImportDrawerRoutine,
  setPostImportFiltersRoutine,
  updatePostImportOfferRoutine,
  reservePostImportOfferRoutine,
  refreshPostImportOfferRoutine,
  clearPostImportFiltersRoutine,
  createPostImportFilterTabRoutine,
  removePostImportFilterTabRoutine,
  setPostImportActiveTabRoutine,
  bidPostImportOfferRoutine,
  cleanPostImportOffersDetailsRoutine
} from './actions-post-import'
import {
  getFreeCarriersOffersRoutine,
  clearFreeCarriersOffersRoutine,
  showFreeCarrierOfferDetailsRoutine,
  setFreeCarriersDrawerRoutine,
  setFreeCarriersFiltersRoutine,
  updateFreeCarrierOfferRoutine,
  reserveFreeCarrierOfferRoutine,
  refreshFreeCarrierOfferRoutine,
  clearFreeCarriersFiltersRoutine,
  createFreeCarriersFilterTabRoutine,
  removeFreeCarriersFilterTabRoutine,
  setFreeCarriersActiveTabRoutine,
  bidFreeCarrierOfferRoutine,
  cleanFreeCarriersOffersDetailsRoutine
} from './actions-free-carriers'
import {
  selectExportOffers,
  selectExportOfferDetails,
  selectExportOfferTimelineData,
  selectIsExportDrawerOpen,
  selectExportOfferDetailsId,
  selectExportFilters,
  selectExportPagination,
  selectImportOffers,
  selectImportOfferDetails,
  selectImportOfferTimelineData,
  selectImportOfferDetailsId,
  selectImportFilters,
  selectIsImportDrawerOpen,
  selectFreeCarriersOffers,
  selectFreeCarrierOfferDetails,
  selectFreeCarrierOfferTimelineData,
  selectFreeCarriersOfferDetailsId,
  selectFreeCarrierFilters,
  selectIsFreeCarrierDrawerOpen,
  selectPostImportOffers,
  selectPostImportOfferDetails,
  selectPostImportOfferTimelineData,
  selectIsPostImportDrawerOpen,
  selectPostImportOfferDetailsId,
  selectPostImportFilters,
  selectImportPagination,
  selectFreeCarrierPagination,
  selectPostImportPagination,
  selectExportErrorMessage,
  selectImportErrorMessage,
  selectFreeCarrierErrorMessage,
  selectPostImportErrorMessage,
  selectExportStock,
  selectImportStock,
  selectFreeCarrierStock,
  selectPostImportStock,
  selectExportFiltersActiveNumber,
  selectImportFiltersActiveNumber,
  selectFreeCarriersFiltersActiveNumber,
  selectPostImportFiltersActiveNumber,
  selectExportFiltersTabsList,
  selectImportFiltersTabsList,
  selectFreeCarriersFiltersTabsList,
  selectPostImportFiltersTabsList,
  selectExportActiveTab,
  selectImportActiveTab,
  selectFreeCarriersActiveTab,
  selectPostImportActiveTab,
  selectExportTabsData,
  selectImportTabsData,
  selectPostImportTabsData,
  selectFreeCarriersTabsData,
  selectExportInfoBoxOffersNumber,
  selectImportInfoBoxOffersNumber,
  selectPostImportInfoBoxOffersNumber,
  selectFreeCarriersInfoBoxOffersNumber
} from './selectors'
import { id } from 'date-fns/locale'

export const connectExport = connect(
  state => ({
    offers: selectExportOffers(state),
    offerDetails: selectExportOfferDetails(state),
    timelineData: selectExportOfferTimelineData(state),
    isDrawerOpen: selectIsExportDrawerOpen(state),
    filters: selectExportFilters(state),
    pagination: selectExportPagination(state),
    selectedOfferId: selectExportOfferDetailsId(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    errorMessage: selectExportErrorMessage(state),
    stock: selectExportStock(state),
    activeFiltersNumber: selectExportFiltersActiveNumber(state),
    tabs: selectExportFiltersTabsList(state),
    tabsData: selectExportTabsData(state),
    activeTab: selectExportActiveTab(state),
    infoBoxOffersNumber: selectExportInfoBoxOffersNumber(state),
    buttonsState: selectExportButtonsState(state)
  }),
  dispatch => ({
    getOffers: () => dispatch(getExportOffersRoutine()),
    clearOffers: () => dispatch(clearExportOffersRoutine()),
    cleanExportOffersDetails: () => dispatch(cleanExportOffersDetailsRoutine()),
    showOffer: (id, query) =>
      dispatch(showExportOfferDetailsRoutine({ id, query })),
    setDrawerState: drawerState =>
      dispatch(setExportDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setExportFiltersRoutine({ name, value })),
    setFilters: payload => dispatch(setExportFiltersRoutine(payload)),
    updateOffer: (id, values) =>
      dispatch(updateExportOfferRoutine({ id, values })),
    reserveOffer: id => dispatch(reserveExportOfferRoutine({ id })),
    bidOffer: payload => dispatch(bidExportOfferRoutine(payload)),
    refreshOffer: id => dispatch(refreshExportOfferRoutine({ id })),
    makeOfferPublic: id => dispatch(makeExportOfferPublicRoutine({ id })),
    clearFilters: () => dispatch(clearExportFiltersRoutine()),
    getFilterTabsList: () => dispatch(getExportFilterTabsListRoutine()),
    createFilterTab: empty => dispatch(createExportFilterTabRoutine({ empty })),
    removeFilterTab: id => dispatch(removeExportFilterTabRoutine({ id })),
    setActiveTab: id => dispatch(setExportActiveTabRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    prepareOrder: (id, offerType) =>
      dispatch(prepareOrderRoutine({ id, offerType })),
    showOngoingOffersReservations: id =>
      dispatch(showOngoingExportOfferReservationsRoutine({ id })),
    showFinishedOffersReservations: id =>
      dispatch(showFinishedExportOfferReservationsRoutine({ id })),
    acceptReservation: payload =>
      dispatch(acceptExportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectExportReservationRoutine(payload))
  })
)

export const connectImport = connect(
  state => ({
    offers: selectImportOffers(state),
    offerDetails: selectImportOfferDetails(state),
    timelineData: selectImportOfferTimelineData(state),
    isDrawerOpen: selectIsImportDrawerOpen(state),
    selectedOfferId: selectImportOfferDetailsId(state),
    filters: selectImportFilters(state),
    pagination: selectImportPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    errorMessage: selectImportErrorMessage(state),
    stock: selectImportStock(state),
    activeFiltersNumber: selectImportFiltersActiveNumber(state),
    tabs: selectImportFiltersTabsList(state),
    tabsData: selectImportTabsData(state),
    activeTab: selectImportActiveTab(state),
    infoBoxOffersNumber: selectImportInfoBoxOffersNumber(state),
    buttonsState: selectImportButtonsState(state)
  }),
  dispatch => ({
    setDrawerState: drawerState =>
      dispatch(setImportDrawerRoutine({ drawerState })),
    showOffer: (id, query) =>
      dispatch(showImportOfferDetailsRoutine({ id, query })),
    getOffers: () => dispatch(getImportOffersRoutine()),
    clearOffers: () => dispatch(clearImportOffersRoutine()),
    cleanImportOffersDetails: () => dispatch(cleanImportOffersDetailsRoutine()),
    setFilter: (name, value) =>
      dispatch(setImportFiltersRoutine({ name, value })),
    setFilters: payload => dispatch(setImportFiltersRoutine(payload)),
    updateOffer: (id, values) =>
      dispatch(updateImportOfferRoutine({ id, values })),
    reserveOffer: id => dispatch(reserveImportOfferRoutine({ id })),
    bidOffer: payload => dispatch(bidImportOfferRoutine(payload)),
    refreshOffer: id => dispatch(refreshImportOfferRoutine({ id })),
    clearFilters: () => dispatch(clearImportFiltersRoutine()),
    createFilterTab: empty => dispatch(createImportFilterTabRoutine({ empty })),
    removeFilterTab: id => dispatch(removeImportFilterTabRoutine({ id })),
    setActiveTab: id => dispatch(setImportActiveTabRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    prepareOrder: (id, offerType) =>
      dispatch(prepareOrderRoutine({ id, offerType })),
    showOngoingOffersReservations: id =>
      dispatch(showOngoingImportOfferReservationsRoutine({ id })),
    showFinishedOffersReservations: id =>
      dispatch(showFinishedImportOfferReservationsRoutine({ id })),
    acceptReservation: payload =>
      dispatch(acceptImportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectImportReservationRoutine(payload))
  })
)

export const connectFreeCarriers = connect(
  state => ({
    offers: selectFreeCarriersOffers(state),
    offerDetails: selectFreeCarrierOfferDetails(state),
    timelineData: selectFreeCarrierOfferTimelineData(state),
    isDrawerOpen: selectIsFreeCarrierDrawerOpen(state),
    selectedOfferId: selectFreeCarriersOfferDetailsId(state),
    filters: selectFreeCarrierFilters(state),
    pagination: selectFreeCarrierPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    errorMessage: selectFreeCarrierErrorMessage(state),
    stock: selectFreeCarrierStock(state),
    activeFiltersNumber: selectFreeCarriersFiltersActiveNumber(state),
    tabs: selectFreeCarriersFiltersTabsList(state),
    tabsData: selectFreeCarriersTabsData(state),
    activeTab: selectFreeCarriersActiveTab(state),
    infoBoxOffersNumber: selectFreeCarriersInfoBoxOffersNumber(state),
    buttonsState: selectFreeCarriersButtonsState(state)
  }),
  dispatch => ({
    setDrawerState: drawerState =>
      dispatch(setFreeCarriersDrawerRoutine({ drawerState })),
    showOffer: (id, query) =>
      dispatch(showFreeCarrierOfferDetailsRoutine({ id, query })),
    getOffers: () => dispatch(getFreeCarriersOffersRoutine()),
    clearOffers: () => dispatch(clearFreeCarriersOffersRoutine()),
    cleanFreeCarriersOffersDetails: () =>
      dispatch(cleanFreeCarriersOffersDetailsRoutine()),
    setFilter: (name, value) =>
      dispatch(setFreeCarriersFiltersRoutine({ name, value })),
    setFilters: payload => dispatch(setFreeCarriersFiltersRoutine(payload)),
    updateOffer: (id, values) =>
      dispatch(updateFreeCarrierOfferRoutine({ id, values })),
    reserveOffer: id => dispatch(reserveFreeCarrierOfferRoutine({ id })),
    bidOffer: payload => dispatch(bidFreeCarrierOfferRoutine(payload)),
    refreshOffer: id => dispatch(refreshFreeCarrierOfferRoutine({ id })),
    clearFilters: () => dispatch(clearFreeCarriersFiltersRoutine()),
    createFilterTab: empty =>
      dispatch(createFreeCarriersFilterTabRoutine({ empty })),
    removeFilterTab: id => dispatch(removeFreeCarriersFilterTabRoutine({ id })),
    setActiveTab: id => dispatch(setFreeCarriersActiveTabRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    prepareOrder: (id, offerType) =>
      dispatch(prepareOrderRoutine({ id, offerType })),
    showOngoingOffersReservations: id =>
      dispatch(showOngoingFreeCarrierOfferReservationsRoutine({ id })),
    showFinishedOffersReservations: id =>
      dispatch(showFinishedFreeCarrierOfferReservationsRoutine({ id })),
    acceptReservation: payload =>
      dispatch(acceptFreeCarrierReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectFreeCarrierReservationRoutine(payload))
  })
)

export const connectPostImport = connect(
  state => ({
    offers: selectPostImportOffers(state),
    offerDetails: selectPostImportOfferDetails(state),
    timelineData: selectPostImportOfferTimelineData(state),
    isDrawerOpen: selectIsPostImportDrawerOpen(state),
    selectedOfferId: selectPostImportOfferDetailsId(state),
    filters: selectPostImportFilters(state),
    pagination: selectPostImportPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    errorMessage: selectPostImportErrorMessage(state),
    stock: selectPostImportStock(state),
    activeFiltersNumber: selectPostImportFiltersActiveNumber(state),
    tabs: selectPostImportFiltersTabsList(state),
    tabsData: selectPostImportTabsData(state),
    activeTab: selectPostImportActiveTab(state),
    infoBoxOffersNumber: selectPostImportInfoBoxOffersNumber(state),
    buttonsState: selectPostImportButtonsState(state)
  }),
  dispatch => ({
    setDrawerState: drawerState =>
      dispatch(setPostImportDrawerRoutine({ drawerState })),
    showOffer: (id, query) =>
      dispatch(showPostImportOfferDetailsRoutine({ id, query })),
    getOffers: () => dispatch(getPostImportOffersRoutine()),
    clearOffers: () => dispatch(clearPostImportOffersRoutine()),
    cleanPostImportOffersDetails: () =>
      dispatch(cleanPostImportOffersDetailsRoutine()),
    setFilter: (name, value) =>
      dispatch(setPostImportFiltersRoutine({ name, value })),
    setFilters: payload => dispatch(setPostImportFiltersRoutine(payload)),
    updateOffer: (id, values) =>
      dispatch(updatePostImportOfferRoutine({ id, values })),
    reserveOffer: id => dispatch(reservePostImportOfferRoutine({ id })),
    bidOffer: payload => dispatch(bidPostImportOfferRoutine(payload)),
    refreshOffer: id => dispatch(refreshPostImportOfferRoutine({ id })),
    clearFilters: () => dispatch(clearPostImportFiltersRoutine()),
    createFilterTab: empty =>
      dispatch(createPostImportFilterTabRoutine({ empty })),
    removeFilterTab: id => dispatch(removePostImportFilterTabRoutine({ id })),
    setActiveTab: id => dispatch(setPostImportActiveTabRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    prepareOrder: (id, offerType) =>
      dispatch(prepareOrderRoutine({ id, offerType })),
    showOngoingOffersReservations: id =>
      dispatch(showOngoingPostImportOfferReservationsRoutine({ id })),
    showFinishedOffersReservations: id =>
      dispatch(showFinishedPostImportOfferReservationsRoutine({ id })),
    acceptReservation: payload =>
      dispatch(acceptPostImportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectPostImportReservationRoutine(payload))
  })
)
