import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'
import { DateTime, Ellipsis } from 'src/features/stocks/components/atoms'
import { breakpoint } from 'src/theme/grid'
import {
  ContractLocations,
  EllipsisContainer
} from 'src/features/contracts/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import CompanyIconUrl from 'src/assets/icons/work-case.svg'
import ContainerIconUrl from 'src/assets/icons/container.svg'
import ShipIconUrl from 'src/assets/icons/ship.svg'
import EditIcon from 'src/assets/icons/edit.svg'
import connect from 'src/features/tracking/duck/connectors'
import TrackingStatus from 'src/features/tracking/components/TrackingStatusDot'
import IconWithTooltip from 'src/components/IconWithTooltip'
import WaitIconUrl from 'src/assets/icons/wait.svg'
import * as routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'

const MobileTableRow = ({ offer, isNew, className }) => {
  const isEditable = offer.isEditable

  const handleOfferNumberClick = id => {
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${id}`
    )
  }
  return (
    <>
      <div className={className}>
        <TopRow>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TrackingStatus status={offer.status} />
            <OfferLink onClick={() => handleOfferNumberClick(offer.id)}>
              {offer.referenceNumber}
            </OfferLink>
          </div>
          <DateAndActionsContainer>
            {offer.reportedDelay && (
              <IconWithTooltip
                icon={<WaitIcon />}
                title={offer.reportedDelay}
              />
            )}
            <CreatedAtField isNew={isNew} addedAt={offer.createdAt} />
            {isEditable && (
              <ActionsMenu>
                <DropdownItem
                  onClick={e => {
                    e.stopPropagation()
                    navigate(
                      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_EDIT}&id=${offer.id}`
                    )
                  }}
                >
                  <EditIcon />
                  <FormattedMessage id='common.edit' />
                </DropdownItem>
              </ActionsMenu>
            )}
          </DateAndActionsContainer>
        </TopRow>
        <LocationRow>
          <EllipsisContainer>
            <Ellipsis>
              {offer.originLocationCity ||
                `${offer.originLocation.countryCode}, ${offer.originLocation.name}` ||
                '-'}
            </Ellipsis>
            <DateTime>
              {offer.originLocationOperationDate}
              {offer.originLocationOperationTime &&
                `, ${offer.originLocationOperationTime}`}
            </DateTime>
          </EllipsisContainer>
          <Arrow>
            <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
          </Arrow>
          <EllipsisContainer>
            <Ellipsis>
              {offer.destinationLocationCity ||
                `${offer.destinationLocation.countryCode}, ${offer.destinationLocation.name}` ||
                '-'}
            </Ellipsis>
            <DateTime>
              {offer.destinationLocationOperationDate}&nbsp;
              {offer.destinationLocationOperationTime &&
                `, ${offer.destinationLocationOperationTime}`}
            </DateTime>
          </EllipsisContainer>
        </LocationRow>
        <DetailsRow>
          {offer.offer.container.id && (
            <div>
              <EllipsisTooltip>
                <span>
                  <ContainerIcon />
                  {offer.offer.container.name}
                </span>
              </EllipsisTooltip>
            </div>
          )}
          {offer.offer.shipowner.id && (
            <div>
              <EllipsisTooltip>
                <span>
                  <ShipIcon />
                  {offer.offer.shipowner.name}
                </span>
              </EllipsisTooltip>
            </div>
          )}
          <div>
            <EllipsisTooltip>
              <span>
                <CompanyIcon />
                {offer.forwarderUser.company.name}
              </span>
            </EllipsisTooltip>
          </div>
        </DetailsRow>
      </div>
    </>
  )
}

const Arrow = styled.span`
  color: ${({ theme }) => theme.colors.apple.hex()};
`
const LocationRow = styled.div`
  display: flex;
  align-items: center;
`
const MoreContractsCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  cursor: pointer;
  font-size: 0.75rem;
`

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const TopRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.75rem;
  font-weight: normal;
  & > div {
    overflow: hidden;
    padding: 0 0.5rem;
    border-right: 1px solid
      ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border: none;
    }
  }
`

const DateAndActionsContainer = styled.div`
  display: flex;
`
const CompanyIcon = styled(CompanyIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
`

const ContainerIcon = styled(ContainerIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -5px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const ShipIcon = styled(ShipIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const StyledComponent = styled(MobileTableRow)`
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(36, 45, 63, 0.15);
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  & > div {
    margin-top: 0.4rem;
    &:first-child {
      margin-top: 0;
    }
  }
  ${ContractLocations} {
    font-size: 0.875rem;
    padding-top: 0;
    ${MoreContractsCounter} {
      margin-left: auto;
    }
  }
  ${breakpoint.m`
      display: none;
    `}
`

const WaitIcon = styled(WaitIconUrl)`
  margin-right: 0.2rem;
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
`

export default connect(StyledComponent)
