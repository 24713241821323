import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { DateTime, Location } from 'src/features/stocks/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import ContainerIcon from 'src/assets/icons/container.svg'
import ShipIcon from 'src/assets/icons/ship.svg'
import WorkcaseIcon from 'src/assets/icons/work-case.svg'

import { normalizeContractImportOfferTimelineData } from 'src/features/stocks/ducks/normalizers'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import { map, pipe, prop } from 'ramda'

const OfferInfoBoxContract = ({ offer, className, offerorCompany }) => {
  const intl = useIntl()
  const locations = pipe(
    prop('transportations'),
    map(normalizeContractImportOfferTimelineData)
  )(offer)

  return (
    <div className={className}>
      {locations.map((transportation, index) => (
        <div>
          <TransportationTitle>
            {`${index + 1}. ${intl.formatMessage({
              id: 'offerDetails.line'
            })}`}
            <span>{`x${offer.transportations[index].numberOfContainers}`}</span>
          </TransportationTitle>
          <Box>
            <LocationRow>
              {transportation.map(
                step =>
                  step.location.id && (
                    <React.Fragment key={`loc${index}${step.location.id}`}>
                      <Location>
                        <EllipsisTooltip>{step.location.name}</EllipsisTooltip>
                      </Location>
                      <DateTime>
                        {step.date && step.date}
                        {step.time && `, ${step.time}`}
                      </DateTime>
                      <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
                    </React.Fragment>
                  )
              )}
            </LocationRow>
            <PropertiesRow>
              <Container>
                <ContainerIcon />
                <div>
                  <EllipsisTooltip>
                    {offer.transportations[index].container.name}
                  </EllipsisTooltip>
                </div>
              </Container>
              {offer.transportations[index].shipowner?.id && (
                <Shipowner>
                  <ShipIcon />
                  <div>
                    <EllipsisTooltip>
                      {offer.transportations[index].shipowner.name}
                    </EllipsisTooltip>
                  </div>
                </Shipowner>
              )}
              {offerorCompany && (
                <Shipowner>
                  <WorkcaseIcon />
                  <div>
                    <EllipsisTooltip>{offerorCompany}</EllipsisTooltip>
                  </div>
                </Shipowner>
              )}
            </PropertiesRow>
          </Box>
        </div>
      ))}
    </div>
  )
}

export default styled(OfferInfoBoxContract)``

const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  border-radius: 4px;
  padding: 0.7rem 1rem;
`

const LocationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 0.3rem;
  }

  ${Location} {
    font-size: 0.875rem;
  }

  ${DateTime} {
    font-size: 0.75rem;
  }

  & svg {
    color: ${({ theme }) => theme.colors.apple.hex()};
    &:last-child {
      display: none;
    }
  }
`

const PropertiesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  & > * {
    margin-right: 1rem;
    margin-top: 0.3rem;
  }
  font-size: 0.75rem;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
  > div {
    overflow: hidden;
  }
`

export const Shipowner = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
  > div {
    overflow: hidden;
  }
`
export const TransportationTitle = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  opacity: 0.7;
  margin: 0.5rem 0 0 0;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  & span {
    font-size: 0.875rem;
    line-height: 0.86;
    color: ${({ theme }) => theme.colors.apple};
    font-weight: 600;
  }
`
