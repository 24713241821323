import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, navigate, Link } from 'gatsby-plugin-intl'
import { isEmpty } from 'ramda'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  Actions,
  AuctionsMobile,
  Container,
  Delete,
  LocationFrom,
  LocationTo,
  Name,
  OfferNumber,
  Offeror,
  Reservations,
  ReservationsMobile
} from 'src/features/myOffers/components/atoms'
import {
  Arrow,
  DateTime,
  Ellipsis,
  GavelIcon
} from 'src/features/stocks/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import CompanyIcon from 'src/assets/icons/work-case.svg'
import ContainerIcon from 'src/assets/icons/container.svg'
import { Avatar } from 'src/components/atoms/Avatar'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import DeleteIcon from 'src/assets/icons/delete.svg'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import ConfirmDialog from 'src/components/ConfirmDialog'
import {
  Company,
  Date,
  FreeCarrierTableRowOngoing,
  Queue
} from 'src/features/reservations/components/atoms'
import { connectFreeCarrierOngoingReservations } from 'src/features/reservations/duck/connectors'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import { ReservationDetailsOffer } from 'src/features/reservations/components/ReservationDetailsOffer'
import AuctionDetailsReservations from 'src/features/myOffers/components/AuctionDetailsReservations'
import { MAX_RESERVATIONS_COUNT_FOR_QUEUE } from 'src/ducks/consts'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import { PrivateLabel } from 'src/features/offers/components/atoms'
import GoPublicAt from 'src/features/myOffers/components/GoPublicAt'

const ReservationRowOngoing = ({
  reservation,
  headers,
  currentUserId,
  cancelReservation,
  setConversationOpened
}) => {
  const [isOpen, setOpen] = useState(false)
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const permissions = useContext(PermissionsContext)
  const hasPermissionToSeeCompanyConversations = hasPermissionTo(
    PERMISSIONS.CHAT_COMPANY_CONVERSATIONS_SEE,
    permissions
  )
  const belongsToCurrentUser = reservation.offer.handler.id === currentUserId
  const chatAvailable =
    belongsToCurrentUser || hasPermissionToSeeCompanyConversations
  const isAuction = reservation.offer.type === OFFER_TYPES.AUCTION

  const handleClick = () => {
    setOpen(!isOpen)
  }

  useEffect(() => {
    isEmpty(reservation.reservations) && setOpen(false)
  }, [reservation.reservations])

  return (
    <>
      <FreeCarrierTableRowOngoing
        key={reservation.id}
        numberOfHeaders={headers.length}
        isOpen={isOpen}
      >
        <OfferNumber>
          <Link to={createOfferDetailsLink(reservation.offer.referenceNumber)}>
            {reservation.offer.referenceNumber}
            {reservation.offer.isPrivate && <PrivateLabel />}
            {reservation.offer.isPublic && (
              <GoPublicAt offer={reservation.offer} />
            )}
          </Link>
        </OfferNumber>
        <Reservations hasReservations={true} onClick={handleClick}>
          <span>
            <FormattedMessage id='myOffers.reservations' />
          </span>
          <MUIDropdownIcon
            width={24}
            height={24}
            style={{ transform: `rotate(${isOpen ? '180deg' : '0'})` }}
          />
        </Reservations>
        <LocationFrom>
          <Arrow>
            <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
          </Arrow>
          {/* COUNTRY_CODES <Ellipsis>{`${reservation.offer.availabilityLocation.countryCode}, ${reservation.offer.availabilityLocation.name}`}</Ellipsis> */}
          <Ellipsis>{reservation.offer.availabilityLocation.name}</Ellipsis>
          <DateTime>
            {reservation.offer.availabilityDate}
            {reservation.offer.availabilityTime &&
              `, ${reservation.offer.availabilityTime}`}
          </DateTime>
        </LocationFrom>
        <LocationTo>
          {/* COUNTRY_CODES <Ellipsis>
            {reservation.offer.destinationLocation.name
              ? `${reservation.offer.destinationLocation.countryCode}, ${reservation.offer.destinationLocation.name}`
              : ''}
          </Ellipsis> */}
          <Ellipsis>{reservation.offer.destinationLocation.name}</Ellipsis>
        </LocationTo>
        <Container>
          <ContainerIcon />
          <div>
            <Ellipsis>{reservation.offer.containerSemiTrailer.name}</Ellipsis>
          </div>
        </Container>
        <Company>
          <CompanyIcon />
          <div>
            <Ellipsis>{reservation.offer.issuer.company.name}</Ellipsis>
          </div>
        </Company>
        <Offeror>
          <Avatar
            small
            url={reservation.offeror.avatar && reservation.offeror.avatar.url}
          />
          <Name>
            <Ellipsis>
              {`${reservation.offeror.firstName} ${reservation.offeror.lastName}`}
            </Ellipsis>
            <DateTime>
              <Ellipsis>{reservation.createdAtFormatted}</Ellipsis>
            </DateTime>
          </Name>
        </Offeror>
        <Date>
          <DateTime>
            <Ellipsis>{reservation.createdAtFormatted}</Ellipsis>
          </DateTime>
          {reservation.isCancellable && (
            <ActionsMenu>
              <DropdownItem
                onClick={e => {
                  e.stopPropagation()
                  setDeleteDialogOpen(true)
                }}
              >
                <DeleteIcon />
                <FormattedMessage id='reservations.cancelReservation' />
              </DropdownItem>
            </ActionsMenu>
          )}
        </Date>
        <Queue>
          <span>
            <b>{reservation.queuePlace}</b>/
            {isAuction
              ? reservation.offer.reservationsCount
              : MAX_RESERVATIONS_COUNT_FOR_QUEUE}
          </span>
          {isAuction && <GavelIcon />}
        </Queue>
        {reservation.isCancellable && (
          <Actions>
            <Delete onClick={() => setDeleteDialogOpen(true)}>
              <DeleteIcon />
            </Delete>
          </Actions>
        )}
      </FreeCarrierTableRowOngoing>
      {!isAuction && (
        <ReservationsMobile onClick={handleClick} isOpen={isOpen}>
          <span>
            <FormattedMessage id='reservations.queue' />
            &nbsp;
            <b>{reservation.queuePlace}</b>/{MAX_RESERVATIONS_COUNT_FOR_QUEUE}
          </span>
          <MUIDropdownIcon
            width={24}
            height={24}
            style={{ transform: `rotate(${isOpen ? '180deg' : '0'})` }}
          />
        </ReservationsMobile>
      )}
      {isAuction && (
        <AuctionsMobile onClick={handleClick} isOpen={isOpen}>
          <GavelIcon />
          <span>
            <b>
              <FormattedMessage id='reservations.queue' /> &nbsp;
            </b>
            {reservation.queuePlace}/
            <b>{reservation.offer.reservationsCount}</b>
          </span>
          <MUIDropdownIcon
            width={24}
            height={24}
            fontSize='small'
            style={{ transform: `rotate(${isOpen ? '180deg' : '0'})` }}
          />
        </AuctionsMobile>
      )}
      {isOpen && !isAuction && (
        <ReservationDetailsOffer
          open={isOpen}
          offer={reservation.offer}
          chatAvailable={chatAvailable}
          setConversationOpened={() => {
            setConversationOpened({
              receiverId: reservation.offer.handler.id,
              contextId: reservation.offer.id,
              contextType: reservation.offer.offerContextType,
              conversationOpened: true
            })
          }}
        />
      )}
      {isOpen && isAuction && (
        <AuctionDetailsReservations
          reservation={reservation}
          isOpen={isOpen}
          offer={reservation.offer}
          chatAvailable={chatAvailable}
          setConversationOpened={() => {
            setConversationOpened({
              receiverId: reservation.offer.handler.id,
              contextId: reservation.offer.id,
              contextType: reservation.offer.offerContextType,
              conversationOpened: true
            })
          }}
        />
      )}
      <ConfirmDialog
        open={isDeleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        offer={reservation.offer}
        onConfirm={() =>
          cancelReservation({
            offerId: reservation.offer.id,
            reservationId: reservation.id
          })
        }
        onOfferNumberClick={() =>
          navigate(createOfferDetailsLink(reservation.offer.referenceNumber))
        }
        message={
          <FormattedMessage
            id='reservations.cancelReservationWarning'
            values={{
              name: reservation.offer.referenceNumber
            }}
          />
        }
        title={<FormattedMessage id='reservations.cancelReservationTitle' />}
      />
    </>
  )
}

export default connectFreeCarrierOngoingReservations(ReservationRowOngoing)
