import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { CreatedAt } from 'src/features/myOffers/components/atoms'
import {
  DateTime,
  Dot,
  Ellipsis,
  GavelIcon,
  GavelIconContainer
} from 'src/features/stocks/components/atoms'
import IconWithTooltip from 'src/components/IconWithTooltip'
import WaitIconUrl from 'src/assets/icons/wait.svg'

export const CreatedAtField = ({
  addedAt,
  isNew,
  auction,
  reportedDelay,
  children
}) => (
  <CreatedAt>
    <DateTime>
      <Ellipsis>
        {isNew && <Dot />}
        {moment(addedAt).fromNow()}
      </Ellipsis>
    </DateTime>
    {auction && (
      <GavelIconContainer>
        <GavelIcon />
      </GavelIconContainer>
    )}
    {reportedDelay && (
      <IconWithTooltip icon={<WaitIcon />} title={reportedDelay} />
    )}
    {children}
  </CreatedAt>
)

const WaitIcon = styled(WaitIconUrl)`
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
`
