import React from 'react'
import { compose, withProps, withHandlers, withState } from 'recompose'
import { ifElse, pipe, merge, prop, head, call, pathOr, F } from 'ramda'
import { isEmptyString } from 'ramda-adjunct'
import { AddressData } from 'src/features/tracking/duck/records'

import { withScriptjs } from 'react-google-maps'
import {
  parseGoogleAutocompleteResponse,
  parseGoogleGeocodedLocation,
  getPostcodeFromGooglePredictions,
  getValueFromGooglePredictions
} from 'src/utils/helpers'

const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=geometry,places,drawing`

const COMPONENT_WRAPPER = <span style={{ display: 'none' }} />
const PL_CENTER_LOCATION = { lat: 52.412273, lng: 19.313473 }
const PL_CENTER_RADIUS = '500'
const DEFAULT_LANG = 'pl-PL'
const DEFAULT_COUNTRY = 'PL'

export default compose(
  withProps({
    googleMapURL: GOOGLE_MAPS_URL,
    loadingElement: COMPONENT_WRAPPER
  }),
  withScriptjs,
  withState('mapRef', 'setMapRef', null),
  withState('predictions', 'updatePredictions', []),
  withState('directions', 'setDirections', []),
  withState('distance', 'setDistance', null),
  withHandlers(() => ({
    handleMapMounted: ({ setMapRef }) => ref => {
      setMapRef(() => ref)
    },
    fetchPredictionsByText: ({ updatePredictions }) => text => {
      const service = new window.google.maps.places.AutocompleteService()
      const request = {
        input: text,
        radius: PL_CENTER_RADIUS,
        location: new window.google.maps.LatLng(PL_CENTER_LOCATION),
        componentRestrictions: { country: DEFAULT_COUNTRY },
        language: DEFAULT_LANG,
        strictBounds: true,
        types: ['address']
      }
      if (!isEmptyString(text)) {
        service.getPlacePredictions(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            pipe(parseGoogleAutocompleteResponse, updatePredictions)(results)
          }
        })
      }
    },
    getGeometryByPlaceId: () => ({ placeId, shortName }) => {
      const service = new window.google.maps.Geocoder()
      return new Promise((resolve, reject) => {
        service.geocode({ placeId }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            return pipe(
              parseGoogleGeocodedLocation,
              merge({ shortName }),
              resolve
            )(results)
          }
          reject(new Error({ data: 'geocode place id error' }))
        })
      })
    },
    getAddressByPlaceId: () => ({ placeId }) => {
      const service = new window.google.maps.Geocoder()
      return new Promise((resolve, reject) => {
        service.geocode({ placeId }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const addressData = new AddressData({
              address: pipe(head, prop('formatted_address'))(results),
              city: getValueFromGooglePredictions(
                'locality',
                'long_name'
              )(results),
              postalCode: getValueFromGooglePredictions(
                'postal_code',
                'long_name'
              )(results),
              countryCode: getValueFromGooglePredictions(
                'country',
                'short_name'
              )(results),
              lat: pipe(
                head,
                pathOr(F, ['geometry', 'location', 'lat']),
                call
              )(results),
              long: pipe(
                head,
                pathOr(F, ['geometry', 'location', 'lng']),
                call
              )(results)
            })
            return resolve(addressData)
          }
          reject(new Error({ data: 'geocode place id error' }))
        })
      })
    },
    getCoordinatesPostcode: () => ({ location }) => {
      const service = new window.google.maps.Geocoder()
      return new Promise((resolve, reject) => {
        service.geocode({ location }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            return pipe(getPostcodeFromGooglePredictions, resolve)(results)
          }
          reject(new Error({ data: 'reverse geocoding error' }))
        })
      })
    },
    getCoordinatesAddressData: () => ({ location }) => {
      const service = new window.google.maps.Geocoder()
      return new Promise((resolve, reject) => {
        service.geocode({ location }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const addressData = new AddressData({
              address: pipe(head, prop('formatted_address'))(results),
              city: getValueFromGooglePredictions(
                'locality',
                'long_name'
              )(results),
              postalCode: getValueFromGooglePredictions(
                'postal_code',
                'long_name'
              )(results),
              countryCode: getValueFromGooglePredictions(
                'country',
                'short_name'
              )(results),
              lat: location.lat(),
              long: location.lng()
            })
            return resolve(addressData)
          }
          reject(new Error({ data: 'reverse geocoding error' }))
        })
      })
    },
    getDistance: ({ setDistance }) => ({ origin, destination }) => {
      const service = new window.google.maps.DistanceMatrixService()
      return new Promise((resolve, reject) => {
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: window.google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === window.google.maps.DistanceMatrixStatus.OK) {
              const distance = result.rows[0].elements[0].distance
              const duration = result.rows[0].elements[0].duration
              setDistance({
                distance,
                duration
              })
              return resolve({
                distance,
                duration
              })
            }
            reject(new Error({ data: 'get distance error' }))
          }
        )
      })
    },
    getDirections: ({ setDirections }) => ({ origin, destination }) => {
      const service = new window.google.maps.DirectionsService()
      return new Promise((resolve, reject) => {
        service.route(
          {
            origin,
            destination,
            travelMode: window.google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result)
              return resolve(result)
            }
            reject(new Error({ data: 'get directions error' }))
          }
        )
      })
    },
    getCurrentUserLocation: () =>
      ifElse(
        () => !window.navigator.geolocation,
        () => Promise.reject(new Error({ data: 'geolocation not supported' })),
        () =>
          new Promise((resolve, reject) => {
            window.navigator.geolocation.getCurrentPosition(
              position =>
                resolve({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  shortName: 'My location'
                }),
              () => reject(new Error({ data: 'geolocation blocked by user' }))
            )
          })
      )
  }))
)
