import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styled from 'styled-components'

const LoadingList = () => (
  <LackOfOffers>
    <FormattedMessage id='common.loading' />
  </LackOfOffers>
)

export default LoadingList

export const LackOfOffers = styled.div`
  min-height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
