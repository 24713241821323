import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { LinkPlaceholder } from 'src/components/Typography'
import routes from 'src/utils/routes'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import { AddOfferButton } from 'src/components/atoms/Buttons'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'

import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import Drawer from './components/Drawer'
import { ExportOfferDetails } from './components/OfferDetails'
import ExportOffersList from './components/lists/ExportOffersList'
import { connectExport } from './ducks/connectors'
import { ListWrapper, ContentWrapper } from './components/atoms'
import ExportFilters from './components/filters/Export'

const StockCargoExport = ({
  className,
  isDrawerOpen,
  setDrawerState,
  getOffers
}) => {
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToAddOffer = hasPermissionTo(
    PERMISSIONS.STOCK_EXPORT_ADD_OFFER,
    permissions
  )
  useEffect(() => {
    getOffers()
    return () => {
      setDrawerState(false)
    }
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader withDropdown>
              {intl.formatMessage({
                id: 'offers.freightExchangeExportTitle'
              })}
            </StockHeader>
            {hasPermissionToAddOffer && (
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_EXPORT}`}
              >
                <AddOfferButton />
              </Link>
            )}
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <ContentWrapper>
        <ListWrapper>
          <ExportFilters />
          <ExportOffersList trigger={isDrawerOpen} />
        </ListWrapper>
        <Drawer open={isDrawerOpen} onClose={() => setDrawerState(false)}>
          <ExportOfferDetails />
        </Drawer>
      </ContentWrapper>
    </Page>
  )
}

const StyledStockCargoExport = styled(StockCargoExport)`
  ${LinkPlaceholder} {
    margin-bottom: 1.2rem;
  }
`

export default connectExport(StyledStockCargoExport)
