import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { LinkPlaceholder } from 'src/components/Typography'
import routes from 'src/utils/routes'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import { AddOfferButton } from 'src/components/atoms/Buttons'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import ImportOffersList from './components/lists/ImportOffersList'
import { ImportOfferDetails } from './components/OfferDetails'
import Drawer from './components/Drawer'
import { connectImport } from './ducks/connectors'
import { ListWrapper, ContentWrapper } from './components/atoms'
import ImportFilters from './components/filters/Import'

const StockCargoImport = ({
  className,
  getOffers,
  setDrawerState,
  isDrawerOpen
}) => {
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToAddOffer = hasPermissionTo(
    PERMISSIONS.STOCK_IMPORT_ADD_OFFER,
    permissions
  )

  useEffect(() => {
    getOffers()
    return () => {
      setDrawerState(false)
    }
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader withDropdown>
              {intl.formatMessage({
                id: 'offers.freightExchangeImportTitle'
              })}
            </StockHeader>
            {hasPermissionToAddOffer && (
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_IMPORT}`}
              >
                <AddOfferButton active />
              </Link>
            )}
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <ContentWrapper>
        <ListWrapper>
          <ImportFilters />
          <ImportOffersList trigger={isDrawerOpen} />
        </ListWrapper>
        <Drawer open={isDrawerOpen} onClose={() => setDrawerState(false)}>
          <ImportOfferDetails />
        </Drawer>
      </ContentWrapper>
    </Page>
  )
}

const StyledStockCargoImport = styled(StockCargoImport)`
  ${LinkPlaceholder} {
    margin-bottom: 1.2rem;
  }
`

export default connectImport(StyledStockCargoImport)
