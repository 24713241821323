import React, { useState } from 'react'
import styled from 'styled-components'
import { head, equals } from 'ramda'
import use100vh from 'src/hooks/use100vh'
import PanelNavigation from 'src/components/PanelNavigation'
import { HeaderContext } from 'src/components/header/HeaderLoggedIn'
import routes from 'src/utils/routes'
import useSwitchActiveTab from 'src/hooks/useSwitchActiveTab'
import MyProfile from 'src/features/account/myProfile/MyProfile'
import MyCompany from 'src/features/account/myCompany/MyCompany'
import Invoices from 'src/features/account/Invoices/Invoices'
import DebtCollection from 'src/features/account/debtCollection/DebtCollection'
import AddUser from 'src/features/account/myCompany/AddUser'
import Settings from 'src/features/account/settings/Settings'

import EditUser from 'src/features/account/myCompany/EditUser'
import ContactUs from 'src/features/account/contact/ContactUs'
import { MENU_ITEMS, MENU_LIST } from './duck/consts'

const Account = ({ className, location }) => {
  const ref = use100vh()
  const [opened, setOpened] = useState(false)
  const [activeTab, setActiveTab] = useSwitchActiveTab(
    head(MENU_LIST).value,
    location.search
  )

  const isPageActive = equals(activeTab)

  return (
    <div className={className} ref={ref}>
      <PanelNavigation
        activeTab={activeTab}
        menu={MENU_LIST}
        setActiveTab={value => setActiveTab(value)}
        url={routes.APP_ACCOUNT}
        opened={opened}
        onClose={() => setOpened(false)}
        showBackButton
      />
      <HeaderContext.Provider
        value={{ onBurgerAction: () => setOpened(!opened) }}
      >
        <Content>
          {isPageActive(MENU_ITEMS.MY_PROFILE) && <MyProfile />}
          {isPageActive(MENU_ITEMS.MY_COMPANY) && (
            <MyCompany location={location} />
          )}
          {isPageActive(MENU_ITEMS.INVOICES) && <Invoices />}
          {isPageActive(MENU_ITEMS.DEBT_COLLECTION) && <DebtCollection />}
          {isPageActive(MENU_ITEMS.ADD_USER) && <AddUser />}
          {isPageActive(MENU_ITEMS.EDIT_USER) && <EditUser />}
          {isPageActive(MENU_ITEMS.SETTINGS) && <Settings />}
          {isPageActive(MENU_ITEMS.CONTACT) && <ContactUs />}
        </Content>
      </HeaderContext.Provider>
    </div>
  )
}

export default styled(Account)`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: auto;
`
