import styled, { css } from 'styled-components'
import CustomIcon from 'src/components/CustomIcon'
import { breakpoint } from 'src/theme/grid'
import Flag from 'src/components/atoms/Flag'
import { IconChat, IconEdit } from 'src/features/account/components/rows'
import Alert from 'src/components/Alert'

export const SectionBox = styled.div`
  display: block;
  margin-bottom: 1.2rem;
  box-sizing: border-box;
  padding: 0 1.2rem 180px 1.2rem;
`

export const SectionHeader = styled.h3`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin-bottom: 1.2rem;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.2rem;
  opacity: 0.8;
`

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 0.8rem 0;
`
export const Row = styled.div`
  display: block;
  width: 100%;
  padding-top: 0;

  ${breakpoint.m`
      display: flex;
      flex-direction: row;
      padding-top: 2rem;
      &:first-of-type {
        padding-top: 0;
        }
  `}
`

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;

  ${breakpoint.m`
        ${({ left }) =>
          left &&
          css`
            padding-right: 1rem;
          `}

            ${({ right }) =>
              right &&
              css`
                padding-left: 1rem;
              `}
  `}
`
export const NarrowColumn = styled(Column)`
  flex: 0;
`

export const AvatarContainer = styled.div`
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: fit-content;
`
export const UserContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 0 0;
  ${breakpoint.m`
        padding: 1.5rem 0 1.5rem 2.5rem;
  `}
`
export const User = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
  ${breakpoint.m`
        text-align: left;
  `}
`
export const UserProperties = styled.div`
  width: 100%;
  display: grid;
  grid-template: auto / 1fr 5fr;

  gap: 0.3rem 1rem;
  align-items: start;
  ${breakpoint.m`
    grid-template: auto / auto 1fr;
  `}
`
export const Value = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`
export const Key = styled.div`
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.75rem;
  line-height: 2;
  justify-self: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  ${breakpoint.m`
      justify-self: end;
  `}
`

export const CreatedAtCompany = styled(Key)`
  align-self: flex-end;
  margin-top: auto;
`

export const CreatedAtUser = styled.span`
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.75rem;
  line-height: 2;
  display: block;
  width: 100%;
  text-align: right;
  ${breakpoint.m`
       display: inline;
       position: absolute;
       right: 0;
       align-self: flex-start;
  `}
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  margin-bottom: 1rem;
  width: 100%;
  padding-top: 2rem;
  i {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
    margin-right: 16px;
  }
  ${breakpoint.m`
          padding-top: 0rem;
  `}
`

export const LanguageContainer = styled.div`
  max-width: 400px;
  position: relative;
  margin: 0 0 1rem;
`

export const Country = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  ${Flag} {
    display: inline-flex;
  }
`

export const DocumentsTableRow = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  cursor: default;
  font-size: 0.875rem;
  grid-column: 1 / 9;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'name name name status status'
    'from from to to icon';
  margin-bottom: 6px;
  ${breakpoint.m`
     margin-bottom: 0;
     grid-template-rows: none;
     grid-template-columns: 2fr repeat(3, 1fr) 0.5fr;
     grid-template-areas:
    'name from to status icon';
  `}

  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    max-width: 100%;
    align-self: center;
    ${breakpoint.m`
    align-self: center;
  `}
    &:first-of-type {
      padding-left: 10px;
      ${breakpoint.m`
      padding-left: 35px;
      `}
    }
  }
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  &:hover {
    box-shadow: 0 2px 20px 0
      ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
    border-left: 4px solid ${({ theme }) => theme.colors.apple.hex()};
    margin-left: -4px;
    position: relative;
  }
`

export const DocumentsHeaderItem = styled.div``

export const DocumentsTableHeader = styled(DocumentsTableRow)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  &:hover {
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
  }
  ${breakpoint.m`
     display: grid;
  `}
  > ${DocumentsHeaderItem} {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
  }
`

export const UsersTableRow = styled(DocumentsTableRow)`
  ${IconEdit}, ${IconChat} {
    display: none;
  }

  > div:first-of-type {
    padding-left: 10px;
    ${breakpoint.m`
      padding-left: 25px;
      `}
  }
  border-radius: 4px;
  margin-bottom: 4px;
  overflow: hidden;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'name name name status status'
    'username username email email email';

  &:hover {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    transform: translate(-16%, 0px);
    transition: transform 0.3s;
    width: 120%;
    grid-template-areas:
      'name name name status status icon2 icon1'
      'username username email email email icon2 icon1';

    ${IconEdit}, ${IconChat} {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
      i {
        color: white;
      }
    }

    ${IconEdit} {
      background-color: ${({ theme }) => theme.colors.bluewood.hex()};
    }
  }

  ${breakpoint.m`
    border-radius: 0;
    margin-bottom: 0;
    grid-template-rows: none;
    grid-template-columns: 2fr 1fr 2fr repeat(2, 1fr) repeat(2, 0.5fr);
     grid-template-areas:
    'name username email role status icon2 icon1';

    &:hover {
      border-radius: 0;
      margin-bottom: 0;
      grid-template-rows: none;
      grid-template-columns: 2fr 1fr 2fr repeat(2, 1fr) repeat(2, 0.5fr);
      grid-template-areas:
        'name username email role status icon2 icon1';
       transform: none;
      width: inherit;
     ${IconEdit}, ${IconChat} {
       &:hover {
        color: ${({ theme }) => theme.colors.easternBlue.hex()};
       }
      display: inherit;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: inherit;
      color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
      i {
        color: inherit;
      }
    }
     ${IconEdit} {
      justify-self: flex-start;
      background-color: inherit;
    }
    }

  `}
`
export const UsersTableHeader = styled(UsersTableRow)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  &:hover {
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
  }
  ${breakpoint.m`
     display: grid;
  `}
  > ${DocumentsHeaderItem} {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
  }
`

export const UsersHeaderItem = styled.div`
  & div {
    display: block;
  }
`

export const Dot = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${({ theme, color = 'apple' }) =>
    theme.colors[color].hex()};
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 1px;
  margin-right: 6px;
`

export const EditIcon = styled(CustomIcon)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
`

export const ChatIcon = styled(CustomIcon)`
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`
export const AddPhotoCircle = styled.label`
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2313A2BFFF' stroke-width='3' stroke-dasharray='5%25%2c 5%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
  margin: 0 auto;
  ${({ url }) =>
    url &&
    css`
      background-image: url(${url});
    `}

  ${CustomIcon} {
    height: 24px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
export const RemoveIconContainer = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  ${CustomIcon} {
    height: 24px;
    z-index: 20;
  }
  position: absolute;
  bottom: 0;
  left: calc(50% + 30px);
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
export const ErrorMessage = styled(Alert)`
  margin: 1rem 0;
  word-break: break-all;
`

export const Icon = styled.i`
  position: absolute;
  top: 5px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  width: 24px;
  svg {
    transition: transform 0.3s;
  }
`
