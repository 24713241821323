import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import { Column, Row, SectionBox } from 'src/features/account/components/atoms'
import TextInput from 'src/components/atoms/TextInput'
import { connectContactForm } from 'src/features/account/duck/connectors'
import Select from 'src/components/atoms/SelectNative'

import { validateContactFormSchema } from 'src/features/account/duck/schema'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { API_STATES } from 'src/ducks/consts'
import {
  AddressDataContainer,
  BankName,
  ButtonSend,
  CallUs,
  CompanyAddress,
  CompanyName,
  Email,
  FormContainer,
  FormTitle,
  Spacer,
  StyledAlert
} from 'src/features/account/contact/atoms'

const ContactUs = ({
  className,
  contactForm,
  sendContactForm,
  setContactFormValue,
  contactCategories
}) => {
  const intl = useIntl()
  const valid = useValidateSchema(contactForm.toJS(), validateContactFormSchema)

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'navigation.contact'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <SectionBox>
        <Row>
          <Column left>
            <FormContainer>
              <FormTitle>
                <FormattedMessage id='contact.contactUs' />
              </FormTitle>
              {contactForm.sendSuccessfully && (
                <StyledAlert
                  type='success'
                  message={intl.formatMessage({ id: 'contact.successMessage' })}
                />
              )}
              {contactForm.errorMessage && (
                <StyledAlert type='alert' message={contactForm.errorMessage} />
              )}
              <Select
                options={contactCategories}
                label={intl.formatMessage({ id: 'contact.category' })}
                placeholder={intl.formatMessage({
                  id: 'contact.categoryPlaceholder'
                })}
                name='category'
                value={contactForm.category}
                onChange={setContactFormValue}
                id='category'
                validate={() => true}
              />
              <TextInput
                label={intl.formatMessage({ id: 'contact.subject' })}
                id='subject'
                name='subject'
                placeholder={intl.formatMessage({
                  id: 'contact.subjectPlaceholder'
                })}
                value={contactForm.subject}
                onChange={setContactFormValue}
                validate={() => true}
              />
              <TextInput
                label={intl.formatMessage({ id: 'common.message' })}
                id='message'
                name='message'
                placeholder={intl.formatMessage({
                  id: 'contact.messagePlaceholder'
                })}
                multiline
                rows={5}
                value={contactForm.message}
                onChange={setContactFormValue}
                validate={() => true}
              />
              <ButtonSend
                active={valid && contactForm.state !== API_STATES.IN_PROGRESS}
                onClick={sendContactForm}
              >
                {intl.formatMessage({ id: 'common.send' })}
              </ButtonSend>
            </FormContainer>
          </Column>
          <Column right>
            <AddressDataContainer>
              <CallUs href='tel:+48603244040'>
                <FormattedMessage
                  id='contact.callUs'
                  values={{
                    b: chunks => <b>{chunks}</b>
                  }}
                />
              </CallUs>
              <Email
                target='_blank'
                href={`mailto:${intl.formatMessage({ id: 'contact.mailUs' })}`}
              >
                <FormattedMessage id='contact.mailUs' />
              </Email>
              <Spacer />
              <CompanyAddress>
                <FormattedMessage id='contact.openHours' />
              </CompanyAddress>
              <Spacer />
              <CompanyName>
                <FormattedMessage id='contact.companyName' />
              </CompanyName>
              <CompanyAddress>
                <FormattedMessage id='contact.street' />
              </CompanyAddress>
              <CompanyAddress>
                <FormattedMessage id='contact.city' />
              </CompanyAddress>
              <Spacer />
              <CompanyName>
                <FormattedMessage id='contact.bankAccount' />
              </CompanyName>
              <CompanyAddress>
                <FormattedMessage id='contact.bankAccountNumber' />
              </CompanyAddress>
              <BankName>
                <FormattedMessage id='contact.bankName' />
              </BankName>
              <br />
              <CompanyName>DIGITAL SERVICE ACT DSA</CompanyName>
              <CompanyAddress>
                <FormattedMessage id='contact.dsaLine1' />
              </CompanyAddress>
              <CompanyAddress>
                <FormattedMessage id='contact.dsaLine2' />
                <a href='mailto:dsa@e-containers.eu'>dsa@e-containers.eu</a>
              </CompanyAddress>
              <CompanyAddress>
                <FormattedMessage id='contact.dsaLine3' />
              </CompanyAddress>
              <br />
            </AddressDataContainer>
          </Column>
        </Row>
      </SectionBox>
    </Page>
  )
}
const StyledContactUs = styled(ContactUs)`
  background-color: ${({ theme }) => theme.colors.white.hex()};
`

export default connectContactForm(StyledContactUs)
