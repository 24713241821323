import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { isNil, head, take } from 'ramda'
import { isFalsy, isEmptyString, isEmptyArray } from 'ramda-adjunct'
import { useIntl } from 'gatsby-plugin-intl'
import debounce from 'lodash.debounce'
import { breakpoint } from 'src/theme/grid'
import LocalStorageService from 'src/services/LocalStorageService'

import useClickOutside from 'src/hooks/useClickOutside'
import { createLastUsedLocalStorageKey } from 'src/utils/helpers'
import {
  FilterBox,
  FilterDropdown,
  SearchInput,
  SearchInputWrapper,
  SelectOptionsWrapper,
  SelectRow,
  OptionsSectionTitle
} from './components/atoms'

const PlaceBox = ({
  className,
  id,
  label,
  locationName,
  locationValue,
  placeholder,
  onChange,
  locationTypes,
  dictionary,
  fetchLocations,
  searchHintText
}) => {
  const intl = useIntl()
  const [filterOpened, setFilterOpened] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [lastUsedOptions, setLastUsedOptions] = useState([])
  const toggleDropdown = () => setFilterOpened(!filterOpened)
  const componentRef = useClickOutside(() => setFilterOpened(false))
  const emptyText = isEmptyString(searchText) || searchText.length < 2
  const lastUsedLocalStorageKey = createLastUsedLocalStorageKey(
    dictionary.name,
    locationTypes
  )

  const debouncedSearch = useCallback(
    debounce(
      text =>
        fetchLocations({
          filter: {
            type: locationTypes,
            name: text
          },
          dictionaryName: dictionary.name,
          limit: dictionary.limit,
          page: dictionary.page
        }),
      200
    ),
    []
  )

  useEffect(() => {
    //search from 2 characters and for empty string to get full list after clear
    if (emptyText) return
    debouncedSearch(searchText)
  }, [searchText])

  useEffect(() => {
    const savedOptions = LocalStorageService.get(lastUsedLocalStorageKey)
    setLastUsedOptions(isNil(savedOptions) ? [] : JSON.parse(savedOptions))
  }, [])

  const saveLastUsedElement = option => {
    //only last 5 options is shown
    const optionsToSave = [option.toJS()].concat(take(4)(lastUsedOptions))
    LocalStorageService.set(
      lastUsedLocalStorageKey,
      JSON.stringify(optionsToSave)
    )
    setLastUsedOptions(optionsToSave)
  }

  const isMultiple = () => Array.isArray(locationValue)

  const selectedOptionValue = locationId =>
    isMultiple()
      ? head(locationValue.filter(obj => obj.id === locationId))
      : locationValue

  const generateFilterName = () => {
    const selectedLocation = isMultiple() ? head(locationValue) : locationValue
    if (isFalsy(selectedLocation)) {
      return label
    }

    const selectedOption = head(
      dictionary.values.filter(opt => opt.value === selectedLocation.id)
    )
    return !isFalsy(selectedOption) ? selectedOption.label : label
  }

  const handleLocationChange = (optionValue, isThisValueSelected) => {
    const singleValue = () => (isThisValueSelected ? null : optionValue)
    const multipleValue = () =>
      isThisValueSelected
        ? locationValue.filter(obj => obj.id !== optionValue.id)
        : [...locationValue, optionValue]
    onChange(locationName, isMultiple() ? multipleValue() : singleValue())
  }

  const handleLocationRangeChange = (range, optionValue, isSelected) => {
    const singleValue = () => ({ ...optionValue, range })
    const multipleValue = () =>
      isSelected
        ? locationValue.map(obj =>
            obj.id === optionValue.id ? { ...obj, range } : obj
          )
        : [...locationValue, { ...optionValue, range }]

    onChange(locationName, isMultiple() ? multipleValue() : singleValue())
  }

  const handleClear = e => {
    !isFalsy(e) && e.stopPropagation()
    onChange(locationName, isMultiple() ? [] : null)
    setSearchText('')
  }

  return (
    <div className={className} ref={componentRef}>
      <FilterBoxStyled
        onClick={toggleDropdown}
        opened={filterOpened}
        hasValue={
          isMultiple() ? locationValue.length > 0 : !isNil(locationValue)
        }
        id={`filter-${id}`}
        selectedNumber={isMultiple() ? locationValue.length : null}
        onClear={handleClear}
      >
        {generateFilterName()}
      </FilterBoxStyled>
      {filterOpened && (
        <FilterDropdown
          filterName={label}
          onClose={() => setFilterOpened(false)}
          onClear={handleClear}
          clearButtonText={intl.formatMessage({ id: 'offersFilters.clear' })}
          confirmButtonText={intl.formatMessage({
            id: 'offersFilters.confirm'
          })}
        >
          <SearchInputWrapper>
            <SearchInput
              name={locationName}
              id={`input-${id}`}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder={placeholder}
            />
          </SearchInputWrapper>
          <SelectOptionsWrapperStyled>
            {emptyText && isEmptyArray(lastUsedOptions) && (
              <SearchHint>{searchHintText}</SearchHint>
            )}
            {emptyText && !isEmptyArray(locationValue) && (
              <>
                <OptionsSectionTitle>
                  {intl.formatMessage({ id: 'offersFilters.chosenOptions' })}
                </OptionsSectionTitle>
                {locationValue.map((option, key) => {
                  const selectedOptionData = selectedOptionValue(option.id)
                  const isSelected = !isFalsy(selectedOptionData)
                  return (
                    <SelectRow
                      key={key}
                      selected={isSelected}
                      name={option.name}
                      stepper
                      onRangeChange={range =>
                        handleLocationRangeChange(range, option, isSelected)
                      }
                      range={
                        !isFalsy(selectedOptionData)
                          ? selectedOptionData.range
                          : 0
                      }
                      onClick={() => {
                        handleLocationChange(option, isSelected)
                      }}
                    />
                  )
                })}
              </>
            )}
            {emptyText && !isEmptyArray(lastUsedOptions) && (
              <>
                <OptionsSectionTitle>
                  {intl.formatMessage({ id: 'offersFilters.lastOptions' })}
                </OptionsSectionTitle>
                {lastUsedOptions.map((option, key) => {
                  const selectedOptionData = selectedOptionValue(option.value)
                  const isSelected = !isFalsy(selectedOptionData)
                  return (
                    <SelectRow
                      key={key}
                      selected={isSelected}
                      name={option.label}
                      stepper
                      onRangeChange={range =>
                        handleLocationRangeChange(
                          range,
                          { id: option.value, name: option.label },
                          isSelected
                        )
                      }
                      range={
                        !isFalsy(selectedOptionData)
                          ? selectedOptionData.range
                          : 0
                      }
                      onClick={() => {
                        handleLocationChange(
                          { id: option.value, range: 0, name: option.label },
                          isSelected
                        )
                      }}
                    />
                  )
                })}
              </>
            )}
            {!emptyText &&
              dictionary.values.map(option => {
                const selectedOptionData = selectedOptionValue(option.value)
                const isSelected = !isFalsy(selectedOptionData)
                return (
                  <SelectRow
                    selected={isSelected}
                    name={option.label}
                    stepper
                    onRangeChange={range =>
                      handleLocationRangeChange(
                        range,
                        { id: option.value, name: option.label },
                        isSelected
                      )
                    }
                    range={
                      !isFalsy(selectedOptionData)
                        ? selectedOptionData.range
                        : 0
                    }
                    onClick={() => {
                      handleLocationChange(
                        { id: option.value, range: 0, name: option.label },
                        isSelected
                      )
                      !isSelected && saveLastUsedElement(option)
                    }}
                  />
                )
              })}
          </SelectOptionsWrapperStyled>
        </FilterDropdown>
      )}
    </div>
  )
}

PlaceBox.defaultProps = {
  value: ''
}

export default styled(PlaceBox)`
  display: flex;
  position: relative;
  ${FilterDropdown} {
    ${breakpoint.m`
      width: 400px;
      height: 490px;
    `}
  }
`
const SelectOptionsWrapperStyled = styled(SelectOptionsWrapper)`
  height: calc(100% - 40px);
`

const FilterBoxStyled = styled(FilterBox)`
  width: 100%;
  ${breakpoint.m`
    width: 180px;
  `}
`

const SearchHint = styled.span`
  display: flex;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;
`
