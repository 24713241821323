import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { useIntl } from 'gatsby-plugin-intl'

const Tabs = ({ className, list = [], children, initialActiveTab = 0 }) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab)
  const intl = useIntl()

  return (
    <>
      <div className={className}>
        <TabsHeaderWrapper>
          {list.map((tab, index) => (
            <TabHeader
              key={index}
              active={index === activeTab}
              onClick={() => setActiveTab(index)}
            >
              {intl.formatMessage({ id: tab })}
            </TabHeader>
          ))}
        </TabsHeaderWrapper>
      </div>
      {React.Children.toArray(children)[activeTab]}
    </>
  )
}

export default styled(Tabs)`
  width: 100%;
  display: inline-block;
  margin-bottom: 1.6rem;
`

export const TabsHeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  border-bottom: solid 1px rgba(36, 45, 63, 0.15);
  white-space: nowrap;
  overflow-x: auto;
  ${breakpoint.m`
    overflow-x: hidden;
  `}
`
export const TabHeader = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.45rem 0.6rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.black.hex()};
      border-bottom: 4px solid ${({ theme }) => theme.colors.apple.hex()};
    `}
  ${breakpoint.xs`
      padding: 0.45rem 1.6rem;
  `}
`
