import { connect } from 'react-redux'
import {
  selectOrderDetails,
  selectOrderDetailsOffer,
  selectOrderEditForm,
  selectOrdersIssued,
  selectOrdersIssuedButtonsState,
  selectOrdersIssuedFilters,
  selectOrdersIssuedPagination,
  selectOrdersReceived,
  selectOrdersReceivedButtonsState,
  selectOrdersReceivedFilters,
  selectOrdersReceivedPagination,
  selectOrderTimelineData,
  selectOrdersIssuedRecords,
  selectOrdersReceivedRecords
} from 'src/features/orders/duck/selectors'
import {
  acceptOrderRoutine,
  clearEditOrderFormRoutine,
  clearOrderDetailsRoutine,
  createOrderRoutine,
  downloadOrderPdfRoutine,
  getOrderDetailsRoutine,
  getOrdersListIssuedRoutine,
  getOrdersListReceivedRoutine,
  prefillOrderFormRoutine,
  rejectOrderRoutine,
  setOrderEditFormValueRoutine,
  setOrdersIssuedFiltersRoutine,
  setOrdersReceivedFiltersRoutine,
  updateOrderRoutine
} from 'src/features/orders/duck/actions'
import {
  selectCurrentUser,
  selectCurrentUserId,
  selectIsCurrentCompanyCarrier
} from 'src/features/account/duck/selectors'
import { setConversationOpenedRoutine } from 'src/features/messages/duck/actions'
import { prepareInvoiceForTransportationOrderRoutine } from 'src/features/invoices/duck/actions'

export const connectOrderEdit = connect(
  state => ({
    orderEditForm: selectOrderEditForm(state),
    orderDetails: selectOrderDetails(state)
  }),
  dispatch => ({
    setOrderEditFormValue: (name, value) =>
      dispatch(setOrderEditFormValueRoutine({ name, value })),
    updateOrder: values => dispatch(updateOrderRoutine(values)),
    createOrder: values => dispatch(createOrderRoutine(values)),
    clearEditOrderForm: () => dispatch(clearEditOrderFormRoutine()),
    prefillOrderForm: id => dispatch(prefillOrderFormRoutine({ id }))
  })
)

export const connectOrderDetails = connect(
  state => ({
    orderDetails: selectOrderDetails(state),
    offer: selectOrderDetailsOffer(state),
    timelineData: selectOrderTimelineData(state),
    currentUser: selectCurrentUser(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state)
  }),
  dispatch => ({
    showOrder: id => dispatch(getOrderDetailsRoutine({ id })),
    acceptOrder: id => dispatch(acceptOrderRoutine({ id })),
    rejectOrder: payload => dispatch(rejectOrderRoutine(payload)),
    prepareInvoice: payload =>
      dispatch(prepareInvoiceForTransportationOrderRoutine(payload)),
    clearOrderDetails: () => dispatch(clearOrderDetailsRoutine()),
    downloadOrderPdf: id => dispatch(downloadOrderPdfRoutine({ id })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectOrderList = connect(
  state => ({
    isCurrentCompanyCarrier: selectIsCurrentCompanyCarrier(state)
  }),
  dispatch => ({
    getOrdersListReceived: () => dispatch(getOrdersListReceivedRoutine()),
    getOrdersListIssued: () => dispatch(getOrdersListIssuedRoutine())
  })
)

export const connectOrdersIssued = connect(
  state => ({
    ordersRecords: selectOrdersIssuedRecords(state),
    orders: selectOrdersIssued(state),
    filters: selectOrdersIssuedFilters(state),
    pagination: selectOrdersIssuedPagination(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectOrdersIssuedButtonsState(state)
  }),
  dispatch => ({
    setFilter: (name, value) =>
      dispatch(setOrdersIssuedFiltersRoutine({ name, value })),
    getOrdersListIssued: () => dispatch(getOrdersListIssuedRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectOrdersReceived = connect(
  state => ({
    ordersRecords: selectOrdersReceivedRecords(state),
    orders: selectOrdersReceived(state),
    filters: selectOrdersReceivedFilters(state),
    pagination: selectOrdersReceivedPagination(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectOrdersReceivedButtonsState(state)
  }),
  dispatch => ({
    setFilter: (name, value) =>
      dispatch(setOrdersReceivedFiltersRoutine({ name, value })),
    getOrdersListReceived: () => dispatch(getOrdersListReceivedRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)
