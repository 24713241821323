import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { LinkPlaceholder } from 'src/components/Typography'
import routes from 'src/utils/routes'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import { AddOfferButton } from 'src/components/atoms/Buttons'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import { PostImportOfferDetails } from './components/OfferDetails'
import PostImportOffersList from './components/lists/PostImportOffersList'
import Drawer from './components/Drawer'
import { connectPostImport } from './ducks/connectors'
import { ListWrapper, ContentWrapper } from './components/atoms'
import PostImportFilters from './components/filters/PostImport'

const StockPostImport = ({
  className,
  getOffers,
  setDrawerState,
  isDrawerOpen
}) => {
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToAddOffer = hasPermissionTo(
    PERMISSIONS.STOCK_POST_IMPORT_ADD_OFFER,
    permissions
  )
  useEffect(() => {
    getOffers()
    return () => {
      setDrawerState(false)
    }
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader withDropdown>
              {intl.formatMessage({ id: 'offers.containersExchangeTitle' })}
            </StockHeader>
            {hasPermissionToAddOffer && (
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_POST_IMPORT}`}
              >
                <AddOfferButton active />
              </Link>
            )}
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <ContentWrapper>
        <ListWrapper>
          <PostImportFilters />
          <PostImportOffersList trigger={isDrawerOpen} />
        </ListWrapper>
        <Drawer open={isDrawerOpen} onClose={() => setDrawerState(false)}>
          <PostImportOfferDetails />
        </Drawer>
      </ContentWrapper>
    </Page>
  )
}

const StyledStockPostImport = styled(StockPostImport)`
  ${LinkPlaceholder} {
    margin-bottom: 1.2rem;
  }
`

export default connectPostImport(StyledStockPostImport)
