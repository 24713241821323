import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import CustomIcon from 'src/components/CustomIcon'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import CompanyContactDataForm from 'src/features/account/myCompany/forms/CompanyContactData'
import { Key, TitleContainer, UserProperties, Value } from './atoms'
import { Title } from './typography'
import TopContainerCertificate from 'src/assets/top_container_certificate.svg'
import IconWithTooltip from 'src/components/IconWithTooltip'

const CompanyContact = ({ company, editAllowed }) => {
  const [edit, setEdit] = useState(false)
  const intl = useIntl()

  return (
    <>
      <TitleContainer>
        <Title>
          <FormattedMessage id='myCompany.contactData' />
        </Title>
        {!edit && editAllowed && (
          <EditIconContainer onClick={() => setEdit(!edit)}>
            <CustomIcon name='edit' size={24} />
          </EditIconContainer>
        )}
      </TitleContainer>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          {!edit && (
            <UserProperties>
              <Key>
                <EllipsisTooltip>
                  <FormattedMessage id='account.tel' />
                </EllipsisTooltip>
              </Key>
              <Value>{company.phoneNumber}</Value>
              <Key>
                <FormattedMessage id='myCompany.email' />
              </Key>
              <Value>
                <a href={`mailto:${company.email}`}>{company.email}</a>
              </Value>
              <Key>
                <FormattedMessage id='myCompany.www' />
              </Key>
              <Value>
                <a href={company.homepageUrl} target='_blank'>
                  {company.homepageUrl}
                </a>
              </Value>
            </UserProperties>
          )}
          {edit && <CompanyContactDataForm onCancel={() => setEdit(false)} />}
        </div>
        {company.isTopContainerCertified && (
          <div
            style={{
              flex: '0 0 auto',
              'max-width': '100%',
              'max-height': '100%',
              display: 'flex',
              'vertical-align': 'middle'
            }}
          >
            <IconWithTooltip
              title={intl.formatMessage({
                id: 'common.companyTopContainerCertified'
              })}
              icon={
                <img
                  style={{ 'margin-left': '10px' }}
                  src={TopContainerCertificate}
                  width={80}
                  alt='Top Container Certificate'
                />
              }
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CompanyContact

const EditIconContainer = styled.div`
  cursor: pointer;
`
