import React from 'react'
import styled from 'styled-components'

const InvoiceDetails = ({ className, invoice }) => (
  <div className={className}>
    <Text>
      {invoice.details.issuer ? `${invoice.details.issuer.company}` : ''}
      {invoice.transportationOrderNumber
        ? `, ${invoice.transportationOrderNumber} `
        : ''}
    </Text>
  </div>
)
export default styled(InvoiceDetails)`
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 20px;
  line-height: 1;
`

const Text = styled.span`
  display: inline;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  span {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.6).hex()};
  }
`
