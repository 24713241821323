import React, { useState } from 'react'
import styled from 'styled-components'
import { isFalsy } from 'ramda-adjunct'

const ConversationInput = ({
  className,
  id,
  name,
  placeholder,
  disabled,
  onSubmit = () => {}
}) => {
  const [value, setValue] = useState('')
  const _handleSubmit = e => {
    e.preventDefault()
    if (!isFalsy(value)) {
      onSubmit(value)
      setValue('')
    }
  }

  return (
    <div className={className}>
      <form onSubmit={_handleSubmit}>
        <Input
          disabled={disabled}
          id={id}
          name={name}
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={placeholder}
        />
      </form>
    </div>
  )
}

export default styled(ConversationInput)`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  height: 42px;
  align-items: center;
  border-radius: 4px;
  border: solid 1px rgba(198, 203, 212, 0.3);
  background-color: #f9fafb;
  border: 1px solid ${({ theme }) => theme.colors.iron.hex()};
  padding: 0 0.6rem;
  form {
    width: 100%;
    display: inline-block;
  }
`

const Input = styled.input`
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  &::placeholder {
    display: inline-block;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  }
`
