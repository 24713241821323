import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import MUIContext from '@material-ui/icons/MoreHoriz'
import useClickOutside from 'src/hooks/useClickOutside'
import { useIntl } from 'gatsby-plugin-intl'

const FiltersMenu = ({
  className,
  createFilterTab,
  closeMobileDrawer = () => {}
}) => {
  const [open, setOpen] = useState(false)
  const componentRef = useClickOutside(() => setOpen(false))
  const intl = useIntl()

  return (
    <div className={className} ref={componentRef}>
      <IconButtonStyled>
        <ContextMenuIcon onClick={() => setOpen(!open)} />
      </IconButtonStyled>
      <ActionMenu active={open}>
        <MenuItem
          onClick={() => {
            createFilterTab()
            setOpen(false)
            closeMobileDrawer()
          }}
        >
          {intl.formatMessage({ id: 'offersFilters.newTabWithThatSearch' })}
        </MenuItem>
      </ActionMenu>
    </div>
  )
}

export default styled(FiltersMenu)`
  display: flex;
  position: relative;
  margin-right: 1rem;
  z-index: 100;
  position: absolute;
  right: 0;
  top: 3px;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  ${breakpoint.m`
    z-index: 1;
  `}
`

export const ContextMenuIcon = styled(MUIContext)`
  display: flex;
`

export const ActionMenu = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  background-color: white;
  position: absolute;
  display: none;
  flex-direction: column;
  z-index: 100;
  overflow: hidden;
  top: 48px;
  right: 0;
  width: 300px;
  ${breakpoint.m`
    top: 48px;
    right: 20px;
    width: 300px;
  `}
  ${({ active }) =>
    active &&
    `
      display: flex;
  `}
`

export const MenuItem = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0.9rem 1rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 0.3s, background-color 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.05).hex()};
  }
  > span {
    margin-left: 0.8rem;
  }
  ${({ active }) =>
    active &&
    `
      font-weight: 800;
  `}
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.darkSkyBlue.hex()};
    `}
`

export const IconButtonStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    color: white;
  }
`
