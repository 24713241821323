import { pipe, find, propEq, concat, prop } from 'ramda'
import { TRACKING_OFFER_TYPES } from 'src/features/tracking/duck/consts'
import {
  CUSTOMS_CLEARANCE_TYPE_OPTIONS_EXPORT,
  CUSTOMS_CLEARANCE_TYPE_OPTIONS_IMPORT
} from 'src/features/orders/duck/consts'

/**
 * Returns order issuer/contractor instead of forwarder/carrier
 * @param {{forwarderUser, carrierUser, offer: {offerContextType}}} orderDetails
 * @returns {{contractor, issuer}}
 */
export const getIssuerAndContractorFromOrderDetails = orderDetails => {
  if (!orderDetails) return

  const issuer = {
    [TRACKING_OFFER_TYPES.EXPORT]: orderDetails.forwarderUser,
    [TRACKING_OFFER_TYPES.IMPORT]: orderDetails.forwarderUser,
    [TRACKING_OFFER_TYPES.FREE_CARRIER]: orderDetails.forwarderUser,
    [TRACKING_OFFER_TYPES.POST_IMPORT]: orderDetails.forwarderUser
  }[orderDetails.offer.offerContextType]

  const contractor = {
    [TRACKING_OFFER_TYPES.EXPORT]: orderDetails.carrierUser,
    [TRACKING_OFFER_TYPES.IMPORT]: orderDetails.carrierUser,
    [TRACKING_OFFER_TYPES.FREE_CARRIER]: orderDetails.carrierUser,
    [TRACKING_OFFER_TYPES.POST_IMPORT]: orderDetails.carrierUser
  }[orderDetails.offer.offerContextType]

  return {
    issuer,
    contractor
  }
}

export const translateCustomsClearanceType = customsClearanceType =>
  pipe(
    find(propEq('value', customsClearanceType)),
    prop('label')
  )(
    concat(
      CUSTOMS_CLEARANCE_TYPE_OPTIONS_IMPORT,
      CUSTOMS_CLEARANCE_TYPE_OPTIONS_EXPORT
    )
  )
