import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { isNil, head, take } from 'ramda'
import { isFalsy, isEmptyString, isEmptyArray } from 'ramda-adjunct'
import { useIntl } from 'gatsby-plugin-intl'
import debounce from 'lodash.debounce'
import { breakpoint } from 'src/theme/grid'
import LocalStorageService from 'src/services/LocalStorageService'

import useClickOutside from 'src/hooks/useClickOutside'
import { createLastUsedLocalStorageKey } from 'src/utils/helpers'
import {
  FilterBox,
  FilterDropdown,
  SearchInput,
  SearchInputWrapper,
  SelectOptionsWrapper,
  SelectRow,
  OptionsSectionTitle
} from './components/atoms'

const SelectBox = ({
  className,
  id,
  name,
  value,
  label,
  onChange,
  placeholder,
  removeSearchInput = false,
  searchHintText,
  dictionary,
  fetchCompanies
}) => {
  const intl = useIntl()
  const [filterOpened, setFilterOpened] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [lastUsedOptions, setLastUsedOptions] = useState([])
  const emptyText = isEmptyString(searchText) || searchText.length < 3
  const toggleDropdown = () => setFilterOpened(!filterOpened)
  const componentRef = useClickOutside(() => setFilterOpened(false))
  const lastUsedLocalStorageKey = createLastUsedLocalStorageKey(dictionary.name)

  const debouncedSearch = useCallback(
    debounce(
      text =>
        fetchCompanies({
          filter: {
            name: text
          },
          limit: dictionary.limit,
          page: dictionary.page
        }),
      200
    ),
    []
  )

  useEffect(() => {
    //search from 2 characters and for empty string to get full list after clear
    if (emptyText) return
    debouncedSearch(searchText)
  }, [searchText])

  useEffect(() => {
    const savedOptions = LocalStorageService.get(lastUsedLocalStorageKey)
    setLastUsedOptions(isNil(savedOptions) ? [] : JSON.parse(savedOptions))
  }, [])

  const saveLastUsedElement = option => {
    //only last 5 options is shown
    const optionsToSave = [option.toJS()].concat(take(4)(lastUsedOptions))
    LocalStorageService.set(
      lastUsedLocalStorageKey,
      JSON.stringify(optionsToSave)
    )
    setLastUsedOptions(optionsToSave)
  }

  const isMultiple = () => Array.isArray(value)

  const generateFilterName = () => {
    const option = isMultiple() ? head(value) : value
    if (isFalsy(option) || isEmptyArray(option)) {
      return label
    }

    return !isFalsy(option.name) ? option.name : label
  }

  const handleChange = (optionValue, isThisValueSelected) => {
    const singleValue = () => (isThisValueSelected ? null : optionValue)
    const multipleValue = () =>
      isThisValueSelected
        ? value.filter(v => v.id !== optionValue.id)
        : [...value, optionValue]
    onChange(name, isMultiple() ? multipleValue() : singleValue())
  }

  const isOptionSelected = optionId =>
    isMultiple()
      ? value.filter(obj => obj.id === optionId).length > 0
      : !isNil(value) && value.id === optionId

  const handleClear = e => {
    !isFalsy(e) && e.stopPropagation()
    setSearchText('')
    onChange(name, isMultiple() ? [] : null)
  }

  return (
    <div className={className} ref={componentRef}>
      <FilterBoxStyled
        onClick={toggleDropdown}
        opened={filterOpened}
        hasValue={isMultiple() ? value.length > 0 : !isNil(value)}
        id={`filter-${id}`}
        selectedNumber={isMultiple() ? value.length : null}
        onClear={handleClear}
      >
        {generateFilterName()}
      </FilterBoxStyled>
      {filterOpened && (
        <FilterDropdown
          filterName={label}
          onClose={() => setFilterOpened(false)}
          onClear={handleClear}
          id={`select-${id}`}
          clearButtonText={intl.formatMessage({ id: 'offersFilters.clear' })}
          confirmButtonText={intl.formatMessage({
            id: 'offersFilters.confirm'
          })}
        >
          {!removeSearchInput && (
            <SearchInputWrapper>
              <SearchInput
                name={name}
                id={`input-${id}`}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                placeholder={placeholder}
              />
            </SearchInputWrapper>
          )}
          {emptyText && isEmptyArray(lastUsedOptions) && (
            <SearchHint>{searchHintText}</SearchHint>
          )}
          <SelectOptionsWrapper>
            {emptyText && !isEmptyArray(value) && (
              <>
                <OptionsSectionTitle>
                  {intl.formatMessage({ id: 'offersFilters.chosenOptions' })}
                </OptionsSectionTitle>
                {value.map((option, key) => {
                  const isSelected = isOptionSelected(option.id)
                  return (
                    <SelectRow
                      key={key}
                      selected={isSelected}
                      name={option.name}
                      onClick={() =>
                        handleChange(
                          { name: option.name, id: option.id },
                          isSelected
                        )
                      }
                    />
                  )
                })}
              </>
            )}
            {emptyText && !isEmptyArray(lastUsedOptions) && (
              <>
                <OptionsSectionTitle>
                  {intl.formatMessage({ id: 'offersFilters.lastOptions' })}
                </OptionsSectionTitle>
                {lastUsedOptions.map((option, key) => {
                  const isSelected = isOptionSelected(option.value)
                  return (
                    <SelectRow
                      key={key}
                      selected={isSelected}
                      name={option.label}
                      onClick={() =>
                        handleChange(
                          { name: option.label, id: option.value },
                          isSelected
                        )
                      }
                    />
                  )
                })}
              </>
            )}
            {!emptyText &&
              dictionary.values.map(option => {
                const isSelected = isOptionSelected(option.value)
                return (
                  <SelectRow
                    key={option.label}
                    selected={isSelected}
                    name={option.label}
                    onClick={() => {
                      handleChange(
                        { name: option.label, id: option.value },
                        isSelected
                      )
                      !isSelected && saveLastUsedElement(option)
                    }}
                  />
                )
              })}
          </SelectOptionsWrapper>
        </FilterDropdown>
      )}
    </div>
  )
}

SelectBox.defaultProps = {
  id: '',
  label: '',
  name: '',
  value: '',
  placeholder: '',
  options: [],
  onChange: () => {}
}

export default styled(SelectBox)`
  display: flex;
  position: relative;
  ${FilterDropdown} {
    ${breakpoint.m`
      width: 230px;
      height: 360px;
    `}
  }
`

const FilterBoxStyled = styled(FilterBox)`
  width: 100%;
  ${breakpoint.m`
    width: 180px;
  `}
`

const SearchHint = styled.span`
  display: flex;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;
`
