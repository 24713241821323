import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link, useIntl } from 'gatsby-plugin-intl'
import routes from 'src/utils/routes'
import { LinkPlaceholder } from 'src/components/Typography'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { validateAddUserSchema } from 'src/features/account/duck/schema'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import AddUserForm from 'src/features/account/myCompany/forms/AddUserForm'
import Alert from 'src/components/Alert'
import BreadCrumbs from 'src/components/BreadCrumbs'
import {
  ActionGroup,
  FormActionBar
} from 'src/features/offers/components/atoms'
import { MENU_ITEMS, MY_COMPANY_TABS } from 'src/features/account/duck/consts'
import { SaveOfferButton } from 'src/components/atoms/Buttons'
import { Page } from 'src/components/Wrappers'
import { SectionBox } from 'src/features/account/components/atoms'
import { connectAddCompanyUser } from 'src/features/account/duck/connectors'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import { API_STATES } from 'src/ducks/consts'

const AddUser = ({
  className,
  setAddUserFormValue,
  addUserForm,
  addUser,
  clearForm,
  addUserFormState: { state }
}) => {
  const intl = useIntl()
  const valid = useValidateSchema(addUserForm.toJS(), validateAddUserSchema)
  useEffect(() => () => clearForm(), [])
  const isLoading = state === API_STATES.IN_PROGRESS
  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'myCompany.users.addUser'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <SectionBox>
        <BreadCrumbs
          links={[
            {
              name: intl.formatMessage({ id: 'myCompany.myCompany' }),
              url: `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`
            },
            {
              name: intl.formatMessage({
                id: 'myCompany.employees'
              }),
              url: `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`,
              state: {
                activeTab: MY_COMPANY_TABS.EMPLOYEES
              }
            },
            {
              name: intl.formatMessage({ id: 'myCompany.users.addUser' }),
              url: null
            }
          ]}
        />
        <Alert
          type='info'
          message={intl.formatMessage({ id: 'myCompany.users.addUserAlert' })}
        />
        <AddUserForm setChange={setAddUserFormValue} data={addUserForm} />
      </SectionBox>
      <FormActionBar>
        <Link
          to={`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`}
          state={{
            activeTab: MY_COMPANY_TABS.EMPLOYEES
          }}
        >
          {intl.formatMessage({ id: 'common.cancel' })}
        </Link>
        <ActionGroup>
          <SaveOfferButton active={valid && !isLoading} onClick={addUser}>
            {intl.formatMessage({ id: 'common.save' })}
          </SaveOfferButton>
        </ActionGroup>
      </FormActionBar>
    </Page>
  )
}

const ConnectedComponent = connectAddCompanyUser(AddUser)
export default styled(ConnectedComponent)`
  ${LinkPlaceholder} {
    margin-bottom: 1.2rem;
  }
`
