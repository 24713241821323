import React from 'react'
import styled from 'styled-components'
import { INVOICES_STATES } from 'src/features/invoices/duck/consts'
import { EllipsisContainer } from 'src/features/contracts/components/atoms'
import { Ellipsis } from 'src/features/stocks/components/atoms'
import { FormattedMessage } from 'gatsby-plugin-intl'

const StatusComponent = ({ status }) => {
  const dotColor = {
    [INVOICES_STATES.FULLY_PAID]: 'apple',
    [INVOICES_STATES.PARTIALLY_PAID]: 'california',
    [INVOICES_STATES.UNPAID]: 'geyser'
  }[status]

  return (
    <Status>
      <Dot color={dotColor} />
      <EllipsisContainer>
        <Ellipsis>
          <FormattedMessage id={`invoices.states.${status}`} />
        </Ellipsis>
      </EllipsisContainer>
    </Status>
  )
}

export default StatusComponent

export const Dot = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${({ theme, color = 'apple' }) =>
    theme.colors[color].hex()};
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  position: absolute;
  top: 5px;
  left: -7px;
`

const Status = styled.div`
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`
