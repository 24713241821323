import React from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Pagination from 'src/components/Pagination'
import {
  BottomRow,
  Container,
  Counter,
  Ellipsis,
  EmptyOffers
} from 'src/features/stocks/components/atoms'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { BOOLEAN } from 'src/ducks/consts'
import {
  ActionGroup,
  HeaderItem,
  ListTopRow
} from 'src/features/myOffers/components/atoms'
import LoadingList from 'src/components/atoms/LoadingList'
import Reservation from 'src/features/reservations/components/free-carrier/ReservationRowOngoing'
import { Icon } from 'src/features/account/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { connectFreeCarrierOngoingReservations } from 'src/features/reservations/duck/connectors'
import { MY_RESERVATIONS_HEADERS } from 'src/features/reservations/duck/consts'
import { FreeCarrierTableHeaderOngoing } from 'src/features/reservations/components/atoms'
import Shade from 'src/components/Shade'
import { isStockLoading } from 'src/utils/stocks'

const OffersList = ({
  reservations,
  pagination,
  filters,
  setFilter,
  hidePagination = false,
  myReservations
}) => {
  const headers = MY_RESERVATIONS_HEADERS.FREE_CARRIER_ONGOING
  const intl = useIntl()

  const toggleFilter = name => {
    setFilter(name, !filters.onlyMe)
  }

  const renderListContents = () => {
    if (isStockLoading(myReservations, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (reservations.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='offers.noOffers' />
        </EmptyOffers>
      )
    }

    if (reservations.length > 0) {
      return reservations.map(reservation => (
        <Reservation
          key={reservation.id}
          reservation={reservation}
          headers={headers}
        />
      ))
    }
  }

  return (
    <>
      <ListTopRow>
        <Counter>
          <span>{`${pagination.total} `}</span>
          <FormattedMessage
            id='offers.offersFound'
            values={{
              count: pagination.total
            }}
          />
        </Counter>
        <ActionGroup>
          <Checkbox
            name='onlyMe'
            value={filters.onlyMe}
            onChange={toggleFilter}
            option={{
              text: intl.formatMessage({ id: 'reservations.onlyMe' }),
              value: BOOLEAN.YES
            }}
          />
        </ActionGroup>
      </ListTopRow>
      <Container>
        <FreeCarrierTableHeaderOngoing numberOfHeaders={headers.length}>
          {headers &&
            headers.map((header, index) => (
              <HeaderItem sort={false} key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage id={header.name} defaultMessage=' ' />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </HeaderItem>
            ))}
        </FreeCarrierTableHeaderOngoing>
        {renderListContents()}
      </Container>
      {reservations.length > 0 && !hidePagination && (
        <BottomRow>
          <Pagination
            itemsPerPage={filters.limit}
            currentPage={pagination.currentPage}
            pagesCount={pagination.lastPage}
            setPage={page => setFilter('page', page)}
            setItemsPerPage={limit => {
              setFilter('limit', limit)
              setFilter('page', 1)
            }}
          />
        </BottomRow>
      )}
    </>
  )
}

export default connectFreeCarrierOngoingReservations(OffersList)
