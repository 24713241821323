import {
  Actions,
  ChangeHandler,
  Copy,
  Delete,
  Refresh,
  MakePublic
} from 'src/features/myOffers/components/atoms'
import PersonIcon from 'src/assets/icons/person.svg'
import VisibilityIcon from 'src/assets/icons/visibility.svg'
import CopyIcon from 'src/assets/icons/copy.svg'
import DeleteIcon from 'src/assets/icons/delete.svg'
import { API_STATES } from 'src/ducks/consts'
import RefreshIcon from 'src/assets/icons/refresh.svg'
import React from 'react'

export const ActionsField = ({
  buttonsState,
  onClickOfferNumber,
  deletable,
  onClickRefresh,
  onClickDelete,
  onClickMakePublic,
  onClickCopy,
  refreshable,
  isUpdatable,
  isPublicable,
  hasPermissionToDeleteOwnOffer
}) => (
  <Actions>
    {isUpdatable && (
      <ChangeHandler onClick={onClickOfferNumber}>
        <PersonIcon />
        <VisibilityIcon />
      </ChangeHandler>
    )}
    <Copy onClick={onClickCopy}>
      <CopyIcon />
    </Copy>
    {deletable && hasPermissionToDeleteOwnOffer && (
      <Delete onClick={onClickDelete}>
        <DeleteIcon />
      </Delete>
    )}
    {refreshable && (
      <Refresh
        loading={buttonsState === API_STATES.IN_PROGRESS}
        onClick={onClickRefresh}
      >
        <RefreshIcon />
      </Refresh>
    )}
    {isPublicable && (
      <MakePublic onClick={onClickMakePublic}>
        <VisibilityIcon />
      </MakePublic>
    )}
  </Actions>
)
