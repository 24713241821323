import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import TextInput from 'src/components/atoms/TextInput'
import { InputTitle } from 'src/features/invoices/components/InvoiceSettingsDialog'
import { validateAddInvoice } from 'src/features/invoices/duck/schema'
import Select from 'src/features/invoices/components/Select'
import { connectDictionaries } from 'src/ducks/connectors'
import RemoveIconUrl from 'src/assets/icons/close-x.svg'
import { breakpoint } from 'src/theme/grid'
import debounce from 'lodash.debounce'
import { TAX_RATES_OPTIONS } from 'src/features/invoices/duck/consts'
import { prepend } from 'ramda'

const MobileInvoiceItemRow = ({
  item,
  index,
  setInvoiceEditFormValue,
  removeItem,
  dictionaries,
  recalculateInvoice,
  invoicesLength
}) => {
  const intl = useIntl()

  const formatPrice = price => (price >= 0 ? Number(price).toFixed(2) : '0.00')

  useEffect(() => {
    recalculateInvoice()
  }, [
    item.description,
    item.unit,
    item.unitPrice.float,
    item.qty,
    item.taxRate
  ])

  const debouncedSetInvoiceEditFormValue = useCallback(
    debounce(setInvoiceEditFormValue, 500),
    []
  )

  const onPriceBlur = () => {
    const priceFormatted = formatPrice(item.unitPrice.float)
    if (priceFormatted !== item.unitPrice.float) {
      debouncedSetInvoiceEditFormValue(
        ['items', index, 'unitPrice', 'float'],
        priceFormatted
      )
    }
  }
  return (
    <Container>
      <div>
        <InputTitle>{index + 1}.</InputTitle>
      </div>
      <Column>
        <Row>
          <TextInput
            required
            label={intl.formatMessage({
              id: 'invoices.name'
            })}
            validate={validateAddInvoice}
            type='text'
            name={['items', index, 'description']}
            value={item.description}
            onChange={setInvoiceEditFormValue}
          />
        </Row>
        <Row>
          <TextInput
            type='text'
            name={['items', index, 'pkwiuCode']}
            label={intl.formatMessage({
              id: 'invoices.pkwiu'
            })}
            value={item.pkwiuCode}
            onChange={setInvoiceEditFormValue}
          />
          <GtuSelectContainer>
            <InputTitle>GTU</InputTitle>
            <Select
              name={['items', index, 'gtuCode']}
              id={`'items${index}.gtuCode`}
              options={prepend(
                { label: '-', value: '' },
                dictionaries.gtuCodes
              )}
              label={intl.formatMessage({
                id: 'invoices.gtu'
              })}
              validate={validateAddInvoice}
              value={item.gtuCode}
              onChange={e =>
                setInvoiceEditFormValue(
                  ['items', index, 'gtuCode'],
                  e.target.value
                )
              }
            />
          </GtuSelectContainer>
          <QtyContainer>
            <TextInput
              required
              type='number'
              name={['items', index, 'qty']}
              label={intl.formatMessage({
                id: 'invoices.qty'
              })}
              value={item.qty}
              onChange={(name, value) => {
                setInvoiceEditFormValue(name, value)
              }}
              id={`'items${index}.qty`}
              validate={validateAddInvoice}
            />
          </QtyContainer>
          <TextInput
            required
            type='text'
            name={['items', index, 'unit']}
            label={intl.formatMessage({
              id: 'invoices.unit'
            })}
            value={item.unit}
            onChange={setInvoiceEditFormValue}
            id={`'items${index}.unit`}
            validate={validateAddInvoice}
          />
        </Row>
        <Row>
          <TextInput
            required
            type='number'
            name={['items', index, 'unitPrice', 'float']}
            label={intl.formatMessage({
              id: 'invoices.price'
            })}
            value={item.unitPrice.float}
            onBlur={onPriceBlur}
            onChange={(name, value) => {
              setInvoiceEditFormValue(
                ['items', index, 'unitPrice', 'float'],
                value
              )
            }}
            validate={validateAddInvoice}
          />
          <GtuSelectContainer>
            <InputTitle required>VAT</InputTitle>
            <Select
              options={TAX_RATES_OPTIONS}
              name='taxRate'
              value={item.taxRate}
              onChange={e => {
                setInvoiceEditFormValue(
                  ['items', index, 'taxRate'],
                  e.target.value
                )
              }}
            />
          </GtuSelectContainer>
          {invoicesLength > 1 && (
            <RemoveIcon onClick={() => removeItem(index)} />
          )}
        </Row>
      </Column>
    </Container>
  )
}

export default connectDictionaries(MobileInvoiceItemRow)

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
  margin-bottom: 16px;
  ${breakpoint.m`
    display: none;
  `}
`

const Column = styled.div`
  flex: 1;
`
const Row = styled.div`
  display: flex;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
  align-items: flex-start;
`

const RemoveIcon = styled(RemoveIconUrl)`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  cursor: pointer;
  align-self: center;
  color: ${({ theme }) => theme.colors.white.hex()};
  background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
  border-radius: 2px;
  margin-top: 6px;
`

const GtuSelectContainer = styled.div`
  flex: 0.7;
`

const QtyContainer = styled.div`
  flex: 0.5;
`
