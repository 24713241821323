import React from 'react'
import styled from 'styled-components'
import { TableHeader } from 'src/features/contracts/components/atoms'
import { HeaderItem } from 'src/features/myOffers/components/atoms'
import { breakpoint } from 'src/theme/grid'
import {
  INVOICE_ITEMS_TABLE_HEADERS,
  INVOICE_ITEMS_TABLE_NAMES,
  INVOICE_ITEMS_TABLE_WIDTHS
} from 'src/features/invoices/duck/consts'

export const ItemsTable = ({
  renderItem = () => {},
  items = [],
  renderHeader = () => {}
}) => (
  <Items>
    <InvoiceItemHeader
      widths={INVOICE_ITEMS_TABLE_WIDTHS}
      columnNames={INVOICE_ITEMS_TABLE_NAMES}
    >
      {INVOICE_ITEMS_TABLE_HEADERS &&
        INVOICE_ITEMS_TABLE_HEADERS.map(renderHeader)}
    </InvoiceItemHeader>
    {items.length > 0 && items.map(renderItem)}
  </Items>
)

const InvoiceItemHeader = styled(TableHeader)`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  border-bottom: none;
  text-transform: none;
  &&& > ${HeaderItem} {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-bottom: none;
    &:hover {
      border-bottom: none;
    }
  }
  ${breakpoint.m`
    border-bottom: none;
  `}
`
const Items = styled.div`
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 1.2rem;
  ${breakpoint.m`
    background-color: inherit;
    margin-left: inherit;
    margin-right: inherit;
    padding-left: 0;
    padding-right: 0;
    padding-top: inherit;
    border-bottom: solid 1px ${({ theme }) => theme.colors.ghost.hex()};
  `}
`
