import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import {
  CancelButton,
  RemoveButton,
  RemoveOfferPromptButton
} from 'src/components/atoms/Buttons'
import { DeleteMultiple } from 'src/features/myOffers/components/atoms'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'

export default ({ selectedOffersCount, onClick }) => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToDeleteAnyOffer = hasPermissionTo(
    PERMISSIONS.OFFER_DELETE_ANY,
    permissions
  )

  return (
    <>
      <DeleteMultiple>
        <span>
          <b>{`${selectedOffersCount} `}</b>
          <FormattedMessage id='myOffers.offersSelected' />
        </span>
        <RemoveButton active onClick={() => setDialogOpen(true)} />
      </DeleteMultiple>
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <FormattedMessage id='offerDetails.changeHandlerWarningTitle' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {hasPermissionToDeleteAnyOffer && (
              <FormattedMessage
                id='myOffers.deleteOffersWarning'
                values={{
                  count: selectedOffersCount
                }}
              />
            )}
            {!hasPermissionToDeleteAnyOffer && (
              <FormattedMessage
                id='myOffers.deleteOffersWarningOnlyOwn'
                values={{
                  count: selectedOffersCount
                }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton
            active
            onClick={() => {
              setDialogOpen(false)
            }}
          >
            {intl.formatMessage({ id: 'common.no' })}
          </CancelButton>
          <RemoveOfferPromptButton active onClick={onClick} />
        </DialogActions>
      </Dialog>
    </>
  )
}
