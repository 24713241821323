import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'

export const FormNavigationHeader = styled.span`
  display: inline-block;
  font-weight: 800;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  padding-left: 1.2rem;
  max-width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${breakpoint.xxs`
    max-width: unset;
  `}
`
