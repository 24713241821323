import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl, Link, navigate } from 'gatsby-plugin-intl'
import { propEq } from 'ramda'
import qs from 'qs'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'

import StockHeader from 'src/features/stocks/components/StockHeader'
import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import {
  createOfferDetailsLink,
  MENU_ITEMS
} from 'src/features/dashboard/duck/consts'
import { Padding } from 'src/features/myOffers/components/atoms'
import { SectionBox } from 'src/features/account/components/atoms'
import Timeline from 'src/features/stocks/components/OfferDetails/Timeline'
import {
  ContainerInfo,
  ContainerSemiTrailerInfo,
  CustomsClearance,
  Notes,
  Price
} from 'src/features/stocks/components/OfferDetails/atoms'
import { Driver } from 'src/features/tracking/components/atoms'
import { Description, Title } from 'src/features/stocks/components/typography'
import { ButtonDark, SaveOfferButton } from 'src/components/atoms/Buttons'
import ShowMore from 'src/features/tracking/components/ShowMore'
import TrackingStatusAlert from 'src/features/tracking/components/TrackingStatusAlert'
import Alert from 'src/components/Alert'
import AttachFileUrl from 'src/assets/icons/attach-file.svg'
import { ellipsisInTheMiddle } from 'src/utils/helpers'
import { breakpoint } from 'src/theme/grid'
import TrackingCurrentInfoBoxWithMap from 'src/features/tracking/components/TrackingCurrentInfoBoxWithMap'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import { API_STATES } from 'src/ducks/consts'
import EditIcon from 'src/assets/icons/edit.svg'
import XIcon from 'src/assets/icons/close-x.svg'
import googlePlayUrl from 'src/assets/google-play-badge.png'
import appStoreUrl from 'src/assets/app-store-badge.svg'
import connect from './duck/connectors'

const TrackingDetails = ({
  className,
  getTrackingItem,
  trackingItem,
  timelineData,
  generateDriverCredentials,
  pinCodeState,
  prepareOrder,
  currentUserId
}) => {
  const isPinCodeLoading = pinCodeState === API_STATES.IN_PROGRESS
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    getTrackingItem(params.id)
  }, [])

  const intl = useIntl()

  const hasContainer =
    trackingItem.offer.container && trackingItem.offer.container.name
  const hasContainerSemiTrailer =
    trackingItem.offer.containerSemiTrailer &&
    trackingItem.offer.containerSemiTrailer.name
  const hasPrice =
    trackingItem.offer.price && trackingItem.offer.price.float > 0
  const hasCustomsClearance = trackingItem.customsClearanceLocation.id

  const hasTransportationOrder = trackingItem.transportationOrderId
  const onTransportationOrderClick = hasTransportationOrder
    ? () =>
        navigate(
          `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${trackingItem.transportationOrderId}`
        )
    : () =>
        trackingItem.canCreateTransportationOrder &&
        prepareOrder(trackingItem.id, STOCKS_TYPES.TRACKING)
  const showOrdersLink =
    hasTransportationOrder || trackingItem.canCreateTransportationOrder
  const isCurrentUserForwarder = propEq(
    'forwarderUserId',
    currentUserId
  )(trackingItem)

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              <FormattedMessage id='tracking.tracking' />
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Padding>
        <BreadCrumbs
          links={[
            {
              name: intl.formatMessage({ id: 'forms.dashboard' }),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
            },
            {
              name: intl.formatMessage({ id: 'tracking.tracking' }),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING}`
            },
            {
              name: (
                <FormattedMessage
                  id='tracking.trackingPreview'
                  values={{ id: trackingItem.referenceNumber }}
                />
              ),
              url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${trackingItem.id}`
            }
          ]}
        />
      </Padding>
      <Row>
        <LeftColumn>
          <HeadingContainer>
            <Heading>
              <FormattedMessage id='tracking.trackingDetails' />{' '}
              <OfferLink
                to={createOfferDetailsLink(trackingItem.offer.referenceNumber)}
              >
                {trackingItem.offer.referenceNumber}
              </OfferLink>
            </Heading>
            {trackingItem.isEditable && (
              <EditLink
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_EDIT}&id=${trackingItem.id}`}
              >
                <EditIcon />
              </EditLink>
            )}
          </HeadingContainer>
          <AlertsRow>
            <TrackingStatusAlert status={trackingItem.status} />
            {showOrdersLink && (
              <OrdersLink
                onClick={onTransportationOrderClick}
                title={<FormattedMessage id='tracking.transportationOrder' />}
                message={
                  hasTransportationOrder ? (
                    <FormattedMessage id='tracking.goToTransportationOrder' />
                  ) : (
                    <FormattedMessage id='tracking.createTransportationOrder' />
                  )
                }
                type='white'
                icon={<AttacheFileIcon />}
              />
            )}
          </AlertsRow>
          {timelineData && <Timeline data={timelineData} />}
          {trackingItem.notes && <Notes offerDetails={trackingItem} />}
          {(hasContainer || hasContainerSemiTrailer) && (
            <ShowMore>
              {hasContainer && (
                <ContainerInfo offerDetails={trackingItem.offer} />
              )}
              {hasContainerSemiTrailer && (
                <ContainerSemiTrailerInfo offerDetails={trackingItem.offer} />
              )}
              {hasPrice && <Price offerDetails={trackingItem.offer} />}
              {hasCustomsClearance && (
                <CustomsClearance offerDetails={trackingItem} />
              )}
            </ShowMore>
          )}
          <Separator />
          {(trackingItem.driverName ||
            trackingItem.vehicleRegistrationPlate) && (
            <Driver
              driver={{
                name: trackingItem.driverName,
                phoneNumber: trackingItem.driverPhoneNumber,
                email: trackingItem.driverEmail,
                identityCardNumber: trackingItem.driverIdentityCardNumber,
                vehicleRegistrationPlate: trackingItem.vehicleRegistrationPlate
              }}
            />
          )}
          {trackingItem.transportationId && (
            <>
              <HeadingTwo>
                <FormattedMessage id='tracking.driverAccessData' />
              </HeadingTwo>
              <DriverAccessData>
                {isPinCodeLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <TransportationId>
                      <Title>
                        <FormattedMessage id='tracking.transportationId' />
                      </Title>
                      <Description>{trackingItem.transportationId}</Description>
                    </TransportationId>
                    <div>
                      <Title>
                        <FormattedMessage id='tracking.pin' />
                      </Title>
                      <Description>{trackingItem.pinCode}</Description>
                    </div>
                  </>
                )}
                {trackingItem.canGenerateDriversAppCredentials && (
                  <>
                    <ButtonContainer>
                      <ButtonDark active onClick={() => setIsModalOpen(true)}>
                        <FormattedMessage id='tracking.generateAgain' />
                      </ButtonDark>
                    </ButtonContainer>
                    <Dialog
                      open={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      PaperProps={{
                        style: { borderRadius: 9 }
                      }}
                    >
                      <DialogContent>
                        <CloseIcon onClick={() => setIsModalOpen(false)} />
                        <DialogHeader>
                          <FormattedMessage id='tracking.generateDriverCredentialsDialogTitle' />
                        </DialogHeader>
                        <DialogText>
                          <FormattedMessage id='tracking.generateDriverCredentialsDialogContent' />
                        </DialogText>
                        <ActionBar>
                          <CancelButton onClick={() => setIsModalOpen(false)}>
                            <FormattedMessage id='common.cancel' />
                          </CancelButton>
                          <SaveOfferButton
                            active
                            onClick={() => {
                              generateDriverCredentials(trackingItem.id)
                              setIsModalOpen(false)
                            }}
                          >
                            <FormattedMessage id='tracking.generateAgain' />
                          </SaveOfferButton>
                        </ActionBar>
                      </DialogContent>
                    </Dialog>
                  </>
                )}
              </DriverAccessData>
              <StoreBadges>
                <a
                  href='https://play.google.com/store/apps/details?id=pl.econtainers.driver'
                  target='_blank'
                >
                  <img alt='pobierz z Google Play' src={googlePlayUrl} />
                </a>
                <a
                  href='https://apps.apple.com/us/app/e-containers-eu-kierowca/id1556615822'
                  target='_blank'
                >
                  <img alt='pobierz z App Store' src={appStoreUrl} />
                </a>
              </StoreBadges>
            </>
          )}
          {trackingItem.files.length > 0 && (
            <>
              <div>
                <HeadingTwo>
                  <FormattedMessage id='tracking.attachedFiles' />{' '}
                </HeadingTwo>
              </div>
              <FilesList>
                {trackingItem.files.map(file => (
                  <File key={file.url}>
                    <AttachIcon>
                      <AttachFileUrl />
                    </AttachIcon>
                    <FileName>
                      <a href={file.url}>
                        {ellipsisInTheMiddle(file.filename, 40)}
                      </a>
                    </FileName>
                  </File>
                ))}
              </FilesList>
            </>
          )}
        </LeftColumn>
        <RightColumn>
          <TrackingCurrentInfoBoxWithMap
            trackingId={trackingItem.id}
            markers={trackingItem.trackingEvents}
            status={trackingItem.status}
            delay={trackingItem.reportedDelay}
            workTime={trackingItem.reportedWorkTime}
            hideWorkTime={isCurrentUserForwarder}
            originLocation={trackingItem.originLocation}
            destinationLocation={trackingItem.destinationLocation}
            offerType={trackingItem.offerType}
            originLocationLat={trackingItem.originLocationLat}
            originLocationLong={trackingItem.originLocationLong}
            destinationLocationLat={trackingItem.destinationLocationLat}
            destinationLocationLong={trackingItem.destinationLocationLong}
          />
        </RightColumn>
      </Row>
    </Page>
  )
}

const ConnectedComponent = connect(TrackingDetails)
export default styled(ConnectedComponent)``

const Heading = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`

const OfferLink = styled(Link)`
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const HeadingTwo = styled.h4`
  font-weight: 600;
`

const DriverAccessData = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  border-radius: 8px;
  padding: 1rem;
  display: flex;
`

const ButtonContainer = styled.div`
  margin-left: auto;
  padding-left: 0.5rem;
`

const TransportationId = styled.div`
  margin-right: 2rem;
`

const AttachFileIconComponent = ({ className }) => (
  <div className={className}>
    <AttachFileUrl />
  </div>
)

const AttacheFileIcon = styled(AttachFileIconComponent)`
  background-color: ${({ theme }) => theme.colors.apple.alpha(0.2).hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black.alpha(0.8).hex()};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const AlertsRow = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint.m`
      flex-direction: row;
  `}
`

const OrdersLink = styled(Alert)`
  display: flex;
  flex: 1;
  cursor: pointer;
`

const Separator = styled.hr`
  opacity: 0.3;
  border-top: solid 1px ${({ theme }) => theme.colors.geyser.hex()};
`
const File = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  width: 100%;
`

const AttachIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.geyser.hex()};
  border-radius: 4px;
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.colors.white.hex()};
  margin-right: 0.5rem;
`

const FileName = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & span {
    font-size: 0.75rem;
    opacity: 0.7;
  }
`
const FilesList = styled.ul`
  width: 100%;
  margin-top: 0.5rem;
  ${breakpoint.m`
    margin-top: 0;
  `}
`

const Row = styled(SectionBox)`
  display: flex;
  flex-direction: column;
  ${breakpoint.m`
      flex-direction: row;
  `}
`
const LeftColumn = styled.div`
  flex: 1;
  ${breakpoint.m`
  margin-right: 2rem;
  `}
  & > div {
    margin: 0.5rem 0;
  }
`

const RightColumn = styled.div`
  flex: 1;
`

const EditLink = styled(Link)`
  opacity: 0.7;
  margin-left: auto;
`

const HeadingContainer = styled.div`
  display: flex;
`

const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    width: 600px;
    padding: 1.6rem 2.2rem;
  `}
`

const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  margin: 0;
  width: 100%;
  display: inline-block;
`

const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin: 0;
  `}
`

const CancelButton = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
  margin-right: 0.8rem;
  align-items: center;
  ${breakpoint.m`
    margin-right: 2.2rem;
  `}
`
const StoreBadges = styled.div`
  display: flex;
  align-items: center;
  img {
    width: auto;
    max-height: 58px;
  }
`
