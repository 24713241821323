import React from 'react'
import styled from 'styled-components'
import { isNil } from 'ramda'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { Description, Title } from 'src/features/stocks/components/typography'
import { breakpoint } from 'src/theme/grid'
import CustomIcon from 'src/components/CustomIcon'

export const Driver = ({
  driver: {
    name,
    phoneNumber,
    email,
    identityCardNumber,
    vehicleRegistrationPlate
  }
}) => (
  <div>
    <Title>
      <FormattedMessage id='tracking.driverAndVehicle' />
    </Title>
    <Description>
      <b>{name}</b>
    </Description>
    <Text>
      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
    </Text>
    <Text>
      <a href={`mailto:${email}`}>{email}</a>
    </Text>
    <Text>{identityCardNumber}</Text>
    {vehicleRegistrationPlate && (
      <PlateContainer>
        <Plate>
          <EUFlag />
          <PlateNumber>{vehicleRegistrationPlate}</PlateNumber>
        </Plate>
      </PlateContainer>
    )}
  </div>
)

const Text = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  opacity: 0.87;
  & a {
    color: ${({ theme }) => theme.colors.black.hex()};
    &:hover {
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
    }
  }
`

const PlateNumber = styled.div`
  padding: 0 0.5rem;
  display: inline-block;
  font-weight: 600;
`

const EUFlag = styled.div`
  width: 12px;
  height: 22px;
  background-color: #2d56b7;
  position: relative;
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 6px;
    left: 1px;
    border: 1px dotted yellow;
    border-radius: 50%;
  }
`

const Plate = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #384661;
  background-color: ${({ theme }) => theme.colors.white.hex()};
`

const PlateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const NativeSelect = styled.select`
  -webkit-appearance: none;
  outline: none;
  height: 25px;
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  padding-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  opacity: 0.7;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  ${breakpoint.m`
      display: none;
  `}
`

const EventTitle = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.california.hex() : theme.colors.ebonyClay.hex()};
    position: absolute;
    top: 7px;
    left: -29px;
  }
`

const EventCoordinates = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  font-size: 0.83rem;
  margin-bottom: 0.4rem;
  width: 100%;
`

const EventTime = styled(EventCoordinates)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.6).hex()};
`

const AttachmentRow = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-top: 0.6rem;
`

const PhotoTitle = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-size: 0.86rem;
  margin-left: 0.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
`

const CustomIconStyled = styled(CustomIcon)`
  margin-left: -3px;
`

const TextBody = styled.span`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  line-height: 1.56;
`

const ContentBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.3rem 0.6rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
  border-radius: 4px;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
`

const ContentTitle = styled.span`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.5).hex()};
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
`

const Delay = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
  font-size: 0.9rem;
  font-weight: 600;
  ${CustomIconStyled} {
    margin-right: 0.3rem;
  }
`

const TrackingEventItemDetailsComponent = ({
  className,
  title,
  comment,
  delay,
  time,
  files,
  selected,
  handleSelected,
  event
}) => {
  const intl = useIntl()
  return (
    <div className={className} onClick={() => handleSelected(event)}>
      <EventTitle selected={selected}>{title}</EventTitle>
      <EventTime>{time}</EventTime>
      {files.length > 0 &&
        files.map(f => (
          <AttachmentRow href={f.url}>
            <CustomIconStyled name='photo' size={24} />
            <PhotoTitle>{f.filename}</PhotoTitle>
          </AttachmentRow>
        ))}
      {!isNil(delay) && (
        <Delay>
          <CustomIconStyled name='wait' size={24} />
          {`${
            Math.floor(delay / 60) > 0 ? `${Math.floor(delay / 60)}h ` : ''
          }${delay % 60} min`}
        </Delay>
      )}
      {!isNil(comment) && (
        <ContentBodyWrapper>
          <ContentTitle>
            {intl.formatMessage({ id: 'tracking.comment' })}
          </ContentTitle>
          <TextBody>{comment}</TextBody>
        </ContentBodyWrapper>
      )}
    </div>
  )
}

export const TrackingEventItemDetails = styled(
  TrackingEventItemDetailsComponent
)`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.6rem 1.6rem 2rem;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    width: 2px;
    height: calc(100% - 30px);
    background-color: #f0f2f6;
    position: absolute;
    top: 25px;
    left: 6px;
  }
`
