import { object, string } from 'yup'
import { validate, validateSchema } from 'src/ducks/schema'

const debtCollectionFormSchema = object().shape({
  additionalInfo: string()
    .required()
    .nullable()
})

export const validateDebtCollectionForm = validate(debtCollectionFormSchema)
export const validateDebtCollectionFormSchema = validateSchema(
  debtCollectionFormSchema
)
