import React from 'react'
import styled, { css } from 'styled-components'
import {
  BigInputContainer,
  InputRow
} from 'src/features/orders/components/atoms'
import { Title } from 'src/features/account/components/typography'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Recipient } from 'src/features/invoices/components/TopSection/styles'
import { TextTitle } from 'src/features/invoices/InvoiceDetails'

export const IssuerDetails = ({ invoice, showLabel }) => (
  <InputRow>
    <InputContainer showLabel={showLabel}>
      {showLabel && (
        <TextTitle>
          <FormattedMessage id='invoices.seller' />
        </TextTitle>
      )}
      <Recipient>
        <Title>{invoice.issuerCompanyName}</Title>
        <p>{invoice.issuerCompanyAddress}</p>
        <p>
          {invoice.issuerCompanyPostalCode} {invoice.issuerCompanyCity}
          {invoice.issuerCompanyCity &&
            invoice.issuerCompanyCountryCode &&
            ', '}
          {invoice.issuerCompanyCity &&
            invoice.issuerCompanyCountryCode?.toUpperCase()}
        </p>
        {invoice.issuerCompanyTaxId && (
          <p>
            <FormattedMessage id='common.nip' />: {invoice.issuerCompanyTaxId}
          </p>
        )}
        {invoice.issuerCompanyEmail && <p>{invoice.issuerCompanyEmail}</p>}
      </Recipient>
    </InputContainer>
  </InputRow>
)

const InputContainer = styled(BigInputContainer)`
  ${Recipient} {
    ${({ showLabel }) =>
      showLabel &&
      css`
        margin-top: 4px;
      `}
  }
`
