import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { includes, pathOr } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import { with100vh } from 'src/hooks/use100vh'
import MUIArrow from '@material-ui/icons/KeyboardArrowRight'
import { breakpoint } from 'src/theme/grid'

import useLocalStorage from 'src/hooks/useLocalStorage'
import PermissionsContext, { hasPermissionTo } from 'src/utils/permissions'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import routes from 'src/utils/routes'
import LogoSygnetUrl from 'src/assets/logo-sygnet.png'
import LogoFullUrl from 'src/assets/logo-dark.png'
import CustomIcon from 'src/components/CustomIcon'

export default ({
  className,
  activeTab,
  setActiveTab,
  url,
  menu,
  opened,
  onClose,
  showBackButton,
  ...rest
}) => {
  const permissions = useContext(PermissionsContext)
  const [collapsed, setCollapsed] = useLocalStorage(
    'panel-navigation-collapsed',
    false
  )

  return (
    <ExternalWrapper collapsed={collapsed} opened={opened}>
      <ToggleWrapper onClick={() => setCollapsed(!collapsed)}>
        <ToggleCollapse collapsed={collapsed} />
      </ToggleWrapper>
      <Navigation collapsed={collapsed}>
        <NavigationWrapper collapsed={collapsed}>
          <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`}>
            <Logo collapsed={collapsed} />
          </Link>
          <ContentWrapper>
            <ListWrapper>
              {menu.map((item, index) => {
                const allowed = isFalsy(item.permission)
                  ? true
                  : hasPermissionTo(item.permission, permissions)
                return (
                  allowed && (
                    <LinkWrapper
                      collapsed={collapsed}
                      spacer={includes(
                        activeTab,
                        pathOr([], ['submenu', 0, 'showWhen'], item)
                      )}
                      key={item.value}
                    >
                      <Link
                        key={`link-${index}`}
                        to={`${url}?tab=${item.value}`}
                        onClick={() => {
                          setActiveTab(item.value)
                          !item.submenu && onClose()
                        }}
                      >
                        <ListItem
                          spacer={item.spacer}
                          key={index}
                          collapsed={collapsed}
                          active={item.activeWhen.includes(activeTab)}
                        >
                          <IconWrapper>{item.icon}</IconWrapper>
                          <ElementName><FormattedMessage id={item.name} /></ElementName>
                          {!isFalsy(rest[item.type]) && (
                            <Number collapsed={collapsed}>
                              {rest[item.type]}
                            </Number>
                          )}
                        </ListItem>
                      </Link>
                      {item.submenu &&
                        item.submenu.map(
                          submenuItem =>
                            submenuItem.showWhen.includes(activeTab) && (
                              <Link
                                key={`sublink-${index}-${submenuItem.value}`}
                                to={`${url}?tab=${submenuItem.value}`}
                                onClick={() => {
                                  setActiveTab(submenuItem.value)
                                  onClose()
                                }}
                              >
                                <SubMenuListItem
                                  hasIcon={submenuItem.icon}
                                  key={index}
                                  collapsed={collapsed}
                                  active={submenuItem.activeWhen.includes(
                                    activeTab
                                  )}
                                >
                                  {submenuItem.icon && (
                                    <IconWrapper>
                                      {submenuItem.icon}
                                    </IconWrapper>
                                  )}
                                  <ElementName><FormattedMessage id={submenuItem.name} /></ElementName>
                                </SubMenuListItem>
                              </Link>
                            )
                        )}
                    </LinkWrapper>
                  )
                )
              })}
            </ListWrapper>
          </ContentWrapper>
          {showBackButton && <StyledBackToDashboard collapsed={collapsed} />}
        </NavigationWrapper>
      </Navigation>
    </ExternalWrapper>
  )
}

const ExternalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 0;
  transition: width 0.3s;
  z-index: 100;
  ${({ opened }) =>
    opened &&
    css`
      width: calc(${({ theme }) => theme.dimensions.mainDrawerWidth} - 40px);
      ${breakpoint.xs`
        width: ${({ theme }) => theme.dimensions.mainDrawerWidth};
      `}
    `}
  ${breakpoint.m`
    width: ${({ theme }) => theme.dimensions.mainDrawerWidth};
  `}
  ${({ collapsed }) =>
    collapsed &&
    css`
      ${breakpoint.m`
        width: 68px;
      `}
    `}
`

const Navigation = with100vh(styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  box-shadow: 0 3px 10px 0 rgba(50, 54, 58, 0.26);
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  transition: width 0.3s;
  ${breakpoint.m`
    top: 0;
    z-index: unset;
    position: relative;
  `}
  ${({ collapsed }) =>
    collapsed &&
    css`
      ${LinkWrapper} {
        ${breakpoint.m`
          &:after, &:before {
            width: 40px;
            left: 14px;
          }
      `}
      }
    `}
`)

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  width: calc(${({ theme }) => theme.dimensions.mainDrawerWidth} - 40px);
  ${breakpoint.xs`
    width: ${({ theme }) => theme.dimensions.mainDrawerWidth};
  `}
  ${({ collapsed }) =>
    collapsed &&
    css`
      ${breakpoint.m`
      width: 68px;
    `}
    `}
`

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`

const ListWrapper = styled.div`
  width: 100%;
  padding-top: 1.6rem;
  box-sizing: border-box;
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  ${({ spacer, collapsed }) =>
    spacer &&
    css`
      margin-bottom: 1rem;
      &:after {
        content: '';
        display: ${collapsed ? 'inline-block' : 'none'};
        width: 276px;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.ghost.alpha(1).hex()};
        position: absolute;
        bottom: 0;
        opacity: 0.3;
        left: 12px;
      }
      &:before {
        content: '';
        display: ${collapsed ? 'inline-block' : 'none'};
        width: 276px;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.ghost.alpha(1).hex()};
        position: absolute;
        top: -0.7rem;
        opacity: 0.3;
        left: 12px;
      }
    `}
`
const ElementName = styled.span`
  //max-width: 150px;
`

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 20;
`

const Number = styled.span`
  display: flex;
  position: absolute;
  right: 14px;
  color: ${({ theme }) => theme.colors.apple.hex()};
  font-weight: 800;
  ${({ collapsed }) =>
    collapsed &&
    `
      display: none;
    `}
`

export const ListItem = styled.span`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.alabaster.hex()};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;
  cursor: pointer;
  transition: opacity 0.3s;
  padding: 0 0.4rem 1.5rem 1.4rem;
  position: relative;
  opacity: 0.7;
  ${ElementName} {
    margin-left: 1.2rem;
    margin-right: 3rem;
  }
  &:hover {
    opacity: 1;
  }
  ${({ active }) =>
    active &&
    css`
      color: white;
      opacity: 1;
      ${IconWrapper} {
        &:after {
          content: '';
          position: absolute;
          z-index: 10;
          width: 40px;
          height: 40px;
          background-color: ${({ theme }) => theme.colors.apple.hex()};
          border-radius: 1px;
        }
      }
    `}
  ${({ collapsed }) =>
    collapsed &&
    css`
      ${breakpoint.m`
        padding: 0 0 1.5rem 1.4rem;
        ${ElementName} {
          display: none;
        }
      `}
    `}
`

export const SubMenuListItem = styled(ListItem)`
  padding-bottom: 1rem;
  ${ElementName} {
    font-size: 0.87rem;
    margin-left: calc(1.2rem + 24px);
    padding-left: 0.5rem;
    ${({ active }) =>
      active &&
      css`
        border-left: 3px solid ${({ theme }) => theme.colors.apple.hex()};
      `}
  }
  ${IconWrapper} {
    display: none;
    ${breakpoint.m`
      display: ${({ collapsed }) => (!collapsed ? 'none' : 'inline-flex')};
    `}
  }
  ${({ hasIcon, collapsed }) =>
    !hasIcon &&
    collapsed &&
    css`
      ${breakpoint.m`
      display: none;
    `}
    `}
`

const BackToDashboard = ({ className, collapsed }) => (
  <div className={className}>
    <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`}>
      <ListItem collapsed={collapsed} active={true}>
        <IconWrapper>
          <CustomIcon name='left' size={34} />
        </IconWrapper>
        <ElementName>
          <FormattedMessage id='account.backToDashboard' />
        </ElementName>
      </ListItem>
    </Link>
  </div>
)

const StyledBackToDashboard = styled(BackToDashboard)`
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  & span {
    font-weight: 600;
  }
  ${ListItem} {
    padding: 1rem;
  }
`

export const Chip = styled.span`
  display: inline-block;
  padding: 0.2rem 0.6rem;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 800;
  border-radius: 20px;
  min-width: 40px;
  text-align: center;
`

const ToggleWrapper = styled.div`
  position: absolute;
  right: -24px;
  top: 80px;
  padding: 12px;
  z-index: 1002;
  cursor: pointer;
  display: none;
  ${breakpoint.m`
    display: block;
  `}
`

const ToggleCollapse = styled(MUIArrow)`
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
  box-shadow: 0 3px 5px 0 rgba(50, 54, 58, 0.1);
  ${({ collapsed }) =>
    !collapsed &&
    `
      transform: rotate(180deg);
  `}
`

const Logo = styled.div`
  background-color: ${({ theme }) => theme.colors.mirage.hex()};
  height: ${({ theme }) => theme.dimensions.topBarHeight};
  width: 100%;
  position: relative;
  box-shadow: -6px 2px 10px 0 #202737;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 30px;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    background-image: url(${LogoFullUrl});
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    ${({ collapsed }) =>
      collapsed &&
      `
      background-image: url(${LogoSygnetUrl});
    `}
  }
`
