import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Avatar } from 'src/components/atoms/Avatar'
import { breakpoint } from 'src/theme/grid'
import React from 'react'
import { OrderSubtitle } from 'src/features/orders/components/atoms'

const CompanyComponent = ({ company, className }) => (
  <div className={className}>
    <p>
      <b>{company.name}</b>
    </p>
    <p>{company.address}</p>
    <p>
      {company.postalCode} {company.city}
      {', '}
      {company.countryCode}
    </p>
    <p>{company.phoneNumber}</p>
  </div>
)

const Company = styled(CompanyComponent)`
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.71;
  flex: 1;
`

const UserComponent = ({ user, className }) => (
  <div className={className}>
    <OrderSubtitle>
      <FormattedMessage id='offerDetails.contactPerson' />
    </OrderSubtitle>
    <Row>
      <Avatar url={user.avatar && user.avatar.url} />
      <UserName>
        <Name>{`${user.firstName} ${user.lastName}`}</Name>
        <PhoneNumber>{user.phoneNumber} </PhoneNumber>
      </UserName>
    </Row>
  </div>
)

const User = styled(UserComponent)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const UserName = styled.div`
  margin-left: 1rem;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.71;
`

const Row = styled.div`
  display: flex;
  ${Avatar} {
    margin-top: 0.4rem;
  }
`

const Name = styled.p`
  font-weight: 600;
`
const PhoneNumber = styled.p``

const ContactRowComponent = ({ user, className }) => (
  <div className={className}>
    <Company company={user.company} />
    <User user={user} />
  </div>
)

export const ContactRow = styled(ContactRowComponent)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
  ${breakpoint.m`
    flex-direction: row;
  `}
`
