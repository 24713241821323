import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { LinkPlaceholder } from 'src/components/Typography'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import { Page } from 'src/components/Wrappers'
import { AddOfferButton } from 'src/components/atoms/Buttons'
import { FreeCarriersOfferDetails } from './components/OfferDetails'
import FreeCarriersOffersList from './components/lists/FreeCarriersOffersList'
import Drawer from './components/Drawer'
import { connectFreeCarriers } from './ducks/connectors'
import StockHeader from './components/StockHeader.jsx'
import { ListWrapper, ContentWrapper } from './components/atoms'
import FreeCarriersFilters from './components/filters/FreeCarriers'

const StockFreeCarriers = ({
  className,
  getOffers,
  setDrawerState,
  isDrawerOpen
}) => {
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToAddOffer = hasPermissionTo(
    PERMISSIONS.STOCK_FREE_CARRIERS_ADD_OFFER,
    permissions
  )

  useEffect(() => {
    getOffers()
    return () => {
      setDrawerState(false)
    }
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader withDropdown>
              {intl.formatMessage({ id: 'offers.vehiclesExchangeTitle' })}
            </StockHeader>
            {hasPermissionToAddOffer && (
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_FREE_CARRIERS}`}
              >
                <AddOfferButton active />
              </Link>
            )}
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <ContentWrapper>
        <ListWrapper>
          <FreeCarriersFilters />
          <FreeCarriersOffersList trigger={isDrawerOpen} />
        </ListWrapper>
        <Drawer open={isDrawerOpen} onClose={() => setDrawerState(false)}>
          <FreeCarriersOfferDetails />
        </Drawer>
      </ContentWrapper>
    </Page>
  )
}

const StyledStockFreeCarriers = styled(StockFreeCarriers)`
  ${LinkPlaceholder} {
    margin-bottom: 1.2rem;
  }
`

export default connectFreeCarriers(StyledStockFreeCarriers)
