import { useState, useEffect } from 'react'
import { isFalsy } from 'ramda-adjunct'
import qs from 'qs'

export default (tab, search) => {
  const [activeTab, setActiveTab] = useState(tab)
  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    !isFalsy(params.tab) && setActiveTab(params.tab)
  }, [search])

  return [activeTab, setActiveTab]
}
