import {
  allPass,
  complement,
  filter,
  flatten,
  includes,
  map,
  pipe,
  propEq,
  propOr,
  propSatisfies
} from 'ramda'
import { MENU_LIST } from 'src/features/dashboard/duck/consts'

export const getSubmenuItemsForActiveTab = activeTab =>
  pipe(
    map(propOr([], 'submenu')),
    map(
      filter(
        allPass([
          propSatisfies(includes(activeTab), 'showWhen'),
          complement(propEq('value', activeTab))
        ])
      )
    ),
    flatten
  )(MENU_LIST)
