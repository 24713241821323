import React, { useState, useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { isEmptyString } from 'ramda-adjunct'
import { useIntl } from 'gatsby-plugin-intl'
import debounce from 'lodash.debounce'

import { breakpoint } from 'src/theme/grid'

import useClickOutside from 'src/hooks/useClickOutside'
import {
  SelectInput,
  SearchInput,
  SearchInputWrapper,
  SelectOptionsWrapper
} from 'src/components/filters/components/atoms'
import { connectSearchRecipient } from 'src/features/invoices/duck/connectors'
import MUIBack from '@material-ui/icons/KeyboardArrowLeft'
import Loader from 'src/components/LinearLoader'

const PlaceBox = ({
  className,
  id,
  label,
  placeholder,
  onSearch,
  required,
  helperText,
  pristine,
  validate,
  searchHintText,
  emptyResultsText = '',
  predictions,
  renderPrediction,
  name,
  value,
  onClear,
  clearRecipientSearch,
  isLoading
}) => {
  const intl = useIntl()
  const [searchText, setSearchText] = useState('')
  const [filterOpened, setFilterOpened] = useState(false)
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })
  const toggleDropdown = () => setFilterOpened(!filterOpened)
  const componentRef = useClickOutside(() => setFilterOpened(false))
  const emptyText = isEmptyString(searchText) || searchText.length < 3

  const debouncedSearch = useCallback(
    debounce(text => onSearch(text), 200),
    []
  )

  useEffect(() => {
    if (!searchText || emptyText) {
      clearRecipientSearch()
      return
    }
    debouncedSearch(searchText)
  }, [searchText])

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(name, value, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [value, inputPristine, pristine])

  const handleClose = () => {
    _setPristine(false)
    setFilterOpened(false)
  }

  const handleClear = () => {
    onClear()
    handleClose()
  }

  return (
    <div className={className} ref={componentRef}>
      <Label required={required}>{label}</Label>
      <SelectInput
        onClick={toggleDropdown}
        opened={filterOpened}
        id={`filter-${id}`}
        selectedNumber={null}
        hasValue={!!value}
        onClear={handleClear}
      >
        {value || placeholder}
      </SelectInput>
      {filterOpened && (
        <FilterDropdownStyled
          filterName={label}
          onClose={handleClose}
          onClear={handleClear}
          clearButtonText={intl.formatMessage({ id: 'offersFilters.clear' })}
          confirmButtonText={intl.formatMessage({
            id: 'offersFilters.confirm'
          })}
        >
          <LoaderStyled loading={isLoading} />
          <SearchInputWrapper>
            <SearchInput
              name={name}
              id={`input-${id}`}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder={placeholder}
              showClearIcon
              onClear={() => {
                setSearchText('')
              }}
            />
          </SearchInputWrapper>
          <SelectOptionsWrapperStyled>
            {emptyText && <SearchHint>{searchHintText}</SearchHint>}
            {!emptyText &&
              predictions.map(pred => renderPrediction(pred, handleClose))}
            {!emptyText && predictions.length === 0 && (
              <SearchHint>{emptyResultsText}</SearchHint>
            )}
          </SelectOptionsWrapperStyled>
        </FilterDropdownStyled>
      )}
      <HelperText valid={valid}>{valid ? helperText : error}</HelperText>
    </div>
  )
}

PlaceBox.defaultProps = {
  value: '',
  pristine: true,
  validate: () => true
}

const StyledPlaceBox = styled(PlaceBox)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export default connectSearchRecipient(StyledPlaceBox)

const SelectOptionsWrapperStyled = styled(SelectOptionsWrapper)`
  margin-top: 8px;
  ${breakpoint.m`
    height: 245px;
    `}
`

const Label = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`

const HelperText = styled.span`
  display: inline-block;
  width: 100%;
  margin: 8px 6px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1em;
  color: ${({ theme, valid }) =>
    valid ? theme.colors.ghost.hex() : theme.colors.error.hex()};
`

const SearchHint = styled.span`
  display: flex;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;
`
const FilterDropdownComponent = ({
  className,
  filterName,
  onClose,
  children
}) => (
  <div className={className}>
    <FilterName>
      <BackIcon onClick={onClose} />
      {filterName}
    </FilterName>
    <FilterContent>{children}</FilterContent>
  </div>
)

FilterDropdownComponent.defaultProps = {
  onClose: () => {},
  onClear: () => {}
}

export const FilterDropdown = styled(FilterDropdownComponent)`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  ${breakpoint.m`
    border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: inherit;
    height: 340px;
    z-index: 20;
    position: absolute;
    top: 40px;
    left: 0;
    ${({ alignRight }) =>
      alignRight &&
      css`
        left: auto;
        right: 0;
      `}
  `}
`

const FilterDropdownStyled = styled(FilterDropdown)`
  ${breakpoint.m`
    top: 70px;
  `}
`

const FilterName = styled.span`
  display: flex;
  width: 100%;
  font-weight: 800;
  font-size: 0.95rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  align-items: center;
  padding: 0.6rem 0.8rem;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${breakpoint.m`
    font-size: 1.1rem;
  `}
`

const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
`

const BackIcon = styled(MUIBack)`
  && {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.black.hex()};
  }
  margin-right: 0.6rem;
  ${breakpoint.m`
    display: none;
  `}
`

const LoaderStyled = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
`
