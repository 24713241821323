import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Bold, Date, Title } from 'src/features/stocks/components/typography'

const TimeLine = ({ className, data }) => (
  <div className={className}>
    {data.map(
      (stage, index) =>
        stage &&
        stage.location?.name && (
          <React.Fragment key={stage.location.name + index}>
            <Title>
              <FormattedMessage id={`offerDetails.${stage.title}`} />
            </Title>
            <DateRow>
              <LocationColumn>
                <Bold>
                  {stage.locationCity
                    ? stage.locationCity
                    : // COUNTRY_CODES : `${stage.location.countryCode}, ${stage.location.name}`}
                      stage.location.name}
                </Bold>
                {stage.companyName && (
                  <DetailedAddress>{stage.companyName}</DetailedAddress>
                )}
                {stage.address && (
                  <DetailedAddress>{stage.address}</DetailedAddress>
                )}
              </LocationColumn>
              <Date>
                {stage.date && stage.date}
                {stage.time && `, ${stage.time}`}
              </Date>
            </DateRow>
          </React.Fragment>
        )
    )}
  </div>
)

const DateRow = styled.div`
  border-left: 1px solid
    ${({ theme }) => theme.colors.ebonyClay.alpha(0.2).hex()};
  padding-left: 11px;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:last-child {
    border: none;
    min-height: auto;
  }
`

const LocationColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.71;
`

const DetailedAddress = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  &:last-child {
    margin-bottom: 1.25rem;
  }
`

export default styled(TimeLine)`
  ${Title} {
    position: relative;
    padding-left: 11px;
    &:before {
      content: '';
      height: 7px;
      width: 7px;
      background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
      border-radius: 50%;
      display: block;
      position: absolute;
      left: -3.5px;
      top: 6.5px;
    }
  }
`
