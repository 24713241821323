import { connect } from 'react-redux'

import {
  setOfferFormImportValueRoutine,
  setOfferFormExportValueRoutine,
  setOfferFormPostImportValueRoutine,
  setOfferFormFreeCarriersValueRoutine,
  createExportOfferRoutine,
  createImportOfferRoutine,
  createFreeCarriersOfferRoutine,
  createPostImportOfferRoutine,
  clearImportOfferFormRoutine,
  clearExportOfferFormRoutine,
  clearPostImportOfferFormRoutine,
  clearFreeCarriersOfferFormRoutine,
  getExportMyCompanyLastOffersRoutine,
  getImportMyCompanyLastOffersRoutine,
  getFreeCarriersMyCompanyLastOffersRoutine,
  getPostImportMyCompanyLastOffersRoutine,
  getExportOfferTemplatesRoutine,
  getImportOfferTemplatesRoutine,
  getFreeCarriersOfferTemplatesRoutine,
  getPostImportOfferTemplatesRoutine,
  fillFormUsingTemplateExportRoutine,
  fillFormUsingTemplateImportRoutine,
  fillFormUsingTemplateFreeCarriersRoutine,
  fillFormUsingTemplatePostImportRoutine,
  removeTemplateExportRoutine,
  removeTemplateImportRoutine,
  removeTemplateFreeCarriersRoutine,
  removeTemplatePostImportRoutine,
  createBulkExportOffersRoutine,
  createBulkImportOffersRoutine,
  createBulkFreeCarriersOffersRoutine,
  createBulkPostImportOffersRoutine,
  createAndDuplicateExportOfferRoutine,
  createAndDuplicateImportOfferRoutine,
  createAndDuplicateFreeCarriersOfferRoutine,
  createAndDuplicatePostImportOfferRoutine,
  createAndStartNewExportOfferRoutine,
  createAndStartNewImportOfferRoutine,
  createAndStartNewFreeCarriersOfferRoutine,
  createAndStartNewPostImportOfferRoutine,
  createContractOfferRoutine,
  setOfferFormContractValueRoutine,
  clearContractOfferRoutine
} from './actions'

import {
  selectOfferFormImportData,
  selectOfferFormExportData,
  selectOfferFormPostImportData,
  selectOfferFormFreeCarriersData,
  selectMyCompanyExportLastOffers,
  selectMyCompanyImportLastOffers,
  selectMyCompanyFreeCarriersLastOffers,
  selectMyCompanyPostImportLastOffers,
  selectOfferTemplatesExport,
  selectOfferTemplatesImport,
  selectOfferTemplatesFreeCarriers,
  selectOfferTemplatesPostImport,
  selectOfferFormContractData
} from './selectors'

const mapStateToProps = state => ({
  offerFormImport: selectOfferFormImportData(state),
  offerFormExport: selectOfferFormExportData(state),
  offerFormPostImport: selectOfferFormPostImportData(state),
  offerFormFreeCarriers: selectOfferFormFreeCarriersData(state),
  offerFormContracts: selectOfferFormContractData(state),

  lastOffersExport: selectMyCompanyExportLastOffers(state),
  lastOffersImport: selectMyCompanyImportLastOffers(state),
  lastOffersFreeCarriers: selectMyCompanyFreeCarriersLastOffers(state),
  lastOffersPostImport: selectMyCompanyPostImportLastOffers(state),

  offerTemplatesExport: selectOfferTemplatesExport(state),
  offerTemplatesImport: selectOfferTemplatesImport(state),
  offerTemplatesFreeCarriers: selectOfferTemplatesFreeCarriers(state),
  offerTemplatesPostImport: selectOfferTemplatesPostImport(state)
})

const mapDispatchToProps = dispatch => ({
  setOfferFormImportValue: (name, value) =>
    dispatch(setOfferFormImportValueRoutine({ name, value })),
  setOfferFormExportValue: (name, value) =>
    dispatch(setOfferFormExportValueRoutine({ name, value })),
  setOfferFormPostImportValue: (name, value) =>
    dispatch(setOfferFormPostImportValueRoutine({ name, value })),
  setOfferFormFreeCarriersValue: (name, value) =>
    dispatch(setOfferFormFreeCarriersValueRoutine({ name, value })),
  setOfferFormContractValue: (name, value) =>
    dispatch(setOfferFormContractValueRoutine({ name, value })),

  createExportOffer: () => dispatch(createExportOfferRoutine()),
  createImportOffer: () => dispatch(createImportOfferRoutine()),
  createFreeCarriersOffer: () => dispatch(createFreeCarriersOfferRoutine()),
  createPostImportOffer: () => dispatch(createPostImportOfferRoutine()),
  createContractOffer: () => dispatch(createContractOfferRoutine()),

  createBulkExportOffers: number =>
    dispatch(createBulkExportOffersRoutine({ number })),
  createBulkImportOffers: number =>
    dispatch(createBulkImportOffersRoutine({ number })),
  createBulkFreeCarriersOffers: number =>
    dispatch(createBulkFreeCarriersOffersRoutine({ number })),
  createBulkPostImportOffers: number =>
    dispatch(createBulkPostImportOffersRoutine({ number })),

  createAndDuplicateExportOffer: () =>
    dispatch(createAndDuplicateExportOfferRoutine()),
  createAndDuplicateImportOffer: () =>
    dispatch(createAndDuplicateImportOfferRoutine()),
  createAndDuplicateFreeCarriersOffer: () =>
    dispatch(createAndDuplicateFreeCarriersOfferRoutine()),
  createAndDuplicatePostImportOffer: () =>
    dispatch(createAndDuplicatePostImportOfferRoutine()),

  createAndStartNewExportOffer: () =>
    dispatch(createAndStartNewExportOfferRoutine()),
  createAndStartNewImportOffer: () =>
    dispatch(createAndStartNewImportOfferRoutine()),
  createAndStartNewFreeCarriersOffer: () =>
    dispatch(createAndStartNewFreeCarriersOfferRoutine()),
  createAndStartNewPostImportOffer: () =>
    dispatch(createAndStartNewPostImportOfferRoutine()),

  clearImportOfferForm: () => dispatch(clearImportOfferFormRoutine()),
  clearExportOfferForm: () => dispatch(clearExportOfferFormRoutine()),
  clearPostImportOfferForm: () => dispatch(clearPostImportOfferFormRoutine()),
  clearFreeCarriersOfferForm: () =>
    dispatch(clearFreeCarriersOfferFormRoutine()),
  clearContractOffer: () => dispatch(clearContractOfferRoutine()),

  getExportMyCompanyLastOffers: () =>
    dispatch(getExportMyCompanyLastOffersRoutine()),
  getImportMyCompanyLastOffers: () =>
    dispatch(getImportMyCompanyLastOffersRoutine()),
  getFreeCarriersMyCompanyLastOffers: () =>
    dispatch(getFreeCarriersMyCompanyLastOffersRoutine()),
  getPostImportMyCompanyLastOffers: () =>
    dispatch(getPostImportMyCompanyLastOffersRoutine()),

  getExportOfferTemplates: () => dispatch(getExportOfferTemplatesRoutine()),
  getImportOfferTemplates: () => dispatch(getImportOfferTemplatesRoutine()),
  getFreeCarriersOfferTemplates: () =>
    dispatch(getFreeCarriersOfferTemplatesRoutine()),
  getPostImportOfferTemplates: () =>
    dispatch(getPostImportOfferTemplatesRoutine()),

  fillFormUsingTemplateExport: template =>
    dispatch(fillFormUsingTemplateExportRoutine({ template })),
  fillFormUsingTemplateImport: template =>
    dispatch(fillFormUsingTemplateImportRoutine({ template })),
  fillFormUsingTemplateFreeCarriers: template =>
    dispatch(fillFormUsingTemplateFreeCarriersRoutine({ template })),
  fillFormUsingTemplatePostImport: template =>
    dispatch(fillFormUsingTemplatePostImportRoutine({ template })),

  removeTemplateExport: id => dispatch(removeTemplateExportRoutine({ id })),
  removeTemplateImport: id => dispatch(removeTemplateImportRoutine({ id })),
  removeTemplateFreeCarriers: id =>
    dispatch(removeTemplateFreeCarriersRoutine({ id })),
  removeTemplatePostImport: id =>
    dispatch(removeTemplatePostImportRoutine({ id }))
})

export default connect(mapStateToProps, mapDispatchToProps)
