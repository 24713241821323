import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import ThumbUpIcon from 'src/assets/icons/thumb-up.svg'
import ThumbDownIcon from 'src/assets/icons/thumb-down.svg'
import DeleteIcon from 'src/assets/icons/delete.svg'
import useClickOutside from 'src/hooks/useClickOutside'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import connect from '../duck/connectors'

const MarkCompany = ({
  className,
  removeCompanyFromBlacklist,
  removeCompanyFromWhitelist,
  addCompanyToBlacklist,
  addCompanyToWhitelist,
  isBlacklisted,
  isWhitelisted,
  companyId
}) => {
  const [open, setOpen] = useState(false)
  const componentRef = useClickOutside(() => setOpen(false))
  const isNotMarked = !isBlacklisted && !isWhitelisted
  const permissions = useContext(PermissionsContext)
  const hasPermissionToManageWhitelist = hasPermissionTo(
    PERMISSIONS.COMPANY_MANAGE_WHITELIST,
    permissions
  )
  const hasPermissionToManageBlacklist = hasPermissionTo(
    PERMISSIONS.COMPANY_MANAGE_BLACKLIST,
    permissions
  )

  const hasAtLeastOnePermissionToManageList =
    hasPermissionToManageWhitelist || hasPermissionToManageBlacklist

  const onButtonClick = e => {
    e.stopPropagation()
    if (hasAtLeastOnePermissionToManageList) {
      setOpen(!open)
    }
  }

  const onTrustClick = () => {
    setOpen(!open)
    if (hasPermissionToManageWhitelist) {
      isWhitelisted
        ? removeCompanyFromWhitelist(companyId)
        : addCompanyToWhitelist(companyId)
    }
  }
  const onBlockClick = () => {
    setOpen(!open)
    if (hasPermissionToManageBlacklist) {
      isBlacklisted
        ? removeCompanyFromBlacklist(companyId)
        : addCompanyToBlacklist(companyId)
    }
  }

  return (
    <div className={className} ref={componentRef}>
      <MarkCompanyAsButton
        isWhitelisted={isWhitelisted}
        isBlacklisted={isBlacklisted}
        isNotMarked={isNotMarked}
        open={open}
        onClick={onButtonClick}
        disabled={!hasAtLeastOnePermissionToManageList}
        hasAtLeastOnePermissionToManageList={
          hasAtLeastOnePermissionToManageList
        }
      >
        {isNotMarked && (
          <>
            <FormattedMessage id='companies.markAs' />
            <MUIDropdownIcon
              width={24}
              height={24}
              style={{
                transform: `rotate(${open ? '180deg' : '0'})`,
                marginLeft: '0.5rem'
              }}
            />
          </>
        )}
        {isWhitelisted && (
          <>
            <p>
              <FormattedMessage id='companies.trusted' />
            </p>
            <ThumbUpIcon />
          </>
        )}
        {isBlacklisted && (
          <>
            <p>
              <FormattedMessage id='companies.blocked' />
            </p>
            <ThumbDownIcon />
          </>
        )}
      </MarkCompanyAsButton>
      <Dropdown open={open}>
        <DropDownItem
          disabled={!hasPermissionToManageWhitelist}
          onClick={e => {
            e.stopPropagation()
            onTrustClick()
          }}
        >
          {!isWhitelisted && (
            <>
              <ThumbUpIcon />
              <FormattedMessage id='companies.trusted' />
            </>
          )}
          {isWhitelisted && (
            <>
              <DeleteIcon />
              <FormattedMessage id='companies.removeFromWhitelist' />
            </>
          )}
        </DropDownItem>
        <DropDownItem
          disabled={!hasPermissionToManageBlacklist}
          onClick={e => {
            e.stopPropagation()
            onBlockClick()
          }}
        >
          {!isBlacklisted && (
            <>
              <ThumbDownIcon />
              <FormattedMessage id='companies.blocked' />
            </>
          )}
          {isBlacklisted && (
            <>
              <DeleteIcon />
              <FormattedMessage id='companies.removeFromBlacklist' />
            </>
          )}
        </DropDownItem>
      </Dropdown>
    </div>
  )
}

const StyledMarkCompany = styled(MarkCompany)`
  position: relative;
`

export default connect(StyledMarkCompany)

const MarkCompanyAsButton = styled.button`
  border: none;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  padding: 0.5rem;
  background-color:  ${({ theme }) => theme.colors.lightGrey.hex()};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease-in, background-color 0.2s ease-in; 
  ${breakpoint.m`
  padding: 0.5rem 0.8rem 0.5rem 1rem;
  `}
  & > p {
  margin-right: 1rem;
  display: none;
    ${breakpoint.m`
    display: block;
  `}
  }
  ${({ open }) =>
    open &&
    css`
      background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
      color: ${({ theme }) => theme.colors.white.hex()};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
  ${({ isNotMarked }) =>
    isNotMarked &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
        color: ${({ theme }) => theme.colors.white.hex()};
      }
    `}
  ${({ isWhitelisted }) =>
    isWhitelisted &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
      color: ${({ theme }) => theme.colors.apple.hex()};
      &:hover {
        background-color: ${({ theme }) => theme.colors.apple.hex()};
        color: ${({ theme }) => theme.colors.white.hex()};
      }
    `}

  ${({ isBlacklisted }) =>
    isBlacklisted &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.fadedRed.hex()};
      color: ${({ theme }) => theme.colors.fadedRed.hex()};
      &:hover {
        background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
        color: ${({ theme }) => theme.colors.white.hex()};
      }
    `}
  ${({ hasAtLeastOnePermissionToManageList, isNotMarked }) =>
    !hasAtLeastOnePermissionToManageList &&
    isNotMarked &&
    css`
      display: none;
    `} 
`

const DropDownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  padding: 0.5rem 2.375rem 0.5rem 1rem;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ghost.alpha(0.2).hex()};
  }
  & > svg {
    flex-shrink: 0;
    opacity: 0.7;
    margin-right: 1rem;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none !important;
      opacity: 0.7;
      cursor: not-allowed;
    `}
`

const Dropdown = styled.div`
  display: none;
  right: 0;
  padding: 0.5rem 0;
  position: absolute;
  top: calc(100% + 4px);
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid rgb(198, 203, 212);
  box-shadow: 0 12px 32px -8px rgba(36, 45, 63, 0.24);
  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
`
