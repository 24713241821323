import React from 'react'
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import Pagination from 'src/components/Pagination'

import {
  BottomRow,
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  RefreshIcon,
  Row
} from 'src/features/stocks/components/atoms'

import {
  ActionGroup,
  HeaderItem,
  Padding
} from 'src/features/myOffers/components/atoms'

import LoadingList from 'src/components/atoms/LoadingList'

import Shade from 'src/components/Shade'

import { isStockLoading } from 'src/utils/stocks'

import { Icon, SectionBox } from 'src/features/account/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { TableHeader } from 'src/features/contracts/components/atoms'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { BOOLEAN } from 'src/ducks/consts'
import TrackingRowOngoing from 'src/features/tracking/components/TrackingRowOngoing'
import {
  TRACKING_ONGOING_STATUS_FILTER_OPTIONS,
  TRACKING_TABLE_HEADERS,
  TRACKING_TABLE_NAMES,
  TRACKING_TABLE_WIDTHS
} from 'src/features/tracking/duck/consts'
import { connectTrackingOngoing } from 'src/features/tracking/duck/connectors'
import Select from 'src/components/filters/SelectBox'
import {
  FilterDropdown,
  SelectOptionsWrapper
} from 'src/components/filters/components/atoms'
import * as routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { NativeSelect } from 'src/features/tracking/components/atoms'

const OffersList = ({
  offers,
  pagination,
  filters,
  setFilter,
  hidePagination = false,
  getOngoingOffers,
  isCurrentCompanyCarrierForwarder,
  className,
  ongoingOffers
}) => {
  const headers = TRACKING_TABLE_HEADERS
  const intl = useIntl()

  const handleOfferClick = id => {
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${id}`
    )
  }

  const toggleFilter = name => {
    setFilter(name, !filters[name])
  }

  const renderListContents = () => {
    if (isStockLoading(ongoingOffers, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (offers.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='offers.noOffers' />
        </EmptyOffers>
      )
    }

    if (offers.length > 0) {
      return offers.map(offer => (
        <TrackingRowOngoing
          offer={offer}
          handleClick={handleOfferClick}
          key={offer.id}
        />
      ))
    }
  }

  return (
    <div className={className}>
      <Padding></Padding>
      <ListTopRow>
        <CounterGroup>
          <Counter>
            <span>{`${pagination.total} `}</span>
            <FormattedMessage
              id='tracking.records'
              values={{
                count: pagination.total
              }}
            />
          </Counter>
          <RefreshIcon onClick={getOngoingOffers} />
        </CounterGroup>
        <ActionGroup>
          {isCurrentCompanyCarrierForwarder && (
            <Checkbox
              name='hideCompanyTransportations'
              value={filters.hideCompanyTransportations}
              onChange={toggleFilter}
              option={{
                text: intl.formatMessage({
                  id: 'tracking.hideCompanyTransportations'
                }),
                value: BOOLEAN.YES
              }}
            />
          )}
          <SelectWrapper>
            <Select
              removeSelectedOptions
              removeSearchInput
              height='250px'
              id='filters-tracking-status'
              name='status'
              label={intl.formatMessage({ id: 'offersFilters.status' })}
              value={filters.status}
              options={TRACKING_ONGOING_STATUS_FILTER_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
              type='radio'
              alignRight
            />
          </SelectWrapper>
          <NativeSelect
            value={filters.status}
            onChange={e => {
              setFilter('status', e.target.value)
            }}
          >
            {TRACKING_ONGOING_STATUS_FILTER_OPTIONS.map(option => (
              <option key={option.label} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </NativeSelect>
        </ActionGroup>
      </ListTopRow>
      <Container>
        <TableHeader
          widths={TRACKING_TABLE_WIDTHS}
          columnNames={TRACKING_TABLE_NAMES}
        >
          {headers &&
            headers.map((header, index) => (
              <HeaderItem sort={false} key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage
                    id={`stocksHeaders.${header.name}`}
                    defaultMessage=' '
                  />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </HeaderItem>
            ))}
        </TableHeader>
        {renderListContents()}
      </Container>
      {offers.length > 0 && !hidePagination && (
        <BottomRow>
          <Pagination
            itemsPerPage={filters.limit}
            currentPage={pagination.currentPage}
            pagesCount={pagination.lastPage}
            setPage={page => setFilter('page', page)}
            setItemsPerPage={limit => {
              setFilter('limit', limit)
              setFilter('page', 1)
            }}
          />
        </BottomRow>
      )}
      <YTDiv>
        <iframe
          width='560'
          height='315'
          style={{ margin: '10px' }}
          src='https://www.youtube.com/embed/jJfRbLLMs5k'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        />
        <iframe
          width='560'
          height='315'
          style={{ margin: '10px' }}
          src='https://www.youtube.com/embed/wdC5Sl3Y4uU'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        />
      </YTDiv>
    </div>
  )
}

const connectedComponent = connectTrackingOngoing(OffersList)
export default styled(connectedComponent)`
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
export const ListTopRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1.2rem;
  ${Counter} {
    margin-right: 0.75rem;
    white-space: nowrap;
  }
  ${breakpoint.m`
     padding: 0;
  `}
`
const CounterGroup = styled.div`
  display: flex;
`

const SelectWrapper = styled.div`
  display: none;
  width: 100%;
  ${FilterDropdown} {
    ${breakpoint.m`
      height: 300px;
    `}
  }
  ${SelectOptionsWrapper} {
    height: 100% !important;
  }
  ${breakpoint.m`
      display: block;
  `}
`
const YTDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${breakpoint.xm`
  flex-direction: row;
`}
`
