import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, Link, useIntl, navigate } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { HeaderItem, Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroupWeb,
  ActionGroupMobile,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection
} from 'src/features/offers/components/atoms'
import { breakpoint } from 'src/theme/grid'
import Drawer from 'src/features/stocks/components/Drawer'
import {
  BigInputContainer,
  Heading
} from 'src/features/orders/components/atoms'

import { connectCorrectiveInvoiceDetails } from 'src/features/invoices/duck/connectors'
import { connectCompany } from 'src/features/account/duck/connectors'

import { Icon } from 'src/features/account/components/atoms'
import { Ellipsis } from 'src/features/stocks/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { Summary } from 'src/features/invoices/components/SummaryBox'
import { ItemsTable } from 'src/features/invoices/components/itemsTable/ItemsTable'
import PdfIcon from 'src/assets/icons/pdf.svg'
import NoteIconUrl from 'src/assets/icons/note.svg'
import { RecipientDetails } from 'src/features/invoices/components/TopSection/RecipientDetails'
import { CorrectedInvoiceNumber } from 'src/features/invoices/components/TopSection/CorrectedInvoiceNumber'
import { CauseOfInvoiceCorrection } from 'src/features/invoices/components/TopSection/CauseOfInvoiceCorrection'
import useUrlParams from 'src/hooks/useUrlParams'
import { API_STATES, DATE_FORMAT } from 'src/ducks/consts'
import moment from 'moment'
import { IssuerDetails } from 'src/features/invoices/components/TopSection/IssuerDetails'
import ItemRow from 'src/features/invoices/components/itemsTable/InvoiceItemRowPreview'
import MarkAsPaid from 'src/features/invoices/components/MarkAsPaid'
import { ButtonDark } from 'src/components/atoms/Buttons'
import Shade from 'src/components/Shade'
import {
  getInvoiceSaftCodes,
  getInvoiceStatus
} from 'src/features/invoices/duck/helpers'
import { SaftCodes } from 'src/features/invoices/components/SaftCodes'
import PaymentsList from 'src/features/invoices/components/PaymentsList'
import DeleteIcon from 'src/assets/icons/delete.svg'
import { INVOICES_STATES } from 'src/features/invoices/duck/consts'

const CorrectiveInvoiceDetails = ({
  className,
  invoice,
  companyId,
  showInvoiceDetails,
  downloadInvoicePdf,
  markAsPaid,
  clearInvoiceDetails,
  deletePayment
}) => {
  const intl = useIntl()
  const { id } = useUrlParams()
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false)
  useEffect(() => {
    id && showInvoiceDetails(id)
  }, [id])
  useEffect(() => () => clearInvoiceDetails(), [])

  const isLoading = invoice.state === API_STATES.IN_PROGRESS

  const status = getInvoiceStatus(invoice)

  const showMarkPaymentsButton =
    status !== INVOICES_STATES.FULLY_PAID &&
    moment(invoice.issueDate).isSameOrBefore(moment(), 'day')

  const saftCodes = getInvoiceSaftCodes(invoice.saft.toJS())

  const isInvoiceOwner = invoice.issuerCompanyId === companyId

  const invoicePaymentMethod = invoice.paymentMethod === 'transfer'
  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                <FormattedMessage id='invoices.correctionPreview' />
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          <FormSection>
            <FormInnerWrapper>
              {isLoading && <Shade />}
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'invoices.invoices' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`
                    },
                    {
                      name: `${intl.formatMessage({
                        id: 'invoices.invoice'
                      })} ${invoice.number}`,
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.id}`
                    }
                  ]}
                />
                <TitleContainer>
                  <Column>
                    <Heading>
                      <FormattedMessage id='invoices.correctiveInvoice' />{' '}
                      {invoice.number}
                      {invoice.transportationOrderId && (
                        <OrderLink>
                          {' '}
                          <FormattedMessage id='common.to' />{' '}
                          <Link
                            to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${invoice.transportationOrder.referenceNumber}`}
                          >
                            {invoice.transportationOrder.referenceNumber}
                          </Link>
                        </OrderLink>
                      )}
                    </Heading>
                    {invoice.isSplitPayment && (
                      <Subtitle>
                        <FormattedMessage id='invoices.isSplitPayment' />
                      </Subtitle>
                    )}
                  </Column>
                  <Column>
                    <TextTitle>
                      <FormattedMessage id='invoices.issuePlaceAndDate' />
                    </TextTitle>
                    <Text>
                      {`${invoice.issuePlace}, ${moment(
                        invoice.issueDate
                      ).format(DATE_FORMAT)}`}
                    </Text>
                  </Column>
                </TitleContainer>
                <Row style={{ marginBottom: '16px' }}>
                  <div style={{ flex: 1 }}>
                    <CorrectedInvoiceNumber invoice={invoice.invoice} />
                  </div>
                  <div style={{ flex: 1 }}>
                    <CauseOfInvoiceCorrection invoice={invoice} />
                  </div>
                </Row>
                <Row>
                  <div style={{ flex: 1 }}>
                    <IssuerDetails invoice={invoice.invoice} showLabel />
                  </div>
                  <div style={{ flex: 1 }}>
                    <RecipientDetails invoice={invoice.invoice} showLabel />
                  </div>
                </Row>
                <CenteredTitle>
                  <FormattedMessage id='invoices.invoiceItemsBeforeChanges' />
                </CenteredTitle>
                <ItemsTable
                  renderHeader={(header, index) => (
                    <HeaderItem sort={false} key={`${header.name} ${index}`}>
                      <Ellipsis>
                        <FormattedMessage
                          id={`invoices.${header.name}`}
                          defaultMessage=' '
                        />
                        {header.sort && (
                          <Icon>
                            <ArrowIcon />
                          </Icon>
                        )}
                      </Ellipsis>
                    </HeaderItem>
                  )}
                  items={invoice.invoice.items}
                  renderItem={(item, index) => (
                    <ItemRow
                      key={`${item.id}-${index}`}
                      item={item}
                      index={index}
                      invoicesLength={invoice.items.length}
                      invoice={invoice}
                    />
                  )}
                />
                <CenteredTitle>
                  <FormattedMessage id='invoices.invoiceItemsAfterChanges' />
                </CenteredTitle>
                <ItemsTable
                  renderHeader={(header, index) => (
                    <HeaderItem sort={false} key={`${header.name} ${index}`}>
                      <Ellipsis>
                        <FormattedMessage
                          id={`invoices.${header.name}`}
                          defaultMessage=' '
                        />
                        {header.sort && (
                          <Icon>
                            <ArrowIcon />
                          </Icon>
                        )}
                      </Ellipsis>
                    </HeaderItem>
                  )}
                  items={invoice.items}
                  renderItem={(item, index) => (
                    <ItemRow
                      key={`${item.id}-${index}`}
                      item={item}
                      index={index}
                      invoicesLength={invoice.items.length}
                      invoice={invoice.invoice}
                    />
                  )}
                />
                <SummaryContainer>
                  <div> </div>
                  <Summary
                    title={<FormattedMessage id='invoices.correctiveSummary' />}
                    subtotal={invoice.subtotal}
                    tax={invoice.tax}
                    total={invoice.total}
                  />
                </SummaryContainer>
                <Separator />
                <RowDesktop>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      {invoice.total.amount < 0 ? (
                        <FormattedMessage id='invoices.toRefund' />
                      ) : (
                        <FormattedMessage id='invoices.toPay' />
                      )}
                    </TextTitle>
                    <Text>{invoice.total.formatted}</Text>
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.paymentMethod' />
                    </TextTitle>
                    <Text>
                      {invoice.paymentMethod === 'transfer'
                        ? intl.formatMessage({ id: 'invoices.transfer' })
                        : intl.formatMessage({ id: 'invoices.cash' })}
                    </Text>
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.dueDate' />
                    </TextTitle>
                    <Text>{moment(invoice.dueDate).format(DATE_FORMAT)}</Text>
                  </Column>
                  {invoicePaymentMethod && (
                    <Column style={{ flex: 2 }}>
                      <TextTitle>
                        <FormattedMessage id='invoices.bankAccount' />
                      </TextTitle>
                      <Text>
                        {`${invoice.paymentBankAccount} (${invoice.paymentBankName})`}
                      </Text>
                    </Column>
                  )}
                </RowDesktop>
                <RowPaymentDetailsMobile>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.sellDate' />
                    </TextTitle>
                    <Text>{moment(invoice.sellDate).format(DATE_FORMAT)}</Text>
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.paymentMethod' />
                    </TextTitle>
                    <Text>
                      {invoice.paymentMethod === 'transfer'
                        ? intl.formatMessage({ id: 'invoices.transfer' })
                        : intl.formatMessage({ id: 'invoices.cash' })}
                    </Text>
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.dueDate' />
                    </TextTitle>
                    <Text>{moment(invoice.dueDate).format(DATE_FORMAT)}</Text>
                  </Column>
                </RowPaymentDetailsMobile>
                {invoicePaymentMethod && (
                  <RowBankAccountMobile>
                    <Column style={{ flex: 2 }}>
                      <TextTitle>
                        <FormattedMessage id='invoices.bankAccount' />
                      </TextTitle>
                      <Text>
                        {`${invoice.paymentBankAccount} (${invoice.paymentBankName})`}
                      </Text>
                    </Column>
                  </RowBankAccountMobile>
                )}
                <Separator />
                {saftCodes.length > 0 && <SaftCodes saftCodes={saftCodes} />}
                <Row>
                  <Column style={{ flex: 3 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.correctiveNotes' />
                    </TextTitle>
                    <Text>{invoice.additionalInfo || '-'}</Text>
                  </Column>
                  <Column style={{ flex: '2', marginLeft: '20px' }}>
                    <TextWrapper>
                      <TextTitle>
                        <FormattedMessage id='invoices.issuer' />
                      </TextTitle>
                      <Text>
                        {invoice.issuer.firstName} {invoice.issuer.lastName}
                      </Text>
                    </TextWrapper>
                  </Column>
                </Row>
                <Separator />
                <Row>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.partiallyPayments' />
                    </TextTitle>
                    <Row>
                      <Text style={{ marginRight: '40px' }}>
                        {invoice.total.amount < 0 ? (
                          <FormattedMessage id='invoices.toRefund' />
                        ) : (
                          <FormattedMessage id='invoices.toPay' />
                        )}
                        : {invoice.total.formatted}
                      </Text>
                      <Text>
                        <FormattedMessage id='invoices.left' />:{' '}
                        <b>{invoice.amountDue.formatted}</b>
                      </Text>
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <PaymentsList
                    payments={invoice.payments}
                    renderItem={payment => (
                      <li key={payment.id}>
                        <PaymentAmount>
                          <b>{payment.amount.formatted} </b>
                        </PaymentAmount>
                        <PaymentDate>
                          {payment.paidAt
                            ? moment(payment.paidAt).format(DATE_FORMAT)
                            : ''}
                        </PaymentDate>
                        {isInvoiceOwner && (
                          <DeletePayment
                            onClick={() =>
                              deletePayment({
                                invoiceId: invoice.id,
                                paymentId: payment.id
                              })
                            }
                          />
                        )}
                      </li>
                    )}
                  />
                </Row>
                <Separator />
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Center>
                <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`}>
                  {intl.formatMessage({ id: 'forms.cancel' })}
                </Link>
                <ActionGroupWeb>
                  <PdfButton
                    active={true}
                    onClick={() => downloadInvoicePdf(invoice.id)}
                  >
                    <PdfIcon />
                    <FormattedMessage id='orders.generatePdf' />
                  </PdfButton>
                  {invoice.canCreateCorrectiveNote && (
                    <PdfButton
                      active={true}
                      onClick={() =>
                        navigate(
                          `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_ADD_NOTE}&id=${invoice.id}`
                        )
                      }
                    >
                      <NoteIcon />
                      <FormattedMessage id='invoices.createCorrectionNote' />
                    </PdfButton>
                  )}
                  {isInvoiceOwner && showMarkPaymentsButton && (
                    <ButtonDark
                      active={true}
                      onClick={() => setPaymentDialogOpen(true)}
                    >
                      <FormattedMessage id='invoices.markPayment' />
                    </ButtonDark>
                  )}
                </ActionGroupWeb>
                <ActionGroupMobile>
                  <PdfButton
                    active={true}
                    onClick={() => downloadInvoicePdf(invoice.id)}
                  >
                    <PdfIcon />
                    <FormattedMessage id='orders.generatePdfOnMobile' />
                  </PdfButton>
                  {invoice.canCreateCorrectiveNote && (
                    <PdfButton
                      active={true}
                      onClick={() =>
                        navigate(
                          `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_ADD_NOTE}&id=${invoice.id}`
                        )
                      }
                    >
                      <NoteIcon />
                      <FormattedMessage id='invoices.createCorrectionNote' />
                    </PdfButton>
                  )}
                  {isInvoiceOwner && showMarkPaymentsButton && (
                    <ButtonDark
                      active={true}
                      onClick={() => setPaymentDialogOpen(true)}
                    >
                      <FormattedMessage id='invoices.markPaymentOnMobile' />
                    </ButtonDark>
                  )}
                </ActionGroupMobile>
              </Center>
            </FormActionBar>
          </FormSection>
        </Content>
      </Page>
      <MarkAsPaid
        onConfirm={markAsPaid}
        invoice={invoice}
        open={paymentDialogOpen}
        setOpen={setPaymentDialogOpen}
      />
    </>
  )
}

const ConnectedComponent = compose(
  connectCorrectiveInvoiceDetails,
  connectCompany
)(CorrectiveInvoiceDetails)

export default styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
    background-color: #f8f8f9;
  }
  ${Padding} {
    max-width: calc(887px + 2.8rem);
    margin: 0 auto;
  }

  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
  ${BigInputContainer} {
    ${breakpoint.m`
  max-width: 90%;
  `}
  }
`

const Center = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(887px + 2.8rem);
  margin: 0 auto;
`

const CenteredTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`

const SummaryContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${breakpoint.m`
    flex-direction: row;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 56px;
  width: 100%;
  padding-top: 2rem;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
  i {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
    margin-right: 16px;
  }
  ${breakpoint.m`
      padding-top: 0rem;
      flex-direction: row;
      align-items: flex-end;
  `}
`
const Row = styled.div`
  display: flex;
  flex-direction: column;
  & > div,
  p:not(:last-child) {
    margin-right: 0.5rem;
  }
  ${breakpoint.m`
    flex-direction: row;
  `}
`
const RowDesktop = styled(Row)`
  flex-direction: row;
  display: none;
  ${breakpoint.m`
    display: flex;
  `}
`
const RowPaymentDetailsMobile = styled(Row)`
  flex-direction: row;
  ${breakpoint.m`
    display: none;
  `}
`
const RowBankAccountMobile = styled(Row)`
  flex-direction: row;
  ${breakpoint.m`
    display: none;
  `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Subtitle = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 0.2rem;
`

const Text = styled.p`
  font-size: 0.875rem;
`
export const TextTitle = styled.h5`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-top: 1rem;
  ${breakpoint.m`
  margin-bottom: 0.5rem;
  margin-top: 0;
  `}
`

export const Separator = styled.hr`
  display: block;
  margin: 1.5rem 0;
  border: none;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
`

export const PdfButton = styled(ButtonDark)`
  padding: 0.5rem 0.6rem;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.black.hex()};
  background-color: ${({ theme }) => theme.colors.white.hex()};
  box-shadow: none;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  svg {
    margin-right: 0.5rem;
  }
`

const PaymentAmount = styled.span`
  display: inline-block;
  min-width: 120px;
  margin-right: 0.5rem;
`

const PaymentDate = styled.span`
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  margin-right: 1rem;
`

const DeletePayment = styled(DeleteIcon)`
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
  cursor: pointer;
  margin-left: auto;
  ${breakpoint.m`
  margin-left: 0;
  `}
`

const OrderLink = styled.span`
  font-size: 0.875rem;
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const NoteIcon = styled(NoteIconUrl)`
  opacity: 0.8;
`

const TextWrapper = styled.div`
  margin-left: -20px;
  ${breakpoint.m`
  margin-left: 0;
  `}
`
