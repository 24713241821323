import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker
} from 'react-google-maps'
import { last } from 'ramda'
import { compose, withProps } from 'recompose'
import MarkerStartPointIcon from 'src/assets/marker-start-point.svg'
import MarkerEndPointIcon from 'src/assets/marker-end-point.svg'
import MarkerActiveEventIcon from 'src/assets/marker-active-event-point.svg'
import MarkerEventIcon from 'src/assets/marker-event-point.svg'
import MarkerCurrentPositionIcon from 'src/assets/marker-current-position.svg'

import { TRANSPORT_EVENTS } from 'src/features/tracking/duck/consts'

const GoogleMapsComponent = ({
  events = [],
  originLocationLat,
  originLocationLong,
  destinationLocationLat,
  destinationLocationLong,
  eventSelected
}) => {
  const [mapZoom, setMapZoom] = useState(10)
  const mapRef = useRef(null)
  const point = last(events)

  useEffect(() => {
    const isMapDefined =
      mapRef && mapRef.current && window.google && window.google.maps
    const mapHasDefinedPoints =
      events.filter(e => e.lat !== 0 && e.long !== 0).length > 0

    if (isMapDefined && mapHasDefinedPoints) {
      const bounds = new window.google.maps.LatLngBounds()
      events.map(e => {
        if (e.lat !== 0 && e.long !== 0) {
          bounds.extend(new window.google.maps.LatLng(e.lat, e.long))
        }
      })
      mapRef.current.fitBounds(bounds)
    }
  }, [mapRef, events])

  useEffect(() => {
    const isMapLoaded =
      mapRef && mapRef.current && window.google && window.google.maps
    const isPointDefined =
      eventSelected !== null &&
      eventSelected.lat !== 0 &&
      eventSelected.long !== 0
    if (isMapLoaded && isPointDefined) {
      mapRef.current.panTo({ lat: eventSelected.lat, lng: eventSelected.long })
      setMapZoom(18)
    }
  }, [mapRef, eventSelected])

  return (
    <GoogleMap
      zoom={mapZoom}
      ref={mapRef}
      defaultZoom={mapZoom}
      defaultCenter={{
        lat: 54.517892,
        lng: 18.541038
      }}
    >
      {point && (
        <Marker
          icon={{ url: MarkerCurrentPositionIcon }}
          position={{
            lat: parseFloat(point.lat),
            lng: parseFloat(point.long)
          }}
        />
      )}{' '}
      <Polyline
        path={events
          .filter(e => e.lat !== 0 && e.long !== 0)
          .map(e => ({ lat: e.lat, lng: e.long }))}
        geodesic={true}
        options={{
          strokeColor: '#ffffff',
          strokeOpacity: 1.0,
          strokeWeight: 20
        }}
      />
      <Polyline
        path={events
          .filter(e => e.lat !== 0 && e.long !== 0)
          .map(e => ({ lat: e.lat, lng: e.long }))}
        geodesic={true}
        options={{
          strokeColor: '#13a2bf',
          strokeOpacity: 1.0,
          strokeWeight: 9
        }}
      />
      {events.map(
        event =>
          event.type !== TRANSPORT_EVENTS.IN_TRANSIT && (
            <Marker
              zIndex={
                eventSelected !== null && eventSelected.id === event.id
                  ? 10001
                  : 100
              }
              icon={{
                url:
                  eventSelected !== null && eventSelected.id === event.id
                    ? MarkerActiveEventIcon
                    : MarkerEventIcon
              }}
              position={{
                lat: parseFloat(event.lat),
                lng: parseFloat(event.long)
              }}
            />
          )
      )}
      {originLocationLat && originLocationLong && (
        <Marker
          icon={{ url: MarkerStartPointIcon }}
          position={{
            lat: parseFloat(originLocationLat),
            lng: parseFloat(originLocationLong)
          }}
        />
      )}
      {destinationLocationLat && destinationLocationLong && (
        <Marker
          icon={{ url: MarkerEndPointIcon }}
          position={{
            lat: parseFloat(destinationLocationLat),
            lng: parseFloat(destinationLocationLong)
          }}
        />
      )}
    </GoogleMap>
  )
}

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 320px;
`

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=geometry,places,drawing`,
    loadingElement: <MapContainer />,
    containerElement: <MapContainer />,
    mapElement: <MapContainer />
  }),
  withScriptjs,
  withGoogleMap
)(GoogleMapsComponent)
