import {
  string,
  object,
  addMethod,
  date,
  ref,
  boolean,
  number,
  mixed,
  array
} from 'yup'
import moment from 'moment'
import { isNil, values, map, prop } from 'ramda'
import { isEmptyString } from 'ramda-adjunct'

import { validate, validateSchema } from 'src/ducks/schema'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import { CONTRACTS_TYPES } from 'src/features/contracts/duck/consts'
import { VALID_FOR_OPTIONS } from 'src/features/stocks/ducks/consts'

addMethod(string, 'timeOrEmpty', function() {
  return this.test('timeOrEmpty', 'validation.timeOrEmpty', value => {
    //'__:__' default value for masked input
    const isEmpty = isNil(value) || isEmptyString(value) || value === '__:__'
    return isEmpty || moment(value, 'HH:mm', true).isValid()
  })
})

addMethod(string, 'time', function() {
  return this.test('time', 'validation.timeOrEmpty', value =>
    moment(value, 'HH:mm', true).isValid()
  )
})

addMethod(string, 'positiveIntegerOrEmpty', function() {
  return this.test('positiveIntegerOrEmpty', 'validation.wrongValue', value => {
    const isEmpty = isNil(value) || isEmptyString(value)
    return isEmpty || /^[1-9][0-9]*$/.test(value)
  })
})

const offerExportSchema = object().shape({
  type: string().required(),
  loadingDate: date()
    .nullable()
    .max(ref('returnDate'))
    .min(ref('auctionDeadlineDate'))
    .min(moment().startOf('day'))
    .required(),
  loadingTime: string()
    .timeOrEmpty()
    .nullable(),
  loadingLocationId: string()
    .nullable()
    .required(),
  returnDate: date()
    .nullable()
    .min(ref('loadingDate'))
    .min(moment().startOf('day'))
    .required(),
  returnTime: string()
    .timeOrEmpty()
    .nullable(),
  returnLocationId: string()
    .nullable()
    .required(),
  containerId: string()
    .nullable()
    .required(),
  cargoType: string().nullable(),
  weight: string()
    .positiveIntegerOrEmpty()
    .nullable(),
  shipownerId: string()
    .nullable()
    .required(),
  isCargoDangerous: string().nullable(),
  notes: string().nullable(),
  price: mixed().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: number()
      .positive()
      .integer()
      .required(),
    otherwise: string()
      .positiveIntegerOrEmpty()
      .nullable()
  }),
  customsClearanceLocationId: string().nullable(),
  templateNameOpened: boolean().nullable(),
  templateName: string().when('templateNameOpened', {
    is: v => v === true,
    then: string().required(),
    otherwise: string().nullable()
  }),
  auctionDeadlineDate: date().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: date()
      .max(ref('loadingDate'))
      .required(),
    otherwise: date().nullable()
  }),
  auctionDeadlineTime: string().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: string()
      .time()
      .required(),
    otherwise: string().nullable()
  }),
  // goPublicAt should be either null or in the future if given
  goPublicAt: date()
    .nullable()
    .test(
      'is-future',
      'goPublicAt must be in the future or null',
      value => value === null || value > new Date()
    )
})

const offerImportSchema = object().shape({
  pickupDate: date()
    .nullable()
    .min(moment().startOf('day'))
    .max(ref('unloadingDate'))
    .required(),
  pickupTime: string()
    .timeOrEmpty()
    .nullable(),
  pickupLocationId: string().required(),
  unloadingDate: date()
    .nullable()
    .min(ref('pickupDate'))
    .required(),
  unloadingTime: string()
    .timeOrEmpty()
    .nullable(),
  unloadingLocationId: string().required(),
  returnLocationId: string().nullable(),
  containerId: string().required(),
  cargoType: string().nullable(),
  weight: string()
    .positiveIntegerOrEmpty()
    .nullable(),
  shipownerId: string().required(),
  isCargoDangerous: string().nullable(),
  notes: string().nullable(),
  price: mixed().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: number()
      .positive()
      .integer()
      .required(),
    otherwise: string()
      .positiveIntegerOrEmpty()
      .nullable()
  }),
  customsClearanceLocationId: string().nullable(),
  templateNameOpened: boolean().nullable(),
  templateName: string().when('templateNameOpened', {
    is: v => v === true,
    then: string().required(),
    otherwise: string().nullable()
  }),
  auctionDeadlineDate: date().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: date()
      .max(ref('pickupDate'))
      .required(),
    otherwise: date().nullable()
  }),
  auctionDeadlineTime: string().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: string()
      .time()
      .required(),
    otherwise: string().nullable()
  }),
  goPublicAt: date()
    .nullable()
    .test(
      'is-future',
      'goPublicAt must be in the future or null',
      value => value === null || value > new Date()
    )
})

const offerPostImportSchema = object().shape({
  availabilityDate: date()
    .nullable()
    .min(moment().startOf('day'))
    .required(),
  availabilityTime: string()
    .timeOrEmpty()
    .nullable(),
  availabilityLocationId: string().required(),
  destinationLocationId: string().nullable(),
  containerId: string().required(),
  shipownerId: string().required(),
  isCargoDangerous: string().nullable(),
  notes: string().nullable(),
  templateNameOpened: boolean().nullable(),
  templateName: string().when('templateNameOpened', {
    is: v => v === true,
    then: string().required(),
    otherwise: string().nullable()
  }),
  price: mixed().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: number()
      .positive()
      .integer()
      .required(),
    otherwise: string()
      .positiveIntegerOrEmpty()
      .nullable()
  }),
  auctionDeadlineDate: date().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: date()
      .max(ref('availabilityDate'))
      .required(),
    otherwise: date().nullable()
  }),
  auctionDeadlineTime: string().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: string()
      .time()
      .required(),
    otherwise: string().nullable()
  }),
  goPublicAt: date()
    .nullable()
    .test(
      'is-future',
      'goPublicAt must be in the future or null',
      value => value === null || value > new Date()
    )
})

const offerFreeCarriersSchema = object().shape({
  availabilityDate: date()
    .nullable()
    .min(moment().startOf('day'))
    .required(),
  availabilityTime: string()
    .timeOrEmpty()
    .nullable(),
  availabilityLocationId: string().required(),
  destinationLocationId: string().nullable(),
  containerSemiTrailerId: string().required(),
  isSemiTrailerWithGenset: string().nullable(),
  isCargoDangerous: string().nullable(),
  notes: string().nullable(),
  templateNameOpened: boolean().nullable(),
  templateName: string().when('templateNameOpened', {
    is: v => v === true,
    then: string().required(),
    otherwise: string().nullable()
  }),
  price: mixed().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: number()
      .positive()
      .integer()
      .required(),
    otherwise: string()
      .positiveIntegerOrEmpty()
      .nullable()
  }),
  auctionDeadlineDate: date().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: date()
      .max(ref('availabilityDate'))
      .required(),
    otherwise: date().nullable()
  }),
  auctionDeadlineTime: string().when('type', {
    is: v => v === OFFER_TYPES.AUCTION,
    then: string()
      .time()
      .required(),
    otherwise: string().nullable()
  }),
  goPublicAt: date()
    .nullable()
    .test(
      'is-future',
      'goPublicAt must be in the future or null',
      value => value === null || value > new Date()
    )
})

const offerContractSchema = object().shape({
  type: string()
    .oneOf(values(CONTRACTS_TYPES))
    .required('validation.wrongValue'),
  startDate: date().required(),
  endDate: date().required(),
  validFor: mixed()
    .oneOf(map(prop('value'), VALID_FOR_OPTIONS), 'validation.required')
    .required(),
  conditions: string(),
  notes: string(),
  transportations: array().of(
    object().shape({
      containerId: string()
        .nullable()
        .required(),
      shipownerId: string()
        .nullable()
        .required(),
      originLocationId: string()
        .nullable()
        .required(),
      destinationLocationId: string()
        .nullable()
        .required(),
      cargoTypeId: string().nullable(),
      adrClassId: string().nullable(),
      europeanEmissionStandard: string().nullable(),
      numberOfContainers: string()
        .positiveIntegerOrEmpty()
        .nullable()
        .required(),
      weight: string()
        .positiveIntegerOrEmpty()
        .nullable(),
      customsClearanceLocationIdOpened: boolean(),
      returnLocationIdOpened: boolean(),
      returnLocationId: string().when('returnLocationIdOpened', {
        is: v => v === true,
        then: string().required(),
        otherwise: string().nullable()
      }),
      customsClearanceLocationId: string().when(
        'customsClearanceLocationIdOpened',
        {
          is: v => v === true,
          then: string().required(),
          otherwise: string().nullable()
        }
      )
    })
  )
})

//export
export const validateOfferExport = validate(offerExportSchema)
export const validateOfferExportSchema = validateSchema(offerExportSchema)

//import
export const validateOfferImport = validate(offerImportSchema)
export const validateOfferImportSchema = validateSchema(offerImportSchema)

//post-import
export const validateOfferPostImport = validate(offerPostImportSchema)
export const validateOfferPostImportSchema = validateSchema(
  offerPostImportSchema
)

//free-carriers
export const validateOfferFreeCarriers = validate(offerFreeCarriersSchema)
export const validateOfferFreeCarriersSchema = validateSchema(
  offerFreeCarriersSchema
)

//export
export const validateOfferContract = validate(offerContractSchema)
export const validateOfferContractSchema = validateSchema(offerContractSchema)
