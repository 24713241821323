import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import {
  getLabelFromSelectOptions,
  removePrefixFromGtuCodeValue
} from 'src/features/invoices/duck/helpers'
import { TAX_RATES_OPTIONS } from 'src/features/invoices/duck/consts'

const MobileInvoiceItemRowPreview = ({ item, index }) => (
  <Container>
    <CellTitle>{`${index + 1}`}.&nbsp;</CellTitle>
    <Column>
      <Row>
        <div>
          <CellTitle>
            <FormattedMessage id='invoices.name' />
          </CellTitle>
          <Cell value={item.description} />
        </div>
      </Row>
      <Row>
        <Column>
          <CellTitle>
            <FormattedMessage id='invoices.pkwiu' />
          </CellTitle>
          <Cell value={item.pkwiuCode} />
        </Column>
        <Column>
          <CellTitle>
            <FormattedMessage id='invoices.gtu' />
          </CellTitle>
          <Cell
            value={
              item.gtuCode ? removePrefixFromGtuCodeValue(item.gtuCode) : ''
            }
          />
        </Column>
        <Column>
          <CellTitle>
            <FormattedMessage id='invoices.qty' />
          </CellTitle>
          <Cell value={`${item.qty}`} />
        </Column>
        <Column>
          <CellTitle>
            <FormattedMessage id='invoices.unit' />
          </CellTitle>
          <Cell value={item.unit} />
        </Column>
      </Row>
      <Row>
        <Column>
          <CellTitle>
            <FormattedMessage id='invoices.price' />
          </CellTitle>
          <Cell value={item.unitPrice.formatted} />
        </Column>
        <Column>
          <CellTitle>
            <FormattedMessage id='invoices.tax' />
          </CellTitle>
          <Cell
            value={getLabelFromSelectOptions(item.taxRate, TAX_RATES_OPTIONS)}
          />
        </Column>
      </Row>
    </Column>
  </Container>
)

export default MobileInvoiceItemRowPreview

const Cell = ({ value, className }) => (
  <div className={className}>{value || '-'}</div>
)

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
  margin-bottom: 16px;
  ${breakpoint.m`
    display: none;
  `}
`

const Column = styled.div`
  flex: 1;
`
const Row = styled.div`
  display: flex;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const CellTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-bottom: 0.3rem;
`
