import React from 'react'
import styled from 'styled-components'
import MUISearch from '@material-ui/icons/Search'
import MUIClose from '@material-ui/icons/Close'

const SearchInputComponent = ({
  className,
  id,
  name,
  value,
  onChange,
  placeholder,
  onClear
}) => (
  <div className={className}>
    <SearchIcon />
    <Input
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
    <CloseIcon onClick={onClear} />
  </div>
)

export default styled(SearchInputComponent)`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  align-items: center;
  border-radius: 2px;
  border: solid 1px rgba(198, 203, 212, 0.3);
  background-color: rgba(198, 203, 212, 0.1);
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  position: relative;
`

const SearchIcon = styled(MUISearch)`
  font-size: 1.6rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.3;
  margin: 0 0.6rem;
`

const CloseIcon = styled(MUIClose)`
  font-size: 1.45rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.3;
  position: absolute;
  right: 0.4rem;
  transition: opacity 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

const Input = styled.input`
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  &::placeholder {
    display: inline-block;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  }
`
