import React from 'react'
import styled, { css } from 'styled-components'

const CheckBoxComponent = ({
  className,
  name,
  value,
  onChange,
  option,
  icon,
  ...rest
}) => {
  const handleChange = (n, v) => onChange(n, v)
  return (
    <div className={className}>
      <Checkbox
        selected={value === option.value}
        onClick={() => handleChange(name, option.value)}
        {...rest}
      />
      {option.icon}
      <div>
        <Name>{option.text}</Name>
        {option.description && <Description>{option.description}</Description>}
      </div>
    </div>
  )
}

const Checkbox = styled.span`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  margin-right: 0.6rem;
  position: relative;
  transition: background-color 0.3s;
  cursor: pointer;
  &:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
    border-radius: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ type }) =>
      type === 'radio' &&
      `
      border-radius: 50%;
    `}
  }
  &:after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    border-radius: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ selected }) =>
      selected &&
      css`
        background-color: ${({ theme }) => theme.colors.apple.hex()};
      `}
    ${({ type }) =>
      type === 'radio' &&
      `
      border-radius: 50%;
    `}
  }
  ${({ type }) =>
    type === 'radio' &&
    `
      border-radius: 50%;
    `}
  &:hover {
    background-color: ${({ theme }) => theme.colors.apple.alpha(0.1).hex()};
  }
`

export default styled(CheckBoxComponent)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0.45rem 0;
  & svg {
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    margin-right: 0.75rem;
  }
`

const Name = styled.span`
  display: inline-block;
  font-size: 0.95rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  line-height: 1.5;
`

const Description = styled.p`
  font-size: 0.75rem;
  text-transform: none;
`
