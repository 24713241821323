import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styled, { css } from 'styled-components'
import {
  StyledSelect,
  StyledTextField
} from 'src/features/stocks/components/atoms'
import MenuItem from '@material-ui/core/MenuItem'
import { ITEMS_PER_PAGE_VARIANTS } from 'src/ducks/consts'
import { breakpoint } from 'src/theme/grid'
import LocalStorageService from 'src/services/LocalStorageService'

const Pagination = ({
  className,
  currentPage,
  pagesCount,
  setPage,
  itemsPerPage,
  setItemsPerPage,
  startAndEndPagesToShow = 1,
  itemsPerPageVariants = ITEMS_PER_PAGE_VARIANTS
}) => {
  const shouldPageNumberBeShown = pageNumber =>
    pageNumber <= startAndEndPagesToShow ||
    pageNumber > pagesCount - startAndEndPagesToShow ||
    pageNumber === currentPage ||
    pageNumber === currentPage - 1 ||
    pageNumber === currentPage + 1

  const shouldPrevDotsBeShown = pageNumber => {
    const dotsPosition = pagesCount - (startAndEndPagesToShow - 1)
    const isGapBigEnough = currentPage < pagesCount - startAndEndPagesToShow - 1

    return isGapBigEnough && pageNumber === dotsPosition
  }

  const shouldNextDotsBeShown = pageNumber => {
    const dotsPosition = startAndEndPagesToShow
    const isGapBigEnough = currentPage > startAndEndPagesToShow + 2

    return isGapBigEnough && pageNumber === dotsPosition
  }

  const goToKeyPressHandler = event => {
    if (
      event.key === 'Enter' &&
      event.target.value > 0 &&
      event.target.value <= pagesCount
    ) {
      setPage(Number(event.target.value))
    }
  }

  return (
    <div className={className}>
      <ItemsPerPage>
        <Label>
          <FormattedMessage id='pagination.show' />
        </Label>
        <StyledSelect
          variant='outlined'
          value={itemsPerPage}
          onChange={({ target: { value } }) => {
            setItemsPerPage(value)
            LocalStorageService.set('offersPerPage', value)
          }}
        >
          {itemsPerPageVariants.map(number => (
            <MenuItem value={number} key={number}>
              {number}
            </MenuItem>
          ))}
        </StyledSelect>
      </ItemsPerPage>
      <PaginationContainer>
        <Arrow
          onClick={() => setPage(currentPage - 1)}
          disabled={currentPage <= 1}
        >
          &#x3c;
        </Arrow>
        {Array(pagesCount)
          .fill()
          .map((_, index) => {
            const pageNumber = index + 1
            return (
              shouldPageNumberBeShown(pageNumber) && (
                <React.Fragment key={index}>
                  {shouldPrevDotsBeShown(pageNumber) && '... '}
                  <PageNumber
                    current={pageNumber === currentPage}
                    onClick={() => setPage(pageNumber)}
                  >
                    {`${pageNumber} `}
                  </PageNumber>
                  {shouldNextDotsBeShown(pageNumber) && '... '}
                </React.Fragment>
              )
            )
          })}
        <Arrow
          onClick={() => setPage(currentPage + 1)}
          disabled={currentPage >= pagesCount}
        >
          &#x3e;
        </Arrow>
      </PaginationContainer>
      <GoTo>
        <Label>
          <FormattedMessage id='pagination.goTo' />{' '}
        </Label>
        <StyledTextField
          type='number'
          InputProps={{ inputProps: { min: 1, max: pagesCount } }}
          variant='outlined'
          onKeyPress={goToKeyPressHandler}
        />
      </GoTo>
    </div>
  )
}
export default styled(Pagination)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    align-items: center;
  }
  ${breakpoint.xxs`
     display: flex;
      flex-direction: row;
  `}
`

const ItemsPerPage = styled.div`
  width: 50%;
  justify-content: flex-end;
  order: 2;
  ${breakpoint.xxs`
    justify-content: flex-start;
    order: 0;
    width: auto;
  `}
`

const GoTo = styled.div`
  width: 50%;
  justify-content: flex-start;

  ${breakpoint.xxs`
    width: auto;
   justify-content: flex-end;

  `}
`

const Label = styled.span`
  margin-right: 0.5rem;
`

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 0;
  ${breakpoint.xxs`
    width: auto;
  `};
`

const PageNumber = styled.span`
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.3rem;
  ${({ current, theme }) =>
    current &&
    css`
      color: ${theme.colors.apple.hex()};
      font-weight: 600;
    `}
`

const Arrow = styled.span`
  margin: 0 1rem;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}
`
