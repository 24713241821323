import { addMethod, boolean, mixed, object, string } from 'yup'
import { validate, validateSchema } from 'src/ducks/schema'
import { any } from 'ramda'
import moment from 'moment'

addMethod(string, 'time', function() {
  return this.test('time', 'validation.timeOrEmpty', value =>
    moment(value, 'HH:mm', true).isValid()
  )
})

const trackingEditSchema = object().shape(
  {
    isCurrentUserForwarder: boolean(),
    originLocationId: string().when(['isCurrentUserForwarder'], {
      is: true,
      then: string().required(),
      otherwise: string().nullable()
    }),
    originLocationAddress: string().nullable(),
    originLocationCity: string().nullable(),
    originLocationPostalCode: string().nullable(),
    originLocationCountryCode: string().nullable(),
    originLocationLat: string().nullable(),
    originLocationLong: string().nullable(),
    originLocationCompanyName: string().nullable(),
    originLocationOperationDate: mixed().when(['isCurrentUserForwarder'], {
      is: true,
      then: string().required(),
      otherwise: string().nullable()
    }),
    originLocationOperationTime: mixed().when(['isCurrentUserForwarder'], {
      is: true,
      then: string()
        // temporary requirement disabled and changed to nullable to make users use it more and easier
        // .time()
        // .required(),
        .nullable(),
      otherwise: string().nullable()
    }),
    destinationLocationId: string().when(['isCurrentUserForwarder'], {
      is: true,
      then: string().required(),
      otherwise: string().nullable()
    }),
    destinationLocationAddress: string().nullable(),
    destinationLocationCity: string().nullable(),
    destinationLocationPostalCode: string().nullable(),
    destinationLocationCountryCode: string().nullable(),
    destinationLocationLat: string().nullable(),
    destinationLocationLong: string().nullable(),
    destinationLocationCompanyName: string().nullable(),
    destinationLocationOperationDate: mixed().when(['isCurrentUserForwarder'], {
      is: true,
      // temporary requirement disabled and changed to nullable to make users use it more and easier
      // then: string().required(),
      then: string().nullable(),
      otherwise: string().nullable()
    }),
    destinationLocationOperationTime: mixed().when(['isCurrentUserForwarder'], {
      is: true,
      then: string()
        // temporary requirement disabled and changed to nullable to make users use it more and easier
        // .time()
        // .required(),
        .nullable(),
      otherwise: string().nullable()
    }),
    notes: string().nullable(),
    driverId: string().nullable(),
    driverName: string().when(['driverId', 'driverPhoneNumber'], {
      is: (...rest) => any(v => !!v, rest),
      then: string().required(),
      otherwise: string().nullable()
    }),
    driverPhoneNumber: mixed().when(['driverId', 'driverName'], {
      is: (...rest) => any(v => !!v, rest),
      then: string()
        .matches(/^$|^\+\d{9,20}$/, 'validation.phoneNumber')
        .required(),
      otherwise: string()
        .matches(/^$|^\+\d{9,20}$/, 'validation.phoneNumber')
        .nullable()
    }),
    driverEmail: string()
      .email()
      .nullable(),
    driverIdentityCardNumber: string().nullable(),
    vehicleRegistrationPlate: string().nullable()
  },
  [['driverName', 'driverPhoneNumber']]
)

export const validateTrackingEdit = validate(trackingEditSchema)
export const validateTrackingEditSchema = validateSchema(trackingEditSchema)
