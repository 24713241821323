import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Chevron from 'src/assets/icons/down.svg'

const ShowMore = ({ children, className }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={className}>
      {open && children}
      <More onClick={() => setOpen(!open)}>
        {!open && <FormattedMessage id='tracking.showMore' />}
        {open && <FormattedMessage id='tracking.showLess' />}{' '}
        <Chevron
          style={{
            transform: `rotate(${open ? '180deg' : '0'})`
          }}
        />
      </More>
    </div>
  )
}

export default styled(ShowMore)`
  & > div {
    margin-top: 0.5rem;
  }
`

const More = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  justify-content: center;
  cursor: pointer;
`
