import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import Loader from 'src/components/LinearLoader'
import { connectMessages } from './duck/connectors'
import Header from './components/ConversationHeader'
import TextInput from './components/ConversationInput'
import MessagesList from './components/MessagesList'

const Conversation = ({
  lastRequest,
  className,
  conversationOpened,
  setConversationOpened,
  sendMessage,
  bootstrapConversation,
  interlocutor,
  messages,
  clearConversation,
  isConversationMuted,
  muteConversation,
  unmuteConversation,
  conversation,
  online,
  markConversationAsSeen,
  markMessageAsSeen,
  reserveOffer,
  acceptReservation,
  rejectReservation,
  cancelReservation,
  getContextAvailableActions,
  isConversationContextLoading,
  conversationContextActions,
  isConversationLoading,
  getMessages,
  isAvailable
}) => {
  const intl = useIntl()

  useEffect(() => {
    if (conversationOpened) {
      bootstrapConversation()
    }
  }, [conversationOpened, conversation.receiverId, conversation.contextId])

  useEffect(() => clearConversation, [])

  const messagesRef = useRef(null)
  const scrollDown = () => {
    messagesRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }
  const scrollAfterSendingMessage = value => {
    sendMessage({
      message: value,
      callback: scrollDown
    })
  }

  const renderInputWrapper = () => {
    if (isAvailable) {
      return (
        <InputWrapper>
          <TextInput
            name='name'
            id='id'
            value=''
            onSubmit={scrollAfterSendingMessage}
            placeholder={intl.formatMessage({
              id: 'messages.conversationInputPlaceholder'
            })}
          />
        </InputWrapper>
      )
    }
    if (!isAvailable) {
      return (
        <>
          <EndOfChat
            message={intl.formatMessage({
              id: 'messages.conversationFinishMessage'
            })}
          />
          <InputWrapper>
            <TextInput
              disabled={!isAvailable}
              placeholder={intl.formatMessage({
                id: 'messages.conversationDisabledInputPlaceholder'
              })}
            />
          </InputWrapper>
        </>
      )
    }
  }

  return (
    conversationOpened && (
      <div className={className}>
        <LoaderStyled loading={isConversationLoading} />
        <Header
          interlocutor={interlocutor}
          interlocutorOnline={online[interlocutor.id]}
          conversation={conversation}
          isConversationMuted={isConversationMuted}
          toggleConversationNotifications={
            isConversationMuted ? unmuteConversation : muteConversation
          }
          reserveOffer={reserveOffer}
          acceptReservation={acceptReservation}
          rejectReservation={rejectReservation}
          cancelReservation={cancelReservation}
          getContextAvailableActions={getContextAvailableActions}
          isConversationContextLoading={isConversationContextLoading}
          conversationContextActions={conversationContextActions}
          onClose={() => {
            setConversationOpened({ conversationOpened: false })
            clearConversation()
          }}
        />
        <MessagesList
          lastRequest={lastRequest}
          messagesRef={messagesRef}
          getMessages={getMessages}
          messages={messages}
          conversationOpened={conversationOpened}
          markConversationAsSeen={() =>
            markConversationAsSeen(conversation.conversationId)
          }
          markMessageAsSeen={markMessageAsSeen}
          conversationId={conversation.conversationId}
        />
        {renderInputWrapper()}
      </div>
    )
  )
}

const ConnectedComponent = connectMessages(Conversation)
export default styled(ConnectedComponent)`
  display: flex;
  flex-direction: column;
  position: fixed;
  box-sizing: border-box;
  background-color: white;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};

  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  ${breakpoint.s`
    right: 32px;
    bottom: 0;
    height: 452px;
    width: 330px;
  `}
`

const InputWrapper = styled.div`
  display: block;
  flex-direction: row;
  width: 100%;
  padding: 0 1rem 0.8rem 1rem;
  box-sizing: border-box;
  z-index: 12;
`
const LoaderStyled = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
`
const EndOfChatMessage = ({ className, message }) => (
  <div className={className}>
    <span>{message}</span>
  </div>
)

const EndOfChat = styled(EndOfChatMessage)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.hawkesBlue.hex()};
    position: absolute;
    top: 50%;
    z-index: 1;
  }
  span {
    display: flex;
    position: relative;
    width: auto;
    max-width: calc(100% - 2.4rem);
    border-radius: 19px;
    padding: 7px 16px;
    font-size: 12px;
    background-color: ${({ theme }) => theme.colors.hawkesBlue.hex()};
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.bluewood.hex()};
    border: solid 1px rgba(19, 162, 191, 0.15);
    text-align: center;
    z-index: 10;
  }
`
