import React from 'react'
import styled, { css } from 'styled-components'

import MUIAddIcon from '@material-ui/icons/Add'
import MUIRemoveIcon from '@material-ui/icons/Remove'

const Stepper = ({
  className,
  step = 1,
  maxValue = 300,
  minValue = 0,
  unit = '',
  value = 0,
  onChange = () => {},
  withInput
}) => {
  const prevActive = value - step >= minValue
  const nextActive = value + step <= maxValue

  const handleLess = () => onChange(value - step)

  const handleMore = () => onChange(value + step)

  return (
    <div className={className}>
      <IconWrapper active={prevActive} onClick={handleLess}>
        <RemoveIcon />
      </IconWrapper>
      {!withInput && <StepperContent>{`${value} ${unit}`}</StepperContent>}
      {withInput && (
        <StepperInput
          value={value}
          onChange={e => onChange(parseInt(e.target.value, 10))}
          type='number'
        />
      )}
      <IconWrapper active={nextActive} onClick={handleMore}>
        <AddIcon />
      </IconWrapper>
    </div>
  )
}

export default styled(Stepper)`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  border-radius: 2px;
`

const AddIcon = styled(MUIAddIcon)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  font-size: 1.45rem !important;
`

const RemoveIcon = styled(MUIRemoveIcon)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  font-size: 1.45rem !important;
`

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  ${AddIcon}, ${RemoveIcon} {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.2).hex()};
  }
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      cursor: pointer;
      ${AddIcon}, ${RemoveIcon} {
        color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
      }
    `}
`

const StepperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 40px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const StepperInput = styled.input`
  border: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
