import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { API_STATES } from 'src/ducks/consts'
import Alert from 'src/components/Alert'
import TextInput from 'src/components/atoms/TextInput'
import {
  InputsRowWrapper,
  InputWrapper
} from 'src/components/atoms/FormHelpers'
import {
  CancelButton,
  SaveButtonWithLoader as SaveButton
} from 'src/components/atoms/Buttons'
import { ButtonsRow } from 'src/features/account/components/atoms'
import { connectEditCurrentUserForm } from 'src/features/account/duck/connectors'
import {
  validateUserSchema,
  validateUser
} from 'src/features/account/duck/schema'

const UserContactDataForm = ({
  className,
  onCancel,
  updateCurrentUser,
  currentUser,
  updateCurrentUserStatus: { changedSuccessfully, errorMessage, state }
}) => {
  const intl = useIntl()
  const [form, setForm] = useState({
    phoneNumber: currentUser.phoneNumber,
    contactInfo: currentUser.contactInfo,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName
  })
  const valid = useValidateSchema(form, validateUserSchema)

  useEffect(() => {
    changedSuccessfully && onCancel()
  }, [changedSuccessfully])

  const isLoading = state === API_STATES.IN_PROGRESS

  const handleSave = () => {
    updateCurrentUser(form)
  }

  const handleChange = (name, value) => setForm({ ...form, [name]: value })

  return (
    <div className={className}>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            type='text'
            label={intl.formatMessage({ id: 'common.name' })}
            id='firstName'
            name='firstName'
            placeholder={intl.formatMessage({ id: 'users.namePlaceholder' })}
            value={form.firstName}
            onChange={handleChange}
            validate={validateUser}
          />
        </InputWrapper>
      </InputsRowWrapper>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            type='text'
            label={intl.formatMessage({ id: 'common.surname' })}
            id='lastName'
            name='lastName'
            placeholder={intl.formatMessage({
              id: 'users.lastNamePlaceholder'
            })}
            value={form.lastName}
            onChange={handleChange}
            validate={validateUser}
          />
        </InputWrapper>
      </InputsRowWrapper>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            type='tel'
            label={intl.formatMessage({ id: 'account.phoneNumberLabel' })}
            id='phoneNumber'
            name='phoneNumber'
            placeholder={intl.formatMessage({ id: 'common.phoneNumber' })}
            value={form.phoneNumber}
            onChange={handleChange}
            validate={validateUser}
          />
        </InputWrapper>
      </InputsRowWrapper>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            label={intl.formatMessage({ id: 'account.additionalInfoEdit' })}
            id='contactInfo'
            name='contactInfo'
            placeholder={intl.formatMessage({
              id: 'account.additionalInfoPlaceholder'
            })}
            value={form.contactInfo}
            onChange={handleChange}
            multiline
            validate={validateUser}
          />
        </InputWrapper>
      </InputsRowWrapper>
      {errorMessage && <Alert type='alert' message={errorMessage} />}
      <ButtonsRow>
        <SaveButton
          active={valid && !isLoading}
          loading={isLoading}
          onClick={handleSave}
        >
          {intl.formatMessage({ id: 'common.save' })}
        </SaveButton>
        <CancelButton active onClick={onCancel}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </CancelButton>
      </ButtonsRow>
    </div>
  )
}
const StyledUserContactForm = styled(UserContactDataForm)`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 400px;
  ${InputsRowWrapper} {
    margin-bottom: 0.5rem;
  }
`

export default connectEditCurrentUserForm(StyledUserContactForm)
