import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import GavelUrl from 'src/assets/icons/gavel.svg'
import moment from 'moment'
import { DATE_LONG_FORMAT_WITH_WEEKDAY } from 'src/ducks/consts'

const AuctionPrice = ({ className, deadline, price, isPriceVisible }) => (
  <div className={className}>
    <Row>
      <Title>
        <FormattedMessage id='offerDetails.currentPrice' />
      </Title>
      <GreenGavelIcon />
    </Row>
    <Row>
      <Price>
        {isPriceVisible && (
          <>
            {price.float} <span>{price.currency}</span>
          </>
        )}
        {!isPriceVisible && (
          <>
            <FormattedMessage id='offerDetails.hidden' />{' '}
            <span>
              <FormattedMessage id='offerDetails.makeFirstBid' />
            </span>
          </>
        )}
      </Price>
      <Date>
        <DateTitle>
          {moment(deadline).isAfter(moment()) && (
            <>
              {moment(deadline).fromNow(true)}
              <FormattedMessage id='offerDetails.toEnd' />
            </>
          )}
          {moment(deadline).isBefore(moment()) && (
            <FormattedMessage id='offerDetails.finished' />
          )}
        </DateTitle>
        <Description>
          ({moment(deadline).format(DATE_LONG_FORMAT_WITH_WEEKDAY)})
        </Description>
      </Date>
    </Row>
  </div>
)

export default styled(AuctionPrice)`
  background-color: ${({ theme }) => theme.colors.lightGreen.hex()};
  border-radius: 4px;
  padding: 0.8rem 0.5rem 0.75rem 0.7rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const GreenGavelIcon = styled(GavelUrl)`
  margin-top: -5px;
  color: ${({ theme }) => theme.colors.apple.hex()};
`

export const Price = styled.div`
  flex: 0.75;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  text-transform: uppercase;
  margin-top: -4px;
  & > span {
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: none;
  }
`

export const Title = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  opacity: 0.7;
  text-transform: uppercase;
`

export const Date = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: -6px;
`

export const DateTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`

export const HiddenPrice = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`

export const Description = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.6;
  margin-top: -2px;
`
