import React from 'react'
import styled, { css } from 'styled-components'
import { isNil, propOr, path } from 'ramda'
import { breakpoint } from 'src/theme/grid'
import { useIntl } from 'gatsby-plugin-intl'
import MUIClose from '@material-ui/icons/Close'
import EllipsisTooltip from 'src/components/EllipsisTooltip'

const Tabs = ({
  className,
  list = [],
  tabsData,
  createFilterTab,
  removeFilterTab,
  activeTab,
  setActiveTab = () => {}
}) => {
  const intl = useIntl()
  //hide tabs if there is only default tab
  const hideTabs = list.length <= 1

  const isTabActive = (id, index) =>
    id === activeTab || (isNil(activeTab) && index === 0)

  return hideTabs ? null : (
    <div className={className}>
      <TabsHeaderWrapper>
        {list.map((tab, index) => {
          const hasCounter = propOr(false, tab.id)(tabsData)
          return (
            <TabHeader
              key={index}
              active={isTabActive(tab.id, index)}
              onClick={() => setActiveTab(tab.id)}
              //first tab is not removable
              hasCounter={hasCounter}
              permanent={index === 0}
            >
              <EllipsisTooltip>
                {tab.name ||
                  intl.formatMessage({ id: 'offersFilters.allOffers' })}
              </EllipsisTooltip>
              {hasCounter && path([tab.id, 'counter'])(tabsData) !== 0 && (
                <OffersNumber>
                  {path([tab.id, 'counter'])(tabsData)}
                </OffersNumber>
              )}
              <CloseIcon
                onClick={e => {
                  e.stopPropagation()
                  removeFilterTab(tab.id)
                }}
              />
            </TabHeader>
          )
        })}
        {/* createFilterTab - true parameter means that tab will be created without any filters */}
        <TabHeader add onClick={() => createFilterTab(true)}>
          + {intl.formatMessage({ id: 'offersFilters.addTab' })}
        </TabHeader>
      </TabsHeaderWrapper>
    </div>
  )
}

export default styled(Tabs)`
  width: 100%;
  display: flex;
  margin-bottom: 1.6rem;
  position: relative;
  &:after {
    content: '';
    width: calc(100% + 5rem);
    height: 1px;
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.15).hex()};
    position: absolute;
    bottom: 0;
    left: -2rem;
  }
`

const OffersNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  min-width: 17px;
  box-sizing: border-box;
  padding: 0 4px;
  border-radius: 8.5px;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  color: white;
  font-weight: 800;
  font-size: 0.7rem;
  margin-left: 0.4rem;
`

const CloseIcon = styled(MUIClose)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  padding: 0.1rem;
  font-size: 18px;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  margin-left: 0.4rem;
  ${breakpoint.m`
    &:hover {
      background-color: ${({ theme }) => theme.colors.ghost.alpha(0.5).hex()};
    }
  `}
`

const TabsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  white-space: nowrap;
  overflow-x: auto;
  position: relative;
`

const TabHeader = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.95rem;
  padding: 0.3rem 0.6rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  cursor: pointer;
  background-color: transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 0.3s;
  position: relative;
  padding: 0.45rem 0.4rem 0.6rem 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 120px;
  max-width: 280px;
  position: relative;
  ${breakpoint.m`
    padding: 0.45rem 30px 0.6rem 0.8rem;
    min-width: 60px;
    max-width: 280px;
  `}
  ${CloseIcon}, ${OffersNumber} {
    ${breakpoint.m`
      position: absolute;
      right: 6px;
      bottom: 10px;
    `}
  }
  ${CloseIcon} {
    ${breakpoint.m`
      display: none;
    `}
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
    ${breakpoint.m`
      ${OffersNumber} {
        display: none;
      }
      ${CloseIcon} {
        display: inline-block;
      }
    `}
  }
  ${({ hasCounter }) =>
    !hasCounter &&
    css`
      ${CloseIcon} {
        display: inline-block;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.black.hex()};
      &:after {
        content: '';
        height: 4px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.apple.hex()};
        position: absolute;
        bottom: 0;
        left: 0;
      }
    `}
  ${({ add }) =>
    add &&
    css`
      color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.87).hex()};
      font-weight: 600;
      padding-right: 1.2rem;
      min-width: 60px;
      ${breakpoint.m`
        min-width: 60px;
        padding-right: 20px;
      `}
    `}
  ${({ permanent }) =>
    permanent &&
    css`
      ${CloseIcon} {
        display: none;
      }
      &:hover {
        background-color: ${({ theme }) =>
          theme.colors.ebonyClay.alpha(0.1).hex()};
        ${OffersNumber} {
          display: flex;
        }
        ${CloseIcon} {
          display: none;
        }
      }
    `}
  & > span {
    position: absolute;
  }
`
