import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import { isEmpty } from 'ramda'
import moment from 'moment'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import {
  createOfferDetailsLink,
  MENU_ITEMS
} from 'src/features/dashboard/duck/consts'
import { Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroup,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection
} from 'src/features/offers/components/atoms'
import { TitleContainer } from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'
import TextInput from 'src/components/atoms/TextInput'
import { breakpoint } from 'src/theme/grid'
import CustomIcon from 'src/components/CustomIcon'
import { API_STATES } from 'src/ducks/consts'
import Drawer from 'src/features/stocks/components/Drawer'
import { connectOrderEdit } from 'src/features/orders/duck/connectors'
import { ContactRow } from 'src/features/orders/components/ContactRow'
import DatePicker from 'src/components/atoms/DatePicker'
import Select from 'src/components/filters/SelectInput'
import { connectDictionaries } from 'src/ducks/connectors'
import {
  validateAddOrder,
  validateAddOrderSchema
} from 'src/features/orders/duck/schema'
import useValidateSchema from 'src/hooks/useValidateSchema'
import TextInputMasked from 'src/components/atoms/TextInputMasked'
import Checkbox from 'src/components/atoms/CheckboxInput'
import {
  FREE_CARRIER_TRANSPORTATION_TYPES,
  FREE_CARRIER_TRANSPORTATION_TYPES_OPTIONS,
  TRACKING_OFFER_TYPES
} from 'src/features/tracking/duck/consts'
import {
  CUSTOMS_CLEARANCE_TYPE_OPTIONS_EXPORT,
  CUSTOMS_CLEARANCE_TYPE_OPTIONS_IMPORT,
  CUSTOMS_CLEARANCE_TYPES
} from 'src/features/orders/duck/consts'
import OrderOfferDetailsDrawer from 'src/features/orders/components/OrderOfferDetailsDrawer'
import {
  AlertContainer,
  BigInputContainer,
  CreateOfferButton,
  Heading,
  HeadingContainer,
  InputRow,
  OfferLink,
  OfferType,
  OfferTypeWrapper,
  SmallInputContainer,
  Subtitle
} from 'src/features/orders/components/atoms'
import { getIssuerAndContractorFromOrderDetails } from 'src/features/orders/duck/helpers'
import useUrlParams from 'src/hooks/useUrlParams'
import { formatOrderReferenceNumber } from 'src/utils/helpers'
import Alert from 'src/components/Alert'
import Shade from 'src/components/Shade'

const AddOrderForm = ({
  className,
  orderEditForm,
  orderDetails,
  setOrderEditFormValue,
  updateOrder,
  createOrder,
  dictionaries,
  clearEditOrderForm,
  prefillOrderForm
}) => {
  const { id } = useUrlParams()

  useEffect(() => {
    id && prefillOrderForm(formatOrderReferenceNumber(id))
  }, [id])

  useEffect(
    () => () => {
      clearEditOrderForm()
    },
    []
  )
  const intl = useIntl()

  const FORM_TYPES = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE'
  }
  const formType = id ? FORM_TYPES.UPDATE : FORM_TYPES.CREATE

  const isFreeCarrierOffer =
    orderEditForm.offerType === TRACKING_OFFER_TYPES.FREE_CARRIER

  const isRejected = !!orderDetails.rejectedAt

  const confirmButtonAction =
    formType === FORM_TYPES.CREATE ? createOrder : updateOrder

  const valid = useValidateSchema(orderEditForm.toJS(), validateAddOrderSchema)

  const customsClearanceTypeOptions =
    (orderEditForm.transportationType ===
      FREE_CARRIER_TRANSPORTATION_TYPES.EXPORT &&
      CUSTOMS_CLEARANCE_TYPE_OPTIONS_EXPORT) ||
    (orderEditForm.transportationType ===
      FREE_CARRIER_TRANSPORTATION_TYPES.IMPORT &&
      CUSTOMS_CLEARANCE_TYPE_OPTIONS_IMPORT) ||
    []

  const showCustomsClearanceAddress =
    orderEditForm.customsClearanceType ===
      CUSTOMS_CLEARANCE_TYPES.IN_CUSTOMS_TRANSIT ||
    orderEditForm.customsClearanceType === CUSTOMS_CLEARANCE_TYPES.ADDRESS

  const { issuer, contractor } = getIssuerAndContractorFromOrderDetails(
    orderDetails
  )

  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                {formType === FORM_TYPES.CREATE ? (
                  <FormattedMessage id='orders.addOrderTitle' />
                ) : (
                  <FormattedMessage id='orders.editOrderTitle' />
                )}{' '}
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          {!issuer.id && <Shade />}
          <FormSection>
            <FormInnerWrapper>
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'orders.orders' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDERS}`
                    },
                    {
                      name:
                        formType === FORM_TYPES.CREATE ? (
                          <FormattedMessage id='orders.addOrderTitle' />
                        ) : (
                          <FormattedMessage id='orders.editOrderTitle' />
                        ),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_ADD}`
                    }
                  ]}
                />
                <HeadingContainer>
                  {formType === FORM_TYPES.CREATE ? (
                    <Heading>
                      <FormattedMessage id='orders.orderTo' />
                      <OfferLink
                        to={createOfferDetailsLink(
                          orderDetails.offer.referenceNumber
                        )}
                      >
                        {orderDetails.offer.referenceNumber}
                      </OfferLink>
                    </Heading>
                  ) : (
                    <Heading>
                      <FormattedMessage
                        id='orders.order'
                        values={{ orderId: orderDetails.referenceNumber }}
                      />
                    </Heading>
                  )}
                </HeadingContainer>
                <AlertContainer>
                  {isRejected && (
                    <Alert
                      type='warning'
                      message={
                        <p>
                          <FormattedMessage id='orders.rejectedOrderMessageForIssuer' />
                        </p>
                      }
                    />
                  )}
                  {orderDetails.rejectionReason && (
                    <Alert
                      type='geyser'
                      message={orderDetails.rejectionReason}
                      title={<FormattedMessage id='orders.rejectionReason' />}
                      icon={<div />}
                    />
                  )}
                </AlertContainer>
                <TitleContainer>
                  <RelationsTitle
                    required={
                      !orderDetails.transportationType && isFreeCarrierOffer
                    }
                  >
                    <FormattedMessage id='orders.relations' />
                  </RelationsTitle>
                </TitleContainer>
                {orderDetails.transportationType && (
                  <OfferTypeWrapper>
                    <CustomIcon
                      name={orderDetails.transportationType}
                      size={24}
                    />
                    <OfferType>
                      <FormattedMessage
                        id={`common.${orderDetails.transportationType}`}
                        defaultMessage='-'
                      />
                    </OfferType>
                  </OfferTypeWrapper>
                )}
                {!orderDetails.transportationType && isFreeCarrierOffer && (
                  <OfferTypeWrapper>
                    {FREE_CARRIER_TRANSPORTATION_TYPES_OPTIONS.map(opt => (
                      <Checkbox
                        required
                        key={opt.value}
                        name='transportationType'
                        value={orderEditForm.transportationType}
                        onChange={setOrderEditFormValue}
                        type='radio'
                        option={opt}
                      />
                    ))}
                  </OfferTypeWrapper>
                )}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.issuer' />
                  </Title>
                </TitleContainer>
                <ContactRow user={issuer} />
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.contractor' />
                  </Title>
                </TitleContainer>
                <ContactRow user={contractor} />
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.cargo' />
                  </Title>
                </TitleContainer>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      required
                      label={intl.formatMessage({
                        id: 'orders.containerNumber'
                      })}
                      id='containerNumber'
                      name='containerNumber'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.containerNumber}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                  <SmallInputContainer>
                    <Select
                      required
                      disabled={!isFreeCarrierOffer}
                      id='containerId'
                      name='containerId'
                      validate={validateAddOrder}
                      label={intl.formatMessage({ id: 'forms.container' })}
                      value={orderEditForm.containerId}
                      options={dictionaries.containers}
                      onChange={setOrderEditFormValue}
                      placeholder={intl.formatMessage({
                        id: 'forms.searchPlaceholder'
                      })}
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Select
                      required
                      disabled={!isFreeCarrierOffer}
                      id='shipownerId'
                      name='shipownerId'
                      validate={validateAddOrder}
                      label={intl.formatMessage({ id: 'forms.shipowner' })}
                      value={orderEditForm.shipownerId}
                      options={dictionaries.shipowners}
                      onChange={setOrderEditFormValue}
                      placeholder={intl.formatMessage({
                        id: 'forms.searchPlaceholder'
                      })}
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      label={intl.formatMessage({
                        id: 'orders.weight'
                      })}
                      id='weight'
                      name='weight'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.weight}
                      onChange={setOrderEditFormValue}
                      type='unit'
                      unit='kg'
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      label={intl.formatMessage({
                        id: 'orders.sealNumber'
                      })}
                      id='sealNumber'
                      name='sealNumber'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.sealNumber}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                </InputRow>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.line' />{' '}
                  </Title>
                </TitleContainer>
                <Subtitle>
                  <FormattedMessage id='orders.pickUp' />
                </Subtitle>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      required
                      label={intl.formatMessage({
                        id: 'orders.pickUpAddress'
                      })}
                      id='pickUpAddress'
                      name='pickUpAddress'
                      validate={validateAddOrder}
                      placeholder={intl.formatMessage({
                        id: 'orders.typeAddress'
                      })}
                      value={orderEditForm.pickUpAddress}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      label='PIN'
                      id='pin'
                      name='pin'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.pin}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      label={intl.formatMessage({
                        id: 'orders.pickUpRef'
                      })}
                      id='pickUpRef'
                      name='pickUpRef'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.pickUpRef}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                </InputRow>
                <Subtitle>
                  <FormattedMessage id='orders.return' />
                </Subtitle>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      required
                      label={intl.formatMessage({
                        id: 'orders.returnAddress'
                      })}
                      id='returnAddress'
                      name='returnAddress'
                      validate={validateAddOrder}
                      placeholder={intl.formatMessage({
                        id: 'orders.typeAddress'
                      })}
                      value={orderEditForm.returnAddress}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      label={intl.formatMessage({
                        id: 'orders.booking'
                      })}
                      id='booking'
                      name='booking'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.booking}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      label={intl.formatMessage({
                        id: 'orders.returnRef'
                      })}
                      id='returnRef'
                      name='returnRef'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.returnRef}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                </InputRow>
                <Subtitle>
                  <FormattedMessage id='orders.loadingUnloading' />
                </Subtitle>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      label={intl.formatMessage({
                        id: 'orders.loadingUnloadingCompanyName'
                      })}
                      id='loadingUnloadingCompanyName'
                      name='loadingUnloadingCompanyName'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.loadingUnloadingCompanyName}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                </InputRow>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      required
                      label={intl.formatMessage({
                        id: 'orders.address'
                      })}
                      id='loadingUnloadingAddress'
                      name='loadingUnloadingAddress'
                      validate={validateAddOrder}
                      placeholder={intl.formatMessage({
                        id: 'orders.typeAddress'
                      })}
                      value={orderEditForm.loadingUnloadingAddress}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                  <SmallInputContainer>
                    <DatePicker
                      required
                      label={intl.formatMessage({
                        id: 'offersFilters.date'
                      })}
                      name='loadingUnloadingDate'
                      validate={validateAddOrder}
                      placeholder='dd.mm.rrrr'
                      value={orderEditForm.loadingUnloadingDate}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <TextInputMasked
                      required
                      mask='99:99'
                      label={intl.formatMessage({
                        id: 'forms.time'
                      })}
                      id='loadingUnloadingTime'
                      name='loadingUnloadingTime'
                      validate={validateAddOrder}
                      placeholder='gg:mm'
                      value={orderEditForm.loadingUnloadingTime}
                      onChange={setOrderEditFormValue}
                      type='tel'
                      calendarTime
                    />
                  </SmallInputContainer>
                </InputRow>
                {!isEmpty(customsClearanceTypeOptions) && (
                  <InputRow>
                    <BigInputContainer>
                      <Select
                        options={customsClearanceTypeOptions.map(item => ({
                          ...item,
                          label: intl.formatMessage({ id: item.label }),
                          value: item.value
                        }))}
                        label={intl.formatMessage({
                          id: 'orders.customsClearanceType'
                        })}
                        name='customsClearanceType'
                        validate={validateAddOrder}
                        value={orderEditForm.customsClearanceType}
                        onChange={setOrderEditFormValue}
                        id='customsClearanceType'
                      />
                    </BigInputContainer>
                  </InputRow>
                )}
                {showCustomsClearanceAddress && (
                  <InputRow optionalField>
                    <BigInputContainer>
                      <TextInput
                        required
                        label={intl.formatMessage({
                          id: 'orders.customsClearanceAddress'
                        })}
                        id='customsClearanceAddress'
                        name='customsClearanceAddress'
                        validate={validateAddOrder}
                        placeholder={intl.formatMessage({
                          id: 'orders.typeAddress'
                        })}
                        value={orderEditForm.customsClearanceAddress}
                        onChange={setOrderEditFormValue}
                      />
                    </BigInputContainer>
                  </InputRow>
                )}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.transport' />{' '}
                  </Title>
                </TitleContainer>
                <Subtitle>
                  {intl.formatMessage({ id: 'tracking.driverData' })}
                </Subtitle>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      required
                      type='text'
                      label={intl.formatMessage({
                        id: 'tracking.driverName'
                      })}
                      id='driverName'
                      name='driverName'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.driverName}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      required
                      type='text'
                      label={intl.formatMessage({
                        id: 'tracking.driverIdentityCardNumber'
                      })}
                      id='driverIdentityCardNumber'
                      name='driverIdentityCardNumber'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.driverIdentityCardNumber}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                </InputRow>
                <Subtitle>
                  <FormattedMessage id='orders.vehicleAndPrice' />
                </Subtitle>
                <InputRow>
                  <SmallInputContainer>
                    <TextInput
                      required
                      type='text'
                      label={intl.formatMessage({
                        id: 'tracking.vehicleRegistrationPlate'
                      })}
                      id='vehicleRegistrationPlate'
                      name='vehicleRegistrationPlate'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.vehicleRegistrationPlate}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      type='text'
                      label={intl.formatMessage({
                        id: 'orders.semitrailerNumber'
                      })}
                      id='containerSemiTrailerRegistrationPlate'
                      name='containerSemiTrailerRegistrationPlate'
                      validate={validateAddOrder}
                      placeholder=''
                      value={
                        orderEditForm.containerSemiTrailerRegistrationPlate
                      }
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      required
                      label={intl.formatMessage({ id: 'forms.price' })}
                      id='price'
                      name='price'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.price}
                      onChange={setOrderEditFormValue}
                      type='currency'
                      onCurrencyChange={setOrderEditFormValue}
                      currencyValue={orderEditForm.currency}
                    />
                  </SmallInputContainer>
                </InputRow>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.commodity' />{' '}
                  </Title>
                </TitleContainer>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      required
                      type='text'
                      label={intl.formatMessage({
                        id: 'orders.name'
                      })}
                      id='cargoName'
                      name='cargoName'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.cargoName}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                </InputRow>
                <InputRow>
                  <BigInputContainer>
                    <Select
                      id='adrClassId'
                      name='adrClassId'
                      validate={validateAddOrder}
                      label={intl.formatMessage({
                        id: 'orders.adrClass'
                      })}
                      value={orderEditForm.adrClassId}
                      options={dictionaries.adrClasses}
                      onChange={setOrderEditFormValue}
                      width='380px'
                    />
                  </BigInputContainer>
                  <SmallInputContainer>
                    <TextInput
                      type='text'
                      label='Nr UN'
                      id='adrClassUn'
                      name='adrClassUn'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.adrClassUn}
                      onChange={setOrderEditFormValue}
                    />
                  </SmallInputContainer>
                </InputRow>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.orderInfo' />
                  </Title>
                </TitleContainer>
                <InputRow>
                  <BigInputContainer>
                    <TextInput
                      required
                      type='text'
                      label={intl.formatMessage({
                        id: 'orders.issuePlace'
                      })}
                      id='issuePlace'
                      name='issuePlace'
                      validate={validateAddOrder}
                      placeholder=''
                      value={orderEditForm.issuePlace}
                      onChange={setOrderEditFormValue}
                    />
                  </BigInputContainer>
                  <SmallInputContainer>
                    <DatePicker
                      label={intl.formatMessage({
                        id: 'orders.issueDate'
                      })}
                      name='date'
                      placeholder='dd.mm.rrrr'
                      value={moment()}
                      disabled
                    />
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <TextInput
                    type='text'
                    label={intl.formatMessage({
                      id: 'orders.additionalInfo'
                    })}
                    id='additionalInfo'
                    name='additionalInfo'
                    validate={validateAddOrder}
                    placeholder=''
                    value={orderEditForm.additionalInfo}
                    onChange={setOrderEditFormValue}
                  />
                </InputRow>
                <InputRow>
                  <TextInput
                    type='text'
                    label={intl.formatMessage({
                      id: 'orders.conditions'
                    })}
                    id='conditions'
                    name='conditions'
                    validate={validateAddOrder}
                    placeholder=''
                    value={orderEditForm.conditions}
                    onChange={setOrderEditFormValue}
                    multiline
                  />
                </InputRow>
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDERS}`}>
                {intl.formatMessage({ id: 'forms.cancel' })}
              </Link>
              <ActionGroup>
                <CreateOfferButton
                  active={
                    valid && orderEditForm.state !== API_STATES.IN_PROGRESS
                  }
                  onClick={() =>
                    confirmButtonAction({
                      values: orderEditForm,
                      id: orderDetails.id
                    })
                  }
                >
                  <FormattedMessage id='orders.sendToAccept' />
                </CreateOfferButton>
              </ActionGroup>
            </FormActionBar>
          </FormSection>
          <Drawer open={true} onClose={() => {}}>
            <OrderOfferDetailsDrawer />
          </Drawer>
        </Content>
      </Page>
    </>
  )
}

const ConnectedComponent = compose(
  connectOrderEdit,
  connectDictionaries
)(AddOrderForm)
export default styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
  }
  ${Padding} {
    max-width: calc(626px + 2.8rem);
    margin: 0 auto;
  }
  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
`
const RelationsTitle = styled(Title)`
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`
