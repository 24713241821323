import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl'
import styled from 'styled-components'

import Dialog from '@material-ui/core/Dialog'
import { BidButton, CancelButton } from 'src/components/atoms/Buttons'
import XIcon from 'src/assets/icons/close-x.svg'
import { breakpoint } from 'src/theme/grid'
import TextInput from 'src/components/atoms/TextInput'
import DatePicker from 'src/components/atoms/DatePicker'
import {
  validateMarkAsPaid,
  validateMarkAsPaidSchema
} from 'src/features/invoices/duck/schema'
import { formatPrice } from 'src/features/invoices/duck/helpers'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import moment from 'moment'
import useValidateSchema from 'src/hooks/useValidateSchema'

export default ({ onConfirm, invoice, open, setOpen }) => {
  const defaultPaidAmount = Number(
    Math.abs(invoice.total.float) - Math.abs(invoice.amountPaid.float)
  ).toFixed(2)

  const [amount, setAmount] = useState(defaultPaidAmount)
  const [paidAt, setPaidAt] = useState(moment())
  const intl = useIntl()

  const onClose = () => {
    setPaidAt(moment())
    setOpen(false)
  }

  useEffect(() => {
    setAmount(
      Number(
        Math.abs(invoice.total.float) - Math.abs(invoice.amountPaid.float)
      ).toFixed(2)
    )
  }, [invoice.amountPaid.float, invoice.total.float])

  const onPriceBlur = () => {
    const priceFormatted = formatPrice(amount)
    if (priceFormatted !== amount) {
      setAmount(priceFormatted)
    }
  }

  const valid = useValidateSchema(
    {
      amount,
      paidAt
    },
    validateMarkAsPaidSchema({
      defaultPaidAmount,
      issueDate: moment(invoice.issueDate)
        .subtract(1, 'day')
        .set({
          hour: 23,
          minute: 59
        })
    })
  )

  const validate = validateMarkAsPaid({
    defaultPaidAmount,
    issueDate: moment(invoice.issueDate)
      .subtract(1, 'day')
      .set({
        hour: 23,
        minute: 59
      })
  })

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CloseIcon onClick={onClose} />
          <DialogHeader>
            <FormattedMessage id='invoices.markInvoiceAsPaid' />
          </DialogHeader>
          <Details>
            <FormattedMessage
              id='invoices.markInvoiceAsPaidDescription'
              values={{
                number: invoice.number,
                a: chunks => (
                  <Link
                    to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.id}`}
                  >
                    {chunks}
                  </Link>
                )
              }}
            />
          </Details>
          <InputRow>
            <InputContainer>
              <TextInput
                type='number'
                onBlur={onPriceBlur}
                label={intl.formatMessage({
                  id: 'invoices.paymentAmount'
                })}
                id='amount'
                name='amount'
                required
                placeholder=''
                value={amount}
                onChange={(name, value) => setAmount(value)}
                validate={validate}
                onCurrencyChange={() => {}}
                currencyValue={invoice.total.currency}
              />
            </InputContainer>
            <InputContainer>
              <DatePicker
                required
                label={intl.formatMessage({
                  id: 'invoices.paidAt'
                })}
                name='paidAt'
                maxDate={moment()}
                minDate={moment(invoice.issueDate).set({
                  hour: 0,
                  minute: 0
                })}
                placeholder='dd.mm.rrrr'
                value={paidAt}
                onChange={(name, value) => setPaidAt(value)}
                validate={validate}
              />
            </InputContainer>
          </InputRow>
          <Details>
            <FormattedMessage id='invoices.currentPaid' />
            <b>{invoice.amountPaid.formatted}</b>
          </Details>
          <ActionBar>
            <CancelButton
              active
              onClick={() => {
                setOpen(false)
              }}
            >
              {intl.formatMessage({
                id: 'common.notNow'
              })}
            </CancelButton>
            <Button
              active={valid}
              onClick={() => {
                onClose()
                onConfirm({
                  id: invoice.id,
                  values: {
                    amount,
                    paidAt
                  }
                })
              }}
            >
              <FormattedMessage id='invoices.markAsPaid' />
            </Button>
          </ActionBar>
        </DialogContent>
      </Dialog>
    </>
  )
}

const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    width: 600px;
    overflow-x: hidden;
    padding: 1.6rem 2.2rem;
  `}
`

const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.78;
  margin: 0.8rem 0 0.875rem 0;
  width: 100%;
  display: inline-block;
`

const Details = styled.p`
  margin: 0 0 0.5rem 0;
  padding-bottom: 0.75rem;
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  padding-bottom: 0.8rem;
  && > button {
    flex: none;
    margin-right: 0;
  }
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    padding-bottom: 0.8rem;
  `}
`

const InputContainer = styled.div`
  flex: 1 0 21%;
  min-width: 100px;
  ${breakpoint.m`
    max-width: 160px;
    &:nth-child(odd){
      margin-right: 40px;  
    }
  `}
`

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${breakpoint.m`
    flex-direction: row;
  `}
`

const Button = styled(BidButton)`
  padding-right: 1rem !important;
`
