import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import styled, { css } from 'styled-components'
import MUIArrow from '@material-ui/icons/KeyboardArrowRight'

const BreadCrumbs = ({ links, className }) => (
  <div className={className}>
    <StyledList>
      {links.map((item, index, arr) => {
        const isLastItem = index === arr.length - 1
        return (
          <StyledListItem key={index}>
            <StyledLink
              key={index}
              to={item.url}
              isLastItem={isLastItem}
              state={item.state}
            >
              {item.name}
            </StyledLink>
            {!isLastItem && <ArrowIcon />}
          </StyledListItem>
        )
      })}
    </StyledList>
  </div>
)

export default styled(BreadCrumbs)`
  width: 100%;
  margin: 0.8rem 0;
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

const StyledListItem = styled.li`
  display: inline-block;
  font-weight: 400;
  font-size: 0.82rem;
  line-height: 1.7;
  vertical-align: middle;
  & span {
    padding: 0 0.6rem;
  }
  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black.hex()};
  ${({ isLastItem }) =>
    isLastItem &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
    `}
`

const ArrowIcon = styled(MUIArrow)`
  font-size: 0.75rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin: 0 0.6rem;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
`
