import { useState, useEffect } from 'react'
import qs from 'qs'

export default (init = {}) => {
  const [state, setState] = useState(init)
  useEffect(() => {
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    setState({ ...params })
  }, [])

  return state
}
