import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { Button } from 'src/components/atoms/Buttons'
import Alert from 'src/components/Alert'

export const FormTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.78;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  ${breakpoint.m`
     margin-top: 0;
     margin-bottom: 2rem;
  `}
`
export const FormContainer = styled.div`
  padding: 0;
  ${breakpoint.m`
        padding: 2rem 2rem 1rem 3rem;
  `}
`
export const AddressDataContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  padding: 1.5rem;
  margin: 1rem 0 0;
  ${breakpoint.m`
          margin: 4rem 2rem 1rem 1rem;
  `}
`
export const ButtonSend = styled(Button)`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: none;
  color: ${({ theme }) => theme.colors.white.hex()};
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  pointer-events: none;
  opacity: 0.5;
  margin-top: 0.5rem;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      pointer-events: auto;
      box-shadow: 0 2px 10px 0 rgba(71, 170, 66, 0.25);
    `}
`
export const CallUs = styled.a`
  display: block;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  b {
    font-weight: 700;
    white-space: nowrap;
  }
`
export const Spacer = styled.div`
  height: 2px;
  width: 64px;
  margin: 0.5rem 0;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
`
export const Email = styled.a`
  display: block;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
`
export const CompanyName = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`
export const CompanyAddress = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`
export const BankName = styled(CompanyAddress)`
  opacity: 0.702635;
`
export const StyledAlert = styled(Alert)`
  margin-bottom: 1rem;
`
