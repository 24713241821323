import React from 'react'
import styled, { css } from 'styled-components'
import { isEmptyArray } from 'ramda-adjunct'
import NoDraftsGraphic from 'src/assets/shipping-containers.svg'
import { useIntl } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import { Template } from './atoms'
import Tabs, { TabContent } from './Tabs'

const Drafts = ({
  className,
  lastOffers,
  templates,
  onClose = () => {},
  fillFormUsingTemplate,
  removeTemplate
}) => {
  const intl = useIntl()
  const emptyLastOffers = isEmptyArray(lastOffers)
  const emptyTemplates = isEmptyArray(templates)

  const handleTemplateClick = template => {
    fillFormUsingTemplate(template)
    onClose()
  }

  return (
    <div className={className}>
      <Tabs
        list={[
          intl.formatMessage({ id: 'forms.lastOffersTabName' }),
          intl.formatMessage({ id: 'forms.draftsTabName' })
        ]}
      >
        <TabContent>
          {!emptyLastOffers && (
            <ListTitle>
              {intl.formatMessage({ id: 'forms.copyLastOffer' })}
            </ListTitle>
          )}
          {lastOffers.map((offer, key) => (
            <Template
              key={key}
              selected={offer.selected}
              {...offer.templateDetails.toJS()}
              onClick={() => {
                handleTemplateClick(offer)
              }}
              offer
            />
          ))}
          {emptyLastOffers && (
            <>
              <NoDraftsComponent />
              <NoDraftsTitle>
                {intl.formatMessage({ id: 'forms.noOffersTitle' })}
              </NoDraftsTitle>
              <NoDraftsDescription>
                {intl.formatMessage({ id: 'forms.noOffersDescription' })}
              </NoDraftsDescription>
            </>
          )}
        </TabContent>
        <TabContent>
          {!emptyTemplates && (
            <ListTitle>
              {intl.formatMessage({ id: 'forms.useOfferTemplate' })}
            </ListTitle>
          )}
          {templates.map((offer, key) => (
            <Template
              key={key}
              selected={offer.selected}
              {...offer.templateDetails.toJS()}
              onClick={() => {
                handleTemplateClick(offer)
              }}
              withTitle
              template
              removeTemplate={(e, id) => {
                e.stopPropagation()
                removeTemplate(id)
              }}
            />
          ))}
          {emptyTemplates && (
            <>
              <NoDraftsComponent />
              <NoDraftsTitle>
                {intl.formatMessage({ id: 'forms.noDraftsTitle' })}
              </NoDraftsTitle>
              <NoDraftsDescription>
                {intl.formatMessage({ id: 'forms.noDraftsDescription' })}
              </NoDraftsDescription>
            </>
          )}
        </TabContent>
      </Tabs>
      <ActionRow>
        <span onClick={onClose}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </span>
      </ActionRow>
    </div>
  )
}

export default styled(Drafts)`
  flex-direction: column;
  height: 100%;
  width: 400px;
  border-left: 1px solid ${({ theme }) => theme.colors.iron.hex()};
  background-color: white;
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 1.4rem;
  position: relative;
  z-index: 20;
  display: none;
  ${({ opened }) =>
    opened &&
    css`
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 102;
      height: 100%;
    `}
  ${breakpoint.m`
    display: flex;
  `}
`

const NoDraftsComponent = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  background-image: url(${NoDraftsGraphic});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 2.2rem 0;
`

const NoDraftsTitle = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 1.05rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black.hex()};
  line-height: 2;
  margin-bottom: 0.4rem;
`

const NoDraftsDescription = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  box-sizing: border-box;
  padding: 0 1.2rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  line-height: 1.71;
`

const ListTitle = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 0.2rem;
  margin-bottom: 1.2rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
`

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.875rem;
  z-index: 200;
  height: 90px;
  padding: 0 1.2rem;
  ${breakpoint.m`
    display: none;
  `}
`
