import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'

import {
  Column,
  DocumentsHeaderItem,
  DocumentsTableHeader,
  DocumentsTableRow,
  TitleContainer
} from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'

import { Container, Ellipsis } from 'src/features/stocks/components/atoms'

import { DOCUMENTS_HEADERS } from 'src/features/account/duck/consts'

import { DocumentsRow } from 'src/features/account/components/rows'

const CompanyDocuments = ({ documents }) => (
  <Column>
    <TitleContainer>
      <Title>
        <FormattedMessage id='myCompany.documents.documents' />
      </Title>
    </TitleContainer>
    <StyledContainer>
      <DocumentsTableHeader numberOfHeaders={DOCUMENTS_HEADERS.length}>
        {DOCUMENTS_HEADERS &&
          DOCUMENTS_HEADERS.map((header, index) => (
            <DocumentsHeaderItem key={`${header.name} ${index}`}>
              <Ellipsis>
                <FormattedMessage id={header.name} defaultMessage=' ' />
              </Ellipsis>
            </DocumentsHeaderItem>
          ))}
      </DocumentsTableHeader>
      {documents.length > 0 &&
        documents.map((document, index) => (
          <DocumentsTableRow
            key={index}
            numberOfHeaders={DOCUMENTS_HEADERS.length}
          >
            {DocumentsRow(document)}
          </DocumentsTableRow>
        ))}
    </StyledContainer>
  </Column>
)

export default CompanyDocuments

const StyledContainer = styled(Container)`
  margin: 0 -0.8rem;
  width: auto;
  ${breakpoint.m`
      margin: initial;
      width: 100%;
  `}
`
