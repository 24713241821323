import React from 'react'
import styled, { css } from 'styled-components'

import MUIAddIcon from '@material-ui/icons/ArrowRight'
import MUIRemoveIcon from '@material-ui/icons/ArrowLeft'

const Stepper = ({
  className,
  step = 1,
  maxValue = 300,
  minValue = 0,
  unit = '',
  value = 0,
  onChange = () => {}
}) => {
  const prevActive = value - step >= minValue
  const nextActive = value + step <= maxValue

  const handleLess = e => {
    e.stopPropagation()
    onChange(value - step)
  }

  const handleMore = e => {
    e.stopPropagation()
    onChange(value + step)
  }

  return (
    <div className={className} onClick={e => e.stopPropagation()}>
      <IconWrapper active={prevActive} onClick={handleLess}>
        <RemoveIcon />
      </IconWrapper>
      <StepperContent>{`+ ${value}${unit}`}</StepperContent>
      <IconWrapper active={nextActive} onClick={handleMore}>
        <AddIcon />
      </IconWrapper>
    </div>
  )
}

export default styled(Stepper)`
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  position: absolute;
  right: 0.4rem;
  bottom: 50%;
  transform: translateY(50%);
`

const AddIcon = styled(MUIAddIcon)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  font-size: 1.2rem !important;
`

const RemoveIcon = styled(MUIRemoveIcon)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  font-size: 1.2rem !important;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  border-radius: 2px;
  ${AddIcon}, ${RemoveIcon} {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.2).hex()};
  }
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      cursor: pointer;
      ${AddIcon}, ${RemoveIcon} {
        color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
      }
    `}
`

const StepperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 23px;
  font-weight: 600;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  white-space: nowrap;
`
