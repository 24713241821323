import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useIntl } from 'gatsby-plugin-intl'

import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import Tabs from 'src/components/Tabs'
import { Column, Row, SectionBox } from 'src/features/account/components/atoms'
import { Page } from 'src/components/Wrappers'
import Alert from 'src/components/Alert'
import { breakpoint } from 'src/theme/grid'
import TrackingOngoing from 'src/features/tracking/components/TrackingOngoing'
import TrackingFinished from 'src/features/tracking/components/TrackingFinished'
import { TRACKING_TABS_ARRAY } from 'src/features/tracking/duck/consts'
import connect from './duck/connectors'

const TrackingList = ({ getOngoingOffers, getFinishedOffers, className }) => {
  useEffect(() => {
    getOngoingOffers()
    getFinishedOffers()
  }, [])

  const intl = useIntl()

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader withDropdown>
              {intl.formatMessage({
                id: 'tracking.tracking'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Tabs list={TRACKING_TABS_ARRAY}>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <TrackingOngoing />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <TrackingFinished />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = connect(TrackingList)
export default styled(ConnectedComponent)`
  ${Tabs} {
    display: block;
    margin-bottom: 0;
    & > div {
      padding: 0.75rem 1.2rem 0 1.2rem;
    }
    & span {
      font-weight: 400;
    }
  }

  ${Alert} {
    width: auto;
    margin: 1rem 0 0 0;
    ${breakpoint.m`
    margin: initial;
    width: inherit;
  `}
  }
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
