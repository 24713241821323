import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import qs from 'qs'
import { breakpoint } from 'src/theme/grid'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import CustomIcon from 'src/components/CustomIcon'
import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { Padding } from 'src/features/myOffers/components/atoms'
import Alert from 'src/components/Alert'
import TrackIconUrl from 'src/assets/icons/track.svg'
import { Description, Title } from 'src/features/stocks/components/typography'
import connect from './duck/connectors'
import { TrackingEventItemDetails } from './components/atoms'
import TrackingHistoryMap from './components/TrackingHistoryMap'

const TrackingHistory = ({
  className,
  getTrackingItem,
  trackingItem,
  getTrackingEventFile
}) => {
  const [eventSelected, setEventSelected] = useState(null)
  const mainContainerRef = useRef(null)
  const mapRef = useRef(null)

  useEffect(() => {
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    getTrackingItem(params.id)
  }, [])

  const intl = useIntl()

  const handleEventSelected = event => {
    setEventSelected(event)
    setTimeout(() => {
      mainContainerRef.current.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }, 1000)
  }

  return (
    <Page
      className={className}
      contentPadding={0}
      wrapperRef={mainContainerRef}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              <FormattedMessage id='tracking.tracking' />
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <MainContainer>
        <ColumnMap>
          <Padding>
            <BreadCrumbs
              links={[
                {
                  name: intl.formatMessage({ id: 'forms.dashboard' }),
                  url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                },
                {
                  name: intl.formatMessage({ id: 'tracking.tracking' }),
                  url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING}`
                },
                {
                  name: (
                    <FormattedMessage
                      id='tracking.trackingPreview'
                      values={{ id: trackingItem.referenceNumber }}
                    />
                  ),
                  url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${trackingItem.id}`
                },
                {
                  name: <FormattedMessage id='tracking.trackingHistory' />,
                  url: null
                }
              ]}
            />
          </Padding>
          <MapContainer ref={mapRef}>
            <TrackingHistoryMap
              eventSelected={eventSelected}
              events={trackingItem.trackingEvents}
              originLocationLat={trackingItem.originLocationLat}
              originLocationLong={trackingItem.originLocationLong}
              destinationLocationLat={trackingItem.destinationLocationLat}
              destinationLocationLong={trackingItem.destinationLocationLong}
            />
          </MapContainer>
        </ColumnMap>
        <EventListContainer>
          <PlacesContainer>
            <PlaceRow>
              <div>
                <span>{intl.formatMessage({ id: 'tracking.fromPlace' })}</span>
                <em>{trackingItem.originLocation.name}</em>
              </div>
              <div>
                <span>
                  {trackingItem.originLocationOperationDate}
                  {trackingItem.originLocationOperationTime &&
                    `, ${trackingItem.originLocationOperationTime}`}
                </span>
              </div>
            </PlaceRow>
            <PlaceRow>
              <div>
                <span>{intl.formatMessage({ id: 'tracking.toPlace' })}</span>
                <em>{trackingItem.destinationLocation.name}</em>
              </div>
              <div>
                <span>
                  {trackingItem.destinationLocationOperationDate}
                  {trackingItem.destinationLocationOperationTime &&
                    `, ${trackingItem.destinationLocationOperationTime}`}
                </span>
              </div>
            </PlaceRow>
          </PlacesContainer>
          <EventDetailsWrapper>
            <Alert
              icon={<TrackIcon />}
              type='white'
              title={
                <FormattedMessage id={`tracking.${trackingItem.status}`} />
              }
              message={
                <FormattedMessage id={`tracking.${trackingItem.offerType}`} />
              }
            />
            <EventDetailsContainer>
              <EventsSummaryRow>
                <div>
                  <Title>
                    <FormattedMessage id='tracking.workTime' />
                  </Title>
                  <Description>
                    {trackingItem.reportedWorkTime || (
                      <FormattedMessage id='common.missing' />
                    )}
                  </Description>
                </div>
                <div>
                  <Title>
                    <FormattedMessage id='tracking.delay' />
                  </Title>
                  <Delay>
                    <>
                      <CustomIcon name='wait' size={24} />
                      {trackingItem.reportedDelay || (
                        <FormattedMessage id='common.missing' />
                      )}
                    </>
                  </Delay>
                </div>
              </EventsSummaryRow>
              <EventListDetails>
                <EventsListTitle>
                  <FormattedMessage id='tracking.trackingHistory' />
                </EventsListTitle>
                {trackingItem.eventsHistory.map(event => (
                  <TrackingEventItemDetails
                    title={intl.formatMessage({ id: event.title })}
                    comment={event.comment}
                    delay={event.delay}
                    lat={event.lat}
                    long={event.long}
                    time={event.createdAt}
                    files={event.files}
                    getFile={getTrackingEventFile}
                    selected={
                      eventSelected !== null && eventSelected.id === event.id
                    }
                    handleSelected={handleEventSelected}
                    event={event}
                  />
                ))}
              </EventListDetails>
            </EventDetailsContainer>
          </EventDetailsWrapper>
        </EventListContainer>
      </MainContainer>
    </Page>
  )
}

const ConnectedComponent = connect(TrackingHistory)
export default styled(ConnectedComponent)``

const MainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  ${breakpoint.m`
    display: flex;
    min-height: unset;
    flex-direction: row;
    height: 100%;
  `}
`

const Column = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
`

const ColumnMap = styled(Column)`
  min-height: 320px;
  max-height: 420px;
  ${breakpoint.m`
    max-height: unset;
  `}
`

const MapContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 320px;
  box-sizing: border-box;
`

const EventListContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;
  background-color: white;
  ${breakpoint.m`
    height: 100%;
    max-width: 400px;
  `}
`

const TrackIconComponent = ({ className }) => (
  <div className={className}>
    <TrackIconUrl />
  </div>
)

const TrackIcon = styled(TrackIconComponent)`
  background-color: ${({ theme }) => theme.colors.california.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white.hex()};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const EventDetailsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(36, 45, 63, 0.16);
  ${breakpoint.m`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  `}
`

const EventDetailsContainer = styled.div`
  display: inline-block;
  padding: 0.6rem 1.6rem;
  box-sizing: border-box;
  ${breakpoint.m`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  `}
`

const EventsSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    width: 50%;
  }
`

const PlacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 1.6rem 12px 1rem;
  background-color: ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
  div:first-child {
    margin-bottom: 0.45rem;
  }
`

const PlaceRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.black.alpha(0.87).hex()};
    font-size: 0.9rem;
  }
  em {
    display: inline-block;
    color: ${({ theme }) => theme.colors.black.hex()};
    font-size: 0.96rem;
    font-weight: 600;
    font-style: normal;
    margin-left: 0.4rem;
  }
`

const EventListDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-top: 1.6rem;
  ${TrackingEventItemDetails}:last-of-type {
    &:after {
      display: none;
    }
  }
`

const EventsListTitle = styled(Title)`
  margin-bottom: 1.2rem;
`

const Delay = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
  font-size: 0.9rem;
  font-weight: 600;
  ${CustomIcon} {
    margin-right: 0.3rem;
  }
`
