import { API_STATES } from 'src/ducks/consts'

/**
 * Checks if the stock is loading based on API state.
 * @param {Record} stock
 * @param {("list"|"buttons"|"details")} key
 * @returns {boolean}
 */
export const isStockLoading = (stock, key) =>
  [API_STATES.IN_PROGRESS, API_STATES.PRISTINE].includes(stock.state[key])
