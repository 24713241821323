import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import MUIAddIcon from '@material-ui/icons/Add'

export const AddInvoiceButtonComponent = ({ onClick }) => {
  const intl = useIntl()
  return (
    <ButtonContainer>
      <AddInvoiceItemButton onClick={onClick}>
        {intl.formatMessage({
          id: 'invoices.addItem'
        })}
        <AddIcon />
      </AddInvoiceItemButton>
    </ButtonContainer>
  )
}
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${breakpoint.m`
   justify-content: flex-start;
  `}
`

const AddInvoiceItemButton = styled.button`
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  font-size: 0.875rem;
  line-height: 1.71;
  font-weight: 600;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  appearance: none;
  & > span {
    white-space: nowrap;
    display: inline-block;
  }
`

const AddIcon = styled(MUIAddIcon)`
  font-size: 24px;
`
