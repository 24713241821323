import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import ReactMarkdown from 'react-markdown'
import Dialog from '@material-ui/core/Dialog'
import { breakpoint } from 'src/theme/grid'
import { CancelButton, SaveOfferButton } from 'src/components/atoms/Buttons'
import connect from 'src/features/termsOfService/ducks/connectors'
import { API_STATES, DATE_FORMAT } from 'src/ducks/consts'
import moment from 'moment'
import Alert from 'src/components/Alert'

const RequestNewLocationDialog = ({
  //   termsOfService,
  onClose,
  errorMessage,
  open
  //   state,
  //   termsAcceptedAt,
  //   acceptTerms,
  //   logoutUser,
  //   getTermsOfService
}) => {
  //   useEffect(() => {
  //     getTermsOfService()
  //   }, [open])

  return (
    <Dialog disableKeyboard onClose={() => null} open={open}>
      <Container>
        <DialogTitle>
          <DialogTitleText>
            <FormattedMessage
              id='termsOfService.title'
              //   values={{
              //     date: termsOfService.createdAt
              //       ? moment(termsOfService.createdAt).format(DATE_FORMAT)
              //       : ''
              //   }}
            />
          </DialogTitleText>
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id='termsOfService.description' />
        </DialogContent>
        {errorMessage && <Alert type='alert' message={errorMessage} />}
        <DialogActions>
          <CancelButton
            active
            onClick={() => {
              alert('saved')
            }}
          >
            <FormattedMessage id='termsOfService.reject' />
          </CancelButton>
          <SaveOfferButton
            // active={state !== API_STATES.IN_PROGRESS}
            active={true}
            onClick={() => {
              alert('accepted')
            }}
          >
            <FormattedMessage id='termsOfService.accept' />
          </SaveOfferButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default RequestNewLocationDialog

// export default connect(TermsOfServiceDialog)

export const Container = styled.div`
  padding: 1.5rem 1rem 0rem 1rem;
  & > div {
    margin: 0.7rem 0;
  }

  ${breakpoint.m`
        padding: 1.5rem 2rem 0rem 2rem;
    `}
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const DialogTitleText = styled.h3`
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  color: ${({ theme }) => theme.colors.black.hex()};
`
export const DialogContent = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;

  & ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
  & ol {
    padding-left: 2rem;
  }
  & a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    &:hover {
      color: ${({ theme }) => theme.colors.easternBlue.darken().hex()};
    }
  }
`

export const DialogSubtitle = styled.h5`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`

export const TermsPreview = styled(DialogContent)`
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 300px;
  background-color: #f8f8f9;
  border-radius: 4px;
  padding: 0.5rem;
`

const DialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ withReason }) =>
    withReason &&
    css`
      && {
        margin: 0 0 0.7rem 0;
      }
    `}
`
