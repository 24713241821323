import { connect } from 'react-redux'

import { getExchangeOffersCountersRoutine } from 'src/ducks/actions'
import { selectCurrentUser } from 'src/features/account/duck/selectors'
import { getStickyNotificationsListRoutine } from 'src/features/notifications/duck/actions'
import { selectStickyNotificationsList } from 'src/features/notifications/duck/selectors'
import { selectExchangeCounters } from 'src/ducks/selectors'

const mapStateToProps = state => ({
  exchangeCounters: selectExchangeCounters(state),
  currentUser: selectCurrentUser(state),
  stickyNotificationsList: selectStickyNotificationsList(state)
})

const mapDispatchToProps = dispatch => ({
  getExchangeOffersCounters: () => dispatch(getExchangeOffersCountersRoutine()),
  getStickyNotificationsList: () =>
    dispatch(getStickyNotificationsListRoutine())
})

export default connect(mapStateToProps, mapDispatchToProps)
