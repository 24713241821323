import React, { useContext } from 'react'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'

import routes from 'src/utils/routes'
import Pagination from 'src/components/Pagination'
import {
  BottomRow,
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  RefreshIcon
} from 'src/features/stocks/components/atoms'
import LoadingList from 'src/components/atoms/LoadingList'

import Shade from 'src/components/Shade'
import Checkbox from 'src/components/atoms/CheckboxInput'

import { BOOLEAN } from 'src/ducks/consts'
import { AddOfferButton } from 'src/components/atoms/Buttons'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { connectExportOngoing } from 'src/features/myOffers/duck/connectors'
import { HEADERS_ONGOING } from 'src/features/myOffers/duck/consts'
import {
  ActionGroup,
  CounterGroup,
  HeaderItem,
  Selected,
  TableHeader
} from 'src/features/myOffers/components/atoms'
import { isStockLoading } from 'src/utils/stocks'
import DeleteMultiple from 'src/features/myOffers/components/DeleteMultiple'
import Offer from 'src/features/myOffers/components/export/OfferRowOngoing'
import { Icon } from 'src/features/account/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { ListTopRow } from 'src/features/contracts/components/MyOffersOngoing'

const OffersList = ({
  ongoingOffers,
  myOffers: offers,
  pagination,
  filters,
  setFilter,
  selectedOffers,
  deleteMultipleOffers,
  selectAllOffers,
  clearSelectedOffers,
  hidePagination = false,
  getOngoingOffers
}) => {
  const headers = HEADERS_ONGOING.MY_EXPORT
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToAddOffer = hasPermissionTo(
    PERMISSIONS.STOCK_EXPORT_ADD_OFFER,
    permissions
  )
  const hasPermissionToDeleteOwnOffer = hasPermissionTo(
    PERMISSIONS.OFFER_DELETE_OWN,
    permissions
  )
  const allOffersSelected = selectedOffers.length === offers.length

  const toggleFilter = name => {
    setFilter(name, !filters.onlyMe)
  }

  const handleSelectAll = () =>
    allOffersSelected ? clearSelectedOffers() : selectAllOffers()

  const renderListContents = () => {
    if (isStockLoading(ongoingOffers, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (offers.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='offers.noOffers' />
        </EmptyOffers>
      )
    }

    if (offers.length > 0) {
      return offers.map(offer => (
        <Offer
          key={offer.id}
          offer={offer}
          isSelected={selectedOffers.includes(offer.id)}
          headers={headers}
          hasPermissionToDeleteOwnOffer={hasPermissionToDeleteOwnOffer}
        />
      ))
    }
  }

  return (
    <>
      <ListTopRow>
        <CounterGroup>
          <Counter>
            <span>{`${pagination.total} `}</span>
            <FormattedMessage
              id='offers.offersFound'
              values={{
                count: pagination.total
              }}
            />
          </Counter>
          <RefreshIcon onClick={getOngoingOffers} />
        </CounterGroup>
        <ActionGroup>
          <Checkbox
            name='onlyMe'
            value={filters.onlyMe}
            onChange={toggleFilter}
            option={{
              text: intl.formatMessage({ id: 'offers.onlyMe' }),
              value: BOOLEAN.YES
            }}
          />
          {hasPermissionToAddOffer && (
            <Link
              to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_EXPORT}`}
            >
              <AddOfferButton />
            </Link>
          )}
        </ActionGroup>
      </ListTopRow>
      <Container>
        {selectedOffers.length > 0 && hasPermissionToDeleteOwnOffer && (
          <DeleteMultiple
            onClick={deleteMultipleOffers}
            selectedOffersCount={selectedOffers.length}
          />
        )}
        <TableHeader numberOfHeaders={headers.length}>
          <Selected
            name='selectAll'
            value={allOffersSelected}
            onChange={handleSelectAll}
            option={{
              text: '',
              value: BOOLEAN.YES
            }}
          />
          {headers &&
            headers.map((header, index) => (
              <HeaderItem sort={false} key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage id={header.name} defaultMessage=' ' />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </HeaderItem>
            ))}
        </TableHeader>
        {renderListContents()}
      </Container>
      {offers.length > 0 && !hidePagination && (
        <BottomRow>
          <Pagination
            itemsPerPage={filters.limit}
            currentPage={pagination.currentPage}
            pagesCount={pagination.lastPage}
            setPage={page => setFilter('page', page)}
            setItemsPerPage={limit => {
              setFilter('limit', limit)
              setFilter('page', 1)
            }}
          />
        </BottomRow>
      )}
    </>
  )
}

export default connectExportOngoing(OffersList)
