import { connect } from 'react-redux'

import {
  selectAdditionalInfo,
  selectOffer,
  selectFiles,
  selectDebtCollectionErrorMessage,
  selectDebtCollectionConfirmation,
  selectDebtor,
  selectIsLoading
} from 'src/features/debtCollection/duck/selectors'
import {
  clearDebtCollectionRoutine,
  createDebtCollectionExportRoutine,
  createDebtCollectionFreeCarriersRoutine,
  createDebtCollectionImportRoutine,
  createDebtCollectionPostImportRoutine,
  setAdditionalInfoRoutine,
  setDebtCollectionFilesRoutine
} from 'src/features/debtCollection/duck/actions'
import { showFinishedExportOfferReservationsRoutine } from 'src/features/myOffers/duck/actions-export'
import { showFinishedImportOfferReservationsRoutine } from 'src/features/myOffers/duck/actions-import'
import { showFinishedPostImportOfferReservationsRoutine } from 'src/features/myOffers/duck/actions-post-import'
import { showFinishedFreeCarrierOfferReservationsRoutine } from 'src/features/myOffers/duck/actions-free-carrier'

const mapStateToProps = state => ({
  offer: selectOffer(state),
  additionalInfo: selectAdditionalInfo(state),
  files: selectFiles(state),
  errorMessage: selectDebtCollectionErrorMessage(state),
  confirmation: selectDebtCollectionConfirmation(state),
  debtor: selectDebtor(state),
  isLoading: selectIsLoading(state)
})

const mapDispatchToProps = dispatch => ({
  setAdditionalInfo: value => dispatch(setAdditionalInfoRoutine(value)),
  createDebtCollectionExport: () =>
    dispatch(createDebtCollectionExportRoutine()),
  createDebtCollectionImport: () =>
    dispatch(createDebtCollectionImportRoutine()),
  createDebtCollectionPostImport: () =>
    dispatch(createDebtCollectionPostImportRoutine()),
  createDebtCollectionFreeCarriers: () =>
    dispatch(createDebtCollectionFreeCarriersRoutine()),
  setDebtCollectionFiles: value =>
    dispatch(setDebtCollectionFilesRoutine(value)),
  clearDebtCollection: () => dispatch(clearDebtCollectionRoutine()),
  showOffersReservationsExport: id =>
    dispatch(showFinishedExportOfferReservationsRoutine({ id })),
  showOffersReservationsImport: id =>
    dispatch(showFinishedImportOfferReservationsRoutine({ id })),
  showOffersReservationsPostImport: id =>
    dispatch(showFinishedPostImportOfferReservationsRoutine({ id })),
  showOffersReservationsFreeCarrier: id =>
    dispatch(showFinishedFreeCarrierOfferReservationsRoutine({ id }))
})

export default connect(mapStateToProps, mapDispatchToProps)
