import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import CustomIcon from 'src/components/CustomIcon'
import styled, { css } from 'styled-components'
import {
  AddPhotoCircle,
  FileInput
} from 'src/features/account/components/atoms'

const AddPhoto = ({ onChange, className, photo }) => {
  const [file, setFile] = useState(null)

  useEffect(() => {
    file && onChange(file)
  }, [file])

  const isEmpty = !photo.url

  return (
    <div className={className}>
      <FileInput
        type='file'
        accept='image/jpg, image/png, image/jpeg, image/gif'
        name='file'
        id='file'
        onChange={e => setFile(e.target.files[0])}
      />
      <AddPhotoCircle htmlFor='file' url={photo.url}>
        {isEmpty && <CustomIcon name='addPhoto' size={24} />}
        {isEmpty && <FormattedMessage id='myCompany.users.addPhoto' />}
      </AddPhotoCircle>
      {!isEmpty && (
        <EditIconContainer htmlFor='file'>
          <CustomIcon name='edit' size={24} />
        </EditIconContainer>
      )}
    </div>
  )
}

const styledAddPhoto = styled(AddPhoto)`
  position: relative;
  height: fit-content;
`

export default styledAddPhoto

const EditIconContainer = styled.label`
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  ${CustomIcon} {
    height: 24px;
  }
  position: absolute;
  bottom: 0;
  left: calc(50% + 30px);
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
