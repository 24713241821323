import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { DateTime } from 'src/features/stocks/components/atoms'
import { Status } from 'src/features/myOffers/components/StatusIcon'
import {
  Actions,
  HeaderItem,
  Offeror
} from 'src/features/myOffers/components/atoms'
import { Avatar } from 'src/components/atoms/Avatar'

export const PlaceholderRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem 0.6rem;
  box-sizing: border-box;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
`

export const Name = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

export const Value = styled(Name)`
  font-weight: 400;
`

export const Queue = styled.div`
  grid-area: queue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  & svg {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
  > div {
    overflow: hidden;
  }
  b {
    font-weight: 700;
  }
`

export const PlaceholderTable = styled.div`
  display: flex;
  width: 100%;
  max-width: 450px;
  flex-direction: column;
  margin: 1.2rem 0;
  ${PlaceholderRow}:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.mercury.hex()};
  }
`
export const TableRow = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  position: relative;
  overflow: visible;
  font-size: 0.875rem;
  margin-top: 4px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container shipowner company company';

  ${breakpoint.m`
     overflow: hidden;
     border-bottom: 1px solid ${({ theme }) =>
       theme.colors.ghost.alpha(0.3).hex()};
     margin-top: 0;
     grid-template-rows: none;
     grid-template-columns: minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr 3fr 2fr;
     grid-template-areas:
    'reservations index locationFrom locationTo container shipowner company offeror queue'
  `}

  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    ${breakpoint.m`
    align-self: center;
  `}
    &:first-of-type {
      padding-left: 10px;
    }
  }

  &:hover {
    z-index: 1;
    box-shadow: 0 2px 20px 0
      ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
    position: relative;
    ${Actions} {
      grid-column: -2;
      width: 100%;
      left: 0;
    }
    & > ${DateTime} {
      display: none;
    }
    ${breakpoint.m`
     grid-template-columns: minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr 3fr 2fr;
     `}
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom: none;
      border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
      margin-left: -4px;
      position: relative;
      ${breakpoint.m`
      border-bottom: none;
      margin-top: 7px;
         box-shadow: 0 2px 20px 0 ${({ theme }) =>
           theme.colors.ebonyClay.alpha(0.1).hex()};
     `}
    `}
  
  & > ${Status}, ${Offeror}, ${Queue} {
  display: none;
      ${breakpoint.m`
     display: flex;
     `}
  }
`

export const TableHeader = styled(TableRow)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  position: static;
  &:hover {
    position: static;
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
    ${breakpoint.m`
     grid-template-columns: minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr 3fr 2fr;
    `}
  }
  ${breakpoint.m`
      border-bottom: none;
     display: grid;
  `}
  > ${HeaderItem} {
    position: relative;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
    &:hover {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    }
  }
  ${({ sticky, headerWidth }) =>
    sticky &&
    css`
      position: fixed;
      top: 0;
      width: ${headerWidth}px;
    `}
`

export const TableRowFinished = styled(TableRow)`
  overflow: visible;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container shipowner company company';

  ${breakpoint.m`
     overflow: hidden;
     grid-template-rows: none;
     grid-template-columns: minmax(30px, 0.6fr) minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr 4fr;
     grid-template-areas:
    'status reservations index locationFrom locationTo container shipowner company offeror'
  `}
  &:hover {
    ${breakpoint.m`
     grid-template-columns: minmax(30px, 0.6fr) minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr 4fr;
     `}
  }
`

export const TableHeaderFinished = styled(TableRowFinished)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  position: static;
  &:hover {
    position: static;
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
    ${breakpoint.m`
     grid-template-columns: minmax(30px, 0.6fr) minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr 4fr;
    `}
  }
  ${breakpoint.m`
     display: grid;
  `}
  > ${HeaderItem} {
    position: relative;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
    &:hover {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    }
  }
  ${({ sticky, headerWidth }) =>
    sticky &&
    css`
      position: fixed;
      top: 0;
      width: ${headerWidth}px;
    `}
`
export const Company = styled.div`
  grid-area: company;
  display: flex;
  align-items: center;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    ${breakpoint.m`
    display: none;
    `}
  }
  > div {
    overflow: hidden;
  }
  & a:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

export const FreeCarrierTableHeaderOngoing = styled(TableHeader)`
  ${breakpoint.m`
     grid-template-columns: 1fr 2fr 3fr 3fr 2fr 2fr 3fr 2fr;
     grid-template-areas:
    'status index locationFrom locationTo container company offeror queue'
  `}
  &:hover {
    ${breakpoint.m`
     grid-template-columns: 1fr 2fr 3fr 3fr 2fr 2fr 3fr 2fr;
    `}
  }
`

export const FreeCarrierTableRowOngoing = styled(TableRow)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container company company';

  ${breakpoint.m`
     grid-template-columns: 1fr 2fr 3fr 3fr 2fr 2fr 3fr 2fr;
     grid-template-areas:
    'status index locationFrom locationTo container company offeror queue'
  `}
  &:hover {
    ${breakpoint.m`
     grid-template-columns: 1fr 2fr 3fr 3fr 2fr 2fr 3fr 2fr;
    `}
  }
`

export const FreeCarrierTableHeaderFinished = styled(TableHeaderFinished)`
  ${breakpoint.m`
     grid-template-columns: minmax(30px, 0.6fr) minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr;
     grid-template-areas:
    'status index locationFrom locationTo container company offeror'
  `}
  &:hover {
    ${breakpoint.m`
     grid-template-columns: minmax(30px, 0.6fr) minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr;
    `}
  }
`

export const FreeCarrierTableRowFinished = styled(TableRowFinished)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container company company';

  ${breakpoint.m`
    grid-template-columns: minmax(30px, 0.6fr) minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr;
     grid-template-areas:
    'status queue index locationFrom locationTo container company offeror'
  `}
  &:hover {
    ${breakpoint.m`
    grid-template-columns: minmax(30px, 0.6fr) minmax(30px, 0.6fr) 2fr 3fr 3fr 2fr 2fr 3fr;
        `}
  }
`

export const ReservationDetails = styled(TableRow)`
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(7, 1fr);
  grid-template-areas:
    'company company company company company company company'
    'offeror offeror offeror offeror offeror offeror offeror';
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  && {
    box-shadow: 0 6px 10px rgba(36, 45, 63, 0.15);
  }
  z-index: 1;
  margin-top: 0 !important;
  margin-bottom: 7px;
  & ${Offeror} > ${Avatar} {
    display: inline-block;
  }
  & ${Company} > svg {
    display: inline-block;
  }
  & > ${Offeror} {
    display: flex;
  }
  ${breakpoint.m`
    grid-template-areas: ' . company company offeror offeror offeror offeror offeror';
    `}
`

export const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: visible !important;

  grid-area: date;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-size: 0.75rem;
  font-weight: 400;
  ${breakpoint.m`
    display: none;
    `}
`
