import { connect } from 'react-redux'

import { setConversationOpenedRoutine } from 'src/features/messages/duck/actions'

import {
  refreshExportOfferRoutine,
  setExportDrawerRoutine,
  showExportOfferDetailsRoutine,
  updateExportOfferRoutine,
  makeExportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-export'

import {
  refreshImportOfferRoutine,
  setImportDrawerRoutine,
  showImportOfferDetailsRoutine,
  updateImportOfferRoutine,
  makeImportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-import'

import {
  refreshPostImportOfferRoutine,
  setPostImportDrawerRoutine,
  showPostImportOfferDetailsRoutine,
  updatePostImportOfferRoutine,
  makePostImportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-post-import'

import {
  refreshFreeCarrierOfferRoutine,
  setFreeCarriersDrawerRoutine,
  showFreeCarrierOfferDetailsRoutine,
  updateFreeCarrierOfferRoutine,
  makeFreeCarrierOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-free-carriers'

import {
  fillFormUsingTemplateExportRoutine,
  fillFormUsingTemplateImportRoutine,
  fillFormUsingTemplatePostImportRoutine,
  fillFormUsingTemplateFreeCarriersRoutine
} from 'src/features/offers/duck/actions'

import { selectCurrentUserId } from 'src/features/account/duck/selectors'

import {
  selectMyFinishedExportOffers,
  selectMyFinishedOffersExportFilters,
  selectMyOffersExportErrorMessage,
  selectMyOffersExportFilters,
  selectMyOffersExportPagination,
  selectMyOngoingExportOffers,
  selectSelectedExportOffers,
  selectMyFinishedOffersExportPagination,
  selectMyOngoingImportOffers,
  selectMyOffersImportFilters,
  selectMyOffersImportPagination,
  selectMyOffersImportErrorMessage,
  selectSelectedImportOffers,
  selectMyFinishedImportOffers,
  selectMyFinishedOffersImportFilters,
  selectMyFinishedOffersImportPagination,
  selectMyOngoingPostImportOffers,
  selectMyOffersPostImportFilters,
  selectMyOffersPostImportPagination,
  selectMyOffersPostImportErrorMessage,
  selectSelectedPostImportOffers,
  selectMyFinishedPostImportOffers,
  selectMyFinishedOffersPostImportFilters,
  selectMyFinishedOffersPostImportPagination,
  selectMyOngoingFreeCarrierOffers,
  selectOngoingFreeCarrierOffers,
  selectMyOffersFreeCarrierFilters,
  selectMyOffersFreeCarrierPagination,
  selectMyOffersFreeCarrierErrorMessage,
  selectSelectedFreeCarrierOffers,
  selectMyFinishedFreeCarrierOffers,
  selectMyFinishedOffersFreeCarrierFilters,
  selectMyFinishedOffersFreeCarrierPagination,
  selectMyOffersExportOngoingButtonsState,
  selectMyOffersExportFinishedButtonsState,
  selectMyOffersImportOngoingButtonsState,
  selectMyOffersImportFinishedButtonsState,
  selectMyOffersPostImportOngoingButtonsState,
  selectMyOffersPostImportFinishedButtonsState,
  selectMyOffersFreeCarrierFinishedButtonsState,
  selectMyOffersFreeCarrierOngoingButtonsState,
  selectFinishedExportOffers,
  selectFinishedFreeCarrierOffers,
  selectOngoingExportOffers,
  selectFinishedImportOffers,
  selectOngoingImportOffers,
  selectFinishedPostImportOffers,
  selectOngoingPostImportOffers
} from 'src/features/myOffers/duck/selectors'
import {
  clearSelectedExportOffersRoutine,
  deleteExportOfferRoutine,
  deleteMultipleExportOffersRoutine,
  getMyOngoingExportOffersRoutine,
  getMyFinishedExportOffersRoutine,
  selectAllExportOffersRoutine,
  setMyExportFiltersRoutine,
  setSelectedExportOfferRoutine,
  setMyFinishedExportFiltersRoutine,
  restoreExportOfferRoutine,
  showOngoingExportOfferReservationsRoutine,
  copyMyOfferToExportFormRoutine,
  showFinishedExportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-export'
import {
  clearSelectedImportOffersRoutine,
  copyMyOfferToImportFormRoutine,
  deleteImportOfferRoutine,
  deleteMultipleImportOffersRoutine,
  getMyFinishedImportOffersRoutine,
  getMyOngoingImportOffersRoutine,
  restoreImportOfferRoutine,
  selectAllImportOffersRoutine,
  setMyFinishedImportFiltersRoutine,
  setMyImportFiltersRoutine,
  setSelectedImportOfferRoutine,
  showFinishedImportOfferReservationsRoutine,
  showOngoingImportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-import'

import {
  clearSelectedPostImportOffersRoutine,
  copyMyOfferToPostImportFormRoutine,
  deletePostImportOfferRoutine,
  deleteMultiplePostImportOffersRoutine,
  getMyFinishedPostImportOffersRoutine,
  getMyOngoingPostImportOffersRoutine,
  restorePostImportOfferRoutine,
  selectAllPostImportOffersRoutine,
  setMyFinishedPostImportFiltersRoutine,
  setMyPostImportFiltersRoutine,
  setSelectedPostImportOfferRoutine,
  showFinishedPostImportOfferReservationsRoutine,
  showOngoingPostImportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-post-import'

import {
  clearSelectedFreeCarrierOffersRoutine,
  copyMyOfferToFreeCarrierFormRoutine,
  deleteFreeCarrierOfferRoutine,
  deleteMultipleFreeCarrierOffersRoutine,
  getMyFinishedFreeCarrierOffersRoutine,
  getMyOngoingFreeCarrierOffersRoutine,
  restoreFreeCarrierOfferRoutine,
  selectAllFreeCarrierOffersRoutine,
  setMyFinishedFreeCarrierFiltersRoutine,
  setMyFreeCarrierFiltersRoutine,
  setSelectedFreeCarrierOfferRoutine,
  showFinishedFreeCarrierOfferReservationsRoutine,
  showOngoingFreeCarrierOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-free-carrier'
import {
  acceptExportReservationRoutine,
  acceptFreeCarrierReservationRoutine,
  acceptImportReservationRoutine,
  acceptPostImportReservationRoutine,
  rejectExportReservationRoutine,
  rejectFreeCarrierReservationRoutine,
  rejectImportReservationRoutine,
  rejectPostImportReservationRoutine
} from 'src/features/reservations/duck/actions'
import { selectOfferForDebtCollectionRoutine } from 'src/features/debtCollection/duck/actions'

export const connectExportOngoing = connect(
  state => ({
    ongoingOffers: selectOngoingExportOffers(state),
    myOffers: selectMyOngoingExportOffers(state),
    filters: selectMyOffersExportFilters(state),
    pagination: selectMyOffersExportPagination(state),
    errorMessage: selectMyOffersExportErrorMessage(state),
    selectedOffers: selectSelectedExportOffers(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersExportOngoingButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingExportOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedExportOffersRoutine()),
    showOffer: id => dispatch(showExportOfferDetailsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setExportDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyExportFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updateExportOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshExportOfferRoutine({ id })),
    makeOfferPublic: id => dispatch(makeExportOfferPublicRoutine({ id })),

    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplateExportRoutine({ template })),
    setSelectedOffer: id => dispatch(setSelectedExportOfferRoutine({ id })),
    deleteMultipleOffers: () => dispatch(deleteMultipleExportOffersRoutine()),
    clearSelectedOffers: () => dispatch(clearSelectedExportOffersRoutine()),
    selectAllOffers: () => dispatch(selectAllExportOffersRoutine()),
    deleteOffer: id => dispatch(deleteExportOfferRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showOngoingExportOfferReservationsRoutine({ id })),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToExportFormRoutine({ offer })),
    acceptReservation: payload =>
      dispatch(acceptExportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectExportReservationRoutine(payload)),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectExportFinished = connect(
  state => ({
    offersFinished: selectFinishedExportOffers(state),
    myOffers: selectMyFinishedExportOffers(state),
    filters: selectMyFinishedOffersExportFilters(state),
    pagination: selectMyFinishedOffersExportPagination(state),
    errorMessage: selectMyOffersExportErrorMessage(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersExportFinishedButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingExportOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedExportOffersRoutine()),
    showOffer: id => dispatch(showExportOfferDetailsRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showFinishedExportOfferReservationsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setExportDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyFinishedExportFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updateExportOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshExportOfferRoutine({ id })),
    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplateExportRoutine({ template })),
    deleteOffer: (id, reason) =>
      dispatch(deleteExportOfferRoutine({ id, reason })),
    restoreOffer: id => dispatch(restoreExportOfferRoutine({ id })),
    acceptReservation: payload =>
      dispatch(acceptExportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectExportReservationRoutine(payload)),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToExportFormRoutine({ offer })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)

export const connectImportOngoing = connect(
  state => ({
    ongoingOffers: selectOngoingImportOffers(state),
    myOffers: selectMyOngoingImportOffers(state),
    filters: selectMyOffersImportFilters(state),
    pagination: selectMyOffersImportPagination(state),
    errorMessage: selectMyOffersImportErrorMessage(state),
    selectedOffers: selectSelectedImportOffers(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersImportOngoingButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingImportOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedImportOffersRoutine()),
    showOffer: id => dispatch(showImportOfferDetailsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setImportDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyImportFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updateImportOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshImportOfferRoutine({ id })),
    makeOfferPublic: id => dispatch(makeImportOfferPublicRoutine({ id })),
    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplateImportRoutine({ template })),
    setSelectedOffer: id => dispatch(setSelectedImportOfferRoutine({ id })),
    deleteMultipleOffers: () => dispatch(deleteMultipleImportOffersRoutine()),
    clearSelectedOffers: () => dispatch(clearSelectedImportOffersRoutine()),
    selectAllOffers: () => dispatch(selectAllImportOffersRoutine()),
    deleteOffer: id => dispatch(deleteImportOfferRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showOngoingImportOfferReservationsRoutine({ id })),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToImportFormRoutine({ offer })),
    acceptReservation: payload =>
      dispatch(acceptImportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectImportReservationRoutine(payload)),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectImportFinished = connect(
  state => ({
    finishedOffers: selectFinishedImportOffers(state),
    myOffers: selectMyFinishedImportOffers(state),
    filters: selectMyFinishedOffersImportFilters(state),
    pagination: selectMyFinishedOffersImportPagination(state),
    errorMessage: selectMyOffersImportErrorMessage(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersImportFinishedButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingImportOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedImportOffersRoutine()),
    showOffer: id => dispatch(showImportOfferDetailsRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showFinishedImportOfferReservationsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setImportDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyFinishedImportFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updateImportOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshImportOfferRoutine({ id })),
    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplateImportRoutine({ template })),
    deleteOffer: (id, reason) =>
      dispatch(deleteImportOfferRoutine({ id, reason })),
    restoreOffer: id => dispatch(restoreImportOfferRoutine({ id })),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToImportFormRoutine({ offer })),
    acceptReservation: payload =>
      dispatch(acceptImportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectImportReservationRoutine(payload)),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)

export const connectPostImportOngoing = connect(
  state => ({
    ongoingOffers: selectOngoingPostImportOffers(state),
    myOffers: selectMyOngoingPostImportOffers(state),
    filters: selectMyOffersPostImportFilters(state),
    pagination: selectMyOffersPostImportPagination(state),
    errorMessage: selectMyOffersPostImportErrorMessage(state),
    selectedOffers: selectSelectedPostImportOffers(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersPostImportOngoingButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingPostImportOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedPostImportOffersRoutine()),
    showOffer: id => dispatch(showPostImportOfferDetailsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setPostImportDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyPostImportFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updatePostImportOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshPostImportOfferRoutine({ id })),
    makeOfferPublic: id => dispatch(makePostImportOfferPublicRoutine({ id })),
    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplatePostImportRoutine({ template })),
    setSelectedOffer: id => dispatch(setSelectedPostImportOfferRoutine({ id })),
    deleteMultipleOffers: () =>
      dispatch(deleteMultiplePostImportOffersRoutine()),
    clearSelectedOffers: () => dispatch(clearSelectedPostImportOffersRoutine()),
    selectAllOffers: () => dispatch(selectAllPostImportOffersRoutine()),
    deleteOffer: id => dispatch(deletePostImportOfferRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showOngoingPostImportOfferReservationsRoutine({ id })),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToPostImportFormRoutine({ offer })),
    acceptReservation: payload =>
      dispatch(acceptPostImportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectPostImportReservationRoutine(payload)),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectPostImportFinished = connect(
  state => ({
    finishedOffers: selectFinishedPostImportOffers(state),
    myOffers: selectMyFinishedPostImportOffers(state),
    filters: selectMyFinishedOffersPostImportFilters(state),
    pagination: selectMyFinishedOffersPostImportPagination(state),
    errorMessage: selectMyOffersPostImportErrorMessage(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersPostImportFinishedButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingPostImportOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedPostImportOffersRoutine()),
    showOffer: id => dispatch(showPostImportOfferDetailsRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showFinishedPostImportOfferReservationsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setPostImportDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyFinishedPostImportFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updatePostImportOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshPostImportOfferRoutine({ id })),
    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplatePostImportRoutine({ template })),
    deleteOffer: (id, reason) =>
      dispatch(deletePostImportOfferRoutine({ id, reason })),
    restoreOffer: id => dispatch(restorePostImportOfferRoutine({ id })),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToPostImportFormRoutine({ offer })),
    acceptReservation: payload =>
      dispatch(acceptPostImportReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectPostImportReservationRoutine(payload)),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)

export const connectFreeCarrierOngoing = connect(
  state => ({
    ongoingOffers: selectOngoingFreeCarrierOffers(state),
    myOffers: selectMyOngoingFreeCarrierOffers(state),
    filters: selectMyOffersFreeCarrierFilters(state),
    pagination: selectMyOffersFreeCarrierPagination(state),
    errorMessage: selectMyOffersFreeCarrierErrorMessage(state),
    selectedOffers: selectSelectedFreeCarrierOffers(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersFreeCarrierOngoingButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingFreeCarrierOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedFreeCarrierOffersRoutine()),
    showOffer: id => dispatch(showFreeCarrierOfferDetailsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setFreeCarriersDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyFreeCarrierFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updateFreeCarrierOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshFreeCarrierOfferRoutine({ id })),
    makeOfferPublic: id => dispatch(makeFreeCarrierOfferPublicRoutine({ id })),
    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplateFreeCarriersRoutine({ template })),
    setSelectedOffer: id =>
      dispatch(setSelectedFreeCarrierOfferRoutine({ id })),
    deleteMultipleOffers: () =>
      dispatch(deleteMultipleFreeCarrierOffersRoutine()),
    clearSelectedOffers: () =>
      dispatch(clearSelectedFreeCarrierOffersRoutine()),
    selectAllOffers: () => dispatch(selectAllFreeCarrierOffersRoutine()),
    deleteOffer: id => dispatch(deleteFreeCarrierOfferRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showOngoingFreeCarrierOfferReservationsRoutine({ id })),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToFreeCarrierFormRoutine({ offer })),
    acceptReservation: payload =>
      dispatch(acceptFreeCarrierReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectFreeCarrierReservationRoutine(payload)),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectFreeCarrierFinished = connect(
  state => ({
    finishedOffers: selectFinishedFreeCarrierOffers(state),
    myOffers: selectMyFinishedFreeCarrierOffers(state),
    filters: selectMyFinishedOffersFreeCarrierFilters(state),
    pagination: selectMyFinishedOffersFreeCarrierPagination(state),
    errorMessage: selectMyOffersFreeCarrierErrorMessage(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersFreeCarrierFinishedButtonsState(state)
  }),
  dispatch => ({
    getOngoingOffers: () => dispatch(getMyOngoingFreeCarrierOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedFreeCarrierOffersRoutine()),
    showOffer: id => dispatch(showFreeCarrierOfferDetailsRoutine({ id })),
    showOffersReservations: id =>
      dispatch(showFinishedFreeCarrierOfferReservationsRoutine({ id })),
    setDrawerState: drawerState =>
      dispatch(setFreeCarriersDrawerRoutine({ drawerState })),
    setFilter: (name, value) =>
      dispatch(setMyFinishedFreeCarrierFiltersRoutine({ name, value })),
    updateOffer: (id, values) =>
      dispatch(updateFreeCarrierOfferRoutine({ id, values })),
    refreshOffer: id => dispatch(refreshFreeCarrierOfferRoutine({ id })),
    fillFormUsingTemplate: template =>
      dispatch(fillFormUsingTemplateFreeCarriersRoutine({ template })),
    deleteOffer: (id, reason) =>
      dispatch(deleteFreeCarrierOfferRoutine({ id, reason })),
    restoreOffer: id => dispatch(restoreFreeCarrierOfferRoutine({ id })),
    copyMyOfferToForm: offer =>
      dispatch(copyMyOfferToFreeCarrierFormRoutine({ offer })),
    acceptReservation: payload =>
      dispatch(acceptFreeCarrierReservationRoutine(payload)),
    rejectReservation: payload =>
      dispatch(rejectFreeCarrierReservationRoutine(payload)),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    selectOfferForDebtCollection: payload =>
      dispatch(selectOfferForDebtCollectionRoutine(payload))
  })
)
