import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import moment from 'moment'
import { DateTime, Ellipsis } from 'src/features/stocks/components/atoms'
import { breakpoint } from 'src/theme/grid'
import {
  ContractLocations,
  EllipsisContainer
} from 'src/features/contracts/components/atoms'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import NoteIconUrl from 'src/assets/icons/note.svg'
import MoneyIconUrl from 'src/assets/icons/money.svg'
import * as routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import PdfIcon from 'src/assets/icons/pdf.svg'
import { getInvoiceStatus } from 'src/features/invoices/duck/helpers'
import InvoiceStatus from 'src/features/invoices/components/InvoiceStatus'
import { DATE_FORMAT } from 'src/ducks/consts'
import { INVOICES_STATES } from 'src/features/invoices/duck/consts'
import IconWithTooltip from 'src/components/IconWithTooltip'
import { Date } from 'src/features/invoices/components/InvoiceRowIssued'

import { connectCompany } from 'src/features/account/duck/connectors'

const MobileTableRow = ({
  invoice,
  className,
  downloadInvoicePdf,
  markAsPaid,
  overdue,
  companyName
}) => {
  const status = getInvoiceStatus(invoice)

  const isInFuture = moment(invoice.issueDate).isAfter(moment(), 'day')

  const isInvoiceOwner = invoice.issuerCompanyName === companyName

  const handleOfferNumberClick = () => {
    navigate(
      invoice.isCorrectiveInvoice
        ? `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CORRECTIVE_INVOICE_DETAILS}&id=${invoice.id}`
        : `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.id}`
    )
  }

  const hasCorrectiveInvoice = !!invoice.lastCorrectiveInvoice.id

  return (
    <>
      <div className={className}>
        <Row>
          <InvoiceStatus status={status} />
          <ActionsMenu>
            <DropdownItem
              onClick={e => {
                e.stopPropagation()
                downloadInvoicePdf(invoice.id)
              }}
            >
              <PdfIcon />
              <FormattedMessage id='orders.downloadPdf' />
            </DropdownItem>
            {isInvoiceOwner && (
              <DropdownItem
                disabled={status === INVOICES_STATES.FULLY_PAID || isInFuture}
                onClick={e => {
                  e.stopPropagation()
                  markAsPaid(true)
                }}
              >
                <MoneyIcon />
                <FormattedMessage id='invoices.markAsPaid' />
              </DropdownItem>
            )}
          </ActionsMenu>
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <OfferLink onClick={handleOfferNumberClick}>
              {invoice.number}
            </OfferLink>
            {invoice.correctiveNotesCount > 0 && (
              <IconWithTooltip
                icon={<NoteIcon />}
                title={<FormattedMessage id='invoices.correctionNote' />}
              />
            )}
            {invoice.isCorrectiveInvoice && (
              <Small>
                &nbsp;do&nbsp;
                <OfferLink
                  onClick={() =>
                    navigate(
                      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoice.invoice.id}`
                    )
                  }
                >
                  {invoice.invoice.number}
                </OfferLink>
              </Small>
            )}
            {hasCorrectiveInvoice && (
              <Small>
                &nbsp;kor.&nbsp;
                <OfferLink
                  onClick={() =>
                    navigate(
                      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CORRECTIVE_INVOICE_DETAILS}&id=${invoice.lastCorrectiveInvoice.id}`
                    )
                  }
                >
                  {invoice.lastCorrectiveInvoice.number}
                </OfferLink>
              </Small>
            )}
          </div>
        </Row>
        <Row>
          <Subtitle>
            <FormattedMessage id='invoices.receiver' />
          </Subtitle>
          <Subtitle>
            <FormattedMessage id='stocksHeaders.amount' />
          </Subtitle>
        </Row>
        <Row>
          <div>
            <ReceiverRow>
              <EllipsisContainer>
                <Ellipsis>{invoice.recipientCompanyName}</Ellipsis>
              </EllipsisContainer>
            </ReceiverRow>
          </div>
          <div>
            <ReceiverRow>
              <EllipsisContainer>
                <Ellipsis>{invoice.total.formatted}</Ellipsis>
              </EllipsisContainer>
            </ReceiverRow>
          </div>
        </Row>
        <Subtitle>NR ZLECENIA</Subtitle>
        <ReceiverRow>
          <EllipsisContainer>
            {invoice.transportationOrder.referenceNumber ? (
              <OfferLink
                onClick={() =>
                  navigate(
                    `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${invoice.transportationOrder.referenceNumber}`
                  )
                }
              >
                {invoice.transportationOrder.referenceNumber}
              </OfferLink>
            ) : (
              '-'
            )}
          </EllipsisContainer>
        </ReceiverRow>
        <DatesRow>
          <div>
            <Subtitle>
              <FormattedMessage id='stocksHeaders.issued' />
            </Subtitle>
            <DetailsRow>
              <div>
                <EllipsisTooltip>
                  <span>
                    {invoice.issueDate &&
                      moment(invoice.issueDate).format(DATE_FORMAT)}
                  </span>
                </EllipsisTooltip>
              </div>
            </DetailsRow>
          </div>
          <div>
            <Subtitle>
              <FormattedMessage id='invoices.paymentDate' />
            </Subtitle>
            <DetailsRow>
              <div>
                <EllipsisTooltip>
                  <Date overdue={overdue}>
                    {invoice.dueDate &&
                      moment(invoice.dueDate).format(DATE_FORMAT)}
                  </Date>
                </EllipsisTooltip>
              </div>
            </DetailsRow>
          </div>
        </DatesRow>
      </div>
    </>
  )
}

const ConnectedComponent = compose(connectCompany)(MobileTableRow)

const ReceiverRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  ${DateTime} {
    text-align: left;
  }
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: -0.4rem;
`
const MoreContractsCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  cursor: pointer;
  font-size: 0.75rem;
`

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const Row = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.75rem;
  font-weight: normal;
  & > div {
    overflow: hidden;
    padding: 0 0.5rem;
    border-right: 1px solid
      ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border: none;
    }
  }
`

const MoneyIcon = styled(MoneyIconUrl)`
  width: 24px;
  height: 24px;
`

export default styled(ConnectedComponent)`
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(36, 45, 63, 0.15);
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  & > div {
    margin-top: 0.4rem;
    &:first-child {
      margin-top: 0;
    }
  }
  ${ContractLocations} {
    font-size: 0.875rem;
    padding-top: 0;
    ${MoreContractsCounter} {
      margin-left: auto;
    }
  }
  ${breakpoint.m`
      display: none;
    `}
`

const DatesRow = styled.div`
  display: flex;

  ${Subtitle} {
    margin-bottom: 0;
  }
  & > div {
    width: 50%;
  }
`
const NoteIcon = styled(NoteIconUrl)`
  opacity: 0.8;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  margin-left: 8px;
  flex-shrink: 0;
`

const Small = styled.div`
  font-size: 0.75rem;
  & > a {
    font-size: 0.75rem;
  }
`
