import React from 'react'
import styled from 'styled-components'
import { DATE_SHORT_FORMAT_WITH_TIME } from 'src/ducks/consts'
import { PrivateLabel } from 'src/features/offers/components/atoms'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import IconWithTooltip from 'src/components/IconWithTooltip'
import WarningIcon from 'src/assets/icons/warning.svg'
import { WarningOutlined, WarningSharp } from '@material-ui/icons'

const GoPublicAt = ({ offer }) => {
  const { goPublicAt } = offer
  return goPublicAt ? (
    <GoPublicRow>
      {goPublicAt.format(DATE_SHORT_FORMAT_WITH_TIME)}
      {goPublicAt.isBefore() && ' ?'}
    </GoPublicRow>
  ) : null
}

const GoPublicRow = styled.span`
  font-size: 0.7rem;
  color: black;
  text-align: left;
  padding: 0;
  margin: 0;
  display: flex;
`

export default GoPublicAt
