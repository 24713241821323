import React from 'react'
import { isNil } from 'ramda'
import { isArray, isString } from 'ramda-adjunct'
import styled, { css } from 'styled-components'
import { mapNotificationCodeToIcon } from '../duck/consts'
import OfferDetails from './OfferDetails'
import OrderDetails from './OrderDetails'
import InvoiceDetails from './InvoiceDetails'

const Notification = ({ className, notification, onClick = () => {} }) => (
  <div className={className} onClick={onClick}>
    <InformationGroup>
      <TypeIcon type={notification.type}>
        {mapNotificationCodeToIcon(notification.code)}
      </TypeIcon>
      <Details>
        <Row>
          <Subject>
            {notification.subject}
            {notification.coworkerName && (
              <span>{` (${notification.coworkerName})`}</span>
            )}
          </Subject>
        </Row>
        {!isNil(notification.offer) && (
          <Row>
            <OfferDetails offer={notification.offer} />
          </Row>
        )}
        {!isNil(notification.body) && (
          <Row>
            {isString(notification.body) && (
              <TextBody>{notification.body}</TextBody>
            )}
            {isArray(notification.body) && (
              <Column>
                {notification.body.map(elem => (
                  <ContentBodyWrapper>
                    <ContentTitle>{elem.title}</ContentTitle>
                    <TextBody>{elem.value}</TextBody>
                  </ContentBodyWrapper>
                ))}
              </Column>
            )}
          </Row>
        )}
        {!isNil(notification.transportationOrder) &&
          isNil(notification.invoice) && (
            <Row>
              <OrderDetails order={notification.transportationOrder} />
            </Row>
          )}
        {!isNil(notification.invoice) && (
          <Row>
            <InvoiceDetails invoice={notification.invoice} />
          </Row>
        )}
        <Row>
          {notification.cta.title && (
            <RefNumber href={notification.cta.url}>
              {notification.cta.title}
            </RefNumber>
          )}
          {!notification.cta.title && notification.offer?.referenceNumber && (
            <RefNumber disabled>
              {notification.offer?.referenceNumber}
            </RefNumber>
          )}
          <Time>{notification.createdAt}</Time>
        </Row>
      </Details>
      <IconWrapper>{isNil(notification.readAt) && <UnseenDot />}</IconWrapper>
    </InformationGroup>
  </div>
)
export default styled(Notification)`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0.7rem 0;
  cursor: pointer;
  &:after {
    content: '';
    width: 85%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ type }) => css`
    background-color: ${({ theme }) => {
      switch (type) {
        case 'error':
          return theme.colors.fadedRed.alpha(0.05).hex()
        case 'warning':
          return theme.colors.fadedRed.alpha(0.05).hex()
        default:
          return theme.colors.easternBlue.alpha(0.05).hex()
      }
    }};
  `}
  ${({ marked }) =>
    !marked &&
    css`
      background-color: transparent;
    `}
`

const InformationGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 73%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TypeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 0 14px 0 18px;
  background-color: ${({ theme, type }) =>
    type !== 'info'
      ? theme.colors.fadedRed.alpha(0.2).hex()
      : theme.colors.easternBlue.alpha(0.2).hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const Subject = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 0.88rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-right: 0.6rem;
  span {
    display: inline;
    font-size: 0.75rem;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
`

const UnseenDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
  border-radius: 50%;
  position: relative;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) =>
      theme.colors.easternBlue.alpha(0.15).hex()};
    border-radius: 50%;
    position: absolute;
    top: -2px;
    left: -2px;
  }
`

const Time = styled.span`
  display: inline-block;
  font-size: 0.67rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.6;
  font-weight: 500;
  vertical-align: center;
  line-height: 1.3;
`

const RefNumber = styled.a`
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-size: 0.67rem;
  vertical-align: center;
  line-height: 1.3;
  &:after {
    content: '.';
    font-size: 0.68rem;
    color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    position: relative;
    top: -3px;
    margin: 0 0.2rem;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

const TextBody = styled.span`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  line-height: 1.56;
`

const ContentBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.3rem 0.6rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.geyser.alpha(0.3).hex()};
  border-radius: 4px;
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
`

const ContentTitle = styled.span`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.5).hex()};
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
`
