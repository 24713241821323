import React from 'react'
import { FormattedMessage, useIntl, Link, navigate } from 'gatsby-plugin-intl'
import moment from 'moment'
import { pathOr } from 'ramda'
import styled, { css } from 'styled-components'
import { isFalsy } from 'ramda-adjunct'
import {
  AdditionalInfo,
  Description,
  Title
} from 'src/features/stocks/components/typography'
import {
  CreatedDate,
  GavelIcon,
  OfferRemovedInformation,
  OfferRemovedInformationText,
  OfferRemovedInformationTextDetails,
  Separator,
  TitleRow
} from 'src/features/stocks/components/atoms'
import { DATE_LONG_FORMAT } from 'src/ducks/consts'
import AuctionPrice from 'src/components/atoms/AuctionPrice'
import Adr from 'src/components/atoms/Adr'
import Genset from 'src/components/atoms/Genset'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import {
  BidButton,
  ChatButton,
  DelayedOfferButton,
  ReservationsButton
} from 'src/components/atoms/Buttons'
import VisibilityIcon from 'src/assets/icons/visibility.svg'
import RefreshIcon from 'src/assets/icons/refresh.svg'
import Euro from 'src/components/atoms/Euro'
import ChatIcon from 'src/assets/icons/icon_chat.svg'
import Alert from 'src/components/Alert'
import XIcon from 'src/assets/icons/close-x.svg'
import ImportIcon from 'src/assets/icons/import.svg'
import ExportIcon from 'src/assets/icons/export.svg'
import HookIcon from 'src/assets/icons/hook-no-container.svg'
import { breakpoint } from 'src/theme/grid'
import { CONTRACTS_TYPES } from 'src/features/contracts/duck/consts'
import ContractIcon from 'src/assets/icons/description.svg'
import TrackingIcon from 'src/assets/icons/track.svg'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import { Status } from 'src/features/myOffers/components/StatusIcon'
import { FINISHED_OFFER_STATES } from 'src/features/myOffers/duck/consts'
import CustomIcon from 'src/components/CustomIcon'
import IconWithTooltip from 'src/components/IconWithTooltip'
import { PrivateLabel } from 'src/features/offers/components/atoms'
// import { PrivateOfferCountdown } from 'src/features/stocks/components/OfferDetails/atoms'

export const DrawerTitle = ({ offerDetails, setDrawerState }) => (
  <TitleRow>
    <ReferenceNumberRow>
      <div>
        {offerDetails && (
          <Link to={createOfferDetailsLink(offerDetails.referenceNumber)}>
            {offerDetails.referenceNumber}
          </Link>
        )}
      </div>
      <div>{offerDetails.isPrivate && <PrivateLabel />}</div>
    </ReferenceNumberRow>
    {setDrawerState && <CloseIcon onClick={() => setDrawerState(false)} />}
  </TitleRow>
)
export const OfferRemovedInfo = () => {
  const intl = useIntl()
  return (
    <>
      <OfferRemovedInformation>
        <HookIcon />
      </OfferRemovedInformation>
      <OfferRemovedInformationText>
        {intl.formatMessage({ id: 'offers.offerRemoved' })}
      </OfferRemovedInformationText>
      <OfferRemovedInformationTextDetails>
        {intl.formatMessage({ id: 'offers.offerRemovedDetails' })}
      </OfferRemovedInformationTextDetails>
    </>
  )
}

export const OfferSoldInfo = ({ status }) => {
  const intl = useIntl()
  if (!status) return null

  const alertType = {
    [FINISHED_OFFER_STATES.SOLD]: 'success',
    [FINISHED_OFFER_STATES.ANNULLED]: 'alert',
    [FINISHED_OFFER_STATES.FINISHED_INCONCLUSIVELY]: 'warning',
    [FINISHED_OFFER_STATES.FINISHED_WITHOUT_RESERVATIONS]: 'warning'
  }[status]

  return (
    <Alert
      type={alertType}
      icon={<Status status={status} />}
      message={intl.formatMessage({ id: `offerDetails.states.${status}` })}
    />
  )
}

export const OfferBelongsToCurrentUserInfo = () => {
  const intl = useIntl()
  return (
    <div>
      <Alert
        type='info'
        message={intl.formatMessage({
          id: 'offerDetails.yourCompanyOffer'
        })}
      />
    </div>
  )
}

export const OfferHasTrackingInfo = () => {
  const intl = useIntl()
  return (
    <div>
      <Alert
        title={intl.formatMessage({ id: 'offerDetails.tracking' })}
        icon={<TrackingIconWithBackground />}
        type='wildSand'
        message={intl.formatMessage({
          id: 'offerDetails.trackingIsRequired'
        })}
      />
    </div>
  )
}

export const AuctionPriceOfferDetails = ({ offerDetails, hidePrice }) => (
  <div>
    <AuctionPrice
      deadline={offerDetails.auctionDeadline}
      price={offerDetails.price}
      isPriceVisible={!hidePrice}
    />
  </div>
)
export const CustomsClearance = ({ offerDetails }) => (
  <div>
    <Title>
      <FormattedMessage id='forms.customsClearanceLocationLabel' />
    </Title>
    <Description>{offerDetails.customsClearanceLocation.name}</Description>
  </div>
)
export const ContainerInfo = ({ offerDetails }) => (
  <Container>
    <div>
      <Title>
        <FormattedMessage id='offerDetails.container' />
      </Title>
      <Description>
        {`${offerDetails.container.name} | ${offerDetails.shipowner.name}`}
      </Description>
      <AdditionalInfo>
        {offerDetails.weight && (
          <FormattedMessage
            id='offerDetails.weight'
            values={{ weight: offerDetails.weight }}
          />
        )}
        {offerDetails.weight && offerDetails.cargoType && ', '}
        {pathOr('', ['cargoType', 'name'])(offerDetails)}
      </AdditionalInfo>
    </div>
    <BadgesContainer>
      {offerDetails.europeanEmissionStandard && (
        <Euro euroClass={offerDetails.europeanEmissionStandard} />
      )}
      {offerDetails.adrClassId && (
        <Adr
          adrClass={
            isFalsy(offerDetails.adrClass) ? null : offerDetails.adrClass.class
          }
        />
      )}
    </BadgesContainer>
  </Container>
)
export const ContainerSemiTrailerInfo = ({ offerDetails }) => (
  <Container>
    <div>
      <Title>
        <FormattedMessage id='offerDetails.containerSemiTrailer' />
      </Title>
      <Description>{offerDetails.containerSemiTrailer.name}</Description>
    </div>
    <BadgesContainer>
      {offerDetails.europeanEmissionStandard && (
        <Euro euroClass={offerDetails.europeanEmissionStandard} />
      )}
      {offerDetails.adrClassId && (
        <Adr
          adrClass={
            isFalsy(offerDetails.adrClass) ? null : offerDetails.adrClass.class
          }
        />
      )}
      {offerDetails.isSemiTrailerWithGenset && <Genset />}
    </BadgesContainer>
  </Container>
)
export const Notes = ({ offerDetails }) => {
  const isAuction = offerDetails.type === OFFER_TYPES.AUCTION

  return (
    <div>
      <Title>
        {!isAuction && <FormattedMessage id='offerDetails.additionalInfo' />}
        {isAuction && <FormattedMessage id='offerDetails.auctionConditions' />}
      </Title>
      <Description>{offerDetails.notes}</Description>
    </div>
  )
}
export const Price = ({ offerDetails }) => (
  <div>
    <Title>
      <FormattedMessage id='offerDetails.price' />
    </Title>
    <Description>{offerDetails.price.formatted}</Description>
  </div>
)

export const ContractDates = ({ offerDetails, withIcon }) => (
  <Container>
    <div>
      <Title>
        <FormattedMessage id='offerDetails.contract' />
      </Title>
      <Description>
        <span>
          <FormattedMessage id='common.from' />{' '}
        </span>
        {offerDetails.startDateFormatted}
        <span>
          {' '}
          <FormattedMessage id='common.to' />{' '}
        </span>
        {offerDetails.endDateFormatted}
      </Description>
    </div>
    <BadgesContainer>
      {withIcon && <ContractIcon style={{ opacity: 0.7 }} />}
    </BadgesContainer>
  </Container>
)

export const Type = ({ offerDetails }) => (
  <Container>
    <div>
      <Title>
        <FormattedMessage id='offerDetails.type' />
      </Title>
      <Description>
        {offerDetails.type === CONTRACTS_TYPES.EXPORT ? (
          <FormattedMessage id='common.export' />
        ) : (
          offerDetails.type
        )}
      </Description>
    </div>
    <BadgesContainer>
      {offerDetails.type === CONTRACTS_TYPES.EXPORT && (
        <ExportIcon style={{ opacity: 0.7 }} />
      )}
      {offerDetails.type === CONTRACTS_TYPES.IMPORT && (
        <ImportIcon style={{ opacity: 0.7 }} />
      )}
    </BadgesContainer>
  </Container>
)

export const CreatedDateOfferDetails = ({ offerDetails }) => (
  <CreatedDate>
    <Separator />
    <div>
      {offerDetails.createdAt && (
        <p>
          <FormattedMessage
            id='offerDetails.createdAt'
            defaultMessage={' '}
            values={{
              date: moment(offerDetails.createdAt).format(DATE_LONG_FORMAT)
            }}
          />
        </p>
      )}
      {offerDetails.updatedAt && (
        <p>
          <FormattedMessage
            id='offerDetails.updatedAt'
            defaultMessage={' '}
            values={{
              date: moment(offerDetails.updatedAt).format(DATE_LONG_FORMAT)
            }}
          />
        </p>
      )}
    </div>
  </CreatedDate>
)
export const ButtonsForCustomer = ({
  offerDetails,
  setBidDialog,
  stock,
  reserveOffer,
  setConversationOpened,
  showPrivateOfferCountdown = true
}) => (
  <BottomButtons>
    {offerDetails.isPrivate && showPrivateOfferCountdown && (
      <PrivateOfferCountdownRow>
        <PrivateOfferCountdown offerDetails={offerDetails} />
      </PrivateOfferCountdownRow>
    )}
    <ButtonsRow>
      {offerDetails.type === OFFER_TYPES.AUCTION && (
        <>
          <DelayedOfferButton
            active={offerDetails.isBiddable}
            onClick={() => setBidDialog(true)}
            offerId={offerDetails.id}
            availableTime={offerDetails.bidActionAvailableAt}
          >
            <FormattedMessage id='offerDetails.makeABid' />
            <span>
              <GavelIcon />
            </span>
          </DelayedOfferButton>
        </>
      )}
      {offerDetails.type === OFFER_TYPES.QUEUE && (
        <DelayedOfferButton
          active={!stock.isLoading('buttons') && offerDetails.isReservable}
          loading={stock.isLoading('buttons')}
          onClick={() => reserveOffer(offerDetails.id)}
          offerId={offerDetails.id}
          availableTime={offerDetails.reserveActionAvailableAt}
        >
          <FormattedMessage
            id='offerDetails.reserveOffer'
            values={{ reservationsCount: offerDetails.reservationsCount }}
          />
        </DelayedOfferButton>
      )}
      <ChatButton
        active={offerDetails.id}
        onClick={() =>
          setConversationOpened({
            receiverId: offerDetails.handlerId,
            contextId: offerDetails.id,
            contextType: offerDetails.offerContextType,
            conversationOpened: true
          })
        }
      >
        <FormattedMessage id='offerDetails.startChat' />
        <span>
          <ChatIcon />
        </span>
      </ChatButton>
    </ButtonsRow>
  </BottomButtons>
)
export const ButtonsForOwner = ({
  offerDetails,
  stock,
  refreshOffer,
  navigateToMyOffers,
  showPrivateOfferCountdown = true
}) => (
  <BottomButtons>
    {offerDetails.isPrivate && showPrivateOfferCountdown && (
      <PrivateOfferCountdownRow>
        <PrivateOfferCountdown offerDetails={offerDetails} />
      </PrivateOfferCountdownRow>
    )}
    <ButtonsRow>
      {offerDetails.type === OFFER_TYPES.AUCTION && (
        <BidButton active={false}>
          <FormattedMessage id='offerDetails.makeABid' />
          <span>
            <GavelIcon />
          </span>
        </BidButton>
      )}
      {offerDetails.type === OFFER_TYPES.QUEUE && (
        <ReservationsButton
          onClick={() =>
            navigate(createOfferDetailsLink(offerDetails.referenceNumber))
          }
          active={navigateToMyOffers}
        >
          <FormattedMessage
            id='offerDetails.reservations'
            values={{ reservationsCount: offerDetails.reservationsCount }}
          />
          <span>
            <VisibilityIcon />
          </span>
        </ReservationsButton>
      )}
      {offerDetails.type === OFFER_TYPES.QUEUE && (
        <ChatButton
          active={!stock.isLoading('buttons') && offerDetails.isRefreshable}
          loading={stock.isLoading('buttons')}
          onClick={() => refreshOffer(offerDetails.id)}
        >
          <FormattedMessage id='offerDetails.refreshOffer' />
          <span>
            <RefreshIcon />
          </span>
        </ChatButton>
      )}
    </ButtonsRow>
  </BottomButtons>
)
export const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`
const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-top: 4px;
    &:first-of-type {
      margin-top: 0;
    }
  }
`
export const BottomButtons = styled.div`
  position: fixed;
  z-index: 22;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  bottom: 0;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  ${breakpoint.m`
       position: absolute;
  `}
`

const Container = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const MiddleContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  & > div {
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
`

export const OfferRemovedInfoDetails = styled.div`
  font-size: 12px;
`

const TrackingIconWithBackgroundComponent = ({ className }) => (
  <div className={className}>
    <TrackingIcon />
  </div>
)

export const CountdownTimer = ({ endTime }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endTime) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft())

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <div>
      {timeLeft.days ? `${timeLeft.days} dni, ` : ''}
      {` ${timeLeft.hours || 0} godzin, ${timeLeft.minutes ||
        0} minut, ${timeLeft.seconds || 0} sekund`}
    </div>
  )
}

export const PrivateOfferCountdown = ({ offerDetails }) => {
  return (
    <PrivateOfferWrapper>
      <PrivateOffer>
        {/* <Countdown publicAt={offerDetails.publicAt} /> */}
        {/* CustomIcon name='visibility' size={25} /> */}
        <PrivateLabel />
        {/* {offerDetails.publicAt && offerDetails.publicAt.format(DATE_LONG_FORMAT)} */}
        {offerDetails.goPublicAt ? (
          <CountdownTimer endTime={offerDetails.goPublicAt} />
        ) : (
          'Oferta nieupubliczniana automatycznie'
        )}
        <IconWithTooltip title='O tym czasie oferta stanie się ofertą publiczną, o ile nie ma aktywnych rezerwacji dla oferty.' />
      </PrivateOffer>
    </PrivateOfferWrapper>
  )
}

const PrivateOfferWrapper = styled.div`
  padding-left: 16px;
`

export const ReferenceNumberRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;
`

export const TrackingIconWithBackground = styled(
  TrackingIconWithBackgroundComponent
)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 60%;
    height: 60%;
  }
  ${({ small }) =>
    small &&
    css`
      width: 24px;
      height: 24px;
    `}
`

const PrivateOffer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.text};
  svg {
    margin-right: 8px;
  }
`
const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
`

const PrivateOfferCountdownRow = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: flex-start;
`
