import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { isNil } from 'ramda'
import { useIntl } from 'gatsby-plugin-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import MUIDoneIcon from '@material-ui/icons/Done'
import MUICloseIcon from '@material-ui/icons/Close'
import MUIDownArrowIcon from '@material-ui/icons/KeyboardArrowDown'
import MUIUpArrowIcon from '@material-ui/icons/KeyboardArrowUp'

import useClickOutside from 'src/hooks/useClickOutside'
import { DelayedOfferButton } from 'src/components/atoms/Buttons'
import Alert from 'src/components/Alert'
import TrackingIcon from 'src/assets/icons/track.svg'

const ActionMenu = ({
  className,
  onClose = () => {},
  context = {},
  reserveOffer,
  acceptReservation,
  rejectReservation,
  cancelReservation,
  getContextAvailableActions,
  conversation,
  isConversationContextLoading,
  conversationContextActions
}) => {
  const [subMenuOpened, setSubMenuOpened] = useState(false)
  const intl = useIntl()
  const componentRef = useClickOutside(onClose)
  useEffect(() => {
    getContextAvailableActions(conversation.conversationId)
  }, [])

  const noActions =
    !conversationContextActions.isReservable &&
    !conversationContextActions.isRejectable &&
    !conversationContextActions.isAcceptable &&
    !conversationContextActions.isCancellable

  return (
    <div className={className} ref={componentRef}>
      {context.doesRequireTracking && (
        <AlertStyled
          title={intl.formatMessage({ id: 'offerDetails.tracking' })}
          icon={<TrackingIconWithBackground />}
          type='wildSand'
          message={intl.formatMessage({
            id: 'offerDetails.trackingIsRequired'
          })}
        />
      )}
      <LinksGroup>
        {isConversationContextLoading && (
          <PopupNavigationElement>
            <Center>
              <Loader />
            </Center>
          </PopupNavigationElement>
        )}
        {!isConversationContextLoading && (
          <>
            {conversationContextActions.isReservable && (
              <PopupNavigationElement noPadding>
                <DelayedOfferButton
                  component={ReservationButton}
                  active={true}
                  onClick={() => {
                    onClose()
                    reserveOffer({
                      contextType: context.contextType,
                      offerId: context.id
                    })
                  }}
                  offerId={context.id}
                  availableTime={context.reserveActionAvailableAt}
                >
                  <CheckIcon />{' '}
                  {intl.formatMessage({ id: 'messages.reserveOffer' })}(
                  {conversationContextActions.reservationsCount}/3)
                </DelayedOfferButton>
              </PopupNavigationElement>
            )}
            {conversationContextActions.isAcceptable && (
              <>
                <PopupNavigationElement
                  onClick={() => {
                    setSubMenuOpened(!subMenuOpened)
                  }}
                >
                  <CheckIcon /> {subMenuOpened ? <UpArrow /> : <DownArrow />}
                  {intl.formatMessage({ id: 'messages.acceptReservation' })}
                </PopupNavigationElement>
                {subMenuOpened && (
                  <>
                    <SubmenuItem
                      onClick={() => {
                        onClose()
                        acceptReservation({
                          contextType: context.contextType,
                          offerId: context.id,
                          reservationId:
                            conversationContextActions.reservationId,
                          withTracking: true
                        })
                      }}
                    >
                      {intl.formatMessage({ id: 'myOffers.withTracking' })}
                    </SubmenuItem>
                    <SubmenuItem
                      onClick={() => {
                        onClose()
                        acceptReservation({
                          contextType: context.contextType,
                          offerId: context.id,
                          reservationId:
                            conversationContextActions.reservationId,
                          withTracking: false
                        })
                      }}
                    >
                      {intl.formatMessage({ id: 'myOffers.withoutTracking' })}
                    </SubmenuItem>
                  </>
                )}
              </>
            )}
            {conversationContextActions.isCancellable &&
              isNil(context.outcome) && (
                <PopupNavigationElement
                  onClick={() => {
                    onClose()
                    cancelReservation({
                      contextType: context.contextType,
                      offerId: context.id,
                      reservationId: conversationContextActions.reservationId
                    })
                  }}
                >
                  <CloseIcon />{' '}
                  {intl.formatMessage({ id: 'messages.cancelReservation' })}
                </PopupNavigationElement>
              )}
            {conversationContextActions.isRejectable && (
              <PopupNavigationElement
                onClick={() => {
                  onClose()
                  rejectReservation({
                    contextType: context.contextType,
                    offerId: context.id,
                    reservationId: conversationContextActions.reservationId
                  })
                }}
              >
                <CloseIcon />{' '}
                {intl.formatMessage({ id: 'messages.rejectReservation' })}
              </PopupNavigationElement>
            )}
            {noActions && (
              <PopupNavigationElement onClick={() => {}}>
                {intl.formatMessage({ id: 'messages.noActions' })}
              </PopupNavigationElement>
            )}
          </>
        )}
      </LinksGroup>
    </div>
  )
}

export default styled(ActionMenu)`
  width: 280px;
  box-sizing: border-box;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  border: 1px solid #c6cbd4;
  position: absolute;
  z-index: 100;
  top: 24px;
  right: -5px;
  display: none;
  flex-direction: column;
  ${({ active }) =>
    active &&
    `
      display: flex;
  `}
  ${({ hidePopup }) =>
    hidePopup &&
    `
    display: none;
  `}
`

const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem 0;
  position: relative;
`

export const PopupNavigationElement = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.85rem;
  padding: 0.6rem 0.6rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.black.hex()};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  > span {
    margin-left: 0.8rem;
  }
  &:hover {
    background-color: rgba(29, 30, 78, 0.05);
  }
  ${({ active }) =>
    active &&
    `
      font-weight: 800;
  `}
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`

const CheckIcon = styled(MUIDoneIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.apple.hex()};
  margin-right: 0.85rem;
`

const CloseIcon = styled(MUICloseIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.fadedRed.hex()};
  margin-right: 0.85rem;
`

const DownArrow = styled(MUIDownArrowIcon)`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.black.hex()};
  position: absolute;
  top: 15px;
  right: 8px;
`

const UpArrow = styled(MUIUpArrowIcon)`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.black.hex()};
  position: absolute;
  top: 15px;
  right: 8px;
`

const Loader = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.bluewood.hex()} !important;
`

const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`

const ReservationButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.ghost.hex()};
  padding: 0.4rem 0.6rem;
  width: 100%;
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      background-color: white;
    `}
`

const AlertStyled = styled(Alert)`
  padding: 8px 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div:last-of-type {
    div:first-child {
      font-size: 0.74rem;
    }
    div:last-child {
      font-size: 0.65rem;
    }
  }
`

const TrackingIconWithBackgroundComponent = ({ className }) => (
  <div className={className}>
    <TrackingIcon />
  </div>
)

const TrackingIconWithBackground = styled(TrackingIconWithBackgroundComponent)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 60%;
    height: 60%;
  }
`

const SubmenuItem = styled(PopupNavigationElement)`
  padding-left: 3.6rem;
  box-sizing: border-box;
`
