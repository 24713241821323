import React from 'react'
import styled, { css } from 'styled-components'
import ExampleUserAvatarUrl from 'src/assets/avatar-placeholder.svg'

const LastMessage = ({
  className,
  user,
  onClick = () => {},
  interlocutorOnline
}) => (
  <div className={className} onClick={onClick}>
    <InformationGroup>
      <Avatar url={user.avatar} online={interlocutorOnline} />
      <Column>
        <Row>
          <PersonName>{user.userName}</PersonName>
        </Row>
        <Row>
          <CompanyName title={user.companyName}>{user.companyName}</CompanyName>
        </Row>
      </Column>
    </InformationGroup>
  </div>
)

export default styled(LastMessage)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0.6rem 0;
  cursor: pointer;
  &:after {
    content: '';
    width: 85%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ messageSeen }) =>
    !messageSeen &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.easternBlue.alpha(0.05).hex()};
    `}
`

const InformationGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
`

const Avatar = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: url(${({ url }) => url || ExampleUserAvatarUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 14px 0 18px;
  position: relative;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: -2px;
    bottom: 1px;
  }
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme, online }) =>
      online ? theme.colors.apple.hex() : theme.colors.geyser.hex()};
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 3px;
  }
`
const PersonName = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 0.88rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.6rem;
`

const CompanyName = styled.span`
  display: inline-block;
  line-height: 1.42;
  font-size: 0.73rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
