import React from 'react'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'
import moment from 'moment'
import { API_STATES, DATE_FORMAT } from 'src/ducks/consts'
import Alert from 'src/components/Alert'
import { SaveOfferButton } from 'src/components/atoms/Buttons'
import Dialog from '@material-ui/core/Dialog'
import {
  Container,
  DialogContent,
  DialogSubtitle
} from 'src/features/termsOfService/TermsOfServiceDialog'
import styled, { css } from 'styled-components'
import routes from 'src/utils/routes'
import { CloseIcon } from 'src/components/ConfirmDialog'
import { ellipsisInTheMiddle } from 'src/utils/helpers'

const DebtConfirmationDialog = ({
  confirmation,
  errorMessage,
  clearDebtCollection,
  offer
}) => {
  const onClose = () => {
    navigate(`${routes.APP_DASHBOARD}?tab=dashboard`)
    clearDebtCollection()
  }
  return (
    <Dialog disableKeyboard onClose={onClose} open={confirmation.id}>
      <CloseIcon onClick={onClose} />
      <Container>
        <DialogTitle>
          <DialogTitleText>
            <FormattedMessage id='debtCollection.confirmationTitle' />
          </DialogTitleText>
        </DialogTitle>
        <DialogContent>
          <OrderNumber>
            <OrderNumberTitle>
              <FormattedMessage id='debtCollection.orderNumber' />
            </OrderNumberTitle>
            <h1>{confirmation.referenceNumber}</h1>
            <OrderNumberFooter>
              <FormattedMessage id='debtCollection.orderNumberInfo' />
            </OrderNumberFooter>
          </OrderNumber>
        </DialogContent>
        <DialogContent>
          <FormattedMessage
            id='debtCollection.confirmationDescription'
            values={{
              date: moment().format(DATE_FORMAT),
              offerNumber: offer.referenceNumber
            }}
          />
        </DialogContent>
        <DialogContent>
          <DialogSubtitle>
            <FormattedMessage id='debtCollection.creditor' />
            {': '}
            <CompanyName>
              {confirmation.creditor.name}
              <span>{` NIP:${confirmation.creditor.taxId}`}</span>
            </CompanyName>
          </DialogSubtitle>
          <And>
            <FormattedMessage id='debtCollection.and' />
          </And>
          <DialogSubtitle>
            <FormattedMessage id='debtCollection.debtor' />
            {': '}
            <CompanyName>
              {confirmation.debtor.name}
              <span>{` NIP:${confirmation.debtor.taxId}`}</span>
            </CompanyName>
          </DialogSubtitle>
        </DialogContent>
        {confirmation.attachments.length > 0 && (
          <DialogContent>
            <DialogSubtitle>
              <FormattedMessage id='debtCollection.attachedDocuments' />
            </DialogSubtitle>
            <ul>
              {confirmation.attachments.map(file => (
                <li key={file}> {ellipsisInTheMiddle(file, 40)}</li>
              ))}
            </ul>
          </DialogContent>
        )}
        {errorMessage && <Alert type='alert' message={errorMessage} />}
        <DialogActions>
          <SaveOfferButton
            active={confirmation.state !== API_STATES.IN_PROGRESS}
            onClick={onClose}
          >
            <FormattedMessage id='debtCollection.close' />
          </SaveOfferButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default DebtConfirmationDialog

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const DialogTitleText = styled.h3`
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  color: ${({ theme }) => theme.colors.black.hex()};
`

const DialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ withReason }) =>
    withReason &&
    css`
      && {
        margin: 0 0 0.7rem 0;
      }
    `}
`

const OrderNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  padding: 1rem;
  border-radius: 4px;
`
const OrderNumberTitle = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  opacity: 0.7;
  line-height: 1;
  text-transform: uppercase;
`

const OrderNumberFooter = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  line-height: 2;
`
const CompanyName = styled.p`
  display: inline;
  font-size: 0.875rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  & > span {
    font-size: 0.75rem;
    opacity: 0.7;
  }
`

const And = styled.div`
  font-size: 0.75rem;
  line-height: 1.58;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`
