import { connect } from 'react-redux'

import { selectCurrentUserCompanyId } from 'src/features/account/duck/selectors'

import {
  searchCompanyRoutine,
  clearCompanySearchResultRoutine,
  clearCompanyPublicDetailsRoutine,
  showCompanyPublicDetailsRoutine,
  getAllMarkedCompaniesRoutine,
  getWhitelistedCompaniesRoutine,
  getBlacklistedCompaniesRoutine,
  addCompanyToWhitelistRoutine,
  addCompanyToBlacklistRoutine,
  removeCompanyFromBlacklistRoutine,
  removeCompanyFromWhitelistRoutine
} from './actions'
import {
  selectCompanyDetails,
  selectCompaniesSearchResult,
  selectCompaniesWhitelist,
  selectCompaniesBlacklist,
  selectCompaniesState,
  selectCompaniesErrorMessage,
  selectAllMarkedCompanies
} from './selectors'

const mapStateToProps = state => ({
  companyDetails: selectCompanyDetails(state),
  companiesSearchResult: selectCompaniesSearchResult(state),
  companiesWhitelist: selectCompaniesWhitelist(state),
  companiesBlacklist: selectCompaniesBlacklist(state),
  companiesState: selectCompaniesState(state),
  companiesErrorMessage: selectCompaniesErrorMessage(state),
  allMarkedCompanies: selectAllMarkedCompanies(state),
  currentCompanyId: selectCurrentUserCompanyId(state)
})

const mapDispatchToProps = dispatch => ({
  searchCompany: taxId => dispatch(searchCompanyRoutine(taxId)),
  clearCompanySearchResult: taxId =>
    dispatch(clearCompanySearchResultRoutine(taxId)),
  clearCompanyPublicDetails: taxId =>
    dispatch(clearCompanyPublicDetailsRoutine(taxId)),
  showCompanyPublicDetails: taxId =>
    dispatch(showCompanyPublicDetailsRoutine(taxId)),
  getAllMarkedCompanies: () => dispatch(getAllMarkedCompaniesRoutine()),
  getBlacklistedCompanies: () => dispatch(getBlacklistedCompaniesRoutine()),
  getWhitelistedCompanies: () => dispatch(getWhitelistedCompaniesRoutine()),
  addCompanyToWhitelist: companyId =>
    dispatch(addCompanyToWhitelistRoutine(companyId)),
  addCompanyToBlacklist: companyId =>
    dispatch(addCompanyToBlacklistRoutine(companyId)),
  removeCompanyFromWhitelist: companyId =>
    dispatch(removeCompanyFromWhitelistRoutine(companyId)),
  removeCompanyFromBlacklist: companyId =>
    dispatch(removeCompanyFromBlacklistRoutine(companyId))
})

export default connect(mapStateToProps, mapDispatchToProps)
