import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { isNil } from 'ramda'
import { Link, useIntl } from 'gatsby-plugin-intl'
import ExampleUserAvatarUrl from 'src/assets/avatar-placeholder.svg'
import MUIClose from '@material-ui/icons/Close'
import MUIMore from '@material-ui/icons/MoreHoriz'
import CustomIcon from 'src/components/CustomIcon'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import OfferInfoBox from './OfferInfoBox'
import ActionMenu from './ActionMenu'

const Header = ({
  className,
  onClose = () => {},
  interlocutor,
  isConversationMuted,
  toggleConversationNotifications,
  conversation,
  interlocutorOnline,
  reserveOffer,
  acceptReservation,
  rejectReservation,
  cancelReservation,
  getContextAvailableActions,
  isConversationContextLoading,
  conversationContextActions
}) => {
  const intl = useIntl()
  const [actionMenuOpened, setActionMenuOpened] = useState(false)
  return (
    <div className={className}>
      <ActionBar>
        <InformationGroup>
          <Avatar url={interlocutor.avatar} online={interlocutorOnline} />
          <Column>
            <PersonName>{`${interlocutor.firstName} ${interlocutor.lastName}`}</PersonName>
            <CompanyName>{interlocutor.companyName}</CompanyName>
          </Column>
        </InformationGroup>
        <ActionGroup>
          {conversation.conversationId && (
            <NotificationIcon
              name={
                isConversationMuted ? 'notificationOff' : 'notificationActive'
              }
              isMuted={isConversationMuted}
              onClick={toggleConversationNotifications}
              size={24}
            />
          )}
          <CloseIcon onClick={onClose} />
        </ActionGroup>
      </ActionBar>
      <InformationBar personal={isNil(conversation.context)}>
        {isNil(conversation.context)
          ? intl.formatMessage({ id: 'messages.personalChat' })
          : `${intl.formatMessage({ id: 'messages.offerChat' })} ${
              conversation.context.referenceNumber
            }`}
      </InformationBar>
      {!isNil(conversation.context) && (
        <ContextInformation>
          <Row>
            <OfferLink
              to={createOfferDetailsLink(conversation.context.referenceNumber)}
            >
              {conversation.context.referenceNumber}
            </OfferLink>
            <ActionMenuWrapper>
              {conversation.conversationId && (
                <MoreIcon onClick={() => setActionMenuOpened(true)} />
              )}
              {actionMenuOpened && (
                <ActionMenu
                  active={actionMenuOpened}
                  onClose={() => setActionMenuOpened(false)}
                  belongsToTheCurrentCompany={
                    conversation.context.belongsToTheCurrentCompany
                  }
                  context={conversation.context}
                  conversation={conversation}
                  reserveOffer={reserveOffer}
                  acceptReservation={acceptReservation}
                  rejectReservation={rejectReservation}
                  cancelReservation={cancelReservation}
                  getContextAvailableActions={getContextAvailableActions}
                  isConversationContextLoading={isConversationContextLoading}
                  conversationContextActions={conversationContextActions}
                />
              )}
            </ActionMenuWrapper>
          </Row>
          <OfferInfoBoxStyled context={conversation.context} />
        </ContextInformation>
      )}
    </div>
  )
}

export default styled(Header)`
  display: inline-block;
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 48px;
`

const ActionMenuWrapper = styled.div`
  display: inline-block;
  position: relative;
`

const InformationBar = styled.div`
  display: flex;
  height: 23px;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 0.65rem;
  background-color: ${({ theme }) => theme.colors.bluewood.hex()};
  ${({ personal }) =>
    personal &&
    css`
      background-color: #dbe1ea;
      color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Avatar = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: url(${({ url }) => url || ExampleUserAvatarUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 14px 0 10px;
  position: relative;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: -2px;
    bottom: 1px;
  }
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme, online }) =>
      online ? theme.colors.apple.hex() : theme.colors.geyser.hex()};
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 3px;
  }
`

const InformationGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.8rem;
`

const CloseIcon = styled(MUIClose)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.black.hex()};
  cursor: pointer;
`

const MoreIcon = styled(MUIMore)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.black.hex()};
  cursor: pointer;
`

const PersonName = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 0.84rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CompanyName = styled.span`
  display: inline-block;
  line-height: 1.42;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NotificationIcon = styled(CustomIcon)`
  cursor: pointer;
  margin-top: 3px;
  margin-right: 16px;
  ${({ isMuted }) =>
    isMuted &&
    css`
      color: ${({ theme }) => theme.colors.fadedRed.hex()};
    `}
`

const ContextInformation = styled.div`
  background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  box-shadow: 0 0 10px 0 rgba(36, 45, 63, 0.16);
  padding-bottom: 0.2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
`

const OfferInfoBoxStyled = styled(OfferInfoBox)`
  padding-top: 0;
  margin-top: -5px;
  a {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 1rem;
  padding-top: 0.3rem;
`

const OfferLink = styled(Link)`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
