import translate from 'src/intl/translate'
import { values } from 'ramda'

export const MY_OFFERS_TABS = {
  ONGOING: 'myOffers.ongoing',
  FINISHED: 'myOffers.finished'
}

export const MY_OFFERS_TABS_ARRAY = values(MY_OFFERS_TABS)

export const HEADERS_ONGOING = {
  MY_EXPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.loading',
      sort: null
    },
    {
      name: 'stocksHeaders.return',
      sort: null
    },
    {
      name: 'stocksHeaders.container',
      sort: null
    },
    {
      name: 'stocksHeaders.shipper',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: true
    }
  ],
  MY_IMPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.pickup',
      sort: null
    },
    {
      name: 'stocksHeaders.unloading',
      sort: null
    },
    {
      name: 'stocksHeaders.container',
      sort: null
    },
    {
      name: 'stocksHeaders.shipper',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: true
    }
  ],
  MY_POST_IMPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.availability',
      sort: null
    },
    {
      name: 'stocksHeaders.destination',
      sort: null
    },
    {
      name: 'stocksHeaders.container',
      sort: null
    },
    {
      name: 'stocksHeaders.shipper',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: true
    }
  ],
  MY_FREE_CARRIER: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.availability',
      sort: null
    },
    {
      name: 'stocksHeaders.destination',
      sort: null
    },
    {
      name: 'stocksHeaders.trailer',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: true
    }
  ]
}

export const HEADERS_FINISHED = {
  MY_EXPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.loading',
      sort: null
    },
    {
      name: 'stocksHeaders.return',
      sort: null
    },
    {
      name: 'stocksHeaders.container',
      sort: null
    },
    {
      name: 'stocksHeaders.shipper',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.finished',
      sort: true
    }
  ],
  MY_IMPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.pickup',
      sort: null
    },
    {
      name: 'stocksHeaders.unloading',
      sort: null
    },
    {
      name: 'stocksHeaders.container',
      sort: null
    },
    {
      name: 'stocksHeaders.shipper',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.finished',
      sort: true
    }
  ],
  MY_POST_IMPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.availability',
      sort: null
    },
    {
      name: 'stocksHeaders.destination',
      sort: null
    },
    {
      name: 'stocksHeaders.container',
      sort: null
    },
    {
      name: 'stocksHeaders.shipper',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.finished',
      sort: true
    }
  ],
  MY_FREE_CARRIER: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.reservations',
      sort: null
    },
    {
      name: 'stocksHeaders.availability',
      sort: null
    },
    {
      name: 'stocksHeaders.destination',
      sort: null
    },
    {
      name: 'stocksHeaders.trailer',
      sort: null
    },
    {
      name: 'stocksHeaders.offerHolder',
      sort: null
    },
    {
      name: 'stocksHeaders.finished',
      sort: true
    }
  ]
}

export const FINISHED_OFFER_STATES = {
  SOLD: 'sold',
  ANNULLED: 'annulled',
  FINISHED_INCONCLUSIVELY: 'finished_inconclusively',
  FINISHED_WITHOUT_RESERVATIONS: 'finished_without_reservations'
}
