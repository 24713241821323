import {
  addMethod,
  object,
  string,
  array,
  date,
  boolean,
  mixed,
  number,
  ref
} from 'yup'
import {
  validate,
  validateSchema,
  validateSchemaWithContext,
  validateWithContext
} from 'src/ducks/schema'
import moment from 'moment'
import { isNil, equals, values } from 'ramda'
import { isEmptyString, isFalsy } from 'ramda-adjunct'
import { CORRECTION_REASON } from 'src/features/invoices/duck/consts'

addMethod(string, 'time', function() {
  return this.test('time', 'validation.timeOrEmpty', value =>
    moment(value, 'HH:mm', true).isValid()
  )
})

addMethod(string, 'timeOrEmpty', function() {
  return this.test('timeOrEmpty', 'validation.timeOrEmpty', value => {
    //'__:__' default value for masked input
    const isEmpty = isNil(value) || isEmptyString(value) || value === '__:__'
    return isEmpty || moment(value, 'HH:mm', true).isValid()
  })
})

addMethod(string, 'positiveIntegerOrEmpty', function() {
  return this.test('positiveIntegerOrEmpty', 'validation.wrongValue', value => {
    const isEmpty = isNil(value) || isEmptyString(value)
    return isEmpty || /^[1-9][0-9]*$/.test(value)
  })
})

const allowEmptyString = value => {
  if (value === '') {
    return null
  }
  if (Number.isNaN(value)) {
    return null
  }
  return value
}

const addInvoiceSchema = object().shape({
  numberingScheme: string().required(),
  numberPostfix: string().nullable(),
  issuePlace: string()
    .nullable()
    .required(),
  issueDate: date()
    .nullable()
    .required(),
  sellDate: date().required(),
  dueDate: date().required(),
  recipientCompanyId: string().nullable(),
  recipientCompanyName: string().when('recipientCompanyId', {
    is: isFalsy,
    then: string().required(),
    otherwise: string().nullable()
  }),
  recipientCompanyAddress: string().when('recipientCompanyId', {
    is: isFalsy,
    then: string().required(),
    otherwise: string().nullable()
  }),
  recipientCompanyAddressLine2: string().nullable(),
  recipientCompanyCity: string().when('recipientCompanyId', {
    is: isFalsy,
    then: string().required(),
    otherwise: string().nullable()
  }),
  recipientCompanyPostalCode: string().when('recipientCompanyId', {
    is: isFalsy,
    then: string().required(),
    otherwise: string().nullable()
  }),
  recipientCompanyCountryCode: string().when('recipientCompanyId', {
    is: isFalsy,
    then: string().required(),
    otherwise: string().nullable()
  }),
  recipientCompanyTaxId: string().when('recipientCompanyId', {
    is: isFalsy,
    then: string().required(),
    otherwise: string().nullable()
  }),
  recipientCompanyEmail: string().nullable(),
  currency: string().required(),
  amountPaid: object().shape({
    amount: string().nullable(),
    currency: string().nullable(),
    formatted: string().nullable(),
    float: number()
      .transform(allowEmptyString)
      .min(0, 'validation.positive')
      .required()
      .nullable()
  }),
  paymentMethod: string()
    .oneOf(['transfer', 'cash'])
    .required(),
  paymentBankAccount: string().when('paymentMethod', {
    is: equals('transfer'),
    then: string().required(),
    otherwise: string().nullable()
  }),
  paymentBankName: mixed().when('paymentMethod', {
    is: paymentMethod => paymentMethod === 'transfer',
    then: string().required(),
    otherwise: string().nullable()
  }),
  isSplitPayment: boolean().required(),
  saft: object().shape({
    SW: boolean().required(),
    EE: boolean().required(),
    TP: boolean().required(),
    MPP: boolean().required(),
    B_SPV: boolean().required(),
    B_SPV_DOSTAWA: boolean().required(),
    B_MPV_PROWIZJA: boolean().required()
  }),
  additionalInfo: string().nullable(),
  items: array().of(
    object().shape({
      description: string().required(),
      qty: number()
        .transform(allowEmptyString)
        .integer()
        .positive()
        .required()
        .nullable(),
      unit: string().required(),
      unitPrice: object().shape({
        amount: string().nullable(),
        currency: string().nullable(),
        formatted: string().nullable(),
        float: number()
          .transform(allowEmptyString)
          .positive()
          .required()
          .nullable()
      }),
      taxRate: string().required(),
      gtuCode: string().nullable(),
      pkwiuCode: string().nullable()
    })
  )
})

const addInvoiceRegenerateNumberSchema = object().shape({
  numberingScheme: string().required(),
  numberPostfix: string().nullable()
})

const addInvoiceRecalculateSchema = object().shape({
  currency: string().required(),
  items: array().of(
    object().shape({
      description: string().required(),
      qty: number()
        .transform(allowEmptyString)
        .integer()
        .positive()
        .required()
        .nullable(),
      unit: string().required(),
      unitPrice: object().shape({
        amount: string().nullable(),
        currency: string().nullable(),
        formatted: string().nullable(),
        float: number()
          .transform(allowEmptyString)
          .positive()
          .required()
          .nullable()
      }),
      taxRate: string().required(),
      gtuCode: string().nullable(),
      pkwiuCode: string().nullable()
    })
  )
})

const markAsPaidSchema = object().shape({
  amount: number()
    .transform(allowEmptyString)
    .min(0.01)
    .max(ref('$defaultPaidAmount'))
    .required()
    .nullable(),
  paidAt: date()
    .min(ref('$issueDate'))
    .max(moment().set({ hour: 23, minute: 59 }))
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required()
    .typeError('validation.wrongValue')
})

const addRecipientFormSchema = object().shape({
  recipientCompanyName: string().required(),
  recipientCompanyAddress: string().required(),
  recipientCompanyAddressLine2: string().nullable(),
  recipientCompanyCity: string().required(),
  recipientCompanyPostalCode: string().required(),
  recipientCompanyCountryCode: string().required(),
  recipientCompanyTaxId: string().required(),
  recipientCompanyEmail: string()
    .email()
    .nullable()
})

const addNoteSchema = object().shape({
  issueDate: date()
    .min(ref('$issueDate'))
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required()
    .typeError('validation.wrongValue'),
  originalContent: string()
    .nullable()
    .required(),
  correctedContent: string()
    .nullable()
    .required(),
  additionalInfo: string().nullable()
})

const addCorrectiveInvoiceSchema = object().shape({
  issuePlace: string()
    .nullable()
    .required(),
  issueDate: date()
    .min(ref('$invoiceIssueDate'))
    .nullable()
    .required(),
  dueDate: date().required(),
  correctionReason: string()
    .oneOf(values(CORRECTION_REASON))
    .required(),
  paymentMethod: string()
    .oneOf(['transfer', 'cash'])
    .required(),
  paymentBankAccount: string().when('paymentMethod', {
    is: equals('transfer'),
    then: string().required(),
    otherwise: string().nullable()
  }),
  paymentBankName: mixed().when('paymentMethod', {
    is: paymentMethod => paymentMethod === 'transfer',
    then: string().required(),
    otherwise: string().nullable()
  }),
  isSplitPayment: boolean().required(),
  saft: object().shape({
    SW: boolean().required(),
    EE: boolean().required(),
    TP: boolean().required(),
    MPP: boolean().required(),
    B_SPV: boolean().required(),
    B_SPV_DOSTAWA: boolean().required(),
    B_MPV_PROWIZJA: boolean().required()
  }),
  additionalInfo: string().nullable(),
  items: array().of(
    object().shape({
      description: string().required(),
      qty: number()
        .transform(allowEmptyString)
        .integer()
        .required()
        .nullable(),
      unit: string().required(),
      unitPrice: object().shape({
        amount: string().nullable(),
        currency: string().nullable(),
        formatted: string().nullable(),
        float: number()
          .transform(allowEmptyString)
          .positive()
          .required()
          .nullable()
      }),
      taxRate: string().required(),
      gtuCode: string().nullable(),
      pkwiuCode: string().nullable()
    })
  )
})

export const validateAddInvoice = validate(addInvoiceSchema)
export const validateAddInvoiceSchema = validateSchema(addInvoiceSchema)
export const validateAddInvoiceRecalculateSchema = validateSchema(
  addInvoiceRecalculateSchema
)
export const validateAddInvoiceRegenerateNumberSchema = validateSchema(
  addInvoiceRegenerateNumberSchema
)

export const validateAddRecipient = validate(addRecipientFormSchema)
export const validateAddRecipientFormSchema = validateSchema(
  addRecipientFormSchema
)

export const validateMarkAsPaid = validateWithContext(markAsPaidSchema)
export const validateMarkAsPaidSchema = validateSchemaWithContext(
  markAsPaidSchema
)

export const validateAddNote = validateWithContext(addNoteSchema)
export const validateAddNoteSchema = validateSchemaWithContext(addNoteSchema)

export const validateAddCorrectiveInvoice = validateWithContext(
  addCorrectiveInvoiceSchema
)
export const validateAddCorrectiveInvoiceSchema = validateSchemaWithContext(
  addCorrectiveInvoiceSchema
)
