import React, { useRef, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { isFalsy } from 'ramda-adjunct'
import MUIArrow from '@material-ui/icons/KeyboardArrowDown'
import MUIBack from '@material-ui/icons/KeyboardArrowLeft'

import MUISearch from '@material-ui/icons/Search'
import MUIClose from '@material-ui/icons/Close'
import { breakpoint } from 'src/theme/grid'
import { REFERENCE_NUMBER_REGEX } from 'src/features/stocks/ducks/consts'
import RangeStepper from './RangeStepper'

const FilterName = styled.span`
  display: flex;
  width: 100%;
  font-weight: 800;
  font-size: 0.95rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  align-items: center;
  padding: 0.6rem 0.8rem;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${breakpoint.m`
    font-size: 1.1rem;
  `}
`

const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100% - 100px);
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 57px;
  padding: 0 0.8rem;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
`

const ClearButton = styled.div`
  display: inline-block;
  background-color: transparent;
  padding: 0.43rem 0.7rem;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-size: 0.85rem;
  font-weight: 600;
  transition: background-color 0.3s;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.05).hex()};
  }
`

const ConfirmButton = styled(ClearButton)`
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  }
`

const FilterDropdownComponent = ({
  className,
  filterName,
  clearButtonText,
  confirmButtonText,
  onClose,
  onClear,
  children
}) => (
  <div className={className}>
    <FilterName>
      <BackIcon onClick={onClose} />
      {filterName}
    </FilterName>
    <FilterContent>{children}</FilterContent>
    <ActionBar>
      <ClearButton onClick={onClear}>{clearButtonText}</ClearButton>
      <ConfirmButton onClick={onClose}>{confirmButtonText}</ConfirmButton>
    </ActionBar>
  </div>
)

FilterDropdownComponent.defaultProps = {
  onClose: () => {},
  onClear: () => {}
}

export const FilterDropdown = styled(FilterDropdownComponent)`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  ${breakpoint.m`
    border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 256px;
    height: 340px;
    z-index: 20;
    position: absolute;
    top: 40px;
    left: 0;
    ${({ alignRight }) =>
      alignRight &&
      css`
        left: auto;
        right: 0;
      `}
  `}
`

const Arrow = styled(MUIArrow)`
  && {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.black.hex()};
  }
  margin-left: 0.6rem;
  transition: transform 0.3s;
  opacity: 0.7;
  ${({ opened }) =>
    opened &&
    `
      transform: rotate(180deg);
    `}
  display: none;
  ${breakpoint.m`
    display: inline-block;
  `}
`

const BackIcon = styled(MUIBack)`
  && {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.black.hex()};
  }
  margin-right: 0.6rem;
  ${breakpoint.m`
    display: none;
  `}
`

const CloseIcon = styled(MUIClose)`
  && {
    font-size: 1.2rem;
  }
  margin-left: 0.6rem;
  transition: transform 0.3s;
  opacity: 0.7;
  cursor: pointer;
`

const FilterBoxActionSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
`

const FilterBoxComponent = ({
  className,
  opened,
  children,
  onClick,
  onClear,
  selectedNumber,
  hasValue
}) => (
  <div className={className} onClick={onClick}>
    <span>{children}</span>
    <FilterBoxActionSection>
      {!isFalsy(selectedNumber) && selectedNumber > 1 && (
        <span>+{selectedNumber - 1}</span>
      )}
      {hasValue ? <CloseIcon onClick={onClear} /> : <Arrow opened={opened} />}
    </FilterBoxActionSection>
  </div>
)

export const FilterBox = styled(FilterBoxComponent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  padding: 0 0.6rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
  border-radius: 2px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  ${breakpoint.m`
      height: 36px;
      border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  `}
  ${({ opened, hasValue }) =>
    (opened || hasValue) &&
    css`
      color: ${({ theme }) => theme.colors.apple.hex()};
      ${breakpoint.m`
        border-color: ${({ theme }) => theme.colors.apple.hex()};
        box-shadow: 0 0 10px -4px rgba(71, 170, 66, 0.24);
      `}
    `}
  & > span:first-of-type {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 50px);
    font-size: 0.95rem;
    font-weight: 400;
    ${breakpoint.m`
      font-weight: 600;
  `}
  }
  & > span {
    display: inline-block;
    font-size: 0.75rem;
    margin: 0 0.4rem;
    font-style: normal;
  }
  &:hover {
    ${breakpoint.m`
      border-color: ${({ theme }) => theme.colors.apple.hex()};
      color: ${({ theme }) => theme.colors.apple.hex()};
    `}
  }
`

const SearchIcon = styled(MUISearch)`
  && {
    font-size: 1.6rem;
  }
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.3;
  margin: 0 0.6rem;
`

const Input = styled.input`
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  &::placeholder {
    display: inline-block;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  }
`

const SearchInputComponent = ({
  className,
  id,
  name,
  value,
  onChange,
  placeholder,
  showClearIcon,
  onClear = () => {}
}) => {
  const inputRef = useRef(null)
  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [inputRef])

  return (
    <div className={className}>
      <SearchIcon />
      <Input
        id={id}
        ref={inputRef}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {showClearIcon && <CloseIcon onClick={onClear} />}
    </div>
  )
}

export const SearchInput = styled(SearchInputComponent)`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  align-items: center;
  border-radius: 2px;
  border: solid 1px rgba(198, 203, 212, 0.3);
  background-color: rgba(198, 203, 212, 0.1);
`

const SearchFilterComponent = ({
  className,
  id,
  name,
  initValue = '',
  setFilter,
  placeholder,
  active,
  filterValue,
  activeTab
}) => {
  const [value, setValue] = useState(initValue)
  const [inputFocus, setInputFocus] = useState(false)
  const setFieldValue = val =>
    val.match(REFERENCE_NUMBER_REGEX) && setValue(val)
  const onSubmit = e => {
    e.preventDefault()
    value.length > initValue.length && setFilter(name, value)
  }

  useEffect(() => {
    active && setValue(filterValue)
  }, [filterValue])

  useEffect(() => setValue(filterValue || initValue), [activeTab])

  const onChange = e => {
    setFieldValue(e.target.value)
    active && setFilter(name, null)
  }

  return (
    <Focus focus={inputFocus}>
      <div className={className}>
        <form onSubmit={onSubmit}>
          <Input
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
        </form>
        {!active && <SearchIcon onClick={onSubmit} />}
        {active && (
          <CloseIcon
            onClick={() => {
              setFilter(name, null)
              setValue(initValue)
            }}
          />
        )}
      </div>
    </Focus>
  )
}

const Focus = styled.div`
  ${({ focus }) =>
    focus &&
    css`
      box-shadow: 0 0 10px -4px ${({ theme }) => theme.colors.apple.hex()};
    `}
`

export const SearchFilter = styled(SearchFilterComponent)`
  display: none;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  border-radius: 2px;
  border: 1px solid rgb(198, 203, 212);
  width: 100%;
  padding: 0 0.6rem;
  transition: border-color 0.3s, color 0.3s;
  & > svg {
    cursor: pointer;
    opacity: 0.8;
  }
  & ${Input} {
    padding-left: 0.4rem;
  }
  ${breakpoint.m`
    display: flex;
    width: 180px;
    &:hover {
    border-color: ${({ theme }) => theme.colors.apple.hex()};
    color: ${({ theme }) => theme.colors.apple.hex()};
    }
  `}
  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
      & > svg {
        color: ${({ theme }) => theme.colors.apple.hex()};
      }
    `}
  ${SearchIcon} {
    margin-right: 0;
  }
`

export const SearchFilterMobile = styled(SearchFilterComponent)`
  box-sizing: border-box;
  display: flex;
  flex: 0;
  flex-direction: row;
  align-items: center;
  height: 36px;
  margin-left: auto;
  padding: 0 0.2rem;
  transition: all 0.3s;
  border-radius: 4px;

  & > svg {
    opacity: 0.8;
    margin-left: auto;
    margin-right: 0;
    pointer-events: none;
    cursor: pointer;
  }

  & ${Input} {
    display: none;
    font-weight: 400;
    font-size: 0.875rem;
  }

  &:hover {
    flex: 1;
    background-color: ${({ theme }) => theme.colors.white.hex()};
    border: 1px solid rgb(198, 203, 212);
    & ${Input} {
      display: block;
    }
    & > svg {
      pointer-events: initial;
    }
  }

  ${breakpoint.m`
    display: none;
  `}
  ${breakpoint.xxs`
     padding: 0 0.6rem;
  `}

  ${({ active }) =>
    active &&
    css`
      && {
        flex: 1;
        background-color: ${({ theme }) => theme.colors.white.hex()};
        border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
      }
      & ${Input} {
        display: block;
      }
      & > svg {
        color: ${({ theme }) => theme.colors.apple.hex()};
        pointer-events: initial;
      }
    `}
`

export const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.8rem;
`

export const SelectOptionsWrapper = styled.div`
  display: inline-block;
  height: calc(100% - 45px);
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0.6rem 0;
`

export const SelectWrapper = styled.div`
  ${FilterDropdown} {
    ${breakpoint.m`
      height: 245px;
    `}
  }
  ${SelectOptionsWrapper} {
    height: 100% !important;
  }
`

const Checkbox = styled.span`
  display: flex;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border-radius: 4px;
  margin-right: 0.6rem;
  position: relative;
  transition: background-color 0.3s;
  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.colors.ghost.hex()};
    border-radius: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ type }) =>
      type === 'radio' &&
      `
      border-radius: 50%;
    `}
  }
  &:after {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    box-sizing: border-box;
    border-radius: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ selected }) =>
      selected &&
      css`
        background-color: ${({ theme }) => theme.colors.apple.hex()};
      `}
    ${({ type }) =>
      type === 'radio' &&
      `
      border-radius: 50%;
    `}
  }
`

const SelectRowName = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black.hex()};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
  ${({ stepper }) =>
    stepper &&
    css`
      max-width: calc(100% - 120px);
    `}
  ${breakpoint.m`
    font-weight: 600;
  `}
`

const SelectRowComponent = ({
  className,
  name,
  onClick,
  selected,
  type = 'checkbox',
  stepper = false,
  onRangeChange,
  range
}) => (
  <div className={className} onClick={onClick}>
    <Checkbox selected={selected} type={type} />
    <SelectRowName stepper={stepper}>{name}</SelectRowName>
    {stepper && (
      <RangeStepper
        step={25}
        maxValue={300}
        minValue={0}
        unit='km'
        value={range}
        onChange={onRangeChange}
      />
    )}
  </div>
)

export const SelectRow = styled(SelectRowComponent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.6rem 0.8rem;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
  position: relative;
  ${breakpoint.m`
    padding: 0.45rem 0.8rem;
  `}
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.04).hex()};
    ${Checkbox} {
      background-color: ${({ theme }) => theme.colors.apple.alpha(0.1).hex()};
    }
  }

  ${breakpoint.m`
      ${RangeStepper} {
        display: none;
      }
      &:hover {
        ${RangeStepper} {
          display: flex;
        }
      }
  `}
  ${({ range }) =>
    range &&
    css`
      ${RangeStepper} {
        display: flex;
      }
    `}
`

const SelectInputComponent = ({
  className,
  opened,
  children,
  onClick,
  onClear,
  selectedNumber,
  hasValue
}) => (
  <div className={className} onClick={onClick}>
    <span>{children}</span>
    <FilterBoxActionSection>
      {!isFalsy(selectedNumber) && selectedNumber > 1 && (
        <span>+{selectedNumber - 1}</span>
      )}
      {hasValue ? (
        <CloseIcon
          onClick={e => {
            e.stopPropagation()
            onClear()
          }}
        />
      ) : (
        <Arrow opened={opened} />
      )}
    </FilterBoxActionSection>
  </div>
)

export const SelectInput = styled(SelectInputComponent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  color: ${({ theme }) => theme.colors.ghost.hex()};
  padding: 0 0.6rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
  border-radius: 2px;
  position: relative;
  background-color: white;
  z-index: 10;
  ${({ opened }) =>
    opened &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.easternBlue.hex()};
      box-shadow: 0 0 10px -4px rgba(19, 162, 191, 0.25);
      color: ${({ theme }) => theme.colors.black.hex()};
    `}
  ${({ hasValue }) =>
    hasValue &&
    css`
      color: ${({ theme }) => theme.colors.black.hex()};
    `}
  & > span:first-of-type {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 50px);
    font-size: 0.95rem;
    font-weight: 400;
  }
  & > span {
    display: inline-block;
    font-size: 0.75rem;
    margin: 0 0.4rem;
    font-style: normal;
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.black.hex()};
  }
`

export const OptionsSectionTitle = styled.span`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.4rem;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
`

export const Separator = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.ghost.alpha(0.4).hex()};
  margin: 0.4rem 0;
`
