import {
  BigInputContainer,
  InputRow
} from 'src/features/orders/components/atoms'
import { Title } from 'src/features/account/components/typography'
import { FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'
import { Recipient } from 'src/features/invoices/components/TopSection/styles'
import { TextTitle } from 'src/features/invoices/InvoiceDetails'
import styled, { css } from 'styled-components'

export const RecipientDetails = ({ invoice, showLabel }) => (
  <InputRow>
    <InputContainer showLabel={showLabel}>
      {showLabel && (
        <TextTitle>
          <FormattedMessage id='invoices.buyer' />
        </TextTitle>
      )}
      <Recipient>
        <Title>{invoice.recipientCompanyName}</Title>
        <p>{invoice.recipientCompanyAddress}</p>
        <p>{invoice.recipientCompanyAddressLine2}</p>
        <p>
          {invoice.recipientCompanyPostalCode} {invoice.recipientCompanyCity}
          {invoice.recipientCompanyCity &&
            invoice.recipientCompanyCountryCode &&
            ', '}
          {invoice.recipientCompanyCity &&
            invoice.recipientCompanyCountryCode?.toUpperCase()}
        </p>
        {invoice.recipientCompanyTaxId && (
          <p>
            <FormattedMessage id='common.nip' />:{' '}
            {invoice.recipientCompanyTaxId}
          </p>
        )}
        {invoice.recipientCompanyEmail && (
          <p>{invoice.recipientCompanyEmail}</p>
        )}
      </Recipient>
    </InputContainer>
  </InputRow>
)

const InputContainer = styled(BigInputContainer)`
  ${Recipient} {
    ${({ showLabel }) =>
      showLabel &&
      css`
        margin-top: 4px;
      `}
  }
`
