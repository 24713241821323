import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react'
import styled from 'styled-components'
import {
  useIntl,
  Link,
  FormattedHTMLMessage,
  FormattedMessage
} from 'gatsby-plugin-intl'
import moment from 'moment'
import Checkbox from 'src/components/atoms/CheckboxInput'
import Select from 'src/components/atoms/SelectNative'
import Alert from 'src/components/Alert'
import TextInputMasked from 'src/components/atoms/TextInputMasked'
import DatePicker from 'src/components/atoms/DatePicker'
import {
  AuctionTimeInputWrapper,
  WarningWrapper
} from 'src/features/offers/components/atoms'

import { BOOLEAN } from 'src/ducks/consts'
import { breakpoint } from 'src/theme/grid'
import Dialog from '@material-ui/core/Dialog'
import DictionariesService from 'src/services/DictionariesService'
import ExchangeService from 'src/services/ExchangeService'
import {
  RemoveButton,
  AddButton,
  Button,
  EditButton,
  ButtonLight,
  ButtonDark,
  ButtonOutlined
} from 'src/components/atoms/Buttons'
import TextInput from 'src/components/atoms/TextInput'
import { debounce } from 'lodash'
import { CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { setSnackbarValuesRoutine } from 'src/ducks/actions'
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery
} from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { useDebounce } from 'use-debounce'
import { query } from 'src/pages'
import { useInView } from 'react-intersection-observer'
import { DATE_FORMAT, DATE_LONG_FORMAT_WITH_WEEKDAY } from 'src/ducks/consts'
import XIcon from 'src/assets/icons/close-x.svg'
import CustomIcon from 'src/components/CustomIcon'
import IconWithTooltip from 'src/components/IconWithTooltip'
import { useSelector } from 'react-redux'
import { USER_ROLES } from 'src/ducks/consts'
import LocalStorageService from 'src/services/LocalStorageService'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: 0,
    // minimal width 300 px
    width: 500,
    [theme.breakpoints.up('md')]: {
      width: 600
    }
  }
}))

const useExchangeRoomMember = (exchangeType, memberId) => {
  const querylient = useQueryClient()

  return useQuery(
    ['exchangeRoomMember', exchangeType, memberId],
    async () => {
      const response = await ExchangeService.getExchangeRoomMember(
        exchangeType,
        memberId
      )
      return response
    },
    {
      // enabled: !!memberId,
      retry: false
    }
  )
}

const useInfiniteSearchExchangeRoomMembers = (exchangeType, filters) => {
  const [debouncedName] = useDebounce(filters.name || '', 300)
  const debouncedFilters = filters.name
    ? { ...filters, name: debouncedName }
    : { ...filters }

  return useInfiniteQuery(
    ['exchangeRoomMembers', exchangeType, { filter: debouncedFilters }],
    async ({ pageParam = 1 }) => {
      const response = await ExchangeService.searchExchangeRoomMembers(
        exchangeType,
        { filter: debouncedFilters, page: pageParam }
      )
      return response
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage.response.links.next) {
          const url = new URL(lastPage.response.links.next)
          const page = url.searchParams.get('page')
          return page ? Number(page) : undefined
        }
        return undefined
      },
      // if no filter specified enable or if there's a filter.name enable it only if filter.name value is > 3 chars
      enabled:
        !debouncedFilters.name || debouncedFilters.name.trim().length > 3,

      refetchOnWindowFocus: false,
      refetchOnMount: true,
      cacheTime: 5
    }
  )
}

const useAddExchangeRoomMember = exchangeType => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    memberId => ExchangeService.addExchangeRoomMember(exchangeType, memberId),
    {
      onMutate: variables => {},

      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['exchangeRoomMembers', exchangeType])
        queryClient.invalidateQueries([
          'exchangeRoomMember',
          exchangeType,
          variables
        ])
      },
      onError: () => {}
    }
  )
}

const useRemoveMemberFromExchangeRoom = exchangeType => {
  const queryClient = useQueryClient()

  return useMutation(
    memberId =>
      ExchangeService.removeExchangeRoomMember(exchangeType, memberId),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['exchangeRoomMembers', exchangeType])
        queryClient.invalidateQueries([
          'exchangeRoomMember',
          exchangeType,
          variables
        ])
      },
      onError: () => {}
    }
  )
}

const MemberStatus = ({ memberId, exchangeType }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const exchangeRoomMemberQuery = useExchangeRoomMember(exchangeType, memberId)
  const addExchangeRoomMemberQuery = useAddExchangeRoomMember(exchangeType)
  const removeMemberFromExchangeRoomQuery = useRemoveMemberFromExchangeRoom(
    exchangeType
  )

  const handleRemoveExchangeRoomMember = async memberId => {
    removeMemberFromExchangeRoomQuery.mutate(memberId)
  }

  const handleAddExchangeRoomMember = async memberId => {
    addExchangeRoomMemberQuery.mutate(memberId)
  }

  return (
    <>
      {exchangeRoomMemberQuery.isLoading && <CircularProgress />}
      {exchangeRoomMemberQuery.isSuccess && (
        <>
          <StatusContainer>
            <RemoveButton
              style={{ height: '40px' }}
              active
              loading={removeMemberFromExchangeRoomQuery.isLoading}
              title={intl.formatMessage({ id: 'common.remove' })}
              onClick={() => handleRemoveExchangeRoomMember(memberId)}
            />
            <JoinedSince>
              {' '}
              {moment(exchangeRoomMemberQuery.data.data.joinedAt).format(
                DATE_FORMAT
              )}
            </JoinedSince>
          </StatusContainer>
        </>
      )}
      {exchangeRoomMemberQuery.isError && (
        <AddButton
          active
          loading={addExchangeRoomMemberQuery.isLoading}
          title={intl.formatMessage({ id: 'common.add' })}
          onClick={() => {
            handleAddExchangeRoomMember(memberId)
          }}
        />
      )}
    </>
  )
}

const DialogMemberAdd = ({ exchangeType }) => {
  const dispatch = useDispatch()
  const [addingMemberId, setAddingMemberId] = useState(null)
  const { ref, inView } = useInView()

  // filters for search
  const [
    searchForNewExchangeRoomMembersFilters,
    setSearchForNewExchangeRoomMembersFilters
  ] = useState({})

  const searchExchangeRoomMembersQuery = useInfiniteSearchExchangeRoomMembers(
    exchangeType,
    searchForNewExchangeRoomMembersFilters
  )

  const handleSearchExchangeRoomMember = (name, value) => {
    setSearchForNewExchangeRoomMembersFilters({
      ...searchForNewExchangeRoomMembersFilters,
      name: value
    })
  }

  useEffect(() => {
    if (inView) {
      searchExchangeRoomMembersQuery.fetchNextPage()
    }
  }, [inView])

  return (
    <>
      <MembersHeaderContainer>
        <TextInput
          type='search'
          value={searchForNewExchangeRoomMembersFilters.name || ''}
          name='search'
          onChange={handleSearchExchangeRoomMember}
        />
      </MembersHeaderContainer>
      <MemberListContainer>
        {searchExchangeRoomMembersQuery.data?.pages.map(page => (
          <React.Fragment key={page.nextId}>
            {page.data.map(member => (
              <ItemRow>
                <CompanyItem onClick={() => {}}>
                  <CompanyNameContainer>
                    <div>
                      <CompanyName>{member.name}</CompanyName>
                      <CompanyTaxId>NIP: {member.taxId}</CompanyTaxId>
                    </div>
                  </CompanyNameContainer>
                  {/* <CompanyAddress>{'address'}</CompanyAddress> */}
                </CompanyItem>

                <ButtonContainer>
                  <MemberStatus
                    memberId={member.id}
                    exchangeType={exchangeType}
                  />
                </ButtonContainer>
              </ItemRow>
            ))}
          </React.Fragment>
        ))}

        <LoadMoreContainer ref={ref}>
          {/* <ButtonOutlined
          active={searchExchangeRoomMembersQuery.hasNextPage}
          title='Pokaż więcej'
          onClick={() => searchExchangeRoomMembersQuery.fetchNextPage()}
        /> */}
        </LoadMoreContainer>
      </MemberListContainer>
    </>
  )
}
const PrivateGroupDialog = ({ open, onClose, exchangeType }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const classes = useStyles()

  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <CloseIcon onClick={onClose} />
          <Alert
            type='info'
            message={intl.formatMessage({
              id: 'forms.privateRoomMemberManageModalHeader'
            })}
          />
        </DialogHeader>
        <DialogMemberAdd exchangeType={exchangeType} />
      </DialogContent>
    </Dialog>
  )
}

const hourOptions = [
  { value: '5', id: '1', label: '5m' },
  { value: '15', id: '2', label: '15m' },
  { value: '30', id: '3', label: '30m' },
  { value: '45', id: '4', label: '45m' },
  { value: '60', id: '5', label: '60m' },
  { value: '90', id: '6', label: '90m' },
  { value: '120', id: '7', label: '2h' },
  { value: '180', id: '8', label: '3h' },
  { value: '360', id: '9', label: '6h' },
  { value: '720', id: '10', label: '12h' },
  { value: '1440', id: '11', label: '24h' }
]

const getDateTimeMomentFromString = dateTimeString => {
  return moment(dateTimeString, 'YYYY-MM-DD HH:mm')
}

const getDateTimeStringFromMoment = momentDateTime => {
  return momentDateTime.format('YYYY-MM-DD HH:mm')
}

const getTimeStringFromMoment = momentDateTime => {
  return momentDateTime.format('HH:mm')
}

const getDateStringFromMoment = momentDateTime => {
  return momentDateTime.format('YYYY-MM-DD')
}

const PrivateOfferCreateOptions = ({
  exchangeType,
  onGoPublicDateTimeChange
}) => {
  const intl = useIntl()
  const [minutes, setMinutes] = useState('')
  const [avoidGoingPublic, setAvoidGoingPublic] = useState(BOOLEAN.NO)
  const [goPublicAtDateTime, setGoPublicAtDateTime] = useState(
    moment().add(15, 'minutes')
  )
  const [privateGroupDialogOpen, setPrivateGroupDialogOpen] = useState(false)
  const userRole = useSelector(state => state.account.currentUser.role)

  // get from LocalStorageService (key 'goPublicAtDateTimeMinutesAddon' and set it to goPublicAtDateTime)
  useEffect(() => {
    const goPublicAtDateTimeMinutesAddon = LocalStorageService.get(
      `last-used-go-public-at-minute-addon-${exchangeType}`
    )
    if (goPublicAtDateTimeMinutesAddon) {
      setGoPublicAtDateTime(
        moment().add(goPublicAtDateTimeMinutesAddon, 'minutes')
      )
      setMinutes(goPublicAtDateTimeMinutesAddon)
    }
  }, [])

  const handleMinutesChange = (name, value) => {
    setMinutes(value)
    LocalStorageService.set(
      `last-used-go-public-at-minute-addon-${exchangeType}`,
      value
    )
    setGoPublicAtDateTime(moment().add(value, 'minutes'))
  }

  const handleAvoidGoingPublicChange = (name, value) => {
    // if true, set goPublicAtDateTime to null
    setAvoidGoingPublic(!avoidGoingPublic)
  }

  const handleGoPublicAtTimeChange = (name, value) => {
    // update GoPublicAtDateTime accordingly and return modified momentjs object
    setMinutes('')
    setGoPublicAtDateTime(
      moment(
        `${getDateStringFromMoment(goPublicAtDateTime)} ${value}`,
        'YYYY-MM-DD HH:mm'
      )
    )
  }

  const handleGoPublicAtDateChange = (name, value) => {
    // update GoPublicAtDateTime value is a string YYYY-MM-DD from
    setMinutes('')
    setGoPublicAtDateTime(
      moment(
        `${value} ${getTimeStringFromMoment(goPublicAtDateTime)}`,
        'YYYY-MM-DD HH:mm'
      )
    )
  }

  // make goPublicAtDateTime null if avoidGoingPublic is true
  useEffect(() => {
    if (avoidGoingPublic) {
      onGoPublicDateTimeChange('goPublicAt', null)
    } else {
      onGoPublicDateTimeChange('goPublicAt', goPublicAtDateTime.toISOString())
    }
  }, [avoidGoingPublic])

  useEffect(() => {
    onGoPublicDateTimeChange('goPublicAt', goPublicAtDateTime.toISOString())
  }, [goPublicAtDateTime])

  const validateGoPublicAtDateTime = () => {
    return false
    if (avoidGoingPublic) {
      return true
    }
    return goPublicAtDateTime.isAfter(moment())
  }
  return (
    <>
      <WarningWrapper>
        <Alert
          message={intl.formatMessage({
            id: 'forms.privateOfferCreateOptionsInfoMessage'
          })}
          type='info'
        />
      </WarningWrapper>
      {!avoidGoingPublic && (
        <InputsRowWrapper>
          <InputWrapper>
            <DatePicker
              label={intl.formatMessage({
                id: 'forms.privateOfferCreateOptionsGoPublicAtDateLabel'
              })}
              disablePast
              required={false}
              name='auctionDeadlineDate'
              placeholder='dd.mm.rrrr'
              value={getDateStringFromMoment(goPublicAtDateTime)}
              onChange={handleGoPublicAtDateChange}
              maxDate={moment().add(6, 'days')}
            />
          </InputWrapper>
          <InputTimeWrapper>
            <TextInputMasked
              mask='99:99'
              label={intl.formatMessage({
                id: 'forms.privateOfferCreateOptionsGoPublicAtTimeLabel'
              })}
              required={false}
              id='GoPublicAtTime'
              name='GoPublicAtTime'
              placeholder='gg:mm'
              value={getTimeStringFromMoment(goPublicAtDateTime)}
              onChange={handleGoPublicAtTimeChange}
              validate={validateGoPublicAtDateTime}
              type='time'
              step={300}
              // calendarTime
            />
          </InputTimeWrapper>
          <OrWrapper>
            <p>
              <FormattedMessage id='forms.privateOfferCreateOptionsGoPublicAtInOrLabel' />
            </p>
          </OrWrapper>
          <HourInputWrapper>
            <Select
              error='error'
              icon='visibility'
              required={false}
              label={intl.formatMessage({
                id: 'forms.privateOfferCreateOptionsGoPublicAtInLabel'
              })}
              // helperText={intl.formatMessage({
              //   id: 'forms.privateOfferCreateOptionsGoPublicAtInHoursLabel'
              // })}
              options={hourOptions.map(option => ({
                value: option.value,
                id: option.id,
                label: option.label
              }))}
              onChange={handleMinutesChange}
              name='minutes'
              value={minutes}
              placeholder='-'
            />
          </HourInputWrapper>
        </InputsRowWrapper>
      )}

      <Checkbox
        name='pub'
        value={avoidGoingPublic}
        option={{
          value: BOOLEAN.YES,
          text: intl.formatMessage({
            id: 'forms.privateOfferCreateOptionsAvoidGoingPublicLabel'
          })
        }}
        onChange={handleAvoidGoingPublicChange}
      />

      {userRole === USER_ROLES.ADMIN && (
        <p>
          <>
            <a onClick={() => setPrivateGroupDialogOpen(true)}>
              <>
                <CustomIcon name='edit' />
                <FormattedMessage id='forms.privateRoomEditMembersBtnText' />
              </>
            </a>

            <IconWithTooltip
              title={intl.formatMessage({
                id: 'forms.privateRoomEditMembersBtnTooltip'
              })}
            />
          </>
        </p>
      )}

      {/* <EditPrivateGroupButton onClick={() => setPrivateGroupDialogOpen(true)} /> */}
      {privateGroupDialogOpen && (
        <PrivateGroupDialog
          open={privateGroupDialogOpen}
          exchangeType={exchangeType}
          onClose={() => setPrivateGroupDialogOpen(false)}
        />
      )}
    </>
  )
}

const InputsRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

const HourInputWrapper = styled.div`
  width: 15%;
  ${breakpoint.m`
    width: 75px;`}
`
const InputTimeWrapper = styled.div`
  width: calc(30% - 1rem);
  ${breakpoint.m`
    width: 100px;
  `}
`

const OrWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  ${breakpoint.m`
    width: 20px;
  `}
`
const InputWrapper = styled.div`
  width: calc(40% - 1rem);
  ${breakpoint.m`
    width: 150px;
  `}
`
const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    padding: 1.6rem 2.2rem;
  `}
`
const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  margin: 0.8rem 0 1.2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
  margin-bottom: 1rem;
`

const ItemRow = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  padding: 0.8125rem 1.5rem;
  ${breakpoint.m`
    align-items: center;
  `}
`

export const CompanyItem = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;

  ${breakpoint.m`
     flex-direction: row;
     align-items: center;

  `}
`
export const CompanyName = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`
export const CompanyTaxId = styled.div`
  font-size: 0.75rem;
  line-height: 2;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  opacity: 0.7;
`
export const CompanyAddress = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  display: flex;
  align-items: center;
`
const CompanyNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${breakpoint.m`
      // width: 30%;

  `}
`

const ButtonContainer = styled.div`
  ${breakpoint.m`
    width: 165px;
    display: flex;
    justify-content: flex-end;
  `}
`
const StatusContainer = styled.div`
  // align center items
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
`

const JoinedSince = styled.div`
  font-size: 0.65rem;
  line-height: 2;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  opacity: 0.7;
`
const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const MemberListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 350px);
`

const MembersHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
`
const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
`

const EditPrivateGroupButton = styled(EditButton)``

export default PrivateOfferCreateOptions
