import React, { useContext, useState } from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import {
  CONTRACTS_HEADERS_MY_RESERVATIONS_ONGOING_NAMES,
  CONTRACTS_HEADERS_MY_RESERVATIONS_ONGOING_WIDTHS
} from 'src/features/contracts/duck/consts'
import {
  DateTime,
  Ellipsis,
  Location
} from 'src/features/stocks/components/atoms'
import moment from 'moment'
import { DATE_FORMAT } from 'src/ducks/consts'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import Chevron from 'src/assets/icons/down.svg'
import {
  ContractLocations,
  EllipsisContainer,
  TableRow
} from 'src/features/contracts/components/atoms'
import styled from 'styled-components'
import { isNotOlderThanMinutes } from 'src/utils/helpers'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import { connectMyContractsReservationsOngoing } from 'src/features/contracts/duck/connectors'
import { ReservationsField } from 'src/features/myOffers/components/ReservationsField'
import { OfferorWithoutGrid } from 'src/features/myOffers/components/Offeror'
import MobileTableRowMyReservations from 'src/features/contracts/components/MobileTableRowMyReservations'
import ReservationDetails from 'src/features/contracts/components/ReservationDetails'

const OfferRow = ({
  offer,
  currentUserId,
  reservation,
  cancelReservation,
  setConversationOpened
}) => {
  isNotOlderThanMinutes(offer.addedToListingAt, 1)
  const [openContractDetails, setOpenContractDetails] = useState(false)
  const [openReservations, setOpenReservations] = useState(false)
  const permissions = useContext(PermissionsContext)
  const hasPermissionToSeeCompanyConversations = hasPermissionTo(
    PERMISSIONS.CHAT_COMPANY_CONVERSATIONS_SEE,
    permissions
  )
  const belongsToCurrentUser = offer.handler.id === currentUserId
  const chatAvailable =
    belongsToCurrentUser || hasPermissionToSeeCompanyConversations
  const hasReservations = offer.reservationsCount > 0

  const handleOffersCounterClick = () => {
    if (hasReservations) setOpenReservations(!openReservations)
  }

  return (
    <>
      <TableRow
        widths={CONTRACTS_HEADERS_MY_RESERVATIONS_ONGOING_WIDTHS}
        columnNames={CONTRACTS_HEADERS_MY_RESERVATIONS_ONGOING_NAMES}
        isOpen={openReservations}
        open={openContractDetails}
      >
        <div>
          <OfferLink to={createOfferDetailsLink(offer.referenceNumber)}>
            {offer.referenceNumber}
          </OfferLink>
        </div>
        <ReservationsField
          hasReservations={hasReservations}
          onClick={handleOffersCounterClick}
          auction={true}
          reservationsCount={offer.reservationsCount}
          price={reservation.price}
          open={openReservations}
        />
        <div>
          <Ellipsis>
            <FormattedMessage id={`common.${offer.type}`} defaultMessage='-' />
          </Ellipsis>
        </div>
        <div>
          <Ellipsis>{offer.issuer.company.name}</Ellipsis>
        </div>
        <DateTime>
          <Ellipsis>{moment(offer.startDate).format(DATE_FORMAT)}</Ellipsis>
        </DateTime>
        <DateTime>
          <Ellipsis>{moment(offer.endDate).format(DATE_FORMAT)}</Ellipsis>
        </DateTime>
        <ContractLocations>
          {offer.transportations.map((line, index) => (
            <React.Fragment key={`${line.offerId}-${index}`}>
              {(index === 0 || openContractDetails) && (
                <Location>
                  <LocationRow>
                    <EllipsisContainer>
                      <Ellipsis>{`${line.originLocation.countryCode}, ${line.originLocation.name}`}</Ellipsis>
                    </EllipsisContainer>
                    <Arrow>
                      <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
                    </Arrow>
                    <EllipsisContainer>
                      <Ellipsis>{`${line.destinationLocation.countryCode}, ${line.destinationLocation.name}`}</Ellipsis>
                    </EllipsisContainer>
                    {offer.transportations.length > 1 && index === 0 && (
                      <MoreContractsCounter
                        onClick={e => {
                          e.stopPropagation()
                          setOpenContractDetails(!openContractDetails)
                        }}
                      >
                        {openContractDetails ? (
                          <FormattedMessage id='common.less' />
                        ) : (
                          `+${offer.transportations.length - 1}`
                        )}
                        <Chevron
                          style={{
                            transform: `rotate(${
                              openContractDetails ? '180deg' : '0'
                            })`
                          }}
                        />
                      </MoreContractsCounter>
                    )}
                  </LocationRow>
                  <DateTime>
                    {line.container.name}
                    {', '}
                    {line.shipowner.name}
                    {', '}
                    {'x'}
                    {line.numberOfContainers}
                  </DateTime>
                </Location>
              )}
            </React.Fragment>
          ))}
        </ContractLocations>
        <OfferorWithoutGrid
          hidePhoneNumber
          offeror={reservation.offeror}
          withDate
          date={moment(reservation.createdAt).fromNow()}
        />
      </TableRow>
      <MobileTableRowMyReservations
        offer={offer}
        reservation={reservation}
        open={openContractDetails}
        setOpen={setOpenContractDetails}
        openReservations={openReservations}
        handleOffersCounterClick={handleOffersCounterClick}
        showReservations
      />
      {openReservations && (
        <ReservationDetails
          price={reservation.price}
          createdAt={reservation.createdAt}
          finishedAt={reservation.offer.finishedAt}
          validUntil={offer.validUntilDate}
          chatAvailable={chatAvailable}
          offer={reservation.offer}
          setConversationOpened={() =>
            setConversationOpened({
              conversationOpened: true,
              receiverId: reservation.offer.handlerId
            })
          }
          isCancellable={reservation.isCancellable}
          handleCancelReservation={() => {
            cancelReservation({
              offerId: reservation.offer.id,
              reservationId: reservation.id
            })
          }}
        />
      )}
    </>
  )
}

export default connectMyContractsReservationsOngoing(OfferRow)

const Arrow = styled.span`
  color: ${({ theme }) => theme.colors.apple.hex()};
`
const LocationRow = styled.div`
  display: flex;
  align-items: center;
`
const MoreContractsCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  cursor: pointer;
  font-size: 0.75rem;
`

const OfferLink = styled(Link)`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
