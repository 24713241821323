import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import Checkbox from 'src/components/atoms/CheckboxInput'

export default forwardRef(
  (
    { label, children, handleClose = () => {}, opened, setOpened, className },
    ref
  ) => {
    const handleChange = () => {
      opened && handleClose()
      setOpened(!opened)
    }

    return (
      <Section opened={opened} ref={ref} className={className}>
        <Checkbox
          value={opened}
          option={{ value: true, text: label }}
          onChange={handleChange}
        />
        <Content opened={opened}>{children}</Content>
      </Section>
    )
  }
)

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.4rem;
  ${({ opened }) =>
    opened &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.alpha(0.05).hex()};
    `}
`

const Content = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.8rem;
  margin-left: 0.6rem;
  height: 0;
  overflow: hidden;
  ${({ opened }) =>
    opened &&
    css`
      height: auto;
      overflow: visible;
    `}
`
