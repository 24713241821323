import React from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import {
  BigInputContainer,
  SmallInputContainer
} from 'src/features/orders/components/atoms'
import {
  InputTitle,
  RadioWrapper
} from 'src/features/invoices/components/InvoiceSettingsDialog'
import {
  INVOICE_LANGUAGES_OPTIONS,
  INVOICE_NUMBERING_SCHEME_OPTIONS
} from 'src/features/invoices/duck/consts'
import TextInput from 'src/components/atoms/TextInput'
import { validateAddInvoice } from 'src/features/invoices/duck/schema'
import DatePicker from 'src/components/atoms/DatePicker'
import Select from 'src/features/invoices/components/Select'
import { CURRENCIES_OPTIONS } from 'src/features/offers/duck/consts'
import SearchRecipient from 'src/features/invoices/components/SearchRecipient'
import {
  AddIcon,
  AddRecipientButton,
  DateInputContainer,
  RecipientContainer,
  Row,
  SearchRecipientContainer,
  SelectRow
} from 'src/features/invoices/components/TopSection/styles'
import { RecipientDetails } from 'src/features/invoices/components/TopSection/RecipientDetails'
import { StyledCheckbox } from 'src/features/invoices/styles'

export const SectionTopMobile = ({
  setInvoiceEditFormValue,
  onClick,
  recipientAddedManually,
  invoice,
  recalculateInvoice,
  clearRecipient,
  setInvoiceMultipleFormValues,
  onPostfixChange
}) => {
  const intl = useIntl()
  return (
    <div>
      <Row>
        <BigInputContainer>
          <InputTitle required>
            <FormattedMessage id='invoices.numberingScheme' />
          </InputTitle>
          <RadioWrapper>
            {INVOICE_NUMBERING_SCHEME_OPTIONS.map(opt => (
              <StyledCheckbox
                key={opt.value}
                name='numberingScheme'
                value={invoice.numberingScheme}
                selected={opt.value === invoice.numberingScheme}
                onChange={(name, value) => {
                  setInvoiceEditFormValue(name, value)
                }}
                type='radio'
                option={opt}
              />
            ))}
          </RadioWrapper>
        </BigInputContainer>
      </Row>
      <Row>
        <SmallInputContainer>
          <TextInput
            label={intl.formatMessage({
              id: 'invoices.numberPostfix'
            })}
            id='numberPostfix'
            name='numberPostfix'
            placeholder=''
            value={invoice.numberPostfix}
            onChange={onPostfixChange}
            validate={validateAddInvoice}
            hideError
          />
        </SmallInputContainer>
        <SmallInputContainer>
          <InputTitle>
            {intl.formatMessage({
              id: 'common.language'
            })}
          </InputTitle>
          <Select
            options={INVOICE_LANGUAGES_OPTIONS}
            label={intl.formatMessage({
              id: 'common.language'
            })}
            name='pdfLang'
            validate={validateAddInvoice}
            value={invoice.pdfLang}
            onChange={e => setInvoiceEditFormValue('pdfLang', e.target.value)}
            id='pdfLang'
          />
        </SmallInputContainer>
        <SmallInputContainer>
          <InputTitle>
            {intl.formatMessage({
              id: 'forms.currency'
            })}
          </InputTitle>
          <Select
            options={CURRENCIES_OPTIONS}
            label={intl.formatMessage({
              id: 'forms.currency'
            })}
            name='currency'
            validate={validateAddInvoice}
            value={invoice.currency}
            onChange={e =>
              setInvoiceEditFormValue(
                'currency',
                e.target.value,
                recalculateInvoice()
              )
            }
            id='currency'
          />
        </SmallInputContainer>
      </Row>
      <SearchRecipientContainer>
        <SearchRecipient
          required
          id='recipientCompanyName'
          label={intl.formatMessage({
            id: 'invoices.recipient'
          })}
          name='recipientCompanyName'
          value={recipientAddedManually ? '' : invoice.recipientCompanyName}
          placeholder={intl.formatMessage({
            id: 'invoices.searchRecipientPlaceholder'
          })}
          searchHintText={intl.formatMessage({
            id: 'invoices.searchRecipientHint'
          })}
          emptyResultsText={intl.formatMessage({
            id: 'invoices.emptyResultsText'
          })}
          renderPrediction={(option, onClose) => (
            <SelectRow
              selected={option.id === invoice.recipientCompanyId}
              key={option.id}
              name={option.name}
              taxId={option.taxId}
              onClick={() => {
                clearRecipient()
                setInvoiceMultipleFormValues({
                  recipientCompanyAddress: option.address,
                  recipientCompanyAddressLine2: option.addressNote,
                  recipientCompanyCity: option.city,
                  recipientCompanyCountryCode: option.countryCode,
                  recipientCompanyEmail: option.email,
                  recipientCompanyId: option.id,
                  recipientCompanyName: option.name,
                  recipientCompanyPostalCode: option.postalCode,
                  recipientCompanyTaxId: option.taxId
                })
                onClose()
              }}
            />
          )}
        />
        <div>
          <AddRecipientButton onClick={onClick}>
            <AddIcon />
          </AddRecipientButton>
        </div>
      </SearchRecipientContainer>
      <RecipientContainer>
        <RecipientDetails invoice={invoice} />
      </RecipientContainer>

      <Row>
        <DateInputContainer>
          <TextInput
            required
            label={intl.formatMessage({
              id: 'invoices.issuePlace'
            })}
            id='issuePlace'
            name='issuePlace'
            placeholder=''
            value={invoice.issuePlace}
            onChange={setInvoiceEditFormValue}
            validate={validateAddInvoice}
          />
        </DateInputContainer>
        <DateInputContainer>
          <DatePicker
            required
            label={intl.formatMessage({
              id: 'invoices.issueDate'
            })}
            name='issueDate'
            placeholder='dd.mm.rrrr'
            value={invoice.issueDate}
            onChange={setInvoiceEditFormValue}
          />
        </DateInputContainer>
      </Row>
      <Row>
        <DateInputContainer style={{ marginLeft: 'auto' }}>
          <DatePicker
            required
            label={intl.formatMessage({
              id: 'invoices.sellDate'
            })}
            name='sellDate'
            placeholder='dd.mm.rrrr'
            value={invoice.sellDate}
            minDate={invoice.issueDate}
            onChange={setInvoiceEditFormValue}
          />
        </DateInputContainer>
        <DateInputContainer style={{ marginLeft: 'auto' }}>
          <DatePicker
            required
            label={intl.formatMessage({
              id: 'invoices.dueDate'
            })}
            name='dueDate'
            placeholder='dd.mm.rrrr'
            minDate={invoice.sellDate}
            value={invoice.dueDate}
            onChange={setInvoiceEditFormValue}
          />
        </DateInputContainer>
      </Row>
    </div>
  )
}
