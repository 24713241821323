import React from 'react'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'
import {
  DateTime,
  Ellipsis,
  Location
} from 'src/features/stocks/components/atoms'

import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import {
  EllipsisContainer,
  TableRow
} from 'src/features/contracts/components/atoms'
import styled from 'styled-components'
import { isNotOlderThanMinutes } from 'src/utils/helpers'
import { Action, Actions } from 'src/features/myOffers/components/atoms'
import {
  TRACKING_TABLE_NAMES,
  TRACKING_TABLE_WIDTHS
} from 'src/features/tracking/duck/consts'
import { connectTrackingOngoing } from 'src/features/tracking/duck/connectors'
import EditIcon from 'src/assets/icons/edit.svg'
import TrackingStatus from 'src/features/tracking/components/TrackingStatusDot'
import MobileTrackingRow from 'src/features/tracking/components/MobileTrackingRow'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'

const OfferRow = ({ offer, handleClick }) => {
  const isNew = isNotOlderThanMinutes(offer.createdAt, 1)

  return (
    <>
      <TableRow
        widths={TRACKING_TABLE_WIDTHS}
        columnNames={TRACKING_TABLE_NAMES}
      >
        <Status>
          <TrackingStatus status={offer.status} />
          <EllipsisContainer>
            <Ellipsis>
              <FormattedMessage id={`tracking.${offer.status}`} />
            </Ellipsis>
          </EllipsisContainer>
        </Status>
        <div>
          <OfferLink onClick={() => handleClick(offer.id)}>
            {offer.referenceNumber}
          </OfferLink>
        </div>
        <Location>
          <Ellipsis>
            {offer.originLocationCity ||
              (offer.originLocation.name
                ? `${offer.originLocation.countryCode}, ${offer.originLocation.name}`
                : '-')}
          </Ellipsis>
          <DateTime>
            {offer.originLocationOperationDate}
            {offer.originLocationOperationTime &&
              `, ${offer.originLocationOperationTime}`}
          </DateTime>
        </Location>
        <Location>
          <Ellipsis>
            {offer.destinationLocationCity ||
              (offer.destinationLocation.name
                ? `${offer.destinationLocation.countryCode}, ${offer.destinationLocation.name}`
                : '-')}
          </Ellipsis>
          <DateTime>
            {offer.destinationLocationOperationDate}
            {offer.destinationLocationOperationTime &&
              `, ${offer.destinationLocationOperationTime}`}
          </DateTime>
        </Location>
        <div>
          <Ellipsis>
            {!offer.offer.container.name &&
              !offer.offer.shipowner.name &&
              !offer.offer.containerSemiTrailer &&
              '-'}
            {offer.offer.container.name}
            {offer.offer.shipowner.name && `, ${offer.offer.shipowner.name}`}
            {offer.offer.containerSemiTrailer &&
              offer.offer.containerSemiTrailer.name}
          </Ellipsis>
        </div>
        <div>
          <Ellipsis>{offer.forwarderUser.company.name}</Ellipsis>
        </div>
        <CreatedAtField
          isNew={isNew}
          addedAt={offer.createdAt}
          reportedDelay={offer.reportedDelay}
        />
        {offer.isEditable && (
          <Actions>
            <Action
              onClick={() => {
                navigate(
                  `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_EDIT}&id=${offer.id}`
                )
              }}
            >
              <EditIcon />
            </Action>
          </Actions>
        )}
      </TableRow>
      <MobileTrackingRow offer={offer} isNew={isNew} />
    </>
  )
}

export default connectTrackingOngoing(OfferRow)

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const Status = styled.div`
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`
