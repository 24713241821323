import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import MUIIcon from '@material-ui/icons/KeyboardArrowDown'
import { useIntl, FormattedMessage, navigate } from 'gatsby-plugin-intl'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import { SaveOfferButton } from 'src/components/atoms/Buttons'
import XIcon from 'src/assets/icons/close-x.svg'
import { breakpoint } from 'src/theme/grid'
import { OfferLink } from 'src/features/myOffers/components/atoms'
import Shade from 'src/components/Shade'
import CheckIcon from 'src/assets/icons/check.svg'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import connect from 'src/features/tracking/duck/connectors'

const AcceptReservationButton = ({
  className,
  active,
  acceptReservation,
  hasTracking,
  offerReferenceNumber,
  onOfferNumberClick,
  isLoading,
  refreshList,
  trackingItem
}) => {
  const intl = useIntl()
  const [menuOpen, setMenuOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const buttonRef = useRef(null)
  const mobileButtonRef = useRef(null)

  const handleMenuClick = e => {
    e.stopPropagation()
    setMenuOpen(!menuOpen)
  }

  const handleMobileMenuClick = e => {
    e.stopPropagation()
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const handleAcceptReservation = params => {
    acceptReservation(params)
    params.withTracking && setModalOpen(true)
  }

  const handleCloseModal = () => {
    refreshList()
    setModalOpen(false)
  }

  return (
    <>
      <MobileButton
        active={active}
        onClick={handleMobileMenuClick}
        ref={mobileButtonRef}
      >
        <CheckIcon />
        <Menu
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          anchorEl={mobileButtonRef?.current}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <MenuItem
            onClick={() => {
              handleAcceptReservation({ withTracking: true })
            }}
          >
            {intl.formatMessage({ id: 'myOffers.acceptWithTracking' })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAcceptReservation({ withTracking: false })
            }}
          >
            {intl.formatMessage({ id: 'myOffers.acceptWithoutTracking' })}
          </MenuItem>
        </Menu>
      </MobileButton>
      <div
        className={className}
        onClick={() => handleAcceptReservation({ withTracking: hasTracking })}
      >
        <span>
          <FormattedMessage id='myOffers.accept' />
        </span>
        <ContextMenuButtonButton
          active={active}
          onClick={handleMenuClick}
          ref={buttonRef}
        >
          <ArrowIcon
            style={{
              transform: `rotate(${menuOpen ? '180deg' : '0'})`
            }}
          />
          <Menu
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            anchorEl={buttonRef?.current}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <MenuItem
              onClick={() => {
                handleAcceptReservation({ withTracking: true })
              }}
            >
              {intl.formatMessage({ id: 'myOffers.acceptWithTracking' })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAcceptReservation({ withTracking: false })
              }}
            >
              {intl.formatMessage({ id: 'myOffers.acceptWithoutTracking' })}
            </MenuItem>
          </Menu>
        </ContextMenuButtonButton>
      </div>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          style: { borderRadius: 9 }
        }}
      >
        {isLoading && <Shade />}
        <DialogContent>
          <CloseIcon onClick={handleCloseModal} />
          <DialogHeader>
            <FormattedMessage
              id='myOffers.acceptWithTrackingModalTitle'
              values={{ offerId: offerReferenceNumber }}
            />
          </DialogHeader>
          <DialogText>
            <FormattedMessage
              id='myOffers.acceptWithTrackingModalDescription'
              values={{
                offerId: (
                  <OfferLink onClick={onOfferNumberClick}>
                    {offerReferenceNumber}
                  </OfferLink>
                ),
                p: (...chunks) => (
                  <p>
                    <br />
                    {chunks}
                  </p>
                )
              }}
            />
          </DialogText>
          <ActionBar>
            <CancelButton onClick={handleCloseModal}>
              {intl.formatMessage({ id: 'myOffers.notNow' })}
            </CancelButton>
            <SaveOfferButton
              active
              onClick={() =>
                navigate(
                  `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_EDIT}&id=${trackingItem.id}`
                )
              }
            >
              <FormattedMessage id='myOffers.completeData' />
            </SaveOfferButton>
          </ActionBar>
        </DialogContent>
      </Dialog>
    </>
  )
}

const StyledAcceptButton = styled(AcceptReservationButton)`
  font-size: 1rem;
  font-weight: 600;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35px;
  overflow: visible !important;
  padding: 0 1.2rem;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.mercury.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  pointer-events: none;
  position: relative;
  padding-right: 52px;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      cursor: pointer;
      color: white;
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      box-shadow: 0 2px 10px 0 rgba(71, 170, 66, 0.25);
    `}
  ${breakpoint.m`
  display: flex;
  `}
`

export default connect(StyledAcceptButton)

const ArrowIcon = styled(MUIIcon)`
  color: white !important;
  font-size: 1.2rem;
`

const ContextMenuButtonButton = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.corduroy.darken(0.3).hex()};
  position: absolute;
  right: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.darken(0.3).hex()};
    `}
`
const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    width: 600px;
    padding: 1.6rem 2.2rem;
  `}
`

const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  margin: 0.8rem 0 1.2rem 0;
  width: 100%;
  display: inline-block;
`

const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    margin-bottom: 0;
  `}
`

const CancelButton = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
  margin-right: 0.8rem;
  align-items: center;
  ${breakpoint.m`
    margin-right: 2.2rem;
  `}
`

const MobileButton = styled.div`
  box-sizing: border-box;
  padding: 0 0.5rem;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  ${breakpoint.m`
    display: none;
  `}
`
