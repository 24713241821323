import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { FormattedMessage, Link, navigate, useIntl } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroup,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection
} from 'src/features/offers/components/atoms'
import { breakpoint } from 'src/theme/grid'
import Drawer from 'src/features/stocks/components/Drawer'
import {
  BigInputContainer,
  CreateOfferButton,
  InputRow
} from 'src/features/orders/components/atoms'
import { connectInvoiceNote } from 'src/features/invoices/duck/connectors'
import TextInput from 'src/components/atoms/TextInput'
import { API_STATES, DATE_API_FORMAT, DATE_FORMAT } from 'src/ducks/consts'
import { TitleContainer } from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'
import useValidateSchema from 'src/hooks/useValidateSchema'
import {
  validateAddNote,
  validateAddNoteSchema
} from 'src/features/invoices/duck/schema'
import { RecipientDetails } from 'src/features/invoices/components/TopSection/RecipientDetails'
import { Center, IssuerContainer } from 'src/features/invoices/styles'
import { IssuerDetails } from 'src/features/invoices/components/TopSection/IssuerDetails'
import { TextTitle } from 'src/features/invoices/InvoiceDetails'
import { DateInputContainer } from 'src/features/invoices/components/TopSection/styles'
import DatePicker from 'src/components/atoms/DatePicker'
import moment from 'moment'
import useUrlParams from 'src/hooks/useUrlParams'

const AddNote = ({
  className,
  clearInvoiceDetails,
  showInvoiceDetails,
  invoiceDetails,
  createCorrectionNote
}) => {
  const intl = useIntl()
  const { id } = useUrlParams()

  useEffect(() => {
    id && showInvoiceDetails(id)
  }, [id])

  useEffect(() => () => clearInvoiceDetails(), [])
  function formReducer(state, action) {
    switch (action.type) {
      case 'setValue':
        return { ...state, [action.payload.name]: action.payload.value }
      default:
        return state
    }
  }

  const [form, dispatch] = useReducer(formReducer, {
    correctedContent: '',
    originalContent: '',
    issueDate: moment().format(DATE_API_FORMAT)
  })

  const setFormValue = (name, value) =>
    dispatch({ type: 'setValue', payload: { name, value } })

  const isLoading = invoiceDetails.state === API_STATES.IN_PROGRESS

  const valid = useValidateSchema(
    form,
    validateAddNoteSchema({
      issueDate: moment(invoiceDetails.issueDate)
        .subtract(1, 'day')
        .set({
          hour: 23,
          minute: 59
        })
    })
  )

  const validate = validateAddNote({
    issueDate: moment(invoiceDetails.issueDate)
      .subtract(1, 'day')
      .set({
        hour: 23,
        minute: 59
      })
  })

  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                <FormattedMessage id='invoices.createCorrectionNote' />
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          <FormSection>
            <FormInnerWrapper>
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'invoices.invoices' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`
                    },
                    {
                      name: intl.formatMessage({
                        id: 'invoices.createCorrectionNote'
                      }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_ADD_NOTE}`
                    }
                  ]}
                />
                <PageTitleContainer>
                  <PageTitle>
                    <FormattedMessage id='invoices.correctionNote' />{' '}
                  </PageTitle>
                </PageTitleContainer>
                <TopRow>
                  <div style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.correctedInvoice' />
                    </TextTitle>
                    <OfferLink
                      onClick={() =>
                        navigate(
                          `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${invoiceDetails.id}`
                        )
                      }
                    >
                      {invoiceDetails.number}{' '}
                    </OfferLink>
                    <Date>
                      <FormattedMessage id='invoices.issuedAt' />{' '}
                      {invoiceDetails.issueDate &&
                        moment(invoiceDetails.issueDate).format(DATE_FORMAT)}
                    </Date>
                  </div>
                  <DateInputContainer>
                    <DatePicker
                      label={intl.formatMessage({
                        id: 'invoices.noteIssueDate'
                      })}
                      minDate={invoiceDetails.issueDate}
                      name='issueDate'
                      placeholder='dd.mm.rrrr'
                      value={form.issueDate}
                      onChange={setFormValue}
                      validate={validate}
                      required
                    />
                  </DateInputContainer>
                </TopRow>
                <Row style={{ marginBottom: '2rem' }}>
                  <div style={{ flex: 1 }}>
                    <IssuerDetails invoice={invoiceDetails} showLabel />
                  </div>
                  <div style={{ flex: 1 }}>
                    <RecipientDetails invoice={invoiceDetails} showLabel />
                  </div>
                </Row>
                <Row>
                  <div style={{ flex: 1 }}>
                    <TextInput
                      required
                      type='text'
                      label={intl.formatMessage({
                        id: 'invoices.toBeCorrected'
                      })}
                      id='originalContent'
                      name='originalContent'
                      placeholder=''
                      value={form.originalContent}
                      onChange={setFormValue}
                      multiline
                      validate={validate}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextInput
                      required
                      type='text'
                      label={intl.formatMessage({
                        id: 'invoices.correct'
                      })}
                      id='correctedContent'
                      name='correctedContent'
                      placeholder=''
                      value={form.correctedContent}
                      onChange={setFormValue}
                      multiline
                      validate={validate}
                    />
                  </div>
                </Row>
                <AdditionalInfo>
                  <TitleContainer>
                    <Title>
                      <FormattedMessage id='orders.additionalInfo' />
                    </Title>
                  </TitleContainer>
                  <InputRow>
                    <BigInputContainer>
                      <TextInput
                        type='text'
                        label={intl.formatMessage({
                          id: 'invoices.noteAdditionalInfo'
                        })}
                        id='additionalInfo'
                        name='additionalInfo'
                        placeholder=''
                        value={form.additionalInfo}
                        onChange={setFormValue}
                        multiline
                        validate={validate}
                      />
                    </BigInputContainer>
                    <IssuerContainer>
                      <TextInput
                        disabled
                        label={intl.formatMessage({
                          id: 'invoices.issuer'
                        })}
                        id='issuer'
                        name='issuer'
                        placeholder=''
                        value={`${invoiceDetails.issuer.firstName} ${invoiceDetails.issuer.lastName}`}
                        validate={validate}
                      />
                    </IssuerContainer>
                  </InputRow>
                </AdditionalInfo>
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Center>
                <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`}>
                  {intl.formatMessage({ id: 'forms.cancel' })}
                </Link>
                <ActionGroup>
                  <CreateOfferButton
                    active={valid && !isLoading}
                    onClick={() => {
                      createCorrectionNote({
                        invoiceId: invoiceDetails.id,
                        values: {
                          issueDate: form.issueDate,
                          originalContent: form.originalContent,
                          correctedContent: form.correctedContent,
                          additionalInfo: form.additionalInfo
                        }
                      })
                    }}
                  >
                    <FormattedMessage id='invoices.createNote' />
                  </CreateOfferButton>
                </ActionGroup>
              </Center>
            </FormActionBar>
          </FormSection>
        </Content>
      </Page>
    </>
  )
}

const ConnectedComponent = compose(connectInvoiceNote)(AddNote)

export default styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
    background-color: #f8f8f9;
  }
  ${Padding} {
    max-width: calc(887px + 2.8rem);
    margin: 0 auto;
  }
  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: column;
  & > div,
  p:not(:last-child) {
    margin-right: 0.5rem;
  }
  ${breakpoint.m`
    flex-direction: row;
  `}
`
const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const Date = styled.span`
  font-size: 0.875rem;
`
const PageTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  padding-bottom: 4px;
`
const PageTitleContainer = styled(TitleContainer)`
  padding-top: 1rem;
  ${breakpoint.m`
    padding-top: 2rem;
    margin-bottom: 2rem;
  `}
`

const TopRow = styled(Row)`
  & > div:nth-child(odd) {
    margin-bottom: 2rem;
    ${breakpoint.m`
    margin-bottom: 0;
  `}
  }
  ${breakpoint.m`
    margin-bottom: 1rem;
  `}
`

const AdditionalInfo = styled.div`
  ${breakpoint.m`
      margin-top: 2rem;
  `}
`
