import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import camelcase from 'lodash.camelcase'
import { checkDocumentStatusByDate } from 'src/utils/helpers'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import { DATE_FORMAT } from 'src/ducks/consts'
import {
  DOCUMENTS_COLORS,
  DOCUMENTS_STATES
} from 'src/features/account/duck/consts'
import { ChatIcon, Dot, EditIcon } from 'src/features/account/components/atoms'
import PdfIcon from 'src/assets/icons/pdf-2.svg'
import PersonIconUrl from 'src/assets/person.svg'
import EmailIconUrl from 'src/assets/mail-alt.svg'
import CustomIcon from 'src/components/CustomIcon'
import { Avatar } from 'src/components/atoms/Avatar'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import { connectUserAccount } from 'src/features/account/duck/connectors'

export const DocumentsRow = document => {
  const status = document.url
    ? checkDocumentStatusByDate(document.validTo)
    : DOCUMENTS_STATES.MISSING

  return (
    <>
      <DocumentName>
        <EllipsisTooltip>
          <FormattedMessage id={`documentTypes.${camelcase(document.type)}`} />
        </EllipsisTooltip>
      </DocumentName>
      <DocumentFrom>
        <span>
          <FormattedMessage id='myCompany.documents.from' />:{' '}
        </span>
        {document.validFrom
          ? moment(document.validFrom).format(DATE_FORMAT)
          : '-'}
      </DocumentFrom>
      <DocumentTo>
        <span>
          {' '}
          <FormattedMessage id='myCompany.documents.to' />:{' '}
        </span>
        {document.validTo ? (
          <>
            {moment(document.validTo).format(DATE_FORMAT)}
            {status === DOCUMENTS_STATES.EXPIRED && (
              <StyledCustomIcon size={16} name='alert' />
            )}
            {status === DOCUMENTS_STATES.EXPIRING && (
              <StyledCustomIcon size={16} name='warning' />
            )}
          </>
        ) : (
          '-'
        )}
      </DocumentTo>
      <DocumentStatus>
        <Dot color={DOCUMENTS_COLORS[status]} />
        <FormattedMessage id={`documentStates.${status}`} />
      </DocumentStatus>
      <DocumentIcon>
        {document.url && (
          <a href={document.url} target='_blank'>
            <PdfIcon />
          </a>
        )}
      </DocumentIcon>
    </>
  )
}

const UsersRowComponent = ({
  user,
  onEdit,
  handleConversationStart,
  editAllowed,
  currentUser
}) => (
  <>
    <Name>
      <Avatar medium url={user.avatar && user.avatar.url} />
      <NameAndRoleContainer>
        <EllipsisTooltip>
          {`${user.firstName} ${user.lastName}`}
        </EllipsisTooltip>
        <MobileVisibleRole>
          <FormattedMessage id={`common.${user.role}`} />
        </MobileVisibleRole>
      </NameAndRoleContainer>
    </Name>
    <Username>
      <EllipsisTooltip>{user.username}</EllipsisTooltip>
    </Username>
    <Email>
      <EllipsisTooltip>{user.email}</EllipsisTooltip>
    </Email>
    <Role>
      <FormattedMessage id={`common.${user.role}`} />
    </Role>
    <Status>
      {user.isActive ? (
        <>
          <Dot color='apple' />
          <FormattedMessage id='myCompany.users.isActive' />
        </>
      ) : (
        <>
          <Dot color='fadedRed' />
          <FormattedMessage id='myCompany.users.isNotActive' />
        </>
      )}
    </Status>
    {currentUser.id !== user.id && (
      <IconChat onClick={handleConversationStart}>
        <ChatIcon name='chat' size={24} />
      </IconChat>
    )}
    <IconEdit onClick={() => editAllowed && onEdit(user.id)}>
      {editAllowed && <EditIcon name='edit' size={24} />}
    </IconEdit>
  </>
)

export const UsersRow = connectUserAccount(UsersRowComponent)

const DocumentName = styled.div`
  text-transform: capitalize;
  font-weight: 600;
  grid-area: name;
`

const DocumentFrom = styled.div`
  grid-area: from;
  font-size: 0.75rem;
  span {
    color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
    ${breakpoint.m`
      display: none;
  `}
  }
`

const DocumentTo = styled.div`
  grid-area: to;
  font-size: 0.75rem;
  i {
    display: none;
    ${breakpoint.m`
    display: inline-block;
  `}
  }
  span {
    color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
    ${breakpoint.m`
      display: none;
  `}
  }
`

const DocumentIcon = styled.div`
  grid-area: icon;
  text-align: center;
  font-size: 0.75rem;
  justify-self: end;
  ${breakpoint.m`
      justify-self: start;
  `}
`

const DocumentStatus = styled.div`
  grid-area: status;
  font-size: 0.75rem;
  justify-self: end;
  ${breakpoint.m`
      justify-self: start;
  `}
`

const Username = styled.div`
  grid-area: username;
  font-size: 0.75rem;

  && {
    padding-left: 32px;
    position: relative;
    ${breakpoint.m`
      padding-left: 10px;
  `}
  }

  &:before {
    position: absolute;
    left: 10px;
    content: '';
    background-image: url(${PersonIconUrl});
    opacity: 0.7;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    ${breakpoint.m`
      display: none;
  `}
  }
`
const Email = styled.div`
  grid-area: email;
  font-size: 0.75rem;

  && {
    padding-left: 22px;
    position: relative;
    ${breakpoint.m`
      padding-left: 10px;
  `}
  }

  &:before {
    position: absolute;
    left: 0px;
    content: '';
    background-image: url(${EmailIconUrl});
    opacity: 0.7;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    ${breakpoint.m`
      display: none;
  `}
  }
`
export const Role = styled.div`
  grid-area: role;
  font-size: 0.75rem;
  display: none;
  ${breakpoint.m`
      display: block;
  `}
`

export const MobileVisibleRole = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-size: 0.75rem;
  font-weight: normal;
  ${breakpoint.m`
      display: none;
  `}
`

const Status = styled.div`
  grid-area: status;
  font-size: 0.75rem;
  justify-self: end;
  ${breakpoint.m`
      justify-self: start;
  `}
`

export const IconEdit = styled.div`
  cursor: pointer;
  grid-area: icon1;
  display: none;
  box-sizing: border-box;
  ${breakpoint.m`
      display: block;
  `}
`

export const IconChat = styled.div`
  grid-area: icon2;
  cursor: pointer;
  text-align: right;
  display: none;
  box-sizing: border-box;
  ${breakpoint.m`
      display: block;
  `}
`

export const Name = styled(DocumentName)`
  grid-area: name;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  ${Avatar} {
    margin-right: 1rem;
  }
`

export const NameAndRoleContainer = styled.div`
  overflow: hidden;
`

const StyledCustomIcon = styled(CustomIcon)`
  display: inline-block;
  z-index: 0;
  vertical-align: middle;
  margin-left: 0.7rem;
`
