import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Currency, Reservations } from 'src/features/myOffers/components/atoms'
import { MAX_RESERVATIONS_COUNT_FOR_QUEUE } from 'src/ducks/consts'

export const ReservationsField = ({
  hasReservations,
  onClick,
  auction,
  reservationsCount,
  price,
  open,
  finishedOffer
}) => (
  <Reservations hasReservations={hasReservations} onClick={onClick}>
    <span>
      <FormattedMessage id='myOffers.reservations' />
    </span>
    {!auction &&
      !finishedOffer &&
      `${reservationsCount}/${MAX_RESERVATIONS_COUNT_FOR_QUEUE}`}
    {auction &&
      !finishedOffer &&
      `${reservationsCount}/${MAX_RESERVATIONS_COUNT_FOR_QUEUE}`}
    {finishedOffer && !auction && `${reservationsCount}`}
    {auction &&
      reservationsCount === 0 &&
      finishedOffer &&
      `${reservationsCount}`}
    {auction && reservationsCount > 0 && `${price.float}`}
    {auction && reservationsCount > 0 && <Currency>{price.currency}</Currency>}
    {hasReservations && (
      <div>
        <MUIDropdownIcon
          width={24}
          height={24}
          style={{ transform: `rotate(${open ? '180deg' : '0'})` }}
        />
      </div>
    )}
  </Reservations>
)
