import React from 'react'
import { compose } from 'recompose'
import { useIntl } from 'gatsby-plugin-intl'
import PlaceAndDatesRangeBox from 'src/components/filters/PlaceAndDatesRangeBox'
import Select from 'src/components/filters/SelectBox'
import SelectRemote from 'src/components/filters/SelectRemoteBox'
import {
  SearchFilter,
  SelectWrapper
} from 'src/components/filters/components/atoms'
import { connectDictionaries } from 'src/ducks/connectors'
import { connectImport } from 'src/features/stocks/ducks/connectors'
import {
  EURO_OPTIONS,
  REFERENCE_NUMBER_PREFIX,
  RESERVATION_STATUS_OPTIONS,
  VISIBILITY_OPTIONS
} from 'src/features/stocks/ducks/consts'
import { LOCATION_TYPES } from 'src/ducks/consts'
import FiltersRow from './FiltersRow'
import PermissionsContext, {
  PERMISSIONS,
  hasPermissionTo
} from 'src/utils/permissions'

const ImportFilters = ({
  setFilter,
  setFilters,
  filters,
  dictionaries,
  pickupDictionary,
  unloadingDictionary,
  fetchLocations,
  companiesDictionary,
  fetchCompanies,
  activeFiltersNumber,
  clearFilters,
  createFilterTab,
  removeFilterTab,
  tabs,
  tabsData,
  activeTab,
  setActiveTab
}) => {
  const intl = useIntl()
  const permissions = React.useContext(PermissionsContext)

  return (
    <FiltersRow
      activeFiltersNumber={activeFiltersNumber}
      clearFilters={clearFilters}
      createFilterTab={createFilterTab}
      removeFilterTab={removeFilterTab}
      tabs={tabs}
      tabsData={tabsData}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      advancedFilters={
        <>
          <SelectRemote
            id='filters-import-company'
            name='company'
            label={intl.formatMessage({ id: 'offersFilters.company' })}
            value={filters.company}
            options={[]}
            onChange={setFilter}
            placeholder={intl.formatMessage({
              id: 'offersFilters.searchPlaceholder'
            })}
            searchHintText={intl.formatMessage({
              id: 'offersFilters.searchHintText'
            })}
            dictionary={companiesDictionary}
            fetchCompanies={fetchCompanies}
          />
          <SelectWrapper>
            <Select
              height='440px'
              width='380px'
              id='filters-export-adr-classes'
              name='adrClass'
              label={intl.formatMessage({ id: 'offersFilters.adrClasses' })}
              value={filters.adrClass}
              options={dictionaries.adrClasses}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              removeSelectedOptions
              height='300px'
              width='230px'
              id='filters-export-euro'
              name='europeanEmissionStandard'
              label={intl.formatMessage({
                id: 'offersFilters.europeanEmissionStandard'
              })}
              value={filters.europeanEmissionStandard}
              options={EURO_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              height='350px'
              width='280px'
              id='filters-export-cargo-type'
              name='cargoType'
              label={intl.formatMessage({
                id: 'offersFilters.cargoType'
              })}
              value={filters.cargoType}
              options={dictionaries.cargoTypes}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              removeSelectedOptions
              removeSearchInput
              height='250px'
              id='filters-export-status'
              name='status'
              label={intl.formatMessage({ id: 'offersFilters.status' })}
              value={filters.status}
              options={RESERVATION_STATUS_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
              type='radio'
            />
          </SelectWrapper>
          {hasPermissionTo(
            PERMISSIONS.STOCK_PRIVATE_OFFERS_VIEW,
            permissions
          ) && (
            <SelectWrapper>
              <Select
                removeSearchInput
                removeSelectedOptions
                height='250px'
                id='filters-import-visibility'
                name='visibility'
                label={intl.formatMessage({ id: 'offersFilters.visibility' })}
                value={filters.visibility}
                options={VISIBILITY_OPTIONS.map(option => ({
                  ...option,
                  label: intl.formatMessage({ id: option.label })
                }))}
                onChange={setFilter}
                placeholder={intl.formatMessage({
                  id: 'offersFilters.searchPlaceholder'
                })}
                type='radio'
              />
            </SelectWrapper>
          )}
        </>
      }
    >
      <SelectWrapper>
        <SearchFilter
          name='referenceNumber'
          id='input-search-import'
          initValue={REFERENCE_NUMBER_PREFIX.CARGO_IMPORT}
          filterValue={filters.referenceNumber}
          activeTab={activeTab}
          active={!!filters.referenceNumber}
          setFilter={setFilter}
          placeholder={intl.formatMessage({ id: 'common.offerNumber' })}
        />
      </SelectWrapper>
      <PlaceAndDatesRangeBox
        id='filters-import-pickup'
        label={intl.formatMessage({ id: 'offersFilters.pickup' })}
        onChange={setFilter}
        onChangeBulk={setFilters}
        locationName='pickupLocation'
        locationValue={filters.pickupLocation}
        dateFromName='pickupDateFrom'
        dateFromValue={filters.pickupDateFrom}
        dateFromLabel={intl.formatMessage({
          id: 'offersFilters.pickupFrom'
        })}
        dateToName='pickupDateTo'
        dateToValue={filters.pickupDateTo}
        dateToLabel={intl.formatMessage({
          id: 'offersFilters.pickupTo'
        })}
        locationTabName={intl.formatMessage({
          id: 'offersFilters.terminal'
        })}
        datesTabName={intl.formatMessage({
          id: 'offersFilters.date'
        })}
        placeholder={intl.formatMessage({
          id: 'offersFilters.locationPlaceholderTerminal'
        })}
        locationTypes={LOCATION_TYPES.TERMINAL}
        dictionary={pickupDictionary}
        fetchLocations={fetchLocations}
        searchHintText={intl.formatMessage({
          id: 'offersFilters.searchHintText'
        })}
      />
      <PlaceAndDatesRangeBox
        id='filters-import-unloading'
        label={intl.formatMessage({ id: 'offersFilters.unloading' })}
        onChange={setFilter}
        onChangeBulk={setFilters}
        locationName='unloadingLocation'
        locationValue={filters.unloadingLocation}
        dateFromName='unloadingDateFrom'
        dateFromValue={filters.unloadingDateFrom}
        dateFromLabel={intl.formatMessage({
          id: 'offersFilters.unloadingFrom'
        })}
        dateToName='unloadingDateTo'
        dateToValue={filters.unloadingDateTo}
        dateToLabel={intl.formatMessage({
          id: 'offersFilters.unloadingTo'
        })}
        locationTabName={intl.formatMessage({
          id: 'offersFilters.place'
        })}
        datesTabName={intl.formatMessage({
          id: 'offersFilters.date'
        })}
        placeholder={intl.formatMessage({
          id: 'offersFilters.locationPlaceholderPlace'
        })}
        locationTypes={LOCATION_TYPES.CITY}
        dictionary={unloadingDictionary}
        fetchLocations={fetchLocations}
        searchHintText={intl.formatMessage({
          id: 'offersFilters.searchHintText'
        })}
      />
      <Select
        height='420px'
        id='filters-import-container'
        name='container'
        label={intl.formatMessage({ id: 'offersFilters.container' })}
        value={filters.container}
        options={dictionaries.containers}
        onChange={setFilter}
        placeholder={intl.formatMessage({
          id: 'offersFilters.searchPlaceholder'
        })}
      />
      <Select
        height='420px'
        id='filters-import-shipowner'
        name='shipowner'
        label={intl.formatMessage({ id: 'offersFilters.shipowner' })}
        value={filters.shipowner}
        options={dictionaries.shipowners}
        onChange={setFilter}
        placeholder={intl.formatMessage({
          id: 'offersFilters.searchPlaceholder'
        })}
      />
    </FiltersRow>
  )
}

export default compose(connectDictionaries, connectImport)(ImportFilters)
