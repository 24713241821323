import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import ContractIcon from 'src/assets/icons/description.svg'
import moment from 'moment'
import { DATE_LONG_FORMAT_WITH_WEEKDAY } from 'src/ducks/consts'
import {
  Date,
  DateTitle,
  Description,
  Price,
  Row,
  Title
} from 'src/components/atoms/AuctionPrice'

const ContractPrice = ({
  className,
  deadline,
  price,
  belongsToCurrentCompany
}) => (
  <div className={className}>
    <Row>
      <Title>
        {belongsToCurrentCompany && (
          <FormattedMessage id='offerDetails.currentPrice' />
        )}
        {!belongsToCurrentCompany && (
          <FormattedMessage id='makeABid.yourOffer' />
        )}
      </Title>
      <GreenContractIcon />
    </Row>
    <Row>
      <Price>
        {price.float ? price.float : <FormattedMessage id='common.missing' />}{' '}
        <span>{price.currency}</span>
        <span>
          {!belongsToCurrentCompany && !price && (
            <FormattedMessage id='offerDetails.makeFirstBid' />
          )}
        </span>
      </Price>
      <Date>
        <DateTitle>
          {moment(deadline).isAfter(moment()) && (
            <>
              {moment(deadline).fromNow(true)}
              <FormattedMessage id='offerDetails.toEnd' />
            </>
          )}
          {moment(deadline).isBefore(moment()) && (
            <FormattedMessage id='offerDetails.finished' />
          )}
        </DateTitle>
        <Description>
          ({moment(deadline).format(DATE_LONG_FORMAT_WITH_WEEKDAY)})
        </Description>
      </Date>
    </Row>
  </div>
)
export default styled(ContractPrice)`
  background-color: ${({ theme }) => theme.colors.lightGreen.hex()};
  border-radius: 4px;
  padding: 0.8rem 0.5rem 0.75rem 0.7rem;
`

export const GreenContractIcon = styled(ContractIcon)`
  margin-top: -5px;
  color: ${({ theme }) => theme.colors.apple.hex()};
`
