import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { isNil, head } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import { useIntl } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'

import useClickOutside from 'src/hooks/useClickOutside'
import {
  SelectInput,
  FilterDropdown,
  SearchInput,
  SearchInputWrapper,
  SelectOptionsWrapper,
  SelectRow
} from './components/atoms'

const SelectInputComponent = ({
  className,
  id,
  label,
  name,
  value,
  placeholder,
  onChange,
  options,
  required,
  pristine,
  validate,
  helperText,
  hideSearchInput
}) => {
  const intl = useIntl()
  const [filterOpened, setFilterOpened] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })
  const toggleDropdown = () => setFilterOpened(!filterOpened)
  const componentRef = useClickOutside(() => setFilterOpened(false))

  const handleClose = () => {
    _setPristine(false)
    setFilterOpened(false)
  }

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(name, value, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [value, inputPristine, pristine])

  const isMultiple = () => Array.isArray(value)

  const generateFilterName = () => {
    const selectedId = isMultiple() ? head(value) : value
    if (isFalsy(selectedId)) {
      return label
    }

    const selectedOption = head(options.filter(opt => opt.value === selectedId))
    return !isFalsy(selectedOption) ? selectedOption.label : label
  }

  const handleChange = (optionValue, isThisValueSelected) => {
    const singleValue = () => (isThisValueSelected ? null : optionValue)
    const multipleValue = () =>
      isThisValueSelected
        ? value.filter(v => v !== optionValue)
        : [...value, optionValue]
    onChange(name, isMultiple() ? multipleValue() : singleValue())
    !isThisValueSelected && handleClose()
  }

  const handleClear = () => {
    setSearchText('')
    onChange(name, isMultiple() ? [] : null)
  }

  //TODO: make internal search better: lowercase, trim spaces itp
  const filteredOptions = () =>
    searchText === ''
      ? options
      : options.filter(o =>
          o.label.toLowerCase().includes(searchText.toLowerCase())
        )

  const isSelected = optionValue => {
    const singleSelected = () => value === optionValue
    const multipleSelected = () => value.includes(optionValue)

    return isMultiple() ? multipleSelected() : singleSelected()
  }

  return (
    <div className={className} ref={componentRef}>
      <Label required={required}>{label}</Label>
      <SelectInput
        onClick={toggleDropdown}
        opened={filterOpened}
        hasValue={isMultiple() ? value.length > 0 : !isNil(value)}
        id={`filter-${id}`}
        selectedNumber={isMultiple() ? value.length : null}
        onClear={handleClear}
      >
        {generateFilterName()}
      </SelectInput>
      {filterOpened && (
        <FilterDropdownStyled
          filterName={label}
          onClose={() => handleClose()}
          onClear={handleClear}
          clearButtonText={intl.formatMessage({ id: 'offersFilters.clear' })}
          confirmButtonText={intl.formatMessage({
            id: 'offersFilters.confirm'
          })}
        >
          {!hideSearchInput && (
            <SearchInputWrapper>
              <SearchInput
                name={name}
                id={`input-${id}`}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                placeholder={placeholder}
              />
            </SearchInputWrapper>
          )}
          <SelectOptionsWrapperStyled>
            {filteredOptions().map(option => {
              const selected = isSelected(option.value)
              return (
                <SelectRow
                  selected={selected}
                  name={option.label}
                  onClick={() => handleChange(option.value, selected)}
                />
              )
            })}
          </SelectOptionsWrapperStyled>
        </FilterDropdownStyled>
      )}
      <HelperText valid={valid}>{valid ? helperText : error}</HelperText>
    </div>
  )
}

SelectInputComponent.defaultProps = {
  value: '',
  validate: () => true,
  pristine: true
}

export default styled(SelectInputComponent)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      div {
        opacity: 0.7;
      }
    `}
  ${FilterDropdown} {
    ${breakpoint.m`
      width: ${({ width }) => width || '260px'};
      height: 440px;
    `}
  }
`

const SelectOptionsWrapperStyled = styled(SelectOptionsWrapper)`
  height: calc(100% - 40px);
`

const Label = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`

const FilterDropdownStyled = styled(FilterDropdown)`
  ${breakpoint.m`
    top: 70px;
  `}
`

const HelperText = styled.span`
  display: inline-block;
  width: 100%;
  margin: 8px 6px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1em;
  color: ${({ theme, valid }) =>
    valid ? theme.colors.ghost.hex() : theme.colors.error.hex()};
`
