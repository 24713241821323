import React from 'react'
import {
  AuctionGavelIcon,
  AuctionIcon,
  AuctionPrice,
  AuctionPriceTitle,
  AuctionPriceValue,
  OfferorsCounter,
  SmallGreenGavelIcon,
  AuctionDetails as StyledAuctionDetails,
  AuctionTrophyIcon
} from 'src/features/myOffers/components/atoms'
import { FormattedMessage, useIntl, FormattedPlural } from 'gatsby-plugin-intl'
import { DateTime } from 'src/features/stocks/components/atoms'
import AuctionDeadline from 'src/features/myOffers/components/AuctionDeadline'
import Offeror from 'src/features/myOffers/components/Offeror'
import EllipsisTooltip from 'src/components/EllipsisTooltip'

const AuctionDetailsReservations = ({
  reservation,
  isOpen,
  offer,
  chatAvailable,
  setConversationOpened
}) => {
  const isFinished = offer.finishedAt
  const isWonByCurrentUser = isFinished && reservation.acceptedAt
  const intl = useIntl()

  return (
    <StyledAuctionDetails isOpen={isOpen}>
      <AuctionIcon>
        {isWonByCurrentUser ? <AuctionTrophyIcon /> : <AuctionGavelIcon />}
      </AuctionIcon>
      <AuctionPrice>
        <AuctionPriceTitle>
          {isFinished ? (
            <FormattedMessage id='reservations.wonPrice' />
          ) : (
            <FormattedMessage id='offerDetails.currentPrice' />
          )}
        </AuctionPriceTitle>
        {reservation && (
          <AuctionPriceValue>
            {offer.price.float}
            <span>{offer.price.currency}</span>
          </AuctionPriceValue>
        )}
        {!reservation && (
          <>
            <AuctionPriceValue>
              <FormattedMessage id='offerDetails.hidden' />
            </AuctionPriceValue>
            <DateTime>
              <EllipsisTooltip>
                <FormattedMessage id='myOffers.startPrice' />
                {': '}
                {offer.price.float}&nbsp;
                <span>{offer.price.currency}</span>
              </EllipsisTooltip>
            </DateTime>
          </>
        )}
        {reservation && (
          <DateTime>
            <EllipsisTooltip>
              {isWonByCurrentUser ? (
                <FormattedMessage id='reservations.youWon' />
              ) : (
                <>
                  <FormattedMessage id='myOffers.yoursBid' />
                  {': '}
                  {reservation.price.float}{' '}
                  <span>{reservation.price.currency}</span>
                </>
              )}{' '}
            </EllipsisTooltip>
          </DateTime>
        )}
      </AuctionPrice>
      <AuctionDeadline
        auctionDeadline={offer.auctionDeadline}
        finishedAt={offer.finishedAt}
      />
      {reservation && reservation.offeror.id && (
        <Offeror
          chatAvailable={chatAvailable}
          offeror={reservation.offer.handler}
          setConversationOpened={setConversationOpened}
        />
      )}
      <OfferorsCounter>
        <div>
          <SmallGreenGavelIcon />
        </div>
        {offer.reservationsCount}
        &nbsp;
        <div>
          <FormattedPlural
            value={offer.reservationsCount}
            other={intl.formatMessage({ id: 'myOffers.auctioneers' })}
            one={intl.formatMessage({ id: 'myOffers.auctioneer' })}
          />
        </div>
      </OfferorsCounter>
    </StyledAuctionDetails>
  )
}

export default AuctionDetailsReservations
