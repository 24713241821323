import React from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import Pagination from 'src/components/Pagination'

import LoadingList from 'src/components/atoms/LoadingList'

import Shade from 'src/components/Shade'

import { isStockLoading } from 'src/utils/stocks'

import {
  BottomRow,
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  RefreshIcon,
  Row
} from 'src/features/stocks/components/atoms'

import {
  ActionGroup,
  HeaderItem,
  Padding
} from 'src/features/myOffers/components/atoms'
import { Icon, SectionBox } from 'src/features/account/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { TableHeader } from 'src/features/contracts/components/atoms'
import Checkbox from 'src/components/atoms/CheckboxInput'
import { BOOLEAN } from 'src/ducks/consts'
import Select from 'src/components/filters/SelectBox'
import {
  FilterDropdown,
  SelectOptionsWrapper
} from 'src/components/filters/components/atoms'
import { NativeSelect } from 'src/features/tracking/components/atoms'
import { connectOrdersReceived } from 'src/features/orders/duck/connectors'
import {
  ORDERS_RECEIVED_TABLE_HEADERS,
  ORDERS_RECEIVED_TABLE_NAMES,
  ORDERS_RECEIVED_TABLE_WIDTHS,
  ORDERS_STATUS_FILTER_OPTIONS
} from 'src/features/orders/duck/consts'
import OrderRowReceived from 'src/features/orders/components/OrderRowReceived'

const OrdersReceivedList = ({
  orders,
  pagination,
  filters,
  setFilter,
  hidePagination = false,
  getOrdersListReceived,
  className,
  ordersRecords
}) => {
  const headers = ORDERS_RECEIVED_TABLE_HEADERS
  const intl = useIntl()

  const toggleFilter = name => {
    setFilter(name, !filters[name])
  }

  const renderListContents = () => {
    if (isStockLoading(ordersRecords, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (orders.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='offers.noOffers' />
        </EmptyOffers>
      )
    }

    if (orders.length > 0) {
      return orders.map(order => (
        <OrderRowReceived key={order.id} order={order} />
      ))
    }
  }

  return (
    <div className={className}>
      <Padding></Padding>
      <ListTopRow>
        <CounterGroup>
          <Counter>
            <span>{`${pagination.total} `}</span>
            <FormattedMessage
              id='orders.orderPlural'
              values={{
                count: pagination.total
              }}
            />
          </Counter>
          <RefreshIcon onClick={getOrdersListReceived} />
        </CounterGroup>
        <ActionGroup>
          <Checkbox
            name='onlyMe'
            value={filters.onlyMe}
            onChange={toggleFilter}
            option={{
              text: intl.formatMessage({
                id: 'orders.onlyMe'
              }),
              value: BOOLEAN.YES
            }}
          />
          <SelectWrapper>
            <Select
              removeSelectedOptions
              removeSearchInput
              height='250px'
              id='filters-orders-status'
              name='status'
              label={intl.formatMessage({ id: 'offersFilters.status' })}
              value={filters.status}
              options={ORDERS_STATUS_FILTER_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
              type='radio'
              alignRight
            />
          </SelectWrapper>
          <NativeSelect
            value={filters.status}
            onChange={e => {
              setFilter('status', e.target.value)
            }}
          >
            {ORDERS_STATUS_FILTER_OPTIONS.map(option => (
              <option key={option.label} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </NativeSelect>
        </ActionGroup>
      </ListTopRow>
      <Container>
        <TableHeader
          widths={ORDERS_RECEIVED_TABLE_WIDTHS}
          columnNames={ORDERS_RECEIVED_TABLE_NAMES}
        >
          {headers &&
            headers.map((header, index) => (
              <HeaderItem sort={false} key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage
                    id={`stocksHeaders.${header.name}`}
                    defaultMessage=' '
                  />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </HeaderItem>
            ))}
        </TableHeader>
        {renderListContents()}
      </Container>
      {orders.length > 0 && !hidePagination && (
        <BottomRow>
          <Pagination
            itemsPerPage={filters.limit}
            currentPage={pagination.currentPage}
            pagesCount={pagination.lastPage}
            setPage={page => setFilter('page', page)}
            setItemsPerPage={limit => {
              setFilter('limit', limit)
              setFilter('page', 1)
            }}
          />
        </BottomRow>
      )}
    </div>
  )
}

const connectedComponent = connectOrdersReceived(OrdersReceivedList)
export default styled(connectedComponent)`
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
export const ListTopRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1.2rem;
  ${Counter} {
    margin-right: 0.75rem;
    white-space: nowrap;
  }
  ${breakpoint.m`
     padding: 0;
  `}
`
const CounterGroup = styled.div`
  display: flex;
`

const SelectWrapper = styled.div`
  display: none;
  width: 100%;
  ${FilterDropdown} {
    ${breakpoint.m`
      height: 300px;
    `}
  }
  ${SelectOptionsWrapper} {
    height: 100% !important;
  }
  ${breakpoint.m`
      display: block;
  `}
`
