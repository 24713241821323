import { useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { pl, de, en } from 'src/intl/momentLocales'

const initializeIntl = () => {
  const intl = useIntl()

  useEffect(() => {
    moment.defineLocale('pl', pl)
    moment.defineLocale('de', de)
    moment.defineLocale('en', en)
  }, [])

  useEffect(() => {
    moment.locale(intl.locale)
  }, [intl.locale])

  return intl
}

export default initializeIntl
