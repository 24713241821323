import React from 'react'
import { FormattedMessage, FormattedPlural, useIntl } from 'gatsby-plugin-intl'
import {
  AuctionGavelIcon,
  AuctionIcon,
  AuctionPrice,
  AuctionPriceTitle,
  AuctionPriceValue,
  OfferorsCounter,
  SmallGreenGavelIcon,
  AuctionDetails as StyledAuctionDetails
} from 'src/features/myOffers/components/atoms'
import { DateTime } from 'src/features/stocks/components/atoms'
import moment from 'moment'
import { DATE_SHORT_FORMAT_WITH_TIME } from 'src/ducks/consts'
import AuctionDeadline from 'src/features/myOffers/components/AuctionDeadline'
import Offeror from 'src/features/myOffers/components/Offeror'
import EllipsisTooltip from 'src/components/EllipsisTooltip'

const AuctionDetailsOffers = ({
  reservation,
  isOpen,
  offer,
  chatAvailable,
  setConversationOpened = () => {}
}) => {
  const intl = useIntl()
  return (
    <StyledAuctionDetails isOpen={isOpen}>
      <AuctionIcon>
        <AuctionGavelIcon />
      </AuctionIcon>
      <AuctionPrice>
        <AuctionPriceTitle>
          <FormattedMessage id='offerDetails.currentPrice' />
        </AuctionPriceTitle>
        {reservation && (
          <AuctionPriceValue>
            {offer.price.float}
            <span>{offer.price.currency}</span>
          </AuctionPriceValue>
        )}
        {!reservation && (
          <>
            <AuctionPriceValue>
              <FormattedMessage id='offerDetails.hidden' />
            </AuctionPriceValue>
            <DateTime>
              <EllipsisTooltip>
                <FormattedMessage id='myOffers.startPrice' />
                {': '}
                {offer.price.float}&nbsp;
                <span>{offer.price.currency}</span>
              </EllipsisTooltip>
            </DateTime>
          </>
        )}
        {reservation && (
          <DateTime>
            <EllipsisTooltip>
              <FormattedMessage id='myOffers.bidAt' />
              {': '}
              {moment(reservation.acceptedAt).format(
                DATE_SHORT_FORMAT_WITH_TIME
              )}
            </EllipsisTooltip>
          </DateTime>
        )}
      </AuctionPrice>
      <AuctionDeadline
        auctionDeadline={offer.auctionDeadline}
        finishedAt={offer.finishedAt}
      />
      {reservation && reservation.offeror.id && (
        <Offeror
          chatAvailable={chatAvailable}
          offeror={reservation.offeror}
          setConversationOpened={setConversationOpened}
        />
      )}
      <OfferorsCounter>
        <div>
          <SmallGreenGavelIcon />
        </div>
        {offer.reservationsCount}
        &nbsp;
        <div>
          <FormattedPlural
            value={offer.reservationsCount}
            other={intl.formatMessage({ id: 'myOffers.auctioneers' })}
            one={intl.formatMessage({ id: 'myOffers.auctioneer' })}
          />
        </div>
      </OfferorsCounter>
    </StyledAuctionDetails>
  )
}
export default AuctionDetailsOffers
