import styled from 'styled-components'
import { grid } from 'src/theme/grid'

export const InputWrapper = styled.div`
  display: inline-block;
  ${({ width }) => grid.breakpoints({ df: 12, m: width }, 12, '15px')};
  flex-direction: column;
`

export const InputsRowWrapper = styled.div`
  display: block;
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
`
