import React from 'react'
import styled, { css } from 'styled-components'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/styles/withStyles'
import { Select } from '@material-ui/core'
import { breakpoint } from 'src/theme/grid'
import PlaceholderUrl from 'src/assets/images/placeholder.png'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import ChatIconSvg from 'src/assets/icons/icon_chat.svg'
import RefreshIcon2 from 'src/assets/icons/refresh-2.svg'
import GavelIconUrl from 'src/assets/icons/gavel.svg'
import PrivateIconUrl from 'src/assets/icons/private.svg'
import ActionsMenu from 'src/features/stocks/components/ActionsMenu'

export const Reserved = styled.div`
  width: 35px;
  height: 21px;
  border-radius: 10.5px;
  background-color: #f89406;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
  line-height: 21px;
  ${breakpoint.m`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    font-weight: 600;
    background: none;
    border-radius: 0;
   `}
`

export const DateTime = styled.div`
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  font-size: 0.75rem;
  font-weight: 400;
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  min-width: 0;
  text-align: right;
  box-sizing: border-box;
  ${breakpoint.m`
    text-align: left;
    grid-column: auto / auto;
    grid-row: auto / auto;
   `}
`

export const DateTimeWithReservation = styled(DateTime)`
  grid-area: date;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: visible !important;
  ${breakpoint.m`
    display: block;
   `}
  ${Reserved}, svg {
    flex-shrink: 0;
    margin-left: 0.5rem;
    ${breakpoint.m`
      display: none;
   `}
  }
  ${ActionsMenu} {
    ${breakpoint.m`
      display: none;
   `}
  }
`

export const Location = styled.div`
  overflow: hidden;
  font-weight: 600;
  ${DateTime} {
    text-align: left;
  }
  &:first-of-type {
    grid-column: 1 / span 2;
    ${breakpoint.m`
    grid-column: auto / auto;
    grid-row: auto / auto;
   `}
  }
`

export const LocationFrom = styled(Location)`
  grid-area: locationFrom;
`

export const LocationTo = styled(Location)`
  grid-area: locationTo;
`

export const Shipowner = styled.div`
  grid-area: shipowner;
`

export const Company = styled.div`
  grid-area: company;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  ${breakpoint.m`
  font-size: inherit;
  `}
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    ${breakpoint.m`
     display: none;
  `}
  }
  > div {
    overflow: hidden;
  }
`

export const Container = styled.div`
  display: block;
  position: relative;
  grid-template-columns: auto;
  width: 100%;
  ${({ sticky, headerHeight }) =>
    sticky &&
    css`
      padding-top: ${headerHeight}px;
    `}
`

export const CompanyName = styled.div`
  grid-column: 3/4;
  ${breakpoint.m`
grid-column: auto`}
`

export const ContainerSemiTrailer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    ${breakpoint.m`
     display: none;
  `}
  }
  > div {
    overflow: hidden;
  }
  grid-column: 1/3;
  ${breakpoint.m`
grid-column: auto`}
`

export const TableRow = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 6px;
  grid-column: 1 / 9;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
    grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container shipowner company company';
  ${breakpoint.m`
     margin-bottom: 0;
     grid-template-rows: none;
     grid-template-columns: ${({ numberOfHeaders }) =>
       `minmax(80px, 1fr) repeat(2, 2fr) repeat(${numberOfHeaders -
         4}, 1fr)`} minmax(48px, 0.4fr);
     grid-template-areas:
    'index locationFrom locationTo container shipowner company date reservations'
  `}

  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 4px;
    ${breakpoint.m`
    padding: 10px;
    align-self: center;
  `}
    &:first-of-type {
      ${breakpoint.m`
      padding-left: 16px;
      `}
    }
    &:nth-last-child(-n + 1) {
      display: none;
      ${breakpoint.m`
      display: block;
      height: 1rem;
      `}
    }
  }
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  border-bottom: 1px solid ${({ theme }) =>
    theme.colors.ghost.alpha(0.3).hex()};
  &:hover {
    box-shadow: 0 2px 20px 0
      ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
    margin-left: -4px;
    position: relative;
  }
  ${({ isNewLive }) =>
    isNewLive &&
    css`
      border-left: 4px solid ${({ theme }) => theme.colors.apple.hex()};
    `}
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGreen.hex()};
      position: relative;
    `}
  ${({ isRemoved }) =>
    isRemoved &&
    css`
      background-color: ${({ theme }) => theme.colors.catskillWhite.hex()};
      color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
      pointer-events: none;
    `}
`

export const FreeCarriersTableRow = styled(TableRow)`
  grid-template-areas:
    'index index date date'
    'locationFrom locationFrom locationTo locationTo'
    'container container company company';
  ${breakpoint.m`
     grid-template-areas:
    'index locationFrom locationTo container company date reservations'
  `}
`

export const HeaderItem = styled.div`
  &&& {
    ${({ sort }) =>
      !sort &&
      css`
        cursor: default;
        &:hover {
          border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
        }
      `}
  }
`

export const TableHeader = styled(TableRow)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.alpha(0).hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  &:hover {
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
  }
  ${breakpoint.m`
     display: grid;
  `}
  > ${HeaderItem} {
    position: relative;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
    &:hover {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    }
  }
  ${({ sticky, headerWidth }) =>
    sticky &&
    css`
      position: fixed;
      top: 0;
      width: ${headerWidth}px;
    `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-bottom: 10px;
  margin-top: 22px;
`
export const BottomRow = styled(Row)`
  justify-content: center;
  margin-top: 10px;
  ${breakpoint.m`
        justify-content: flex-end;
  `}
`
export const Counter = styled.div`
  font-size: 0.85rem;
  margin-left: 0.1rem;
  & > span {
    font-weight: 600;
  }
`
export const StyledSelect = withStyles({
  root: {
    backgroundColor: '#fff',
    fontWeight: 400,
    fontSize: '.9rem',
    fontFamily: 'inherit'
  },
  select: {
    padding: '.5rem 2rem .5rem 1rem'
  }
})(Select)

export const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.white.hex()};
  .MuiOutlinedInput-input {
    padding: 0.5rem;
    width: 2rem;
  }
`
export const Dot = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 1px;
  margin-right: 6px;
`

export const EmptyOffers = styled.div`
  min-height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CompanyLogo = styled.div`
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  background-image: url(${({ url }) => url || PlaceholderUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  ${({ small }) =>
    small &&
    css`
      width: 24px;
      height: 24px;
    `}
  ${({ big }) =>
    big &&
    css`
      width: 160px;
      height: 160px;
    `}
`

export const TitleRow = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 22;
  padding: 0 1rem 0.75rem 1rem;
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    text-decoration: none;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`

export const ListWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white.hex()};
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  height: ${({ theme }) => `calc(100vh - ${theme.dimensions.topBarHeight})`};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.6rem 0 2.6rem 0;
  ${breakpoint.m`
    padding: 0 1.5rem 2.6rem;
  `}
`

export const Arrow = styled.span`
  float: right;
  margin-right: -8px;
  color: ${({ theme }) => theme.colors.apple.hex()};
  ${breakpoint.m`
      display: none;
  `}
`

export const Ellipsis = ({ children, trigger }) => (
  <EllipsisTooltip trigger={trigger}>{children}</EllipsisTooltip>
)

export const CreatedDate = styled.div`
  background-color: #f9f9f9;
  flex: 1;
  && {
    padding: 0 0 120px 0;
    ${breakpoint.m`
      padding: 0 0 1rem 0;
  `}
  }
  & > div {
    padding: 0.75rem 2rem 0.75rem 1.5rem;
  }

  & p {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  }
`
export const Separator = styled.hr`
  margin: 0.75rem 2rem 0 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
`

export const ChatIcon = styled(ChatIconSvg)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
`

const OffersNumber = styled.span`
  display: flex;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  color: white;
  margin-right: 0.4rem;
  align-items: center;
  justify-content: center;
`

const InfoBoxComponent = ({ className, number, children }) => (
  <div className={className}>
    <OffersNumber>{number}</OffersNumber>
    {children}
  </div>
)

export const InfoBox = styled(InfoBoxComponent)`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  border-radius: 20px;
  box-shadow: 0 2px 20px 0 rgba(36, 45, 63, 0.3);
  height: 40px;
  color: white;
  font-weight: 600;
  font-size: 0.75rem;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0 1rem 0 0.8rem;
`
export const OffersRemoveCloseIcon = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  right: 20px;
  box-sizing: border-box;
  transform: translate(-50%);
  justify-content: center;
  z-index: 100;
  background-color: white;
  border-radius: 4px;
  padding: 0.4rem 1.2rem;
  height: 100%;
  width: 100%;
`

export const OfferRemovedInformation = styled.div`
  display: flex;
  margin-top: 100px;
  margin-bottom: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  justify-content: center;
  z-index: 100;
  background-color: white;
  border-radius: 4px;
`
export const OfferRemovedInformationText = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  font-size: 16px;
  box-sizing: border-box;
  justify-content: center;
  z-index: 100;
  background-color: white;
  border-radius: 4px;
  align-items: center;
  text-align: center;
`
export const OfferRemovedInformationTextDetails = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  box-sizing: border-box;
  justify-content: center;
  z-index: 100;
  background-color: white;
  border-radius: 4px;
  padding: 0.4rem 1.2rem;
  text-align: center;
`

export const RefreshIcon = styled(RefreshIcon2)`
  width: 25px;
  height: 25px;
  display: inline-block;
  cursor: pointer;
  opacity: 0.7;
  transition: transform 200ms;
  &:hover {
    opacity: 1;
    transform: rotate(-30deg);
  }
`

export const ClearAllFilters = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-size: 0.85rem;
  font-weight: 400;
  cursor: pointer;
  margin-right: 0.8rem;
`

export const ExchangeTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.8rem;
  padding: 0 0.5rem;
  ${breakpoint.m`
       padding: 0;
  `}
  ${RefreshIcon} {
    margin: 0 0.8rem;
  }
`

export const GavelIconContainer = styled.div`
  margin-left: 4px;
`

export const GavelIcon = styled(GavelIconUrl)`
  display: inline;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 1;
`

export const PrivateIcon = styled(PrivateIconUrl)`
  display: inline;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 1;
`
