import React, { useEffect, useState, useRef } from 'react'
import CustomIcon from 'src/components/CustomIcon'

import styled, { css } from 'styled-components'
import { connectAvatar } from 'src/features/account/duck/connectors'
import { API_STATES } from 'src/ducks/consts'
import CircularProgress from '@material-ui/core/CircularProgress'

const UploadAvatar = ({ uploadUserAvatar, avatarState }) => {
  const [file, setFile] = useState(null)
  const inputRef = useRef(null)

  useEffect(() => {
    file && uploadUserAvatar(file)
  }, [file])

  useEffect(() => {
    avatarState === API_STATES.DONE && setFile(null)
    if (avatarState === API_STATES.ERROR) inputRef.current.value = ''
  }, [avatarState, inputRef.current])

  const isLoading = avatarState === API_STATES.IN_PROGRESS

  return (
    <>
      <FileInput
        ref={inputRef}
        disabled={isLoading}
        type='file'
        accept='image/jpg, image/png, image/jpeg, image/gif'
        name='file'
        id='file'
        onChange={e => setFile(e.target.files[0])}
      />
      <EditIconContainer htmlFor='file' disabled={isLoading}>
        {!isLoading && <CustomIcon name='edit' size={24} />}
        {isLoading && <Loader />}
      </EditIconContainer>
    </>
  )
}

export default connectAvatar(UploadAvatar)

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const Loader = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
  vertical-align: middle;
  color: white !important;
`
const EditIconContainer = styled.label`
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  ${CustomIcon} {
    height: 24px;
  }
  position: absolute;
  bottom: 0;
  left: calc(50% + 30px);
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
