import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MUIVisibility from '@material-ui/icons/Visibility'
import MUIVisibilityOff from '@material-ui/icons/VisibilityOff'
import InputMask from 'react-input-mask'
import MUISchedule from '@material-ui/icons/Schedule'

const TextInput = ({
  label,
  name,
  id,
  value,
  onChange,
  validate,
  pristine,
  helperText,
  variant,
  type,
  mask,
  required,
  placeholder,
  unit,
  disabled,
  className,
  calendarTime
}) => {
  const classes = useStyles()
  const [passwordVisible, _setPasswordVisibility] = useState(false)
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })

  const handleChange = e => onChange(name, e.target.value)

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(name, value, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [value, inputPristine, pristine])

  const adornmentIcon = () => {
    switch (type) {
      case 'password':
        return (
          <InputAdornment position='end'>
            <IconButton
              onClick={() => _setPasswordVisibility(!passwordVisible)}
            >
              {passwordVisible ? <MUIVisibility /> : <MUIVisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      case 'tel':
        return (
          calendarTime && (
            <InputAdornment position='end'>
              <TimeIcon />
            </InputAdornment>
          )
        )
      case 'unit':
        return (
          <InputAdornment position='end'>
            {' '}
            <UnitIcon>{unit}</UnitIcon>
          </InputAdornment>
        )
      default:
        return null
    }
  }

  return (
    <div className={className}>
      <Label required={required}>{label}</Label>
      {type === 'time' ? (
        <TextField
          className={classes.textField}
          id={id}
          variant={variant || 'outlined'}
          name={name}
          error={!valid}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={() => _setPristine(false)}
          type={type}
          InputLabelProps={{
            classes: {
              root: classes.label
            }
          }}
          InputProps={{
            classes: {
              root: classes.root,
              input: classes.input,
              inputMultiline: classes.inputMultiline,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline
            },
            endAdornment: adornmentIcon()
          }}
        />
      ) : (
        <InputMask
          onChange={handleChange}
          onBlur={() => _setPristine(false)}
          mask={mask}
          value={value}
          disabled={disabled}
        >
          {inputProps => (
            <TextField
              className={classes.textField}
              id={id}
              variant={variant || 'outlined'}
              name={name}
              error={!valid}
              placeholder={placeholder}
              {...inputProps}
              type={!type || passwordVisible ? 'text' : type}
              InputLabelProps={{
                classes: {
                  root: classes.label
                }
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.input,
                  inputMultiline: classes.inputMultiline,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline
                },
                endAdornment: adornmentIcon()
              }}
            />
          )}
        </InputMask>
      )}
      <HelperText valid={valid}>{valid ? helperText : error}</HelperText>
    </div>
  )
}

TextInput.defaultProps = {
  label: '',
  helperText: '',
  id: '',
  name: '',
  value: '',
  pristine: true,
  multiline: false,
  rows: 4,
  handleChange: () => {},
  validate: () => true
}

export default styled(TextInput)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      div {
        opacity: 0.7;
      }
    `}
`

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%'
  },
  root: {
    backgroundColor: 'white',
    '&$focused': {
      boxShadow: '0 2px 10px 0 rgba(19, 162, 191, 0.25)'
    },
    '& $notchedOutline': {
      borderWidth: '1px',
      borderColor: '#c6cbd4',
      borderRadius: '2px'
    },
    '&:hover $notchedOutline': {},
    '&$focused $notchedOutline': {
      borderWidth: '1px',
      borderColor: '#13a2bf'
    }
  },
  input: {
    padding: '10px 10px 10px 12px',
    height: '20px',
    color: '#585a59'
  },
  inputMultiline: {
    height: 'auto',
    resize: 'none'
  },
  label: {
    color: '#585a59',
    opacity: '0.7'
  },
  focused: {},
  notchedOutline: {},
  underline: {},
  helperText: {
    color: '#373e4c',
    fontSize: '0.9rem',
    fontWeight: 500,
    lineHeight: 1.33,
    opacity: 0.87,
    marginBottom: '1rem'
  }
}))

const Label = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`

const UnitIcon = styled.span`
  display: inline-block;
  position: relative;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-size: 0.875rem;
  padding-left: 0.6rem;
  &:before {
    content: '';
    width: 1px;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.ghost.hex()};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

const TimeIcon = styled(MUISchedule)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  font-size: 1.4rem;
`

const HelperText = styled.span`
  display: inline-block;
  width: 100%;
  margin: 8px 6px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1em;
  color: ${({ theme, valid }) =>
    valid ? theme.colors.ghost.hex() : theme.colors.error.hex()};
`
