import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Dialog from '@material-ui/core/Dialog'
import { breakpoint } from 'src/theme/grid'
import {
  CancelButton,
  RemoveOfferPromptButton
} from 'src/components/atoms/Buttons'
import OfferInfoBox from 'src/components/OfferInfoBox'
import XIcon from 'src/assets/icons/close-x.svg'
import TextInput from 'src/components/atoms/TextInput'
import { MAX_CHARACTERS_INPUT_REASON } from 'src/ducks/consts'

const ConfirmDialog = ({
  open,
  setOpen,
  offer,
  title,
  message,
  inputTitle,
  inputPlaceholder,
  onConfirm,
  onOfferNumberClick,
  withReason,
  offerorCompany,
  confirmButton,
  confirmButtonTitle
}) => {
  const [reason, setReason] = useState('')
  const active = !withReason || reason.length > 2
  const ConfirmButton = confirmButton || RemoveOfferPromptButton

  const onChangeInputValue = (_, value) => {
    value.length <= MAX_CHARACTERS_INPUT_REASON && setReason(value)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <CloseIcon onClick={() => setOpen(false)} />
      <Container>
        <DialogTitle>
          <DialogTitleText>{title}</DialogTitleText>
        </DialogTitle>
        <DialogContent>{message}</DialogContent>
        {offer && (
          <OfferInfoBox
            offer={offer}
            onOfferNumberClick={onOfferNumberClick}
            offerorCompany={offerorCompany}
          />
        )}
        {withReason && (
          <TextInput
            label={`${inputTitle} (${reason.length}/${MAX_CHARACTERS_INPUT_REASON})`}
            id='reason'
            name='reason'
            placeholder={inputPlaceholder}
            value={reason}
            onChange={onChangeInputValue}
            multiline
            validate={() => true}
          />
        )}
        <DialogActions withReason={withReason}>
          <CancelButton
            active
            onClick={() => {
              setOpen(false)
            }}
          >
            <FormattedMessage id='common.notNow' />
          </CancelButton>
          <ConfirmButton
            title={confirmButtonTitle}
            active={active}
            onClick={() => {
              onConfirm(reason)
              setOpen(false)
              setReason('')
            }}
          />
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default ConfirmDialog

export const Container = styled.div`
  padding: 1.5rem 1rem 0rem 1rem;
  & > div {
    margin: 0.7rem 0;
  }

  ${breakpoint.m`
        padding: 1.5rem 2rem 0rem 2rem;
    `}
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const DialogTitleText = styled.h3`
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  color: ${({ theme }) => theme.colors.black.hex()};
`
export const DialogContent = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;
`
const DialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ withReason }) =>
    withReason &&
    css`
      && {
        margin: 0 0 0.7rem 0;
      }
    `}
`

export const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 30px;
  height: 30px;
`
