import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isNil, head } from 'ramda'
import {
  isFalsy,
  isUndefined,
  isEmptyString,
  isEmptyArray
} from 'ramda-adjunct'
import { useIntl } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'

import useClickOutside from 'src/hooks/useClickOutside'
import {
  FilterBox,
  FilterDropdown,
  SearchInput,
  SearchInputWrapper,
  SelectOptionsWrapper,
  SelectRow,
  OptionsSectionTitle,
  Separator
} from './components/atoms'

const SelectBox = ({
  className,
  id,
  name,
  value,
  label,
  options,
  onChange,
  placeholder,
  alignRight,
  removeSearchInput = false,
  removeSelectedOptions = false,
  type = 'checkbox'
}) => {
  const intl = useIntl()
  const [filterOpened, setFilterOpened] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])
  const toggleDropdown = () => setFilterOpened(!filterOpened)
  const componentRef = useClickOutside(() => setFilterOpened(false))
  const emptyText = isEmptyString(searchText) || searchText.length < 2

  useEffect(() => {
    if (filterOpened) {
      setSelectedOptions(parseValueToOptions())
    }
  }, [filterOpened])

  const isMultiple = () => Array.isArray(value)

  const generateFilterName = () => {
    const selectedValue = isMultiple() ? head(value) : value
    if (isNil(selectedValue) || isUndefined(selectedValue)) {
      return label
    }

    const selectedOption = head(
      options.filter(opt => opt.value === selectedValue)
    )
    return !isFalsy(selectedOption) ? selectedOption.label : label
  }

  const handleChange = (optionValue, isThisValueSelected) => {
    const singleValue = () => (isThisValueSelected ? null : optionValue)
    const multipleValue = () =>
      isThisValueSelected
        ? value.filter(v => v !== optionValue)
        : [...value, optionValue]
    onChange(name, isMultiple() ? multipleValue() : singleValue())
  }

  const handleClear = e => {
    !isFalsy(e) && e.stopPropagation()
    setSearchText('')
    onChange(name, isMultiple() ? [] : null)
  }

  const filteredOptions = () =>
    searchText === ''
      ? options
      : options.filter(o =>
          o.label.toLowerCase().includes(searchText.toLowerCase())
        )

  const isSelected = optionValue => {
    const singleSelected = () => value === optionValue
    const multipleSelected = () => value.includes(optionValue)

    return isMultiple() ? multipleSelected() : singleSelected()
  }

  const parseValueToOptions = () => {
    const values = !isMultiple() ? [value] : value
    return values.map(v => head(options.filter(option => option.value === v)))
  }

  return (
    <div className={className} ref={componentRef}>
      <FilterBoxStyled
        onClick={toggleDropdown}
        opened={filterOpened}
        hasValue={isMultiple() ? value.length > 0 : !isNil(value)}
        id={`filter-${id}`}
        selectedNumber={isMultiple() ? value.length : null}
        onClear={handleClear}
      >
        {generateFilterName()}
      </FilterBoxStyled>
      {filterOpened && (
        <FilterDropdown
          alignRight={alignRight}
          filterName={label}
          onClose={() => setFilterOpened(false)}
          onClear={handleClear}
          id={`select-${id}`}
          clearButtonText={intl.formatMessage({ id: 'offersFilters.clear' })}
          confirmButtonText={intl.formatMessage({
            id: 'offersFilters.confirm'
          })}
        >
          {!removeSearchInput && (
            <SearchInputWrapper>
              <SearchInput
                name={name}
                id={`input-${id}`}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                placeholder={placeholder}
              />
            </SearchInputWrapper>
          )}
          <SelectOptionsWrapper>
            {!removeSelectedOptions &&
              emptyText &&
              !isEmptyArray(selectedOptions) && (
                <>
                  <OptionsSectionTitle>
                    {intl.formatMessage({ id: 'offersFilters.chosenOptions' })}
                  </OptionsSectionTitle>
                  {selectedOptions.map((option, index) => {
                    const selected = isSelected(option.value)
                    return (
                      <SelectRow
                        key={index}
                        type={type}
                        selected={selected}
                        name={option.label}
                        onClick={() => handleChange(option.value, selected)}
                      />
                    )
                  })}
                  <Separator />
                </>
              )}

            {filteredOptions().map((option, index) => {
              const selected = isSelected(option.value)
              return (
                <SelectRow
                  key={index}
                  type={type}
                  selected={selected}
                  name={option.label}
                  onClick={() => handleChange(option.value, selected)}
                />
              )
            })}
          </SelectOptionsWrapper>
        </FilterDropdown>
      )}
    </div>
  )
}

SelectBox.defaultProps = {
  id: '',
  label: '',
  name: '',
  value: '',
  placeholder: '',
  options: [],
  onChange: () => {}
}

export default styled(SelectBox)`
  display: flex;
  position: relative;
  ${FilterDropdown} {
    ${breakpoint.m`
      width: ${({ width }) => width || '220px'};
      height: 400px
    `}
  }
`

const FilterBoxStyled = styled(FilterBox)`
  width: 100%;
  ${breakpoint.m`
    width: 180px;
  `}
`
