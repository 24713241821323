import React, { useState } from 'react'
import { isNil } from 'ramda'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { Title } from 'src/features/stocks/components/typography'
import Timeline from 'src/features/stocks/components/OfferDetails/Timeline'
import Shade from 'src/components/Shade'
import Contact from 'src/features/stocks/components/OfferDetails/Contact'
import {
  ContainerInfo,
  ContractDates,
  CreatedDateOfferDetails,
  CustomsClearance,
  DrawerTitle,
  MiddleContainer,
  Notes,
  OfferBelongsToCurrentUserInfo,
  OfferRemovedInfo,
  Type
} from 'src/features/stocks/components/OfferDetails/atoms'
import ContractPrice from 'src/components/atoms/ContractPrice'
import ReserveContract from 'src/features/contracts/components/ReserveContract'
import { ButtonsForCustomerContract } from 'src/features/contracts/components/atoms'
import ContractIcon from 'src/assets/icons/description.svg'

const OfferDetails = ({
  className,
  setDrawerState,
  offerDetails,
  timelineData,
  checkIfTheOfferBelongsToTheCurrentCompany,
  checkIfTheOfferBelongsToTheCurrentUser,
  updateOffer,
  reserveOffer,
  stock,
  setConversationOpened
}) => {
  const intl = useIntl()
  const offerRemoved = !isNil(offerDetails.deletedAt)
  const hasNotes = offerDetails.notes
  const offerBelongsToCurrentUserCompany = checkIfTheOfferBelongsToTheCurrentCompany(
    offerDetails.issuer.companyId
  )
  const offerBelongsToCurrentUser = checkIfTheOfferBelongsToTheCurrentUser(
    offerDetails.handler.id
  )
  const [bidDialog, setBidDialog] = useState(false)

  const changeHandler = newHandlerId => {
    updateOffer(offerDetails.id, { handler_id: newHandlerId })
  }

  return (
    <div className={className}>
      {(stock.isLoading('details') || offerRemoved) && <Shade />}
      <DrawerTitle
        offerDetails={offerDetails}
        setDrawerState={setDrawerState}
      />
      {offerRemoved && <OfferRemovedInfo />}
      {!offerRemoved && offerDetails.id && (
        <MiddleContainer>
          {offerBelongsToCurrentUserCompany && (
            <OfferBelongsToCurrentUserInfo />
          )}
          <div>
            <ContractPrice
              deadline={offerDetails.finishedAt || offerDetails.validUntilDate}
              price={offerDetails.price}
              belongsToCurrentCompany={offerBelongsToCurrentUserCompany}
            />
          </div>
          <Type offerDetails={offerDetails} />
          <ContractDates offerDetails={offerDetails} icon={ContractIcon} />
          {offerDetails.transportations.map((transportation, index) => (
            <React.Fragment key={transportation.id}>
              <TransportationTitle>
                {`${index + 1}. ${intl.formatMessage({
                  id: 'offerDetails.line'
                })}`}
                <span>{`x${transportation.numberOfContainers}`}</span>
              </TransportationTitle>
              {timelineData && <Timeline data={timelineData[index]} />}
              {transportation.customsClearanceLocationId && (
                <CustomsClearance offerDetails={transportation} />
              )}
              {transportation.containerId && (
                <ContainerInfo offerDetails={transportation} />
              )}
            </React.Fragment>
          ))}
          {hasNotes && <Notes offerDetails={offerDetails} />}
          <Contact
            issuer={offerDetails.issuer}
            handler={offerDetails.handler}
            changeHandler={changeHandler}
            offerBelongsToCurrentUserCompany={offerBelongsToCurrentUserCompany}
            offerBelongsToCurrentUser={offerBelongsToCurrentUser}
            isUpdatable={offerDetails.isUpdatable}
            handleConversationStart={() => {
              setConversationOpened({
                receiverId: offerDetails.handlerId,
                conversationOpened: true
              })
            }}
          />
          <CreatedDateOfferDetails offerDetails={offerDetails} />
        </MiddleContainer>
      )}
      {!offerBelongsToCurrentUserCompany && !offerRemoved && (
        <ButtonsForCustomerContract
          offerDetails={offerDetails}
          setBidDialog={setBidDialog}
          stock={stock}
          setConversationOpened={setConversationOpened}
        />
      )}
      <ReserveContract
        open={bidDialog}
        setOpen={setBidDialog}
        offer={offerDetails}
        onClick={price => reserveOffer(offerDetails.id, { price })}
      />
    </div>
  )
}

export default styled(OfferDetails)`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 1.6rem 0 80px 0;
  ${Title} {
    padding-bottom: 4px;
  }
`
export const TransportationTitle = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  opacity: 0.7;
  border-bottom: 2px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  margin: 0.5rem 0;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  & span {
    font-size: 0.875rem;
    line-height: 0.86;
    color: ${({ theme }) => theme.colors.apple};
    font-weight: 600;
  }
`
