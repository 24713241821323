import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import Tabs from 'src/components/Tabs'
import { Column, Row, SectionBox } from 'src/features/account/components/atoms'
import { Page } from 'src/components/Wrappers'
import Alert from 'src/components/Alert'
import { breakpoint } from 'src/theme/grid'
import InvoicesReceived from 'src/features/invoices/components/InvoicesReceived'
import InvoicesIssued from 'src/features/invoices/components/InvoicesIssued'
import { connectInvoicesList } from 'src/features/invoices/duck/connectors'
import { INVOICES_TABS_ARRAY } from 'src/features/invoices/duck/consts'

const Invoices = ({
  getInvoicesListReceived,
  getInvoicesListIssued,
  className
}) => {
  useEffect(() => {
    getInvoicesListIssued()
    getInvoicesListReceived()
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              <FormattedMessage id='invoices.invoices' />
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Tabs list={INVOICES_TABS_ARRAY}>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <InvoicesIssued />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <InvoicesReceived />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = connectInvoicesList(Invoices)

export default styled(ConnectedComponent)`
  ${Tabs} {
    display: block;
    margin-bottom: 0;
    & > div {
      padding: 0.75rem 1.2rem 0 1.2rem;
    }
    & span {
      font-weight: 400;
    }
  }

  ${Alert} {
    width: auto;
    margin: 1rem 0 0 0;
    ${breakpoint.m`
    margin: initial;;
    width: inherit;
  `}
  }
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
