import styled from 'styled-components'

export const NotificationsBox = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0px 6px 0px rgba(36, 45, 63, 0.16);
  background-color: white;
  margin-top: 0.8rem;
  margin-bottom: 60px;
  div:last-of-type {
    &:after {
      display: none;
    }
  }
`
