import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'
import { Ellipsis, Location } from 'src/features/stocks/components/atoms'
import { DATE_FORMAT } from 'src/ducks/consts'
import { breakpoint } from 'src/theme/grid'
import {
  ContractLocations,
  EllipsisContainer
} from 'src/features/contracts/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import Chevron from 'src/assets/icons/down.svg'
import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import ActionsMenu, {
  DropdownItem
} from 'src/features/stocks/components/ActionsMenu'
import PhoneIcon from 'src/assets/icons/phone.svg'
import ChatIcon from 'src/assets/icons/icon_chat.svg'
import { connectContracts } from 'src/features/contracts/duck/connectors'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import CompanyIconUrl from 'src/assets/icons/work-case.svg'
import ContainerIconUrl from 'src/assets/icons/container.svg'
import ShipIconUrl from 'src/assets/icons/ship.svg'
import ContractIcon from 'src/assets/icons/description.svg'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import DeleteIcon from 'src/assets/icons/delete.svg'
import { Status } from 'src/features/myOffers/components/StatusIcon'

const MobileTableRow = ({
  offer,
  open,
  setOpen,
  isNew,
  className,
  showOffer,
  checkIfTheOfferBelongsToTheCurrentUser,
  setConversationOpened,
  onClick,
  handleOffersCounterClick,
  openReservations,
  showReservations,
  hideActionsMenu,
  setDrawerState,
  deleteOffer
}) => {
  const isFinished = offer.finishedAt
  const isDeletable = offer.isDeletable

  const handleOfferNumberClick = id => {
    showOffer(id)
    setDrawerState(true)
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS}`)
  }
  return (
    <>
      <div className={className} onClick={onClick}>
        <TopRow>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isFinished && <Status status={offer.outcome} />}
            <OfferLink onClick={() => handleOfferNumberClick(offer.id)}>
              {offer.referenceNumber}
            </OfferLink>
          </div>
          <DateAndActionsContainer>
            <CreatedAtField isNew={isNew} addedAt={offer.addedToListingAt} />
            {!hideActionsMenu && (
              <ActionsMenu
                onClick={() => {
                  showOffer(offer.id)
                }}
              >
                <DropdownItem
                  onClick={e => e.stopPropagation()}
                  disabled={!offer.handler.phoneNumber}
                >
                  <PhoneIcon />
                  <a href={`tel:${offer.handler.phoneNumber}`}>
                    <FormattedMessage id='common.call' />
                  </a>
                </DropdownItem>
                <DropdownItem
                  disabled={checkIfTheOfferBelongsToTheCurrentUser(
                    offer.handlerId
                  )}
                  onClick={e => {
                    e.stopPropagation()
                    setConversationOpened({
                      receiverId: offer.handlerId,
                      conversationOpened: true
                    })
                  }}
                >
                  <ChatIcon />
                  <FormattedMessage id='common.openChat' />
                </DropdownItem>
              </ActionsMenu>
            )}
            {isDeletable && (
              <ActionsMenu>
                <DropdownItem
                  disabled={!isDeletable}
                  onClick={e => {
                    e.stopPropagation()
                    deleteOffer(offer.id)
                  }}
                >
                  <DeleteIcon />
                  <FormattedMessage id='common.remove' />
                </DropdownItem>
              </ActionsMenu>
            )}
          </DateAndActionsContainer>
        </TopRow>
        <DetailsRow>
          <div>
            <EllipsisTooltip>
              <span>
                <FormattedMessage
                  id={`common.${offer.type}`}
                  defaultMessage='-'
                />
              </span>
            </EllipsisTooltip>
          </div>
          <div>
            <EllipsisTooltip>
              <span>
                {moment(offer.startDate).format(DATE_FORMAT)}
                {' - '}
                {moment(offer.endDate).format(DATE_FORMAT)}
              </span>
            </EllipsisTooltip>
          </div>
          <div>
            <EllipsisTooltip>
              <span>
                <CompanyIcon />
                {offer.issuer.company.name}
              </span>
            </EllipsisTooltip>
          </div>
        </DetailsRow>
        <ContractLocations>
          {offer.transportations.map((line, index) => (
            <React.Fragment key={`${line.offerId}-${index}`}>
              {(index === 0 || open) && (
                <Location>
                  <LocationRow>
                    <EllipsisContainer>
                      <Ellipsis>{line.originLocation.name}</Ellipsis>
                    </EllipsisContainer>
                    <Arrow>
                      <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
                    </Arrow>
                    <EllipsisContainer>
                      <Ellipsis>{line.destinationLocation.name}</Ellipsis>
                    </EllipsisContainer>
                    {offer.transportations.length > 1 && index === 0 && (
                      <MoreContractsCounter
                        onClick={e => {
                          e.stopPropagation()
                          setOpen(!open)
                        }}
                      >
                        {open ? (
                          <FormattedMessage id='common.less' />
                        ) : (
                          `+${offer.transportations.length - 1}`
                        )}
                        <Chevron
                          style={{
                            transform: `rotate(${open ? '180deg' : '0'})`
                          }}
                        />
                      </MoreContractsCounter>
                    )}
                  </LocationRow>
                  <LineDetails>
                    <div>
                      <ContainerIcon />
                      {line.container.name}
                    </div>
                    <div>
                      <ShipIcon />
                      {line.shipowner.name}
                    </div>
                    <div>
                      <span>
                        {'  x'}
                        {line.numberOfContainers}
                      </span>
                    </div>
                  </LineDetails>
                </Location>
              )}
            </React.Fragment>
          ))}
        </ContractLocations>
      </div>
      {showReservations && (
        <ShowReservationsPanelMobile
          onClick={handleOffersCounterClick}
          isOpen={openReservations}
        >
          <ContractIconSmall
            style={{
              color: !offer.acceptedReservation.id
                ? 'inherit'
                : `${({ theme }) => theme.colors.apple.hex()}`
            }}
          />
          {!isFinished && (
            <b>
              {moment(offer.validUntilDate).fromNow(true)}
              <FormattedMessage id='offerDetails.toEnd' />{' '}
            </b>
          )}
          {isFinished && offer.acceptedReservation.id && (
            <b>{offer.acceptedReservation.price.formatted}</b>
          )}
          {isFinished && !offer.acceptedReservation.id && (
            <b>
              <FormattedMessage id='common.missing' />
            </b>
          )}
          &nbsp;
          <span>
            {' ('}
            {offer.reservationsCount}{' '}
            <FormattedMessage
              id='offers.offersFound'
              values={{ count: offer.reservationsCount }}
            />
            {') '}
          </span>
          {offer.reservationsCount > 0 && (
            <Chevron
              style={{
                transform: `rotate(${openReservations ? '180deg' : '0'})`
              }}
            />
          )}
        </ShowReservationsPanelMobile>
      )}
    </>
  )
}

const Arrow = styled.span`
  color: ${({ theme }) => theme.colors.apple.hex()};
`
const LocationRow = styled.div`
  display: flex;
  align-items: center;
`
const MoreContractsCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  cursor: pointer;
  font-size: 0.75rem;
`

const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const TopRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.75rem;
  font-weight: normal;
  & > div {
    overflow: hidden;
    padding: 0 0.5rem;
    border-right: 1px solid
      ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border: none;
    }
  }
`

const DateAndActionsContainer = styled.div`
  display: flex;
`
const CompanyIcon = styled(CompanyIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
`

const ContainerIcon = styled(ContainerIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -5px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const ShipIcon = styled(ShipIconUrl)`
  opacity: 0.7;
  display: inline-flex;
  margin-bottom: -3px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`

const LineDetails = styled.div`
  font-size: 0.75rem;
  font-weight: normal;
  display: flex;
  align-items: center;
  margin-top: -2px;
  div {
    padding: 0 6px;
    &:first-child {
      padding-left: 0;
    }
  }
  span {
    color: ${({ theme }) => theme.colors.apple.hex()};
  }
`

const StyledComponent = styled(MobileTableRow)`
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(36, 45, 63, 0.15);
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  & > div {
    margin-top: 0.4rem;
    &:first-child {
      margin-top: 0;
    }
  }
  ${ContractLocations} {
    font-size: 0.875rem;
    padding-top: 0;
    ${MoreContractsCounter} {
      margin-left: auto;
    }
  }
  ${breakpoint.m`
      display: none;
    `}
`

const ContractIconSmall = styled(ContractIcon)`
  width: 18px;
  height: 18px;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
`

const ShowReservationsPanelMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  padding: 4px 0;
  min-height: 26px;
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
  box-shadow: 0 6px 6px 0 rgba(36, 45, 63, 0.15);
  z-index: 0;
  & > svg {
    margin-right: 3px;
  }
  span {
    opacity: 0.7;
  }
  ${breakpoint.m`
      display: none
  `}
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom: none;
      position: relative;
      box-shadow: none;
      z-index: 1;
      border-bottom: none;
    `}
`

export default connectContracts(StyledComponent)
