import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import {
  CONTRACTS_HEADERS_MY_OFFERS_FINISHED_NAMES,
  CONTRACTS_HEADERS_MY_OFFERS_FINISHED_WIDTHS
} from 'src/features/contracts/duck/consts'
import {
  DateTime,
  Ellipsis,
  Location
} from 'src/features/stocks/components/atoms'
import moment from 'moment'
import { DATE_FORMAT, DATE_LONG_FORMAT_WITH_WEEKDAY } from 'src/ducks/consts'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import Chevron from 'src/assets/icons/down.svg'
import { CreatedAtField } from 'src/features/myOffers/components/CreatedAtField'
import {
  ContractLocations,
  ContractOfferDetails,
  EllipsisContainer,
  ReservationsFieldContract,
  TableRow
} from 'src/features/contracts/components/atoms'
import styled from 'styled-components'
import { isNotOlderThanMinutes } from 'src/utils/helpers'
import { HandlerField } from 'src/features/myOffers/components/HandlerField'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { createOfferDetailsLink } from 'src/features/dashboard/duck/consts'
import { connectMyContractsOffersFinished } from 'src/features/contracts/duck/connectors'
import ReservationDetailsOffers from 'src/features/contracts/components/ReservationDetailsMyOffers'
import ContractIcon from 'src/assets/icons/description.svg'
import { DateTitle, Description } from 'src/components/atoms/AuctionPrice'
import { Status } from 'src/features/myOffers/components/StatusIcon'
import MobileTableRow from 'src/features/contracts/components/MobileTableRow'

const OfferRow = ({
  offer,
  currentUserId,
  buttonsState,
  acceptReservation,
  setConversationOpened,
  showOffersReservations
}) => {
  const isNew = isNotOlderThanMinutes(offer.addedToListingAt, 1)
  const [openContractDetails, setOpenContractDetails] = useState(false)
  const [openReservations, setOpenReservations] = useState(false)
  const permissions = useContext(PermissionsContext)
  const hasPermissionToSeeCompanyConversations = hasPermissionTo(
    PERMISSIONS.CHAT_COMPANY_CONVERSATIONS_SEE,
    permissions
  )
  const belongsToCurrentUser = offer.handler.id === currentUserId
  const chatAvailable =
    belongsToCurrentUser || hasPermissionToSeeCompanyConversations
  const hasReservations = offer.reservationsCount > 0

  const handleOffersCounterClick = () => {
    setOpenReservations(!openReservations)
  }

  useEffect(() => {
    openReservations &&
      hasReservations &&
      offer.reservations.length === 0 &&
      showOffersReservations(offer.id)
  }, [openReservations, hasReservations, offer])

  return (
    <>
      <TableRow
        widths={CONTRACTS_HEADERS_MY_OFFERS_FINISHED_WIDTHS}
        columnNames={CONTRACTS_HEADERS_MY_OFFERS_FINISHED_NAMES}
        isOpen={openReservations}
        open={openContractDetails}
      >
        <div>
          <Status status={offer.outcome} />
        </div>
        <div>
          <OfferLink to={createOfferDetailsLink(offer.referenceNumber)}>
            {offer.referenceNumber}
          </OfferLink>
        </div>
        <ReservationsFieldContract
          clickable={true}
          handleClick={handleOffersCounterClick}
          reservationsCount={offer.reservationsCount}
          price={offer.acceptedReservation.price}
          open={openReservations}
          isFinished
        />
        <div>
          <Ellipsis>
            <FormattedMessage id={`common.${offer.type}`} defaultMessage='-' />
          </Ellipsis>
        </div>
        <DateTime>
          <Ellipsis>{moment(offer.startDate).format(DATE_FORMAT)}</Ellipsis>
        </DateTime>
        <DateTime>
          <Ellipsis>{moment(offer.endDate).format(DATE_FORMAT)}</Ellipsis>
        </DateTime>
        <ContractLocations>
          {offer.transportations.map((line, index) => (
            <React.Fragment key={`${line.offerId}-${index}`}>
              {(index === 0 || openContractDetails) && (
                <Location>
                  <LocationRow>
                    <EllipsisContainer>
                      <Ellipsis>{`${line.originLocation.countryCode}, ${line.originLocation.name}`}</Ellipsis>
                    </EllipsisContainer>
                    <Arrow>
                      <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
                    </Arrow>
                    <EllipsisContainer>
                      <Ellipsis>{`${line.destinationLocation.countryCode}, ${line.destinationLocation.name}`}</Ellipsis>
                    </EllipsisContainer>
                    {offer.transportations.length > 1 && index === 0 && (
                      <MoreContractsCounter
                        onClick={e => {
                          e.stopPropagation()
                          setOpenContractDetails(!openContractDetails)
                        }}
                      >
                        {openContractDetails ? (
                          <FormattedMessage id='common.less' />
                        ) : (
                          `+${offer.transportations.length - 1}`
                        )}
                        <Chevron
                          style={{
                            transform: `rotate(${
                              openContractDetails ? '180deg' : '0'
                            })`
                          }}
                        />
                      </MoreContractsCounter>
                    )}
                  </LocationRow>
                  <DateTime>
                    {line.container.name}
                    {', '}
                    {line.shipowner.name}
                    {', '}
                    {'x'}
                    {line.numberOfContainers}
                  </DateTime>
                </Location>
              )}
            </React.Fragment>
          ))}
        </ContractLocations>
        <HandlerField handler={offer.handler}>
          <Ellipsis>{offer.issuer.company.name}</Ellipsis>
        </HandlerField>
        <CreatedAtField isNew={isNew} addedAt={offer.addedToListingAt} />
      </TableRow>
      <MobileTableRow
        offer={offer}
        open={openContractDetails}
        setOpen={setOpenContractDetails}
        openReservations={openReservations}
        isNew={isNew}
        handleOffersCounterClick={handleOffersCounterClick}
        showReservations
        hideActionsMenu
      />
      {openReservations && (
        <>
          <ContractOfferDetails isOpen isLast={!hasReservations}>
            <ContractDetailsContainer
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ContractIconBig />
              <div>
                <DateTitle>
                  <FormattedMessage id='myOffers.auctionFinished' />
                </DateTitle>
                <Description>
                  {moment(offer.finishedAt).format(
                    DATE_LONG_FORMAT_WITH_WEEKDAY
                  )}
                </Description>
              </div>
            </ContractDetailsContainer>
            <BiddersContainer>
              <ContractIconSmall />
              <FormattedMessage
                id='myOffers.bidder'
                values={{ count: offer.reservations.length }}
              />
            </BiddersContainer>
          </ContractOfferDetails>
          {offer.reservations.map((reservation, index) => (
            <ReservationDetailsOffers
              key={reservation.id}
              offer={offer}
              reservation={reservation}
              finished={offer.finishedAt}
              index={index}
              isOpen={openReservations}
              chatAvailable={chatAvailable}
              buttonsState={buttonsState}
              acceptReservation={acceptReservation}
              setConversationOpened={() => {
                setConversationOpened({
                  receiverId: reservation.offeror.id,
                  conversationOpened: true
                })
              }}
            />
          ))}
        </>
      )}
    </>
  )
}

export default connectMyContractsOffersFinished(OfferRow)

const Arrow = styled.span`
  color: ${({ theme }) => theme.colors.apple.hex()};
`
const LocationRow = styled.div`
  display: flex;
  align-items: center;
`
const MoreContractsCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
  cursor: pointer;
  font-size: 0.75rem;
`

const OfferLink = styled(Link)`
  white-space: nowrap;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const ContractIconBig = styled(ContractIcon)`
  width: 40px;
  height: 40px;
  opacity: 0.15;
  margin-right: 1rem;
`

const ContractIconSmall = styled(ContractIcon)`
  width: 18px;
  height: 18px;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.apple.hex()};
`

const ContractDetailsContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`

const BiddersContainer = styled.div`
  align-items: center;
  display: flex;
  opacity: 0.7;
  font-weight: 600;
  font-size: 0.75rem;
`
