import React, { useContext, useEffect } from 'react'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'

import routes from 'src/utils/routes'
import Pagination from 'src/components/Pagination'

import Shade from 'src/components/Shade'
import LoadingList from 'src/components/atoms/LoadingList'

import {
  BottomRow,
  Container,
  ContentWrapper,
  Counter,
  Ellipsis,
  EmptyOffers,
  ListWrapper,
  RefreshIcon,
  Row
} from 'src/features/stocks/components/atoms'
import { isStockLoading } from 'src/utils/stocks'

import { AddOfferButton } from 'src/components/atoms/Buttons'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { HeaderItem, Padding } from 'src/features/myOffers/components/atoms'
import { Icon, SectionBox } from 'src/features/account/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { connectContracts } from 'src/features/contracts/duck/connectors'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import {
  CONTRACTS_HEADERS,
  CONTRACTS_HEADERS_STOCKS_WIDTHS,
  CONTRACTS_HEADERS_STOCKS_NAMES
} from 'src/features/contracts/duck/consts'
import { TableHeader } from 'src/features/contracts/components/atoms'
import OfferRow from 'src/features/contracts/components/OfferRow'
import Drawer from 'src/features/stocks/components/Drawer'
import { ContractsOfferDetails } from 'src/features/stocks/components/OfferDetails'

const OffersList = ({
  offers,
  pagination,
  filters,
  setFilter,
  hidePagination = false,
  getContractOffersList,
  isDrawerOpen,
  setDrawerState,
  showOffer,
  className,
  offerDetails,
  stock
}) => {
  const headers = CONTRACTS_HEADERS.STOCK
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const hasPermissionToAddOffer = hasPermissionTo(
    PERMISSIONS.CONTRACTS_ADD_OFFER,
    permissions
  )

  const handleOfferClick = id => {
    setDrawerState(true)
    showOffer(id, { origin: 'drawer' })
  }

  useEffect(() => {
    getContractOffersList()
    return () => {
      setDrawerState(false)
    }
  }, [])

  const renderListContents = () => {
    if (isStockLoading(stock, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (offers.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='offers.noOffers' />
        </EmptyOffers>
      )
    }

    if (offers.length > 0) {
      return offers.map(offer => (
        <OfferRow
          offer={offer}
          handleClick={handleOfferClick}
          key={offer.id}
          isSelected={offer.id === offerDetails.id && isDrawerOpen}
          clickable
        />
      ))
    }
  }

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader withDropdown>
              {intl.formatMessage({
                id: 'dashboard.contracts'
              })}
            </StockHeader>
            {hasPermissionToAddOffer && (
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_CONTRACTS}`}
              >
                <AddOfferButton />
              </Link>
            )}
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <ContentWrapper>
        <ListWrapper>
          <SectionBox>
            <Padding></Padding>
            <ListTopRow>
              <Counter>
                <span>{`${pagination.total} `}</span>
                <FormattedMessage
                  id='offers.offersFound'
                  values={{
                    count: pagination.total
                  }}
                />
              </Counter>
              <RefreshIcon onClick={getContractOffersList} />
            </ListTopRow>
            <Container>
              <TableHeader
                widths={CONTRACTS_HEADERS_STOCKS_WIDTHS}
                columnNames={CONTRACTS_HEADERS_STOCKS_NAMES}
              >
                {headers &&
                  headers.map((header, index) => (
                    <HeaderItem sort={false} key={`${header.name} ${index}`}>
                      <Ellipsis>
                        <FormattedMessage
                          id={`stocksHeaders.${header.name}`}
                          defaultMessage=' '
                        />
                        {header.sort && (
                          <Icon>
                            <ArrowIcon />
                          </Icon>
                        )}
                      </Ellipsis>
                    </HeaderItem>
                  ))}
              </TableHeader>
              {renderListContents()}
            </Container>
            {offers.length > 0 && !hidePagination && (
              <BottomRow>
                <Pagination
                  itemsPerPage={filters.limit}
                  currentPage={pagination.currentPage}
                  pagesCount={pagination.lastPage}
                  setPage={page => setFilter('page', page)}
                  setItemsPerPage={limit => {
                    setFilter('limit', limit)
                    setFilter('page', 1)
                  }}
                />
              </BottomRow>
            )}
          </SectionBox>
        </ListWrapper>
        <Drawer open={isDrawerOpen} onClose={() => setDrawerState(false)}>
          <ContractsOfferDetails />
        </Drawer>
      </ContentWrapper>
    </Page>
  )
}

const connectedComponent = connectContracts(OffersList)
export default styled(connectedComponent)`
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
export const ListTopRow = styled(Row)`
  justify-content: flex-start;
  padding: 0 1.2rem;
  ${Counter} {
    margin-right: 0.75rem;
  }
  ${breakpoint.m`
     padding: 0;
  `}
`
