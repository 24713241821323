import React from 'react'
import styled from 'styled-components'
import { isFalsy } from 'ramda-adjunct'
import { DateTime, Location } from 'src/features/stocks/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import ContainerIcon from 'src/assets/icons/container.svg'
import ShipIcon from 'src/assets/icons/ship.svg'
import WorkcaseIcon from 'src/assets/icons/work-case.svg'

import EllipsisTooltip from 'src/components/EllipsisTooltip'

const OfferInfoBox = ({ className, context }) => (
  <div className={className}>
    <LocationRow>
      <Location>
        <EllipsisTooltip>
          {context.from}{' '}
          {context.postalCodeFrom && `(${context.postalCodeFrom})`}
        </EllipsisTooltip>
      </Location>
      <DateTime>{context.fromDate}</DateTime>
      {!isFalsy(context.to) && (
        <>
          <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
          <Location>
            <EllipsisTooltip>
              {context.to} {context.postalCodeTo && `(${context.postalCodeTo})`}
            </EllipsisTooltip>
          </Location>
          <DateTime>{context.toDate}</DateTime>
        </>
      )}
    </LocationRow>
    <PropertiesRow>
      <OfferLink onClick={() => {}}>{context.referenceNumber}</OfferLink>
      {!isFalsy(context.container) && (
        <Container>
          <ContainerIcon />
          <div>
            <EllipsisTooltip>{context.container}</EllipsisTooltip>
          </div>
        </Container>
      )}
      {!isFalsy(context.shipowner) && (
        <Shipowner>
          <ShipIcon />
          <div>
            <EllipsisTooltip>{context.shipowner}</EllipsisTooltip>
          </div>
        </Shipowner>
      )}
      {!isFalsy(context.companyName) && (
        <Shipowner>
          <WorkcaseIcon />
          <div>
            <EllipsisTooltip>{context.companyName}</EllipsisTooltip>
          </div>
        </Shipowner>
      )}
    </PropertiesRow>
  </div>
)

export default styled(OfferInfoBox)`
  padding: 0.4rem 1rem 0.4rem 1rem;
`

const LocationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 0.3rem;
  }

  ${Location} {
    font-size: 0.8rem;
  }

  ${DateTime} {
    font-size: 0.69rem;
  }

  & svg {
    color: ${({ theme }) => theme.colors.apple.hex()};
  }
`

const PropertiesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.2rem;
  & > * {
    margin-right: 0.6rem;
  }
  font-size: 0.75rem;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.67rem;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
  > div {
    overflow: hidden;
  }
`

const Shipowner = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  font-size: 0.67rem;
  & svg {
    opacity: 0.7;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
  > div {
    overflow: hidden;
  }
`
const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.67rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`
