import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import LogoUrl from 'src/assets/logo-dark.png'
import { breakpoint } from 'src/theme/grid'
import { PlaceholderTitle } from './Typography'

const NotAuthorized = ({ className }) => {
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 3000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className={className}>
      {!isLoading && <PlaceholderTitle> Brak dostępu</PlaceholderTitle>}
      {isLoading && (
        <LogoWrapper>
          <Logo src={LogoUrl} alt='logo' />
        </LogoWrapper>
      )}
    </div>
  )
}

export default styled(NotAuthorized)`
  min-height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoWrapper = styled.div`
  display: inline-block;
  padding: 0 0.6rem 0.8rem 0;
`

const Logo = styled.img`
  display: inline-block;
  height: 48px;
  width: auto;
  margin-bottom: 1.8rem;
  ${breakpoint.m`
    margin-bottom: 0;
  `}
`
