import React from 'react'
import styled from 'styled-components'
import { TRACKING_STATES } from 'src/features/tracking/duck/consts'
import { Dot } from 'src/features/account/components/atoms'

const icons = {
  [TRACKING_STATES.INCOMPLETE_DATA]: {
    color: 'corduroy'
  },
  [TRACKING_STATES.WAITING_FOR_DRIVER]: {
    color: 'corduroy'
  },
  [TRACKING_STATES.IN_TRANSIT]: {
    color: 'california'
  },
  [TRACKING_STATES.ACTIVE]: {
    color: 'apple'
  },
  [TRACKING_STATES.COMPLETED_ON_SCHEDULE]: {
    color: 'apple'
  },
  [TRACKING_STATES.COMPLETED_BEHIND_SCHEDULE]: {
    color: 'fadedRed'
  },
  [TRACKING_STATES.DELAYED]: {
    color: 'fadedRed'
  },
  [TRACKING_STATES.COMPLETED_UNKNOWN_SCHEDULE]: {
    color: 'apple'
  },
  [TRACKING_STATES.COMPLETED_UNKNOWN]: {
    color: 'corduroy'
  }
}

const StatusComponent = ({ status, className }) => (
  <div className={className}>
    {status && <Dot color={icons[status].color} />}
  </div>
)

export default styled(StatusComponent)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`
