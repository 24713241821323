import React from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'
import { breakpoint } from 'src/theme/grid'
import { Avatar } from 'src/components/atoms/Avatar'
import {
  OfferorName,
  PhoneNumber
} from 'src/features/myOffers/components/atoms'
import ChatIcon from 'src/assets/icons/icon_chat.svg'
import PhoneIcon from 'src/assets/icons/phone.svg'

const Handler = ({
  handler,
  chatAvailable,
  className,
  setConversationOpened = () => {}
}) => (
  <div className={className}>
    <Row>
      <Avatar small url={handler.avatar && handler.avatar.url} />
      <OfferorNameAndCompanyBox>
        <OfferorName>{`${handler.firstName} ${handler.lastName}`}</OfferorName>
      </OfferorNameAndCompanyBox>
    </Row>
    <ContactRow>
      {handler.phoneNumber && !isEmpty(handler.phoneNumber) && (
        <PhoneNumber href={`tel:${handler.phoneNumber}`}>
          <span>{handler.phoneNumber}</span>
          <PhoneIcon />
        </PhoneNumber>
      )}
      {chatAvailable && <ChatIcon onClick={setConversationOpened} />}
    </ContactRow>
  </div>
)

export default styled(Handler)`
  grid-area: offeror;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoint.m`
     justify-content: flex-start;
  `}

  & ${PhoneNumber} {
    & > span {
      display: none;
      ${breakpoint.m`
     display: inline;
  `}
    }

    & > svg {
      ${breakpoint.m`
     display: none;
  `}
    }
  }

  & svg {
    margin-left: 1rem;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
    cursor: pointer;
    &:hover {
      transition: color 0.3s;
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
    }
    ${breakpoint.m`
     display: inline-block;
     margin-left: 0.5rem;
  `}
  }
`

const Row = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  flex-direction: row;
  & > ${Avatar} {
    margin-right: 0.5rem;
  }
`

const ContactRow = styled(Row)`
  flex-shrink: 0;
`

const OfferorNameAndCompanyBox = styled.div`
  min-width: 0;
`
