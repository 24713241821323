import styled, { css } from 'styled-components'
import React from 'react'

const Select = styled.select`
  background-color: ${({ theme }) => theme.colors.white.hex()};
  outline: none;
  border: none;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9IiMyNDJkM2YiIGZpbGwtb3BhY2l0eT0iMC43IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik03LjQxIDguNTlMMTIgMTMuMTdsNC41OS00LjU4TDE4IDEwbC02IDYtNi02IDEuNDEtMS40MXoiPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+Cg==)
    no-repeat right center;
  background-size: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

const SelectWrapper = styled.div`
  height: 40px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  border-radius: 2px;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  transition: border 0.3s;
  &:hover {
    border-color: ${({ theme }) => theme.colors.black.hex()};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

export default ({ options, disabled, className, ...props }) => (
  <SelectWrapper disabled={disabled} className={className}>
    <Select options={options} disabled={disabled} {...props}>
      {options.map(option => (
        <option key={option.id} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </SelectWrapper>
)
