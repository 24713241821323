import React from 'react'
import {
  Actions,
  ChangeHandler,
  Copy,
  Delete,
  Refresh
} from 'src/features/myOffers/components/atoms'
import PersonIcon from 'src/assets/icons/person.svg'
import VisibilityIcon from 'src/assets/icons/visibility.svg'
import CopyIcon from 'src/assets/icons/copy.svg'
import DeleteIcon from 'src/assets/icons/delete.svg'
import RestoreIcon from 'src/assets/icons/restore.svg'
import MoneyIcon from 'src/assets/icons/money.svg'

export const ActionsFieldFinished = ({
  onClickRestore,
  deletable,
  onClickDelete,
  onClickCopy,
  onClickOfferNumber,
  restorable,
  hasPermissionToDeleteOwnOffer,
  onClickDebtCollection,
  isDebtCollectable,
  isUpdatable
}) => (
  <Actions>
    {isUpdatable && (
      <ChangeHandler onClick={onClickOfferNumber}>
        <PersonIcon />
        <VisibilityIcon />
      </ChangeHandler>
    )}
    <Copy onClick={onClickCopy}>
      <CopyIcon />
    </Copy>
    {deletable && hasPermissionToDeleteOwnOffer && (
      <Delete onClick={onClickDelete}>
        <DeleteIcon />
      </Delete>
    )}
    {restorable && (
      <Refresh onClick={onClickRestore}>
        <RestoreIcon />
      </Refresh>
    )}
    {isDebtCollectable && (
      <Refresh onClick={onClickDebtCollection}>
        <MoneyIcon />
      </Refresh>
    )}
  </Actions>
)
