export const USERS_HEADERS_PUBLIC = [
  {
    name: 'myCompany.users.name',
    sort: null
  },
  {
    name: 'myCompany.users.login',
    sort: true
  },
  {
    name: 'myCompany.users.email',
    sort: null
  },
  {
    name: 'account.tel',
    sort: null
  },
  {
    name: 'account.additionalInfo',
    sort: null
  }
]
