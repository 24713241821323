import React from 'react'
import { Ellipsis } from 'src/features/stocks/components/atoms'
import { Handler, Name } from 'src/features/myOffers/components/atoms'
import { Avatar } from 'src/components/atoms/Avatar'
import PersonIcon from 'src/assets/icons/person.svg'

export const HandlerField = ({ handler }) => (
  <Handler>
    <Avatar small url={handler.avatar && handler.avatar.url} />
    <PersonIcon />
    <Name>
      <Ellipsis>{`${handler.firstName} ${handler.lastName}`}</Ellipsis>
    </Name>
  </Handler>
)
