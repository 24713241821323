import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  margin-top: 0.5rem;
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.white.hex()};
  font-size: 0.875rem;
  line-height: 1.71;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
  ${breakpoint.m`
          width: 120px;
          margin-top: 0;
  `}
`
