import styled from 'styled-components'

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 800;
  font-size: 1.1rem;
`
export const Role = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
  line-height: 1.71;
`
export const Title = styled.h4`
  font-size: 0.875rem;
  padding-bottom: 4px;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 800;
  line-height: 1.71;
  &:first-letter {
    text-transform: capitalize;
  }
`
export const Subtitle = styled.h5`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
  margin-bottom: 0.3rem;
`
