import React, { useState } from 'react'
import {
  ActionButtons,
  Company,
  Index,
  OfferLink,
  ReservationDetails as StyledReservationDetails
} from 'src/features/myOffers/components/atoms'
import CompanyIcon from 'src/assets/icons/work-case.svg'
import { DateTime, Ellipsis } from 'src/features/stocks/components/atoms'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import {
  RejectOfferButton,
  RejectOfferPromptButton
} from 'src/components/atoms/Buttons'
import { API_STATES } from 'src/ducks/consts'
import ConfirmDialog from 'src/components/ConfirmDialog'
import Offeror from 'src/features/myOffers/components/Offeror'
import { FINISHED_OFFER_STATES } from 'src/features/myOffers/duck/consts'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import AcceptReservationButton from 'src/features/myOffers/components/AcceptReservationButton'
import { ReservationStatus } from 'src/features/myOffers/components/ReservationStatus'

const ReservationDetailsOffers = ({
  offer,
  reservation,
  index,
  isOpen,
  chatAvailable,
  buttonsState,
  acceptReservation = () => {},
  hiddenReservationsCount,
  rejectReservation = () => {},
  handleOfferNumberClick = () => {},
  getOngoingOffers = () => {},
  setConversationOpened = () => {},
  rejectReservationSuccess = () => {},
  acceptReservationSuccess = () => {},
  botttomOfferor
}) => {
  const [isRejectDialogOpen, setRejectDialogOpen] = useState(false)
  const intl = useIntl()
  const isSold = offer.outcome === FINISHED_OFFER_STATES.SOLD
  const hasTracking = offer.doesRequireTracking || offer.doesAllowTracking
  const reservationStatus = reservation.acceptedAt
    ? 'accepted'
    : reservation.annulledAt && 'rejected'

  return (
    <StyledReservationDetails
      botttomOfferor={botttomOfferor}
      key={reservation.id}
      isOpen={isOpen}
      isLast={
        index + 1 === offer.reservations.length && hiddenReservationsCount === 0
      }
    >
      <Index>{index + 1}.</Index>
      <Company>
        <div>
          <CompanyIcon />
        </div>
        <div style={{ minWidth: 0 }}>
          <Ellipsis>
            <Link
              to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.COMPANY}&companyId=${reservation.offeror.company.id}`}
            >
              {reservation.offeror.company.name}
            </Link>
          </Ellipsis>
          <DateTime>
            <FormattedMessage
              id='myOffers.reservationDate'
              values={{ date: reservation.createdAt }}
            />
          </DateTime>
        </div>
      </Company>
      <Offeror
        chatAvailable={chatAvailable}
        offeror={reservation.offeror}
        setConversationOpened={setConversationOpened}
      />
      <ActionButtons>
        <ReservationStatus status={reservationStatus} />
        {reservation.isAcceptable && (
          <AcceptReservationButton
            offerReferenceNumber={offer.referenceNumber}
            active={buttonsState !== API_STATES.IN_PROGRESS}
            isLoading={buttonsState === API_STATES.IN_PROGRESS}
            onOfferNumberClick={() => handleOfferNumberClick(offer.id)}
            refreshList={getOngoingOffers}
            hasTracking={hasTracking}
            acceptReservation={({ withTracking }) =>
              acceptReservation({
                offerId: offer.id,
                reservationId: reservation.id,
                onSuccess: acceptReservationSuccess,
                withTracking
              })
            }
          />
        )}
        {reservation.isRejectable && (
          <>
            <RejectOfferButton
              active={buttonsState !== API_STATES.IN_PROGRESS}
              onClick={() => setRejectDialogOpen(true)}
            />
            <ConfirmDialog
              withReason={isSold}
              confirmButton={RejectOfferPromptButton}
              offerorCompany={reservation.offeror.company.name}
              open={isRejectDialogOpen}
              setOpen={setRejectDialogOpen}
              offer={offer}
              onConfirm={reason =>
                rejectReservation({
                  data: {
                    offerId: offer.id,
                    reservationId: reservation.id,
                    reason
                  },
                  meta: {
                    onSuccess: rejectReservationSuccess
                  }
                })
              }
              onOfferNumberClick={() => handleOfferNumberClick(offer.id)}
              message={
                isSold ? (
                  <FormattedMessage
                    id='myOffers.rejectSoldReservationWarning'
                    values={{
                      name: offer.referenceNumber,
                      a: (...chunks) => (
                        <OfferLink
                          onClick={() => handleOfferNumberClick(offer.id)}
                        >
                          {chunks}
                        </OfferLink>
                      )
                    }}
                  />
                ) : (
                  ''
                )
              }
              title={
                <FormattedMessage
                  id='myOffers.rejectSoldReservationTitle'
                  values={{
                    name: offer.referenceNumber
                  }}
                />
              }
              inputTitle={intl.formatMessage({
                id: 'myOffers.rejectSoldInputTitle'
              })}
              inputPlaceholder={intl.formatMessage({
                id: 'myOffers.rejectSoldInputPlaceholder'
              })}
            />
          </>
        )}
      </ActionButtons>
    </StyledReservationDetails>
  )
}

export default ReservationDetailsOffers
