import React from 'react'
import styled from 'styled-components'
import PlUrl from 'src/assets/icons/pl.svg'
import DeUrl from 'src/assets/icons/de.svg'
import EnUrl from 'src/assets/icons/en.svg'
import RuUrl from 'src/assets/icons/ru.svg'

const ICONS = {
  pl: PlUrl,
  de: DeUrl,
  en: EnUrl,
  ru: RuUrl
}

const Icon = ({ className, name }) => {
  const Component = ICONS[name.toLowerCase()] || null

  return (
    Component && (
      <div className={className}>
        <Component height='16px' width='auto' preserveAspectRatio='none' />
      </div>
    )
  )
}

export default styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  width: 25px;
  height: 16px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  box-sizing: border-box;
  align-self: center;
  margin-right: 0.5rem;
`
