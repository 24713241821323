import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import Flag from 'src/components/atoms/Flag'
import { CompanyLogo } from 'src/features/stocks/components/atoms'
import {
  AvatarContainer,
  Country,
  Key,
  User,
  UserContainer,
  UserProperties,
  Value
} from './atoms'
import { Name, Role } from './typography'

const CompanyInfo = ({ company }) => (
  <>
    <CompanyLogoContainer>
      <CompanyLogo big url={company.logo.url} />
    </CompanyLogoContainer>
    <CompanyContainer>
      <User>
        <Name>{`${company.name}`}</Name>
        <Role>
          <FormattedMessage
            id={`myCompany.companyProfiles.${company.businessProfile}`}
          />
        </Role>
      </User>
      <UserProperties>
        <Key>
          <FormattedMessage id='common.nip' />
        </Key>
        <Value>{company.taxId}</Value>
        <Key>
          <FormattedMessage id='common.address' />
        </Key>
        <Value>
          {`${company.address}
${company.postalCode} ${company.city}
`}
          <Country>
            {company.countryCode && <Flag name={company.countryCode} />}
            {company.countryCode}
          </Country>
        </Value>
      </UserProperties>
    </CompanyContainer>
  </>
)

const CompanyLogoContainer = styled(AvatarContainer)`
  align-items: flex-start;
`

const CompanyContainer = styled(UserContainer)``

export default CompanyInfo
