import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

const OrderDetails = ({ className, order }) => (
  <div className={className}>
    <Text>
      {order.transportationType ? (
        <>
          <FormattedMessage id={`common.${order.transportationType}`} />
          {', '}
        </>
      ) : (
        ''
      )}
      {order.loadingUnloadingCompanyName
        ? `${order.loadingUnloadingCompanyName}, `
        : ''}
      {order.loadingUnloadingAddress
        ? `${order.loadingUnloadingAddress}, `
        : ''}
      {order.loadingUnloadingDate ? (
        <span>{`${order.loadingUnloadingDate}, `}</span>
      ) : (
        ''
      )}
      {order.loadingUnloadingTime ? (
        <span>{`${order.loadingUnloadingTime}, `}</span>
      ) : (
        ''
      )}
      {order.container ? `${order.container}, ` : ''}
      {order.shipowner ? `${order.shipowner}, ` : ''}
      {order.forwarder.company ? `${order.forwarder.company} ` : ''}
      {order.forwarder.name ? `(${order.forwarder.name})` : ''}
    </Text>
  </div>
)

export default styled(OrderDetails)`
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 20px;
  line-height: 1;
`

const Text = styled.span`
  display: inline;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  span {
    color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.6).hex()};
  }
`
