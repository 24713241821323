import React from 'react'
import styled from 'styled-components'
import MUIClose from '@material-ui/icons/Close'

const SearchInputComponent = ({
  className,
  id,
  name,
  value,
  onChange,
  placeholder,
  onClear,
  onKeyDown
}) => (
  <div className={className}>
    <Input
      id={id}
      name={name}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      onKeyPress={onKeyDown}
    />
    {value.length > 0 && <CloseIcon onClick={onClear} />}
  </div>
)

export default styled(SearchInputComponent)`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-right: 0.5rem;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgb(221, 223, 226);
  background-color: ${({ theme }) => theme.colors.white.hex()};
  position: relative;
`

const CloseIcon = styled(MUIClose)`
  font-size: 1.45rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  opacity: 0.3;
  position: absolute;
  right: 1rem;
  transition: opacity 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

const Input = styled.input`
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  padding: 1rem 3rem 1rem 1.5rem;
  &::placeholder {
    display: inline-block;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  }
`
