import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import { remove } from 'ramda'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { HeaderItem, Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroup,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection
} from 'src/features/offers/components/atoms'
import { breakpoint } from 'src/theme/grid'
import Drawer from 'src/features/stocks/components/Drawer'
import { connectDictionaries } from 'src/ducks/connectors'
import {
  BigInputContainer,
  CreateOfferButton,
  Heading,
  InputRow,
  SmallInputContainer
} from 'src/features/orders/components/atoms'
import { connectAddCorrectiveInvoice } from 'src/features/invoices/duck/connectors'
import Checkbox from 'src/components/atoms/CheckboxInput'
import TextInput from 'src/components/atoms/TextInput'
import { API_STATES, BOOLEAN } from 'src/ducks/consts'
import { Icon, TitleContainer } from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import ItemRow from 'src/features/invoices/components/itemsTable/InvoiceItemRow'
import ItemRowPreview from 'src/features/invoices/components/itemsTable/InvoiceItemRowPreview'
import { InvoiceItem } from 'src/features/invoices/duck/records'
import { Summary } from 'src/features/invoices/components/SummaryBox'
import { ItemsTable } from 'src/features/invoices/components/itemsTable/ItemsTable'
import { PaymentsBox } from 'src/features/invoices/components/PaymentsBox'
import debounce from 'lodash.debounce'
import {
  validateAddCorrectiveInvoiceSchema,
  validateAddCorrectiveInvoice
} from 'src/features/invoices/duck/schema'
import { AddInvoiceButtonComponent } from 'src/features/invoices/components/AddInvoiceItemButton'
import VisibilityIcon from 'src/assets/icons/visibility.svg'
import { RecipientDetails } from 'src/features/invoices/components/TopSection/RecipientDetails'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import {
  AdditionalInfo,
  Center,
  CenteredTitle,
  HeaderName,
  IssuerContainer,
  PreviewButton,
  SummaryContainer
} from 'src/features/invoices/styles'
import useUrlParams from 'src/hooks/useUrlParams'
import { InputTitle } from 'src/features/invoices/components/InvoiceSettingsDialog'
import { DateInputContainer } from 'src/features/invoices/components/TopSection/styles'
import DatePicker from 'src/components/atoms/DatePicker'
import { Ellipsis } from 'src/features/stocks/components/atoms'
import Select from 'src/features/invoices/components/Select'
import useValidateSchema from 'src/hooks/useValidateSchema'

const AddCorrectiveInvoice = ({
  className,
  prepareInvoice,
  invoice,
  setInvoiceEditFormValue,
  createInvoice,
  recalculateInvoice,
  previewInvoice,
  clearAddInvoiceForm
}) => {
  const { id } = useUrlParams()

  useEffect(() => {
    id && prepareInvoice(id)
    return clearAddInvoiceForm
  }, [id])

  const debouncedRecalculate = useCallback(
    debounce(recalculateInvoice, 1000),
    []
  )

  const intl = useIntl()

  const isLoading = invoice.state === API_STATES.IN_PROGRESS

  const removeItem = index => {
    debouncedRecalculate()
    setInvoiceEditFormValue('items', remove(index, 1, invoice.items))
  }
  const addItem = () => {
    debouncedRecalculate()
    setInvoiceEditFormValue('items', [...invoice.items, new InvoiceItem()])
  }

  const valid = useValidateSchema(
    invoice.toJS(),
    validateAddCorrectiveInvoiceSchema({
      invoiceIssueDate: moment(invoice.invoice.issueDate)
        .subtract(1, 'day')
        .set({
          hour: 23,
          minute: 59
        })
    })
  )

  const validate = validateAddCorrectiveInvoice({
    invoiceIssueDate: moment(invoice.invoice.issueDate)
      .subtract(1, 'day')
      .set({
        hour: 23,
        minute: 59
      })
  })

  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                <FormattedMessage id='invoices.addCorrectiveInvoice' />
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          <FormSection>
            <FormInnerWrapper>
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'invoices.invoices' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`
                    },
                    {
                      name: intl.formatMessage({
                        id: 'invoices.addCorrectiveInvoice'
                      }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CORRECTIVE_INVOICE_ADD}`
                    }
                  ]}
                />
                <TitleContainer>
                  <Heading>
                    <FormattedMessage id='invoices.correctiveInvoice' />{' '}
                    {invoice.number}
                  </Heading>
                </TitleContainer>
                <div>
                  <InputRow style={{ justifyContent: 'space-between' }}>
                    <CorrectionReasonContainer>
                      <InputTitle required>
                        <FormattedMessage id='invoices.correctionReason' />
                      </InputTitle>
                      <Select
                        options={invoice.availableCorrectionReasons}
                        label={intl.formatMessage({
                          id: 'invoices.correctionReason'
                        })}
                        name='correctionReason'
                        validate={validate}
                        value={invoice.correctionReason}
                        onChange={e =>
                          setInvoiceEditFormValue(
                            'correctionReason',
                            e.target.value
                          )
                        }
                        id='correctionReason'
                      />
                    </CorrectionReasonContainer>
                    <InputRow>
                      <DateInputContainer>
                        <TextInput
                          label={intl.formatMessage({
                            id: 'invoices.issuePlace'
                          })}
                          id='issuePlace'
                          name='issuePlace'
                          placeholder=''
                          value={invoice.issuePlace}
                          onChange={setInvoiceEditFormValue}
                          validate={validate}
                          required
                        />
                      </DateInputContainer>
                      <DateInputContainer>
                        <DatePicker
                          label={intl.formatMessage({
                            id: 'invoices.issueDate'
                          })}
                          name='issueDate'
                          validate={validate}
                          placeholder='dd.mm.rrrr'
                          minDate={invoice.invoice.issueDate}
                          value={invoice.issueDate}
                          onChange={setInvoiceEditFormValue}
                          required
                        />
                      </DateInputContainer>
                    </InputRow>
                  </InputRow>
                  <InputRow>
                    <BigInputContainer>
                      <RecipientDetails invoice={invoice.invoice} showLabel />
                    </BigInputContainer>
                    <DateInputContainer style={{ marginLeft: 'auto' }}>
                      <DatePicker
                        label={intl.formatMessage({
                          id: 'invoices.dueDate'
                        })}
                        name='dueDate'
                        placeholder='dd.mm.rrrr'
                        minDate={invoice.sellDate}
                        value={invoice.dueDate}
                        onChange={setInvoiceEditFormValue}
                        required
                      />
                    </DateInputContainer>
                  </InputRow>
                </div>
                <CenteredTitle>
                  <FormattedMessage id='invoices.invoiceItemWas' />
                </CenteredTitle>
                <ItemsTable
                  renderHeader={(header, index) => (
                    <HeaderItem sort={false} key={`${header.name} ${index}`}>
                      <Ellipsis>
                        <FormattedMessage
                          id={`invoices.${header.name}`}
                          defaultMessage=' '
                        />
                        {header.sort && (
                          <Icon>
                            <ArrowIcon />
                          </Icon>
                        )}
                      </Ellipsis>
                    </HeaderItem>
                  )}
                  items={invoice.invoice.items}
                  renderItem={(item, index) => (
                    <ItemRowPreview
                      key={`${item.id}-${index}`}
                      item={item}
                      index={index}
                      invoicesLength={invoice.items.length}
                      invoice={invoice}
                    />
                  )}
                />
                <CenteredTitle>
                  <FormattedMessage id='invoices.invoiceItemsShouldBe' />
                </CenteredTitle>
                <ItemsTable
                  renderHeader={(header, index) => (
                    <HeaderItem sort={false} key={`${header.name} ${index}`}>
                      <EllipsisTooltip>
                        <HeaderName required={header.required}>
                          <FormattedMessage
                            id={`invoices.${header.name}`}
                            defaultMessage=' '
                          />
                        </HeaderName>
                        {header.sort && (
                          <Icon>
                            <ArrowIcon />
                          </Icon>
                        )}
                      </EllipsisTooltip>
                    </HeaderItem>
                  )}
                  items={invoice.items}
                  renderItem={(item, index) => (
                    <ItemRow
                      key={`${item.id}-${index}`}
                      item={item}
                      index={index}
                      invoicesLength={invoice.items.length}
                      setInvoiceEditFormValue={setInvoiceEditFormValue}
                      removeItem={removeItem}
                      recalculateInvoice={debouncedRecalculate}
                      invoice={invoice}
                    />
                  )}
                />
                <SummaryContainer>
                  <AddInvoiceButtonComponent onClick={addItem} />
                  <Summary
                    title={
                      <FormattedMessage id='invoices.settlementOfTheCorrection' />
                    }
                    subtotal={invoice.subtotal}
                    tax={invoice.tax}
                    total={invoice.total}
                  />
                </SummaryContainer>
                <PaymentsBox
                  correctiveInvoice
                  invoice={invoice}
                  validate={validate}
                  amountPaid={invoice.amountPaid}
                  onChange={setInvoiceEditFormValue}
                  paymentMethod={invoice.paymentMethod}
                  paymentBankAccount={invoice.paymentBankAccount}
                  paymentBankName={invoice.paymentBankName}
                  isSplitPayment={invoice.isSplitPayment}
                  setSplitPayment={name =>
                    setInvoiceEditFormValue(name, !invoice.isSplitPayment)
                  }
                  renderSaftOption={name => (
                    <Checkbox
                      key={name}
                      name={name}
                      value={invoice.saft[name]}
                      option={{
                        value: BOOLEAN.YES,
                        text: intl.formatMessage({
                          id: `invoices.saft.${name}`
                        })
                      }}
                      onChange={fieldName =>
                        setInvoiceEditFormValue(
                          ['saft', fieldName],
                          !invoice.saft[fieldName]
                        )
                      }
                    />
                  )}
                />
                <AdditionalInfo>
                  <TitleContainer>
                    <Title>
                      <FormattedMessage id='orders.additionalInfo' />
                    </Title>
                  </TitleContainer>
                  <InputRow>
                    <BigInputContainer>
                      <TextInput
                        type='text'
                        label={intl.formatMessage({
                          id: 'invoices.correctionComments'
                        })}
                        id='additionalInfo'
                        name='additionalInfo'
                        placeholder=''
                        value={invoice.additionalInfo}
                        onChange={setInvoiceEditFormValue}
                        multiline
                        validate={validate}
                      />
                    </BigInputContainer>
                    <IssuerContainer>
                      <TextInput
                        disabled
                        label={intl.formatMessage({
                          id: 'invoices.issuer'
                        })}
                        id='issuer'
                        name='issuer'
                        placeholder=''
                        value={`${invoice.issuer.firstName} ${invoice.issuer.lastName}`}
                        validate={validate}
                      />
                    </IssuerContainer>
                  </InputRow>
                </AdditionalInfo>
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Center>
                <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`}>
                  {intl.formatMessage({ id: 'forms.cancel' })}
                </Link>
                <ActionGroup>
                  <PreviewButton
                    active={valid && !isLoading}
                    onClick={previewInvoice}
                  >
                    <FormattedMessage id='invoices.preview' />
                    <VisibilityIcon />
                  </PreviewButton>
                  <CreateOfferButton
                    active={valid && !isLoading}
                    onClick={createInvoice}
                  >
                    <FormattedMessage id='invoices.addCorrectiveInvoice' />
                  </CreateOfferButton>
                </ActionGroup>
              </Center>
            </FormActionBar>
          </FormSection>
        </Content>
      </Page>
    </>
  )
}

const ConnectedComponent = compose(
  connectAddCorrectiveInvoice,
  connectDictionaries
)(AddCorrectiveInvoice)

export default styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
    background-color: #f8f8f9;
  }
  ${Padding} {
    max-width: calc(887px + 2.8rem);
    margin: 0 auto;
  }
  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
`

const CorrectionReasonContainer = styled(SmallInputContainer)`
  margin-bottom: 1rem;
  ${breakpoint.m`
    margin-bottom: inherit;
   `}
`
