import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { find, propEq } from 'ramda'
import routes from 'src/utils/routes'
import useUrlParams from 'src/hooks/useUrlParams'
import { LinkPlaceholder } from 'src/components/Typography'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { validateEditUserSchema } from 'src/features/account/duck/schema'
import { connectEditCompanyUser } from 'src/features/account/duck/connectors'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import EditUserForm from 'src/features/account/myCompany/forms/EditUserForm'
import BreadCrumbs from 'src/components/BreadCrumbs'
import {
  ActionGroup,
  FormActionBar
} from 'src/features/offers/components/atoms'
import { MENU_ITEMS, MY_COMPANY_TABS } from 'src/features/account/duck/consts'
import { SaveOfferButton } from 'src/components/atoms/Buttons'
import { Page } from 'src/components/Wrappers'
import { SectionBox } from 'src/features/account/components/atoms'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'
import { API_STATES } from 'src/ducks/consts'

const EditUser = ({
  className,
  editUserForm,
  editUser,
  users,
  getCurrentUserCoworkers,
  clearForm,
  prefillEditUserForm,
  editUserStatus: { state }
}) => {
  const intl = useIntl()
  const valid = useValidateSchema(editUserForm.toJS(), validateEditUserSchema)
  const { id } = useUrlParams()
  const user = useMemo(() => find(propEq('id', id), users), [id, users])
  const isLoading = state === API_STATES.IN_PROGRESS
  useEffect(() => {
    getCurrentUserCoworkers()
    return () => clearForm()
  }, [])

  useEffect(() => {
    if (user) prefillEditUserForm(user.toJS())
  }, [user])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              <FormattedMessage
                id='myCompany.users.editUserTitle'
                values={{
                  name: user && `${user.firstName} ${user.lastName}`
                }}
              />
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <SectionBox>
        <BreadCrumbs
          links={[
            {
              name: intl.formatMessage({ id: 'myCompany.myCompany' }),
              url: `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`
            },
            {
              name: intl.formatMessage({
                id: 'myCompany.employees'
              }),
              url: `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`,
              state: {
                activeTab: MY_COMPANY_TABS.EMPLOYEES
              }
            },
            {
              name: intl.formatMessage({ id: 'myCompany.users.editUser' }),
              url: null
            }
          ]}
        />
        {user && <EditUserForm user={user} />}
      </SectionBox>
      {user && (
        <FormActionBar>
          <Link
            to={`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`}
            state={{
              activeTab: MY_COMPANY_TABS.EMPLOYEES
            }}
          >
            {intl.formatMessage({ id: 'common.cancel' })}
          </Link>
          <ActionGroup>
            <SaveOfferButton active={valid && !isLoading} onClick={editUser}>
              {intl.formatMessage({ id: 'common.save' })}
            </SaveOfferButton>
          </ActionGroup>
        </FormActionBar>
      )}
    </Page>
  )
}

const ConnectedComponent = connectEditCompanyUser(EditUser)
export default styled(ConnectedComponent)`
  ${LinkPlaceholder} {
    margin-bottom: 1.2rem;
  }
`
