import { Avatar } from 'src/components/atoms/Avatar'
import EllipsisTooltip from 'src/components/EllipsisTooltip'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { ChatIcon } from 'src/features/account/components/atoms'
import React from 'react'
import {
  IconChat,
  MobileVisibleRole,
  Name,
  NameAndRoleContainer,
  Role
} from 'src/features/account/components/rows'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import EmailIconUrl from 'src/assets/mail-alt.svg'
import PersonIconUrl from 'src/assets/person.svg'

export const UsersRowPublic = ({ user, onChat }) => (
  <>
    <Name>
      <Avatar medium url={user.avatar && user.avatar.url} />
      <NameAndRoleContainer>
        <EllipsisTooltip>
          {`${user.firstName} ${user.lastName}`}
        </EllipsisTooltip>
        <MobileVisibleRole>
          <FormattedMessage id={`common.${user.role}`} />
        </MobileVisibleRole>
      </NameAndRoleContainer>
    </Name>
    <Username>
      <EllipsisTooltip>{user.username}</EllipsisTooltip>
    </Username>
    <Email>
      <EllipsisTooltip>{user.email}</EllipsisTooltip>
    </Email>
    <Status>{user.phoneNumber}</Status>
    <Role>{user.contactInfo}</Role>
    <IconChat onClick={() => onChat(user.id)}>
      <ChatIcon name='chat' size={24} />
    </IconChat>
  </>
)

const Email = styled.div`
  grid-area: email;
  font-size: 0.75rem;

  && {
    padding-left: 22px;
    position: relative;
    ${breakpoint.m`
      padding-left: 10px;
  `}
  }

  &:before {
    position: absolute;
    left: 0px;
    content: '';
    background-image: url(${EmailIconUrl});
    opacity: 0.7;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    ${breakpoint.m`
      display: none;
  `}
  }
`

const Status = styled.div`
  grid-area: status;
  font-size: 0.75rem;
  justify-self: end;
  ${breakpoint.m`
      justify-self: start;
  `}
`

const Username = styled.div`
  grid-area: username;
  font-size: 0.75rem;

  && {
    padding-left: 32px;
    position: relative;
    ${breakpoint.m`
      padding-left: 10px;
  `}
  }

  &:before {
    position: absolute;
    left: 10px;
    content: '';
    background-image: url(${PersonIconUrl});
    opacity: 0.7;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    ${breakpoint.m`
      display: none;
  `}
  }
`
