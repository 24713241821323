import React, { useContext } from 'react'
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import ChatIcon from 'src/assets/icons/icon_chat.svg'
import GgReplacementUrl from 'src/assets/gg.png'
import {
  Bold,
  Description,
  PhoneNumbers,
  Title
} from 'src/features/stocks/components/typography'
import Documents from 'src/features/stocks/components/OfferDetails/Documents'
import { pathOr, propEq, any, isEmpty, not } from 'ramda'
import Alert from 'src/components/Alert'
import { REQUIRED_DOCUMENT_TYPE } from 'src/ducks/consts'
import SelectUser from 'src/components/SelectUser'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import { Avatar } from 'src/components/atoms/Avatar'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import TopContainerCertificate from 'src/assets/top_container_certificate.svg'
import IconWithTooltip from 'src/components/IconWithTooltip'
import WarningIcon from '@material-ui/icons/Warning'

const Contact = ({
  className,
  stockType,
  handler,
  changeHandler,
  offerBelongsToCurrentUser,
  offerBelongsToCurrentUserCompany,
  isUpdatable,
  handleConversationStart = () => {},
  issuer: {
    company: {
      name,
      address,
      city,
      countryCode,
      phoneNumber,
      documents = [],
      postalCode,
      id,
      isTopContainerCertified,
      hasPaymentIssues
    }
  }
}) => {
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const gg = pathOr(null, ['communicators', 'gg'], handler)
  const showDocuments = not(isEmpty(documents))
  const showLicenceWarningMessage = not(
    any(
      propEq(
        'type',
        [STOCKS_TYPES.FREE_CARRIERS, STOCKS_TYPES.POST_IMPORT].includes(
          stockType
        )
          ? 'carrier_license'
          : 'forwarder_license'
      ),
      documents
    )
  )

  const showChangeUserSelect =
    (isUpdatable &&
      offerBelongsToCurrentUser &&
      hasPermissionTo(PERMISSIONS.OFFER_EDIT_OWN, permissions)) ||
    (isUpdatable &&
      offerBelongsToCurrentUserCompany &&
      hasPermissionTo(PERMISSIONS.OFFER_EDIT_ANY, permissions))

  const userCantUndoChange =
    offerBelongsToCurrentUser &&
    !hasPermissionTo(PERMISSIONS.OFFER_EDIT_ANY, permissions)

  return (
    <div className={className}>
      <div>
        <Title>
          <FormattedMessage id='offerDetails.contactPerson' />
        </Title>
        {!showChangeUserSelect && (
          <User>
            <Avatar url={handler.avatar && handler.avatar.url} />
            <Username>
              <Bold>{`${handler.firstName} ${handler.lastName}`}</Bold>
              <Description>
                <a href={`tel:${handler.phoneNumber}`}>{handler.phoneNumber}</a>
                {/* {' '}
                 {gg && (
                  <a href={`gg:${gg}`}>
                    <img
                      alt='gadu-gadu'
                      src={`http://status.gadu-gadu.pl/users/status.asp?id=${gg}&styl=0`}
                      onError={e => {
                        e.target.onerror = null
                        e.target.src = GgReplacementUrl
                      }}
                    />
                  </a>
                )} */}
              </Description>
            </Username>
            {!offerBelongsToCurrentUser && (
              <ChatIconContainer>
                <ChatIcon onClick={handleConversationStart} />
              </ChatIconContainer>
            )}
          </User>
        )}
        {showChangeUserSelect && (
          <SelectUser
            handler={handler}
            onChange={changeHandler}
            userCantUndoChange={userCantUndoChange}
          />
        )}
      </div>

      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Title>
            <FormattedMessage id='offerDetails.contactCompany' />
          </Title>
          <Company>
            <Bold>
              <Link
                to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.COMPANY}&companyId=${id}`}
              >
                {name}
              </Link>
            </Bold>
          </Company>
          {hasPaymentIssues && (
            <StyledAlertPaymentIssues
              type='warning'
              message={intl.formatMessage({
                id: 'common.hasPaymentIssues'
              })}
            />
          )}
          {showLicenceWarningMessage && (
            <StyledAlert
              type='warning'
              message={intl.formatMessage({
                id: `offerDetails.no${
                  [
                    STOCKS_TYPES.FREE_CARRIERS,
                    STOCKS_TYPES.POST_IMPORT
                  ].includes(stockType)
                    ? 'Carrier'
                    : 'Forwarder'
                }LicenceWarning`
              })}
            />
          )}
          <Description as='div'>
            <p>{address}</p>
            <p>
              {`${postalCode} ${city}${countryCode ? `, ${countryCode}` : ''}`}
            </p>
          </Description>
          <PhoneNumbers as='div'>
            <p>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </p>
          </PhoneNumbers>
        </div>
        {isTopContainerCertified && (
          <div
            style={{
              flex: '0 0 auto',
              'max-width': '100%',
              'max-height': '100%',
              display: 'flex',
              'vertical-align': 'middle'
            }}
          >
            <IconWithTooltip
              title={intl.formatMessage({
                id: 'common.companyTopContainerCertified'
              })}
              icon={
                <img
                  style={{ 'margin-left': '10px' }}
                  src={TopContainerCertificate}
                  width={80}
                  alt='Top Container Certificate'
                />
              }
            />
          </div>
        )}
      </div>

      {showDocuments && <Documents documents={documents} />}
    </div>
  )
}

const Company = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  a:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const Username = styled.div`
  margin-left: 1rem;
`

const ChatIconContainer = styled.span`
  margin-left: auto;
  display: block;
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.7).hex()};
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
`

const StyledAlert = styled(Alert)`
  margin: 0.5rem 0;
`

const StyledAlertPaymentIssues = styled(StyledAlert)`
   // border: 1px solid ${({ theme }) => theme.colors.error.hex()};
  font-weight: 600;
  // background-color: transparent;
  // background-color: ${({ theme }) => theme.colors.alabaster.hex()};
`

export default styled(Contact)`
  background-color: ${({ theme }) => theme.colors.alabaster.hex()};
  flex: 1;
  && {
    padding: 0;
  }
  & > div {
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
`
