import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import CustomIcon from 'src/components/CustomIcon'

const icons = {
  accepted: {
    icon: 'success',
    color: 'apple'
  },
  rejected: {
    icon: 'cancel',
    color: 'fadedRed'
  },
  noReaction: {
    icon: 'warning',
    color: 'california'
  }
}

const StatusComponent = ({ status, className, onlyIcon }) => (
  <div className={className}>
    {status && <CustomIcon name={icons[status].icon} size={24} />}
    {status && !onlyIcon && (
      <span>
        <FormattedMessage id={`myOffers.${status}`} />
      </span>
    )}
  </div>
)

export const ReservationStatus = styled(StatusComponent)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  & i {
    flex-shrink: 0;
    display: inline-flex;
    color: ${({ theme, status }) =>
      status && theme.colors[icons[status].color].hex()};
  }
  & span {
    margin-left: 0.2rem;
    display: none;
    ${breakpoint.m`
    display: inline;
  `}
  }
`
