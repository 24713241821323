import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import {
  Column,
  LanguageContainer,
  NarrowColumn,
  Row,
  TitleContainer
} from 'src/features/account/components/atoms'
import { Subtitle, Title } from 'src/features/account/components/typography'
import { Language } from 'src/components/SelectLanguage'
import {
  InputsRowWrapper,
  InputWrapper
} from 'src/components/atoms/FormHelpers'
import TextInput from 'src/components/atoms/TextInput'
import { validateAddUser } from 'src/features/account/duck/schema'
import { ROLE_OPTIONS } from 'src/features/account/duck/consts'
import Checkbox from 'src/components/atoms/CheckboxInput'
import AddPhoto from 'src/features/account/components/AddPhotoByUrl'
import Alert from 'src/components/Alert'

const AddUserForm = ({ className, setChange, data }) => {
  const intl = useIntl()

  return (
    <Row className={className}>
      <Column left>
        <TitleContainer>
          <Title>
            <FormattedMessage id='myCompany.users.role' />
          </Title>
        </TitleContainer>
        <RadioWrapper>
          {ROLE_OPTIONS.map(opt => (
            <Checkbox
              key={opt.value}
              name='role'
              value={data.role}
              onChange={setChange}
              type='radio'
              option={{ ...opt, text: intl.formatMessage({ id: opt.text }) }}
            />
          ))}
        </RadioWrapper>
        <TitleContainer>
          <Title>
            <FormattedMessage id='myCompany.users.basicInfo' />
          </Title>
        </TitleContainer>
        <BasicInfoContainer>
          <NarrowColumn>
            <AddPhoto
              onChange={(fileUrl, fileSize = 0) => {
                setChange('avatar', fileUrl)
                setChange('avatarSize', fileSize)
              }}
              photo={data.avatar}
            />
          </NarrowColumn>
          <Column right>
            <InputsRowWrapper>
              <InputWrapper width={12}>
                <TextInput
                  label={intl.formatMessage({ id: 'common.name' })}
                  id='name'
                  name='firstName'
                  placeholder={intl.formatMessage({
                    id: 'myCompany.users.namePlaceholder'
                  })}
                  value={data.firstName}
                  onChange={setChange}
                  validate={validateAddUser}
                />
              </InputWrapper>
            </InputsRowWrapper>
            <InputsRowWrapper>
              <InputWrapper width={12}>
                <TextInput
                  label={intl.formatMessage({ id: 'common.surname' })}
                  id='lastName'
                  name='lastName'
                  placeholder={intl.formatMessage({
                    id: 'myCompany.users.lastNamePlaceholder'
                  })}
                  value={data.lastName}
                  onChange={setChange}
                  validate={validateAddUser}
                />
              </InputWrapper>
            </InputsRowWrapper>
            <InputsRowWrapper>
              <InputWrapper width={12}>
                <TextInput
                  label={intl.formatMessage({ id: 'common.userName' })}
                  id='userName'
                  name='username'
                  placeholder={intl.formatMessage({
                    id: 'myCompany.users.usernamePlaceholder'
                  })}
                  value={data.username}
                  onChange={setChange}
                  validate={validateAddUser}
                />
              </InputWrapper>
            </InputsRowWrapper>
            <InputsRowWrapper>
              <InputWrapper width={12}>
                <TextInput
                  label={intl.formatMessage({ id: 'common.email' })}
                  id='email'
                  name='email'
                  placeholder={intl.formatMessage({
                    id: 'myCompany.users.emailPlaceholder'
                  })}
                  value={data.email}
                  onChange={setChange}
                  validate={validateAddUser}
                />
              </InputWrapper>
            </InputsRowWrapper>
            <InputsRowWrapper>
              <InputWrapper width={12}>
                <TextInput
                  label={intl.formatMessage({
                    id: 'common.password'
                  })}
                  id='password'
                  name='password'
                  type='password'
                  placeholder={intl.formatMessage({
                    id: 'myCompany.users.passwordPlaceholder'
                  })}
                  value={data.password}
                  onChange={setChange}
                  validate={validateAddUser}
                />
              </InputWrapper>
            </InputsRowWrapper>
          </Column>
        </BasicInfoContainer>
      </Column>
      <Column right>
        <Row>
          <Column>
            <TitleContainer>
              <Title>
                <FormattedMessage id='myCompany.contactData' />
              </Title>
            </TitleContainer>
            <InputsRowWrapper>
              <InputWrapper width={12}>
                <TextInput
                  type='tel'
                  label={intl.formatMessage({ id: 'account.phoneNumberLabel' })}
                  id='phoneNumber'
                  name='phoneNumber'
                  placeholder={intl.formatMessage({ id: 'common.phoneNumber' })}
                  value={data.phoneNumber}
                  onChange={setChange}
                  validate={validateAddUser}
                />
              </InputWrapper>
            </InputsRowWrapper>
            <InputsRowWrapper>
              <InputWrapper width={12}>
                <TextInput
                  label={intl.formatMessage({
                    id: 'account.additionalInfoEdit'
                  })}
                  id='contactInfo'
                  name='contactInfo'
                  placeholder={intl.formatMessage({
                    id: 'account.additionalInfoPlaceholder'
                  })}
                  value={data.contactInfo}
                  onChange={setChange}
                  multiline
                  validate={validateAddUser}
                />
              </InputWrapper>
            </InputsRowWrapper>
            <TitleContainer>
              <Title>
                <FormattedMessage id='account.settings' />
              </Title>
            </TitleContainer>
            <LanguageContainer>
              <Subtitle>
                <FormattedMessage id='common.language' />
              </Subtitle>
              <Language
                updateCurrentUserLanguage={({ lang }) =>
                  setChange('lang', lang)
                }
                value={data.lang}
              />
            </LanguageContainer>
            {data.errorMessage && (
              <ErrorMessage type='alert' message={data.errorMessage} />
            )}
          </Column>
        </Row>
      </Column>
    </Row>
  )
}
export default styled(AddUserForm)`
  ${InputWrapper} {
    max-width: 400px;
  }
  ${InputsRowWrapper} {
    margin-bottom: 0.5rem;
  }
`

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 1rem;
  flex-wrap: wrap;
  ${Checkbox} {
    width: auto;
    flex: 1;
  }
`

const BasicInfoContainer = styled(Row)`
  & {
    padding-top: 0;
  }
`

const ErrorMessage = styled(Alert)`
  max-width: 400px;
  box-sizing: border-box;
  margin: 1rem 0;
`
