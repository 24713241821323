import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { isNil } from 'ramda'
import styled from 'styled-components'
import { Title } from 'src/features/stocks/components/typography'
import Timeline from 'src/features/stocks/components/OfferDetails/Timeline'
import Contact from 'src/features/stocks/components/OfferDetails/Contact'
import {
  ContainerInfo,
  ContainerSemiTrailerInfo,
  CreatedDateOfferDetails,
  CustomsClearance,
  DrawerTitle,
  MiddleContainer,
  Notes,
  OfferHasTrackingInfo,
  OfferRemovedInfo,
  Price
} from 'src/features/stocks/components/OfferDetails/atoms'
import { compose } from 'recompose'
import { connectTrackingDetails } from 'src/features/tracking/duck/connectors'
import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'

const OfferDetails = ({
  className,
  setDrawerState,
  trackingDetails,
  timelineData,
  setConversationOpened,
  checkIfTheOfferBelongsToTheCurrentUser
}) => {
  const offerRemoved = !isNil(trackingDetails.deletedAt)
  const hasTracking =
    trackingDetails.doesRequireTracking || trackingDetails.doesAllowTracking
  const hasCustomsClearance = trackingDetails.customsClearanceLocation.id
  const hasContainer =
    trackingDetails.offer.container && trackingDetails.offer.container.name
  const hasContainerSemiTrailer =
    trackingDetails.offer.containerSemiTrailer &&
    trackingDetails.offer.containerSemiTrailer.name
  const hasNotes = trackingDetails.notes
  const hasPrice =
    trackingDetails.offer.price && trackingDetails.offer.price.float > 0
  const isCurrentUserCarrier = checkIfTheOfferBelongsToTheCurrentUser(
    trackingDetails.carrierUser.id
  )
  const isCurrentUserForwarder = checkIfTheOfferBelongsToTheCurrentUser(
    trackingDetails.forwarderUser.id
  )

  return (
    <div className={className}>
      <DrawerTitle
        offerDetails={trackingDetails.offer}
        setDrawerState={setDrawerState}
      />
      {offerRemoved && <OfferRemovedInfo />}
      {!offerRemoved && trackingDetails.offer.id && (
        <MiddleContainer>
          {hasTracking && <OfferHasTrackingInfo />}
          {timelineData && <Timeline data={timelineData} />}
          {hasCustomsClearance && (
            <CustomsClearance offerDetails={trackingDetails} />
          )}
          {hasContainer && (
            <ContainerInfo offerDetails={trackingDetails.offer} />
          )}
          {hasContainerSemiTrailer && (
            <ContainerSemiTrailerInfo offerDetails={trackingDetails.offer} />
          )}
          {hasNotes && <Notes offerDetails={trackingDetails} />}
          {hasPrice && <Price offerDetails={trackingDetails.offer} />}
          <UserTitle>
            <FormattedMessage id='tracking.carrier' />
          </UserTitle>
          <Contact
            issuer={trackingDetails.carrierUser}
            handler={trackingDetails.carrierUser}
            offerBelongsToCurrentUser={isCurrentUserCarrier}
            handleConversationStart={() => {
              setConversationOpened({
                receiverId: trackingDetails.carrierUserId,
                contextId: trackingDetails.offer.id,
                conversationOpened: true,
                contextType: trackingDetails.offerType
              })
            }}
          />
          <UserTitle>
            <FormattedMessage id='tracking.forwarder' />
          </UserTitle>
          <Contact
            offerBelongsToCurrentUser={isCurrentUserForwarder}
            issuer={trackingDetails.forwarderUser}
            handler={trackingDetails.forwarderUser}
            handleConversationStart={() => {
              setConversationOpened({
                receiverId: trackingDetails.forwarderUserId,
                contextId: trackingDetails.offer.id,
                conversationOpened: true,
                contextType: trackingDetails.offerType
              })
            }}
          />
          <CreatedDateOfferDetails offerDetails={trackingDetails.offer} />
        </MiddleContainer>
      )}
    </div>
  )
}

const StyledOfferDetails = styled(OfferDetails)`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 1.6rem 0 0 0;
  ${Title} {
    padding-bottom: 4px;
  }
`
export default compose(
  connectTrackingDetails,
  connectMessagesBasicData
)(StyledOfferDetails)

const UserTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.alabaster.hex()};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.hex()};
`
