import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { isFalsy } from 'ramda-adjunct'
import { makeStyles } from '@material-ui/core/styles'
import MomentUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { DATE_API_FORMAT } from 'src/ducks/consts'

const DatePicker = ({
  name,
  value,
  onChange,
  label,
  helperText,
  initialFocusedDate,
  required,
  placeholder,
  pristine,
  validate,
  disablePast,
  minDate,
  maxDate,
  disabled
}) => {
  const classes = useStyles()
  const [inputPristine, _setPristine] = useState(pristine)
  const [{ valid, error }, _validate] = useState({ valid: true, errors: [] })

  const handleChange = v => onChange(name, v && v.format(DATE_API_FORMAT))

  useEffect(() => {
    if (!inputPristine || !pristine) {
      validate(name, value, v => {
        _validate(() => ({ valid: v.valid, error: v.errors.join(' ') }))
      })
    }
  }, [value, inputPristine, pristine])

  return (
    <div className={classes.container}>
      <Label required={required}>{label}</Label>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          disabled={disabled}
          autoOk
          variant='inline'
          inputVariant='outlined'
          initialFocusedDate={initialFocusedDate}
          size='small'
          format='DD.MM.YYYY'
          margin='none'
          placeholder={placeholder}
          fullWidth
          value={value}
          onChange={handleChange}
          onBlur={() => _setPristine(false)}
          disablePast={disablePast}
          //default min date if not present
          minDate={isFalsy(minDate) ? '1900-01-01' : minDate}
          //default max date if not present
          maxDate={isFalsy(maxDate) ? '2100-01-01' : maxDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            size: 'small'
          }}
          helperText={null}
          InputProps={{
            classes: {
              root: classes.root,
              input: classes.input,
              inputMultiline: classes.inputMultiline,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
              focused: classes.inputLabelFocused,
              shrink: classes.inputLabelFocused
            }
          }}
        />
      </MuiPickersUtilsProvider>
      <HelperText valid={valid}>{valid ? helperText : error}</HelperText>
    </div>
  )
}

export default DatePicker

DatePicker.defaultProps = {
  onChange: () => {},
  validate: () => true
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%'
  },
  root: {
    backgroundColor: 'white',
    '&$focused': {
      boxShadow: '0 2px 10px 0 rgba(19, 162, 191, 0.25)'
    },
    '& $notchedOutline': {
      borderWidth: '1px',
      borderColor: '#c6cbd4',
      borderRadius: '2px'
    },
    '&:hover $notchedOutline': {},
    '&$focused $notchedOutline': {
      borderWidth: '1px',
      borderColor: '#13a2bf'
    }
  },
  input: {
    padding: '10px 0 10px 12px',
    height: '20px',
    color: '#585a59'
  },
  inputMultiline: {
    height: 'auto',
    resize: 'none'
  },
  label: {
    color: '#585a59',
    opacity: '0.7'
  },
  focused: {},
  notchedOutline: {},
  underline: {},
  helperText: {
    color: '#373e4c',
    fontSize: '0.9rem',
    fontWeight: 500,
    lineHeight: 1.33,
    opacity: 0.87,
    marginBottom: '1rem'
  },
  inputLabel: {},
  inputLabelFocused: {}
}))

const Label = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
      font-size: 0.75rem;
    }
  `}
`

const HelperText = styled.span`
  display: inline-block;
  width: 100%;
  margin: 8px 6px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1em;
  color: ${({ theme, valid }) =>
    valid ? theme.colors.ghost.hex() : theme.colors.error.hex()};
`
