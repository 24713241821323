import React from 'react'
import styled from 'styled-components'
import CustomIcon from 'src/components/CustomIcon'
import { FINISHED_OFFER_STATES } from 'src/features/myOffers/duck/consts'

const icons = {
  [FINISHED_OFFER_STATES.SOLD]: {
    icon: 'success',
    color: 'apple'
  },
  [FINISHED_OFFER_STATES.ANNULLED]: {
    icon: 'cancel',
    color: 'fadedRed'
  },
  [FINISHED_OFFER_STATES.FINISHED_INCONCLUSIVELY]: {
    icon: 'warning',
    color: 'california'
  },
  [FINISHED_OFFER_STATES.FINISHED_WITHOUT_RESERVATIONS]: {
    icon: 'cancel',
    color: 'corduroy'
  }
}
const StatusComponent = ({ status, className }) => (
  <div className={className}>
    {status && <CustomIcon name={icons[status].icon} size={24} />}
  </div>
)
export const Status = styled(StatusComponent)`
  flex-shrink: 0;
  color: ${({ theme, status }) =>
    status && theme.colors[icons[status].color].hex()};
  display: flex;
  align-items: center;
  & i {
    display: inline-flex;
  }
`
