import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import MUIIcon from '@material-ui/icons/KeyboardArrowDown'
import { breakpoint } from 'src/theme/grid'
import useClickOutside from 'src/hooks/useClickOutside'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import MUIAddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import XIcon from 'src/assets/icons/close-x.svg'
import { SaveOfferButton } from 'src/components/atoms/Buttons'
import Stepper from 'src/components/atoms/Stepper'
import { MAX_BULK_OFFER_NUMBER } from 'src/features/offers/duck/consts'

const CreateOfferButton = ({
  className,
  children,
  active,
  isLoading = false,
  createOffer,
  createBulkOffers,
  createAndDuplicateOffer,
  createAndStartNewOffer
}) => {
  const intl = useIntl()
  const [state, _setState] = useState({
    contextMenuOpened: false,
    multipleOffersDialogOpened: false,
    offersNumber: 1
  })

  const componentRef = useClickOutside(() =>
    setState({ contextMenuOpened: false })
  )

  const handleContextMenuToggle = e => {
    e.stopPropagation()
    setState({ contextMenuOpened: !state.contextMenuOpened })
  }

  const setState = (values = {}) => _setState(v => ({ ...v, ...values }))

  return (
    <>
      <Dialog
        open={state.multipleOffersDialogOpened}
        onClose={() =>
          setState({
            contextMenuOpened: false,
            multipleOffersDialogOpened: false
          })
        }
        PaperProps={{
          style: { borderRadius: 9 }
        }}
      >
        <DialogContent>
          <CloseIcon
            onClick={() =>
              setState({
                multipleOffersDialogOpened: false,
                contextMenuOpened: false
              })
            }
          />
          <DialogHeader>
            {intl.formatMessage({ id: 'forms.bulkOfferCreationTitle' })}
          </DialogHeader>
          <DialogText>
            {intl.formatMessage({ id: 'forms.bulkOfferCreationDescription' })}
          </DialogText>
          <Row>
            <StepperText>
              {intl.formatMessage({
                id: 'forms.bulkOfferCreationOfferNumberText'
              })}{' '}
              <span>
                <FormattedMessage
                  id='forms.bulkOfferCreationMaxNumber'
                  values={{
                    number: MAX_BULK_OFFER_NUMBER
                  }}
                />
              </span>
            </StepperText>
            <Stepper
              step={1}
              minValue={1}
              maxValue={MAX_BULK_OFFER_NUMBER}
              value={state.offersNumber}
              onChange={v => setState({ offersNumber: v })}
            />
          </Row>
          <ActionBar>
            <CancelButton
              onClick={() =>
                setState({
                  contextMenuOpened: false,
                  multipleOffersDialogOpened: false
                })
              }
            >
              {intl.formatMessage({ id: 'forms.bulkOfferCreationCancel' })}
            </CancelButton>
            <SaveOfferButton
              active={!isLoading}
              onClick={() => createBulkOffers(state.offersNumber)}
            >
              <FormattedMessage id='forms.bulkOfferCreationAddXOffers' />
              <AddIcon />
            </SaveOfferButton>
          </ActionBar>
        </DialogContent>
      </Dialog>
      <div className={className} onClick={createOffer} ref={componentRef}>
        <span>{children}</span>
        <ContextMenuButtonButton
          active={active && !isLoading}
          onClick={handleContextMenuToggle}
        >
          <ArrowIcon />
          <ContextMenu active={state.contextMenuOpened}>
            <ContextMenuElement
              onClick={() => {
                createAndStartNewOffer()
              }}
            >
              {intl.formatMessage({ id: 'forms.saveAndAddAnother' })}
            </ContextMenuElement>
            <ContextMenuElement
              onClick={() => {
                createAndDuplicateOffer()
              }}
            >
              {intl.formatMessage({ id: 'forms.saveAndDuplicate' })}
            </ContextMenuElement>
            <ContextMenuElement
              onClick={() => {
                setState({
                  multipleOffersDialogOpened: true,
                  contextMenuOpened: false
                })
              }}
            >
              {intl.formatMessage({ id: 'forms.addMultipleOffers' })}
            </ContextMenuElement>
          </ContextMenu>
        </ContextMenuButtonButton>
      </div>
    </>
  )
}

export default styled(CreateOfferButton)`
  font-size: 1rem;
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  overflow: visible;
  padding: 0 1.2rem;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.mercury.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  pointer-events: none;
  position: relative;
  padding-right: 52px;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      cursor: pointer;
      color: white;
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      box-shadow: 0 2px 10px 0 rgba(71, 170, 66, 0.25);
    `}
`

const ArrowIcon = styled(MUIIcon)`
  color: white !important;
  font-size: 1.2rem;
`

const ContextMenuButtonButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.corduroy.darken(0.3).hex()};
  pointer-events: none;
  position: absolute;
  right: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      pointer-events: unset;
      background-color: ${({ theme }) => theme.colors.apple.darken(0.3).hex()};
    `}
`

const ContextMenu = styled.div`
  width: 240px;
  box-sizing: border-box;
  padding: 0.6rem 1rem 0.8rem 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  position: absolute;
  bottom: 56px;
  right: 0;
  display: none;
  flex-direction: column;
  ${({ active }) =>
    active &&
    `
      display: flex;
  `}
  ${breakpoint.s`
    right: 0;
  `}
`

export const ContextMenuElement = styled.div`
  display: flex;
  width: 100%;
  font-size: 1rem;
  padding: 0.6rem 0;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.7;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
  > span {
    margin-left: 0.8rem;
  }
`

const CloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`

const DialogContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem;
  ${breakpoint.m`
    min-height: 260px;
    width: 600px;
    padding: 1.6rem 2.2rem;
  `}
`

const DialogHeader = styled.h3`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  margin: 0.8rem 0 1.2rem 0;
  width: 100%;
  display: inline-block;
`

const DialogText = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 400;
  line-height: 1.71;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
`

const StepperText = styled(DialogText)`
  width: auto;
  margin-right: 1.2rem;
  span {
    opacity: 0.6;
    font-size: 0.79rem;
  }
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  ${breakpoint.xs`
    justify-content: flex-end;
  `}
  ${breakpoint.m`
    margin-top: 0.8rem;
    margin-bottom: 0;
  `}
`

const AddIcon = styled(MUIAddIcon)`
  font-size: 1.25rem;
  margin-left: 0.6rem;
`

const CancelButton = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
  margin-right: 0.8rem;
  align-items: center;
  ${breakpoint.m`
    margin-right: 2.2rem;
  `}
`

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.8rem 0 2.4rem 0;
  ${Stepper} {
    margin-top: 0.8rem;
    ${breakpoint.m`
      margin-top: 0;
    `}
  }
`
