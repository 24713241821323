import { connect } from 'react-redux'
import {
  selectContractsFilters,
  selectContractsOfferDetails,
  selectContractsOffers,
  selectContractsOfferTimelineData,
  selectContractsPagination,
  selectContractsStock,
  selectIsContractsDrawerOpen,
  selectMyContractsFinishedFilters,
  selectMyContractsFinishedPagination,
  selectMyContractsOffersFinished,
  selectMyContractsOffersOngoing,
  selectMyOffersOnGoing,
  selectMyContractsOngoingFilters,
  selectMyContractsOngoingPagination,
  selectMyContractsReservationsFinished,
  selectMyContractsReservationsFinishedFilters,
  selectMyContractsReservationsFinishedPagination,
  selectMyContractsReservationsOngoing,
  selectMyContractsReservationsOngoingFilters,
  selectMyContractsReservationsOngoingPagination,
  selectMyOffersContractsFinishedButtonsState,
  selectMyOffersContractsOngoingButtonsState,
  selectMyReservationsContractsFinishedButtonsState,
  selectMyReservationsContractsOngoingButtonsState,
  selectContractsReservationsFinished,
  selectContractsReservationsOngoing,
  selectContractsButtonsState
} from 'src/features/contracts/duck/selectors'
import {
  acceptContractReservationRoutine,
  getContractOfferRoutine,
  getContractOffersListRoutine,
  getMyFinishedContractOffersRoutine,
  getMyOngoingContractOffersRoutine,
  reserveContractOfferRoutine,
  setContractDrawerRoutine,
  setContractOffersFiltersRoutine,
  showFinishedContractOfferReservationsRoutine,
  showOngoingContractOfferReservationsRoutine,
  updateContractOfferRoutine,
  setMyFinishedContractOffersFiltersRoutine,
  setMyOngoingContractOffersFiltersRoutine,
  getMyOngoingContractReservationsRoutine,
  getMyFinishedContractReservationsRoutine,
  setMyOngoingContractReservationsFiltersRoutine,
  setMyFinishedContractReservationsFiltersRoutine,
  cancelContractReservationRoutine,
  deleteContractOfferRoutine,
  cleanContractsDetailsRoutine
} from 'src/features/contracts/duck/actions'

import {
  selectCurrentUserCompanyId,
  selectCurrentUserId
} from 'src/features/account/duck/selectors'

import { setConversationOpenedRoutine } from 'src/features/messages/duck/actions'

export const connectContracts = connect(
  state => ({
    offers: selectContractsOffers(state),
    filters: selectContractsFilters(state),
    pagination: selectContractsPagination(state),
    offerDetails: selectContractsOfferDetails(state),
    timelineData: selectContractsOfferTimelineData(state),
    isDrawerOpen: selectIsContractsDrawerOpen(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    stock: selectContractsStock(state),
    buttonsState: selectContractsButtonsState(state)
  }),
  dispatch => ({
    getContractOffersList: () => dispatch(getContractOffersListRoutine()),
    setFilter: (name, value) =>
      dispatch(setContractOffersFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setContractDrawerRoutine({ drawerState })),
    cleanContractDetails: () => dispatch(cleanContractsDetailsRoutine()),
    showOffer: (id, query) => dispatch(getContractOfferRoutine({ id, query })),
    updateOffer: (id, values) =>
      dispatch(updateContractOfferRoutine({ id, values })),
    reserveOffer: (id, values) =>
      dispatch(reserveContractOfferRoutine({ id, values })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    getOngoingOffers: () => dispatch(getMyOngoingContractOffersRoutine()),
    getFinishedOffers: () => dispatch(getMyFinishedContractOffersRoutine()),
    getOngoingReservations: () =>
      dispatch(getMyOngoingContractReservationsRoutine()),
    deleteOffer: id => dispatch(deleteContractOfferRoutine({ id })),
    showOngoingContractReservations: id =>
      dispatch(showOngoingContractOfferReservationsRoutine({ id })),
    showFinishedContractReservations: id =>
      dispatch(showFinishedContractOfferReservationsRoutine({ id })),
    getFinishedReservations: () =>
      dispatch(getMyFinishedContractReservationsRoutine()),
    acceptReservation: payload =>
      dispatch(acceptContractReservationRoutine(payload))
  })
)

export const connectMyContractsOffersOngoing = connect(
  state => ({
    myOffersOnGoing: selectMyOffersOnGoing(state),
    offers: selectMyContractsOffersOngoing(state),
    filters: selectMyContractsOngoingFilters(state),
    pagination: selectMyContractsOngoingPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    stock: selectContractsStock(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersContractsOngoingButtonsState(state)
  }),
  dispatch => ({
    showOffer: id => dispatch(getContractOfferRoutine({ id })),
    setFilter: (name, value) =>
      dispatch(setMyOngoingContractOffersFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setContractDrawerRoutine({ drawerState })),
    getOngoingOffers: () => dispatch(getMyOngoingContractOffersRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    showOffersReservations: id =>
      dispatch(showOngoingContractOfferReservationsRoutine({ id })),
    deleteOffer: id => dispatch(deleteContractOfferRoutine({ id })),
    acceptReservation: payload =>
      dispatch(acceptContractReservationRoutine(payload))
  })
)

export const connectMyContractsOffersFinished = connect(
  state => ({
    offers: selectMyContractsOffersFinished(state),
    filters: selectMyContractsFinishedFilters(state),
    pagination: selectMyContractsFinishedPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    stock: selectContractsStock(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyOffersContractsFinishedButtonsState(state)
  }),
  dispatch => ({
    showOffer: id => dispatch(getContractOfferRoutine({ id })),
    setFilter: (name, value) =>
      dispatch(setMyFinishedContractOffersFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setContractDrawerRoutine({ drawerState })),
    getFinishedOffers: () => dispatch(getMyFinishedContractOffersRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    showOffersReservations: id =>
      dispatch(showFinishedContractOfferReservationsRoutine({ id }))
  })
)

export const connectMyContractsReservationsOngoing = connect(
  state => ({
    ongoingOffers: selectContractsReservationsOngoing(state),
    offers: selectMyContractsReservationsOngoing(state),
    filters: selectMyContractsReservationsOngoingFilters(state),
    pagination: selectMyContractsReservationsOngoingPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    stock: selectContractsStock(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyReservationsContractsOngoingButtonsState(state)
  }),
  dispatch => ({
    showOffer: id => dispatch(getContractOfferRoutine({ id })),
    setFilter: (name, value) =>
      dispatch(setMyOngoingContractReservationsFiltersRoutine({ name, value })),
    setDrawerState: drawerState =>
      dispatch(setContractDrawerRoutine({ drawerState })),
    getOngoingReservations: () =>
      dispatch(getMyOngoingContractReservationsRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    cancelReservation: payload =>
      dispatch(cancelContractReservationRoutine(payload))
  })
)

export const connectMyContractsReservationsFinished = connect(
  state => ({
    finishedReservations: selectContractsReservationsFinished(state),
    offers: selectMyContractsReservationsFinished(state),
    filters: selectMyContractsReservationsFinishedFilters(state),
    pagination: selectMyContractsReservationsFinishedPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    stock: selectContractsStock(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectMyReservationsContractsFinishedButtonsState(state)
  }),
  dispatch => ({
    showOffer: id => dispatch(getContractOfferRoutine({ id })),
    setFilter: (name, value) =>
      dispatch(
        setMyFinishedContractReservationsFiltersRoutine({ name, value })
      ),
    setDrawerState: drawerState =>
      dispatch(setContractDrawerRoutine({ drawerState })),
    getFinishedReservations: () =>
      dispatch(getMyFinishedContractReservationsRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)
