import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import Tabs from 'src/components/Tabs'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import Alert from 'src/components/Alert'
import { Page } from 'src/components/Wrappers'
import StockHeader from 'src/features/stocks/components/StockHeader'
import { breakpoint } from 'src/theme/grid'

import { connectNotificationsBasics } from 'src/features/notifications/duck/connectors'
import { NotificationsBox } from './components/atoms'
import Section from './components/Section'
import NotificationSetting from './components/NotificationSetting'

const Settings = ({
  className,
  getNotificationsSettings,
  notificationsSettings,
  updateNotificationSetting
}) => {
  const intl = useIntl()
  useEffect(() => {
    getNotificationsSettings()
  }, [])

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'settings.pageTitle'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Tabs list={[intl.formatMessage({ id: 'settings.notifications' })]}>
        <TabContent>
          <Alert
            fullWidth
            type='info'
            message={intl.formatMessage({
              id: 'settings.alertInformationText'
            })}
          />
          <NotificationsBox>
            {notificationsSettings.map(setting => (
              <Section label={setting.label} icon={setting.type}>
                <LabelGroup>
                  <Label>WWW</Label>
                  <Label>E-mail</Label>
                  <Label>Mobile</Label>
                </LabelGroup>
                {setting.values.map(value => (
                  <NotificationSetting
                    settings={value}
                    updateNotificationSetting={updateNotificationSetting}
                  />
                ))}
              </Section>
            ))}
          </NotificationsBox>
        </TabContent>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = connectNotificationsBasics(Settings)
export default styled(ConnectedComponent)`
  ${Tabs} {
    & > div {
      padding: 0.8rem 1.2rem 0 1.2rem;
      background: transparent;
    }
    & span {
      font-weight: 400;
    }
  }
`

const TabContent = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.8rem;
  ${breakpoint.m`
    padding: 0 1.6rem;
  `}
`

const LabelGroup = styled.div`
  display: none;
  width: 180px;
  position: absolute;
  right: 3.2rem;
  top: 0;
  ${breakpoint.m`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`

const Label = styled.span`
  display: inline-block;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
  width: 40px;
  text-align: center;
`
