import { connect } from 'react-redux'
import {
  selectCurrentUserCompanyId,
  selectCurrentUserId,
  selectIsCarrierForwarder
} from 'src/features/account/duck/selectors'
import { setConversationOpenedRoutine } from 'src/features/messages/duck/actions'
import {
  selectDrivers,
  selectTrackingDetails,
  selectTrackingEditForm,
  selectTrackingFinished,
  selectTrackingFinishedButtonsState,
  selectTrackingFinishedFilters,
  selectTrackingFinishedPagination,
  selectTrackingOngoingButtonsState,
  selectTrackingOngoingFilters,
  selectTrackingOngoingPagination,
  selectTrackingPinCodeState,
  selectTrackingTimelineData,
  selectTrackingOngoingRecords,
  selectTrackingFinishedRecords
} from 'src/features/tracking/duck/selectors'
import {
  deleteTrackingFileRoutine,
  generateDriverCredentialsRoutine,
  getTrackingListFinishedRoutine,
  setEditTrackingFormRoutine,
  setTrackingFinishedFiltersRoutine,
  setTrackingTransportationTypeRoutine,
  updateTrackingRoutine,
  uploadTrackingFilesRoutine,
  getTrackingEventFileRoutine,
  getDriversRoutine,
  clearEditFormRoutine
} from 'src/features/tracking/duck/actions'
import { curry } from 'ramda'
import { prepareOrderRoutine } from 'src/features/orders/duck/actions'
import {
  getTrackingListOngoingRoutine,
  getTrackingDetailsRoutine,
  setTrackingOngoingFiltersRoutine
} from './actions'
import { selectTrackingOngoing } from './selectors'

export const connectTrackingOngoing = connect(
  state => ({
    ongoingOffers: selectTrackingOngoingRecords(state),
    offers: selectTrackingOngoing(state),
    filters: selectTrackingOngoingFilters(state),
    pagination: selectTrackingOngoingPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectTrackingOngoingButtonsState(state),
    isCurrentCompanyCarrierForwarder: selectIsCarrierForwarder(state)
  }),
  dispatch => ({
    showOffer: id => dispatch(getTrackingDetailsRoutine({ id })),
    setFilter: (name, value) =>
      dispatch(setTrackingOngoingFiltersRoutine({ name, value })),
    getOngoingOffers: () => dispatch(getTrackingListOngoingRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectTrackingFinished = connect(
  state => ({
    finishedOffers: selectTrackingFinishedRecords(state),
    offers: selectTrackingFinished(state),
    filters: selectTrackingFinishedFilters(state),
    pagination: selectTrackingFinishedPagination(state),
    checkIfTheOfferBelongsToTheCurrentCompany: id =>
      id === selectCurrentUserCompanyId(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state),
    currentUserId: selectCurrentUserId(state),
    buttonsState: selectTrackingFinishedButtonsState(state),
    isCurrentCompanyCarrierForwarder: selectIsCarrierForwarder(state)
  }),
  dispatch => ({
    showOffer: id => dispatch(getTrackingDetailsRoutine({ id })),
    setFilter: (name, value) =>
      dispatch(setTrackingFinishedFiltersRoutine({ name, value })),
    getFinishedOffers: () => dispatch(getTrackingListFinishedRoutine()),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload))
  })
)

export const connectTrackingEdit = connect(
  state => ({
    trackingEditFrom: selectTrackingEditForm(state),
    trackingItem: selectTrackingDetails(state),
    currentUserId: selectCurrentUserId(state),
    drivers: selectDrivers(state)
  }),
  dispatch => ({
    getDrivers: () => dispatch(getDriversRoutine()),
    setTrackingEditFormValue: (name, value) =>
      dispatch(setEditTrackingFormRoutine({ name, value })),
    getTrackingItem: id => dispatch(getTrackingDetailsRoutine({ id })),
    updateTracking: ({ id, values }) =>
      dispatch(updateTrackingRoutine({ id, values })),
    setTrackingTransportationType: ({ id, values }) =>
      dispatch(setTrackingTransportationTypeRoutine({ id, values })),
    uploadTrackingFiles: curry((id, files) =>
      dispatch(uploadTrackingFilesRoutine({ id, files }))
    ),
    deleteTrackingFile: curry((trackingId, fileId) =>
      dispatch(deleteTrackingFileRoutine({ trackingId, fileId }))
    ),
    clearEditTrackingForm: () => dispatch(clearEditFormRoutine())
  })
)

export const connectTrackingDetails = connect(
  state => ({
    trackingDetails: selectTrackingDetails(state),
    timelineData: selectTrackingTimelineData(state),
    pinCodeState: selectTrackingPinCodeState(state),
    checkIfTheOfferBelongsToTheCurrentUser: offerId =>
      offerId === selectCurrentUserId(state)
  }),
  dispatch => ({
    getFinishedOffers: () => dispatch(getTrackingListFinishedRoutine()),
    getOngoingOffers: () => dispatch(getTrackingListOngoingRoutine()),
    getTrackingItem: id => dispatch(getTrackingDetailsRoutine({ id })),
    generateDriverCredentials: id =>
      dispatch(generateDriverCredentialsRoutine({ id }))
  })
)

export default connect(
  state => ({
    trackingItem: selectTrackingDetails(state),
    timelineData: selectTrackingTimelineData(state),
    pinCodeState: selectTrackingPinCodeState(state),
    currentUserId: selectCurrentUserId(state)
  }),
  dispatch => ({
    prepareOrder: (id, offerType) =>
      dispatch(prepareOrderRoutine({ id, offerType })),
    getFinishedOffers: () => dispatch(getTrackingListFinishedRoutine()),
    getOngoingOffers: () => dispatch(getTrackingListOngoingRoutine()),
    getTrackingItem: id => dispatch(getTrackingDetailsRoutine({ id })),
    generateDriverCredentials: id =>
      dispatch(generateDriverCredentialsRoutine({ id })),
    getTrackingEventFile: url => dispatch(getTrackingEventFileRoutine({ url }))
  })
)
