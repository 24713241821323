import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, Link, useIntl, navigate } from 'gatsby-plugin-intl'
import moment from 'moment'
import MUIAddIcon from '@material-ui/icons/Add'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroup,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection
} from 'src/features/offers/components/atoms'
import { breakpoint } from 'src/theme/grid'
import Drawer from 'src/features/stocks/components/Drawer'
import OrderOfferDetailsDrawer from 'src/features/orders/components/OrderOfferDetailsDrawer'
import {
  AcceptButton,
  ButtonDark,
  EditButton,
  RejectOfferButton
} from 'src/components/atoms/Buttons'
import { connectOrderDetails } from 'src/features/orders/duck/connectors'
import useUrlParams from 'src/hooks/useUrlParams'
import {
  AlertContainer,
  BigInputContainer,
  GeneratePdf,
  Heading,
  HeadingContainer,
  InputRow,
  OfferType,
  OfferTypeWrapper,
  OrderSubtitle,
  Property,
  SmallInputContainer
} from 'src/features/orders/components/atoms'
import { formatOrderReferenceNumber } from 'src/utils/helpers'
import { TitleContainer } from 'src/features/account/components/atoms'
import { Title } from 'src/features/account/components/typography'
import CustomIcon from 'src/components/CustomIcon'
import {
  getIssuerAndContractorFromOrderDetails,
  translateCustomsClearanceType
} from 'src/features/orders/duck/helpers'
import { ContactRow } from 'src/features/orders/components/ContactRow'
import { DATE_FORMAT } from 'src/ducks/consts'
import Alert from 'src/components/Alert'
import PdfIcon from 'src/assets/icons/pdf.svg'
import ConfirmDialog from 'src/components/ConfirmDialog'
import Shade from 'src/components/Shade'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'

const OrderDetails = ({
  className,
  showOrder,
  orderDetails,
  currentUser,
  rejectOrder,
  downloadOrderPdf,
  acceptOrder,
  clearOrderDetails,
  prepareInvoice
}) => {
  const intl = useIntl()
  const [isRejectDialogOpen, setRejectDialogOpen] = useState(false)
  const { id } = useUrlParams()
  useEffect(() => {
    id && showOrder(formatOrderReferenceNumber(id))
  }, [id])

  useEffect(
    () => () => {
      clearOrderDetails()
    },
    []
  )

  const { issuer, contractor } = getIssuerAndContractorFromOrderDetails(
    orderDetails
  )

  const isCurrentUserContractor =
    contractor.company.id === currentUser.company.id
  const isCurrentUserIssuer = issuer.company.id === currentUser.company.id
  const isAccepted = !!orderDetails.acceptedAt
  const isRejected = !!orderDetails.rejectedAt

  const actionsAvailable = isCurrentUserContractor && !isAccepted && !isRejected

  const isUpdatable = isCurrentUserIssuer && isRejected

  const permissions = useContext(PermissionsContext)
  const hasPermissionToCreateInvoiceForCompanyOrders = hasPermissionTo(
    PERMISSIONS.INVOICE_CREATE_TRANSPORTATION_ORDER_ANY,
    permissions
  )
  const isCurrentUserOrderOwner = currentUser.id === orderDetails.carrierUserId

  const isCurrentCompanyOrderOwner =
    orderDetails.carrierCompanyId === currentUser.company.id

  const canGenerateInvoice =
    isAccepted &&
    (isCurrentUserOrderOwner ||
      (isCurrentCompanyOrderOwner &&
        hasPermissionToCreateInvoiceForCompanyOrders))

  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                <FormattedMessage id='orders.transportationOrder' />
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          {!orderDetails.id && <Shade />}
          <FormSection>
            <FormInnerWrapper>
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'orders.orders' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDERS}`
                    },
                    {
                      name: (
                        <FormattedMessage
                          id='orders.order'
                          values={{ orderId: orderDetails.referenceNumber }}
                        />
                      ),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDERS}`
                    }
                  ]}
                />
                <HeadingContainer>
                  <Heading>
                    <FormattedMessage
                      id='orders.order'
                      values={{ orderId: orderDetails.referenceNumber }}
                    />
                  </Heading>
                  {orderDetails.acceptedAt && (
                    <GeneratePdf
                      onClick={() => {
                        downloadOrderPdf(orderDetails.id)
                      }}
                    >
                      <PdfIcon />
                      <FormattedMessage id='orders.generatePdf' />
                    </GeneratePdf>
                  )}
                </HeadingContainer>
                <AlertContainer>
                  {actionsAvailable && (
                    <Alert
                      type='warning'
                      message={<FormattedMessage id='orders.newOrderInfo' />}
                    />
                  )}
                  {isAccepted && (
                    <Alert
                      type='success'
                      message={
                        <FormattedMessage id='orders.acceptedOrderInfo' />
                      }
                    />
                  )}
                  {isRejected && !isUpdatable && (
                    <Alert
                      type='warning'
                      message={
                        <FormattedMessage id='orders.rejectedOrderInfo' />
                      }
                    />
                  )}
                  {isUpdatable && (
                    <Alert
                      type='warning'
                      message={
                        <p>
                          <FormattedMessage id='orders.rejectedOrderMessageForIssuer' />
                        </p>
                      }
                    />
                  )}
                  {orderDetails.rejectionReason && (
                    <Alert
                      type='geyser'
                      message={orderDetails.rejectionReason}
                      title={<FormattedMessage id='orders.rejectionReason' />}
                      icon={<div />}
                    />
                  )}
                </AlertContainer>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.relations' />
                  </Title>
                </TitleContainer>
                {orderDetails.transportationType && (
                  <OfferTypeWrapper>
                    <CustomIcon
                      name={orderDetails.transportationType}
                      size={24}
                    />
                    <OfferType>
                      <FormattedMessage
                        id={`common.${orderDetails.transportationType}`}
                        defaultMessage='-'
                      />
                    </OfferType>
                  </OfferTypeWrapper>
                )}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.issuer' />
                  </Title>
                </TitleContainer>
                {issuer.id && <ContactRow user={issuer} />}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.contractor' />
                  </Title>
                </TitleContainer>
                {contractor.id && <ContactRow user={contractor} />}
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.cargo' />
                  </Title>
                </TitleContainer>
                <InputRow>
                  <BigInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.containerNumber' />}
                    >
                      {orderDetails.containerNumber || '-'}
                    </Property>
                  </BigInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.containerType' />}
                    >
                      {orderDetails.container.name || '-'}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.shipowner' />}
                    >
                      {orderDetails.shipowner.name || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='orders.weight' />}>
                      {orderDetails.weight || '-'}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.sealNumber' />}
                    >
                      {orderDetails.sealNumber || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.line' />
                  </Title>
                </TitleContainer>
                <InputRow>
                  <OrderSubtitle>
                    <FormattedMessage id='orders.pickUp' />
                  </OrderSubtitle>
                </InputRow>
                <InputRow>
                  <BigInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.pickUpAddress' />}
                    >
                      {orderDetails.pickUpAddress || '-'}
                    </Property>
                  </BigInputContainer>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='orders.pin' />}>
                      {orderDetails.pin || '-'}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.pickUpRef' />}
                    >
                      {orderDetails.pickUpRef || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <OrderSubtitle>
                    <FormattedMessage id='orders.return' />
                  </OrderSubtitle>
                </InputRow>
                <InputRow>
                  <BigInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.returnAddress' />}
                    >
                      {orderDetails.returnAddress || '-'}
                    </Property>
                  </BigInputContainer>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='orders.booking' />}>
                      {orderDetails.booking || '-'}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.returnRef' />}
                    >
                      {orderDetails.returnRef || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <OrderSubtitle>
                    <FormattedMessage id='orders.loadingUnloading' />
                  </OrderSubtitle>
                </InputRow>
                <InputRow>
                  <BigInputContainer>
                    <Property title={<FormattedMessage id='orders.address' />}>
                      {orderDetails.loadingUnloadingAddress || '-'}
                    </Property>
                  </BigInputContainer>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='forms.date' />}>
                      {orderDetails.loadingUnloadingDate &&
                        moment(orderDetails.loadingUnloadingDate).format(
                          DATE_FORMAT
                        )}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='forms.time' />}>
                      {orderDetails.loadingUnloadingTime || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <SmallInputContainer>
                    <Property
                      title={
                        <FormattedMessage id='orders.loadingUnloadingCompanyName' />
                      }
                    >
                      {orderDetails.loadingUnloadingCompanyName || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <SmallInputContainer>
                    <Property
                      title={
                        <FormattedMessage id='orders.customsClearanceType' />
                      }
                    >
                      {intl.formatMessage({
                        id:
                          translateCustomsClearanceType(
                            orderDetails.customsClearanceType
                          ) || '-'
                      })}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={
                        <FormattedMessage id='orders.customsClearanceAddress' />
                      }
                    >
                      {orderDetails.customsClearanceAddress || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.transport' />
                  </Title>
                </TitleContainer>
                <InputRow>
                  <OrderSubtitle>
                    <FormattedMessage id='orders.driverData' />
                  </OrderSubtitle>
                </InputRow>
                <InputRow>
                  <BigInputContainer>
                    <Property
                      title={<FormattedMessage id='myCompany.users.name' />}
                    >
                      {orderDetails.driverName || '-'}
                    </Property>
                  </BigInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={
                        <FormattedMessage id='tracking.driverIdentityCardNumber' />
                      }
                    >
                      {orderDetails.driverIdentityCardNumber || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <OrderSubtitle>
                    <FormattedMessage id='orders.vehicleAndPrice' />
                  </OrderSubtitle>
                </InputRow>
                <InputRow>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.vehicleNumber' />}
                    >
                      {orderDetails.vehicleRegistrationPlate || '-'}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.semitrailerNumber' />}
                    >
                      {orderDetails.containerSemiTrailerRegistrationPlate ||
                        '-'}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='forms.price' />}>
                      {orderDetails.price.formatted || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.commodity' />
                  </Title>
                </TitleContainer>
                <InputRow>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='orders.name' />}>
                      {orderDetails.cargoName || '-'}
                    </Property>
                  </SmallInputContainer>
                  <BigInputContainer>
                    <Property title={<FormattedMessage id='orders.adrClass' />}>
                      {orderDetails.adrClass.name || '-'}
                    </Property>
                  </BigInputContainer>
                  <SmallInputContainer>
                    <Property title={<FormattedMessage id='orders.unNumber' />}>
                      {orderDetails.adrClassUn || '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id='orders.orderInfo' />
                  </Title>
                </TitleContainer>
                <InputRow>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.issuePlace' />}
                    >
                      {orderDetails.issuePlace || '-'}
                    </Property>
                  </SmallInputContainer>
                  <SmallInputContainer>
                    <Property
                      title={<FormattedMessage id='orders.issueDate' />}
                    >
                      {orderDetails.createdAt
                        ? moment(orderDetails.createdAt).format(DATE_FORMAT)
                        : '-'}
                    </Property>
                  </SmallInputContainer>
                </InputRow>
                <InputRow>
                  <Property
                    title={<FormattedMessage id='orders.additionalInfo' />}
                  >
                    {orderDetails.additionalInfo || '-'}
                  </Property>
                </InputRow>
                <InputRow>
                  <Property title={<FormattedMessage id='orders.conditions' />}>
                    {orderDetails.conditions || '-'}
                  </Property>
                </InputRow>
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDERS}`}>
                {intl.formatMessage({ id: 'orders.goBack' })}
              </Link>
              {actionsAvailable && (
                <ActionGroup>
                  <AcceptButton
                    active
                    onClick={() => acceptOrder(orderDetails.id)}
                  />
                  <RejectOfferButton
                    active
                    onClick={() => setRejectDialogOpen(true)}
                    title={<FormattedMessage id='orders.reject' />}
                  />
                </ActionGroup>
              )}
              {canGenerateInvoice && (
                <ActionGroup>
                  <ButtonDark
                    active={true}
                    onClick={() => {
                      prepareInvoice({ transportationOrderId: orderDetails.id })
                    }}
                  >
                    <FormattedMessage id='invoices.addInvoice' />
                    <AddIcon />
                  </ButtonDark>
                </ActionGroup>
              )}
              {isUpdatable && (
                <ActionGroup>
                  <EditButton
                    active
                    onClick={() =>
                      navigate(
                        `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_EDIT}&id=${orderDetails.referenceNumber}`
                      )
                    }
                    title={<FormattedMessage id='common.edit' />}
                  />
                </ActionGroup>
              )}
            </FormActionBar>
          </FormSection>
          <Drawer open={true} onClose={() => {}}>
            <OrderOfferDetailsDrawer />
          </Drawer>
        </Content>
      </Page>
      <ConfirmDialog
        withReason
        confirmButton={RejectOfferButton}
        confirmButtonTitle={<FormattedMessage id='orders.reject' />}
        open={isRejectDialogOpen}
        setOpen={setRejectDialogOpen}
        onConfirm={reason => {
          rejectOrder({ reason, id: orderDetails.id })
        }}
        message={<FormattedMessage id='orders.rejectOrderMessage' />}
        title={
          <FormattedMessage
            id='orders.rejectOrderTitle'
            values={{
              orderId: orderDetails.referenceNumber
            }}
          />
        }
        inputTitle={intl.formatMessage({
          id: 'orders.rejectOrderInputTitle'
        })}
        inputPlaceholder={intl.formatMessage({
          id: 'orders.rejectOrderInputPlaceholder'
        })}
      />
    </>
  )
}

const ConnectedComponent = connectOrderDetails(OrderDetails)

export default styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
  }
  ${Padding} {
    max-width: calc(626px + 2.8rem);
    margin: 0 auto;
  }
  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
`

const AddIcon = styled(MUIAddIcon)`
  font-size: 24px;
`
