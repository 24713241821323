import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Dot } from 'src/features/account/components/atoms'

const icons = {
  new: {
    color: 'california'
  },
  accepted: {
    color: 'apple'
  },
  rejected: {
    color: 'fadedRed'
  }
}

const StatusComponent = ({ status, className }) => (
  <div className={className}>
    {status && <Dot color={icons[status].color} />}{' '}
  </div>
)

export default styled(StatusComponent)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`

StatusComponent.propTypes = {
  status: PropTypes.oneOf(['rejected', 'accepted', 'new']),
  className: PropTypes.string
}

StatusComponent.defaultProps = {
  status: 'new',
  className: ''
}
