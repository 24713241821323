import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import useValidateSchema from 'src/hooks/useValidateSchema'
import TextInput from 'src/components/atoms/TextInput'
import {
  InputsRowWrapper,
  InputWrapper
} from 'src/components/atoms/FormHelpers'
import { SaveButtonWithLoader as SaveButton } from 'src/components/atoms/Buttons'
import { connectChangePassword } from 'src/features/account/duck/connectors'
import { API_STATES } from 'src/ducks/consts'
import { ButtonsRow } from 'src/features/account/components/atoms'
import {
  validatePasswordSchema,
  validatePassword,
  validatePasswordConfirmation
} from 'src/features/account/duck/schema'

const PasswordChangeForm = ({
  className,
  changePassword,
  changePasswordStatus: { state }
}) => {
  const intl = useIntl()
  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })

  const isLoading = state === API_STATES.IN_PROGRESS
  const valid = useValidateSchema(password, validatePasswordSchema)

  const handleSave = () => {
    changePassword(password)
  }

  const handleChange = (name, value) =>
    setPassword({ ...password, [name]: value })

  return (
    <div className={className}>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            label={intl.formatMessage({
              id: 'account.currentPasswordLabel'
            })}
            id='currentPassword'
            name='currentPassword'
            type='password'
            placeholder={intl.formatMessage({
              id: 'account.currentPasswordPlaceholder'
            })}
            value={password.currentPassword}
            onChange={handleChange}
            validate={validatePassword}
          />
        </InputWrapper>
      </InputsRowWrapper>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            label={intl.formatMessage({
              id: 'account.newPasswordLabel'
            })}
            id='newPassword'
            name='newPassword'
            type='password'
            placeholder={intl.formatMessage({
              id: 'account.newPasswordPlaceholder'
            })}
            value={password.newPassword}
            onChange={handleChange}
            validate={validatePassword}
          />
        </InputWrapper>
      </InputsRowWrapper>
      <InputsRowWrapper>
        <InputWrapper width={12}>
          <TextInput
            label={intl.formatMessage({
              id: 'account.confirmPasswordLabel'
            })}
            id='newPasswordConfirmation'
            name='newPasswordConfirmation'
            type='password'
            placeholder={intl.formatMessage({
              id: 'account.confirmPasswordPlaceholder'
            })}
            value={password.newPasswordConfirmation}
            onChange={handleChange}
            validate={(name, value, callback) =>
              validatePasswordConfirmation(
                {
                  [name]: value,
                  newPassword: password.newPassword,
                  currentPassword: password.currentPassword
                },
                callback
              )
            }
          />
        </InputWrapper>
      </InputsRowWrapper>
      <ButtonsRow>
        <SaveButton
          active={valid && !isLoading}
          loading={isLoading}
          onClick={handleSave}
        >
          {intl.formatMessage({ id: 'account.changePasswordButton' })}
        </SaveButton>
      </ButtonsRow>
    </div>
  )
}
const StyledPasswordChange = styled(PasswordChangeForm)`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 400px;
`

export default connectChangePassword(StyledPasswordChange)
