import React from 'react'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import Pagination from 'src/components/Pagination'
import LoadingList from 'src/components/atoms/LoadingList'

import Shade from 'src/components/Shade'

import { isStockLoading } from 'src/utils/stocks'

import {
  BottomRow,
  Container,
  Counter,
  Ellipsis,
  EmptyOffers,
  RefreshIcon,
  Row
} from 'src/features/stocks/components/atoms'

import {
  ActionGroup,
  HeaderItem,
  Padding
} from 'src/features/myOffers/components/atoms'
import { Icon, SectionBox } from 'src/features/account/components/atoms'
import ArrowIcon from 'src/assets/icons/arrow-down.svg'
import { TableHeader } from 'src/features/contracts/components/atoms'

import Select from 'src/components/filters/SelectBox'
import {
  FilterDropdown,
  SelectOptionsWrapper
} from 'src/components/filters/components/atoms'
import NativeSelect from 'src/features/invoices/components/Select'
import { connectInvoicesIssued } from 'src/features/invoices/duck/connectors'
import InvoiceRowIssued from 'src/features/invoices/components/InvoiceRowIssued'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { AddOfferButton } from 'src/components/atoms/Buttons'
import {
  INVOICES_ISSUED_TABLE_HEADERS,
  INVOICES_ISSUED_TABLE_NAMES,
  INVOICES_ISSUED_TABLE_WIDTHS,
  INVOICES_STATUS_FILTER_OPTIONS
} from 'src/features/invoices/duck/consts'

const OrdersIssuedList = ({
  invoicesIssued,
  invoices,
  pagination,
  filters,
  setFilter,
  hidePagination = false,
  getInvoicesListIssued,
  className,
  downloadInvoicePdf,
  downloadCorrectiveInvoicePdf,
  markAsPaid,
  markCorrectiveAsPaid
}) => {
  const headers = INVOICES_ISSUED_TABLE_HEADERS
  const intl = useIntl()

  const renderListContents = () => {
    if (isStockLoading(invoicesIssued, 'list')) {
      return (
        <>
          <Shade />
          <LoadingList />
        </>
      )
    }

    if (invoices.length === 0) {
      return (
        <EmptyOffers>
          <FormattedMessage id='invoices.noInvoices' />
        </EmptyOffers>
      )
    }

    if (invoices.length > 0) {
      return invoices.map(invoice => (
        <InvoiceRowIssued
          downloadInvoicePdf={downloadInvoicePdf}
          downloadCorrectiveInvoicePdf={downloadCorrectiveInvoicePdf}
          key={invoice.id}
          invoice={invoice}
          markAsPaid={markAsPaid}
          markCorrectiveAsPaid={markCorrectiveAsPaid}
        />
      ))
    }
  }

  return (
    <div className={className}>
      <Padding></Padding>
      <ListTopRow>
        <CounterGroup>
          <Counter>
            <span>{`${pagination.total} `}</span>
            <FormattedMessage
              id='invoices.invoicePlural'
              values={{
                count: pagination.total
              }}
            />
          </Counter>
          <RefreshIcon onClick={getInvoicesListIssued} />
        </CounterGroup>
        <ActionGroup>
          <SelectWrapper>
            <Select
              removeSelectedOptions
              removeSearchInput
              height='250px'
              id='filters-invoices-status'
              name='paymentStatus'
              label={intl.formatMessage({ id: 'offersFilters.status' })}
              value={filters.paymentStatus}
              options={INVOICES_STATUS_FILTER_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={setFilter}
              placeholder={intl.formatMessage({
                id: 'offersFilters.searchPlaceholder'
              })}
              type='radio'
              alignRight
            />
          </SelectWrapper>
          <MobileSelectWrapper>
            <NativeSelect
              value={filters.paymentStatus}
              options={INVOICES_STATUS_FILTER_OPTIONS.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))}
              onChange={e => {
                setFilter('paymentStatus', e.target.value)
              }}
            />
          </MobileSelectWrapper>
          <Link to={`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES_ADD}`}>
            <AddOfferButton
              title={<FormattedMessage id='invoices.addInvoice' />}
            />
          </Link>
        </ActionGroup>
      </ListTopRow>
      <Container>
        <TableHeader
          widths={INVOICES_ISSUED_TABLE_WIDTHS}
          columnNames={INVOICES_ISSUED_TABLE_NAMES}
        >
          {headers &&
            headers.map((header, index) => (
              <HeaderItem sort={false} key={`${header.name} ${index}`}>
                <Ellipsis>
                  <FormattedMessage
                    id={`stocksHeaders.${header.name}`}
                    defaultMessage=' '
                  />
                  {header.sort && (
                    <Icon>
                      <ArrowIcon />
                    </Icon>
                  )}
                </Ellipsis>
              </HeaderItem>
            ))}
        </TableHeader>
        {renderListContents()}
      </Container>
      {invoices.length > 0 && !hidePagination && (
        <BottomRow>
          <Pagination
            itemsPerPage={filters.limit}
            currentPage={pagination.currentPage}
            pagesCount={pagination.lastPage}
            setPage={page => setFilter('page', page)}
            setItemsPerPage={limit => {
              setFilter('limit', limit)
              setFilter('page', 1)
            }}
          />
        </BottomRow>
      )}
    </div>
  )
}

const connectedComponent = connectInvoicesIssued(OrdersIssuedList)
export default styled(connectedComponent)`
  ${SectionBox} {
    padding: 0 0 180px 0;
    ${breakpoint.m`
      padding: 0 1.2rem 180px 1.2rem;
  `}
  }
`
export const ListTopRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1.2rem;
  ${Counter} {
    margin-right: 0.75rem;
    white-space: nowrap;
  }
  ${breakpoint.m`
     padding: 0;
  `}
`
const CounterGroup = styled.div`
  display: flex;
`

const SelectWrapper = styled.div`
  display: none;
  width: 100%;
  ${FilterDropdown} {
    ${breakpoint.m`
      height: 300px;
    `}
  }
  ${SelectOptionsWrapper} {
    height: 100% !important;
  }
  ${breakpoint.m`
      display: block;
  `}
`

const MobileSelectWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  ${breakpoint.m`
      display: none;
  `}
`
