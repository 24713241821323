import React, { useState } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import camelcase from 'lodash.camelcase'
import DownIcon from 'src/assets/icons/down.svg'
import PdfIcon from 'src/assets/icons/pdf-2.svg'
import styled from 'styled-components'
import { Description, Title } from 'src/features/stocks/components/typography'

const Documents = ({ className, documents }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={className}>
      <Title onClick={() => setOpen(!open)}>
        <FormattedMessage id='offerDetails.documents' />
        <DownIcon transform={`rotate(${open ? 180 : 0})`} />
      </Title>
      {open &&
        documents.map((file, index) => (
          <Item as='div' key={index}>
            <ItemContent href={file.url} target='_blank'>
              <FormattedMessage id={`documentTypes.${camelcase(file.type)}`} />
              <PdfIcon />
            </ItemContent>
          </Item>
        ))}
    </div>
  )
}

const ItemContent = styled.a`
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  margin-left: 1.5rem;
  margin-right: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
`

const Item = styled(Description)`
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ebonyClay.alpha(0.03).hex()};
    ${ItemContent} .pdfSvgClass {
      fill: ${({ theme }) => theme.colors.easternBlue.hex()};
    }
  }
  &:last-child ${ItemContent} {
    border: none;
  }
`

export default styled(Documents)`
  && {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  ${Title} {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding-left: 1.5rem;
    padding-right: 2rem;
  }
`
