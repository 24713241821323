import React, { useEffect, useState } from 'react'
import { isNotEmpty } from 'ramda-adjunct'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { propOr, flip, gt, any, map } from 'ramda'
import CustomIcon from 'src/components/CustomIcon'
import styled, { css } from 'styled-components'
import { MAX_FILE_SIZE_BYTES } from 'src/ducks/consts'
import { ErrorMessage, FileInput } from 'src/features/account/components/atoms'
import { useDropArea } from 'react-use'
import { normalizeInputFiles } from 'src/features/debtCollection/duck/normalizers'

const AddFiles = ({ onChange, className, files, children }) => {
  const [inputFiles, setInputFiles] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    const moreThanSize = flip(gt)(MAX_FILE_SIZE_BYTES)
    const filesArrayNotEmpty = isNotEmpty(inputFiles)
    const size = propOr(0, 'size')
    const hasWrongSize = any(moreThanSize, map(size, inputFiles))

    if (!hasWrongSize && filesArrayNotEmpty) {
      onChange([...files, ...normalizeInputFiles(inputFiles)])
      setInputFiles([])
    }

    setError(hasWrongSize)
  }, [inputFiles, files])

  const [bond] = useDropArea({
    onFiles: dropZoneFiles => setInputFiles(dropZoneFiles)
  })

  return (
    <>
      <div className={className} {...bond}>
        <FileInput
          type='file'
          accept='image/jpg, image/png, image/jpeg, image/gif, application/pdf'
          name='file'
          id='file'
          onChange={e => setInputFiles(e.target.files)}
          multiple
        />
        <FileDropZone htmlFor='file'>{children}</FileDropZone>
      </div>
      {error && (
        <ErrorMessage
          type='alert'
          message={
            <FormattedMessage id='debtCollection.toLargeFileErrorMessage' />
          }
        />
      )}
    </>
  )
}

const styledAddFiles = styled(AddFiles)`
  position: relative;
  height: fit-content;
`

export default styledAddFiles

export const FileDropZone = styled.label`
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px;
  border: 1px dashed rgb(198, 203, 212);
  background-color: ${({ theme }) => theme.colors.white.hex()};
  padding: 1.4375rem;
  margin: 4px 0;
  font-size: 0.875rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  & a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    font-weight: 600;
  }
  ${({ url }) =>
    url &&
    css`
      background-image: url(${url});
    `}

  ${CustomIcon} {
    height: 24px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
