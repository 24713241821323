import translate from 'src/intl/translate'
import { values } from 'ramda'
import { generateTableNames, generateTableWidths } from 'src/utils/helpers'

export const CUSTOMS_CLEARANCE_TYPES = {
  AFTER_CUSTOMS_CLEARANCE: 'after_customs_clearance',
  IN_CUSTOMS_TRANSIT: 'in_customs_transit',
  SHIPPER: 'shipper',
  PORT: 'port',
  ADDRESS: 'address'
}

export const CUSTOMS_CLEARANCE_TYPE_OPTIONS_IMPORT = [
  {
    label: 'orders.afterCustomsClearance',
    value: CUSTOMS_CLEARANCE_TYPES.AFTER_CUSTOMS_CLEARANCE
  },
  {
    label: 'orders.inCustomsTransit',
    value: CUSTOMS_CLEARANCE_TYPES.IN_CUSTOMS_TRANSIT
  }
]

export const CUSTOMS_CLEARANCE_TYPE_OPTIONS_EXPORT = [
  {
    label: 'orders.shipper',
    value: CUSTOMS_CLEARANCE_TYPES.SHIPPER
  },
  {
    label: 'orders.port',
    value: CUSTOMS_CLEARANCE_TYPES.PORT
  },
  {
    label: 'orders.exactAddress',
    value: CUSTOMS_CLEARANCE_TYPES.ADDRESS
  }
]

export const ORDERS_TABS = {
  ISSUED: 'orders.issued',
  RECEIVED: 'orders.received'
}

export const ORDERS_TABS_ARRAY = values(ORDERS_TABS)

export const ORDERS_STATUS_FILTER_OPTIONS = [
  {
    label: 'orders.all',
    value: null
  },
  {
    label: 'orders.new',
    value: 'new'
  },
  {
    label: 'orders.accepted',
    value: 'accepted'
  },
  {
    label: 'orders.rejected',
    value: 'rejected'
  }
]

export const ORDERS_ISSUED_TABLE_HEADERS = [
  {
    name: 'status',
    sort: null,
    width: 0.7
  },
  {
    name: 'orderNumber',
    sort: null,
    width: 1
  },
  {
    name: 'relation',
    sort: null,
    width: 0.5
  },
  {
    name: 'loadingOrReturn',
    sort: null,
    width: 2
  },
  {
    name: 'cargo',
    sort: null,
    width: 1
  },
  {
    name: 'contractor',
    sort: null,
    width: 1.5
  },
  {
    name: 'date',
    sort: true,
    width: 0.7
  }
]

export const ORDERS_ISSUED_TABLE_NAMES = generateTableNames(
  ORDERS_ISSUED_TABLE_HEADERS
)
export const ORDERS_ISSUED_TABLE_WIDTHS = generateTableWidths(
  ORDERS_ISSUED_TABLE_HEADERS
)

export const ORDERS_RECEIVED_TABLE_HEADERS = [
  {
    name: 'status',
    sort: null,
    width: 0.7
  },
  {
    name: 'orderNumber',
    sort: null,
    width: 1
  },
  {
    name: 'relation',
    sort: null,
    width: 0.5
  },
  {
    name: 'loadingOrReturn',
    sort: null,
    width: 2
  },
  {
    name: 'cargo',
    sort: null,
    width: 1
  },
  {
    name: 'issuer',
    sort: null,
    width: 1.5
  },
  {
    name: 'date',
    sort: true,
    width: 0.7
  }
]

export const ORDERS_RECEIVED_TABLE_NAMES = generateTableNames(
  ORDERS_RECEIVED_TABLE_HEADERS
)
export const ORDERS_RECEIVED_TABLE_WIDTHS = generateTableWidths(
  ORDERS_RECEIVED_TABLE_HEADERS
)
