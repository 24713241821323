import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { Status } from 'src/features/myOffers/components/StatusIcon'
import {
  Actions,
  HeaderItem,
  Currency,
  Reservations
} from 'src/features/myOffers/components/atoms'
import { ChatButton, DelayedOfferButton } from 'src/components/atoms/Buttons'
import { FormattedMessage } from 'gatsby-plugin-intl'
import ChatIcon from 'src/assets/icons/icon_chat.svg'
import ContractIcon from 'src/assets/icons/description.svg'
import React from 'react'
import { BottomButtons } from 'src/features/stocks/components/OfferDetails/atoms'
import MUIDropdownIcon from '@material-ui/icons/KeyboardArrowDown'

export const TableRow = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  margin-top: 4px;
  align-items: ${({ open }) => (open ? 'flex-start' : 'center')};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  display: none;
  &:hover {
    box-shadow: none;
  }

  ${breakpoint.m`
    display: grid;
    margin-top: 0;
    grid-template-rows: none;
    grid-template-columns: ${({ widths }) => widths};
    grid-template-areas: ${({ columnNames }) => columnNames};
  `}

  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    &:first-of-type {
      padding-left: 10px;
    }
  }

  &:hover {
    z-index: 1;
    box-shadow: none;
    position: relative;
    ${Actions} {
      width: 100%;
      left: 0;
    }
    ${breakpoint.m`
     grid-template-columns: ${({ widths }) => widths};
     `}
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGreen.hex()};
      border-left: 4px solid ${({ theme }) => theme.colors.apple.hex()};
      margin-left: -4px;
      position: relative;
      ${Actions} {
        background-color: ${({ theme }) => theme.colors.lightGreen.hex()};
      }
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 1;
      margin-top: 7px !important;
      border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
      border-bottom: none;
      margin-left: -4px;
      position: relative;
      box-shadow: none;
      ${breakpoint.m`
      border-bottom: none;
         box-shadow: 0 2px 20px 0 ${({ theme }) =>
           theme.colors.ebonyClay.alpha(0.1).hex()};
     `}
    `}

  & > ${Status} {
    display: none;
    ${breakpoint.m`
     display: flex;
     `}
  }
`
export const TableHeader = styled(TableRow)`
  cursor: default;
  background-color: ${({ theme }) => theme.colors.whiteTwo.hex()};
  color: ${({ theme }) => theme.colors.black.alpha(0.5).hex()};
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: none;
  display: none;
  position: static;
  &:hover {
    position: static;
    box-shadow: none;
    border-left: 0;
    margin-left: 0;
    ${breakpoint.m`
     grid-template-columns: ${({ widths }) => widths};
    `}
  }
  ${breakpoint.m`
     display: grid;
  `}
  > ${HeaderItem} {
    position: relative;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    align-self: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteTwo.hex()};
    &:hover {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
    }
  }
  ${({ sticky, headerWidth }) =>
    sticky &&
    css`
      position: fixed;
      top: 0;
      width: ${headerWidth}px;
    `}
`

export const ContractLocations = styled.div`
  && {
    padding-top: 4px;
  }
`
export const EllipsisContainer = styled.div`
  overflow: hidden;
  width: 100%;
  margin-left: 10px;
`
export const ContractOfferDetails = styled.div`
  color: ${({ theme }) => theme.colors.black.hex()};
  background-color: ${({ theme }) => theme.colors.white.alpha(0.9).hex()};
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  align-items: ${({ open }) => (open ? 'flex-start' : 'center')};
  justify-content: space-between;
  display: none;
  ${breakpoint.m`
      display: flex
  `}
  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    &:first-of-type {
      padding-left: 10px;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 1;
      border-left: 4px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
      border-bottom: none;
      margin-left: -4px;
      position: relative;
      box-shadow: none;
    `}
  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: 7px;
    `}
`

export const ButtonsForCustomerContract = ({
  offerDetails,
  setBidDialog,
  stock,
  setConversationOpened,
  offerBelongsToCurrentUser
}) => (
  <BottomButtons>
    <DelayedOfferButton
      active={!stock.isLoading('buttons') && offerDetails.isReservable}
      loading={stock.isLoading('buttons')}
      onClick={() => setBidDialog(true)}
      offerId={offerDetails.id}
      availableTime={offerDetails.reserveActionAvailableAt}
    >
      <FormattedMessage id='offerDetails.makeABid' />
      <span>
        <ContractIcon />
      </span>
    </DelayedOfferButton>
    <ChatButton
      active={offerDetails.id && !offerBelongsToCurrentUser}
      onClick={() =>
        setConversationOpened({
          receiverId: offerDetails.handlerId,
          conversationOpened: true
        })
      }
    >
      <FormattedMessage id='offerDetails.startChat' />
      <span>
        <ChatIcon />
      </span>
    </ChatButton>
  </BottomButtons>
)

export const ReservationsFieldContract = ({
  clickable,
  handleClick,
  reservationsCount,
  price,
  open,
  isFinished = false
}) => {
  const hasPrice = price.float > 0
  return (
    <Reservations hasReservations={clickable} onClick={handleClick}>
      <span>
        <FormattedMessage id='myOffers.reservations' />
      </span>
      {!hasPrice && !isFinished && `${reservationsCount}`}
      {!hasPrice && isFinished && <FormattedMessage id='common.missing' />}
      {hasPrice && `${price.float}`}
      {hasPrice && <Currency>{price.currency}</Currency>}
      {clickable && (
        <div>
          <MUIDropdownIcon
            width={24}
            height={24}
            style={{ transform: `rotate(${open ? '180deg' : '0'})` }}
          />
        </div>
      )}
    </Reservations>
  )
}
