import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl'
import { compose } from 'recompose'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'

import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { Padding } from 'src/features/myOffers/components/atoms'
import {
  ActionGroup,
  Content,
  FormActionBar,
  FormInnerWrapper,
  FormSection
} from 'src/features/offers/components/atoms'
import { breakpoint } from 'src/theme/grid'
import Drawer from 'src/features/stocks/components/Drawer'
import { connectInvoiceNote } from 'src/features/invoices/duck/connectors'
import { DATE_FORMAT } from 'src/ducks/consts'
import { TitleContainer } from 'src/features/account/components/atoms'
import { RecipientDetails } from 'src/features/invoices/components/TopSection/RecipientDetails'
import { Center } from 'src/features/invoices/styles'
import { IssuerDetails } from 'src/features/invoices/components/TopSection/IssuerDetails'
import {
  PdfButton,
  Separator,
  TextTitle
} from 'src/features/invoices/InvoiceDetails'
import moment from 'moment'
import useUrlParams from 'src/hooks/useUrlParams'
import PdfIcon from 'src/assets/icons/pdf.svg'

const NoteDetails = ({
  className,
  downloadNotePdf,
  showCorrectionNote,
  note
}) => {
  const intl = useIntl()
  const { id } = useUrlParams()

  useEffect(() => {
    id && showCorrectionNote(id)
  }, [id])

  return (
    <>
      <Page
        className={className}
        contentPadding={0}
        topBar={
          <HeaderLoggedIn>
            <HeaderGroup>
              <StockHeader>
                <FormattedMessage id='invoices.correctionNotePreview' />
              </StockHeader>
            </HeaderGroup>
          </HeaderLoggedIn>
        }
      >
        <Content>
          <FormSection>
            <FormInnerWrapper>
              <Padding>
                <BreadCrumbs
                  links={[
                    {
                      name: intl.formatMessage({ id: 'forms.dashboard' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DASHBOARD}`
                    },
                    {
                      name: intl.formatMessage({ id: 'invoices.invoices' }),
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`
                    },
                    {
                      name: `${intl.formatMessage({
                        id: 'invoices.invoice'
                      })} ${note.invoice.number}`,
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${note.invoice.id}`
                    },
                    {
                      name: `${intl.formatMessage({
                        id: 'invoices.correctionNote'
                      })} ${note.number}`,
                      url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_NOTE_DETAILS}&id=${note.invoice.id}`
                    }
                  ]}
                />
                <PageTitleContainer>
                  <PageTitle>
                    <FormattedMessage id='invoices.correctionNote' />{' '}
                    {note.number}
                  </PageTitle>
                </PageTitleContainer>
                <TopRow>
                  <div style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.correctedInvoice' />
                    </TextTitle>
                    <OfferLink
                      onClick={() =>
                        navigate(
                          `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICE_DETAILS}&id=${note.invoice.id}`
                        )
                      }
                    >
                      {note.invoice.number}{' '}
                    </OfferLink>
                    <Date>
                      <FormattedMessage id='invoices.issuedAt' />{' '}
                      {note.invoice.issueDate &&
                        moment(note.invoice.issueDate).format(DATE_FORMAT)}
                    </Date>
                  </div>
                  <DateContainer>
                    <TextTitle>
                      <FormattedMessage id='invoices.noteIssueDate' />
                    </TextTitle>
                    <Date>
                      {note.issueDate &&
                        moment(note.issueDate).format(DATE_FORMAT)}
                    </Date>
                  </DateContainer>
                </TopRow>
                <Row style={{ marginBottom: '1rem' }}>
                  <div style={{ flex: 1 }}>
                    <IssuerDetails invoice={note.invoice} showLabel />
                  </div>
                  <div style={{ flex: 1 }}>
                    <RecipientDetails invoice={note.invoice} showLabel />
                  </div>
                </Row>
                <TextContentRow>
                  <TextContent>
                    <TextTitleNote>
                      <FormattedMessage id='invoices.toBeCorrected' />
                    </TextTitleNote>
                    <StyledSeparator />
                    <Text>{note.originalContent}</Text>
                  </TextContent>
                  <TextContent>
                    <TextTitleNote>
                      <FormattedMessage id='invoices.correct' />
                    </TextTitleNote>
                    <StyledSeparator />
                    <Text>{note.correctedContent}</Text>
                  </TextContent>
                </TextContentRow>
                <Separator />
                <Row>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.noteAdditionalInfo' />
                    </TextTitle>
                    <Text>{note.additionalInfo || '-'}</Text>
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <TextTitle>
                      <FormattedMessage id='invoices.issuer' />
                    </TextTitle>
                    <Text>
                      {note.issuer.firstName} {note.issuer.lastName}
                    </Text>
                  </Column>
                </Row>
              </Padding>
            </FormInnerWrapper>
            <FormActionBar>
              <Center>
                <div></div>
                <ActionGroup>
                  <PdfButton
                    active={true}
                    onClick={() => {
                      downloadNotePdf({ id: note.id })
                    }}
                  >
                    <PdfIcon />
                    <FormattedMessage id='orders.generatePdf' />
                  </PdfButton>
                </ActionGroup>
              </Center>
            </FormActionBar>
          </FormSection>
        </Content>
      </Page>
    </>
  )
}

const ConnectedComponent = compose(connectInvoiceNote)(NoteDetails)

export default styled(ConnectedComponent)`
  ${FormInnerWrapper} {
    margin-bottom: 5rem;
    background-color: #f8f8f9;
  }
  ${Padding} {
    max-width: calc(887px + 2.8rem);
    margin: 0 auto;
  }
  ${Drawer} {
    display: none;
    ${breakpoint.m`
    display: flex;
   `}
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.m`
    flex-direction: row;
      & > div:not(:last-child),
  p:not(:last-child) {
    margin-right: 0.5rem;
  }
  `}
`

const TextContentRow = styled(Row)`
  & > div {
    margin-right: 0;
    &:first-child {
      margin-bottom: 1rem;
    }
  }

  ${breakpoint.m`
    flex-direction: row;
  & > div:not(:last-child),
  p:not(:last-child) {
    margin-right: 1rem;
  }
  & > div {
    &:first-child {
      margin-bottom: 0rem;
    }
  }
  `}
`
const OfferLink = styled.a`
  white-space: nowrap;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
`

const Date = styled.span`
  font-size: 0.875rem;
`

const Text = styled.p`
  font-size: 0.875rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const TextContent = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  padding: 1rem;
`

const TextTitleNote = styled(TextTitle)`
  margin-top: 0;
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${TextTitle} {
    margin-top: 0;
  }
  ${breakpoint.m`
      align-items: flex-end;
  `}
`

const PageTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  padding-bottom: 4px;
`
const PageTitleContainer = styled(TitleContainer)`
  padding-top: 1rem;
  margin-bottom: 1rem;
  ${breakpoint.m`
    padding-top: 2rem;
    margin-bottom: 2rem;
  `}
`

const TopRow = styled(Row)`
  & > div:nth-child(odd) {
    margin-bottom: 2rem;
    ${breakpoint.m`
    margin-bottom: 0;
  `}
  }
  ${breakpoint.m`
    margin-bottom: 2rem;
  `}
`

const StyledSeparator = styled(Separator)`
  margin-top: 0.5rem;
`
