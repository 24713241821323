import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { DateTime } from 'src/features/stocks/components/atoms'
import { DATE_LONG_FORMAT_WITH_WEEKDAY } from 'src/ducks/consts'
import EllipsisTooltip from 'src/components/EllipsisTooltip'

const AuctionDeadline = ({ className, auctionDeadline, finishedAt }) => (
  <>
    {!finishedAt && (
      <div className={className}>
        <EllipsisTooltip>
          {moment(auctionDeadline).fromNow(true)}{' '}
          <FormattedMessage id='myOffers.tillEnd' />
        </EllipsisTooltip>
        <DateTime>
          <EllipsisTooltip>
            {'('}
            {moment(auctionDeadline).format(DATE_LONG_FORMAT_WITH_WEEKDAY)}
            {')'}
          </EllipsisTooltip>
        </DateTime>
      </div>
    )}
    {finishedAt && (
      <div className={className}>
        <EllipsisTooltip>
          <FormattedMessage id='myOffers.auctionFinished' />
        </EllipsisTooltip>
        <DateTime>
          <EllipsisTooltip>
            {'('}
            {moment(finishedAt).format(DATE_LONG_FORMAT_WITH_WEEKDAY)}
            {')'}
          </EllipsisTooltip>
        </DateTime>
      </div>
    )}
  </>
)

export default styled(AuctionDeadline)`
  grid-area: deadline;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`
