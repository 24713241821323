import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { pathOr, indexOf } from 'ramda'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import { DATE_FORMAT } from 'src/ducks/consts'
import PermissionsContext, {
  hasPermissionTo,
  PERMISSIONS
} from 'src/utils/permissions'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import Tabs from 'src/components/Tabs'
import { MY_COMPANY_TABS_ARRAY } from 'src/features/account/duck/consts'
import { Page } from 'src/components/Wrappers'
import Alert from 'src/components/Alert'
import connect from 'src/features/account/duck/connectors'
import {
  Column,
  Row,
  SectionBox,
  CreatedAtUser
} from 'src/features/account/components/atoms'
import CompanyInfo from 'src/features/account/components/CompanyInfo'
import CompanyContact from 'src/features/account/components/CompanyContact'
import CompanyDocuments from 'src/features/account/components/CompanyDocuments'
import CompanyUsers from 'src/features/account/components/CompanyUsers'
import StockHeader from 'src/features/stocks/components/StockHeader.jsx'

const Company = ({
  className,
  currentUser,
  currentCompany,
  updateMyCompanyStatus: { changedSuccessfully },
  getMyCompanyDetails,
  location
}) => {
  const intl = useIntl()
  const permissions = useContext(PermissionsContext)
  const activeTab = pathOr('', ['state', 'activeTab'], location)

  useEffect(() => {
    getMyCompanyDetails()
  }, [])

  const activeTabIndex = indexOf(activeTab, MY_COMPANY_TABS_ARRAY)
  const initialActiveTab = activeTabIndex > 0 ? activeTabIndex : 0

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'myCompany.myCompany'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Tabs list={MY_COMPANY_TABS_ARRAY.map((tab) => intl.formatMessage({id: tab}))} initialActiveTab={initialActiveTab}>
        <SectionBox>
          <Row>
            <Alert
              fullWidth
              type='info'
              message={intl.formatMessage({
                id: 'myCompany.changeDataAlert'
              })}
            />
          </Row>
          <Row>
            <Column left>
              {currentUser.company.createdAt && (
                <CreatedAtUser>
                  <FormattedMessage
                    id='myCompany.createdAt'
                    values={{
                      date: moment(currentUser.company.createdAt).format(
                        DATE_FORMAT
                      )
                    }}
                  />
                </CreatedAtUser>
              )}
              <Row>
                <CompanyInfo company={currentCompany} />
              </Row>
            </Column>
            <Column right>
              <CompanyContactRow>
                <Column>
                  <CompanyContact
                    company={currentUser.company}
                    editAllowed={
                      hasPermissionTo(
                        PERMISSIONS.COMPANY_EDIT_DETAILS,
                        permissions
                      ) && !changedSuccessfully
                    }
                  />
                  {changedSuccessfully && (
                    <Alert
                      type='success'
                      message={intl.formatMessage({
                        id: 'myCompany.updateSuccess'
                      })}
                    />
                  )}
                </Column>
              </CompanyContactRow>
            </Column>
          </Row>
          <Row>
            <CompanyDocuments documents={currentCompany.documents} />
          </Row>
        </SectionBox>
        <SectionBox>
          <Row>
            <Column>
              <Row>
                <Column>
                  <CompanyUsers
                    users={currentCompany.users}
                    editAllowed={hasPermissionTo(
                      PERMISSIONS.COMPANY_MANAGE_USERS,
                      permissions
                    )}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </SectionBox>
      </Tabs>
    </Page>
  )
}

const ConnectedComponent = connect(Company)
export default styled(ConnectedComponent)`
  ${Tabs} {
    display: block;
    & > div {
      padding: 0.8rem 1.2rem 0 1.2rem;
    }
    & span {
      font-weight: 400;
    }
  }

  ${Alert} {
    width: auto;
    margin: 1rem 0 0 0;
    ${breakpoint.m`
    margin: initial;
    width: inherit;
  `}
  }
`

const CompanyContactRow = styled(Row)`
  height: 100%;
`
