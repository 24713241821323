import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage, useIntl, navigate } from 'gatsby-plugin-intl'
import { Page } from 'src/components/Wrappers'
import HeaderLoggedIn, {
  HeaderGroup
} from 'src/components/header/HeaderLoggedIn'
import StockHeader from 'src/features/stocks/components/StockHeader'
import { Column, Row, SectionBox } from 'src/features/account/components/atoms'
import Alert from 'src/components/Alert'
import { Padding } from 'src/features/myOffers/components/atoms'
import BreadCrumbs from 'src/components/BreadCrumbs'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import TextInput from 'src/components/atoms/TextInput'
import { CancelButton } from 'src/components/atoms/Buttons'
import DebtCollectionAgreementUrl from 'src/assets/documents/umowa_o_prowadzenie_windykacji.pdf'
import AttachFileIcon from 'src/assets/icons/attach-file.svg'
import RemoveIconUrl from 'src/assets/icons/delete.svg'

import {
  AddressDataContainer,
  CallUs,
  CompanyAddress,
  CompanyName,
  Email,
  Spacer
} from 'src/features/account/contact/atoms'
import { breakpoint } from 'src/theme/grid'
import AddMultipleFilesByUrl from 'src/features/debtCollection/components/AddMultipleFilesByUrl'
import { bytesToKB, ellipsisInTheMiddle } from 'src/utils/helpers'
import { filter, not, pathEq } from 'ramda'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import DebtConfirmationDialog from 'src/features/debtCollection/components/DebtConfirmationDialog'
import useValidateSchema from 'src/hooks/useValidateSchema'
import {
  validateDebtCollectionForm,
  validateDebtCollectionFormSchema
} from 'src/features/debtCollection/duck/schema'
import OfferInfoBox from './components/OfferInfoBox'
import connect from './duck/connectors'

const DebtCollectionForm = ({
  className,
  offer,
  setAdditionalInfo,
  additionalInfo,
  files,
  setDebtCollectionFiles,
  createDebtCollectionFreeCarriers,
  createDebtCollectionPostImport,
  createDebtCollectionImport,
  createDebtCollectionExport,
  errorMessage,
  confirmation,
  clearDebtCollection,
  showOffersReservationsExport,
  showOffersReservationsFreeCarrier,
  showOffersReservationsPostImport,
  showOffersReservationsImport,
  debtor,
  isLoading
}) => {
  const intl = useIntl()

  const createDebtCollection = {
    [STOCKS_TYPES.POST_IMPORT]: createDebtCollectionPostImport,
    [STOCKS_TYPES.CARGO_EXPORT]: createDebtCollectionExport,
    [STOCKS_TYPES.CARGO_IMPORT]: createDebtCollectionImport,
    [STOCKS_TYPES.FREE_CARRIERS]: createDebtCollectionFreeCarriers
  }[offer.offerType]

  const getReservations = {
    [STOCKS_TYPES.POST_IMPORT]: showOffersReservationsPostImport,
    [STOCKS_TYPES.CARGO_EXPORT]: showOffersReservationsExport,
    [STOCKS_TYPES.CARGO_IMPORT]: showOffersReservationsImport,
    [STOCKS_TYPES.FREE_CARRIERS]: showOffersReservationsFreeCarrier
  }[offer.offerType]

  const backLink = {
    [STOCKS_TYPES.POST_IMPORT]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_POST_IMPORT_OFFERS}`,
    [STOCKS_TYPES.CARGO_EXPORT]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`,
    [STOCKS_TYPES.CARGO_IMPORT]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`,
    [STOCKS_TYPES.FREE_CARRIERS]: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_FREE_CARRIERS_OFFERS}`
  }[offer.offerType]

  const breadcrumbs = {
    [STOCKS_TYPES.CARGO_EXPORT]: [
      {
        name: intl.formatMessage({ id: 'dashboard.exportTitle' }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_EXPORT}`
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.myOffers'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`
      },
      {
        name: intl.formatMessage({
          id: 'debtCollection.title'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DEBT_COLLECTION}`
      }
    ],
    [STOCKS_TYPES.CARGO_IMPORT]: [
      {
        name: intl.formatMessage({ id: 'dashboard.importTitle' }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_CARGO_IMPORT}`
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.myOffers'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`
      },
      {
        name: intl.formatMessage({
          id: 'debtCollection.title'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DEBT_COLLECTION}`
      }
    ],
    [STOCKS_TYPES.POST_IMPORT]: [
      {
        name: intl.formatMessage({ id: 'dashboard.postImportTitle' }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_POST_IMPORT}`
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.myOffers'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_POST_IMPORT_OFFERS}`
      },
      {
        name: intl.formatMessage({
          id: 'debtCollection.title'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DEBT_COLLECTION}`
      }
    ],
    [STOCKS_TYPES.FREE_CARRIERS]: [
      {
        name: intl.formatMessage({ id: 'dashboard.freeCarriersTitle' }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.STOCKS_FREE_CARRIERS}`
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.myOffers'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_FREE_CARRIERS_OFFERS}`
      },
      {
        name: intl.formatMessage({
          id: 'debtCollection.title'
        }),
        url: `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DEBT_COLLECTION}`
      }
    ]
  }[offer.offerType]

  useEffect(() => {
    if (!offer.id) navigate(backLink)
    setAdditionalInfo(
      intl.formatMessage({ id: 'debtCollection.additionalInfoPlaceholder' })
    )
    // if (offer.reservations.length === 0 && offer.id) {
    //   getReservations(offer.id)
    // }
  }, [])

  const onRemove = fileUrl => {
    setDebtCollectionFiles(
      filter(file => not(pathEq(['image', 'url'], fileUrl, file)), files)
    )
  }

  const valid = useValidateSchema(
    { additionalInfo },
    validateDebtCollectionFormSchema
  )

  return (
    <Page
      className={className}
      contentPadding={0}
      topBar={
        <HeaderLoggedIn>
          <HeaderGroup>
            <StockHeader>
              {intl.formatMessage({
                id: 'debtCollection.title'
              })}
            </StockHeader>
          </HeaderGroup>
        </HeaderLoggedIn>
      }
    >
      <Padding>
        <BreadCrumbs links={breadcrumbs} />
      </Padding>
      <SectionBox>
        <Row>
          <Column left>
            <Row>
              <Title>
                <FormattedMessage id='debtCollection.debtCollectionOrder' />
              </Title>
            </Row>
            <Row>
              <Alert
                type='warning'
                message={
                  <FormattedMessage
                    id='debtCollection.warning'
                    values={{
                      a: (...chunks) => (
                        <a href={DebtCollectionAgreementUrl} target='_blank'>
                          {chunks}
                        </a>
                      )
                    }}
                  />
                }
              />
            </Row>
            <Row>
              <OfferInfoBox
                offer={offer}
                onOfferNumberClick={() => {}}
                offerorCompany={offer.issuer.company.name}
              />
            </Row>
            <Row>
              <div>
                <InputTitle>
                  <FormattedMessage id='debtCollection.debtor' />
                </InputTitle>
                <Debtor>{debtor?.name || offer.issuer.company.name}</Debtor>
              </div>
            </Row>
            <Row>
              <TextInput
                label={intl.formatMessage({
                  id: 'debtCollection.additionalInfo'
                })}
                id='additionalInfo'
                name='additionalInfo'
                value={additionalInfo}
                onChange={(_, value) => setAdditionalInfo(value)}
                multiline
                validate={validateDebtCollectionForm}
                rows={14}
                required
              />
            </Row>
            <Row>
              <DropZoneContainer>
                <InputTitle>
                  <FormattedMessage id='debtCollection.documents' />
                </InputTitle>
                <AddMultipleFilesByUrl
                  files={files}
                  onChange={setDebtCollectionFiles}
                >
                  <FormattedMessage
                    id='debtCollection.dropZone'
                    values={{ a: (...chunks) => <a>{chunks}</a> }}
                  />
                </AddMultipleFilesByUrl>
                <DropZoneInfo>
                  <FormattedMessage id='debtCollection.dropZoneInfo' />
                </DropZoneInfo>
              </DropZoneContainer>
            </Row>
            <Row>
              <FilesList>
                {files.map(file => (
                  <File key={file.image.url}>
                    <AttachIcon>
                      <AttachFileIcon />
                    </AttachIcon>
                    <FileName>
                      {ellipsisInTheMiddle(file.name, 40)}
                      <span>{bytesToKB(file.size)}</span>
                    </FileName>
                    <RemoveIcon onClick={() => onRemove(file.image.url)}>
                      <RemoveIconUrl />
                    </RemoveIcon>
                  </File>
                ))}
              </FilesList>
            </Row>
            <Row>
              <Line />
            </Row>
            <Row>
              <Alert
                type='info'
                message={<FormattedMessage id='debtCollection.infoWarning' />}
              />
            </Row>
            <Row>
              <ButtonsContainer>
                <AcceptButton
                  active={valid && !isLoading}
                  onClick={createDebtCollection}
                >
                  <FormattedMessage id='debtCollection.reportDebtor' />
                </AcceptButton>
                <CancelButton
                  active={true}
                  onClick={() => {
                    navigate(backLink)
                  }}
                >
                  <FormattedMessage id='common.cancel' />
                </CancelButton>
              </ButtonsContainer>
            </Row>
          </Column>
          <Column right>
            <Row>
              <Column>
                <AddressDataContainer>
                  <Row>
                    <ContactSectionTitle>
                      <FormattedMessage
                        id='debtCollection.ourPriority'
                        values={{ b: (...chunks) => <b>{chunks}</b> }}
                      />
                    </ContactSectionTitle>
                  </Row>
                  <Row>
                    <ContactSectionSubtitle>
                      <FormattedMessage id='debtCollection.whatIsItAboutTitle' />
                    </ContactSectionSubtitle>
                  </Row>
                  <Row>
                    <ContactSectionDescription>
                      <FormattedMessage
                        id='debtCollection.whatIsItAboutDescription'
                        values={{
                          b: (...chunks) => <b>{chunks}</b>,
                          a: (...chunks) => <a>{chunks}</a>,
                          p: (...chunks) => <p>{chunks}</p>
                        }}
                      />
                    </ContactSectionDescription>
                  </Row>
                  <Row>
                    <Column>
                      <CallUs href='tel:+48662401789'>
                        <FormattedMessage
                          id='contact.callUs'
                          values={{
                            b: chunks => <b>{chunks}</b>
                          }}
                        />
                      </CallUs>
                      <Email
                        target='_blank'
                        href={`mailto:${intl.formatMessage({
                          id: 'contact.mailUs'
                        })}`}
                      >
                        <FormattedMessage id='contact.mailUs' />
                      </Email>
                      <Spacer />
                      <CompanyAddress>
                        <FormattedMessage id='contact.openHours' />
                      </CompanyAddress>
                      <Spacer />
                      <CompanyName>
                        <FormattedMessage id='contact.companyName' />
                      </CompanyName>
                      <CompanyAddress>
                        <FormattedMessage id='contact.street' />
                      </CompanyAddress>
                      <CompanyAddress>
                        <FormattedMessage id='contact.city' />
                      </CompanyAddress>
                    </Column>
                  </Row>
                </AddressDataContainer>
              </Column>
            </Row>
          </Column>
        </Row>
      </SectionBox>
      <DebtConfirmationDialog
        confirmation={confirmation}
        clearDebtCollection={clearDebtCollection}
        errorMessage={errorMessage}
        offer={offer}
      />
    </Page>
  )
}

const connectedComponent = connect(DebtCollectionForm)

export default styled(connectedComponent)`
  background-color: ${({ theme }) => theme.colors.whiteThree.hex()};
  ${SectionBox} {
    ${breakpoint.m`
      padding: 2rem 4.125rem 2rem 5rem;
  `}
  }
`

export const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.78;
  text-align: center;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 700;
`

export const InputTitle = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 600;
`
const Debtor = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  font-weight: 400;
  padding: 0.75rem;
`

const DropZoneContainer = styled.div`
  width: 100%;
`

export const DropZone = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px;
  border: 1px dashed rgb(198, 203, 212);
  padding: 1.4375rem;
  margin: 4px 0;
  font-size: 0.875rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  & a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    font-weight: 600;
  }
`

const DropZoneInfo = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  opacity: 0.7;
`
export const Line = styled.div`
  width: 100%;
  opacity: 0.3;
  border: 1px solid rgb(198, 203, 212);
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AcceptButton = styled.button`
  width: 100%;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  border: none;
  color: ${({ theme }) => theme.colors.white.hex()};
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.71;
  font-weight: 600;
  opacity: 0.5;
  pointer-events: none;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`

export const ContactSectionTitle = styled.p`
  display: block;
  font-size: 0.875rem;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  b {
    color: ${({ theme }) => theme.colors.apple.hex()};
  }
`
export const ContactSectionSubtitle = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 700;
`

export const ContactSectionDescription = styled.div`
  font-size: 0.75rem;
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
  b {
    font-weight: 600;
  }
`

const File = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  width: 100%;
`

const AttachIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.geyser.hex()};
  border-radius: 4px;
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.colors.white.hex()};
  margin-right: 0.5rem;
`

const RemoveIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const FileName = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & span {
    font-size: 0.75rem;
    opacity: 0.7;
  }
`
const FilesList = styled.ul`
  width: 100%;
  margin-top: 0.5rem;
  ${breakpoint.m`
    margin-top: 0;
  `}
`
