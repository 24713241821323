import React, { useCallback, useEffect } from 'react'
import { Ellipsis } from 'src/features/stocks/components/atoms'
import { useIntl } from 'gatsby-plugin-intl'
import { TableRow } from 'src/features/contracts/components/atoms'
import styled from 'styled-components'
import {
  INVOICE_ITEMS_TABLE_NAMES,
  INVOICE_ITEMS_TABLE_WIDTHS,
  TAX_RATES_OPTIONS
} from 'src/features/invoices/duck/consts'
import RemoveIconUrl from 'src/assets/icons/close-x.svg'
import { connectDictionaries } from 'src/ducks/connectors'
import MobileInvoiceItemRow from 'src/features/invoices/components/itemsTable/MobileInvoiceItemRow'
import debounce from 'lodash.debounce'
import { formatPrice } from 'src/features/invoices/duck/helpers'

const ItemRow = ({
  item,
  index,
  className,
  setInvoiceEditFormValue,
  removeItem,
  dictionaries,
  recalculateInvoice,
  invoicesLength
}) => {
  useEffect(() => {
    recalculateInvoice()
  }, [
    item.description,
    item.unit,
    item.unitPrice.float,
    item.qty,
    item.taxRate
  ])
  const intl = useIntl()
  const debouncedSetInvoiceEditFormValue = useCallback(
    debounce(setInvoiceEditFormValue, 500),
    []
  )

  const onPriceBlur = () => {
    const priceFormatted = formatPrice(item.unitPrice.float)
    if (priceFormatted !== item.unitPrice.float) {
      debouncedSetInvoiceEditFormValue(
        ['items', index, 'unitPrice', 'float'],
        priceFormatted
      )
    }
  }

  return (
    <>
      <InvoiceItemRow
        className={className}
        widths={INVOICE_ITEMS_TABLE_WIDTHS}
        columnNames={INVOICE_ITEMS_TABLE_NAMES}
      >
        <div>
          <Ellipsis>{index + 1}.</Ellipsis>
        </div>
        <div>
          <InvisibleInput
            type='text'
            name={['items', index, 'description']}
            value={item.description}
            onChange={e =>
              setInvoiceEditFormValue(
                ['items', index, 'description'],
                e.target.value
              )
            }
          />
        </div>
        <div>
          <InvisibleInput
            type='text'
            name={['items', index, 'pkwiuCode']}
            value={item.pkwiuCode}
            onChange={e =>
              setInvoiceEditFormValue(
                ['items', index, 'pkwiuCode'],
                e.target.value
              )
            }
          />
        </div>
        <div>
          <Select
            name='gtuCode'
            value={item.gtuCode}
            onChange={e =>
              setInvoiceEditFormValue(
                ['items', index, 'gtuCode'],
                e.target.value
              )
            }
          >
            <option value=''>-</option>
            {dictionaries.gtuCodes.map(option => (
              <option key={option.label} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <InvisibleInput
            type='number'
            min={0}
            name={['items', index, 'qty']}
            value={item.qty}
            onChange={e => {
              setInvoiceEditFormValue(['items', index, 'qty'], e.target.value)
            }}
          />
        </div>
        <div>
          <InvisibleInput
            type='text'
            name={['items', index, 'unit']}
            value={item.unit}
            onChange={e =>
              setInvoiceEditFormValue(['items', index, 'unit'], e.target.value)
            }
          />
        </div>
        <div>
          <PriceInput
            type='number'
            min={0}
            name={['items', index, 'unitPrice', 'float']}
            onBlur={onPriceBlur}
            value={item.unitPrice.float}
            onChange={e => {
              setInvoiceEditFormValue(
                ['items', index, 'unitPrice', 'float'],
                e.target.value
              )
            }}
          />
        </div>
        <div>
          <Select
            name='taxRate'
            value={item.taxRate}
            onChange={e => {
              setInvoiceEditFormValue(
                ['items', index, 'taxRate'],
                e.target.value
              )
            }}
          >
            {TAX_RATES_OPTIONS.map(option => (
              <option key={option.label} value={option.value}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </div>
        <RemoveIconContainer>
          {invoicesLength > 1 && (
            <RemoveIcon onClick={() => removeItem(index)} />
          )}
        </RemoveIconContainer>
      </InvoiceItemRow>
      <MobileInvoiceItemRow
        item={item}
        index={index}
        className={className}
        setInvoiceEditFormValue={setInvoiceEditFormValue}
        removeItem={removeItem}
        dictionaries={dictionaries}
        recalculateInvoice={recalculateInvoice}
        invoicesLength={invoicesLength}
      />
    </>
  )
}
const InvoiceItemRow = styled(TableRow)`
  border-radius: 0;
  border: solid 1px #c6cbd4;
  border-bottom: none;
  &:nth-child(2) {
    border-radius: 2px 2px 0 0;
  }
  &:last-child {
    border-bottom: solid 1px ${({ theme }) => theme.colors.ghost.hex()};
    border-radius: 0 0 2px 2px;
  }
  & > div {
    padding: 0.5rem;
    height: 100%;
    align-items: center;
    display: flex;
    border-left: 1px solid ${({ theme }) => theme.colors.ghost.alpha(0.3).hex()};
  }
`

export default connectDictionaries(ItemRow)

const RemoveIconContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

const RemoveIcon = styled(RemoveIconUrl)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
`

const InvisibleInput = styled.input`
  border: none;
  width: 100%;
`

const PriceInput = styled(InvisibleInput)`
  text-align: right;
`

const Select = styled.select`
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9IiMyNDJkM2YiIGZpbGwtb3BhY2l0eT0iMC43IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik03LjQxIDguNTlMMTIgMTMuMTdsNC41OS00LjU4TDE4IDEwbC02IDYtNi02IDEuNDEtMS40MXoiPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+Cg==)
    no-repeat right center;
  background-size: 20px;
  padding-right: 20px;
  outline: none;
  height: 25px;
  border: none;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.71;
  background-color: ${({ theme }) => theme.colors.white.hex()};
  color: ${({ theme }) => theme.colors.black.hex()};
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;
`
